<template>
  <CommonDialog
    :isShowHeader="false"
    :isShowBackButton="false"
    width="570px"
    height="100%"
    max-width="100%"
    persistent
    :className="['specialisms_multiselect_modal']"
  >
    <template v-slot:body>
      <div class="header">
        <h2>Add Specialisms</h2>
        <v-icon
          icon="mdi-close"
          class="close__icon"
          @click="onCloseModal"
        ></v-icon>
      </div>
      <div class="specialisms_multiselect_modal_body" v-if="!isLoading">
        <div class="specialisms_multiselect_modal_inner no-scrollbar">
          <v-expansion-panels v-model="panel" multiple>
            <v-expansion-panel
              v-for="(specialism, index) in listOfSpecialismsCategory"
              :key="index"
            >
              <v-expansion-panel-title>
                <div class="specialisms_multiselect_modal_item_heading">
                  <v-checkbox
                    color="#4B4BFF"
                    :value="specialism.id"
                    v-model="mainCategory"
                    @click="onClickMainCategory(specialism.id)"
                  />
                  <div class="specialisms_multiselect_modal_item_title">
                    <v-icon
                      v-if="specialism.iconName"
                      class="specialisms_multiselect_modal_item_title_icon"
                    >
                      <img
                        style="width: 14px"
                        v-if="specialism.iconName"
                        :src="
                          require('@/assets/category-icons/' +
                            specialism.iconName +
                            '.svg')
                        "
                        alt="icon"
                      />
                    </v-icon>
                    <div class="specialisms_multiselect_modal_item_title_text">
                      <h2>{{ specialism?.poLabel }}</h2>
                      <p>
                        {{
                          specialism.subCategories
                            .slice(0, 2)
                            .map((sub) => sub.label)
                            .join(", ")
                        }}
                        <span v-if="specialism.subCategories?.length > 2">
                          etc..</span
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <div
                  class="specialisms_multiselect_modal_item_content"
                  v-for="subCategories in specialism.subCategories"
                  :key="subCategories.id"
                >
                  <v-checkbox
                    color="#4B4BFF"
                    :label="subCategories.label"
                    :value="subCategories.id"
                    v-model="selectedCategory"
                  >
                  </v-checkbox>
                </div>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>

        <div class="specialisms_multiselect_modal_footer">
          <v-btn
            class="button button-purple-border"
            @click="onCloseModal"
            block
          >
            <Text variant="span"> Cancel </Text>
          </v-btn>
          <v-btn
            class="button button-orange"
            block
            :loading="isLoading"
            @click="onclickSaveButton"
          >
            <Text variant="span"> Save </Text>
          </v-btn>
        </div>
      </div>

      <CommonLoader :loading="isLoading" class="loader-container" />
    </template>
  </CommonDialog>
</template>
<script lang="ts" setup>
import { computed, ComputedRef, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { USER_STORE } from "@/store/modules/user";
import { PROJECT_STORE } from "@/store/modules/project";
import WizardModal from "@/core/components/modals/WizardModal.vue";
import Loader from "@/core/components/common/Loader.vue";
import CommonDialog from "@/core/components/CommonDialog.vue";
import CommonLoader from "@/core/components/CommonLoader.vue";
import { ProjectSpecialismGeneratedEnum } from "@/core/enums/ProjectsEnum";

const store = useStore();
const showSearchSpecialism = ref(false);
const selectedCategory = ref([]) as any;
const props = defineProps({
  isReview: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["onClickCloseTab", "onClickSaveSpecialism"]);
const mainCategory = ref([]) as any;
const panel = ref([]) as any;
const listOfSpecialismsCategory = computed(
  () => store.getters[`${USER_STORE}/specialismsCategory`]
);
const isLoading = ref(false);
const user = computed(() => store.getters[`${USER_STORE}/user`]);
const projectSpecialisms = computed(
  () => store.getters[`${PROJECT_STORE}/projectSpecialisms`]
);

const onClickMainCategory = (mainCategoryId: number) => {
  if (mainCategory.value.includes(mainCategoryId)) {
    const mainCategory = listOfSpecialismsCategory.value.find(
      (category: any) => category?.id === mainCategoryId
    );
    mainCategory.subCategories.forEach((element: any) => {
      const elementIndex = selectedCategory.value.findIndex(
        (subCategory: any) => subCategory === element.id
      );
      if (elementIndex > -1) {
        selectedCategory.value.splice(elementIndex, 1);
      }
    });
  } else {
    const mainCategory = listOfSpecialismsCategory.value.find(
      (category: any) => category?.id === mainCategoryId
    );

    mainCategory.subCategories.forEach((element: any) => {
      if (!selectedCategory.value.includes(element?.id)) {
        selectedCategory.value.push(element?.id);
      }
    });
  }
};
onMounted(async () => {
  try {
    if (!listOfSpecialismsCategory.value) {
      isLoading.value = true;
      await store.dispatch(`${USER_STORE}/getCategories`);
    }
    // const selectedCategoryIds = projectSpecialisms.value.map((item: any) => {
    //   return item?.id;
    // });
    // listOfSpecialismsCategory.value?.forEach((specialism: any) => {
    //   if (selectedCategoryIds.includes(specialism.id)) {
    //     specialism.selected = true;
    //   } else {
    //     specialism.selected = false;
    //   }
    // });

    projectSpecialisms.value.forEach((specialism: any) => {
      selectedCategory.value.push(specialism.id);
    });
    listOfSpecialismsCategory.value.forEach(
      (specialism: any, index: number) => {
        if (specialism.subCategories.length) {
          const isSelectedAllSubCategory = specialism.subCategories.every(
            (subCategory: any) =>
              selectedCategory.value.includes(subCategory.id)
          );
          if (isSelectedAllSubCategory) {
            mainCategory.value.push(specialism.id);
            panel.value.push(index);
          }
        }
      }
    );

    isLoading.value = false;
  } catch (error) {
    console.log();
  }
});

const onclickSaveButton = async () => {
  try {
    if (!props.isReview) {
      const newAddedSubCategoriesData = await store.dispatch(
        `${PROJECT_STORE}/getNewAddedSubCategoryForProject`,
        {
          subCategoriesIds: selectedCategory.value,
          generatedBySpecialism: ProjectSpecialismGeneratedEnum.PROPERTY,
        }
      );
      store.dispatch(
        `${PROJECT_STORE}/setProjectSpecialisms`,
        newAddedSubCategoriesData
      );
    } else {
      emit("onClickSaveSpecialism", selectedCategory.value);
    }
  } catch (error) {
    console.log();
  } finally {
    onCloseModal();
  }
};

const onCloseModal = () => {
  // listOfSpecialismsCategory.value?.forEach(
  //   (specialism: any) => (specialism.selected = false)
  // );
  return emit("onClickCloseTab");
};
</script>

<style lang="scss" scoped>
@import "@/modules/trade-passport/styles/TradePassport.scss";
@import "@/modules/onboarding/styles/onboarding.scss";
.loader-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999999;
  width: 50px;
  height: 50px;
}
</style>
