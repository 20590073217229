import $axios from "../utils/axios-api-config";

export default {
  getCourses() {
    return $axios.get(`/courses`);
  },
  checkElegibility(courseId: number) {
    return $axios.get(`/courses/${courseId}/check-eligibility`);
  },
  getCourseSchedule(params: any) {
    return $axios.get(`/course-schedules?search=${params.name}&limit=100`);
  },
  getCourseScheduleById(params: {
    courseScheduleId: any;
    currentFilters: {
      search: any;
      status: any;
    };
  }): Promise<any> {
    const { courseScheduleId, currentFilters } = params;
    const urlParams = new URLSearchParams(currentFilters);
    const data = $axios.get(
      `/course-schedules/${courseScheduleId}?${urlParams.toString()}`
    );
    return data;
  },
  getCourseSchedules(params: any) {
    return $axios.get("/course-schedules", { params });
  },
  createUserCourse(
    userId: number,
    courseId: number,
    forQueue: boolean,
    terms: boolean
  ) {
    return $axios.post(`/user/${userId}/courses`, {
      courseId,
      forQueue,
      terms,
    });
  },
  updateUserCourse(
    userId: number,
    courseId: number,
    forQueue: boolean,
    terms: boolean,
    enrollmentStatus: string
  ) {
    return $axios.put(`/user/${userId}/courses/${courseId}`, {
      forQueue,
      terms,
      enrollmentStatus,
    });
  },
  createUserCourseSchedules(courseScheduleId: number, courseUserId: number) {
    return $axios.post(`/user-course/${courseUserId}/schedules`, {
      courseScheduleId,
    });
  },
  getEnrollCourses(userId: number) {
    return $axios.get(`/user/${userId}/courses`);
  },
  checkCourseAlreadyEnrolled(userId: number, scheduleId: number) {
    return $axios.get(`/user/${userId}/courses/is-enrolled/${scheduleId}`);
  },
};
