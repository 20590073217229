import { UserMessagesRoomStatusEnum } from "../enums/chatsEnums";
import $axios from "../utils/axios-api-config";

export default {
  /**
   * Fetch user message room by projectId, userId
   * @param userId
   * @param projectId
   * @returns UserMessageRoom[]
   */
  getUserMessageRoomByProjectId(userWorkStationId: number) {
    return $axios.get(`/user-messages/workstation/${userWorkStationId}`);
  },

  // getUserMessageRoomByProjectId(userWorkStationId: number, projectId: number) {
  //   return $axios.get(
  //     `/user-messages/${userWorkStationId}/project/${projectId}`
  //   );
  // },
  /**
   * Fetch all user message rooms
   * @param userId
   * @returns UserMessageRoom[]
   */
  getUserMessageRooms(userId: number) {
    return $axios.get(`/user-messages/${userId}`);
  },

  /**
   * Fetch all user messages by userMessageRoomId
   * @param userId
   * @param userMessageRoomId
   * @returns UserMessage[]
   */
  getUserMessages(userMessageRoomId: number) {
    return $axios.get(`/user-messages/user-room/${userMessageRoomId}`);
  },

  getUserMessagesByGroup(userId: number, userMessageRoomId: number) {
    return $axios.get(
      `/user-messages/${userId}/group-room/${userMessageRoomId}`
    );
  },

  /**
   * Post message in a userMessageRoom
   * @param formData CreateUserMessageRequestDto
   * @returns UserMessage
   */
  createUserMessage(payload: any) {
    return $axios.post(`/user-messages/`, payload);
  },

  /**
   * Create user message room if not exists
   * @param formData CreateUserMessageRoomRequestDto
   * @returns UserMessageRoom
   */
  createUserMessageRoom(formData: any) {
    return $axios.post(`/user-messages/user-room`, formData);
  },

  createUserGroupMessageRoom(formData: any) {
    return $axios.post(`/user-messages/user-group-room`, formData);
  },

  /**
   * Update user messages by userMessageRoomId
   * @param userId
   * @param userMessageRoomId
   * @param formData
   * @returns UserMessages[]
   */
  updateUserMessagesByUserMessageRoom(
    userId: number,
    userMessageRoomId: number,
    formData: any
  ) {
    return $axios.put(
      `/user-messages/${userId}/user-room/${userMessageRoomId}`,
      formData
    );
  },

  /**
   * Upload file attachment on user message room via userMessageRoomId
   * @param userWorkstationId
   * @param userMessageRoomId
   * @param userMessageId
   * @param file
   * @returns
   */
  createUserMessageRoomAttachment(
    userWorkstationId: number,
    userMessageRoomId: number,
    userMessageId: number,
    file: any
  ) {
    return $axios.post(
      `/user-messages/${userWorkstationId}/user-room/${userMessageRoomId}/attachment/${userMessageId}`,
      file
    );
  },

  /**
   * Fetch user messages by filters(params)
   * @param userId
   * @param params
   * @returns UserMessages[]
   */
  getUserMessagesFilters(userId: number, params = {}) {
    return $axios.get(`/user-messages/${userId}/filters/`, { params });
  },

  /**
   * Fetch all user message rooms
   * @param userId
   * @returns UserMessageRoom[]
   */
  getGroupContacts(userId: number) {
    return $axios.get(`/user-messages/${userId}/contacts`);
  },

  getUserMessagesRoomByuserWorkstationId(userWorkStationId: number) {
    return $axios.get(`/user-messages/user-workstation/${userWorkStationId}`);
  },

  /* putMessageHistoryByRoom(roomId: number, payload) {
    return $axios.post(`/message-history/${roomId}`, payload);
  },

  updateMessagesListByRoom(roomId: number, status = 'unread') {
    return $axios.get(`/message-history/${roomId}/${status}`);
  },

  getMessageHistoryRoomsByProject(projectId: number) {
    return $axios.get(`/message-history/${projectId}/project`);
  },

  getMessageHistoryByRoom(roomId: number) {
    return $axios.get(`/message-history/${roomId}/room`);
  },

  getMessageHistoryByUser() {
    return $axios.get('/message-history/user/list');
  },

  getMessageHistoryByToUserId(type = 'toUser', userId: number) {
    return $axios.get(`/message-history/filters/${type}/${userId}`);
  } */

  /**
   * Delete user messages by userMessageRoomId
   * @param userId
   * @param userMessageRoomId
   */
  deleteUserMessages(userMessageRoomId: number, isGroup = false) {
    return $axios.delete(
      `/user-messages/user-room/${userMessageRoomId}/${isGroup}`
    );
  },

  getAllGroupMembers(userMessageRoomId: number) {
    return $axios.get(`/user-messages/group-room/members/${userMessageRoomId}`);
  },

  addUserGroupMessageRoom(formData: any) {
    return $axios.post(`/user-messages/user-group-room/add/members`, formData);
  },
  acceptProjectChatStatus(
    userMessageRoomId: number,
    formData: { status: UserMessagesRoomStatusEnum }
  ) {
    return $axios.put(
      `/user-messages/user-room-project-chat-status/status/${userMessageRoomId}`,
      formData
    );
  },
  rejectProjectChatStatus(userMessageRoomId: number) {
    return $axios.delete(
      `/user-messages/user-room-project-chat-status/reject/${userMessageRoomId}`
    );
  },
  getKrystalConversation(): any {
    return $axios.get(`/gemini-flash/krystal-conversation`);
  },
  getKrystalChatResponse(payload: { prompt: string }): any {
    return $axios.post(`/gemini-flash/prompt-question`, payload);
  },
  //
};
