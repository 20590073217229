<template>
  <GeneralDialog
    :headerTitle="modalHeader.title"
    :headerDescription="modalHeader.description"
    @on-close="onCloseModal"
  >
    <template #body>
      <div class="tw-flex tw-flex-col tw-w-full tw-items-start tw-gap-2">
        <v-form
          :rules="validation"
          ref="templateNameFormRef"
          class="tw-w-full"
          @submit.prevent
        >
          <div class="tw-flex tw-flex-col tw-gap-2 tw-w-full">
            <Text variant="span" textWeight="400">Template Name</Text>
            <v-text-field
              density="compact"
              :rules="validation.templateName"
              v-model="state.form.templateName"
              variant="outlined"
              class="tw-rounded-[8px]"
            ></v-text-field>
          </div>
        </v-form>
      </div>
    </template>
    <template #footer>
      <div
        class="tw-flex tw-flex-col tw-gap-2 tw-items-center tw-justify-center"
      >
        <Button
          variant="secondary"
          label="Save Estimate Template"
          @click="onSave"
        />
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup>
import { ref, reactive } from "vue";

import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import { JOB_TEMPLATE_STORE } from "@/store/modules/job-template";
import { useStore } from "vuex";
import { computed } from "vue";
import workstation, { WORKSTATION } from "@/store/modules/workstation";

const emits = defineEmits(["on-close", "on-save"]);
const store = useStore();
const modalHeader = {
  title: "Save this estimate as a template",
  description: "Enter a name to use this saved template in the future",
};

const state = reactive({
  form: {
    templateName: "",
  },
});
const templateNameFormRef = ref(null);
const validation = ref({
  templateName: [(v) => !!v || "Template name is required"],
});

const onCloseModal = () => {
  emits("on-close");
};
const estimateForm = computed(() => {
  const estimates = store.getters[`${JOB_TEMPLATE_STORE}/estimateForm`];
  return estimates;
});

const onSave = async () => {
  const isFormValid = await templateNameFormRef.value.validate();

  if (isFormValid.valid) {
    try {
      const estimateData = JSON.parse(JSON.stringify(estimateForm.value));
      const metaData = estimateData.projectStages.map((phase) => {
        const tasks = phase.projectStageTasks.map((task) => {
          return {
            ...task,
            id: null,
            estimationPhaseTasksProperties: [],
          };
        });
        return {
          ...phase,
          id: null,
          attachments: [],
          estimationPhaseProperties: [],
          assignPhaseWorkStation: null,
          acceptedBy: null,
          projectStageTasks: tasks,
          user: null,
          workStation: null,
        };
      });

      const payload = {
        name: state.form.templateName,
        meta: { projectStages: metaData },
      };

      await store.dispatch(
        `${JOB_TEMPLATE_STORE}/createMyJobTemplate`,
        payload
      );

      await store.dispatch(`${JOB_TEMPLATE_STORE}/getMyJobTemplates`);
    } catch (error) {
      console.log();
    } finally {
      emits("on-save");
    }
  }
};
</script>
<script>
export default {
  name: "SaveTemplateModal",
};
</script>
<style lang="scss" scoped></style>
