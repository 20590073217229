<template>
  <main
    class="tw-h-full tw-flex tw-flex-col tw-col-span-2 md:tw-py-4 tw-gap-6 md:!tw-gap-2"
  >
    <v-btn
      variant="plain"
      class="tw-mr-auto tw-p-0 tw-capitalize !tw-h-auto tw-text-[#0C0F4A] tw-font-semibold tw-opacity-100"
      width="auto"
      aria-label="back"
      prepend-icon="mdi-chevron-left"
      @click="toggleShowTemplate"
      @click.prevent="() => $emit('on-back')"
      >back</v-btn
    >
    <div class="">
      <div
        class="tw-w-full tw-flex tw-flex-row tw-items-center tw-justify-between"
      >
        <span class="tw-text-lg tw-font-bold lg:tw-text-base tw-text-start"
          >Iknowa Job Estimates</span
        >
        <v-btn
          @click.prevent="() => $emit('on-load-template')"
          class="load-button"
        >
          <span>
            <img :src="require('@/assets/icons/load-icon.svg')" alt=""
          /></span>
          <span> load</span>
        </v-btn>
      </div>
      <div class="tw-flex tw-items-center tw-gap-3 tw-mt-4 md:!tw-mt-2">
        <v-icon
          v-if="templateData"
          class="custom_edit"
          icon="mdi-pencil"
          size="x-small"
          @click.prevent="() => $emit('on-update-template')"
        ></v-icon>
        <h4
          class="tw-text-2xl tw-text-[#0C0F4A] tw-font-bold tw-leading-8 lg:tw-text-lg text-left"
        >
          {{ templateData ? templateData?.name : "New Template" }}
        </h4>
      </div>
      <div class="tw-flex tw-gap-4">
        <div class="header-buttons tw-flex tw-gap-4"></div>
      </div>
    </div>

    <div
      class="tw-flex tw-items-center tw-gap-4 tw-w-full project-start-wrapper tw-pb-4"
    >
      <div class="tw-flex tw-items-center tw-gap-4 tw-w-full">
        <Text class="!tw-text-xs !tw-font-normal" whiteSpace="nowrap"
          >Proposed Project Start Date:</Text
        >
        <el-form :model="formData" class="proposed_project_date">
          <el-form-item class="tw-mb-0">
            <!-- :class="{ 'is-error': isFormError.startDate }" -->
            <NoBgDatePicker
              @change="updateEstimation"
              v-model="formData.suggestedStartDate"
              :disabled="isSubContractor || isLockTemplateModifiedChecker"
              placeholder="DD/MM/YYYY"
              class="!tw-w-[130px] !tw-max-w-full"
            />
          </el-form-item>
        </el-form>
      </div>
    </div>

    <div
      class="tw-flex tw-flex-col tw-gap-6 tw-w-full accordion__section md:!tw-mt-2"
    >
      <v-expansion-panels v-model="panel" multiple
        ><v-expansion-panel
          v-for="(estimate, index) in getProjectEstimateData"
          :key="index"
        >
          <v-expansion-panel-title :color="estimate.color">
            <div class="heading">
              <div class="drag_panel_icon">
                <v-icon icon="mdi-dots-vertical"></v-icon>
                <v-icon icon="mdi-dots-vertical"></v-icon>
              </div>
              <h6 class="accordion_button_title">
                {{ getPanelName(estimate.stageType, index) }}
              </h6>
            </div>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <SandBoxEstimateCard
              :formProp="estimate"
              :phaseIndex="index"
              :key="index"
              @on-update-Estimation="updateEstimation"
            />
            <!-- :isPhaseAssignedToMe="isPhaseAssignedToMe(estimate)" -->
          </v-expansion-panel-text>
        </v-expansion-panel></v-expansion-panels
      >
    </div>

    <Button
      class="button"
      label="add another phase"
      @click="addPhase"
      width="100%"
    />
  </main>
</template>
<script setup>
import { computed, reactive, onBeforeMount } from "vue";
import { useStore } from "vuex";

import Text from "@/core/components/ui/general/Text.vue";
import NoBgDatePicker from "@/core/components/ui/inputs/NoBgDatePicker.vue";
import SandBoxEstimateCard from "@/modules/jobs/components/estimates/SandBoxEstimateCard.vue";
import Button from "@/core/components/ui/general/Button.vue";
import { JOB_TEMPLATE_STORE } from "@/store/modules/job-template";
import { ref } from "vue";
import { USER_STORE } from "@/store/modules/user";
import { WORKSTATION } from "@/store/modules/workstation";
import { watch } from "vue";
import $axios from "@/core/utils/axios-api-config";

const panel = ref([0]);

const props = defineProps({
  estimateForm: Object,
  templateData: {
    type: Object,
    default: null,
  },
});
const emits = defineEmits(["on-save-template", "on-load-template"]);
const store = useStore();
const userImagesMap = ref({});
const formData = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/formData`]
);
const isFormReadOnly = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/isFormReadOnly`]
);
const errorStatus = ref(false);
const user = computed(() => store.getters[`${USER_STORE}/user`]);

const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);

const isSubContractor = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/getIsSubContractor`]
);

const addPhase = () => {
  if (!isFormReadOnly.value) {
    store.dispatch(`${JOB_TEMPLATE_STORE}/addProjectStage`);
    updateEstimation();
  }
};

const state = reactive({
  showLockedState: false,
});

const getPanelName = (stageType, index) => {
  return `${stageType === "labour" ? "Phase" : "materials"} ${index + 1}`;
};

const isEstimateSubmitted = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/getIsEstimateSubmitted`]
);

const keyExists = (key) => {
  return key in userImagesMap.value;
};

const addUserImage = (id, imageUrl) => {
  if (!keyExists(id)) {
    userImagesMap.value[id] = imageUrl;
    return true;
  } else {
    return false;
  }
};

const isLockTemplateModifiedChecker = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/lockTemplateModifiedChecker`]
);

watch(
  () => props.estimateForm.projectStages,
  (newValue) => {
    panel.value = [];
    newValue.forEach((val, index) => {
      panel.value.push(index);
    });
  },
  { deep: true }
);

const updateEstimation = async () => {
  errorStatus.value = true;
  if (formData.value.suggestedStartDate) {
    errorStatus.value = false;
  } else {
    store.dispatch(`${JOB_TEMPLATE_STORE}/setEstimatePhaseDate`);
  }
  await store.dispatch(`${JOB_TEMPLATE_STORE}/setFormError`, {
    field: "startDate",
    value: errorStatus.value,
  });
};

const getProjectEstimateData = computed(() => {
  const cloneEstimateForm = JSON.parse(JSON.stringify(props.estimateForm));
  const updatedEstimatePhases = cloneEstimateForm.projectStages.sort(
    (a, b) => a.phaseOrder - b.phaseOrder
  );
  return updatedEstimatePhases;
});
const loadJobTemplate = async () => {
  try {
    const response = await store.dispatch(
      `${JOB_TEMPLATE_STORE}/getJobTemplate`,
      props.templateData?.id
    );

    if (response && response?.meta) {
      const templatePhases = response?.meta?.projectStages.map((phase) => {
        return {
          ...phase,
          user: { id: user.value?.id },
          workStation: { id: activeUserWorkstation.value?.id },
        };
      });

      store.dispatch(`${JOB_TEMPLATE_STORE}/setEstimateForm`, {
        projectStages: templatePhases,
      });
    }
  } catch (error) {
    console.log();
  }
};
onBeforeMount(async () => {
  panel.value = [];
  props.estimateForm.projectStages.forEach((element, index) => {
    panel.value.push(index);
  });

  if (!formData.value.id) {
    updateEstimation();
  }

  if (props.templateData) {
    await loadJobTemplate();
  }
});
</script>
<script>
export default {
  name: "sandBoxEstimateScreen",
};
</script>
<style lang="scss" scoped>
.accordion__section {
  :deep(.v-expansion-panels) {
    border-radius: 10px !important;
    gap: 16px;
    .v-expansion-panel {
      border-radius: 10px;
      border-top-left-radius: 10px !important;
      border-top-right-radius: 10px !important;
      .v-expansion-panel__shadow {
        box-shadow: none;
      }
      .v-expansion-panel-title {
        .heading {
          display: flex;
          gap: 16px;
          align-items: center;
          width: 100%;
          max-width: 100%;

          .drag_panel_icon {
            .v-icon {
              width: 8px;
              color: rgba($white, 1);
            }
          }
          .accordion_button_title {
            @include fluidFont(14, 14, 1.2);
            font-weight: 700;
            color: rgba($white, 1);
          }
        }

        .assign_button {
          width: calc(100% - 120px);
        }

        .v-expansion-panel-title__icon {
          .v-icon {
            color: rgba($white, 1);
          }
        }
      }

      .v-expansion-panel-text {
        .v-expansion-panel-text__wrapper {
          padding: 0;
        }
      }
    }
  }
}

.project-start-wrapper {
  border-bottom: 1px solid rgba($PrimaryBlueLight, 1);
  :deep(.el-form) {
    .el-form-item {
      .el-form-item__content {
        border-bottom: 1px solid rgba(12, 15, 74, 0.2);
        .el-input {
          width: 100%;
          max-width: 100%;
          height: auto;
          min-height: auto;
          .el-input__wrapper {
            background-color: transparent;
            box-shadow: none;
            .el-input__prefix {
              .el-input__prefix-inner {
                .el-icon {
                  svg {
                    color: #ffa500;
                  }
                }
              }
            }
          }
          .el-input__inner {
            @include fluidFont(12, 12, 1.5);
            font-weight: 500;
            color: rgba($blueDark, 1);
            &::placeholder {
              @include fluidFont(12, 12, 1.5);
              font-weight: 500;
              color: rgba($blueDark, 0.5);
            }
          }
          .el-input__suffix {
            .el-input__suffix-inner {
              .clear-icon {
                margin-left: 16px;
              }
            }
          }
        }
      }
    }
  }
}

.is-error input,
.is-error select {
  border-color: red;
}
.is-error {
  border: 1px solid red;
}

.card__wrapper {
  display: flex;
  background-color: rgba($white, 1);
  box-shadow: 0px 0px 8px 0px #0c0f4a14;
  border-radius: 6px;
  align-items: center;
  min-width: auto;
  gap: 4px;
  padding: 4px 8px;
  justify-content: space-between;

  .card__img {
    width: 16px;
    height: 16px;
    border-radius: 100%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .card__text {
    width: calc(100% - 50px);
    display: flex;
    align-items: center;
    white-space: nowrap;

    p {
      @include fluidFont(12, 12, 1.2);
      font-weight: 500;
      color: rgba(72, 89, 123, 1);
      letter-spacing: 0.4px;
      padding-right: 4px;
    }

    span {
      @include fluidFont(12, 12, 1.2);
      font-weight: 700;
      color: rgba(72, 89, 123, 1);
      letter-spacing: 0.4px;
      padding-left: 4px;
    }
  }

  .v-icon {
    color: rgba(128, 130, 159, 1);
    @include fluidFont(16, 16, 1);
  }
}

.header-buttons {
  .v-btn {
    height: auto;
    box-shadow: none;
    padding: 7px 22px;
    :deep(.v-btn__content) {
      @include fluidFont(12, 12, 1.2);
      font-weight: 600;
      color: rgba($blueDark, 0.5);
      text-transform: capitalize;
      letter-spacing: 0.4px;
    }
  }
}
.custom_edit {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: rgba($info, 1);
  color: rgba($white, 1);
  @include fluidFont(16, 16, 1);
  // @include respond(md) {
  //   width: 30px;
  //   height: 30px;
  // }
}
.load-button {
  height: auto;
  padding: 0;
  box-shadow: none;
  :deep(.v-btn__content) {
    padding: 8px 12px;
    @include fluidFont(14, 14, 1.2);
    font-weight: 600;
    color: rgba($blueDark, 1);
    text-transform: capitalize;
    letter-spacing: 0.4px;
    span {
      img {
        vertical-align: text-top;
      }
    }
  }
}
.proposed_project_date {
  .el-form-item {
    :deep(.el-form-item__content) {
      .el-input {
        width: 100%;
        max-width: 100%;
        height: auto;
        min-height: auto;
        .el-input__wrapper {
          padding: 0;
          background-color: transparent;
          box-shadow: none;
          .el-input__prefix {
            order: 3;
            .el-input__prefix-inner {
              .el-icon {
                svg {
                  color: #ffa500;
                }
              }
            }
          }
        }
        .el-input__inner {
          @include fluidFont(12, 12, 1.5);
          font-weight: 500;
          color: rgba($blueDark, 1);
          order: 1;
          &::placeholder {
            @include fluidFont(12, 12, 1.5);
            font-weight: 500;
            color: rgba($blueDark, 0.5);
          }
        }
        .el-input__suffix {
          order: 2;
        }
      }
    }
  }
}
</style>
