import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4c7b7874"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "profile_content md:!tw-pb-16 md:!tw-box-border" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_PersonalDetailsComponent = _resolveComponent("PersonalDetailsComponent")!
  const _component_PasswordDetailsComponent = _resolveComponent("PasswordDetailsComponent")!
  const _component_v_window_item = _resolveComponent("v-window-item")!
  const _component_v_window = _resolveComponent("v-window")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_tabs, {
      class: "tab-list",
      modelValue: _ctx.tab,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tab) = $event)),
      "align-tabs": "center",
      stacked: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_tab, { value: "tab-1" }, {
          default: _withCtx(() => [
            _createTextVNode(" Personal Details ")
          ]),
          _: 1
        }),
        _createVNode(_component_v_tab, { value: "tab-2" }, {
          default: _withCtx(() => [
            _createTextVNode(" Password Details ")
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_v_window, {
      modelValue: _ctx.tab,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.tab) = $event))
    }, {
      default: _withCtx(() => [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(2, (i) => {
          return _createVNode(_component_v_window_item, {
            key: i,
            value: 'tab-' + i
          }, {
            default: _withCtx(() => [
              (i === 1)
                ? (_openBlock(), _createBlock(_component_PersonalDetailsComponent, { key: 0 }))
                : _createCommentVNode("", true),
              (i === 2)
                ? (_openBlock(), _createBlock(_component_PasswordDetailsComponent, { key: 1 }))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1032, ["value"])
        }), 64))
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}