<template>
  <v-expansion-panels v-model="tradePassportPanel">
    <v-expansion-panel
      :expand="isExpand"
      class="collapsible-component !tw-rounded-[10px]"
      :title="title"
      v-model="tradePassportPanel"
    >
      <!-- <v-expansion-panel-title
        ><Text variant="p">{{ title }}</Text>
      </v-expansion-panel-title> -->
      <v-expansion-panel-text class="tw-border-t-2">
        <slot name="content"></slot>
        <v-divider class="tw-mt-8 tw-mb-2" v-if="hasActions"></v-divider>
        <v-card-actions class="tw-border-t-2" v-if="hasActions">
          <slot name="actions"></slot>
        </v-card-actions>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script setup>
import { ref } from "vue";

const props = defineProps({
  hasActions: Boolean,
  title: String,
  isExpand: {
    type: Boolean,
    default: false,
  },
});

const tradePassportPanel = ref(props.isExpand ? [0] : [1]);
</script>
<style lang="scss" scoped>
.collapsible-component {
  :deep(.v-expansion-panel-title) {
    min-height: 62px;
    font-weight: 700;
  }
  :deep(.v-expansion-panel__shadow) {
    box-shadow: 0px 0px 8px 0px rgba(12, 15, 74, 0.08);
  }
  :deep(.v-expansion-panel-text__wrapper) {
    padding: 0;
  }
}
</style>
