<template>
  <CommonDialog
    v-model="openReviewModal"
    :isShowHeader="false"
    :isShowBackButton="false"
    :className="['job_review_modal']"
    width="418px"
    height="auto"
    ><template v-slot:body>
      <div class="modal_content">
        <div class="modal__header">
          <v-btn
            class="modal__close"
            variant="plain"
            icon="mdi-close"
            @click="onCloseModal"
          ></v-btn>

          <div class="modal__header-inner">
            <div class="modal__header-top">
              <div class="modal__header-image">
                <img src="@/assets/images/review-card-1.png" alt="" />
              </div>
              <div class="modal__header-content">
                <h2>{{ reviewData?.project?.name }}</h2>
              </div>
            </div>
            <div class="modal__header-rating-wrapper">
              <div class="modal__header-rating">
                <v-rating
                  :model-value="reviewData.avgRating"
                  active-color="orange"
                  color="rgba(12, 15, 74, 0.26)"
                  empty-icon="mdi-star"
                  readonly
                ></v-rating>
                <p>{{ reviewData.avgRating }} out of 5</p>
              </div>

              <div class="modal__header-notification-wrapper">
                <v-btn
                  class="modal__header-notification"
                  height="44px"
                  width="44px"
                  variant="plain"
                  @click="onClickReviewChat"
                >
                  <img src="@/assets/icons/chat-dark.svg" alt="" />
                </v-btn>

                <div class="modal__header-notification-badge">
                  <p>1</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal__body">
          <div
            class="modal__body-item"
            v-for="status of getModifiedReviewStatusList"
            :key="status.id"
          >
            <div class="modal__body-item-title">
              <p>{{ status.tag }}</p>
              <p>{{ status.rating }}</p>
            </div>
            <v-progress-linear
              bg-color="#E8E8E8"
              :color="status.color"
              :model-value="status.starRate"
              :height="10"
              rounded
            ></v-progress-linear>
          </div>
        </div>
      </div>
    </template>
  </CommonDialog>
</template>
<script setup>
import CommonDialog from "@/core/components/CommonDialog.vue";
import { defineEmits, computed } from "vue";
import {
  ProjectRatingTypeEnum,
  PropertySideRatingTags,
  TradeSideRatingTags,
} from "@/core/enums/ProjectsEnum";
import { parseJSON } from "@/core/utils/common";

const emits = defineEmits(["on-close", "onViewComment"]);
const props = defineProps(["reviewData"]);
const openReviewModal = true;

const getProjectRatingTags = computed(() =>
  props.reviewData.type === ProjectRatingTypeEnum.TRADER_OWNER
    ? TradeSideRatingTags
    : PropertySideRatingTags
);
const getcolor = (rate) => {
  if (rate <= 25) {
    return "#FA4B00";
  } else if (rate <= 50) {
    return "#FFCD28";
  } else if (rate <= 75) {
    return "#29BC84";
  } else if (rate <= 100) return "#1FB27A";
};
const getModifiedReviewStatusList = computed(() => {
  const ratingStatusList = parseJSON(props.reviewData.ratingStatusList) || [];
  const modifiedData = getProjectRatingTags.value.map((statusItem, idx) => {
    const matchingData = ratingStatusList.find(
      (dataItem) => dataItem.tag === statusItem.label
    );

    if (matchingData) {
      const starRate = (matchingData.rating * 100) / 5;
      return {
        id: idx + 1,
        ...matchingData,
        starRate,
        color: getcolor(starRate),
      };
    } else {
      return {
        id: idx + 1,
        tag: statusItem.label,
        rating: 0,
        starRate: 0,
        color: getcolor(0),
      };
    }
  });
  return modifiedData;
});
const onCloseModal = () => {
  emits("on-close");
};
const onClickReviewChat = () => {
  emits("onViewComment");
};
</script>
<script>
export default {
  name: "ProjectReviewModal",
};
</script>
