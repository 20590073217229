<template>
  <v-dialog
    width="968px"
    height="800px"
    class="!tw-mt-[1.3rem] rlg:!tw-mt-0"
    :class="{ '!tw-mt-[0]': hideBackButton }"
    v-model="state.modal"
    persistent
  >
    <main class="tw-w-full tw-h-full tw-flex tw-bg-transparent tw-relative">
      <section
        @click="onBack"
        class="tw-absolute tw-top-[-2rem] tw-left-0"
        v-if="!hideBackButton && currentStep > 1"
      >
        <ChipState
          class="tw-justify-start !tw-p-0 tw-cursor-pointer"
          label="Back"
          textColor="rgba(255, 255, 255, 1)"
        >
          <template #prefix>
            <v-icon
              icon="mdi-chevron-left"
              color="rgba(255, 255, 255, 1)"
            ></v-icon>
          </template>
        </ChipState>
      </section>
      <section
        class="tw-rounded-[30px] tw-overflow-hidden tw-w-full tw-h-full tw-flex tw-bg-white tw-flex-col tw-justify-between tw-relative"
      >
        <div
          v-if="!hasCustomHeader"
          class="tw-relative tw-w-full tw-bg-[#E7EBF7] tw-h-[90px] tw-py-3 tw-flex tw-flex-col tw-gap-1 tw-items-center tw-justify-center tw-px-5 tw-box-border rmd:tw-h-[147px] rmd:tw-py-5 rmd:tw-px-14 rlg:tw-gap-2"
        >
          <div class="tw-w-[120px] rlg:tw-w-[160px]">
            <img
              class="tw-h-full tw-w-full tw-object-fill"
              src="@/assets/images/logo/iknowa-logo-v4.svg"
            />
          </div>

          <Text
            class="tw-hidden rmd:tw-inline rmd:!tw-text-[28px] rmd:!tw-leading-8"
            lineHeight="16px"
            textAlign="center"
            >{{ currentTab?.header }}</Text
          >

          <v-icon
            v-if="!hideClose"
            @click="onClose"
            icon="mdi-close"
            class="tw-cursor-pointer tw-absolute tw-top-6 tw-right-6"
            size="x-small"
          ></v-icon>
        </div>
        <slot name="custom-header" v-else></slot>
        <div
          class="tw-w-full tw-h-full tw-overflow-auto tw-box-border tw-px-5 tw-pt-3 rlg:tw-pt-6 rlg:tw-px-14"
          :class="{ 'tw-mb-4': hideFooter }"
        >
          <div class="tw-flex tw-flex-col tw-h-full tw-gap-4">
            <div class="tw-flex tw-flex-col tw-gap-3 rmd:tw-gap-4">
              <Text
                class="rmd:!tw-text-4xl rmd:!tw-leading-10"
                variant="h4"
                textWeight="500"
                lineHeight="28px"
                >{{ currentTab?.title }}</Text
              >
              <Text
                variant="h6"
                textWeight="500"
                textColor="rgba(12, 15, 74, 0.5)"
                lineHeight="18px"
                >{{ currentTab?.description }}</Text
              >
            </div>
            <slot name="content"></slot>
          </div>
        </div>
        <div
          v-if="!hideFooter"
          class="tw-w-full tw-pt-4 tw-px-5 tw-box-border tw-pb-6 rlg:tw-pb-8 rlg:tw-px-14"
          :class="{ 'tw-pt-0': hideAction || currentTab?.hideAction }"
        >
          <div
            class="tw-flex tw-w-full tw-flex-col tw-gap-3 tw-items-start rmd:tw-gap-6"
          >
            <div
              class="tw-flex tw-w-full"
              v-if="!hideAction || !currentTab?.hideAction"
            >
              <Button
                v-if="!hasCustomActionFooter"
                @click="onHandleClick"
                class="!tw-w-full rmd:!tw-w-[50%]"
                :label="currentTab?.id === steps.length ? 'Finish' : 'continue'"
              ></Button>
              <slot name="footer-action" v-else></slot>
            </div>
            <div class="tw-flex tw-w-full" v-if="!hideStepper">
              <v-stepper v-model="state.step" class="onboard-stepper tw-w-full">
                <v-stepper-header>
                  <v-stepper-item
                    v-for="(step, index) in steps"
                    :key="index"
                    :class="{ done: state.step > step.id }"
                    :value="step.id"
                    :title="step.tab"
                  ></v-stepper-item>
                </v-stepper-header>
              </v-stepper>
            </div>
          </div>
        </div>
      </section>
    </main>
  </v-dialog>
</template>
<script setup>
import { computed, onMounted, reactive, watch } from "vue";

import Text from "@/core/components/ui/general/Text.vue";
// import Button from "@/core/components/ui/general/Button.vue";
import ChipState from "../common/ChipState.vue";

const emits = defineEmits(["on-close", "on-back", "on-handle-click"]);
const props = defineProps([
  "hideStepper",
  "hideAction",
  "hideFooter",
  "hasCustomActionFooter",
  "hasCustomHeader",
  "hideBackButton",
  "hideClose",
  "singleData",
  "steps", // id, tab, name, header, title, description, hideAction. NOTE: id must start from number 1 (Type Number)
  "currentStep", // must be number 1 (Type Number)
]);

const state = reactive({
  modal: true,
  step: 0,
});

const currentTab = computed(() => {
  if (props.steps) {
    return props.steps[props.currentStep - 1];
  }
  return props.singleData;
});

const onHandleClick = () => {
  emits("on-handle-click", state.step);
};

const onBack = () => {
  emits("on-back", state.step);
};

const onClose = () => {
  emits("on-close", state.step);
};

watch(
  () => props.currentStep,
  (newValue) => {
    if (newValue) {
      state.step = props.currentStep;
    }
  },
  { deep: true, immediate: true }
);

onMounted(() => {
  state.step = props.currentStep;
});
</script>
<style lang="scss" scoped>
:deep(.v-overlay__content) {
  @apply tw-m-0 tw-max-h-[calc(100%-43px)] tw-max-w-[calc(100%-25px)];
}

:deep(.v-stepper-item) {
  width: 100%;
  max-width: 25px;
}

:deep(.v-stepper-item__content) {
  display: none;
}

:deep(.v-stepper-item--selected) {
  max-width: unset;
  .v-stepper-item__content {
    display: inline-block;
  }
}

@media screen and (min-width: 568px) {
  :deep(.v-stepper-item) {
    max-width: unset;
  }
  :deep(.v-stepper-item__content) {
    display: inline-block;
  }
}
</style>
