export enum ContractorInvitePermissionEnum {
  ALLPHASE = 1,
  BYMAINCONTRACTORONLY = 2,
}

export enum ContractorRequestStatusEnum {
  PENDING = "pending",
  ACCEPTED = "accepted",
  REJECTED = "rejected",
}

export const permissionType = [
  { type: "All phases & tasks", id: 1 },
  { type: "Phases & tasks by the main contractor only", id: 2 },
];

export enum GrantTypeEnum {
  GOVERNMENT = 1,
  PERSONAL = 2,
}

export enum EstimatePhaseType {
  LABOUR = 1,
  MATERIALS = 2,
}

export enum DurationsType {
  DAY = 1,
  WEEK = 2,
}

export enum AgreeTermsConsumerDisclosure {
  PENDING = 1,
  ACCEPTED = 2,
  REJECTED = 3,
  NOT_ADDED = 4,
  SECURE_PROJECT_ESCROW = 5,
  PROJECT_UNDERWAY = 6,
  COMPLETED = 7,
}
export enum EscrowStatusEnum {
  NOT_STARTED = "1",
  NOT_STARTED_POKED = "2",
  IN_PROGRESS = "3",
  IN_PROGRESS_POKED = "4",
  STAGE_COMPLETE = "5",
  CANCELLED = "6",
  PAYMENT_IN_PROCESS = "7",
}

export enum SiteVisitStatus {
  DEFAULT = 0,
  ACCEPTED = 1,
  REJECTED = 2,
  CANCELED = 3,
}

export enum EstimateTypeForDeposit {
  Initial_Estimate = 1,
  Variable_Cost_Estimation = 2,
  Fixed_Cost_Estimation = 3,
}

export enum EstimatePhaseStatus {
  PENDING = 1,
  ACCEPTED = 2,
  REJECTED = 3,
  WAITING = 4,
}
export const ESCROW_STATUS = [
  {
    id: "1",
    name: "Not Started",
    poLabel: "Deposit required for start stage",
    poButtonLabel: "Pay Deposit",
    tpLabel: "Waiting for stage deposit",
    tpButtonLabel: "Request Deposit And Start",
    icon: "pie-chart",
    type: "warning",
  },
  {
    id: "2",
    name: "Not Started",
    poLabel: "Tradesperson requested stage deposit",
    poButtonLabel: "Pay Deposit",
    tpLabel: "Sent request for stage deposit",
    tpButtonLabel: "Request Deposit And Start",
    icon: "pie-chart",
    type: "warning",
  },
  {
    id: "3",
    name: "In Progress",
    poLabel: "Stage in progress",
    poButtonLabel: "Approve & Release Payment",
    tpLabel: "Proof of work attachments are needed for stage completion",
    tpButtonLabel: "Request Payment",
    icon: "alarm-clock",
    type: "primary",
  },
  {
    id: "4",
    name: "In Progress",
    poLabel: "Tradesperson requests approval and payment release",
    poButtonLabel: "Approve & Release Payment",
    tpLabel: "Sent request for stage payment",
    tpButtonLabel: "Request Payment",
    icon: "alarm-clock",
    type: "primary",
  },
  {
    id: "5",
    name: "Stage Complete",
    poLabel: "",
    poButtonLabel: "View Invoice",
    tpLabel: "success",
    tpButtonLabel: "View Invoice",
    icon: "pie-chart",
    type: "primary",
  },
  {
    id: "6",
    name: "Cancelled",
    poLabel: "",
    poButtonLabel: "",
    tpLabel: "",
    tpButtonLabel: "",
    icon: "error",
    type: "danger",
  },
  {
    id: "7",
    name: "Payment In Process",
    poLabel: "Payment is in process",
    poButtonLabel: "Pay Deposit",
    tpLabel: "Payment is in process",
    tpButtonLabel: "Request Payment",
    icon: "pie-chart",
    type: "warning",
  },
];

export enum PropertySideStatus {
  PROJECT_LIVE = 1,
  ESTIMATE_RECEIVE = 2,
  CONTRACTOR_APPROVE = 3,
  SECURE_PROJECT_ESCROW = 4,
  PROJECT_UNDERWAY = 5,
  COMPLETED = 6,
}
