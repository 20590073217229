<template>
  <main class="tw-w-full tw-flex tw-flex-col tw-gap-6 md:tw-pt-4 md:tw-gap-5">
    <div
      class="tw-flex tw-items-center tw-gap-4 tw-w-full project-start-wrapper tw-flex-col rsm:tw-flex-row rsm:tw-gap-12 rsm:tw-justify-between tw-py-4 sm:tw-flex-col"
    >
      <div
        class="tw-flex tw-items-center tw-gap-4 sm:tw-w-full sm:tw-max-w-full sm:tw-justify-between"
      >
        <Text class="!tw-text-xs !tw-font-normal" whiteSpace="nowrap"
          >Project Date:</Text
        >
        <el-form :model="formData" class="proposed_project_date">
          <el-form-item class="tw-mb-0" :class="{ 'is-error': errorStatus }">
            <NoBgDatePicker
              v-model="formData.suggestedStartDate"
              placeholder="DD/MM/YYYY"
              class="!tw-w-[104px] !tw-max-w-full"
              :disabled="showGannt"
              startDate="00/00/0000"
              :isHistoric="true"
              @change="updateEstimation"
            />
          </el-form-item>
        </el-form>
      </div>

      <Button
        v-if="showTimeLineButton"
        variant="secondary-reverse"
        :label="showGannt ? 'PROJECT ESTIMATE' : 'PROJECT TIMELINE'"
        class="rsm:!tw-w-[auto]"
        @click="toggleShowGanttState"
      >
        <template #prefix
          ><GanttIcon v-if="!showGannt" /><CoinsIcon v-else
        /></template>
      </Button>
    </div>
    <div
      class="tw-flex tw-flex-col tw-gap-6 tw-w-full accordion__section"
      v-if="!showGannt"
    >
      <v-expansion-panels v-model="panel" multiple
        ><v-expansion-panel
          v-for="(estimate, index) in estimateForm.projectStages"
          :key="index"
        >
          <v-expansion-panel-title :color="estimate.color">
            <div class="heading">
              <div class="drag_panel_icon">
                <v-icon icon="mdi-dots-vertical"></v-icon>
                <v-icon icon="mdi-dots-vertical"></v-icon>
              </div>

              <h6 class="accordion_button_title">
                {{ getPanelName(estimate.stageType, index) }}
              </h6>
            </div>
          </v-expansion-panel-title>
          <v-expansion-panel-text v-if="isShowPanelText">
            <HistoricalEstimateFormCard
              :formProp="estimate"
              :phaseIndex="index"
              :key="index"
            /> </v-expansion-panel-text></v-expansion-panel
      ></v-expansion-panels>
    </div>
    <Button
      v-if="!showGannt"
      class="button"
      label="add another phase"
      @click="addPhase"
    />
  </main>
</template>
<script setup>
import { computed, reactive } from "vue";
import { useStore } from "vuex";

import Text from "@/core/components/ui/general/Text.vue";
import NoBgDatePicker from "@/core/components/ui/inputs/NoBgDatePicker.vue";
import HistoricalEstimateFormCard from "@/modules/jobs/components/estimates/HistoricalEstimateFormCard.vue";

import Button from "@/core/components/ui/general/Button.vue";
import { JOB_TEMPLATE_STORE } from "@/store/modules/job-template";

import GanttIcon from "@/core/components/icons/GanttIcon.vue";
import CoinsIcon from "@/core/components/icons/CoinsIcon.vue";
import { ref } from "vue";
import { JOBS_STORE } from "@/store/modules/jobs";
import { USER_STORE } from "@/store/modules/user";
import { WORKSTATION } from "@/store/modules/workstation";
import { EstimatePhaseStatus } from "@/core/enums/estimateEnum";
import { watch } from "vue";
import { getImageApiUrl } from "@/core/utils/common";
import $axios from "@/core/utils/axios-api-config";
import { onBeforeMount } from "vue";

const store = useStore();

const panel = ref([0]);
const emits = defineEmits(["on-toggle-gantt"]);
const props = defineProps({
  estimateForm: Object,
  showTimeLineButton: Boolean,
});

const showGannt = ref(false);

const userImagesMap = ref({});

const isOpenAssignPhaseModal = ref(false);

const isShowPanelText = ref(true);

const selectedPhase = ref(null);

const formData = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/formData`]
);

const isFormReadOnly = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/isFormReadOnly`]
);

const projectDetails = computed(
  () => store.getters[`${JOBS_STORE}/getJobDetails`]
);

const projectProperties = computed(
  () => projectDetails.value?.projectProperties
);

const errorStatus = ref(false);
const user = computed(() => store.getters[`${USER_STORE}/user`]);

const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);

const toggleShowGanttState = () => {
  showGannt.value = !showGannt.value;
  emits("on-toggle-gantt", showGannt.value);
};

const addPhase = () => {
  if (!isFormReadOnly.value) {
    store.dispatch(`${JOB_TEMPLATE_STORE}/addProjectStage`);
    updateEstimation();
  }
};
const formErrors = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/getFromErrors`]
);
const updateEstimation = async (phaseIndex = null) => {
  errorStatus.value = true;
  if (formData.value.suggestedStartDate) {
    errorStatus.value = false;
  }
  store.dispatch(
    `${JOB_TEMPLATE_STORE}/setEstimatePhaseDate`,
    formData.value.suggestedStartDate
  );
  await store.dispatch(`${JOB_TEMPLATE_STORE}/setFormError`, {
    field: "startDate",
    value: errorStatus.value,
  });
};

const state = reactive({
  showLockedState: false,
});

const getPanelName = (stageType, index) => {
  return `${stageType === "labour" ? "Phase" : "materials"} ${index + 1}`;
};

const keyExists = (key) => {
  return key in userImagesMap.value;
};

const addUserImage = (id, imageUrl) => {
  if (!keyExists(id)) {
    userImagesMap.value[id] = imageUrl;
    return true;
  } else {
    return false;
  }
};

const getUserWorkStationProfileImage = async (userDetails) => {
  if (userDetails) {
    const profileImage = userDetails.profileImage;
    if (!keyExists(userDetails.id)) {
      if (!profileImage) {
        addUserImage(userDetails.id, "@/assets/images/user-placeholder.png");
        return;
      }
      const imageUrl = getImageApiUrl(profileImage, true);
      try {
        const encoded = await $axios.get(imageUrl);
        addUserImage(userDetails.id, encoded.publicUrl);
        return encoded.publicUrl;
      } catch (error) {
        return null;
      }
    }
  }
};

watch(
  () => props.estimateForm.projectStages,
  (newValue) => {
    panel.value = [];
    newValue.forEach((val, index) => {
      panel.value.push(index);
    });
  },
  { deep: true }
);

watch(
  () => formErrors.value.startDate,
  (newValue) => {
    if (newValue) {
      updateEstimation();
    }
  },
  { deep: true }
);
</script>
<script>
export default {
  name: "HistoricalEstimateFormScreen",
};
</script>
<style lang="scss" scoped>
// .is-error input,
// .is-error select {
//   border: 1px solid red;
// }
.is-error {
  :deep(.el-input__inner) {
    border: 1px solid red !important;
  }
}
.accordion__section {
  :deep(.v-expansion-panels) {
    border-radius: 10px !important;
    gap: 16px;
    .v-expansion-panel {
      border-radius: 10px;
      border-top-left-radius: 10px !important;
      border-top-right-radius: 10px !important;
      &::after {
        display: none;
      }
      .v-expansion-panel-title {
        .heading {
          display: flex;
          gap: 16px;
          align-items: center;
          width: 100%;
          max-width: 100%;

          .drag_panel_icon {
            .v-icon {
              width: 8px;
              color: rgba($white, 1);
            }
          }
          .accordion_button_title {
            @include fluidFont(14, 14, 1.2);
            font-weight: 700;
            color: rgba($white, 1);
          }
        }

        .assign_button {
          width: calc(100% - 120px);
        }

        .v-expansion-panel-title__icon {
          .v-icon {
            color: rgba($white, 1);
          }
        }
      }

      .v-expansion-panel-text {
        .v-expansion-panel-text__wrapper {
          padding: 0;
        }
      }
    }
  }
}

.project-start-wrapper {
  border-bottom: 1px solid rgba($PrimaryBlueLight, 1);
  :deep(.el-form) {
    .el-form-item {
      .el-form-item__content {
        border-bottom: 1px solid rgba(12, 15, 74, 0.2);
        .el-input {
          width: 100%;
          max-width: 100%;
          height: auto;
          min-height: auto;
          .el-input__wrapper {
            background-color: transparent;
            box-shadow: none;
            .el-input__prefix {
              .el-input__prefix-inner {
                .el-icon {
                  margin: 0;
                  svg {
                    color: #ffa500;
                  }
                }
              }
            }
          }
          .el-input__inner {
            @include fluidFont(12, 12, 1.5);
            font-weight: 500;
            color: rgba($blueDark, 1);
            &::placeholder {
              @include fluidFont(12, 12, 1.5);
              font-weight: 500;
              color: rgba($blueDark, 0.5);
            }
          }
          .el-input__suffix {
            position: absolute;
            right: -20px;
          }
        }
      }
    }
  }
}

.card__wrapper {
  display: flex;
  background-color: rgba($white, 1);
  box-shadow: 0px 0px 8px 0px #0c0f4a14;
  border-radius: 6px;
  align-items: center;
  min-width: auto;
  gap: 4px;
  padding: 4px 8px;
  justify-content: space-between;

  .card__img {
    width: 16px;
    height: 16px;
    border-radius: 100%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .card__text {
    width: calc(100% - 50px);
    display: flex;
    align-items: center;
    white-space: nowrap;

    p {
      @include fluidFont(12, 12, 1.2);
      font-weight: 500;
      color: rgba(72, 89, 123, 1);
      letter-spacing: 0.4px;
      padding-right: 4px;
    }

    span {
      @include fluidFont(12, 12, 1.2);
      font-weight: 700;
      color: rgba(72, 89, 123, 1);
      letter-spacing: 0.4px;
      padding-left: 4px;
    }
  }

  .v-icon {
    color: rgba(128, 130, 159, 1);
    @include fluidFont(16, 16, 1);
  }
}
</style>
