<template>
  <v-dialog
    v-if="!isShowOccupierWelcome"
    v-model="isShowOccupiersWorkStation"
    width="968px"
    class="custom__modal__box occupier__modal__box"
    persistent
    min-height="800px"
  >
    <div class="previous_btn isShowLimitedCompanyWorkStation">
      <v-btn
        variant="text"
        aria-label="Refresh"
        class="back_btn"
        prepend-icon="mdi-chevron-left"
        @click="onClickBackButton"
        >back</v-btn
      >
    </div>
    <main class="main">
      <header class="custom__modal__header">
        <img
          class="open-logo"
          src="../../../../../src/assets/images/logo/iknowa-logo-v4.svg"
        />
        <h4>{{ currentSelectedTab.header }}</h4>
      </header>
      <div class="custom__modal__content">
        <div class="custom__modal__heading">
          <h3 class="title">{{ currentSelectedTab.title }}</h3>
          <p class="description">
            {{ currentSelectedTab.description }}
          </p>
        </div>
        <div class="custom__modal__content__wrapper">
          <div
            class="custom__modal__content__wrapper__top"
            v-if="currentSelectedTab.name === 'Profile Picture & Name'"
          >
            <div class="custom__modal__sub__content">
              <label>Nickname</label>
              <v-text-field
                density="compact"
                placeholder="E.g. Klein Buildings"
                v-model="tradingName"
                variant="outlined"
                class="c-input rounded-full"
              ></v-text-field>
              <!--  -->
              <div class="uploade-wrapper solo-trader-uploade-wrapper">
                <div class="uploade-wrapper">
                  <v-file-input
                    class="upload_file"
                    label="Upload"
                    append-inner-icon="mdi-image-plus"
                    variant="solo-filled"
                    @change="onFileChange"
                    @click:clear="clearImageUrl"
                    :class="{ 'added-img': imageUrl }"
                  ></v-file-input>
                  <v-img
                    class="uploaded-img"
                    :width="200"
                    aspect-ratio="16/9"
                    cover
                    :src="imageUrl"
                  ></v-img>
                </div>
              </div>
            </div>
            <v-btn
              class="button button-orange disabled"
              @click="onClickContinue"
              :loading="isLoadingAddressData ? true : false"
              >Continue</v-btn
            >
          </div>
          <div
            v-if="currentSelectedTab.name === 'Address'"
            class="modal__inner__body address_modal"
          >
            <div class="flex_50" v-if="!isShowNewAddresslist">
              <h4 class="h4">Your Address</h4>
              <div class="user_Address">{{ user.address }}</div>
              <div class="edit_postcode">
                <div>{{ user.postcode }}</div>
                <v-btn class="button" @click="onClickAddAnotherAddress">
                  <img src="../../../../assets/icons/trash-icon.svg"
                /></v-btn>
              </div>
              <v-btn
                class="button button-purple-border w-full mb48"
                @click="onClickAddAnotherAddress"
                >Choose another address</v-btn
              >
              <v-btn
                class="button button-orange w-full"
                @click="onConfirmAddress"
                >Confirm</v-btn
              >
            </div>

            <div class="flex_50" v-else>
              <v-form ref="postcodeform" :rules="rules" @submit.prevent>
                <div v-if="isShowAddressData">
                  <div class="input__file">
                    <label>Address line</label>
                    <v-text-field
                      density="compact"
                      variant="outlined"
                      class="c-input rounded-full"
                      v-model="address"
                      disabled
                    ></v-text-field>
                  </div>
                  <div class="input__file">
                    <label>City / Town</label>
                    <v-text-field
                      density="compact"
                      variant="outlined"
                      class="c-input rounded-full"
                      v-model="place"
                      disabled
                    ></v-text-field>
                  </div>
                </div>
                <div class="input__file">
                  <label>Postcode</label>
                  <v-text-field
                    density="compact"
                    placeholder="e.g E1 1BA"
                    variant="outlined"
                    class="c-input rounded-full"
                    v-model="postCode"
                    :rules="rules.postCode"
                    :disabled="isShowAddressData ? true : false"
                  ></v-text-field>
                  <ul
                    v-if="addressListfromPostcode.length && !isShowAddressData"
                    class="address__postcodes"
                  >
                    <li
                      v-for="{ DPA } in addressListfromPostcode"
                      :key="DPA?.UPRN"
                      @click="handleSelectAddress(DPA)"
                    >
                      <div>{{ DPA?.ADDRESS }}</div>
                    </li>
                  </ul>
                </div>
                <span class="error_msg" v-if="errorMessagePostcode">{{
                  errorMessagePostcode
                }}</span>
                <v-btn
                  v-if="!isShowAddressData"
                  class="button disabled"
                  :class="{ 'button-orange': isDisabledNextButton }"
                  size="large"
                  block
                  :loading="isActiveNextButton"
                  :disabled="!isDisabledNextButton"
                  @click="GoToFindAddress"
                >
                  find address
                </v-btn>
                <v-btn
                  v-if="isShowAddressData"
                  class="button disabled"
                  :class="{ 'button-orange': isDisabledNextButton }"
                  size="large"
                  block
                  :disabled="!isDisabledNextButton"
                  @click="onClickContine"
                >
                  CONTINUE
                </v-btn>
              </v-form>
            </div>
          </div>
        </div>
        <v-stepper class="onboard-stepper" v-model="currentSelectedTab.name">
          <v-stepper-header class="onboard-stepper__header">
            <v-stepper-item
              class="onboard-stepper__item"
              v-for="(step, index) in tabListItems"
              :key="index"
              :title="step.tab"
              :value="step.name"
            ></v-stepper-item>
          </v-stepper-header>
        </v-stepper>
      </div>
    </main>
  </v-dialog>
  <commonDialog
    v-else
    v-model="isShowOccupierWelcome"
    :isShowHeader="false"
    :onClickBackButton="onClickToggleOccupierWelcome"
    width="620px"
    max-width="100%"
    persistent
    :className="['occupier__last__step__box']"
  >
    <template v-slot:body>
      <div class="custom__modal__heading">
        <h3 class="title">Welcome To iknowa {{ user?.firstName }}!</h3>

        <div class="custom__modal__img">
          <img
            src="../../../../assets/images/welcome-homeowners.png"
            alt="Create Workstation"
          />
        </div>
        <p class="description explore__desc">
          Explore the platform, we’ve a lot of amazing features for you in
          iknowa! Feel free to go around check all the things we have made for
          you.
        </p>
      </div>
      <v-btn
        class="button button-purple-border btn_purple"
        size="large"
        block
        @click="onClickOccupierDashboard"
      >
        Explore
      </v-btn>
    </template>
  </commonDialog>
</template>

<script lang="ts">
import { computed, ref } from "vue";
import { VUE_APP_STRIPE_PUBLISHABLE_KEY } from "@/config";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { USER_STORE } from "@/store/modules/user";
import { WORKSTATION } from "@/store/modules/workstation";
import { BusinessDetailTypeEnum } from "@/core/enums/RolesEnum";
import axios from "axios";
import { VUE_APP_OS_API, VUE_APP_OS_POSTCODE_URL } from "@/config";
import { USER } from "@/store/modules/user";
import CommonDialog from "@/core/components/CommonDialog.vue";

export default {
  components: {
    CommonDialog,
  },
  setup(props: any, ctx: any) {
    const store = useStore();
    const router = useRouter();
    const isShowOccupiersWorkStation = ref(true);
    const isShowNewAddresslist = ref(false);
    const postCode = ref("");
    const errorMessagePostcode = ref("");
    const addressListfromPostcode = ref([]) as any;
    const billingAddressForm = ref(null);
    const isActiveNextButton = ref(false);
    const address = ref("");
    const place = ref("");
    const postcodeform = ref(null) as any;
    const isShowAddressData = ref(false);
    const tradingName = ref(null);
    const imageUrl = ref("");
    const imageData = ref("");
    const isLoadingAddressData = ref(false);
    const isShowOccupierWelcome = ref(false);
    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const tabListItems = [
      {
        tab: "Profile Picture & Name",
        name: "Profile Picture & Name",
        header: "Workstation Creation",
        title: "How do you refer to your Company?",
        description: "Please let us know your preferred / Trading As name",
      },
      {
        tab: "Address",
        name: "Address",
        header: "Confirm Address",
        title: "Confirm your address",
        description: "Please enter your qualification details.",
      },
    ];

    const currentSelectedTab = ref(
      tabListItems[store.getters[`${WORKSTATION}/getOccupiersWorkStationModal`]]
    );

    const onClickBackButton = () => {
      const currentTabIndex = tabListItems.findIndex(
        (tab) => tab.name === currentSelectedTab.value.name
      );
      if (currentTabIndex === 0) {
        return ctx.emit("onClickCloseTab");
      }
      currentSelectedTab.value = tabListItems[currentTabIndex - 1];
      store.commit(
        `${WORKSTATION}/setActiveOccupiersWorkStationModal`,
        currentTabIndex - 1
      );
    };
    const goToNextTab = () => {
      const currentTabIndex = tabListItems.findIndex(
        (tab) => tab.name === currentSelectedTab.value.name
      );
      if (currentTabIndex + 1 < tabListItems.length) {
        currentSelectedTab.value = tabListItems[currentTabIndex + 1];
        store.commit(
          `${WORKSTATION}/setActiveOccupiersWorkStationModal`,
          currentTabIndex + 1
        );
      }
    };

    const onClickContinue = async () => {
      try {
        isLoadingAddressData.value = true;
        const companyName = tradingName.value;
        const type = BusinessDetailTypeEnum.SOLETRADER;

        await store.dispatch(`${USER_STORE}/updateUser`, {
          userId: user.value?.id,
          userData: { hasBusinessType: true },
        });

        const createdWorkstation = await store.dispatch(
          `${WORKSTATION}/createUserWorkstation`,
          {
            userId: user.value?.id,
            userWorkstation: {
              name: companyName,
              companyName,
              businessType: type,
            },
          }
        );

        await store.dispatch(
          `${WORKSTATION}/getUserWorkstations`,
          user.value.id
        );

        await store.dispatch(
          `${WORKSTATION}/setActiveUserWorkstation`,
          createdWorkstation
        );

        goToNextTab();
        isShowNewAddresslist.value = false;
      } catch (error) {
        console.log("error", error);
      } finally {
        isLoadingAddressData.value = false;
      }
    };

    const onFileChange = async (event: any) => {
      const fileInput = event.target;
      if (fileInput.files && fileInput.files.length > 0) {
        const file = fileInput.files[0];

        imageUrl.value = URL.createObjectURL(file);
        imageData.value = file;
      } else {
        console.error("Error: No file selected.");
      }
    };
    const clearImageUrl = () => {
      imageUrl.value = "";
    };

    const onClickAddAnotherAddress = () => {
      currentSelectedTab.value.header = "Residential Address";
      currentSelectedTab.value.title = "What’s your residential address";
      currentSelectedTab.value.description =
        "This is required for our compliance requirements.";
      isShowNewAddresslist.value = true;
      isShowAddressData.value = false;
    };

    const onConfirmAddress = () => {
      isShowOccupierWelcome.value = true;
    };

    const onClickOccupierDashboard = () => {
      store.commit(`${USER}/setRole`, "Occupier");
      router.push("/dashboard");
    };

    const onClickToggleOccupierWelcome = () => {
      isShowOccupierWelcome.value = false;
    };

    const rules = ref({
      postCode: [(v: string) => !!v || "PostCode is required"],
    });

    const isDisabledNextButton = computed(() => {
      return /^[a-zA-Z0-9\s]+$/.test(postCode.value);
    });

    const handleSelectAddress = (DPA: any) => {
      address.value = DPA.THOROUGHFARE_NAME
        ? DPA.THOROUGHFARE_NAME
        : DPA.ORGANISATION_NAME;
      place.value = DPA.POST_TOWN;
      postCode.value = DPA.POSTCODE;
      billingAddressForm.value = DPA;
    };

    const GoToFindAddress = async (e: any) => {
      e.preventDefault();
      const isFormValid = await postcodeform.value.validate();
      if (isFormValid.valid) {
        if (billingAddressForm.value) {
          isShowAddressData.value = true;
        } else {
          try {
            isActiveNextButton.value = true;
            // Make an HTTP GET request to the API endpoint
            const response = await axios.get(
              `${VUE_APP_OS_POSTCODE_URL}?postcode=${postCode.value}&output_srs=EPSG:4326&key=${VUE_APP_OS_API}`
            );
            addressListfromPostcode.value = response.data.results;

            // Check if the request was successful
            if (response.status === 200) {
              errorMessagePostcode.value = "";
              address.value = response.data?.result?.primary_care_trust;
              place.value = response.data?.result?.region;
            } else if (response.status === 400) {
              // Handle error cases
              errorMessagePostcode.value = "Invalid postcode";
              console.error("Failed to fetch address data");
            }
          } catch (error) {
            // Handle any errors that occur during the HTTP request
            errorMessagePostcode.value = "Invalid postcode";
            console.error("Error fetching address data:", error);
          } finally {
            isActiveNextButton.value = false;
          }
        }
      }
    };

    const onClickContine = async () => {
      const UpdatedUser = await store.dispatch(`${USER_STORE}/updateUser`, {
        userId: store.getters[`${USER_STORE}/user`]?.id,
        userData: {
          postcode: postCode.value,
          address: address.value + "," + place.value,
        },
      });
      await store.dispatch(`${USER_STORE}/saveUserState`, UpdatedUser?.user);
      isShowOccupierWelcome.value = true;
    };

    return {
      isShowOccupiersWorkStation,
      onClickContinue,
      tradingName,
      VUE_APP_STRIPE_PUBLISHABLE_KEY,
      isLoadingAddressData,
      tabListItems,
      currentSelectedTab,
      onClickBackButton,
      goToNextTab,
      onFileChange,
      clearImageUrl,
      imageUrl,
      user,
      onClickAddAnotherAddress,
      isShowNewAddresslist,
      onConfirmAddress,
      rules,
      isDisabledNextButton,
      GoToFindAddress,
      isActiveNextButton,
      errorMessagePostcode,
      VUE_APP_OS_POSTCODE_URL,
      VUE_APP_OS_API,
      billingAddressForm,
      postcodeform,
      postCode,
      addressListfromPostcode,
      isShowAddressData,
      address,
      place,
      handleSelectAddress,
      onClickContine,
      isShowOccupierWelcome,
      onClickOccupierDashboard,
      onClickToggleOccupierWelcome,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/modules/workstation/occupiers/styles/OccupiersWorkstation.scss";
@import "@/modules/workstation/soletrader/styles/SoleTraderWorkStationComponent.scss";
</style>
