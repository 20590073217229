<template>
  <div class="tw-flex tw-items-center tw-gap-2 tw-box-border">
    <ProfileThumbnail size="sm" :src="imageUrl" />
    <Text variant="span" textWeight="600">{{
      contractorDetails?.userName
    }}</Text>
    <Text variant="span">|&nbsp; {{ contractorDetails?.workstationName }}</Text>
  </div>
</template>
<script setup>
import Text from "@/core/components/ui/general/Text.vue";
import ProfileThumbnail from "@/core/uni-components/ProfileThumbnail.vue";
import { onBeforeMount } from "vue";
import { getImageApiUrl } from "../utils/common";
import $axios from "@/core/utils/axios-api-config";
import { ref } from "vue";
const props = defineProps(["contractorDetails"]);
const imageUrl = ref(null);
const isProFileImageUploading = ref(false);
onBeforeMount(async () => {
  try {
    isProFileImageUploading.value = true;
    const imageApiUrl = getImageApiUrl(
      props?.contractorDetails?.profileImg,
      true
    );
    const encoded = await $axios.get(imageApiUrl);
    imageUrl.value = encoded.publicUrl;
  } catch (error) {
    return null;
  } finally {
    isProFileImageUploading.value = false;
  }
});
</script>
<style lang="scss" scoped></style>
