<template>
  <CommonDialog
    :isShowHeader="false"
    :isShowBackButton="false"
    width="765px"
    max-width="100%"
    :className="['setting_up_preview_modal']"
  >
    <template v-slot:body>
      <div class="modal_content">
        <div class="header">
          <v-btn
            variant="text"
            icon="mdi-close"
            @click="onClickCloseButton"
          ></v-btn>
        </div>
        <div
          class="modal_content_main md:!tw-p-0 md:!tw-flex md:!tw-flex-col md:!tw-gap-4"
        >
          <div class="title">Complete ID Verification</div>
          <div class="sub-title md:!tw-m-0">
            Sometimes we call it KYC (Know Your Customer) is used to increase
            clients trust towards you and your business and of course to win
            more jobs. Showing that there’s a real hero behind the verification.
          </div>
          <div class="title_img md:!tw-m-0">
            <img src="@/assets/images/completeID-verification-preview.png" />
          </div>
        </div>
        <div class="modal_footer_button">
          <v-btn
            variant="elevated"
            class="button button-orange w-full"
            @click="onClickStatusModalContinue"
          >
            Complete ID Verification
          </v-btn>
        </div>
      </div>
    </template>
  </CommonDialog>
  <WorkstationVerificationModal
    v-if="openedModel === modelName.VERIFYMODAL"
    :onClickCloseTab="onClickCloseModel"
  />
</template>

<script lang="ts">
import CommonDialog from "@/core/components/CommonDialog.vue";
import { USER_STORE } from "@/store/modules/user";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import WorkstationVerificationModal from "@/modules/workstation/components/WorkstationVerificationModal.vue";
import { WORKSTATION } from "@/store/modules/workstation";

export default {
  components: {
    CommonDialog,
    WorkstationVerificationModal,
  },
  setup(props: any, ctx: any) {
    const store = useStore();

    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const modelName = {
      ADDRESSMODAL: "addressModal",
      VERIFYMODAL: "verifyModal",
    };
    const openedModel = ref("");
    const currentStatusModalName = ref("");
    const statusModal = { PAYMENT: "payment", KYC: "kyc" };

    const activeUserWorkstation = computed(
      () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
    );

    const onClickCloseButton = () => {
      ctx.emit("onClickCloseTab");
    };

    const onClickStatusModalContinue = async () => {
      try {
        const url = new URL(window.location.href);
        url.search = "";
        if (currentStatusModalName.value === statusModal.PAYMENT) {
          const response = await store.dispatch(
            `${WORKSTATION}/getWorkstationVerificationData`,
            activeUserWorkstation.value?.id
          );
        }
        history.replaceState(null, "", url.toString());
        openedModel.value = "verifyModal";
      } catch (error) {
        console.log();
      }
    };

    const onClickCloseModel = async () => {
      openedModel.value = "";
      ctx.emit("onClickCloseTab");
    };

    return {
      onClickCloseButton,
      onClickStatusModalContinue,
      modelName,
      openedModel,
      onClickCloseModel,
      currentStatusModalName,
      statusModal,
    };
  },
};
</script>
