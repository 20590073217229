<template>
  <div class="trade_passport_wrapper">
    <!-- <div class="dashboard-verify-profile trade-passport-verify">
      <div class="dashboard-verify-profile__info common-profile-view">
        <div class="img-wrap">
          <img
            v-if="userWorkStationImage"
            :src="userWorkStationImage"
            width="150"
            height="150"
            alt="userImage"
          />
          <UserProfileLogo v-else />
        </div>
        <div class="content">
          <h3 class="dashboard-verify-profile__name">
            {{ activeUserWorkstation?.name }}
          </h3>
          <p class="dashboard-verify-profile__address">
            <v-icon icon="mdi-map-marker" />
            <span>{{
              user?.address ? `${user?.address}` : "Add Address"
            }}</span>
            &nbsp;&nbsp;&nbsp;
            <span>{{ user?.postcode }}</span>
          </p>
        </div>
      </div>

      <div class="dashboard-verify-profile__request desktop__request">
        <p
          v-if="
            userWorkStationStatus ===
            UserVerificationStatusEnum.PENDING_VERIFICATION
          "
        >
          <v-icon icon="mdi-alert-circle"></v-icon>
          <span>VERIFICATION</span>
          PENDING
        </p>
        <p
          class="verified"
          v-if="userWorkStationStatus === UserVerificationStatusEnum.VERIFIED"
        >
          <v-icon icon="mdi-check-circle"></v-icon>
          <span>VERIFIED </span>
          PROFILE
        </p>
        <label
          class="nowrap-txt"
          v-if="userWorkStationStatus === UserVerificationStatusEnum.VERIFIED"
          >Looking To Upskill?
          <span class="yellow-text" @click="navigateToCourse()"
            >Explore Our Courses<v-icon icon="mdi-chevron-right" /></span
        ></label>
      </div>
    </div> -->

    <v-tabs class="tab-list" align-tabs="center" stacked v-model="activeTab">
      <v-tab
        v-for="item in tradeItems"
        :key="item.name"
        :value="item.name"
        :disabled="isItemDisable(item?.name)"
      >
        <v-icon :icon="item.icon" />
        {{ item.name }}
      </v-tab>
    </v-tabs>
    <!-- <div class="dashboard-verify-profile__request mobile__request">
      <p v-if="userWorkStationStatus !== UserVerificationStatusEnum.VERIFIED">
        <v-icon icon="mdi-alert-circle"></v-icon>
        <span>VERIFICATION</span>
        REQUIRED
      </p>
      <p
        class="verified"
        v-if="userWorkStationStatus === UserVerificationStatusEnum.VERIFIED"
      >
        <v-icon icon="mdi-check-circle"></v-icon>
        <span>VERIFIED </span>
        PROFILE
      </p>
      <div
        class="nowrap-txt"
        v-if="userWorkStationStatus !== UserVerificationStatusEnum.VERIFIED"
      >
        <p>Fully Unlock Iknowa</p>
        <span class="yellow-text"
          >Get Verified<v-icon icon="mdi-chevron-right"
        /></span>
      </div>
      <div
        class="nowrap-txt"
        v-if="userWorkStationStatus === UserVerificationStatusEnum.VERIFIED"
      >
        Looking To Upskill?
        <span class="yellow-text"
          >Explore Our Accreditation Opportunities<v-icon
            icon="mdi-chevron-right"
        /></span>
      </div>
    </div> -->
    <div v-if="isLoading && activeTab === 'Overview'">
      <Loader :show="isLoading" />
    </div>
    <div v-if="activeTab === 'Overview' && !isLoading" class="overview">
      <div
        class="btn-import-workshop"
        v-if="
          isUserProprtyOwner ||
          (userAllWorkStation?.length > 2 && getUserWorkStationFieldAccess)
        "
      >
        <div class="back-btn" v-if="isUserProprtyOwner">
          <BackButton
            label="Back to Your Estimates"
            @click="onClickBackEstimateButton"
          />
        </div>
        <v-btn
          v-if="userAllWorkStation?.length > 2 && getUserWorkStationFieldAccess"
          class="button button-orange"
          @click="importWorkstationData"
          ><img src="@/assets/images/folder-import.svg" alt="" /> Import
          Workstation Data</v-btn
        >
        <!-- <v-btn class="button button-purple" @click="onClickAssignData"
          ><img src="@/assets/images/folder-import.svg" alt="" /> Assign</v-btn
        > -->
      </div>
      <!-- <div class="dashboard_tab_header tradepassport__dashboard">
        <div class="dashboard_tab_header__content">
          <div
            class="dashboard_tab_header__content_left md:!tw-justify-start md:!tw-items-start"
          >
            <div class="dashboard_tab_header__content_left_img">
              <img
                class="!tw-rounded-full"
                v-if="userWorkStationImage"
                :src="userWorkStationImage"
                width="100"
                height="100"
                alt="userImage"
              />
              <UserProfileLogo class="!tw-rounded-full" v-else />
              <div
                class="verified__icon"
                v-if="
                  activeUserWorkstation?.verificationStatus ===
                  UserVerificationStatusEnum.VERIFIED
                "
              >
                <img src="../../../assets/icons/verified.svg" alt="" />
              </div>
            </div>
            <div class="dashboard_tab_header__content_left_text_content">
              <div class="dashboard_tab_header__content_left_text right-border">
                <h6 class="md:!tw-text-left">
                  {{ activeUserWorkstation?.name }}
                </h6>
                <div class="dashboard_tab_header__content_left_text_list">
                  <p>Type:</p>
                  <span>{{
                    activeWorkStationType(user?.userBusinessDetails?.type)
                  }}</span>
                </div>
                <div class="dashboard_tab_header__content_left_text_list">
                  <p>Subscription Type:</p>
                  <span class="tag">
                    {{ subscriptionType(activeUserWorkstation) }}
                  </span>
                </div>
              </div>
              <div class="dashboard_tab_header__content_left_text">
                <div class="dashboard_tab_header__content_left_text_list">
                  <p>Created Date:</p>
                  <span>{{
                    workstationCreatedDate(activeUserWorkstation?.dateCreated)
                  }}</span>
                </div>
                <div class="dashboard_tab_header__content_left_text_list">
                  <p>Update Date:</p>
                  <span>{{
                    workstationCreatedDate(activeUserWorkstation?.dateUpdated)
                  }}</span>
                </div>
                <div class="dashboard_tab_header__content_left_text_list">
                  <p>Address:</p>
                  <span class="!tw-whitespace-pre-wrap">
                    {{
                      workstationAddress(
                        activeUserWorkstation?.user?.userBusinessDetails?.type
                      ) !== "null null null"
                        ? workstationAddress(
                            activeUserWorkstation?.user?.userBusinessDetails
                              ?.type
                          )
                        : ""
                    }}</span
                  >
                </div>
                <div
                  v-if="
                    userWorkStationStatus ===
                    UserVerificationStatusEnum.VERIFIED
                  "
                  class="dashboard_tab_header__content_left_text_list"
                >
                  <p>isAvailable</p>
                  <v-switch
                    v-model="isAvailable"
                    :disabled="!getUserWorkStationFieldAccess"
                    color="success"
                  ></v-switch>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="getUserWorkStationFieldAccess"
            class="dashboard_tab_header__content_right"
            @click="onClickEditWorkStation"
          >
            <v-btn class="button button-orange"> Edit Details</v-btn>
          </div>
        </div>
      </div> -->

      <!-- TRADE PASSPORT GENERAL DETAILS -->
      <Card class="tw-p-5 !tw-max-h-[unset]">
        <template #content>
          <section
            class="tw-grid tw-w-full tw-gap-5 tw-min-h-[235px] rlg:tw-grid-cols-2"
          >
            <!-- Workstation details card -->
            <Card class="!tw-h-full tw-p-4">
              <template #content>
                <div class="tw-h-full tw-flex-col-between">
                  <div class="tw-h-full tw-flex tw-items-center tw-gap-3">
                    <div v-if="activeUserWorkstation?.name === 'Default'">
                      <div
                        class="contractor-detail__img-wrapper tw-overflow-hidden tw-w-[32px] tw-h-[32px] tw-max-h-[98px] tw-max-w-[98px] tw-min-h-[98px] tw-min-w-[98px] rsm:tw-max-h-[104px] rsm:tw-max-w-[104px] rsm:tw-min-h-[104px] rsm:tw-min-w-[104px] tw-rounded-lg md:!tw-overflow-hidden md:!tw-rounded-full"
                      >
                        <img
                          class="tw-w-full tw-h-full tw-object-cover"
                          src="../../../assets/images/iknowa-thumbnail.png"
                        />
                        <div class="verified__icon">
                          <img
                            src="../../../assets/icons/verified.svg"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div
                        class="tw-relative"
                        v-if="
                          userWorkStationImage || contractorworkStationImage
                        "
                      >
                        <ProfileThumbnail
                          size="xllg"
                          mobilSize="xlg"
                          :isOnline="true"
                          :src="
                            isUserProprtyOwner
                              ? contractorworkStationImage
                              : userWorkStationImage
                          "
                        />
                        <ChipState
                          bgColor="#FFF"
                          :hasShadow="true"
                          class="tw-absolute tw-top-[0] tw-right-[0] tw-z-10 !tw-w-6 !tw-h-6 !tw-px-0 !tw-py-0 !tw-gap-0"
                        >
                          <template #prefix>
                            <div>
                              <CheckGreenShield
                                v-if="
                                  activeUserWorkstation?.verificationStatus ===
                                  UserVerificationStatusEnum.VERIFIED
                                "
                              />
                              <ErrorRedIcon v-else />
                            </div>
                          </template>
                        </ChipState>
                      </div>

                      <div
                        v-else
                        class="tw-rounded-full tw-max-h-[104px] tw-max-w-[104px] tw-min-h-[104px] tw-min-w-[104px] tw-h-[48px] tw-relative"
                      >
                        <ChipState
                          bgColor="#FFF"
                          :hasShadow="true"
                          class="tw-absolute tw-top-[0] tw-right-[0] tw-z-10 !tw-w-6 !tw-h-6 !tw-px-0 !tw-py-0 !tw-gap-0"
                        >
                          <template #prefix>
                            <div>
                              <CheckGreenShield
                                v-if="
                                  activeUserWorkstation?.verificationStatus ===
                                  UserVerificationStatusEnum.VERIFIED
                                "
                              />
                              <ErrorRedIcon v-else />
                            </div>
                          </template>
                        </ChipState>
                        <UserProfileLogo class="!tw-rounded-full" />
                      </div>
                    </div>
                    <div
                      v-if="activeUserWorkstation?.name === 'Default'"
                      class="tw-flex tw-flex-col tw-gap-1 tw-items-start"
                    >
                      <div
                        class="tw-flex-col tw-flex tw-gap-2 tw-flex-start rsm:tw-items-center rsm:tw-flex-row"
                      >
                        <Text
                          class="rsm:!tw-text-[22px]"
                          variant="h6"
                          textAlign="left"
                          >{{ activeUserWorkstation?.name }}</Text
                        >
                        <ChipState
                          class="!tw-w-[90px]"
                          bgColor="rgba(249, 130, 40, 0.26)"
                          :label="
                            activeWorkStationType(
                              user?.userBusinessDetails?.type
                            )
                          "
                        ></ChipState>
                      </div>
                      <div
                        class="tw-hidden tw-items-center tw-gap-1 rsm:!tw-flex"
                      >
                        <Text
                          class="tw-w-[60%]"
                          variant="p"
                          textWeight="500"
                          textAlign="left"
                          >44 Treeline Court, Birmingham.</Text
                        >
                        <el-divider direction="vertical"></el-divider>
                        <Postcode value="W12 9RE" />
                      </div>
                    </div>
                    <div
                      v-else
                      class="tw-flex tw-flex-col tw-gap-1 tw-items-start"
                    >
                      <div
                        class="tw-flex-col tw-flex tw-gap-2 tw-flex-start rsm:tw-items-center rsm:tw-flex-row"
                      >
                        <Text
                          class="rsm:!tw-text-[22px]"
                          variant="h6"
                          textAlign="left"
                          >{{ activeUserWorkstation?.name }}</Text
                        >
                        <ChipState
                          class="!tw-w-[90px]"
                          bgColor="rgba(249, 130, 40, 0.26)"
                          :label="
                            activeWorkStationType(
                              user?.userBusinessDetails?.type
                            )
                          "
                        ></ChipState>
                      </div>
                      <div
                        class="tw-hidden tw-items-center tw-gap-1 rsm:!tw-flex"
                      >
                        <Text
                          class="tw-w-[60%]"
                          variant="p"
                          textWeight="500"
                          textAlign="left"
                          >{{
                            workstationAddress(
                              activeUserWorkstation?.user?.userBusinessDetails
                                ?.type
                            ) !== "null null"
                              ? workstationAddress(
                                  activeUserWorkstation?.user
                                    ?.userBusinessDetails?.type
                                )
                              : ""
                          }}</Text
                        >
                        <el-divider
                          v-if="
                            workstationPostcode(
                              activeUserWorkstation?.user?.userBusinessDetails
                                ?.type
                            ) !== null
                          "
                          direction="vertical"
                        ></el-divider>
                        <Postcode
                          v-if="
                            workstationPostcode(
                              activeUserWorkstation?.user?.userBusinessDetails
                                ?.type
                            ) !== null
                          "
                          :value="
                            workstationPostcode(
                              activeUserWorkstation?.user?.userBusinessDetails
                                ?.type
                            )
                          "
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    class="tw-flex tw-flex-col tw-items-start tw-gap-1 rsm:!tw-hidden"
                  >
                    <Text variant="p" textWeight="500" textAlign="left">{{
                      workstationAddress(
                        activeUserWorkstation?.user?.userBusinessDetails?.type
                      ) !== "null null"
                        ? workstationAddress(
                            activeUserWorkstation?.user?.userBusinessDetails
                              ?.type
                          )
                        : ""
                    }}</Text>
                    <Postcode
                      v-if="
                        workstationPostcode(
                          activeUserWorkstation?.user?.userBusinessDetails?.type
                        ) !== null
                      "
                      :value="
                        workstationPostcode(
                          activeUserWorkstation?.user?.userBusinessDetails?.type
                        )
                      "
                    />
                  </div>
                  <div
                    class="tw-border-t-dashed-custom tw-pt-4 tw-box-border tw-w-full tw-flex tw-items-center tw-justify-end"
                  >
                    <div
                      v-if="getUserWorkStationFieldAccess"
                      class="tw-flex tw-gap-2 tw-items-center tw-cursor-pointer"
                      @click="onClickEditWorkStation"
                    >
                      <Text variant="p" textColor="#FFA500"
                        >Edit Workstation</Text
                      >
                      <v-icon icon="mdi-chevron-right" color="#FFA500"></v-icon>
                    </div>
                  </div>
                </div>
              </template>
            </Card>
            <!-- Rate details card -->
            <Card class="!tw-h-full tw-p-4">
              <template #content>
                <div class="tw-h-full tw-flex-col-between">
                  <div class="tw-h-full tw-flex tw-flex-col tw-gap-4">
                    <div class="tw-flex-row-between">
                      <Text variant="h5">Rates</Text>
                      <span class="icon-title-info">
                        <ToolTip
                          description="Displaying Hourly Rate, Emergency Call Out Fee, and Video Consultation Rate."
                        />
                      </span>
                    </div>
                    <div
                      v-if="activeUserWorkstation?.name === 'Default'"
                      class="tw-h-full tw-flex tw-flex-col tw-gap-3"
                    >
                      <div class="tw-flex tw-items-center tw-gap-2">
                        <Text variant="p" textAlign="left"
                          >Hourly Rate:&nbsp;
                          <Text variant="p" textWeight="500" textAlign="left"
                            >£ 10.00</Text
                          ></Text
                        >
                        <el-divider direction="vertical"></el-divider>
                        <Text variant="p" textAlign="left"
                          >Daily Rate:&nbsp;
                          <Text variant="p" textWeight="500" textAlign="left"
                            >£ 104.00</Text
                          ></Text
                        >
                      </div>
                      <div class="tw-flex tw-items-center tw-gap-2">
                        <Text variant="p" textAlign="left"
                          >Emergency Call Out Fee:&nbsp;
                          <Text variant="p" textWeight="500" textAlign="left"
                            >£ 110.00</Text
                          ></Text
                        >
                        <el-divider direction="vertical"></el-divider>
                        <Text variant="p" textAlign="left"
                          >Emergency Call out Hourly Rate:&nbsp;
                          <Text variant="p" textWeight="500" textAlign="left"
                            >£ 19.00</Text
                          ></Text
                        >
                      </div>
                      <div class="tw-flex tw-items-center tw-gap-2">
                        <Text variant="p" textAlign="left"
                          >Video Consultation:&nbsp;
                          <Text variant="p" textWeight="500" textAlign="left"
                            >£ 55.00</Text
                          ></Text
                        >
                      </div>
                    </div>
                    <div v-else class="tw-h-full tw-flex tw-flex-col tw-gap-3">
                      <div class="tw-flex tw-items-center tw-gap-2">
                        <Text variant="p" textAlign="left"
                          >Hourly Rate:&nbsp;
                          <Text variant="p" textWeight="500" textAlign="left">{{
                            CurrencyFilter.formatToCurrency(
                              activeUserWorkstation?.hourlyRate?.minRate
                            )
                          }}</Text></Text
                        >
                        <el-divider direction="vertical"></el-divider>
                        <Text variant="p" textAlign="left"
                          >Daily Rate:&nbsp;
                          <Text variant="p" textWeight="500" textAlign="left">{{
                            CurrencyFilter.formatToCurrency(
                              activeUserWorkstation?.dayRate?.minRate
                            )
                          }}</Text></Text
                        >
                      </div>
                      <div class="tw-flex tw-items-center tw-gap-2">
                        <Text variant="p" textAlign="left"
                          >Emergency Call Out Fee:&nbsp;
                          <Text variant="p" textWeight="500" textAlign="left">{{
                            CurrencyFilter.formatToCurrency(
                              activeUserWorkstation?.emergencyCallOutFee
                            )
                          }}</Text></Text
                        >
                        <el-divider direction="vertical"></el-divider>
                        <Text variant="p" textAlign="left"
                          >Emergency Call out Hourly Rate:&nbsp;
                          <Text variant="p" textWeight="500" textAlign="left">{{
                            CurrencyFilter.formatToCurrency(
                              activeUserWorkstation?.emergencyCallOutRate
                            )
                          }}</Text></Text
                        >
                      </div>
                      <div class="tw-flex tw-items-center tw-gap-2">
                        <Text variant="p" textAlign="left"
                          >Video Consultation:&nbsp;
                          <Text variant="p" textWeight="500" textAlign="left">{{
                            CurrencyFilter.formatToCurrency(
                              activeUserWorkstation?.videoConsulationFee
                            )
                          }}</Text></Text
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    class="tw-border-t-dashed-custom tw-pt-4 tw-box-border tw-w-full tw-flex tw-items-center tw-justify-end"
                  >
                    <div
                      v-if="getUserWorkStationFieldAccess"
                      class="tw-flex tw-gap-2 tw-items-center tw-cursor-pointer"
                      @click="onClickUpdateUserRates"
                    >
                      <Text variant="p" textColor="#FFA500">Edit Rates</Text>
                      <v-icon icon="mdi-chevron-right" color="#FFA500"></v-icon>
                    </div>
                  </div>
                </div>
              </template>
            </Card>
          </section>
        </template>
      </Card>

      <v-row class="trade-passport-row row-1">
        <!-- Your Trade Specialisms -->
        <div class="second w-4 tw-h-[570px]">
          <v-card class="custom-card specialisms-card">
            <div class="icon-title">
              <div class="icon-title__inner">
                <p class="common-title">Services</p>
              </div>
              <span class="icon-title-info">
                <ToolTip
                  description="Displaying Your Specialisms in which you can  manage your trade specialism."
                />
              </span>
            </div>
            <div
              class="tw-flex tw-flex-col tw-h-full tw-items-start tw-justify-between tradespecialism"
              v-if="activeUserWorkstation?.name === 'Default'"
            >
              <div class="card-service-icon-list tw-flex">
                <template v-for="(skill, index) in defaultUserSpecialisms">
                  <SpecialismItemTwo
                    :class="{
                      '!tw-max-h-[112px] card-service-icon-list-item':
                        filterUserSpecialisms?.length < 7,
                    }"
                    v-if="index < 8"
                    :key="index"
                    :label="skill.label"
                    :iconName="skill.iconName"
                  />
                </template>
                <div
                  v-if="filterUserSpecialisms?.length > 8"
                  class="tw-bg-[rgba(255,165,0,0.1)] tw-rounded-lg tw-py-3 tw-px-4 tw-box-border tw-min-h-[100px] tw-flex tw-flex-col tw-gap-1 tw-items-center tw-justify-start tw-border-[1px] tw-border-[#FFA500] tw-border-solid"
                >
                  <div
                    class="tw-bg-[#FFA500] tw-rounded-full tw-min-h-[40px] tw-min-w-[40px] tw-flex tw-items-center tw-justify-center"
                  >
                    <Text class="!tw-text-[20px]" textColor="#FFF">{{
                      `${filterUserSpecialisms?.length - 1}+`
                    }}</Text>
                  </div>
                  <Text variant="span">More</Text>
                </div>
              </div>
              <div
                class="tw-border-0 tw-border-[#C5D2F2] tw-border-t tw-border-dashed tw-pt-4 tw-box-border tw-w-full tw-flex tw-items-center tw-justify-end tw-mt-[auto]"
              >
                <div
                  class="tw-flex tw-gap-2 tw-items-center tw-cursor-pointer"
                  @click="onClickManageSpecialism"
                >
                  <Text variant="p" textColor="#FFA500">Manage Services</Text>
                  <v-icon icon="mdi-chevron-right" color="#FFA500"></v-icon>
                </div>
              </div>
            </div>
            <div
              v-else
              class="tw-flex tw-gap-4 tw-h-full md:!tw-overflow-y-auto md:!tw-pr-[.2rem]"
            >
              <div
                class="card-services tw-grid tw-w-full tw-gap-4 tw-flex-wrap !tw-pb-4 md:tw-gap-2"
                v-if="filterUserSpecialisms?.length && !specialismsLoading"
              >
                <template v-for="(skill, index) in filterUserSpecialisms">
                  <SpecialismItemTwo
                    class="card-service-icon-list-item"
                    :class="{
                      '!tw-max-h-[126px] ': filterUserSpecialisms?.length < 7,
                    }"
                    v-if="index < 8"
                    :key="index"
                    :label="skill.label"
                    :iconName="skill.iconName"
                  />
                </template>
                <div
                  v-if="filterUserSpecialisms?.length > 8"
                  class="tw-bg-[rgba(255,165,0,0.1)] tw-w-full tw-rounded-lg tw-py-3 tw-px-4 tw-box-border tw-flex tw-flex-col tw-gap-2 tw-items-center tw-justify-center tw-border-[1px] tw-border-[#FFA500] tw-border-solid tw-h-[125px]"
                >
                  <div
                    class="tw-bg-[#FFA500] tw-rounded-full tw-min-h-[40px] tw-min-w-[40px] tw-flex tw-items-center tw-justify-center"
                  >
                    <Text class="!tw-text-[18px]" textColor="#FFF">{{
                      `${filterUserSpecialisms?.length - 1}+`
                    }}</Text>
                  </div>
                  <Text variant="span">More</Text>
                </div>
              </div>
              <div
                v-if="specialismsLoading"
                class="tw-w-full tw-h-full tw-flex tw-flex-col tw-justify-center tw-items-center"
              >
                <v-progress-circular
                  :size="50"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </div>
              <div
                class="tw-w-full tw-h-full tw-flex tw-items-center tw-flex-col tw-justify-center tw-gap-4"
                v-if="!filterUserSpecialisms?.length && !specialismsLoading"
              >
                <div class="card__empty_img tw-w-[100px] tw-h-[100px]">
                  <img
                    :src="require('@/assets/images/specialisms-empty.png')"
                    alt="icon"
                    class="tw-h-full tw-w-full tw-object-contain"
                  />
                </div>
                <Text
                  variants="p"
                  textWeight="400"
                  textColor="rgba(12, 15, 74, 0.5)"
                  >Your Services will appear here</Text
                >
                <Button
                  v-if="getUserWorkStationFieldAccess"
                  @click="onClickManageSpecialism"
                  variant="accent"
                  label="Add Services"
                  class="!tw-w-[auto]"
                />
              </div>
            </div>
            <div
              v-if="
                filterUserSpecialisms?.length &&
                activeUserWorkstation?.name !== 'Default'
              "
              class="tw-border-0 tw-border-[#C5D2F2] tw-border-t tw-border-dashed tw-pt-4 tw-box-border tw-w-full tw-flex tw-items-center tw-justify-end tw-mt-[auto]"
            >
              <div
                class="tw-flex tw-gap-2 tw-items-center tw-cursor-pointer"
                @click="onClickManageSpecialism"
              >
                <Text variant="p" textColor="#FFA500">Manage Services</Text>
                <v-icon icon="mdi-chevron-right" color="#FFA500"></v-icon>
              </div>
            </div>
          </v-card>
        </div>
        <!-- console.log("hello") -->
        <!-- Your Insurances -->
        <div class="second w-4 !tw-h-[570px]">
          <v-card class="custom-card insurance_wrapper">
            <div class="icon-title">
              <div class="icon-title__inner">
                <p class="common-title">Insurance</p>
              </div>
              <span class="icon-title-info">
                <ToolTip
                  description="Displaying Your Insurances in which you can  manage your trade Insurances."
                />
              </span>
            </div>
            <div
              v-if="activeUserWorkstation?.name === 'Default'"
              class="insurance"
            >
              <div class="insurances-list">
                <template
                  v-for="insurance in userDefaultInsurances.slice(0, 2)"
                  :key="insurance.id"
                >
                  <GreyItemCard class="tw-shadow-none">
                    <template #status>
                      <div class="tw-flex tw-items-center tw-gap-2">
                        <PendingChip class="tw-w-[100px]" />
                        <RejectChip
                          class="tw-w-[100px]"
                          label="Expired"
                          v-if="isExpiredDate(insurance?.metadata?.expiryDate)"
                        />
                      </div>
                    </template>
                    <template #action>
                      <div class="tw-flex tw-gap-2 tw-items-center">
                        <PencilCircularBlueIcon
                          @click="onEditInsurance(insurance)"
                          class="!tw-min-h-[30px] !tw-min-w-[30px] tw-cursor-pointer"
                        />
                        <TrashCircularGrayIcon
                          class="tw-cursor-pointer"
                          @click="
                            onDeleteItem(
                              [],
                              insurance.id,
                              confirmDeleteInsuranceData.type,
                              confirmDeleteInsuranceData.label
                            )
                          "
                        />
                      </div>
                    </template>
                    <template #content>
                      <div class="tw-flex tw-flex-col tw-items-start tw-gap-1">
                        <Text variant="h6">{{
                          insurance?.metadata?.insuranceName
                        }}</Text>
                        <Text variant="p" textWeight="400"
                          >Coverage: £{{
                            formatSum(insurance?.metadata?.sum)
                          }}</Text
                        >
                        <Text variant="p" textWeight="400"
                          >Expiry:
                          {{
                            formatDate(insurance?.metadata?.expiryDate)
                          }}</Text
                        >
                      </div>
                    </template>
                  </GreyItemCard>
                </template>
                <div class="more__items">
                  <span>+2</span>
                  <span>more Insurances</span>
                </div>
              </div>
            </div>
            <div
              v-if="activeUserWorkstation?.name === 'Default'"
              class="tw-border-0 tw-border-[#C5D2F2] tw-border-t tw-border-dashed tw-pt-4 tw-box-border tw-w-full tw-flex tw-items-center tw-justify-end"
            >
              <div
                class="tw-flex tw-gap-2 tw-items-center tw-cursor-pointer"
                @click="onClickAddInsurance"
              >
                <Text variant="p" textColor="#FFA500"
                  >Add Insurance Details</Text
                >
                <v-icon icon="mdi-chevron-right" color="#FFA500"></v-icon>
              </div>
            </div>
            <div v-else class="tw-h-full insurance">
              <div
                class="insurances-list"
                v-if="userInsurances?.length && !insuranceLoading"
              >
                <template
                  v-for="insurance in userInsurances.slice(0, 2)"
                  :key="insurance.id"
                >
                  <GreyItemCard>
                    <template #status>
                      <div class="tw-flex tw-items-center tw-gap-2">
                        <PendingChip
                          class="tw-w-[100px]"
                          v-if="
                            insurance?.userVerificationStatus ===
                              UserVerificationStatusEnum.PENDING_VERIFICATION &&
                            !isExpiredDate(insurance?.metadata?.expiryDate)
                          "
                        />
                        <AcceptChip
                          class="tw-w-[100px]"
                          label="Accepted"
                          v-if="
                            insurance?.userVerificationStatus ===
                              UserVerificationStatusEnum.VERIFIED &&
                            !isExpiredDate(insurance?.metadata?.expiryDate)
                          "
                        />
                        <RejectChip
                          class="tw-w-[100px]"
                          label="Rejected"
                          v-if="
                            insurance?.userVerificationStatus ===
                              UserVerificationStatusEnum.REJECTED &&
                            !isExpiredDate(insurance?.metadata?.expiryDate)
                          "
                        />
                        <RejectChip
                          class="tw-w-[100px]"
                          label="Expired"
                          v-if="isExpiredDate(insurance?.metadata?.expiryDate)"
                        />
                      </div>
                    </template>
                    <template #action>
                      <div
                        class="tw-flex tw-gap-2 tw-items-center"
                        v-if="getUserWorkStationFieldAccess"
                      >
                        <PencilCircularBlueIcon
                          @click="onEditInsurance(insurance)"
                          class="!tw-min-h-[30px] !tw-min-w-[30px] tw-cursor-pointer"
                        />
                        <TrashCircularGrayIcon
                          class="tw-cursor-pointer"
                          @click="
                            onDeleteItem(
                              [],
                              insurance.id,
                              confirmDeleteInsuranceData.type,
                              confirmDeleteInsuranceData.label
                            )
                          "
                        />
                      </div>
                    </template>
                    <template #content>
                      <div class="tw-flex tw-flex-col tw-items-start tw-gap-1">
                        <Text variant="h6">{{
                          insurance?.metadata?.insuranceName ||
                          insurance?.insurance?.name
                        }}</Text>
                        <Text variant="p" textWeight="400"
                          >Coverage: £{{
                            formatSum(insurance?.metadata?.sum)
                          }}</Text
                        >
                        <Text variant="p" textWeight="400"
                          >Expiry:
                          {{
                            formatDate(insurance?.metadata?.expiryDate)
                          }}</Text
                        >
                      </div>
                    </template>
                  </GreyItemCard>
                </template>
                <div
                  class="tw-w-full tw-flex tw-justify-end"
                  v-if="userInsurances?.length > 2"
                >
                  <div
                    class="tw-border-solid tw-border-[1px] tw-border-[rgba(12,15,74,0.1)] tw-rounded-[20px] tw-bg-[rgba(12,15,74,0.05)] tw-flex tw-items-center tw-justify-center tw-py-1 tw-px-2 tw-box-border"
                  >
                    <Text variant="p" textColor="rgba(12,15,74,0.5)"
                      >+{{ userInsurances?.length - 2 }} more Insurance(s)</Text
                    >
                  </div>
                </div>
              </div>
              <div
                v-if="insuranceLoading"
                class="tw-w-full tw-h-full tw-flex tw-flex-col tw-justify-center tw-items-center"
              >
                <v-progress-circular
                  :size="50"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </div>

              <div
                class="tw-w-full tw-h-full tw-flex tw-items-center tw-flex-col tw-justify-center tw-gap-4"
                v-if="!userInsurances?.length && !insuranceLoading"
              >
                <div class="card__empty_img tw-w-[100px] tw-h-[100px]">
                  <img
                    :src="require('@/assets/images/insurance-empty-state.png')"
                    alt="icon"
                    class="tw-w-full tw-h-full tw-object-contain"
                  />
                </div>
                <Text
                  variants="p"
                  textWeight="400"
                  textColor="rgba(12, 15, 74, 0.5)"
                  >Your Insurance details will appear here</Text
                >
                <Button
                  v-if="getUserWorkStationFieldAccess"
                  @click="onClickAddInsurance"
                  variant="accent"
                  label="Add Insurances"
                  class="!tw-w-[auto]"
                />
              </div>
            </div>
            <div
              v-if="userInsurances?.length"
              class="tw-border-0 tw-border-[#C5D2F2] tw-border-t tw-border-dashed tw-pt-4 tw-box-border tw-w-full tw-flex tw-items-center tw-justify-end"
            >
              <div
                class="tw-flex tw-gap-2 tw-items-center tw-cursor-pointer"
                @click="onClickAddInsurance"
              >
                <Text variant="p" textColor="#FFA500">Manage Insurance</Text>
                <v-icon icon="mdi-chevron-right" color="#FFA500"></v-icon>
              </div>
            </div>
          </v-card>
        </div>

        <!-- Your Certifications -->
        <div class="second w-4 !tw-h-[570px]">
          <v-card class="custom-card certificate_wrapper">
            <div class="icon-title">
              <div class="icon-title__inner">
                <p class="common-title">Certifications</p>
              </div>
              <span class="icon-title-info">
                <ToolTip
                  description="Displaying Your Certifications in which you can  manage your trade Certifications."
                />
              </span>
            </div>
            <div
              v-if="activeUserWorkstation?.name === 'Default'"
              class="certificates tw-h-full"
            >
              <div
                class="card__list tw-flex tw-flex-col tw-gap-2 tw-pb-4 tw-w-full tw-max-w-full"
              >
                <template
                  v-for="certificate in userDefaultCertificates.slice(0, 2)"
                  :key="certificate?.id"
                >
                  <GreyItemCard>
                    <template #status>
                      <div class="tw-flex tw-items-center tw-gap-2">
                        <PendingChip
                          class="tw-w-[100px]"
                          v-if="
                            certificate?.userVerificationStatus ===
                            UserVerificationStatusEnum.PENDING_VERIFICATION
                          "
                        />
                        <AcceptChip
                          class="tw-w-[100px]"
                          label="Accepted"
                          v-if="
                            certificate?.userVerificationStatus ===
                            UserVerificationStatusEnum.VERIFIED
                          "
                        />
                        <RejectChip
                          class="tw-w-[100px]"
                          label="Rejected"
                          v-if="
                            certificate?.userVerificationStatus ===
                            UserVerificationStatusEnum.REJECTED
                          "
                        />
                      </div>
                    </template>
                    <template #action>
                      <div class="tw-flex tw-gap-2 tw-items-center">
                        <PencilCircularBlueIcon
                          @click="onEditCertificate(certificate)"
                          class="!tw-min-h-[30px] !tw-min-w-[30px] tw-cursor-pointer"
                        />
                        <TrashCircularGrayIcon
                          class="tw-cursor-pointer"
                          @click="
                            onDeleteItem(
                              certificate.assignedTo,
                              certificate.id,
                              confirmDeleteCertificateData.type,
                              confirmDeleteCertificateData.label
                            )
                          "
                        />
                      </div>
                    </template>
                    <template #content>
                      <div class="tw-w-full tw-flex tw-flex-col tw-items-start">
                        <div
                          class="tw-w-full tw-flex tw-items-start tw-gap-2 tw-pb-4 tw-box-border"
                        >
                          <ServiceIconWrapper
                            bgColor="#0C0F4A"
                            filter="invert(100%)
                      brightness(100%) saturate(0%)"
                          />
                          <div class="tw-flex tw-flex-col tw-items-start">
                            <h6
                              class="tw-text-base tw-font-bold tw-leading-[18px] tw-tracking-[0.4px] tw-text-[#0C0F4A] tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap tw-max-w-[360px] md:tw-max-w-[580px] sm:tw-max-w-[200px]"
                            >
                              {{ certificate?.certificate?.name }}
                            </h6>

                            <!-- <Text variant="h6">{{
                              certificate?.certificate?.name
                            }}</Text> -->
                            <Text
                              variant="p"
                              textWeight="400"
                              v-if="certificate?.metadata?.registrationNumber"
                            >
                              Membership No:
                              {{
                                certificate?.metadata?.registrationNumber
                              }}</Text
                            >
                          </div>
                        </div>
                        <div
                          v-if="certificate?.attachment?.length"
                          class="tw-pt-2 tw-box-border tw-border-t-dashed-custom tw-w-full tw-flex tw-gap-2 tw-items-center tw-justify-start"
                        >
                          <PdfIconGray />
                          <p
                            class="tw-text-sm tw-font-bold tw-leading-[18px] tw-tracking-[0.4px] tw-text-[#0C0F4A] tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap tw-max-w-[350px]"
                          >
                            {{ certificate?.attachment[0]?.originalName }}
                          </p>
                          <!-- <Text variant="p" textWeight="400">
                            {{ certificate?.attachment[0]?.originalName }}</Text
                          > -->
                        </div>
                      </div>
                    </template>
                  </GreyItemCard>
                </template>
              </div>
            </div>
            <div
              v-if="
                activeUserWorkstation?.name === 'Default' &&
                userDefaultCertificates?.length
              "
              class="tw-border-0 tw-border-[#C5D2F2] tw-border-t tw-border-dashed tw-pt-4 tw-box-border tw-w-full tw-flex tw-items-center tw-justify-end"
            >
              <div
                class="tw-flex tw-gap-2 tw-items-center tw-cursor-pointer"
                @click="onClickManageCertifications"
              >
                <Text variant="p" textColor="#FFA500">Manage Certificates</Text>
                <v-icon icon="mdi-chevron-right" color="#FFA500"></v-icon>
              </div>
            </div>
            <div
              v-else
              class="tw-h-full certificates tw-flex tw-justify-between tw-items-center tw-flex-col tw-overflow-auto"
            >
              <div
                class="card__list tw-flex tw-flex-col tw-gap-2 tw-pb-4 tw-w-full tw-max-w-full"
                v-if="userCertificates?.length && !certificateLoading"
              >
                <template
                  v-for="certificate in userCertificates.slice(0, 2)"
                  :key="certificate?.id"
                >
                  <GreyItemCard>
                    <template #status>
                      <div class="tw-flex tw-items-center tw-gap-2">
                        <PendingChip
                          class="tw-w-[100px]"
                          v-if="
                            certificate?.userVerificationStatus ===
                            UserVerificationStatusEnum.PENDING_VERIFICATION
                          "
                        />
                        <AcceptChip
                          class="tw-w-[100px]"
                          label="Accepted"
                          v-if="
                            certificate?.userVerificationStatus ===
                            UserVerificationStatusEnum.VERIFIED
                          "
                        />
                        <RejectChip
                          class="tw-w-[100px]"
                          label="Rejected"
                          v-if="
                            certificate?.userVerificationStatus ===
                            UserVerificationStatusEnum.REJECTED
                          "
                        />
                      </div>
                    </template>
                    <template #action>
                      <div
                        class="tw-flex tw-gap-2 tw-items-center"
                        v-if="getUserWorkStationFieldAccess"
                      >
                        <PencilCircularBlueIcon
                          @click="onEditCertificate(certificate)"
                          class="!tw-min-h-[30px] !tw-min-w-[30px] tw-cursor-pointer"
                        />
                        <TrashCircularGrayIcon
                          class="tw-cursor-pointer"
                          @click="
                            onDeleteItem(
                              certificate.assignedTo,
                              certificate.id,
                              confirmDeleteCertificateData.type,
                              confirmDeleteCertificateData.label
                            )
                          "
                        />
                      </div>
                    </template>
                    <template #content>
                      <div class="tw-w-full tw-flex tw-flex-col tw-items-start">
                        <div
                          class="tw-w-full tw-flex tw-items-start tw-gap-2 tw-pb-4 tw-box-border"
                        >
                          <ServiceIconWrapper
                            bgColor="#0C0F4A"
                            filter="invert(100%)
                      brightness(100%) saturate(0%)"
                          />
                          <div class="tw-flex tw-flex-col tw-items-start">
                            <h6
                              class="tw-text-base tw-font-bold tw-leading-[18px] tw-tracking-[0.4px] tw-text-[#0C0F4A] tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap tw-max-w-[360px] 2xl:tw-max-w-[300px] md:tw-max-w-[580px] sm:tw-max-w-[200px]"
                            >
                              {{ certificate?.certificate?.name }}
                            </h6>

                            <Text
                              variant="p"
                              textWeight="400"
                              v-if="certificate?.metadata?.registrationNumber"
                            >
                              Membership No:
                              {{
                                certificate?.metadata?.registrationNumber
                              }}</Text
                            >
                          </div>
                        </div>
                        <div
                          v-if="certificate?.attachment?.length"
                          class="tw-pt-2 tw-box-border tw-border-t-dashed-custom tw-w-full tw-flex tw-gap-2 tw-items-center tw-justify-start"
                        >
                          <PdfIconGray />
                          <p
                            class="tw-text-sm tw-font-bold tw-leading-[18px] tw-tracking-[0.4px] tw-text-[#0C0F4A] tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap tw-max-w-[360px]"
                          >
                            {{ certificate?.attachment[0]?.originalName }}
                          </p>
                          <!-- <Text variant="p" textWeight="400">
                            {{ certificate?.attachment[0]?.originalName }}</Text
                          > -->
                        </div>
                      </div>
                    </template>
                  </GreyItemCard>
                </template>
                <div
                  class="tw-w-full tw-flex tw-justify-end"
                  v-if="userCertificates?.length > 2"
                >
                  <div
                    class="tw-border-solid tw-border-[1px] tw-border-[rgba(12,15,74,0.1)] tw-rounded-[20px] tw-bg-[rgba(12,15,74,0.05)] tw-flex tw-items-center tw-justify-center tw-py-1 tw-px-2 tw-box-border"
                  >
                    <Text variant="p" textColor="rgba(12,15,74,0.5)"
                      >+{{ userCertificates?.length - 2 }} more
                      Certificate(s)</Text
                    >
                  </div>
                </div>
              </div>
              <div
                v-if="certificateLoading"
                class="tw-w-full tw-h-full tw-flex tw-flex-col tw-justify-center tw-items-center"
              >
                <v-progress-circular
                  :size="50"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </div>
              <div
                class="tw-w-full tw-h-full tw-flex tw-items-center tw-flex-col tw-justify-center tw-gap-4"
                v-if="!userCertificates?.length && !certificateLoading"
              >
                <div class="card__empty_img tw-w-[100px] tw-h-[100px]">
                  <img
                    :src="require('@/assets/images/certificates-empty.png')"
                    alt="icon"
                    class="tw-w-full tw-h-full tw-object-contain"
                  />
                </div>
                <Text
                  variants="p"
                  textWeight="400"
                  textColor="rgba(12, 15, 74, 0.5)"
                  >Your Certificates will appear here</Text
                >
                <Button
                  v-if="getUserWorkStationFieldAccess"
                  @click="onClickManageCertifications"
                  variant="accent"
                  label="Add Certificates"
                  class="!tw-w-[auto]"
                />
              </div>
            </div>
            <div
              v-if="userCertificates?.length"
              class="tw-border-0 tw-border-[#C5D2F2] tw-border-t tw-border-dashed tw-pt-4 tw-box-border tw-w-full tw-flex tw-items-center tw-justify-end"
            >
              <div
                class="tw-flex tw-gap-2 tw-items-center tw-cursor-pointer"
                @click="onClickManageCertifications"
              >
                <Text variant="p" textColor="#FFA500">Manage Certificates</Text>
                <v-icon icon="mdi-chevron-right" color="#FFA500"></v-icon>
              </div>
            </div>
          </v-card>
        </div>
      </v-row>

      <!-- Description -->
      <v-row class="trade-passport-row row-1">
        <div class="second tw-w-full">
          <v-card class="custom-card description_wrapper">
            <div class="icon-title tw-m-0">
              <div class="icon-title__inner">
                <p class="common-title">Description</p>
              </div>
              <span class="icon-title-info">
                <ToolTip
                  description="Showing your Description and you can edit your Description"
                />
              </span>
            </div>
            <div v-if="activeUserWorkstation?.name === 'Default'">
              <div class="editor_text" v-if="userDefaultDescriptionData">
                <span v-html="userDefaultDescriptionData"></span>
              </div>
              <div
                class="tw-border-0 tw-border-[#C5D2F2] tw-border-t tw-border-dashed tw-pt-4 tw-box-border tw-w-full tw-flex tw-items-center tw-justify-end"
              >
                <div
                  class="tw-flex tw-gap-2 tw-items-center tw-cursor-pointer"
                  @click="onEditDescription(userDescriptionData)"
                >
                  <Text variant="p" textColor="#FFA500">{{
                    userDefaultDescriptionData
                      ? "Edit Description"
                      : "Add Description"
                  }}</Text>
                  <v-icon icon="mdi-chevron-right" color="#FFA500"></v-icon>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="editor_text" v-if="userDescriptionData">
                <span v-html="userDescriptionData"></span>
              </div>
              <div
                class="tw-w-full tw-h-full tw-flex tw-items-center tw-flex-col tw-justify-center tw-gap-4"
                v-else
              >
                <div class="card__empty_img tw-w-[100px] tw-h-[100px]">
                  <img
                    :src="require('@/assets/images/description-empty.png')"
                    alt="icon"
                    class="tw-w-full tw-h-full tw-object-contain"
                  />
                </div>
                <Text
                  variants="p"
                  textWeight="400"
                  textColor="rgba(12, 15, 74, 0.5)"
                  >Your Description will appear here</Text
                >
                <Button
                  v-if="getUserWorkStationFieldAccess"
                  @click="onEditDescription(userDescriptionData)"
                  variant="accent"
                  label="Add Description"
                  class="!tw-w-[auto]"
                />
              </div>

              <div
                v-if="userDescriptionData && getUserWorkStationFieldAccess"
                class="tw-border-0 tw-border-[#C5D2F2] tw-border-t tw-border-dashed tw-pt-4 tw-box-border tw-w-full tw-flex tw-items-center tw-justify-end"
              >
                <div
                  class="tw-flex tw-gap-2 tw-items-center tw-cursor-pointer"
                  @click="onEditDescription(userDescriptionData)"
                >
                  <Text variant="p" textColor="#FFA500">{{
                    userDescriptionData ? "Edit Description" : "Add Description"
                  }}</Text>
                  <v-icon icon="mdi-chevron-right" color="#FFA500"></v-icon>
                </div>
              </div>
            </div>
          </v-card>
        </div>
      </v-row>

      <!-- Gallery -->
      <v-row class="trade-passport-row row-1 md:!tw-pb-16 md:!tw-box-border">
        <div class="second !tw-min-h-[300px] tw-w-full">
          <v-card class="custom-card description_wrapper">
            <div class="icon-title">
              <div class="icon-title__inner">
                <p class="common-title">Gallery</p>
              </div>
              <span class="icon-title-info">
                <ToolTip
                  description="Your Gallery photos are shows here.and you can  manage your gallery"
                />
              </span>
            </div>
            <div
              v-if="activeUserWorkstation?.name === 'Default'"
              class="tw-flex tw-flex-col tw-justify-between tw-items-start tw-h-full gallery"
            >
              <div
                class="gallery-content-container tw-gap-4 tw-grid tw-w-full tw-pb-4 tw-max-h-[342px] tw-overflow-auto tw-min-h-[170px]"
                v-if="defaultFileList && defaultFileList?.length"
              >
                <template v-for="(file, index) in defaultFileList" :key="index">
                  <div class="gallery_item tw-relative">
                    <TrashCircularWhiteIcon
                      @click="onOpenDeleteConfirmationModal(file)"
                      class="tw-absolute tw-right-3 tw-top-3 tw-z-50 tw-cursor-pointer"
                    />
                    <div
                      v-if="file.docType === 'gallery'"
                      @click="onOpenPhotoPreviewModal(file)"
                      class="tw-flex tw-items-center tw-justify-center tw-min-h-[160px] tw-max-h-[160px] tw-overflow-hidden tw-rounded-lg tw-relative tw-bg-slate-200"
                    >
                      <!-- <TrashCircularWhiteIcon
                        @click="onOpenDeleteConfirmationModal(file)"
                        class="tw-absolute tw-right-3 tw-top-3 tw-z-50 tw-cursor-pointer"
                      /> -->
                      <img
                        class="tw-cursor-pointer tw-object-cover tw-rounded-lg"
                        width="100%"
                        height="100%"
                        :src="require(`@/assets/images/proof_work_modal_3.jpg`)"
                      />
                    </div>
                  </div>
                </template>
              </div>
              <div
                class="tw-border-0 tw-border-[#C5D2F2] tw-border-t tw-border-dashed tw-pt-4 tw-box-border tw-w-full tw-flex tw-items-center tw-justify-end"
              >
                <div
                  class="tw-flex tw-gap-2 tw-items-center tw-cursor-pointer"
                  @click="onClickManageGallery"
                >
                  <Text variant="p" textColor="#FFA500"> Manage Gallery </Text>
                  <v-icon icon="mdi-chevron-right" color="#FFA500"></v-icon>
                </div>
              </div>
            </div>
            <div
              v-else
              class="tw-flex tw-flex-col tw-justify-between tw-items-start tw-h-full gallery"
            >
              <div
                class="gallery-content-container tw-gap-4 tw-grid tw-w-full tw-pb-4 tw-max-h-[342px] tw-overflow-auto tw-min-h-[170px]"
                v-if="fileList && fileList?.length && !state.galleryIsLoading"
              >
                <template v-for="(file, index) in fileList" :key="index">
                  <div class="gallery_item tw-relative">
                    <TrashCircularWhiteIcon
                      v-if="getUserWorkStationFieldAccess"
                      @click="onOpenDeleteConfirmationModal(file)"
                      class="tw-absolute tw-right-3 tw-top-3 tw-z-50 tw-cursor-pointer"
                    />
                    <div
                      v-if="file.docType === 'gallery'"
                      @click="onOpenPhotoPreviewModal(file)"
                      class="tw-flex tw-items-center tw-justify-center tw-min-h-[160px] tw-max-h-[160px] tw-overflow-hidden tw-rounded-lg tw-relative tw-bg-slate-200"
                    >
                      <v-icon
                        @click="onOpenVideo(file)"
                        v-if="file.fileType === 'video/mp4'"
                        icon="mdi-play-circle"
                        color="#264FD5"
                        class="tw-absolute tw-right-[45%] tw-top-[45%] tw-z-50 tw-cursor-pointer"
                      ></v-icon>

                      <img
                        v-if="
                          file.attachmentSource && file.fileType === 'video/mp4'
                        "
                        class="tw-cursor-pointer"
                        :src="require(`@/assets/images/iknowa-thumbnail.png`)"
                      />
                      <v-img
                        v-else-if="
                          file.attachmentSource && file.fileType !== 'video/mp4'
                        "
                        class="tw-w-full tw-h-full tw-cursor-pointer"
                        :class="{
                          'tw-hidden':
                            file?.fileType !== 'image/jpeg' &&
                            file?.fileType !== 'image/png',
                        }"
                        aspect-ratio="16/9"
                        cover
                        :src="file?.attachmentSource"
                      ></v-img>
                      <v-img
                        v-else
                        class="tw-cursor-pointer"
                        :class="{
                          'tw-hidden':
                            file?.fileType !== 'image/jpeg' &&
                            file?.fileType !== 'image/png',
                        }"
                        :width="60"
                        :height="60"
                        :src="
                          require(`@/assets/images/project-placeholder.png`)
                        "
                      ></v-img>
                    </div>
                  </div>
                </template>
              </div>
              <div
                v-if="!fileList?.length && !state.galleryIsLoading"
                class="tw-w-full tw-h-full tw-flex tw-items-center tw-flex-col tw-justify-center tw-gap-4 tw-pb-4"
              >
                <div class="card__empty_img tw-w-[100px] tw-h-[100px]">
                  <img
                    :src="require('@/assets/images/gallery-empty.png')"
                    alt="icon"
                    class="tw-w-full tw-h-full tw-object-contain"
                  />
                </div>
                <Text
                  variants="p"
                  textWeight="400"
                  textColor="rgba(12, 15, 74, 0.5)"
                  >Your photos will appear here</Text
                >
                <Button
                  v-if="getUserWorkStationFieldAccess"
                  variant="accent"
                  label="Upload Photos"
                  class="!tw-w-[auto]"
                  @click="onClickManageGallery"
                />
              </div>
              <div
                v-if="state.galleryIsLoading"
                class="tw-w-full tw-h-full tw-flex tw-flex-col tw-justify-center tw-items-center"
              >
                <v-progress-circular
                  :size="50"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </div>
              <div
                v-if="userDescriptionData"
                class="tw-border-0 tw-border-[#C5D2F2] tw-border-t tw-border-dashed tw-pt-4 tw-box-border tw-w-full tw-flex tw-items-center tw-justify-end tw-mt-[auto]"
              >
                <div
                  class="tw-flex tw-gap-2 tw-items-center tw-cursor-pointer"
                  @click="onClickManageGallery"
                >
                  <Text variant="p" textColor="#FFA500">{{
                    userDescriptionData ? "Manage Gallery" : "Upload Photos"
                  }}</Text>
                  <v-icon icon="mdi-chevron-right" color="#FFA500"></v-icon>
                </div>
              </div>
            </div>
          </v-card>
        </div>
      </v-row>
    </div>
  </div>
  <v-alert
    v-if="state.alert"
    class="tw-absolute tw-bottom-[1rem] tw-right-0"
    transition="slide-y-transition"
    :title="state.alertConfig.title"
    :text="state.alertConfig.text"
    :type="state.alertType === 'success' ? state.alertType : 'error'"
  >
  </v-alert>

  <TradePassportCertification v-if="activeTab === 'Certifications'" />
  <TradePassportInsurance v-if="activeTab === 'Insurances'" />
  <TradePassportSpecialism v-if="activeTab === 'Services'" />
  <TradePassportGallery
    @onChangeGallery="onChangeGallery"
    v-if="activeTab === 'Gallery'"
  />
  <TradePassportJobs v-if="activeTab === 'Jobs'" />
  <TradePassportReviews v-if="activeTab === 'Reviews'" />
  <CertificationModal
    v-if="openedModel === modelName.CERTIFICATION"
    :userCertificateData="userCertificateData"
    @onClickCloseTab="onClickCloseModel"
  />
  <InsuranceModel
    v-if="openedModel === modelName.INSURANCE"
    @onClickCloseTab="onClickCloseModel"
    :isOuterEditInsurance="isOuterEditInsurance"
    :userInsuranceData="userInsuranceData"
  />
  <DescriptionEditorComponent
    v-if="openedModel === modelName.DESCRIPTION"
    @onClickCloseTab="onClickCloseModel"
    :isOuterEditDescription="isOuterEditDescription"
    :userDescriptionData="userDescriptionData"
    @userDescriptionData="userDescription"
    :HeaderTittle="HeaderTittle"
  />
  <TaxAndVatModel
    v-if="openedModel === modelName.TAXANDVAT"
    @onClickCloseTab="onClickCloseModel"
    mode="Edit"
  />
  <EditWorkStationDetailsPopup
    v-if="isShowEditWorkStationModal"
    @closeEditWorkStationModal="closeEditWorkStationModal"
  />
  <AddUserRatesModal
    v-if="isShowUserRateModal"
    @closeUpdateUserRatesModal="closeUpdateUserRatesModal"
  />
  <AddUserExperienceModal
    v-if="isShowUserExperienceModal"
    @closeUpdateUserExperienceModal="closeUpdateUserExperienceModal"
  />
  <TeamMemberModal
    v-if="openedModel === modelName.TEAMMEMBERS"
    @onClickCloseTab="onClickCloseModel"
  />

  <ConfirmDeleteModal
    v-if="isOpenConfirmDelete"
    :type="confirmDeleteType"
    :label="confirmDeleteLabel"
    @on-close="onCloseConfirmDelete"
    @on-delete="deleteItem"
  ></ConfirmDeleteModal>
  <ImportWorkStationModal
    v-if="isShowImportWorkStation"
    @onCloseImportWorkstationModal="onCloseImportWorkstationModal"
  />
  <SelectTeamMemberModal
    v-if="isShowSelectTeamMember"
    @onCloseModal="onCloseSelectTeam"
    @onClickContinue="onClickContinueSelectTeamMember"
  />
  <AssignModal
    v-if="isShowAssignModal"
    @onCloseModal="onCloseAssignModal"
    @onContinueButtonClick="onContinueButtonClick"
  />
  <ConfirmAssignModal
    v-if="isShowConfirmAssignModal"
    @onCloseConfirmModal="onCloseConfirmAssignModal"
    @onclickNo="onClickNoConfirmAssignModal"
  />

  <DocumentDeleteConfirmationModal
    v-if="modals.deleteConfirmation"
    @on-close="onCloseDeleteConfirmationModal"
    :selectedDocument="state.selectedDocument"
    deleteType="files"
    :isGallery="true"
    @on-success="onSuccess('delete')"
    @on-success-gallery="
      getTradePassportGallery($event, true, state.selectedDocument?.id)
    "
  />

  <GalleryPhotoPreview
    v-if="modals.photoPreview"
    :file="state.selectedDocument"
    @on-close="onClosePhotoPreviewModal"
  />

  <PageRestrictionNoticeModalTradePassport
    v-if="isShowWorkstationRestrictionModal"
    @on-close="toggleRestrictionModal"
  />
  <FreezeInsuranceExpired
    v-if="
      userInsurenceExperiedStatus && isShowFreezeModal && isWorkStationVerified
    "
    @on-close="onCloseFreezeModal"
  />
</template>

<script lang="ts">
import {
  PaymentPlanType,
  UserVerificationStatusEnum,
} from "@/core/enums/RolesEnum";
import { useRoute, useRouter } from "vue-router";
import { USER_STORE } from "@/store/modules/user";
import UserService from "@/core/services/user.service";
import { WORKSTATION } from "@/store/modules/workstation";
import { INSURANCE_STORE } from "@/store/modules/insurance";
import { onMounted, watch, computed, ref, reactive } from "vue";
import { useStore } from "vuex";
import TradePassportCertification from "../components/TradePassportCertification.vue";
import TradePassportInsurance from "../components/TradePassportInsurance.vue";
import TradePassportSpecialism from "../components/TradePassportSpecialism.vue";
import TradePassportJobs from "../components/TradePassportJobs.vue";
import TradePassportReviews from "../components/TradePassportReviews.vue";
import { BusinessDetailTypeEnum } from "@/core/enums/RolesEnum";
import InsuranceModel from "@/modules/insurance/components/InsuranceModel.vue";
// import ItemListCard from "@/core/components/ItemListCard.vue";
import DescriptionEditorComponent from "@/modules/dashboard/components/DescriptionEditorComponent.vue";
import { COURSE_ROUTE } from "@/modules/courses/routes";
import UserProfileLogo from "@/core/components/UserProfileLogo.vue";
import TaxAndVatModel from "@/modules/tax-and-vat/components/TaxAndVatModel.vue";
import { getImageStringToImageURL, isExpiredDate } from "@/core/utils/common";
import EditWorkStationDetailsPopup from "@/modules/trade-passport/components/EditWorkStationDetailsPopup.vue";
import AddUserRatesModal from "@/modules/trade-passport/components/AddUserRatesModal.vue";
import CertificationModal from "@/modules/certification/components/CertificationModel.vue";
import AddUserExperienceModal from "@/modules/trade-passport/components/AddUserExperienceModal.vue";
import TeamMemberModal from "@/modules/trade-passport/components/TeamMemberModal.vue";
// import SpecialismItem from "@/core/components/common/SpecialismItem.vue";
import SpecialismItemTwo from "@/core/components/common/SpecialismItemTwo.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import { MEMBER_STORE } from "@/store/modules/manage-member";
// import CommonChip from "@/modules/dashboard/components/Chips/CommonChip.vue";
import AcceptChip from "@/modules/dashboard/components/Chips/AcceptChip.vue";
import RejectChip from "@/modules/dashboard/components/Chips/RejectChip.vue";
import PendingChip from "@/modules/dashboard/components/Chips/PendingChip.vue";
import ProfileThumbnail from "@/core/uni-components/ProfileThumbnail.vue";
import ConfirmDeleteModal from "@/modules/dashboard/components/Modals/ConfirmDeleteModal.vue";
import ImportWorkStationModal from "@/modules/trade-passport/components/ImportWorkstationModal.vue";
import SelectTeamMemberModal from "@/modules/trade-passport/components/SelectTeamMemberModal.vue";
import AssignModal from "@/modules/trade-passport/components/AssignModal.vue";
import ConfirmAssignModal from "@/modules/trade-passport/components/ConfirmAssignModal.vue";
import Card from "@/core/components/ui/general/Card.vue";
import ChipState from "@/core/components/common/ChipState.vue";
import CheckGreenShield from "@/core/components/icons/CheckGreenShield.vue";
import ErrorRedIcon from "@/core/components/icons/ErrorRedIcon.vue";
import Postcode from "@/core/uni-components/Chips/Postcode.vue";
import CurrencyFilter from "@/filters/currency.filter";
import GreyItemCard from "@/modules/trade-passport/components/cards/GreyItemCard.vue";
import PencilCircularBlueIcon from "@/core/components/icons/PencilCircularBlueIcon.vue";
import TrashCircularGrayIcon from "@/core/components/icons/TrashCircularGrayIcon.vue";
import ServiceIconWrapper from "@/core/components/common/ServiceIconWrapper.vue";
import PdfIconGray from "@/core/components/icons/PdfIconGray.vue";
import TradePassportGallery from "@/modules/trade-passport/components/TradePassportGallery.vue";
import DocumentDeleteConfirmationModal from "@/modules/documents/components/modal/DocumentDeleteConfirmationModal.vue";
import { DOCUMENTS_STORE } from "@/store/modules/documents";
import TrashCircularWhiteIcon from "@/core/components/icons/TrashCircularWhiteIcon.vue";
import $axios from "@/core/utils/axios-api-config";
import { getImageApiUrl } from "@/core/utils/common";
import GalleryPhotoPreview from "@/modules/trade-passport/components/modals/GalleryPhotoPreview.vue";
import { DASHBOARD_ROUTE } from "@/modules/dashboard/routes";
import { UserRolesEnum } from "@/core/enums/RolesEnum";
import ToolTip from "@/core/components/common/ToolTip.vue";
import { TRADE_ESTIMATE_STORE } from "@/store/modules/trade-estimate";
import BackButton from "@/core/components/common/BackButton.vue";
import insurancesService from "@/core/services/insurances.service";
import FreezeInsuranceExpired from "@/modules/trade-passport/components/modals/FreezeInsuranceExpired.vue";
import PageRestrictionNoticeModalTradePassport from "@/core/components/modals/PageRestrictionNoticeModalTradePassport.vue";
import Loader from "@/core/components/common/Loader.vue";

export default {
  components: {
    // DashBoardWelcome,
    TradePassportCertification,
    TradePassportInsurance,
    TradePassportSpecialism,
    InsuranceModel,
    // ItemListCard,
    DescriptionEditorComponent,
    UserProfileLogo,
    TaxAndVatModel,
    EditWorkStationDetailsPopup,
    AddUserRatesModal,
    CertificationModal,
    AddUserExperienceModal,
    TeamMemberModal,
    // SpecialismItem,
    SpecialismItemTwo,
    Button,
    Text,
    // CommonChip,
    AcceptChip,
    RejectChip,
    PendingChip,
    ProfileThumbnail,
    ConfirmDeleteModal,
    ImportWorkStationModal,
    SelectTeamMemberModal,
    AssignModal,
    ConfirmAssignModal,
    Card,
    ChipState,
    CheckGreenShield,
    ErrorRedIcon,
    Postcode,
    GreyItemCard,
    TrashCircularGrayIcon,
    PencilCircularBlueIcon,
    ServiceIconWrapper,
    PdfIconGray,
    TradePassportGallery,
    DocumentDeleteConfirmationModal,
    TrashCircularWhiteIcon,
    GalleryPhotoPreview,
    ToolTip,
    BackButton,
    TradePassportJobs,
    TradePassportReviews,
    FreezeInsuranceExpired,
    PageRestrictionNoticeModalTradePassport,
    Loader,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const activeTab = ref("Overview");
    const openedModel = ref("");
    const modelName = {
      INSURANCE: "insurance",
      DESCRIPTION: "description",
      TAXANDVAT: "tax&vat",
      CERTIFICATION: "certification",
      TEAMMEMBERS: "TeamMember",
    };
    const initializing = ref(false);
    const insuranceLoading = ref(false);
    const specialismsLoading = ref(false);
    const certificateLoading = ref(false);
    const isOuterEditInsurance = ref(false);
    const userInsuranceData = ref(null) as any;
    const isOuterEditDescription = ref(false);
    const userDescriptionData = ref(null) as any;
    const HeaderTittle = ref("");
    const teamMemberList = ref();
    const modifiedMembers = ref([]) as any;
    const loadingImages = ref(false);

    const isShowAssignModal = ref(false);

    const isOpenConfirmDelete = ref(false);
    const confirmDeleteLabel = ref("");
    const confirmDeleteType = ref("");
    const confirmDeleteId = ref(null) as any;
    const isAvailable = ref();
    const isLoading = ref(false);
    const confirmDeleteCertificateData = ref({
      type: "certificate",
      label: "You're about to delete your certification.",
    });
    const confirmDeleteInsuranceData = ref({
      type: "insurance",
      label: "You're about to delete your insurance.",
    });
    const defaultUserSpecialisms = ref([
      {
        label: "Drainage Specialist",
        iconName: "drainage",
      },
      {
        label: "Extensions Specialist",
        iconName: "extensions",
      },
      {
        label: "Plumber",
        iconName: "plumbing",
      },
    ]);
    const userDefaultInsurances = ref([
      {
        id: 1,
        metadata: {
          sum: "789.00",
          expiryDate: "2024-07-05T05:31:57.552Z",
          insuranceName: "IBG (Insurance Backed Guarantee)",
        },
      },
      {
        id: 2,
        metadata: {
          sum: "123.00",
          expiryDate: "2024-07-09T08:53:27.601Z",
          insuranceName: "Read",
        },
      },
    ]);
    const userDefaultCertificates = ref([
      {
        id: 321,
        metadata: {
          url: "app.iknowa.com",
          registrationNumber: "74",
        },
        userVerificationStatus: "1",
        certificate: {
          id: 14,
          dateCreated: "2024-05-29T09:43:49.227Z",
          dateUpdated: "2024-06-12T09:04:30.771Z",
          name: "N/SVQ Level 2/3 in Heating and Ventilating (Domestic Installation) or equivalent earlier certification that provides evidence of competence",
          template:
            "N/SVQ Level 2/3 in Heating and Ventilating (Domestic Installation) or equivalent earlier certification that provides evidence of competence",
          recordOrder: 4,
        },
        attachment: [
          {
            id: 217,
            dateCreated: "2024-07-08T06:42:55.247Z",
            dateUpdated: "2024-07-08T06:42:55.247Z",
            attachment:
              "1305/certifications/321/1720420948170_75pgzuXaR2ho1.png",
            originalName: "2d-barcode.png",
          },
        ],
        assignedTo: [],
      },
      {
        id: 322,
        metadata: {
          url: "app.iknowa.com",
          registrationNumber: "747",
        },
        userVerificationStatus: "1",
        certificate: {
          id: 3,
          dateCreated: "2021-12-07T03:05:15.897Z",
          dateUpdated: "2024-06-12T09:04:30.771Z",
          name: "Trust Mark",
          template: "TrustMark",
          recordOrder: 14,
        },
        attachment: [],
        assignedTo: [],
      },
    ]);
    const userDefaultDescriptionData = ref(
      `<p>Hi I am Toby Sparks. I am Trade Contractor Sed odio tristique a libero. Habitant duis congue nunc etiam malesuada risus. Tellus rhoncus vel est arcu. Lorem ipsum dolor sit amet consectetur. Sed odio tristique a libero. Habitant duis congue nunc etiam malesuada risus. Tellus rhoncus vel est arcu.Ornare nisi sed odio id. Eget in vestibulum lorem lectus justo enim.</p>`
    );

    const defaultFileList = ref([
      {
        id: 1,
        isDir: false,
        parentId: null,
        name: "1305/documents/1721381399968_rt2qOuzoqDQH8.jpg",
        fileSize: 79987,
        fileType: "image/jpeg",
        originalName: "image.jpg",
        entity: null,
        docType: "gallery",
      },
    ]);
    const isShowWorkstationRestrictionModal = ref(false);
    const isShowUserRateModal = ref(false);
    const isShowFreezeModal = ref(true);
    const isShowUserExperienceModal = ref(false);

    const userCertificateData = ref(null) as any;

    const nationalInsuranceNumber = ref("");
    const isShowPassword = ref(false);

    const isShowImportWorkStation = ref(false);

    const isShowSelectTeamMember = ref(false);

    const isShowConfirmAssignModal = ref(false);

    const confirmDeleteAssignedMembers = ref(null) as any;

    const fileList = ref([]) as any;

    const tradeItems = ref([
      { name: "Overview", icon: "mdi-home" },
      { name: "Services", icon: "mdi-tools" },
      { name: "Certifications", icon: "mdi-file-table" },
      // { name: "Companies", icon: "mdi-text-box" },
      { name: "Insurances", icon: "mdi-shield-home" },
      { name: "Jobs", icon: "mdi-account-tie" },
      { name: "Reviews", icon: "mdi-star" },
      { name: "Gallery", icon: "mdi-image-multiple-outline" },
      // { name: "Recommendations", icon: "mdi-star-circle-outline" },
    ]);

    const preOpenTabs = ref([
      "Overview",
      "Services",
      "Certifications",
      "Insurances",
      "Gallery",
    ]);

    const modals = reactive({
      deleteConfirmation: false,
      photoPreview: false,
    });
    const state = reactive({
      selectedDocument: null as any,
      galleryIsLoading: false,

      alert: false,
      alertType: "",
      actionType: "",
      alertConfig: {
        text: "",
        title: "",
      },
    });

    const userAllWorkStation = computed(
      () => store.getters[`${WORKSTATION}/getUserWorkstations`]
    );
    const userWorkStationStatusInsurance = computed(
      () =>
        store.getters[`${WORKSTATION}/activeUserWorkstation`]
          ?.verificationStatus
    );

    const isWorkStationVerified = computed(() => {
      return (
        userWorkStationStatusInsurance.value ===
        UserVerificationStatusEnum.VERIFIED
      );
    });
    const isUserProprtyOwner = computed(
      () => user.value?.role?.name === UserRolesEnum.PROPERTY_OWNER
    );
    const isShowEditWorkStationModal = ref(false);
    const contractorworkStationImage = ref("");
    const userWorkStationImage = computed(() => {
      if (!isUserProprtyOwner.value) {
        return store.getters[`${USER_STORE}/userWorkStationImage`];
      } else {
        return "";
      }
    });

    const activeUserWorkstation = computed(() => {
      if (isUserProprtyOwner.value) {
        return store.getters[
          `${TRADE_ESTIMATE_STORE}/getContractorTradePassportDetail`
        ];
      } else {
        return store.getters[`${WORKSTATION}/activeUserWorkstation`];
      }
    });
    const userInsurenceExperiedStatus = computed(
      () => store.getters[`${INSURANCE_STORE}/getIsInsuranceExpired`]
    );

    const userWorkStationStatus = computed(() => {
      if (isUserProprtyOwner.value) {
        return store.getters[
          `${TRADE_ESTIMATE_STORE}/getContractorTradePassportDetail`
        ]?.verificationStatus;
      } else {
        return store.getters[`${WORKSTATION}/activeUserWorkstation`]
          ?.verificationStatus;
      }
    });

    const isItemDisable = (itemName: string) => {
      if (isPersonalAccountWorkStation.value) {
        if (preOpenTabs.value.includes(itemName)) {
          if (itemName === "Overview") {
            return false;
          }
          return true;
        } else {
          return true;
        }
      } else {
        if (preOpenTabs.value.includes(itemName)) {
          return false;
        } else if (
          userWorkStationStatus.value !== UserVerificationStatusEnum.VERIFIED
        ) {
          return true;
        } else {
          return false;
        }
      }
    };
    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const userInsurances = computed(
      () => store.getters[`${USER_STORE}/userInsurances`]
    );

    const userCertificates = computed(
      () => store.getters[`${USER_STORE}/userCertificates`]
    );

    const userFullName = computed(() => {
      return `${user.value?.firstName} ${user.value?.lastName}`;
    });
    const userSpecialisms = computed(
      () => store.getters[`${USER_STORE}/userSpecialisms`]
    );
    const getUserWorkStationFieldAccess = computed(
      () => store.getters[`${WORKSTATION}/getUserWorkStationFieldAccess`]
    );
    const isUserWorkStationAdmin = computed(
      () => store.getters[`${WORKSTATION}/isUserWorkStationAdmin`]
    );
    const filterUserSpecialisms = computed(() => {
      const ids = [] as any;
      const categoryList = [] as any;
      for (const item of userSpecialisms.value || []) {
        const { category, subCategories } = item as any;
        const categoryId = category.id;
        const label = category.poLabel ? category.tpLabel : "";
        const iconName = category.iconName;

        if (!ids.includes(categoryId)) {
          ids.push(categoryId);
          categoryList.push({ id: categoryId, label, iconName, subCategories });
        } else {
          categoryList
            .find((value: any) => value.id === categoryId)
            ?.subCategories.push(...subCategories);
        }
      }

      return categoryList || [];
    });

    const hasData = computed(() => {
      if (
        (activeUserWorkstation?.value?.user?.userBusinessDetails
          ?.nationalInsuranceNumber !== null ||
          activeUserWorkstation?.value?.user?.userBusinessDetails
            ?.nationalInsuranceNumber !== "") &&
        activeUserWorkstation?.value?.user?.userBusinessDetails?.ukVatNumber !==
          null &&
        activeUserWorkstation?.value?.user?.userBusinessDetails?.ukVatNumber !==
          ""
      ) {
        return true;
      }
      return false;
    });

    watch(
      () => user,
      (newUserDetails, old) => {
        nationalInsuranceNumber.value =
          user?.value.userBusinessDetails?.nationalInsuranceNumber;
      },
      { deep: true, immediate: true }
    );

    const updateAvailableStatus = async () => {
      try {
        if (activeUserWorkstation.value?.id) {
          await UserService.updateUserWorkstation(
            user.value?.id,
            activeUserWorkstation.value?.id,
            {
              isAvailable: isAvailable.value,
            }
          );
        }
      } catch (error) {
        console.log("error", error);
      }
    };

    watch(
      () => isAvailable.value,
      (newValue, old) => {
        if (!isUserProprtyOwner.value) {
          updateAvailableStatus();
        }
      },
      { deep: true, immediate: true }
    );
    const onCloseFreezeModal = () => {
      isShowFreezeModal.value = false;
    };
    const onOpenDeleteConfirmationModal = (document: any) => {
      if (isPersonalAccountWorkStation.value) return toggleRestrictionModal();
      state.selectedDocument = document;
      modals.deleteConfirmation = true;
    };

    const onCloseDeleteConfirmationModal = () => {
      state.selectedDocument = null;
      modals.deleteConfirmation = false;
    };

    const onOpenPhotoPreviewModal = (file: any) => {
      if (isPersonalAccountWorkStation.value) return toggleRestrictionModal();
      if (file?.fileType === "video/mp4") return;
      state.selectedDocument = file;
      modals.photoPreview = true;
    };

    const onClosePhotoPreviewModal = () => {
      state.selectedDocument = null;
      modals.photoPreview = false;
    };

    const onOpenVideo = (file: any) => {
      // Check if the file has an attachmentSource property
      if (file && file.attachmentSource) {
        // Open the video in a new tab
        window.open(file.attachmentSource, "_blank");
      } else {
        console.error("Invalid file or missing attachmentSource");
      }
    };

    const getTradePassportGallery = async (
      event = {} as any,
      isActionDelete = false,
      id = null as any
    ) => {
      state.galleryIsLoading = true;
      try {
        if (!isActionDelete) {
          const userId = user.value.id;
          if (isUserProprtyOwner.value) {
            fileList.value = await store.dispatch(
              `${DOCUMENTS_STORE}/getContractorTradePassportGallery`,
              {
                workStationId: contractorTradePassportWorkstationId.value,
                userId: user.value.id,
              }
            );
          } else {
            fileList.value = await store.dispatch(
              `${DOCUMENTS_STORE}/getTradePassportGallery`,
              userId
            );
          }

          fileList.value = fileList.value.map((file: any) => {
            return { ...file, name: file.originalName, attachment: file.name };
          });

          const attachmentPromises = fileList.value.map(
            async (currentFile: any) => {
              const { attachment } = currentFile;
              const imageUrl = getImageApiUrl(attachment, true);
              const encoded: any = await $axios.get(imageUrl);
              const publicURL = encoded?.publicUrl;
              return { ...currentFile, attachmentSource: publicURL };
            }
          );

          // Wait for all attachment promises to resolve
          fileList.value = await Promise.all(attachmentPromises);
        } else {
          if (fileList.value?.length) {
            fileList.value = fileList.value.filter(
              (file: any) => file.id !== id
            );
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        state.galleryIsLoading = false;
      }
    };

    const onSuccess = async (event: any) => {
      state.alertType = "success";
      state.actionType = event;
      state.alert = true;
      state.alertConfig = {
        text:
          state.actionType === "edit"
            ? "Edited Successfully"
            : "Deleted Successfully",
        title: "Successful",
      };
    };

    const hideAlert = () => {
      setTimeout(() => {
        state.alert = false;
      }, 3000);
    };

    watch(
      () => state.alert,
      (newValue, oldValue) => {
        if (newValue) {
          hideAlert();
        }
      },
      { deep: true, immediate: true }
    );

    const onDeleteInsurance = async (insuranceId: string) => {
      try {
        await store.dispatch(`${USER_STORE}/removeUserInsurance`, {
          userId: user.value.id,
          userInsuranceId: insuranceId,
        });
        if (userInsurances.value.length === 0 && isWorkStationVerified.value) {
          const responce: any = await insurancesService.isInsuranceExpired(
            user.value.id
          );
          if (responce.isFreezed) {
            store.commit(`${INSURANCE_STORE}/setInsuranceExpired`, true);
          }
        }
        await store.dispatch(`${USER_STORE}/getAccountStatuses`);
      } catch (error) {
        console.log();
      }
    };
    const onEditInsurance = async (insurance: object) => {
      if (isPersonalAccountWorkStation.value) return toggleRestrictionModal();
      userInsuranceData.value = insurance;
      isOuterEditInsurance.value = true;
      openedModel.value = modelName.INSURANCE;
    };

    const onEditDescription = async (description: string) => {
      if (isPersonalAccountWorkStation.value) return toggleRestrictionModal();
      userDescriptionData.value = description;
      isOuterEditDescription.value = true;
      HeaderTittle.value = userDescriptionData.value ? "Edit" : "Add";
      openedModel.value = modelName.DESCRIPTION;
    };

    const onClickCloseModel = async () => {
      openedModel.value = "";
      getTeamMember();
    };

    const userDescription = async (value: any) => {
      userDescriptionData.value = value;
      await store.dispatch(`${WORKSTATION}/activateWorkstationDescription`, {
        userId: user.value?.id,
        userWorkstationId: activeUserWorkstation.value?.id,
        description: userDescriptionData.value,
      });
    };

    const onClickManageSpecialism = () => {
      if (activeUserWorkstation.value?.name === "Default") {
        toggleRestrictionModal();
      } else {
        activeTab.value = "Services";
      }
    };

    const onClickAddInsurance = () => {
      if (isPersonalAccountWorkStation.value) return toggleRestrictionModal();
      activeTab.value = "Insurances";
    };

    const onClickManageCertifications = () => {
      if (isPersonalAccountWorkStation.value) return toggleRestrictionModal();
      activeTab.value = "Certifications";
    };
    const onClickManageGallery = () => {
      if (isPersonalAccountWorkStation.value) return toggleRestrictionModal();
      activeTab.value = "Gallery";
    };

    watch(
      () => activeUserWorkstation.value,
      (newValue, oldValue) => {
        userDescriptionData.value = activeUserWorkstation.value?.description;
        isAvailable.value = activeUserWorkstation.value?.isAvailable;
      },
      { immediate: true }
    );

    const formatNumberWithCommas = (number: any) => {
      const numericValue =
        typeof number === "number" ? number : parseFloat(number);
      return numericValue.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    };

    const navigateToCourse = () => {
      router.push({ name: COURSE_ROUTE });
    };
    const onClickEditVatAndTax = () => {
      openedModel.value = modelName.TAXANDVAT;
    };

    const activeWorkStationType = (businessType: string) => {
      if (isUserProprtyOwner.value) {
        return activeUserWorkstation.value?.user?.userBusinessDetails?.type ===
          BusinessDetailTypeEnum.LIMITED_COMPANY
          ? "LTD Company"
          : "Sole Trader";
      }
      return businessType === BusinessDetailTypeEnum.LIMITED_COMPANY
        ? "LTD Company"
        : "Sole Trader";
    };

    const workstationCreatedDate = (date: any) => {
      const wsDate = new Date(date);

      const day = wsDate.getDate();
      const month = wsDate.toLocaleString("en-US", { month: "long" });
      const year = wsDate.getFullYear();

      return `${day} ${month} ${year}`;
    };

    const workstationAddress = (type: string) => {
      if (activeUserWorkstation.value?.user) {
        let address: string;
        if (type === BusinessDetailTypeEnum.LIMITED_COMPANY) {
          const businessAddress =
            activeUserWorkstation.value?.user?.userBusinessDetails?.businessAddress?.replace(
              activeUserWorkstation.value?.user?.userBusinessDetails
                ?.businessPostcode,
              ""
            );
          address = businessAddress?.replace(/,([^,]*)$/, ".$1");
        } else if (
          activeUserWorkstation.value?.user?.userBusinessDetails
            ?.residencyAddressLookup
        ) {
          const residencyAddressLookup =
            activeUserWorkstation.value?.user?.userBusinessDetails?.residencyAddressLookup?.replace(
              activeUserWorkstation.value?.user?.userBusinessDetails
                ?.residencyPostcode,
              ""
            );
          address = residencyAddressLookup?.replace(/,([^,]*)$/, ".$1");
        } else {
          address = `${activeUserWorkstation.value?.user?.userBusinessDetails?.residencyAddressLine} ${activeUserWorkstation.value?.user?.userBusinessDetails?.residencyTown}`;
        }
        return address;
      } else {
        return null;
      }
    };

    const workstationPostcode = (type: string) => {
      if (activeUserWorkstation.value?.user) {
        return type === BusinessDetailTypeEnum.LIMITED_COMPANY
          ? activeUserWorkstation.value?.user?.userBusinessDetails
              ?.businessPostcode
          : activeUserWorkstation.value?.user?.userBusinessDetails
              ?.residencyPostcode;
      } else {
        return null;
      }
    };

    const onClickEditWorkStation = () => {
      if (isPersonalAccountWorkStation.value) {
        toggleRestrictionModal();
      } else {
        isShowEditWorkStationModal.value = true;
      }
    };

    const closeEditWorkStationModal = async () => {
      isShowEditWorkStationModal.value = false;
      await store.dispatch(`${USER_STORE}/initializeUserState`);
    };

    const onClickUpdateUserRates = () => {
      if (isPersonalAccountWorkStation.value) {
        toggleRestrictionModal();
      } else {
        isShowUserRateModal.value = true;
      }
    };

    const closeUpdateUserRatesModal = () => {
      isShowUserRateModal.value = false;
    };

    const activeWorkStationSubscription = computed(
      () => activeUserWorkstation.value?.subscription
    );

    const getWorkStationPlanType = computed(() => {
      if (
        activeWorkStationSubscription.value?.metadata?.planType ==
        PaymentPlanType.MONTHLY
      ) {
        return "Monthly";
      } else if (
        activeWorkStationSubscription.value?.metadata?.planType ==
        PaymentPlanType.YEARLY
      ) {
        return "Yearly";
      } else {
        return "";
      }
    });

    const onDeleteItem = (
      assignedTo: any,
      id: any,
      type: string,
      label: string
    ) => {
      if (isPersonalAccountWorkStation.value) return toggleRestrictionModal();
      confirmDeleteId.value = id;
      confirmDeleteLabel.value = label;
      confirmDeleteType.value = type;
      isOpenConfirmDelete.value = true;
      confirmDeleteAssignedMembers.value = assignedTo.map(
        (member: any) => member.id
      );
    };

    const onCloseConfirmDelete = () => {
      confirmDeleteId.value = null;
      confirmDeleteLabel.value = "";
      confirmDeleteType.value = "";
      isOpenConfirmDelete.value = false;
    };

    const deleteCertificate = async (certificateId: number, members: any) => {
      try {
        await store.dispatch(`${USER_STORE}/removeUserCertificates`, {
          userId: user?.value?.id,
          userCertificateId: certificateId,
        });

        // Delete related Members
        let payload = {
          teamMemberIds: members,
        };

        if (members?.length) {
          await store.dispatch(
            `${USER_STORE}/removeCertificateRelatedMembers`,
            {
              userId: user?.value?.id,
              teamMemberIds: payload,
            }
          );
        }

        await store.dispatch(`${USER_STORE}/getAccountStatuses`);
      } catch (error) {
        console.log();
      }
    };

    const deleteItem = async (e: any) => {
      if (e === confirmDeleteCertificateData.value.type) {
        await deleteCertificate(
          confirmDeleteId.value,
          confirmDeleteAssignedMembers.value
        );
        await onCloseConfirmDelete();
      }
      if (e === confirmDeleteInsuranceData.value.type) {
        await onDeleteInsurance(confirmDeleteId.value);
        await onCloseConfirmDelete();
      }
    };

    const onClickManageTeam = () => {
      openedModel.value = modelName.TEAMMEMBERS;
    };

    const onEditCertificate = (certificate: any) => {
      if (isPersonalAccountWorkStation.value) return toggleRestrictionModal();
      openedModel.value = modelName.CERTIFICATION;
      userCertificateData.value = certificate;
    };

    const subscriptionType = (type: any) => {
      let plan = "";
      if (type) {
        if (type.subscription) {
          const subscription = JSON.parse(type?.subscription);
          const planType = subscription.metadata.planType;

          if (planType === PaymentPlanType.MONTHLY.toString()) {
            plan = "Monthly";
          }
          if (planType === PaymentPlanType.YEARLY.toString()) {
            plan = "Yearly";
          }
        }
      }

      return plan;
    };

    const onClickUpdateUserExperience = () => {
      isShowUserExperienceModal.value = true;
    };
    const closeUpdateUserExperienceModal = () => {
      isShowUserExperienceModal.value = false;
    };

    const getTeamMember = async () => {
      try {
        const projectDataResponse = await store.dispatch(
          `${MEMBER_STORE}/getTeamMember`,
          {
            userId: user.value?.id,
          }
        );
        teamMemberList.value = projectDataResponse;
      } catch (err) {
        console.log();
      }
    };

    const getWorkstationProfile = async (profileImage: string) => {
      if (!profileImage) {
        return null;
      }
      try {
        const imagePath = profileImage;
        const response = await store.dispatch(
          `${USER_STORE}/getImage`,
          imagePath
        );
        if (response) {
          return response;
        }
        return null;
      } catch (error) {
        return null;
      }
    };

    const modifyMembersList = async () => {
      loadingImages.value = true;
      if (!teamMemberList.value && !teamMemberList.value?.length) {
        loadingImages.value = false;
        return;
      }
      try {
        const defaultMember = teamMemberList.value[0];
        const { profileImage } = defaultMember.userWorkstation;

        const source = await getWorkstationProfile(profileImage);

        teamMemberList.value.forEach((member: any, index: number) => {
          if (index === 0) {
            modifiedMembers.value.push({
              ...member,
              attachmentSource: source,
            });
          } else {
            modifiedMembers.value.push({
              ...member,
            });
          }
        });
      } catch (error) {
        loadingImages.value = false;
      } finally {
        loadingImages.value = false;
      }
    };

    const formatSum = (sum: any) => {
      return new Intl.NumberFormat("en-US", {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(sum);
    };

    const importWorkstationData = () => {
      if (isPersonalAccountWorkStation.value) {
        toggleRestrictionModal();
      } else {
        isShowImportWorkStation.value = true;
      }
    };

    const contractorTradePassportWorkstationId = computed(
      () =>
        store.getters[
          `${TRADE_ESTIMATE_STORE}/getContractorTradePassportWorkstationId`
        ]
    );

    const getInitialData = async () => {
      try {
        isLoading.value = true;
        if (isUserProprtyOwner.value) {
          if (user.value && contractorTradePassportWorkstationId.value) {
            await store.dispatch(
              `${TRADE_ESTIMATE_STORE}/getContractorTradePassportDetail`,
              {
                workStationId: contractorTradePassportWorkstationId.value,
                userId: user.value.id,
              }
            );
            await store
              .dispatch(`${USER_STORE}/setContractorInsurances`, {
                userId: user.value.id,
                workStationId: contractorTradePassportWorkstationId.value,
              })
              .then(() => {
                insuranceLoading.value = false;
              });

            await store
              .dispatch(`${USER_STORE}/getContractorSkills`, {
                userId: user.value.id,
                workStationId: contractorTradePassportWorkstationId.value,
              })
              .then(() => {
                specialismsLoading.value = false;
              });

            await store
              .dispatch(`${USER_STORE}/setContractorCertificates`, {
                userId: user.value.id,
                workStationId: contractorTradePassportWorkstationId.value,
              })
              .then(() => {
                certificateLoading.value = false;
              });

            contractorworkStationImage.value = await getImageStringToImageURL(
              activeUserWorkstation.value?.profileImage
            );

            // nationalInsuranceNumber.value =
            //   user?.value.userBusinessDetails?.nationalInsuranceNumber;
            await getTradePassportGallery();
          } else {
            router.go(-1);
          }
        } else {
          // initializing.value = true;
          // certificateLoading.value = true;
          // specialismsLoading.value = true;
          // insuranceLoading.value = true;
          state.galleryIsLoading = true;
          if (user.value) {
            await getTeamMember();
            await modifyMembersList();
            if (!userInsurances.value?.length) {
              await store
                .dispatch(`${USER_STORE}/setUserInsurances`, user.value?.id)
                .then(() => {
                  insuranceLoading.value = false;
                });
            }
            if (!userSpecialisms.value?.length) {
              await store
                .dispatch(`${USER_STORE}/getUserSkills`, user.value.id)
                .then(() => {
                  specialismsLoading.value = false;
                });
            }
            if (!userCertificates.value?.length) {
              await store
                .dispatch(`${USER_STORE}/setUserCertificates`, user.value?.id)
                .then(() => {
                  certificateLoading.value = false;
                });
            }
            nationalInsuranceNumber.value =
              user?.value.userBusinessDetails?.nationalInsuranceNumber;
            await getTradePassportGallery();
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
        initializing.value = false;
      }
    };

    onMounted(async () => {
      await getInitialData();
    });
    const chipLabel = (status: any) => {
      if (UserVerificationStatusEnum.PENDING_VERIFICATION === status)
        return "Pending";
      else if (UserVerificationStatusEnum.REJECTED === status)
        return "Rejected";
      else if (UserVerificationStatusEnum.VERIFIED === status)
        return "Verified";
      else if (UserVerificationStatusEnum.NOT_ADDED === status)
        return "Not added";
    };
    const chipColor = (status: any) => {
      if (UserVerificationStatusEnum.PENDING_VERIFICATION === status)
        return "#FAA500";
      else if (UserVerificationStatusEnum.REJECTED === status) return "red";
      else if (UserVerificationStatusEnum.VERIFIED === status) return "green";
      else if (UserVerificationStatusEnum.NOT_ADDED === status)
        return "#7467B7";
    };

    const formatDate = (dateString: string) => {
      const date = new Date(dateString);
      const day = date.getUTCDate();
      const month = date.getUTCMonth() + 1;
      const year = date.getUTCFullYear();
      const formattedDay = day < 10 ? "0" + day : day;
      const formattedMonth = month < 10 ? "0" + month : month;
      return `${formattedDay}/${formattedMonth}/${year}`;
    };

    const onCloseImportWorkstationModal = () => {
      isShowImportWorkStation.value = false;
    };
    const isPersonalAccountWorkStation = computed(
      () =>
        store.getters[`${WORKSTATION}/activeUserWorkstation`]?.name ===
        "Default"
    );

    const toggleRestrictionModal = () => {
      isShowWorkstationRestrictionModal.value =
        !isShowWorkstationRestrictionModal.value;
    };

    const onClickAssignData = () => {
      if (isPersonalAccountWorkStation.value) {
        toggleRestrictionModal();
      } else {
        isShowSelectTeamMember.value = true;
      }
    };

    const onCloseSelectTeam = () => {
      isShowSelectTeamMember.value = false;
    };

    const onClickContinueSelectTeamMember = () => {
      isShowSelectTeamMember.value = false;
      isShowAssignModal.value = true;
    };

    const onCloseAssignModal = () => {
      isShowSelectTeamMember.value = true;
      isShowAssignModal.value = false;
    };

    const onContinueButtonClick = () => {
      isShowAssignModal.value = false;
      isShowConfirmAssignModal.value = true;
    };

    const onCloseConfirmAssignModal = () => {
      isShowConfirmAssignModal.value = false;
    };

    const onClickNoConfirmAssignModal = () => {
      isShowAssignModal.value = true;
      isShowConfirmAssignModal.value = false;
    };

    const onClickBackEstimateButton = () => {
      router.go(-1);
    };
    const onChangeGallery = async () => {
      await getTradePassportGallery();
    };
    return {
      UserVerificationStatusEnum,
      userWorkStationStatus,
      tradeItems,
      isItemDisable,
      activeTab,
      filterUserSpecialisms,
      activeUserWorkstation,
      user,
      BusinessDetailTypeEnum,
      userInsurances,
      onDeleteInsurance,
      onEditInsurance,
      openedModel,
      isOuterEditInsurance,
      userInsuranceData,
      modelName,
      onClickCloseModel,
      onEditDescription,
      userDescriptionData,
      isOuterEditDescription,
      HeaderTittle,
      userDescription,
      userFullName,
      userWorkStationImage,
      onClickManageSpecialism,
      onClickAddInsurance,
      onClickManageCertifications,
      userCertificates,
      navigateToCourse,
      onClickEditVatAndTax,
      isExpiredDate,
      activeWorkStationType,
      workstationCreatedDate,
      workstationAddress,
      workstationPostcode,
      isShowEditWorkStationModal,
      onClickEditWorkStation,
      closeEditWorkStationModal,
      onClickUpdateUserRates,
      isShowUserRateModal,
      closeUpdateUserRatesModal,
      activeWorkStationSubscription,
      getWorkStationPlanType,
      userCertificateData,
      onEditCertificate,
      deleteCertificate,
      isShowUserExperienceModal,
      closeUpdateUserExperienceModal,
      onClickUpdateUserExperience,
      subscriptionType,
      onClickManageTeam,
      teamMemberList,
      chipLabel,
      chipColor,
      hasData,
      isShowPassword,
      nationalInsuranceNumber,
      modifiedMembers,
      loadingImages,
      formatSum,
      isOpenConfirmDelete,
      onDeleteItem,
      confirmDeleteInsuranceData,
      confirmDeleteCertificateData,
      confirmDeleteType,
      confirmDeleteLabel,
      onCloseConfirmDelete,
      deleteItem,
      importWorkstationData,
      isShowImportWorkStation,
      onCloseImportWorkstationModal,
      isAvailable,
      onClickAssignData,
      isShowSelectTeamMember,
      onCloseSelectTeam,
      onClickContinueSelectTeamMember,
      isShowAssignModal,
      onCloseAssignModal,
      onContinueButtonClick,
      isShowConfirmAssignModal,
      onCloseConfirmAssignModal,
      onClickNoConfirmAssignModal,
      userAllWorkStation,
      getUserWorkStationFieldAccess,
      isUserWorkStationAdmin,
      formatNumberWithCommas,
      formatDate,
      CurrencyFilter,
      onClickManageGallery,
      state,
      modals,
      onCloseDeleteConfirmationModal,
      onOpenDeleteConfirmationModal,
      fileList,
      initializing,
      certificateLoading,
      specialismsLoading,
      insuranceLoading,
      onSuccess,
      getTradePassportGallery,
      onClosePhotoPreviewModal,
      onOpenPhotoPreviewModal,
      onOpenVideo,
      isShowWorkstationRestrictionModal,
      toggleRestrictionModal,
      defaultUserSpecialisms,
      userDefaultInsurances,
      userDefaultCertificates,
      userDefaultDescriptionData,
      defaultFileList,
      contractorworkStationImage,
      isUserProprtyOwner,
      onClickBackEstimateButton,
      onChangeGallery,
      userInsurenceExperiedStatus,
      isShowFreezeModal,
      onCloseFreezeModal,
      isWorkStationVerified,
      isLoading,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/modules/trade-passport/styles/TradePassport.scss";

.gallery-content-container {
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
}
.back-btn {
  margin-right: auto;
}

.card-services {
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
}
</style>
