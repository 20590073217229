export enum UserNotificationType {
  USER_NETWORK = "networkRequest",
  TRADE_USER_CONNECTION = "TradeUserConnection",
  USER_REQUEST = "userRequest",
  PROJECT_CHAT = "ProjectChatRequest",
  PROPERTY_USER_CONNECTION = "PropertyUserConnection",
  VERIFY_JOBS = "historicalJob",
  CLIENT_HISTORICAL_PROJECT = "clientHistoricalJob",
  EXTERNAL_TRADE_NETWORK = "ExternalTradeNetwork",
  VIDEO_CALL = "videoCall",
  RAISE_PROJECT_TYPE = "RaiseProjectType",
  REJECTED_RAISE_PROJECT = "RejectRaiseProject",
  RAISE_NOTIFICATION = "RaiseNotification",
  RAISE_ISSUE_BY_OCCUPIER = "issueRaisedByOccupier",
  OUTERNETWORKREQUEST = "outerNetworkRequest",
  CONTRACTORINVITEREQUEST = "contractorInviteRequest",
}

export enum UserNetworkRequestStatus {
  PENDING = "pending",
  ACCEPTED = "accepted",
  REJECTED = "rejected",
}

export enum ProjectMetaDataStatusEnum {
  PENDING = 0,
  ACCEPTED = 1,
  REJECTED = 2,
  NOT_STARTED = 4,
}
