<template>
  <div class="overlay__wrapper llg:!tw-top-0 llg:!tw-fixed">
    <div class="close-icon">
      <v-icon icon="mdi-close" @click="$emit('onCloseButtonClick')"></v-icon>
    </div>
    <div class="overlay"></div>
  </div>
  <div
    class="responsive-right-block llg:!tw-top-[8%]"
    :class="{ 'llg:!tw-top-[51px]': route?.name === 'login' }"
  >
    <v-card>
      <div class="responsive-header">
        <div class="logo">
          <img
            class="open-logo"
            src="../../assets/images/logo/iknowa-logo-v4.svg"
          />
        </div>
        <h3>iknowa Benefits</h3>
      </div>
      <div class="responsive-content">
        <v-carousel
          :show-arrows="false"
          hide-delimiter-background
          :touch="true"
        >
          <v-carousel-item v-for="(slide, i) in carouselItem" :key="i">
            <v-sheet height="100%" tile>
              <div class="right_block_content">
                <div class="bg-img"></div>
                <div class="inner-content">
                  <div class="login_img">
                    <img
                      class="open-logo"
                      :src="require(`../../assets/images/${slide?.image}.png`)"
                    />
                  </div>
                  <div class="login_content">
                    <h2 class="title sm:!tw-text-[24px]">
                      {{ slide?.title }}
                    </h2>
                    <p class="description sm:!tw-text-[14px]">
                      {{ slide?.description }}
                    </p>
                    <div class="benifit_points sm:!tw-gap-2">
                      <div
                        class="benifit_points_list"
                        v-for="(item, ii) in Object.keys(slide?.benefits)"
                        :key="ii"
                      >
                        <img class="" src="../../assets/icons/tick.svg" />
                        <p class="sm:!tw-text-[13px]">
                          {{ slide?.benefits[item] }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>
      </div>
    </v-card>
  </div>
</template>

<script lang="ts">
import { computed, ref, onMounted } from "vue";
import { useRoute } from "vue-router";

export default {
  setup() {
    const route = useRoute();

    const isLoginPage = computed(() => {
      return route.name === "login";
    });

    const slides = ref(["First", "Second", "Third", "Fourth", "Fifth"]);
    const carouselItem = [
      {
        title: "Soar to new heights with iknowa",
        description:
          "Discover how an iknowa HQ can take your business to the next level:",
        image: "trade-login-image",
        benefits: {
          data1:
            "Helping you secure high-quality building and retrofitting projects:",
          data2:
            "Upskill and improve your green skills without breaking the bank",
          data3: "Win more contracts with our iknowa Verified Trade Passport",
        },
      },
      {
        title: "Soar to new heights with iknowa",
        description:
          "Backed by the New Homes Ombudsman to improve and supercharge the property universe:",
        image: "property-owner-login-image",
        benefits: {
          data1:
            "Enjoy the peace of mind, that comes with an embedded Ombudsman.",
          data2:
            "Level up with seamless property information and insights to add value to your property (Know Your Property - KYP)",
          data3:
            "Build with the best and partner with iknowa Verified Contractors",
        },
      },
    ];

    onMounted(() => {
      console.log(route, "route");
    });
    return { slides, carouselItem, isLoginPage, route };
  },
};
</script>

<style lang="scss" scoped>
@import "@/modules/login/styles/login.scss";
</style>
