<template>
  <GeneralDialog n-close="onCloseInviteModal"
    ><template #body>
      <div class="show-invited-modal">
        <div class="show-invited-modal__inner">
          <div class="show-invited-modal__icon">
            <v-icon icon="mdi-check" color="#fff"></v-icon>
          </div>
          <h2>
            {{
              isInviteOnlyProject
                ? `This project is now Invite only`
                : `This project is now not Invite only`
            }}
          </h2>
          <p>Only invited contractors can send estimate on this project</p>
        </div>
      </div> </template
    ><template #footer
      ><v-btn
        @click="onCloseInviteModal"
        class="button button-orange !tw-font-bold"
        >CLOSE</v-btn
      ></template
    ></GeneralDialog
  >
</template>
<script lang="ts">
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
export default {
  props: {
    isInviteOnlyProject: { type: Boolean, default: false },
  },
  components: {
    GeneralDialog,
  },
  setup(props: any, ctx: any) {
    const onCloseInviteModal = () => {
      ctx.emit("onCloseInviteModal");
    };
    return { onCloseInviteModal };
  },
};
</script>

<style lang="scss" scoped>
.show-invited-modal {
  color: #0c0f4a;
  width: 400px;

  @include respond(s720) {
    width: 100%;
  }

  &__icon {
    width: 54px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background-color: #40ae42;
    margin: 0 auto 40px;
  }

  &__inner {
    text-align: center;
    max-width: 316px;
    width: 100%;
    margin: 0 auto;
    color: rgba(#0c0f4a, 0.5);
    font-weight: 500;
    letter-spacing: 0.15px;

    .mdi {
      @include fluidFont(35, 35, 1);
    }

    h2 {
      @include fluidFont(24, 24, 30px);
      max-width: 80%;
      width: 100%;
      margin: 0 auto 30px;
      color: #0c0f4a;
    }
  }
}
</style>
