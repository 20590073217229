<template>
  <section
    class="tw-w-full tw-h-full tw-flex md:tw-flex-col tw-gap-4 tw-box-borders"
  >
    <div
      class="tw-w-[440px] tw-h-auto tw-flex tw-flex-col tw-gap-4 tw-px-0 rmd:tw-px-2 tw-box-border tw-overflow-y-auto md:tw-w-full tw-max-w-full"
    >
      <PropertyCard />
      <!-- <CollapsiblePropertySummary /> -->
      <CollapsiblePropertyProjects />
      <CollapsiblePropertyRecommendation />
      <!-- <CollapsiblePropertyConnection />
      <CollapsiblePropertyOccupiers /> -->
    </div>
    <div
      class="tw-h-[670px] tw-flex tw-items-center tw-justify-center tw-rounded-lg tw-overflow-hidden tw-relative tw-w-[calc(100%-460px)] lg:tw-w-full"
    >
      <PropertyGoogleMap
        v-if="addressData"
        :addressData="addressData"
        :propertyId="propertyDetails?.localData?.id"
        ref="googleMap"
      />
    </div>
  </section>
</template>
<script setup lang="ts">
import { LatLngExpression } from "leaflet";
import { computed, ref, onMounted } from "vue";
import { useStore } from "vuex";

import PropertyCard from "@/modules/properties/components/Cards/PropertyCard.vue";
import CollapsiblePropertySummary from "../Collapsibles/CollapsiblePropertySummary.vue";
import CollapsiblePropertyProjects from "../Collapsibles/CollapsiblePropertyProjects.vue";
import CollapsiblePropertyRecommendation from "../Collapsibles/CollapsiblePropertyRecommendation.vue";
import CollapsiblePropertyConnection from "../Collapsibles/CollapsiblePropertyConnection.vue";
import CollapsiblePropertyOccupiers from "../Collapsibles/CollapsiblePropertyOccupiers.vue";
import { PROPERTY_STORE } from "@/store/modules/property";
import PropertyGoogleMap from "@/core/components/map/PropertyGoogleMap.vue";
import { parseJSON } from "@/core/utils/common";
import GoogleMap2 from "@/core/components/map/GoogleMap2.vue";

const store = useStore();
const propertyDetails = computed(
  () => store.getters[`${PROPERTY_STORE}/propertyDetails`]
);
const addressData = computed(() => {
  const propertyAddress = propertyDetails.value?.localData;
  return parseJSON(propertyAddress?.addressData);
});
</script>
<style lang="scss" scoped></style>
