<template>
  <CommonDialog
    v-if="isShowSubscriptionModal"
    :className="['property_owner_subscription_modal']"
    :onClickBackButton="onClickCloseButton"
    :isShowHeader="false"
    :isShowBackButton="false"
    width="620px"
    max-width="100%"
    height="auto"
    ><template v-slot:body>
      <div class="modal_content">
        <div class="header">
          <v-btn
            icon="mdi-close"
            variant="plain"
            @click="onClickCloseButton"
          ></v-btn>
        </div>
        <div class="modal_form_header">
          <img src="../../../../assets/icons/upgrade-premium-yellow.svg" />
          <h3>UPGRADE TO PREMIUM</h3>
          <p>Unlock Your Property Superpower Today</p>
        </div>
        <ul class="modal_form_top">
          <li class="modal_form_benefits">
            <v-icon icon="mdi-check-circle" color="#4887FF" />
            <p>List & Manage <span>Unlimited Properties</span></p>
          </li>
          <li class="modal_form_benefits">
            <v-icon icon="mdi-check-circle" color="#4887FF" />
            <p>Access Verified & Local <span>Trade Contractors</span></p>
          </li>
          <li class="modal_form_benefits">
            <v-icon icon="mdi-check-circle" color="#4887FF" />
            <p>Raise & Manage <span>Any Type of Projects</span></p>
          </li>
        </ul>
        <div class="plan-cards">
          <v-card
            class="plan-card"
            :class="{
              selected: planType === PaymentPlanType.YEARLY,
            }"
            @click="onClickPaymentPlan(PaymentPlanType.YEARLY)"
          >
            <div class="plan-card__tags">
              <span class="plan-card__tag-item">YEARLY</span>
              <span class="plan-card__tag-item-discount"
                >Most Cost Effective</span
              >
            </div>
            <p class="plan-card__price">
              <span>£7.99</span> per month/
              <strong> £95.90 </strong>(+VAT)<strong> billed today</strong>
            </p>
          </v-card>
          <v-card
            class="plan-card"
            :class="{
              selected: planType === PaymentPlanType.MONTHLY,
            }"
            @click="onClickPaymentPlan(PaymentPlanType.MONTHLY)"
          >
            <div class="plan-card__tags">
              <span class="plan-card__tag-item">MONTHLY</span>
            </div>

            <p class="plan-card__price"><span>£9.99</span>(+VAT) per month</p>
          </v-card>
        </div>
        <div class="modal_footer_text">
          <Button
            variant="tertiary"
            label="Upgrade to premium"
            @click="onClickUpgradePremium"
            width="100%"
            :isLoading="paymentRedirectLoading"
            class="button button-orange"
          />
          <p class="modal_footer_TC_text">
            Cancel Anytime |
            <span class="yellow-text" @click="onClickTermsAndConditionModal"
              >Terms of Service
              <v-icon color="#FFA500" icon="mdi-chevron-right"></v-icon
            ></span>
          </p>
        </div>
      </div>
    </template>
  </CommonDialog>
  <CommonDialog
    v-if="isShowSelectWorkstationTypeModal"
    :isShowHeader="false"
    :isShowBackButton="false"
    :className="['select_workstation_type_modal']"
    width="397px"
    height="auto"
    ><template v-slot:body>
      <div class="modal_content">
        <div class="header">
          <h4>Select Workstation Type</h4>
          <v-btn
            icon="mdi-close"
            @click="onClickCloseSelectWorkstationTypeModalButton"
          ></v-btn>
        </div>
        <p class="sub-title">
          Please choose the type of workstation you would like to set up. You
          can either select 'Company' for a business account or 'Individual' for
          a personal account.
        </p>
        <div class="modal_form">
          <v-card
            class="select_type__card-item"
            @click="selectType('Company')"
            :class="{ selected: selectedType === 'Company' }"
          >
            <div class="select_type__card-details">
              <div class="workstation_icon">
                <img
                  class="select_type__icon"
                  src="../../../../assets/icons/company.svg"
                />
              </div>
              <div class="select_type__card-details__text">
                <h4>Company</h4>
              </div>
            </div>
          </v-card>
          <v-card
            class="select_type__card-item"
            @click="selectType('Individual')"
            :class="{ selected: selectedType === 'Individual' }"
          >
            <div class="select_type__card-details !tw-mt-0">
              <div class="select_type__card-details__icon workstation_icon">
                <img
                  class="select_type__icon"
                  src="../../../../assets/icons/home-filled-icon.svg"
                  alt=""
                />
                <!-- <v-icon icon="mdi-home-variant" /> -->
              </div>
              <div class="select_type__card-details__text">
                <h4>Individual</h4>
              </div>
            </div>
          </v-card>
        </div>
        <div class="modal_footer_button">
          <v-btn
            @click="onClickCloseSelectWorkstationTypeModalButton"
            class="button button-purple-border"
            >Cancel</v-btn
          >
          <v-btn
            @click="onClickContinueButton"
            :disabled="!selectedType"
            class="button button-orange"
            >Continue</v-btn
          >
        </div>
      </div>
    </template>
  </CommonDialog>
  <CommonDialog
    v-if="isShowIDCheckModal"
    :className="['property_owner_id_Check_modal']"
    :onClickBackButton="onClickCloseIDCheckButton"
    :HeaderTittle="HeaderTittle"
    title="We need to verify your identity"
    width="968px"
    height="100%"
    ><template v-slot:body>
      <div class="modal_content">
        <div class="description">
          <p>We will require your passport or driver’s license.</p>
          <p>
            Verified ID is required to ensure trust security and compliance on
            Iknowa.
          </p>
        </div>
        <div class="content">
          <div class="your-img-wrap">
            <img
              class="open-logo"
              src="../../../../assets/icons/id-check.svg"
            />
          </div>
          <v-btn class="button button-orange w-full" v-if="isShowNextButton"
            >Next</v-btn
          >
          <a
            v-if="selectedType === 'Individual'"
            class="btn_link"
            :href="siltRedirectUrl"
            >Start ID verification</a
          >
          <a
            v-if="selectedType === 'Company'"
            class="btn_link"
            @click.prevent="onClickIdVerification"
            >Start ID verification</a
          >
        </div>
      </div>
    </template>
  </CommonDialog>
  <KYCStatusModal
    v-if="statusModal.KYC === currentStatusModalName"
    :onContinue="onClickTermAndConditionNext"
    :kycStatus="currentModalStatus"
  />
  <PaymentStatusModal
    v-if="statusModal.PAYMENT === currentStatusModalName"
    :onContinue="onClickStatusModalContinue"
    :paymentStatus="currentModalStatus"
    :planType="planType"
  />
  <PropertyOwnerTermsConditionModal
    v-if="isShowTermsAndConditionModal"
    @closeTermsAndConditionModal="closeTermsAndConditionModal"
  />
  <KYCStatusModal
    v-if="statusModal.KYBINFO == currentStatusModalName"
    :onContinue="onClickCloseSelectWorkstationTypeModalButton"
    kycStatus="KYBINFO"
  />
</template>

<script lang="ts">
import { ref, onBeforeMount, computed } from "vue";
import { useRouter } from "vue-router";
import CommonDialog from "@/core/components/CommonDialog.vue";
import PropertyOwnerTermsConditionModal from "@/modules/workstation/propertyowner/pages/PropertyOwnerTermsConditionModal.vue";
import {
  PaymentPlanType,
  BusinessDetailTypeEnum,
  WorkstationAccountTypeEnum,
  UserWorkstationVerificationType,
  UserVerificationStatusEnum,
  PropertyOwnerWorkstationType,
} from "@/core/enums/RolesEnum";
import {
  VUE_APP_STRIPE_PUBLISHABLE_KEY,
  VUE_APP_SILT_KYC_COMPANY_APP_ID,
} from "@/config";
import { cloneObject } from "@/core/utils/common";
import { useStore } from "vuex";
import $axios from "@/core/utils/axios-api-config";
import { USER_STORE } from "@/store/modules/user";
import { WORKSTATION } from "@/store/modules/workstation";
import PaymentStatusModal from "@/core/components/PaymentStatusModal.vue";
import { DASHBOARD_ROUTE } from "@/modules/dashboard/routes";
import KYCStatusModal from "@/core/components/KYCStatusModal.vue";
import UserService from "@/core/services/user.service";
import Button from "@/core/uni-components/Button.vue";

export default {
  components: {
    CommonDialog,
    PropertyOwnerTermsConditionModal,
    PaymentStatusModal,
    KYCStatusModal,
    Button,
  },
  props: {
    SelectWorkstationType: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  setup(props: any, ctx: any) {
    const store = useStore();
    const router = useRouter();
    const isShowTermsAndConditionModal = ref(false);
    const isShowSubscriptionModal = ref(true);
    const stripe = ref(null);
    const planType = ref(PaymentPlanType.YEARLY) as any;
    const sessionId = ref(null);
    const redirectUrl = ref("");
    const customer = ref("") as any;
    const currentStatusModalName = ref("");
    const statusModal = { PAYMENT: "payment", KYC: "kyc", KYBINFO: "KYBINFO" };
    const currentModalStatus = ref("");
    const selectedType = ref("");
    const isShowIDCheckModal = ref(false);
    const HeaderTittle = ref("");
    const isShowNextButton = ref(false);
    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const isShowSelectWorkstationTypeModal = ref(props?.SelectWorkstationType);
    const paymentRedirectLoading = ref(false);
    const activeUserWorkstation = computed(
      () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
    );

    const selectType = (type: string) => {
      selectedType.value = type;
      if (selectedType.value === "Company") {
        HeaderTittle.value = "KYB Verification";
      } else {
        HeaderTittle.value = "KYC Verification";
      }
    };

    const onClickContinueButton = async () => {
      const selectedTypeData = {
        userId: user.value.id,
        userWorkstationId: activeUserWorkstation.value?.id,
        verificationType:
          selectedType.value === "Company"
            ? PropertyOwnerWorkstationType.COMPANY
            : PropertyOwnerWorkstationType.INDIVIDUAL,
      };
      try {
        await store.dispatch(
          `${USER_STORE}/updatePropertyOwnerWSType`,
          selectedTypeData
        );
        isShowIDCheckModal.value = true;
      } catch (err) {
        console.log();
      }
    };

    const onClickTermAndConditionNext = () => {
      router.push({ name: DASHBOARD_ROUTE });
    };

    const siltRedirectUrl = computed(() => {
      const host = `${window.location.protocol}//${window.location.host}`;
      redirectUrl.value = `${host}/dashboard`;
      return `https://signup.getsilt.com/?company_app_id=${VUE_APP_SILT_KYC_COMPANY_APP_ID}&hide_redirect_button=true&customer_user_id=${user.value?.id}-${activeUserWorkstation.value?.id}&redirect_url=${redirectUrl.value}`;
    });

    const onClickIdVerification = async () => {
      try {
        const response: any = await store.dispatch(
          `${WORKSTATION}/sendUserKYBProcessMail`,
          {
            email: user.value.email,
          }
        );

        response["custom_user_workstation_id"] =
          activeUserWorkstation.value?.id;
        response["custom_user_id"] = user.value.id;
        await store.dispatch(
          `${WORKSTATION}/setWorkStationSiltVerificationStatus`,
          response
        );
        await $axios.post(`/silt/verification/kyb`, response);
        currentStatusModalName.value = "KYBINFO";
        isShowSubscriptionModal.value = false;
      } catch (error) {
        console.log();
      }
    };

    const onClickCloseButton = () => {
      ctx.emit("closeSubscriptionModal");
      currentModalStatus.value = activeUserWorkstation.value.subscription
        ? "paid"
        : "failed";
      return;
    };

    const onClickCloseSelectWorkstationTypeModalButton = () => {
      ctx.emit("closeSelectWorkstationTypeModal");
    };

    const onClickTermsAndConditionModal = () => {
      isShowTermsAndConditionModal.value = true;
      isShowSubscriptionModal.value = false;
    };

    const closeTermsAndConditionModal = () => {
      isShowTermsAndConditionModal.value = false;
      isShowSubscriptionModal.value = true;
    };
    const onClickPaymentPlan = (planName: number) => {
      planType.value = planName;
    };

    const onClickUpgradePremium = () => {
      if (planType.value === PaymentPlanType.YEARLY) {
        initializeStripePayment(false, false, 95.9);
      } else if (planType.value === PaymentPlanType.MONTHLY) {
        initializeStripePayment(false, false, 9.99);
      }
    };
    const initializeStripe = () => {
      let stripe = null;

      if ("Stripe" in window) {
        const { Stripe } = window as any;

        // eslint-disable-next-line no-new
        stripe = new Stripe(VUE_APP_STRIPE_PUBLISHABLE_KEY);
      }

      return stripe;
    };

    const initializeStripePayment = async (
      isTrial: boolean,
      oneTimePayment: boolean,
      price: number
    ) => {
      stripe.value = initializeStripe();
      paymentRedirectLoading.value = true;
      if (stripe.value) {
        await onCheckout(isTrial, oneTimePayment, price);
      }
      paymentRedirectLoading.value = false;
    };

    const onCheckout = async (
      isTrial: boolean,
      oneTimePayment: boolean,
      price: number
    ) => {
      await createSession(isTrial, oneTimePayment, price);
      if (sessionId.value) {
        (stripe.value as any)
          .redirectToCheckout({
            sessionId: sessionId.value,
          })
          .then((result: any) => {
            if (result.error) {
              console.log();
            }
          });
      }
    };

    const createSession = async (
      isTrial = false,
      oneTimePayment = false,
      price: number
    ) => {
      const user = cloneObject(store.getters[`${USER_STORE}/user`]);
      const { id: userId, userStripeCustomer, email } = user;

      const productName = "tradesperson-profile";

      const entity = JSON.stringify({
        id: userId,
        name: "tradesperson-profile",
      });

      if (!userStripeCustomer?.length) {
        await $axios
          .post(`/payment/${userId}/customer`, {
            email,
            entity,
          })
          .then(async (customerResponse) => {
            await store.dispatch(`${USER_STORE}/saveUserStripePayment`, [
              customerResponse,
            ]);
            customer.value = customerResponse;
          })
          .catch((e) => {
            console.log();
          });
      } else {
        const [stripeCustomer] = userStripeCustomer;
        customer.value = stripeCustomer;
      }

      const host = `${window.location.protocol}//${window.location.host}`;
      redirectUrl.value = `${host}/dashboard`;

      const { customerId } = customer.value;
      const totalPrice = price;

      if (customerId) {
        await $axios
          .post(`/payment/${userId}/session`, {
            customerId,
            price: totalPrice,
            redirectUrl: redirectUrl.value,
            productName,
            metadata: JSON.stringify({
              userWorkstationId: activeUserWorkstation.value?.id,
              name: activeUserWorkstation.value?.name,
              companyName: activeUserWorkstation.value?.name,
              productName,
              userId,
              businessType: BusinessDetailTypeEnum.PROPERTY_OWNER,
              planType: planType.value,
              workstationAccountType: WorkstationAccountTypeEnum.HOMEOWNER,
              capacity: "100MB",
              totalMember: 1,
            }),
            isTrial,
            oneTimePayment,
          })
          .then(async (sessionResponse: any) => {
            if (sessionResponse) {
              sessionId.value = sessionResponse?.id;
              localStorage.setItem("isShowPOSubScriptionModal", "true");
            }
          })
          .catch((error) => {
            console.log();
          });
      }
    };

    const getCheckoutSession = async () => {
      try {
        localStorage.removeItem("isShowPOSubScriptionModal");
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        if (!params?.session_id) return;
        isShowSubscriptionModal.value = false;
        currentStatusModalName.value = statusModal.PAYMENT;
        const url = `/payment/${user.value?.id}/session?sessionId=${params.session_id}&isWorkstation=1`;

        const response: any = await $axios.get(url);

        if (response?.status === 400) {
          currentModalStatus.value = "failed";
          return;
        }
        currentModalStatus.value = response?.payment_status;
        planType.value = parseInt(response.metadata?.planType, 10);

        await store.dispatch(`${WORKSTATION}/activateWorkstation`, {
          userId: user.value?.id,
          userWorkstationId: response.metadata?.userWorkstationId,
        });
        store.commit(`${USER_STORE}/setCertificates`, []);
        store.commit(`${USER_STORE}/setInsurances`, []);
        store.commit(`${USER_STORE}/setUserSpecialisms`, []);
        await store.dispatch(
          `${WORKSTATION}/getPropertyOwnerUserWorkstations`,
          user.value.id
        );
        await store.dispatch(`${USER_STORE}/initializeUserState`);
        await store.dispatch(`${USER_STORE}/getAccountStatuses`);
      } catch (error) {
        console.log();
      }
    };

    const getCheckoutUserKYC = async () => {
      try {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        if (!params?.silt_user_id) return;
        const soloTraderVerificationData = await store.dispatch(
          `${WORKSTATION}/getWorkStationSiltVerificationStatus`,
          {
            id: params.silt_user_id,
            verificationType: UserWorkstationVerificationType.KYC,
          }
        );
        await store.dispatch(
          `${WORKSTATION}/saveWorkstationVerificationData`,
          soloTraderVerificationData
        );
        currentStatusModalName.value = statusModal.KYC;
        isShowSubscriptionModal.value = false;
        currentModalStatus.value = soloTraderVerificationData?.status;
        await store.dispatch(`${USER_STORE}/initializeUserState`);
      } catch (error: any) {
        if (error?.response?.status === 400) {
          currentStatusModalName.value = statusModal.KYC;
          isShowSubscriptionModal.value = false;
          currentModalStatus.value = "ERROR";
          return;
        }
      }
    };

    const onClickStatusModalContinue = async () => {
      try {
        const url = new URL(window.location.href);
        url.search = "";
        if (currentStatusModalName.value === statusModal.PAYMENT) {
          const response = await store.dispatch(
            `${WORKSTATION}/getWorkstationVerificationData`,
            activeUserWorkstation.value?.id
          );
        }
        history.replaceState(null, "", url.toString());
        isShowSubscriptionModal.value = false;
        isShowSelectWorkstationTypeModal.value = true;
        // const data = {
        //   userId: user.value?.id,
        //   userWorkstationId: activeUserWorkstation.value?.id,
        //   formData: {
        //     verificationStatus: UserVerificationStatusEnum.PENDING_VERIFICATION,
        //   },
        // };
        // await store.dispatch(
        //   `${WORKSTATION}/updateUserWorkstationStatus`,
        //   data
        // );
      } catch (error) {
        console.log();
      }
    };

    const onClickFreePlan = async () => {
      const response = await UserService.updateFreePlanWorkstation(
        user.value?.id,
        activeUserWorkstation.value?.id
      );
      if (response) {
        isShowSubscriptionModal.value = false;
        isShowSelectWorkstationTypeModal.value = true;
      }
    };

    const onClickCloseIDCheckButton = () => {
      isShowIDCheckModal.value = false;
      isShowSelectWorkstationTypeModal.value = true;
    };

    onBeforeMount(async () => {
      getCheckoutUserKYC();
      getCheckoutSession();
      if (props?.SelectWorkstationType) {
        isShowSubscriptionModal.value = false;
      }
    });

    return {
      onClickCloseButton,
      isShowTermsAndConditionModal,
      isShowSubscriptionModal,
      onClickTermsAndConditionModal,
      closeTermsAndConditionModal,
      onClickPaymentPlan,
      onCheckout,
      initializeStripePayment,
      stripe,
      planType,
      sessionId,
      redirectUrl,
      PaymentPlanType,
      currentStatusModalName,
      statusModal,
      currentModalStatus,
      onClickStatusModalContinue,
      isShowSelectWorkstationTypeModal,
      onClickCloseSelectWorkstationTypeModalButton,
      selectType,
      selectedType,
      onClickContinueButton,
      isShowIDCheckModal,
      HeaderTittle,
      isShowNextButton,
      siltRedirectUrl,
      onClickIdVerification,
      onClickTermAndConditionNext,
      onClickCloseIDCheckButton,
      onClickFreePlan,
      onClickUpgradePremium,
      paymentRedirectLoading,
    };
  },
};
</script>
<style scoped lang="scss">
.btn_link {
  font-size: 0.875rem;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 1.7;
  display: flex;
  width: auto;
  box-shadow: none;
  border-radius: 0.5rem;
  letter-spacing: 0;
  min-height: 42px;
  text-decoration: none;
  border: 1px solid #4f55f0;
  align-items: center;
  justify-content: center;
  color: #4f55f0;
  margin-top: 16px;
  cursor: pointer;
}

.btn_link:hover {
  background-color: #4f55f0;
  color: #fff;
}
</style>
