<template>
  <div class="notification_wrapper">
    <div class="notification_overlay"></div>
    <div
      class="notification_wrapper_body sm:!tw-right-[-7px] sm:!tw-top-[-12px] !tw-z-20"
    >
      <div class="notification_wrapper__header">
        <h5>Notifications</h5>
        <p
          v-if="hasUnreadNotifications"
          @click="onClickMarkAllNotificationAsRead"
        >
          Mark as read
        </p>
      </div>
      <!-- Notification List -->
      <div
        class="notification_wrapper__list"
        v-for="(group, date) in combinedNotifications"
        :key="date"
      >
        <div class="prefix">
          <span>{{ date }}</span>
        </div>
        <div
          v-for="(notification, i) in group"
          :key="i"
          class="notification_wrapper__list_item"
        >
          <template v-if="checkUserInvite(notification.type)">
            <div
              v-if="notification.state == NotificationStateEnum.UNREAD"
              class="notification_wrapper__new_item"
            ></div>
            <div class="notification_wrapper__list_item_content">
              <div class="notification_wrapper__img">
                <img
                  v-if="notification?.profileImage"
                  :src="`https://storage.googleapis.com/idgc-b10/${notification?.profileImage}`"
                  alt=""
                /><img v-else src="../../assets/images/user-placeholder.jpg" />
              </div>
              <div
                v-if="notification.type === 'insuranceExpiration'"
                class="notification_wrapper__user_content"
              >
                <div class="notification_wrapper__text">
                  <p>
                    <strong>{{ notification.content }}</strong>
                  </p>
                  <span>{{ notification?.timeAgo }}</span>
                </div>
              </div>
              <div
                v-if="notification.type === UserNotificationType.USER_NETWORK"
                class="notification_wrapper__user_content"
              >
                <div class="notification_wrapper__user_content_info">
                  <div class="notification_wrapper__text">
                    <p>
                      <strong>{{ notification.content }}</strong> has sent you a
                      connection request.
                    </p>
                    <span>{{ notification?.timeAgo }}</span>
                  </div>
                  <div class="notification_wrapper__buttons">
                    <v-btn
                      class="notification_wrapper__accept"
                      variant="tonal"
                      @click="
                        changeRequestStatus(
                          UserNetworkRequestStatus.ACCEPTED,
                          notification?.sourceId,
                          notification?.id
                        )
                      "
                      >Accept</v-btn
                    ><v-btn
                      class="notification_wrapper__reject"
                      variant="tonal"
                      @click="
                        changeRequestStatus(
                          UserNetworkRequestStatus.REJECTED,
                          notification?.sourceId,
                          notification?.id
                        )
                      "
                      >Reject</v-btn
                    >
                  </div>
                </div>
                <div class="notification_wrapper__more_button">
                  <v-icon> <img src="../../assets/icons/dots.svg" /></v-icon>
                </div>
              </div>
              <div
                v-if="
                  notification.type ===
                  UserNotificationType.CLIENT_HISTORICAL_PROJECT
                "
                class="notification_wrapper__user_content"
              >
                <div class="notification_wrapper__user_content_info">
                  <div class="notification_wrapper__text">
                    <p>
                      <strong>{{ notification.content }}</strong>
                    </p>
                    <span>{{ notification?.timeAgo }}</span>
                  </div>
                  <div class="notification_wrapper__buttons">
                    <v-btn
                      class="notification_wrapper__accept"
                      variant="tonal"
                      @click="
                        onClientHistoricalProjectStatus(
                          HistoricProjectStatusEnum.ACCEPTED,
                          notification
                        )
                      "
                      >Accept</v-btn
                    ><v-btn
                      class="notification_wrapper__reject"
                      variant="tonal"
                      @click="
                        onClientHistoricalProjectStatus(
                          HistoricProjectStatusEnum.REJECTED,
                          notification
                        )
                      "
                      >Reject</v-btn
                    >
                  </div>
                </div>
                <div class="notification_wrapper__more_button">
                  <v-icon> <img src="../../assets/icons/dots.svg" /></v-icon>
                </div>
              </div>

              <div
                v-if="notification.type === UserNotificationType.USER_REQUEST"
                class="notification_wrapper__user_content"
              >
                <div class="notification_wrapper__user_content_info">
                  <div class="notification_wrapper__text">
                    <p>
                      <strong>{{ notification.content }}</strong> has sent you a
                      connection request.
                    </p>
                    <span>{{ notification?.timeAgo }}</span>
                  </div>
                </div>
                <div class="notification_wrapper__more_button">
                  <v-icon> <img src="../../assets/icons/dots.svg" /></v-icon>
                </div>
              </div>

              <div
                v-if="
                  notification.type ===
                  UserNotificationType.TRADE_USER_CONNECTION
                "
                class="notification_wrapper__user_content"
              >
                <div class="notification_wrapper__user_content_info">
                  <div class="notification_wrapper__text">
                    <p>
                      <strong>{{ notification.content }}</strong> wants to add
                      you to their preferred trade contractors list for future
                      projects.
                    </p>
                    <span>{{ notification?.timeAgo }}</span>
                  </div>
                  <div class="notification_wrapper__buttons">
                    <v-btn
                      class="notification_wrapper__accept"
                      variant="tonal"
                      @click="
                        acceptInvitationStatus(
                          notification?.id,
                          notification?.token
                        )
                      "
                      >Accept</v-btn
                    ><v-btn
                      class="notification_wrapper__reject"
                      variant="tonal"
                      @click="rejectInvitationStatus(notification?.id)"
                      >Reject</v-btn
                    >
                  </div>
                </div>
                <div class="notification_wrapper__more_button">
                  <v-icon> <img src="../../assets/icons/dots.svg" /></v-icon>
                </div>
              </div>
              <div>
                <div
                  v-if="
                    notification.type ===
                      UserNotificationType.CONTRACTORINVITEREQUEST &&
                    checkUserInvite(notification.type)
                  "
                  class="notification_wrapper__user_content"
                >
                  <div class="notification_wrapper__user_content_info">
                    <div class="notification_wrapper__text">
                      <p>
                        <strong>{{ notification.content }}</strong> wants to add
                        you to their preferred trade contractors list for future
                        projects.
                      </p>
                      <span>{{ notification?.timeAgo }}</span>
                    </div>
                    <div class="notification_wrapper__buttons">
                      <v-btn
                        class="notification_wrapper__accept"
                        variant="tonal"
                        @click="
                          acceptInvitationStatus(
                            notification?.sourceId,
                            notification?.metadata,
                            notification?.id
                          )
                        "
                        >Accept</v-btn
                      ><v-btn
                        class="notification_wrapper__reject"
                        variant="tonal"
                        @click="rejectInvitationStatus(notification?.sourceId)"
                        >Reject</v-btn
                      >
                    </div>
                  </div>
                  <div class="notification_wrapper__more_button">
                    <v-icon> <img src="../../assets/icons/dots.svg" /></v-icon>
                  </div>
                </div>
              </div>

              <div
                v-if="
                  notification.type ===
                    UserNotificationType.OUTERNETWORKREQUEST &&
                  activeUserWorkstation?.verificationStatus ===
                    UserVerificationStatusEnum.VERIFIED
                "
                class="notification_wrapper__user_content"
              >
                <div class="notification_wrapper__user_content_info">
                  <div class="notification_wrapper__text">
                    <p v-if="outerNetworkRequestStatus(notification?.metadata)">
                      <strong>{{ notification.content }}</strong> wants to add
                      you to their preferred trade contractors list for future
                      projects.
                    </p>
                    <p
                      v-if="!outerNetworkRequestStatus(notification?.metadata)"
                    >
                      You have
                      {{ showOuterNetworkStatus(notification?.metadata) }} of
                      <strong>{{ notification.content }}'s</strong>
                      preferred trade contractors request
                    </p>
                    <span>{{ notification?.timeAgo }}</span>
                  </div>
                  <div
                    class="notification_wrapper__buttons"
                    v-if="outerNetworkRequestStatus(notification?.metadata)"
                  >
                    <v-btn
                      class="notification_wrapper__accept"
                      variant="tonal"
                      @click="
                        acceptExternalTradeNetworkInvitationStatus(
                          notification?.id,
                          notification?.metadata
                        )
                      "
                      >Accept</v-btn
                    ><v-btn
                      class="notification_wrapper__reject"
                      variant="tonal"
                      @click="
                        rejectExternalTradeNetworkInvitationStatus(
                          notification?.id,
                          notification?.metadata
                        )
                      "
                      >Reject</v-btn
                    >
                  </div>
                </div>
                <div class="notification_wrapper__more_button">
                  <v-icon> <img src="../../assets/icons/dots.svg" /></v-icon>
                </div>
              </div>
              <!-- <div
              v-if="
                notification.type ===
                UserNotificationType.EXTERNAL_TRADE_NETWORK
              "
              class="notification_wrapper__user_content"
            >
              <div class="notification_wrapper__user_content_info">
                <div class="notification_wrapper__text">
                  <p>
                    <strong>{{ notification.content }}</strong> wants to add you
                    to their trade contractors list for future projects.
                  </p>
                  <span>{{ notification?.timeAgo }}</span>
                </div>
                <div class="notification_wrapper__buttons">
                  <v-btn
                    class="notification_wrapper__accept"
                    variant="tonal"
                    @click="
                      acceptExternalTradeNetworkInvitationStatus(
                        notification?.id,
                        notification?.token
                      )
                    "
                    >Accept</v-btn
                  ><v-btn
                    class="notification_wrapper__reject"
                    variant="tonal"
                    @click="
                      rejectExternalTradeNetworkInvitationStatus(
                        notification?.id
                      )
                    "
                    >Reject</v-btn
                  >
                </div>
              </div>
              <div class="notification_wrapper__more_button">
                <v-icon> <img src="../../assets/icons/dots.svg" /></v-icon>
              </div>
            </div> -->

              <div
                v-if="notification.type === UserNotificationType.PROJECT_CHAT"
                class="notification_wrapper__user_content"
              >
                <div class="notification_wrapper__user_content_info">
                  <div class="notification_wrapper__text">
                    <p>{{ notification.content }}</p>
                    <span>{{ notification?.timeAgo }}</span>
                  </div>
                  <div class="notification_wrapper__buttons">
                    <v-btn
                      class="notification_wrapper__accept"
                      variant="tonal"
                      @click="
                        acceptProjectInvitationStatus(
                          notification?.id,
                          notification?.sourceId
                        )
                      "
                      >Accept</v-btn
                    ><v-btn
                      class="notification_wrapper__reject"
                      variant="tonal"
                      @click="
                        rejectProjectInvitationStatus(
                          notification?.id,
                          notification?.sourceId
                        )
                      "
                      >Reject</v-btn
                    >
                  </div>
                </div>
                <div class="notification_wrapper__more_button">
                  <v-icon>
                    <img :src="require('@/assets/icons/dots.svg')"
                  /></v-icon>
                </div>
              </div>

              <div
                v-if="
                  notification.type ===
                  UserNotificationType.PROPERTY_USER_CONNECTION
                "
                class="notification_wrapper__user_content"
              >
                <div class="notification_wrapper__user_content_info">
                  <div class="notification_wrapper__text">
                    <p>
                      <strong>{{ notification.content }}</strong> wants to add
                      you to their connection list for future projects.
                    </p>
                    <span>{{ notification?.timeAgo }}</span>
                  </div>
                  <div class="notification_wrapper__buttons">
                    <v-btn
                      class="notification_wrapper__accept"
                      variant="tonal"
                      @click="
                        acceptPropertyInvitationStatus(
                          notification?.id,
                          notification?.token
                        )
                      "
                      >Accept</v-btn
                    ><v-btn
                      class="notification_wrapper__reject"
                      variant="tonal"
                      @click="rejectPropertyInvitationStatus(notification?.id)"
                      >Reject</v-btn
                    >
                  </div>
                </div>
                <div class="notification_wrapper__more_button">
                  <v-icon> <img src="../../assets/icons/dots.svg" /></v-icon>
                </div>
              </div>
              <div
                v-if="notification.type === UserNotificationType.VERIFY_JOBS"
                class="notification_wrapper__user_content"
              >
                <div class="notification_wrapper__user_content_info">
                  <div class="notification_wrapper__text">
                    <p>
                      <strong>{{ notification.content }}</strong>
                    </p>
                    <span>{{ notification?.timeAgo }}</span>
                  </div>
                </div>
                <div class="notification_wrapper__more_button">
                  <v-icon> <img src="../../assets/icons/dots.svg" /></v-icon>
                </div>
              </div>

              <div
                v-if="notification.type === UserNotificationType.VIDEO_CALL"
                class="notification_wrapper__user_content"
              >
                <div class="notification_wrapper__user_content_info">
                  <div class="notification_wrapper__text">
                    <p>
                      <strong>{{ notification.content }}</strong>
                    </p>
                    <span>{{ notification?.timeAgo }}</span>
                  </div>
                  <div class="notification_wrapper__buttons">
                    <v-btn
                      class="notification_wrapper__accept"
                      variant="tonal"
                      @click="onClickAcceptVideoCall(notification)"
                      >Accept</v-btn
                    ><v-btn
                      class="notification_wrapper__reject"
                      variant="tonal"
                      @click="onClickRejectVideoCall(notification)"
                      >Reject</v-btn
                    >
                  </div>
                </div>
                <div class="notification_wrapper__more_button">
                  <v-icon> <img src="../../assets/icons/dots.svg" /></v-icon>
                </div>
              </div>
              <!-- raise Project  -->
              <div
                v-if="
                  notification.type === UserNotificationType.RAISE_PROJECT_TYPE
                "
                class="notification_wrapper__user_content"
              >
                <div class="notification_wrapper__user_content_info">
                  <div class="notification_wrapper__text">
                    <p>
                      <strong>{{ notification.content }}</strong>
                    </p>
                    <span>{{ notification?.timeAgo }}</span>
                  </div>
                  <div class="notification_wrapper__buttons">
                    <v-btn
                      class="notification_wrapper__accept"
                      variant="tonal"
                      @click="
                        updateRaiseProjectRequestStatus(
                          ProjectMetaDataStatusEnum.ACCEPTED,
                          Number(notification?.sourceId),
                          notification?.id,
                          notification?.metadata
                        )
                      "
                      >Accept</v-btn
                    ><v-btn
                      class="notification_wrapper__reject"
                      variant="tonal"
                      @click="
                        updateRaiseProjectRequestStatus(
                          ProjectMetaDataStatusEnum.REJECTED,
                          Number(notification?.sourceId),
                          notification?.id,
                          notification?.metadata
                        )
                      "
                      >Reject</v-btn
                    >
                  </div>
                </div>
                <div class="notification_wrapper__more_button">
                  <v-icon> <img src="../../assets/icons/dots.svg" /></v-icon>
                </div>
              </div>

              <div
                v-if="
                  notification.type ===
                  UserNotificationType.REJECTED_RAISE_PROJECT
                "
                class="notification_wrapper__user_content"
              >
                <div class="notification_wrapper__user_content_info">
                  <div class="notification_wrapper__text">
                    <p>
                      <strong>{{ notification.content }}</strong>
                    </p>
                    <span>{{ notification?.timeAgo }}</span>
                  </div>
                </div>
                <div class="notification_wrapper__more_button">
                  <v-icon> <img src="../../assets/icons/dots.svg" /></v-icon>
                </div>
              </div>

              <div
                v-if="
                  notification.type === UserNotificationType.RAISE_NOTIFICATION
                "
                class="notification_wrapper__user_content"
              >
                <div class="notification_wrapper__user_content_info">
                  <div class="notification_wrapper__text">
                    <p>
                      <strong>{{ notification.content }}</strong>
                    </p>
                    <span>{{ notification?.timeAgo }}</span>
                  </div>
                </div>
                <div class="notification_wrapper__more_button">
                  <v-icon> <img src="../../assets/icons/dots.svg" /></v-icon>
                </div>
              </div>
              <div
                v-if="
                  notification.type ===
                  UserNotificationType.RAISE_ISSUE_BY_OCCUPIER
                "
                class="notification_wrapper__user_content"
              >
                <div class="notification_wrapper__user_content_info">
                  <div class="notification_wrapper__text">
                    <p>
                      <strong>{{ notification.content }}</strong>
                    </p>
                    <span>{{ notification?.timeAgo }}</span>
                  </div>
                </div>
                <div class="notification_wrapper__more_button">
                  <v-icon> <img src="../../assets/icons/dots.svg" /></v-icon>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
  <SelectMultipleWorkstationModal
    v-if="isShowSelectMultipleWorkstationModal"
    @closeMultiSelectWorkstationModal="closeMultiSelectWorkstationModal"
    :acceptInvitationDetails="acceptInvitationDetails"
  />
  <VideoComponent
    v-show="videoConsultationStatus"
    :roomDetails="roomDetails"
    :isDirectJoinVideoCall="true"
    ref="videoComponent"
    @leaveCall="leaveCall"
  />
</template>
<script lang="ts">
import { computed, getCurrentInstance, ref } from "vue";
import { useStore } from "vuex";
import { USER_STORE } from "@/store/modules/user";
import {
  UserNotificationType,
  UserNetworkRequestStatus,
  ProjectMetaDataStatusEnum,
} from "@/core/enums/index";
import TradeNetwork from "@/core/services/trade-network.service";
import Notification from "@/core/services/notification.service";
import NotificationService from "@/core/services/notification.service";
import { CONNECTIONS } from "@/store/modules/connections";
import { PREFERRED_CONTRACTORS } from "@/store/modules/preferred-contractors";
import { WORKSTATION } from "@/store/modules/workstation";
import moment from "moment";
import { NotificationStateEnum } from "@/core/enums/NotificationEnum";
import { onBeforeMount } from "vue";
import {
  UserRolesEnum,
  UserVerificationStatusEnum,
} from "@/core/enums/RolesEnum";
import { CHATMESSAGE } from "@/store/modules/chat-message";
import { UserMessagesRoomStatusEnum } from "@/core/enums/chatsEnums";
import SelectMultipleWorkstationModal from "@/modules/workstation/components/SelectMultipleWorkstationModal.vue";
import { TRADE_NETWORK } from "@/store/modules/trade-network";
import VideoComponent from "@/core/components/VideoComponent.vue";
import { parseJSON } from "@/core/utils/common";
import VideoService from "@/core/services/video.service";
import projectService from "@/core/services/project.service";
import { useRouter } from "vue-router";
import { PROJECT_REQUESTED_DETAILS_ROUTE } from "@/modules/project/routes";
import { PROJECT_STORE } from "@/store/modules/project";
import { HistoricProjectStatusEnum } from "../enums/ProjectsEnum";

export default {
  components: {
    SelectMultipleWorkstationModal,
    VideoComponent,
  },
  setup(props: any, ctx: any) {
    const store = useStore();
    const router = useRouter();
    const internalInstance = getCurrentInstance();

    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const isShowSelectMultipleWorkstationModal = ref(false);
    const acceptInvitationDetails = ref();

    const videoComponent = ref(null) as any;
    const userNotificationsWithTimeAgo = ref([]) as any;
    const userNotifications = computed(
      () => store.getters[`${USER_STORE}/userNotifications`]
    );

    const activeUserWorkstation = computed(
      () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
    );

    const videoConsultationStatus = ref(false);

    const roomDetails = ref({}) as any;

    const userFullName = (notification: any) => {
      return `${notification.fromUser?.firstName} ${notification.fromUser?.lastName}`;
    };

    const formatTimeAgo = (dateString: any) => {
      const dateCreated = moment.utc(dateString);
      const currentTime = moment.utc();
      const diffMinutes = currentTime.diff(dateCreated, "minutes");
      const diffHours = Math.abs(Math.floor(diffMinutes / 60));

      if (diffMinutes < 60) {
        return `${diffMinutes} min ago`;
      } else {
        if (diffHours < 24) {
          return `${diffHours} hr ago`;
        } else {
          const diffDays = currentTime.diff(dateCreated, "days");
          return `${diffDays} days ago`;
        }
      }
    };

    interface Notification {
      id: number;
      dateCreated: string;
      dateUpdated: string;
      sourceId: string;
      sourceType: string;
      state: string;
      type: string;
      content: string;
      metadata: string;
      timeAgo: string;
      profileImage: string;
    }

    interface CombinedNotifications {
      [date: string]: Notification[];
    }

    const combinedNotifications = computed<CombinedNotifications>(() => {
      const Notifications = [
        ...userNotifications.value,
        ...formattedTradeUserConnectionsList.value,
        ...formattedPropertyUserConnectionsList.value,
        ...formattedExternalTradeNetworkConnectionList.value,
      ];
      const notifications = Notifications.map((notification: Notification) => ({
        ...notification,
        profileImage: parseJSON(notification?.metadata)?.profileImage,
        timeAgo: formatTimeAgo(notification.dateCreated),
      }));

      const today = moment().format("YYYY-MM-DD");
      const yesterday = moment().subtract(1, "day").format("YYYY-MM-DD");

      const groupedByDate: CombinedNotifications = {};

      notifications.forEach((notification: Notification) => {
        const date = moment(notification.dateCreated).format("YYYY-MM-DD");
        if (!groupedByDate[date]) {
          groupedByDate[date] = [];
        }
        groupedByDate[date].push(notification);
      });

      const result: CombinedNotifications = {};

      if (groupedByDate[today]) {
        result["Today"] = groupedByDate[today];
      }

      if (groupedByDate[yesterday]) {
        result["Yesterday"] = groupedByDate[yesterday];
      }

      Object.keys(groupedByDate).forEach((date) => {
        if (date !== today && date !== yesterday) {
          result[date] = groupedByDate[date];
        }
      });

      return result;
    });

    const onClickMarkAllNotificationAsRead = async () => {
      userNotifications.value.forEach(async (notification: any) => {
        if (notification.state == NotificationStateEnum.UNREAD) {
          await Notification.updateNotification(notification.id, {
            state: NotificationStateEnum.READ,
          });
        }
      });
      await NotificationService.getUserNotificationStatus();
    };

    const hasUnreadNotifications = computed(() => {
      return Object.values(combinedNotifications.value).some(
        (group: Notification[]) =>
          group.some(
            (notification: Notification) =>
              notification.state === NotificationStateEnum.UNREAD
          )
      );
    });

    const changeRequestStatus = async (
      requestStatus: string,
      id: number,
      notificationId: number
    ) => {
      try {
        await TradeNetwork.updateRequestToContractor(id, {
          status: requestStatus,
        });
        await Notification.deleteNotification(notificationId);

        store.commit(`${USER_STORE}/resetUserNotifications`);
        const userNotifications =
          (await NotificationService.getUserNotificationStatus()) as any;
        userNotifications.forEach((userNotification: object) => {
          store.commit(`${USER_STORE}/setUserNotifications`, userNotification);
        });
      } catch (error) {
        console.log("error", error);
      }
    };

    const updateRaiseProjectRequestStatus = async (
      requestStatus: ProjectMetaDataStatusEnum,
      id: number,
      notificationId: number,
      metaData: any
    ) => {
      try {
        if (requestStatus === ProjectMetaDataStatusEnum.REJECTED) {
          await store.dispatch(`${PROJECT_STORE}/updateRaiseProjectStatus`, {
            projectId: id,
            data: {
              status: requestStatus,
            },
          });
        }

        await Notification.deleteNotification(notificationId);

        store.commit(`${USER_STORE}/resetUserNotifications`);
        const userNotifications =
          (await NotificationService.getUserNotificationStatus()) as any;
        userNotifications.forEach((userNotification: object) => {
          store.commit(`${USER_STORE}/setUserNotifications`, userNotification);
        });

        if (requestStatus === ProjectMetaDataStatusEnum.ACCEPTED) {
          const projectRefId = parseJSON(metaData)?.projectRefId;
          if (projectRefId) {
            router.push({
              name: PROJECT_REQUESTED_DETAILS_ROUTE,
              params: { refId: projectRefId },
            });
          }
        }
      } catch (error) {
        console.log("error", error);
      }
    };

    const closeMultiSelectWorkstationModal = async (type: string) => {
      isShowSelectMultipleWorkstationModal.value = false;
      store.commit(`${USER_STORE}/resetUserNotifications`);
      const userNotifications =
        (await NotificationService.getUserNotificationStatus()) as any;
      userNotifications.forEach((userNotification: object) => {
        store.commit(`${USER_STORE}/setUserNotifications`, userNotification);
      });

      if (
        user.value?.role?.name === UserRolesEnum.PROPERTY_OWNER &&
        activeUserWorkstation.value?.name !== "Default"
      ) {
        let params: any = {
          status: "accepted",
        };
        await store.dispatch(
          `${PREFERRED_CONTRACTORS}/getPreferredContractorsConnection`,
          {
            userId: user.value?.id,
            params: params,
          }
        );
      }
      if (user.value?.role?.name === UserRolesEnum.TRADESPERSON) {
        let params: any = {
          userId: user.value?.id,
          status: "accepted",
        };
        await store.dispatch(
          `${PREFERRED_CONTRACTORS}/fetchPreferredContractors`,
          params
        );
      }
      if (type === UserNotificationType.TRADE_USER_CONNECTION) {
        getTradeUserConnection();
      } else if (type === UserNotificationType.PROPERTY_USER_CONNECTION) {
        getPropertyUserConnection();
      }
    };

    const acceptInvitationStatus = async (
      id: string,
      metaData: string,
      notificationId: number
    ) => {
      await Notification.deleteNotification(notificationId);
      const data = JSON.parse(metaData);
      acceptInvitationDetails.value = {
        id,
        token: data.token,
        type: UserNotificationType.TRADE_USER_CONNECTION,
        notificationId: notificationId,
      };
      isShowSelectMultipleWorkstationModal.value = true;
    };
    const checkUserInvite = (notificationType: any) => {
      if (notificationType !== UserNotificationType.CONTRACTORINVITEREQUEST) {
        return true;
      }
      if (
        user.value?.role?.name === UserRolesEnum.PROPERTY_OWNER &&
        activeUserWorkstation.value?.name !== "Default"
      ) {
        return true;
      }
      if (user.value?.role?.name === UserRolesEnum.TRADESPERSON) {
        if (
          activeUserWorkstation.value?.verificationStatus ===
          UserVerificationStatusEnum.VERIFIED
        ) {
          return true;
        }
      } else {
        return false;
      }
    };

    const rejectInvitationStatus = async (id: string) => {
      try {
        // await store.dispatch(
        //   `${PREFERRED_CONTRACTORS}/rejectTradeUserConnection`,
        //   {
        //     userId: user.value?.id,
        //     tradeUserConnectionId: id,
        //   }
        // );
        await store.dispatch(
          `${PREFERRED_CONTRACTORS}/rejectRequestTradeUserConnection`,
          {
            userId: user.value?.id,
            tradeUserConnectionId: id,
          }
        );
        store.commit(`${USER_STORE}/resetUserNotifications`);
        const userNotifications =
          (await NotificationService.getUserNotificationStatus()) as any;
        userNotifications.forEach((userNotification: object) => {
          store.commit(`${USER_STORE}/setUserNotifications`, userNotification);
        });
      } catch (error) {
        console.log("error", error);
      }
    };

    const acceptProjectInvitationStatus = async (
      notificationId: number,
      id: string
    ) => {
      try {
        await store.dispatch(`${CHATMESSAGE}/acceptProjectChatStatus`, {
          userMessageId: parseInt(id),
          status: UserMessagesRoomStatusEnum.ACCEPTED,
        });

        await Notification.deleteNotification(notificationId);

        store.commit(`${USER_STORE}/resetUserNotifications`);
        const userNotifications =
          (await NotificationService.getUserNotificationStatus()) as any;
        userNotifications.forEach((userNotification: object) => {
          store.commit(`${USER_STORE}/setUserNotifications`, userNotification);
        });
      } catch (error) {
        console.log("error", error);
      }
    };

    const rejectProjectInvitationStatus = async (
      notificationId: number,
      id: string
    ) => {
      try {
        await store.dispatch(`${CHATMESSAGE}/rejectProjectChatStatus`, {
          userMessageId: parseInt(id),
        });

        await Notification.deleteNotification(notificationId);
        store.commit(`${USER_STORE}/resetUserNotifications`);

        const userNotifications =
          (await NotificationService.getUserNotificationStatus()) as any;
        userNotifications.forEach((userNotification: object) => {
          store.commit(`${USER_STORE}/setUserNotifications`, userNotification);
        });
      } catch (error) {
        console.log("error", error);
      }
    };

    const formattedTradeUserConnectionsList = ref([]);
    const getTradeUserConnection = async () => {
      try {
        const TradeUserConnection = await store.dispatch(
          `${PREFERRED_CONTRACTORS}/getTradeUserConnection`,
          {
            userId: user.value?.id,
          }
        );
        const formattedTradeUserConnections = TradeUserConnection.map(
          (connection: any) => ({
            id: connection?.id,
            dateCreated: connection?.dateCreated,
            dateUpdated: connection?.dateUpdated,
            sourceId: connection?.user?.id,
            token: connection?.token,
            sourceType: "TradeUserConnection",
            state: "unread",
            type: "TradeUserConnection",
            content: `${connection?.user?.firstName} ${connection?.user?.lastName}`,
            metadata: JSON.stringify({ id: connection?.id }),
            timeAgo: formatTimeAgo(connection?.dateCreated),
            preferred: true,
          })
        );

        formattedTradeUserConnectionsList.value = formattedTradeUserConnections;
      } catch (err) {
        console.log();
      }
    };

    const formattedExternalTradeNetworkConnectionList = ref([]);
    const getExternalTradeNetworkConnection = async () => {
      try {
        const externalTradeNetworkConnection = await store.dispatch(
          `${TRADE_NETWORK}/getExternalInvitedContractorForNotification`
        );
        const formattedExternalTradeNetworkConnection =
          externalTradeNetworkConnection.map((connection: any) => ({
            id: connection?.id,
            dateCreated: connection?.dateCreated,
            dateUpdated: connection?.dateUpdated,
            sourceId: connection?.user?.id,
            token: connection?.token,
            sourceType: "ExternalTradeNetwork",
            state: "unread",
            type: "ExternalTradeNetwork",
            content: `${connection?.user?.firstName} ${connection?.user?.lastName}`,
            metadata: JSON.stringify({ id: connection?.id }),
            timeAgo: formatTimeAgo(connection?.dateCreated),
          }));

        formattedExternalTradeNetworkConnectionList.value =
          formattedExternalTradeNetworkConnection;
      } catch (err) {
        console.log();
      }
    };

    const rejectExternalTradeNetworkInvitationStatus = async (
      id: number,
      metaData: any
    ) => {
      try {
        const data = JSON.parse(metaData);
        const response = await store.dispatch(
          `${TRADE_NETWORK}/rejectExternalContractorInvitation`,
          {
            rowId: data.id,
          }
        );
        if (response?.data?.statusCode === 500) {
          displayToast(response?.data?.message, "error");
        } else {
          data.requestStatus = "rejected";
          const payLoad = {} as any;
          payLoad.metadata = JSON.stringify(data);
          await Notification.updateOuterNetworkNotificationStatus(id, payLoad);
          store.commit(`${USER_STORE}/resetUserNotifications`);
          const userNotifications =
            (await NotificationService.getUserNotificationStatus()) as any;
          userNotifications.forEach((userNotification: object) => {
            store.commit(
              `${USER_STORE}/setUserNotifications`,
              userNotification
            );
          });
        }
      } catch (error) {
        console.log("error", error);
      }
    };

    const displayToast = (message: string, type: string) => {
      if (
        internalInstance &&
        internalInstance.appContext.config.globalProperties.$notify
      ) {
        internalInstance.appContext.config.globalProperties.$notify[type]({
          message,
        });
      }
    };
    const acceptExternalTradeNetworkInvitationStatus = async (
      id: number,
      metaData: any
    ) => {
      try {
        const data = JSON.parse(metaData);
        console.log("insiddeeee", typeof data);

        const response = await store.dispatch(
          `${TRADE_NETWORK}/acceptExternalContractorInvitation`,
          {
            rowId: data.id,
            token: data.token,
          }
        );
        if (response?.data?.statusCode === 500) {
          displayToast(response?.data?.message, "error");
        } else {
          data.requestStatus = "accepted";
          const payLoad = {} as any;
          payLoad.metadata = JSON.stringify(data);
          await Notification.updateOuterNetworkNotificationStatus(id, payLoad);
          store.commit(`${USER_STORE}/resetUserNotifications`);
          const userNotifications =
            (await NotificationService.getUserNotificationStatus()) as any;
          userNotifications.forEach((userNotification: object) => {
            store.commit(
              `${USER_STORE}/setUserNotifications`,
              userNotification
            );
          });
        }
      } catch (error) {
        console.log(error);
      }
    };

    const formattedPropertyUserConnectionsList = ref([]);

    const getPropertyUserConnection = async () => {
      try {
        const TradeUserConnection = await store.dispatch(
          `${CONNECTIONS}/getPropertyUserConnection`,
          {
            userId: user.value?.id,
          }
        );
        const formattedPropertyUserConnections = TradeUserConnection.map(
          (connection: any) => ({
            id: connection?.id,
            dateCreated: connection?.dateCreated,
            dateUpdated: connection?.dateUpdated,
            sourceId: connection?.user?.id,
            token: connection?.token,
            sourceType: UserNotificationType.PROPERTY_USER_CONNECTION,
            state: "unread",
            type: UserNotificationType.PROPERTY_USER_CONNECTION,
            content: `${connection?.user?.firstName} ${connection?.user?.lastName}`,
            metadata: JSON.stringify({ id: connection?.id }),
            timeAgo: formatTimeAgo(connection?.dateCreated),
          })
        );

        formattedPropertyUserConnectionsList.value =
          formattedPropertyUserConnections;
      } catch (err) {
        console.log();
      }
    };

    const acceptPropertyInvitationStatus = async (
      id: number,
      token: string
    ) => {
      acceptInvitationDetails.value = {
        id,
        token,
        type: UserNotificationType.PROPERTY_USER_CONNECTION,
      };
      isShowSelectMultipleWorkstationModal.value = true;
    };

    const rejectPropertyInvitationStatus = async (id: number) => {
      try {
        await store.dispatch(`${CONNECTIONS}/rejectPropertyUserConnection`, {
          userId: user.value?.id,
          tradeUserConnectionId: id,
          type: "rejected",
        });
        getPropertyUserConnection();
      } catch (error) {
        console.log("error", error);
      }
    };

    const onClickAcceptVideoCall = async (notification: any) => {
      roomDetails.value["title"] = parseJSON(notification.metadata)?.id;
      roomDetails.value["url"] = parseJSON(notification.metadata)?.videoCallUrl;
      roomDetails.value["token"] = parseJSON(notification.metadata)?.token;

      videoConsultationStatus.value = true;
      videoComponent.value?.onClickVideoCallButton();
      await Notification.deleteNotification(notification.id);
      store.commit(`${USER_STORE}/resetUserNotifications`);
      const userNotifications =
        (await NotificationService.getUserNotificationStatus()) as any;
      userNotifications.forEach((userNotification: object) => {
        store.commit(`${USER_STORE}/setUserNotifications`, userNotification);
      });
      ctx.emit("onCloseNotificationComponent");
    };

    const onClickRejectVideoCall = async (notification: any) => {
      const isGroupCall = parseJSON(notification.metadata)?.isGroup;
      if (!isGroupCall) {
        await VideoService.deleteRoom(parseJSON(notification.metadata)?.id);
      }
      await Notification.deleteNotification(notification.id);
      store.commit(`${USER_STORE}/resetUserNotifications`);
      const userNotifications =
        (await NotificationService.getUserNotificationStatus()) as any;
      userNotifications.forEach((userNotification: object) => {
        store.commit(`${USER_STORE}/setUserNotifications`, userNotification);
      });
      ctx.emit("onCloseNotificationComponent");
    };

    const leaveCall = () => {
      videoConsultationStatus.value = false;
      roomDetails.value = {};
    };

    const onClientHistoricalProjectStatus = async (
      requestStatus: string,
      notification: any
    ) => {
      try {
        const metadata = parseJSON(notification.metadata);
        store.dispatch(`${PROJECT_STORE}/updateHistoricalProjectStatus`, {
          projectId: metadata?.projectId,
          data: {
            historicalProjectStatus: requestStatus,
            projectEstimateId: metadata?.projectEstimateId,
          },
        });
        await Notification.deleteNotification(notification.id);

        store.commit(`${USER_STORE}/resetUserNotifications`);
        const userNotifications =
          (await NotificationService.getUserNotificationStatus()) as any;
        userNotifications.forEach((userNotification: object) => {
          store.commit(`${USER_STORE}/setUserNotifications`, userNotification);
        });
      } catch (error) {
        console.log("error", error);
      }
    };

    const outerNetworkRequestStatus = (data: any) => {
      const jsonData = JSON.parse(data);
      const status = jsonData.requestStatus;
      if (status === "requested") {
        return true;
      } else {
        return false;
      }
    };

    const showOuterNetworkStatus = (data: any) => {
      const jsonData = JSON.parse(data);
      const status = jsonData.requestStatus;
      return status;
    };

    onBeforeMount(() => {
      if (
        user.value?.role?.name === UserRolesEnum.PROPERTY_OWNER &&
        activeUserWorkstation.value?.name !== "Default"
      ) {
        getPropertyUserConnection();
        // getTradeUserConnection();
      }
      if (
        activeUserWorkstation.value?.verificationStatus ===
        UserVerificationStatusEnum.VERIFIED
      ) {
        // getTradeUserConnection();
        // getExternalTradeNetworkConnection();
      }
    });
    return {
      user,
      userNotifications,
      UserNotificationType,
      userFullName,
      changeRequestStatus,
      UserNetworkRequestStatus,
      userNotificationsWithTimeAgo,
      combinedNotifications,
      NotificationStateEnum,
      onClickMarkAllNotificationAsRead,
      acceptInvitationStatus,
      rejectInvitationStatus,
      hasUnreadNotifications,
      acceptProjectInvitationStatus,
      rejectProjectInvitationStatus,
      isShowSelectMultipleWorkstationModal,
      closeMultiSelectWorkstationModal,
      acceptInvitationDetails,
      acceptPropertyInvitationStatus,
      rejectPropertyInvitationStatus,
      rejectExternalTradeNetworkInvitationStatus,
      acceptExternalTradeNetworkInvitationStatus,
      videoConsultationStatus,
      onClickAcceptVideoCall,
      onClickRejectVideoCall,
      roomDetails,
      videoComponent,
      leaveCall,
      updateRaiseProjectRequestStatus,
      ProjectMetaDataStatusEnum,
      onClientHistoricalProjectStatus,
      HistoricProjectStatusEnum,
      activeUserWorkstation,
      UserVerificationStatusEnum,
      outerNetworkRequestStatus,
      showOuterNetworkStatus,
      UserRolesEnum,
      checkUserInvite,
    };
  },
};
</script>

<style lang="scss" scoped>
.notification_wrapper {
  position: relative;
  &:before {
    right: 45px;
    top: 65px;
    height: 30px;
    width: 40px;
    position: absolute;
    pointer-events: none;
    margin-top: 0;
    content: "";
    background-image: url(@/assets/icons/triangle.svg);
    @include respond(sm) {
      // right: -10px;
    }

    @media screen and (max-width: 639px) {
      right: -9px !important;
      top: -23px !important;
      height: 14px !important;
      width: 40px !important;
    }
  }
  .notification_wrapper_body {
    padding: 24px 16px;
    background-color: white;
    box-shadow: 0px 0px 8px 0px #0c0f4a14;
    border-radius: 16px;
    max-width: 545px;
    width: 100%;
    position: absolute;
    right: -20px;
    z-index: 4;
    top: 0;
    max-height: 700px;
    overflow: auto;
    // @include respond(xlg) {
    //   right: 0;
    //   margin-right: 30px;
    // }
    // @include respond(lg) {
    //   margin-right: 25px;
    // }
    // @include respond(s720) {
    //   width: calc(100% - 24px);
    //   max-width: 100%;
    //   margin: 0 auto;
    //   right: 0;
    //   left: 0;
    //   max-height: 650px;
    // }
    .notification_wrapper__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h5 {
        @include fluidFont(20, 14, 1.2);
        font-weight: 700;
        letter-spacing: 0.15px;
        color: rgba($blueDark, 1);
      }
      p {
        @include fluidFont(14, 14, 1.2);
        font-weight: 600;
        letter-spacing: 0.15px;
        color: rgba($PrimaryBlue, 1);
        cursor: pointer;
      }
    }
    .prefix {
      margin-top: 16px;
      text-align: left;
      span {
        @include fluidFont(14, 14, 1.2);
        font-weight: 700;
        letter-spacing: 0.15px;
        color: rgba($buttonText, 1);
      }
    }
    .notification_wrapper__list {
      margin-top: 16px;
      text-align: left;
      .notification_wrapper__list_item {
        padding: 16px 0;
        border-bottom: 1px solid #f2f2f2;
        border-radius: 7px;
        position: relative;
        .notification_wrapper__new_item {
          width: 8px;
          height: 8px;
          border-radius: 100%;
          background-color: rgba($PrimaryBlue, 1);
          position: absolute;
          top: 35px;
        }
        .notification_wrapper__list_item_content {
          display: flex;
          gap: 16px;
          align-items: flex-start;
          max-width: 490px;
          margin-left: auto;
          @include respond(s720) {
            max-width: calc(100% - 20px);
          }
          .notification_wrapper__img {
            width: 48px;
            height: 48px;
            border-radius: 100%;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .notification_wrapper__user_content {
            width: calc(100% - 64px);
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            .notification_wrapper__user_content_info {
              .notification_wrapper__text {
                p {
                  @include fluidFont(14, 14, 1.2);
                  font-weight: 400;
                  letter-spacing: 0.15px;
                  color: rgba($blueDark, 1);
                  margin-bottom: 4px;
                }
                span {
                  @include fluidFont(14, 14, 1.3);
                  font-weight: 400;
                  letter-spacing: 0.15px;
                  color: rgba($buttonText, 1);
                }
              }
              .notification_wrapper__buttons {
                margin-top: 10px;
                display: flex;
                gap: 16px;
                align-items: center;
                .notification_wrapper__accept {
                  background-color: rgba($PrimaryGreen, 1);
                  border-radius: 8px;
                  color: rgba($white, 1);
                  @include fluidFont(14, 14, 1.2);
                  font-weight: 700;
                  letter-spacing: 1.25px;
                }
                .notification_wrapper__reject {
                  background-color: rgba($PrimaryRed, 1);
                  border-radius: 8px;
                  color: rgba($white, 1);
                  @include fluidFont(14, 14, 1.2);
                  font-weight: 700;
                  letter-spacing: 1.25px;
                }
              }
            }
            .notification_wrapper__more_button {
              img {
                filter: invert(86%) sepia(10%) saturate(352%) hue-rotate(199deg)
                  brightness(86%) contrast(87%);
              }
            }
          }
        }
      }
    }
  }
}
.notification_wrapper:before {
  right: 0;
  top: -20px;
  height: 30px;
  width: 40px;
  position: absolute;
  pointer-events: none;
  margin-top: 0;
  content: "";
  background-image: url(../../assets/icons/triangle.svg);
}
</style>
