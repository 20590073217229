<template>
  <div class="newgroupchatMessages">
    <v-text-field
      class="chatbox__search create_group__search"
      v-model="searchNetworkUsers"
      label="Search"
      prepend-inner-icon="mdi-magnify"
      variant="solo-filled"
      hide-details
      single-line
      flat
      placeholder="Search"
    ></v-text-field>

    <div class="edit__group">
      <div class="newgroupchatMessages__inputs__wrapper">
        <v-form @submit.prevent="">
          <div class="group_input_content">
            <div class="uploade-wrapper">
              <v-file-input
                class="upload_file"
                variant="solo-filled"
                v-model="groupAvatar"
                accept="image/*"
                label="Group Avatar"
                @change="handleFileChange"
                :on-click:clear="onClearGroupAvatar"
              >
              </v-file-input>
              <v-img
                class="uploaded-img"
                :width="200"
                aspect-ratio="16/9"
                cover
                :src="imageUrl"
              ></v-img>
            </div>

            <v-text-field
              v-model="groupName"
              class="messagechat__message"
              variant="solo-filled"
              hide-details
              single-line
              flat
              label="Group Name"
            ></v-text-field>
          </div>
        </v-form>
      </div>
      <div class="newgroupchatMessages__userNetworkContacts__wrapper">
        <div class="group_selector">
          <v-list dense>
            <v-list-item
              v-for="(item, index) in getGroupNetWorkContactList"
              :key="index"
              @click="toggleCheckboxOnClick(index)"
              :disabled="isLoading"
            >
              <div class="group_img">
                <img
                  v-if="item?.isUserChat"
                  :src="
                    getImagePublicUrl(item?.receiverWorkstation?.user)
                      ? getImagePublicUrl(item?.receiverWorkstation?.user)
                      : require('@/assets/images/user-placeholder.png')
                  "
                  alt="workStation_img"
                />
                <img
                  v-else
                  :src="
                    getImagePublicUrl(item?.receiverWorkstation)
                      ? getImagePublicUrl(item?.receiverWorkstation)
                      : require('@/assets/images/user-placeholder.png')
                  "
                  alt="workStation_img"
                />
              </div>

              <v-list-item-action>
                <v-checkbox
                  :modelValue="isChecked(item)"
                  @update:modelValue="
                    (value:any) => updateSelectedItems(item, value)
                  "
                  :label="
                    item?.isUserChat
                      ? `${item?.receiverWorkstation?.user?.firstName} ${item?.receiverWorkstation?.user?.lastName}`
                      : item?.receiverWorkstation?.name
                  "
                ></v-checkbox>
              </v-list-item-action>
            </v-list-item>
            <div v-if="getGroupNetWorkContactList.length === 0">
              No users found
            </div>
          </v-list>
        </div>
        <div class="submit_button">
          <v-btn
            class="button button-orange"
            v-if="
              groupName &&
              groupName.trim() !== '' &&
              groupAvatar &&
              selectedItems.length
            "
            :disabled="isLoading"
            :isloading="isLoading"
            @click="createNewUserNetWorkGroup"
          >
            Create New Group
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { USER_STORE } from "@/store/modules/user";
import { ref, computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "NewGroupChatComponent",
  props: {
    groupNetWorkContactList: {
      type: Array,
    },
    projectId: {
      type: Number,
    },
    userImagesMap: {
      type: Object,
    },
  },
  setup(props: any, ctx: any) {
    const store = useStore();
    const selectedItems = ref([]) as any;
    const imageUrl = ref("") as any;
    const groupName = ref("");
    const groupAvatar = ref(null) as any;
    const isLoading = ref(false);
    const searchNetworkUsers = ref("");
    const handleFileChange = (event: any) => {
      const fileInput = event.target;
      if (fileInput.files && fileInput.files.length > 0) {
        const file = fileInput.files[0];
        imageUrl.value = URL.createObjectURL(file);
        groupAvatar.value = file;
      } else {
        imageUrl.value = null;
      }
    };
    const createNewUserNetWorkGroup = async () => {
      try {
        if (selectedItems.value) {
          const users = [] as any;
          isLoading.value = true;
          const groupFormData = new FormData();
          groupFormData.append("file", groupAvatar.value);
          const responseImage = await store.dispatch(
            `${USER_STORE}/uploadProfileImage`,
            groupFormData
          );

          if (responseImage) {
            isLoading.value = false;
            selectedItems.value.map((item: any) => {
              users.push({
                userId: item?.receiverWorkstation?.user?.id,
                workstationId: item?.receiverWorkstation?.id,
              });
            });
            const formData = {
              projectId: props.projectId,
              groupName: groupName.value,
              groupAvatar: responseImage?.attachment,
              users,
            };

            if (formData) {
              ctx.emit("saveNewUserGroup", formData);
            }
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };
    //Toggle the checkbox when click on div
    const toggleCheckboxOnClick = (index: any) => {
      const selectedItem = props.groupNetWorkContactList[index];
      const selectedIndex = selectedItems.value.findIndex(
        (item: any) => item === selectedItem
      );

      if (selectedIndex === -1) {
        selectedItems.value.push(selectedItem);
      } else {
        selectedItems.value.splice(selectedIndex, 1);
      }
    };

    const updateSelectedItems = (item: any, value: any) => {
      if (value) {
        selectedItems.value.push(item);
      } else {
        const index = selectedItems.value.findIndex(
          (selectedItem: any) => selectedItem === item
        );
        if (index !== -1) {
          selectedItems.value.splice(index, 1);
        }
      }
    };
    // Define computed property
    const isChecked = computed(() => {
      return (item: any) => selectedItems.value.includes(item);
    });

    const getImagePublicUrl = (message: any) => {
      const imgUrl = props.userImagesMap[message.id];
      if (imgUrl.startsWith("@/assets")) {
        return require("@/assets/images/user-placeholder.png");
      }
      return imgUrl;
    };
    const getGroupNetWorkContactList = computed(() => {
      const searchTerm = searchNetworkUsers?.value?.trim()?.toLowerCase();
      if (searchTerm && searchTerm !== "") {
        return props.groupNetWorkContactList.filter((user: any) =>
          user?.receiverWorkstation?.name?.toLowerCase().includes(searchTerm)
        );
      }
      return props.groupNetWorkContactList;
    });
    const onClearGroupAvatar = () => {
      imageUrl.value = null;
    };
    return {
      selectedItems,
      groupName,
      groupAvatar,
      createNewUserNetWorkGroup,
      imageUrl,
      handleFileChange,
      toggleCheckboxOnClick,
      isChecked,
      updateSelectedItems,
      isLoading,
      getImagePublicUrl,
      searchNetworkUsers,
      getGroupNetWorkContactList,
      onClearGroupAvatar,
    };
  },
};
</script>
<style scoped lang="scss">
.newgroupchatMessages__inputs__wrapper {
  margin: 24px 0;
  .v-form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    .group_input_content {
      display: flex;
      align-items: center;
      width: 100%;
      max-width: 100%;
      gap: 8px;
      .uploade-wrapper {
        width: 40px;
        height: 40px;
        margin: auto;
        cursor: pointer;
        .upload_file {
          margin: 0;
          :deep(.v-input__control) {
            .v-field {
              .v-field__field {
                .v-label {
                  display: none;
                }
                .v-field__input {
                  display: none;
                }
              }
              .v-field__clearable {
                width: unset;
                height: unset;
                background-color: transparent;
                .v-icon {
                  @include fluidFont(12, 12, 1);
                  border-radius: 100%;
                  color: rgba($blueDark, 1);
                  left: 30px;
                  top: -5px;
                }
              }
            }
          }
        }
        .uploaded-img {
          position: absolute;
          top: 0;
          left: 0;
          border-radius: 100%;
          -o-object-fit: cover;
          object-fit: cover;
          height: 100%;
          width: 100%;
          pointer-events: none;
          z-index: -1;
        }
      }
      .messagechat__message {
        width: calc(100% - 40px) !important;
        max-width: 100%;
        :deep(.v-input__control) {
          .v-field {
            border-radius: 8px;
            padding: 4px 12px;
            .v-field__field {
              .v-label {
                margin: 0;
              }
              .v-field__input {
                min-height: 28px;
                padding: 0;
                @include fluidFont(12, 12, 1.2);
                color: rgba($blueDark, 1);
                letter-spacing: 0.25px;
              }
              ::placeholder {
                @include fluidFont(12, 12, 1.2);
                font-weight: 400;
                color: rgba($blueDark, 0.5);
              }
            }
          }
        }
      }
    }
    .text-button {
      height: auto;
      min-width: auto;
      padding: 0;
      box-shadow: none;
      opacity: 1;

      :deep(.v-btn__prepend) {
        margin-inline: 0;
      }
      :deep(.v-ripple__container) {
        display: none !important;
      }
    }
  }
}
.newgroupchatMessages__userNetworkContacts__wrapper {
  .group_selector {
    height: 380px;
    overflow: auto;
    margin-bottom: 20px;
    @include respond(sm) {
      height: 280px;
    }
    .v-list {
      .v-list-item {
        padding: 0;
        :deep(.v-list-item__content) {
          display: flex;
          align-items: center;
          gap: 12px;
          .group_img {
            width: 20px;
            height: 20px;
            border-radius: 100%;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .v-list-item-action {
            width: calc(100% - 40px);
            max-width: 100%;
            .v-input {
              width: 100%;
              .v-input__control {
                min-height: auto;
                .v-selection-control {
                  flex-direction: row-reverse;
                  justify-content: space-between;
                  min-height: auto;
                  .v-selection-control__wrapper {
                    width: auto;
                    height: auto;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .submit_button {
    .button {
      margin-left: auto;
    }
  }
}
.newgroupchatMessages {
  height: 100%;
  .edit__group {
    //height: 100%;
    .newgroupchatMessages__userNetworkContacts__wrapper {
      @include respond(sm) {
        height: 420px;
      }
    }
  }
}

.chatbox__search {
  .v-input__control {
    .v-field {
      border-radius: 9px;
      .v-field__input {
        padding: 0;
        min-height: unset;
        @include fluidFont(12, 12, 1.2);
        font-weight: 400;
        color: rgba($blueDark, 1);
      }
      .v-field__field {
        label {
          @include fluidFont(12, 12, 1);
          color: rgba(142, 142, 147, 1);
        }
      }
    }
    .v-field--prepended {
      padding: 0;
      border-color: rgba(0, 0, 0, 0.04);
      display: flex;
      align-items: center;
      height: 38px;
      padding: 8px 16px;
      gap: 8px;
      border-radius: 9px;
    }
  }
}

.create_group__search {
  border-bottom: 1px solid rgba($blueDark, 0.18);
  padding: 16px 0;
  :deep(.v-input__control) {
    .v-field {
      border-radius: 9px;
      .v-field__input {
        padding: 0;
        min-height: unset;
        @include fluidFont(12, 12, 1.2);
        font-weight: 400;
        color: rgba($blueDark, 1);
      }
      ::placeholder {
        @include fluidFont(12, 12, 1.2);
        font-weight: 400;
        color: rgba($blueDark, 0.5);
      }
      .v-field__field {
        label {
          @include fluidFont(12, 12, 1);
          color: rgba(142, 142, 147, 1);
        }
      }
    }
    .v-field--prepended {
      padding: 0;
      border-color: rgba(0, 0, 0, 0.04);
      display: flex;
      align-items: center;
      height: 38px;
      padding: 8px 16px;
      gap: 8px;
      border-radius: 9px;
    }
  }
}
</style>
