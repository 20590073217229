<template>
  <div v-if="!isLoading" class="invitation_list__wrapper">
    <div class="title">
      <h3 class="invitation_list__title">Invitations</h3>
    </div>
    <v-table
      class="tw-pt-8 tw-pb-4 tw-box-border tw-bg-transparent desktop__wrapper"
    >
      <thead>
        <tr>
          <th class="text-left">
            <Text variant="p">Email ID</Text>
          </th>
          <!-- <th class="text-left">
            <Text variant="p" whiteSpace="nowrap">Invited by</Text>
          </th> -->
          <th class="text-center">
            <Text variant="p" whiteSpace="nowrap">ROLE</Text>
          </th>
          <th class="text-center">
            <Text variant="p" whiteSpace="nowrap">Invited Date</Text>
          </th>
          <th class="text-center">
            <Text variant="p" whiteSpace="nowrap">Action</Text>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(member, index) in modifiedMembers"
          :key="index"
          class="tw-cursor-pointer"
        >
          <td class="text-left">
            <div class="tw-flex tw-items-center tw-gap-2">
              <div
                class="tw-rounded-full tw-overflow-hidden tw-h-[30px] tw-w-[30px] tw-relative tw-max-w-full tw-max-h-full"
              >
                <UserProfileLogo
                  :color="member?.color"
                  :userName="member?.email"
                />
              </div>
              <Text class="tw-whitespace-nowrap" variant="p">
                {{ member?.email }}</Text
              >
            </div>
          </td>
          <!-- <td class="text-left">
            <Text class="tw-whitespace-nowrap" variant="p">
              {{ member?.user?.firstName }} + ' ' +
              {{ member?.user?.lstName }}</Text
            >
          </td> -->
          <td class="text-center">
            <Text class="tw-whitespace-nowrap tw-capitalize" variant="p">{{
              member?.role
            }}</Text>
          </td>
          <td class="text-center">
            <Text class="tw-whitespace-nowrap" variant="p">{{
              member?.dateCreated ? formatDate(member?.dateCreated) : "--"
            }}</Text>
          </td>
          <td class="text-center tw-text-[#FFA500]">
            <div>
              <Text
                @click="onClickRemoveTeamMember(member?.id, member)"
                class="tw-whitespace-nowrap"
                variant="p"
                >cancel</Text
              >
            </div>
          </td>
        </tr>
      </tbody>
    </v-table>
    <div class="invitation_list__content mobile__wrapper">
      <v-card
        class="invitation_list__card"
        v-for="(member, index) in modifiedMembers"
        :key="index"
      >
        <div class="member_name">
          <div
            class="tw-rounded-full tw-overflow-hidden tw-h-[30px] tw-w-[30px] tw-relative tw-max-w-full tw-max-h-full"
          >
            <UserProfileLogo :color="member?.color" :userName="member?.email" />
          </div>
          <Text class="tw-whitespace-nowrap" variant="p">
            {{ member?.email }}</Text
          >
        </div>
        <div class="member_card__content">
          <!-- <div class="content_list_item">
            <h5>INVITED BY</h5>
            <p>
              {{ member?.user?.firstName }} + ' ' + {{ member?.user?.lstName }}
            </p>
          </div> -->
          <div class="content_list_item">
            <h5>Role</h5>
            <p class="tw-capitalize">
              {{ member?.role }}
            </p>
          </div>
          <div class="content_list_item">
            <h5>INVITED DATE</h5>
            <p style="text-transform: capitalize">
              {{ member?.dateCreated ? formatDate(member?.dateCreated) : "--" }}
            </p>
          </div>
        </div>
        <div class="manage_card_footer">
          <v-btn
            @click="onClickRemoveTeamMember(member?.id, member)"
            class="button button-purple-border"
            max-width="100%"
            >CANCEL INVITATION</v-btn
          >
        </div>
      </v-card>
    </div>
    <CommonDialog
      v-if="isShowRemoveTeamMemberModal"
      :isShowHeader="false"
      :onClickBackButton="onClickBackButton"
      :className="['remove_invitation_dialog']"
      width="350px"
      ><template v-slot:body>
        <div class="modal__content">
          <div class="modal__content_img">
            <img src="../../../../assets/icons/warning-red-icon.svg" />
          </div>
          <div class="modal__content_header">
            <h2>Cancel Invitation?</h2>
            <p>
              Are you sure you want to cancel
              <span>{{ memberName }}'s</span> invitation to iknowa platfrom?
            </p>
          </div>
        </div>
        <div class="modal__footer">
          <v-btn class="button button-purple-border" @click="onClickBackButton"
            >Cancel</v-btn
          >
          <v-btn
            class="button button-orange"
            @click="onClickConfirmRemoveTeamMember(userMemberId)"
            >CONFIRM</v-btn
          >
        </div>
      </template>
    </CommonDialog>
    <AddTeamMemberModal
      v-if="openedModel === modelName.ADDTEAMMEMBER"
      @onClickCloseTab="onClickCloseModel"
    />
    <EditTeamMemberModal
      v-if="openedModel === modelName.EDITTEAMMEMBER"
      :memberData="memberData"
      @onClickCloseTab="onClickCloseModel"
    />
  </div>
  <!-- LOADER -->
  <div
    v-if="isLoading"
    class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-full tw-w-full"
  >
    <Loader :show="isLoading" />
  </div>
</template>

<script lang="ts">
import { ref, onBeforeMount, computed } from "vue";
import { useStore } from "vuex";
import { USER_STORE } from "@/store/modules/user";
import { WORKSTATION } from "@/store/modules/workstation";
import Loader from "@/core/components/common/Loader.vue";
import { MEMBER_STORE } from "@/store/modules/manage-member";
import UserProfileLogo from "@/core/components/UserProfileLogo.vue";
import AddTeamMemberModal from "@/modules/trade-passport/components/AddTeamMemberModal.vue";
import EditTeamMemberModal from "@/modules/trade-passport/components/EditTeamMemberModal.vue";
import CommonDialog from "@/core/components/CommonDialog.vue";

export default {
  components: {
    Loader,
    UserProfileLogo,
    AddTeamMemberModal,
    EditTeamMemberModal,
    CommonDialog,
  },
  setup() {
    const store = useStore();

    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const isLoading = ref(false);
    const teamMemberList = ref();
    const modifiedMembers = ref([]) as any;
    const openedModel = ref("");
    const modelName = {
      ADDTEAMMEMBER: "Add Member",
      EDITTEAMMEMBER: "Edit Member",
    };
    const memberData = ref();
    const memberName = ref("");
    const userMemberId = ref();
    const isShowRemoveTeamMemberModal = ref(false);
    const colors = ["#0C0F4A", "#FFA500", "#4F55F0"];

    const activeUserWorkstation = computed(
      () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
    );

    const formatDate = (dateString: string) => {
      const date = new Date(dateString);
      const day = date.getUTCDate();
      const month = date.getUTCMonth() + 1;
      const year = date.getUTCFullYear();
      const formattedDay = day < 10 ? "0" + day : day;
      const formattedMonth = month < 10 ? "0" + month : month;
      return `${formattedDay}/${formattedMonth}/${year}`;
    };

    const getTeamMember = async () => {
      try {
        const response = await store.dispatch(`${MEMBER_STORE}/getTeamMember`, {
          userId: user.value?.id,
        });

        teamMemberList.value = response;
      } catch (err) {
        console.log();
      }
    };

    const getWorkstationProfile = async (profileImage: string) => {
      if (!profileImage) {
        return null;
      }
      try {
        const imagePath = profileImage;
        const response = await store.dispatch(
          `${USER_STORE}/getImage`,
          imagePath
        );
        if (response) {
          return response;
        }
        return null;
      } catch (error) {
        return null;
      }
    };
    const modifyMembersList = async () => {
      isLoading.value = true;
      if (!teamMemberList.value || !teamMemberList.value.length) {
        isLoading.value = false;
        return;
      }
      try {
        // Filter members with invitationStatus 'pending'
        const acceptedMembers = teamMemberList.value.filter(
          (member: { invitationStatus: string }) =>
            member.invitationStatus === "pending"
        );

        // Check if there are any accepted members
        if (!acceptedMembers.length) {
          isLoading.value = false;
          return;
        }

        const defaultMember = acceptedMembers[0];
        const { profileImage } = defaultMember?.userWorkstation;

        const source = await getWorkstationProfile(profileImage);

        acceptedMembers.forEach((member: any, index: number) => {
          const color = colors[index % colors.length];
          if (index === 0) {
            modifiedMembers.value.push({
              ...member,
              attachmentSource: source,
              color: color,
            });
          } else {
            modifiedMembers.value.push({
              ...member,
              color: color,
            });
          }
        });
      } catch (error) {
        isLoading.value = false;
      } finally {
        isLoading.value = false;
      }
    };

    const onClickConfirmRemoveTeamMember = async (userMemberId: number) => {
      try {
        const response = await store.dispatch(
          `${MEMBER_STORE}/deleteMemberDetails`,
          {
            userId: user.value?.id,
            userMemberId: userMemberId,
          }
        );
      } catch (error) {
        console.error("Error updating member details:", error);
      } finally {
        isShowRemoveTeamMemberModal.value = false;
        getTeamMember();
      }
    };

    const onClickBackButton = () => {
      isShowRemoveTeamMemberModal.value = false;
    };

    const onClickEditTeamMember = (member: any) => {
      memberData.value = member;
      openedModel.value = modelName.EDITTEAMMEMBER;
    };

    const onClickRemoveTeamMember = (id: number, name: any) => {
      memberName.value = name?.userName
        ? name?.userName
        : name?.email?.split("@")[0];
      userMemberId.value = id;
      isShowRemoveTeamMemberModal.value = true;
    };

    const onClickCloseModel = async () => {
      openedModel.value = "";
      getTeamMember();
    };

    onBeforeMount(async () => {
      await getTeamMember();
      await modifyMembersList();
    });
    return {
      user,
      activeUserWorkstation,
      formatDate,
      isLoading,
      modifiedMembers,
      teamMemberList,
      onClickCloseModel,
      openedModel,
      modelName,
      memberData,
      onClickEditTeamMember,
      userMemberId,
      memberName,
      onClickRemoveTeamMember,
      isShowRemoveTeamMemberModal,
      onClickConfirmRemoveTeamMember,
      onClickBackButton,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/modules/workstation-setting/styles/InvitationListSetting.scss";
</style>
