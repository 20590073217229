<template>
  <CommonDialog
    v-if="isShowTeamMemberList"
    :isShowHeader="false"
    :isShowBackButton="false"
    :className="['team_member_dialog']"
    width="700px"
    height="auto"
    ><template v-slot:body
      ><div class="header">
        <h4>Manage Team ({{ modifiedMembers?.length }})</h4>
        <v-btn icon="mdi-close" @click="onClickCloseButton"></v-btn>
      </div>
      <div class="modal__content" v-if="modifiedMembers?.length > 0">
        <div
          class="member-list-card__item !tw-flex-col tw-gap-4"
          v-for="(member, index) in modifiedMembers"
          :key="index"
        >
          <div
            class="tw-w-full tw-flex tw-items-start tw-gap-4 tw-justify-between"
          >
            <div class="member-list-card__item_info">
              <div v-if="index === 0">
                <ProfileThumbnail
                  size="md"
                  v-if="member?.userProfileImageUrl"
                  :src="member?.userProfileImageUrl"
                />
                <div
                  v-else
                  class="tw-rounded-full tw-overflow-hidden tw-max-h-[48px] tw-max-w-[48px] tw-min-h-[48px] tw-min-w-[48px] tw-h-[48px] tw-relative"
                >
                  <UserProfileLogo :userName="member?.userName" />
                </div>
              </div>
              <div v-else>
                <ProfileThumbnail
                  size="md"
                  v-if="member?.userProfileImageUrl"
                  :src="member?.userProfileImageUrl"
                />
                <div
                  v-else
                  class="tw-rounded-full tw-overflow-hidden tw-max-h-[48px] tw-max-w-[48px] tw-min-h-[48px] tw-min-w-[48px] tw-h-[48px] tw-relative"
                >
                  <UserProfileLogo :userName="member?.email" />
                </div>
              </div>
              <div class="profile_info_wrapper">
                <div class="text-wrap">
                  <div class="member-list-first-row">
                    <div class="member-list-info">
                      <div class="member-list-name">
                        {{
                          member?.userName
                            ? member?.userName
                            : member?.email?.split("@")[0]
                        }}
                      </div>
                    </div>
                    <div class="custom_tags desktop_wrapper">
                      <div class="member-list-role">{{ member?.role }}</div>
                      <div
                        class="member-list-tags"
                        v-for="tag in parseJSON(member?.tags)"
                        :key="tag"
                      >
                        <div class="member-list-tag">{{ tag }}</div>
                      </div>
                    </div>
                  </div>
                  <p>{{ member?.email }}</p>
                </div>
              </div>
            </div>

            <div class="tw-flex tw-flex-col tw-gap-2 tw-items-end">
              <div class="action-btn-wrap" v-if="index !== 0">
                <v-btn
                  icon="mdi-pencil"
                  variant="text"
                  color="#80829F"
                  @click="onClickEditTeamMember(member)"
                />
                <v-btn
                  icon="mdi-delete"
                  variant="text"
                  color="#80829F"
                  class="delete__btn"
                  @click="onClickRemoveTeamMember(member?.id, member?.name)"
                />
              </div>
              <AcceptChip
                v-if="index > 0 && member.invitationStatus !== 'pending'"
                label="Accepted"
                :isDense="true"
              />
              <PendingChip
                v-if="index > 0 && member.invitationStatus === 'pending'"
                :isDense="true"
              />
            </div>
          </div>

          <div
            class="custom_tags mobile_wrapper tw-flex-wrap tw-flex-start tw-w-full"
          >
            <div class="member-list-role">{{ member?.role }}</div>
            <div
              class="member-list-tags"
              v-for="tag in parseJSON(member?.tags)"
              :key="tag"
            >
              <div class="member-list-tag">{{ tag }}</div>
            </div>
          </div>
        </div>
        <CommonLoader class="tw-mx-auto tw-my-3" :loading="memberAddedLoader" />
      </div>
      <div class="modal__content" v-else>
        <div class="member-loader">
          <CommonLoader :loading="true" />
        </div>
      </div>
      <div class="modal__footer">
        <v-btn class="button button-purple-border" @click="onClickAddTeamMember"
          >ADD NEW MEMber</v-btn
        >
        <v-btn class="button button-orange" @click="onClickCloseButton"
          >DONE</v-btn
        >
      </div>
    </template>
  </CommonDialog>
  <CommonDialog
    v-if="isShowRemoveTeamMemberModal"
    :isShowHeader="false"
    :onClickBackButton="onClickBackButton"
    :className="['remove_team_member_dialog']"
    width="350px"
    ><template v-slot:body>
      <div class="modal__content">
        <div class="modal__content_img">
          <img src="../../../assets/icons/trash-icon.svg" />
        </div>
        <div class="modal__content_header">
          <h2>Deactivate Team Member?</h2>
          <p>
            Are you sure you want to deactivate
            <strong>{{ memberName }}</strong> Account?
          </p>
        </div>
      </div>
      <div class="modal__footer">
        <v-btn
          class="button button-orange w-full"
          @click="onClickConfirmRemoveTeamMember(userMemberId)"
          >CONFIRM</v-btn
        >
      </div>
    </template>
  </CommonDialog>
  <AddTeamMemberModal
    v-if="openedModel === modelName.ADDTEAMMEMBER"
    @onClickCloseTab="onClickCloseModel"
  />
  <EditTeamMemberModal
    v-if="openedModel === modelName.EDITTEAMMEMBER"
    :memberData="memberData"
    @onClickCloseTab="onClickCloseModel"
  />
</template>

<script lang="ts">
import CommonDialog from "@/core/components/CommonDialog.vue";
import AddTeamMemberModal from "@/modules/trade-passport/components/AddTeamMemberModal.vue";
import EditTeamMemberModal from "@/modules/trade-passport/components/EditTeamMemberModal.vue";
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { MEMBER_STORE } from "@/store/modules/manage-member";
import { USER_STORE } from "@/store/modules/user";
import UserProfileLogo from "@/core/components/UserProfileLogo.vue";
import ProfileThumbnail from "@/core/uni-components/ProfileThumbnail.vue";
import PendingChip from "@/modules/dashboard/components/Chips/PendingChip.vue";
// import RejectChip from "@/modules/dashboard/components/Chips/RejectChip.vue";
import AcceptChip from "@/modules/dashboard/components/Chips/AcceptChip.vue";
import { getImageStringToImageURL, parseJSON } from "@/core/utils/common";
import CommonLoader from "@/core/components/CommonLoader.vue";

export default {
  components: {
    CommonDialog,
    AddTeamMemberModal,
    EditTeamMemberModal,
    UserProfileLogo,
    ProfileThumbnail,
    CommonLoader,
    PendingChip,
    // RejectChip,
    AcceptChip,
  },
  setup(props: any, ctx: any) {
    const store = useStore();
    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const isShowRemoveTeamMemberModal = ref(false);
    const memberName = ref("");
    const userMemberId = ref();
    const memberData = ref();
    const openedModel = ref("");
    const modelName = {
      ADDTEAMMEMBER: "Add Member",
      EDITTEAMMEMBER: "Edit Member",
    };
    const isShowTeamMemberList = ref(true);
    const teamMemberList = ref();
    const modifiedMembers = ref([]) as any;
    const loadingImages = ref(false);
    const memberAddedLoader = ref(false);
    const onClickCloseButton = () => {
      ctx.emit("onClickCloseTab");
    };

    const onClickCloseModel = async (isNewMemberAdded = false) => {
      if (isNewMemberAdded) {
        memberAddedLoader.value = true;
      }
      openedModel.value = "";
      isShowTeamMemberList.value = true;
      await getTeamMember();
      memberAddedLoader.value = false;
    };

    const onClickAddTeamMember = () => {
      openedModel.value = modelName.ADDTEAMMEMBER;
      isShowTeamMemberList.value = false;
    };

    const onClickRemoveTeamMember = (id: number, name: string) => {
      memberName.value = name;
      userMemberId.value = id;
      isShowTeamMemberList.value = false;
      isShowRemoveTeamMemberModal.value = true;
    };

    const onClickConfirmRemoveTeamMember = async (userMemberId: number) => {
      try {
        const response = await store.dispatch(
          `${MEMBER_STORE}/deleteMemberDetails`,
          {
            userId: user.value?.id,
            userMemberId: userMemberId,
          }
        );
        modifiedMembers.value = modifiedMembers.value.filter((member: any) => {
          return member?.id !== userMemberId;
        });
      } catch (error) {
        console.error("Error updating member details:", error);
      } finally {
        isShowTeamMemberList.value = true;
        isShowRemoveTeamMemberModal.value = false;
      }
    };

    const onClickBackButton = () => {
      isShowTeamMemberList.value = true;
      isShowRemoveTeamMemberModal.value = false;
    };

    const onClickEditTeamMember = (member: any) => {
      memberData.value = member;
      openedModel.value = modelName.EDITTEAMMEMBER;
      isShowTeamMemberList.value = false;
    };

    const getTeamMember = async () => {
      try {
        loadingImages.value = true;
        const response = await store.dispatch(`${MEMBER_STORE}/getTeamMember`, {
          userId: user.value?.id,
        });

        teamMemberList.value = response;
        if (!teamMemberList.value && !teamMemberList.value.length) {
          loadingImages.value = false;
          return;
        }

        const defaultMember = teamMemberList.value[0];
        const { profileImage } = defaultMember?.userWorkstation;

        const source = await getWorkstationProfile(profileImage);
        modifiedMembers.value = [];
        for (const member of teamMemberList.value) {
          if (member?.toUserId) {
            if (member?.toUser?.userPublicProfile) {
              const imageUrl = await getImageStringToImageURL(
                member?.toUser?.userPublicProfile?.profileImage
              );
              modifiedMembers.value.push({
                ...member,
                userProfileImageUrl: imageUrl,
              });
            } else {
              modifiedMembers.value.push({
                ...member,
                userProfileImageUrl: null,
              });
            }
          } else {
            modifiedMembers.value.push({
              ...member,
              userProfileImageUrl: null,
            });
          }
        }
      } catch (err) {
        console.log();
      } finally {
        loadingImages.value = false;
      }
    };

    const getWorkstationProfile = async (profileImage: string) => {
      if (!profileImage) {
        return null;
      }
      try {
        const imagePath = profileImage;
        const response = await store.dispatch(
          `${USER_STORE}/getImage`,
          imagePath
        );
        if (response) {
          return response;
        }
        return null;
      } catch (error) {
        return null;
      }
    };

    onMounted(async () => {
      await getTeamMember();
    });

    return {
      onClickCloseButton,
      teamMemberList,
      onClickAddTeamMember,
      openedModel,
      modelName,
      onClickCloseModel,
      isShowTeamMemberList,
      onClickRemoveTeamMember,
      isShowRemoveTeamMemberModal,
      onClickBackButton,
      memberName,
      onClickEditTeamMember,
      memberData,
      userMemberId,
      onClickConfirmRemoveTeamMember,
      modifiedMembers,
      parseJSON,
      memberAddedLoader,
    };
  },
};
</script>
