<template>
  <GeneralWidgetLayoutSlot
    v-if="isLoading"
    class="preferred_contractor tw-min-h-[354px] !tw-px-6 !tw-py-4"
    :hasCustomHeader="true"
    :hideFooter="true"
  >
    <template #custom-header>
      <div
        class="tw-flex tw-w-full tw-flex-col tw-gap-3 tw-items-start !tw-box-border"
      >
        <div class="title tw-flex tw-items-center tw-justify-between tw-w-full">
          <h4>Preferred Contractors</h4>
          <ToolTip
            description="These are the contractors you have marked as preferred based on their past performance, reliability, and alignment with your project requirements."
          />
        </div>
      </div>
    </template>
    <template #content>
      <div v-if="isLoading">
        <div class="tw-flex tw-justify-center tw-mt-[20%]">
          <CommonLoader :loading="true" />
        </div>
      </div>
    </template>
  </GeneralWidgetLayoutSlot>
  <GeneralWidgetLayoutSlot
    v-if="modifiedPreferredContractor?.length > 0 && !isLoading"
    class="preferred_contractor tw-min-h-full !tw-px-6 !tw-py-4"
    :hasCustomHeader="true"
    buttonLabel="Manage Preferred Trades"
    @on-action-click="onClickManagePreferredTrade"
  >
    <template #custom-header>
      <div
        class="tw-flex tw-w-full tw-flex-col tw-gap-3 tw-items-start !tw-box-border"
      >
        <div class="title tw-flex tw-items-center tw-justify-between tw-w-full">
          <h4>Preferred Contractors</h4>
          <ToolTip
            description="These are the contractors you have marked as preferred based on their past performance, reliability, and alignment with your project requirements."
          />
        </div>
      </div>
    </template>
    <template #content
      ><section
        class="tw-w-full tw-justify-between tw-flex-col tw-gap-3 tw-flex"
      >
        <div
          class="tw-flex tw-gap-3 tw-relative tw-justify-between main_block tw-border-b tw-border-0 tw-border-solid tw-border-[#00000014] last:tw-border-none tw-pb-3 tw-flex-wrap"
          v-for="(contractor, index) in modifiedPreferredContractor"
          :key="index"
        >
          <div class="left_block">
            <div class="tw-flex tw-gap-3 tw-relative">
              <div
                class="tw-rounded-full tw-overflow-hidden tw-h-[48px] tw-w-[48px]"
              >
                <img
                  class="tw-w-full tw-h-full tw-object-cover"
                  :src="contractor.publicURL"
                />
                <div
                  class="verified__icon tw-absolute tw-left-[30px] tw-top-[-6px] tw-w-[20px] tw-h-[20px] tw-bg-white tw-rounded tw-flex tw-items-center tw-justify-center"
                  v-if="contractor?.invitationStatus === 'accepted'"
                >
                  <img src="@/assets/icons/verified.svg" alt="" />
                </div>
              </div>
              <div class="right_block">
                <h6
                  class="tw-text-sm tw-font-bold tw-leading-[18px] tw-text-[#0C0F4A]"
                >
                  {{ contractor?.userWorkstation?.name }}
                </h6>
                <div
                  class="work__wrapper tw-inline-flex tw-gap-2 tw-items-center tw-mt-2"
                  v-if="contractor.userWorkstation?.userSkill"
                >
                  <template
                    v-for="(skill, index) in getUserSkillList(
                      contractor.userWorkstation?.userSkill
                    ).slice(0, 2)"
                    :key="index"
                  >
                    <div
                      class="tags tw-inline-flex tw-shadow-[0px_0px_6px_0px_#0C0F4A14] tw-bg-white tw-rounded-md tw-p-1 tw-items-center tw-gap-1"
                    >
                      <v-icon
                        class="!tw-w-[12px] !tw-h-[12px] tw-rounded-full tw-bg-[#0C0F4A] tw-overflow-hidden"
                      >
                        <img
                          class="tw-w-[10px] tw-h-[10px]"
                          v-if="skill?.iconName"
                          :src="
                            require('@/assets/category-icons/' +
                              skill?.iconName +
                              '.svg')
                          "
                      /></v-icon>
                      <p
                        class="tw-text-xs tw-text-semi-bold tw-leading-[15px] tw-text-[#0C0F4A]"
                      >
                        {{ skill?.label }}
                      </p>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <!-- <div
            class="right_block tw-inline-flex tw-flex-col tw-gap-1 sm:tw-flex-row"
          >
            <div
              class="tw-inline-flex tw-gap-1 tw-items-center tw-px-2 tw-bg-white tw-shadow-[0px_0px_8px_0px_#0C0F4A14] tw-rounded-lg tw-py-1"
            >
              <v-icon class="tw-w-[16px] tw-h-[16px]">
                <img src="@/assets/icons/check-mark-sucess.svg"
              /></v-icon>
              <span
                class="tw-text-[#1FB27A] tw-text-xs tw-font-bold tw-tracking-[0.15px]"
              >
                2</span
              >
              <p
                class="tw-text-xs tw-font-normal tw-text-[#0C0F4A] tw-tracking-[0.15px] tw-whitespace-nowrap"
              >
                Collaborative Projects
              </p>
            </div>
            <div
              class="tw-w-fit tw-inline-block tw-text-[#7F82A2] tw-justify-center tw-border tw-border-solid tw-rounded tw-px-2 tw-py-1 tw-text-[10px] tw-font-bold tw-mt-1 sm:tw-m-0 tw-whitespace-nowrap"
            >
              TRADE PASSPORT
            </div>
          </div> -->
        </div>
      </section>
    </template>
  </GeneralWidgetLayoutSlot>
  <GeneralWidgetLayoutSlot
    v-if="!modifiedPreferredContractor?.length && !isLoading"
    class="preferred_contractor tw-min-h-[355px] tw-h-full"
    :hasCustomHeader="true"
    :hideFooter="true"
    buttonLabel="Manage Preferred Trades"
    @on-action-click="onClickManagePreferredTrade"
  >
    <template #custom-header>
      <div
        class="tw-flex tw-w-full tw-flex-col tw-gap-3 tw-items-start !tw-px-4 !tw-box-border"
      >
        <div class="title tw-flex tw-items-center tw-justify-between tw-w-full">
          <h4>Preferred Contractors</h4>
          <ToolTip
            description="These are the contractors you have marked as preferred based on their past performance, reliability, and alignment with your project requirements."
          />
        </div>
      </div>
    </template>
    <template #content
      ><section
        class="tw-w-full tw-h-full tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-3"
      >
        <img :src="require(`@/assets/images/trade-network-empty-state.png`)" />
        <Text variant="p" textWeight="600" textColor="rgba(12, 15, 74, 0.5)"
          >Your preferred Contractors will appear here</Text
        >
        <Button
          variant="accent"
          label="INVITE CONTRACTORS"
          class="!tw-w-auto"
          @click="onClickManagePreferredTrade"
        />
      </section>
    </template>
  </GeneralWidgetLayoutSlot>
  <TeamMemberModal
    v-if="openedModel === modelName.TEAMMEMBERS"
    @onClickCloseTab="onClickCloseModel"
  />
</template>
<script setup>
import { computed, reactive, ref, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import TeamMemberModal from "@/modules/trade-passport/components/TeamMemberModal.vue";
import GeneralWidgetLayoutSlot from "@/core/components/slots/GeneralWidgetLayoutSlot.vue";
import Text from "@/core/components/ui/general/Text.vue";
import ProfileThumbnail from "@/core/uni-components/ProfileThumbnail.vue";
import UserProfileLogo from "@/core/components/UserProfileLogo.vue";
import { USER_STORE } from "@/store/modules/user";
import { PREFERRED_CONTRACTORS } from "@/store/modules/preferred-contractors";
import { WORKSTATION } from "@/store/modules/workstation";
import { PREFERRED_CONTRACTORS_ROUTE } from "@/modules/preferred-contractors/routes";
import Button from "@/core/components/ui/general/Button.vue";
import { getImageApiUrl, getUserSubSkillList } from "@/core/utils/common";
import CommonLoader from "@/core/components/CommonLoader.vue";
import $axios from "@/core/utils/axios-api-config";
import ToolTip from "@/core/components/common/ToolTip.vue";

const store = useStore();
const router = useRouter();
const user = computed(() => store.getters[`${USER_STORE}/user`]);

const isLoading = ref(false);

const openedModel = ref("");

const modelName = {
  TEAMMEMBERS: "TeamMember",
};

const onClickManageTeam = () => {
  openedModel.value = modelName.TEAMMEMBERS;
};
const onClickCloseModel = async () => {
  openedModel.value = "";
};
const members = reactive([
  {
    isOnline: true,
  },
]);

const modifiedPreferredContractor = ref([]);

const userWorkStationImage = computed(
  () => store.getters[`${USER_STORE}/userWorkStationImage`]
);

const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);

const allPreferredContractors = computed(
  () =>
    store.getters[`${PREFERRED_CONTRACTORS}/getPreferredContractorsConnection`]
);

const getWorkstationProfile = async (profileImage) => {
  if (!profileImage) {
    return null;
  }
  try {
    const imagePath = profileImage;
    const response = await store.dispatch(`${USER_STORE}/getImage`, imagePath);
    if (response) {
      return response;
    }
    return null;
  } catch (error) {
    return null;
  }
};

const modifyAllUserWorkstation = async () => {
  if (!allPreferredContractors.value && !allPreferredContractors.value.length) {
    return [];
  }
  try {
    const attachmentPromises = allPreferredContractors.value.map(
      (contractor) => {
        const { profileImage } = contractor;
        return getWorkstationProfile(profileImage);
      }
    );
    const attachmentSources = await Promise.all(attachmentPromises);

    modifiedPreferredContractor.value = [];
    allPreferredContractors.value.forEach((workstation, index) => {
      modifiedPreferredContractor.value.push({
        ...workstation,
        attachmentSource: attachmentSources[index],
      });
    });
    for (const contractor of modifiedPreferredContractor.value) {
      if (contractor.userWorkstation.profileImage !== null) {
        const imageUrl = getImageApiUrl(
          contractor.userWorkstation.profileImage,
          true
        );
        const encoded = await $axios.get(imageUrl);
        contractor.publicURL = encoded?.publicUrl;
      }
    }
  } catch (error) {
    // loadingImages.value = false;
  }
};

const onClickManagePreferredTrade = () => {
  router.push({ name: PREFERRED_CONTRACTORS_ROUTE });
};
const getAllSpecialism = computed(
  () => store.getters[`${USER_STORE}/getAllSpecialism`]
);

const getUserSkillList = (userSkill) => {
  return getUserSubSkillList(userSkill, getAllSpecialism.value);
};
onBeforeMount(async () => {
  try {
    isLoading.value = true;
    if (!getAllSpecialism.value.length) {
      await store.dispatch(
        `${USER_STORE}/setAllSpecialismsCategory`,
        activeUserWorkstation.value.id
      );
    }

    modifyAllUserWorkstation();
  } catch (error) {
    console.error(
      "error while loading preferred contractor in dashboard",
      error
    );
  } finally {
    isLoading.value = false;
  }
});
</script>
<style lang="scss" scoped>
.preferred_contractor {
  .info_icon {
    @include fluidFont(16, 16, 1);
  }
}
.counter {
  box-shadow: 0px 0px 20px 0px #0c0f4a1a;
}
.tags {
  .v-icon {
    // width: 12px;
    // height: 12px;

    img {
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg)
        brightness(103%) contrast(103%);
    }
  }
}
</style>
