import { TradeNetworkState } from "@/core/models/trade-network";

export default {
  setTradeNetworkContractor(state: TradeNetworkState, payload: Array<any>) {
    state.tradeNetworkContractorList = payload;
  },
  setUserNetWorkContractor(state: TradeNetworkState, payload: Array<any>) {
    state.userNetworkContractorList = payload;
  },
  setUserNetworkConnections(state: TradeNetworkState, payload: Array<any>) {
    state.userNetworkConnectedList = payload;
  },
  setFilterState(state: any, payload: any) {
    state.isClearFilter = payload;
  },
  setExternalInvitedContractorList(
    state: TradeNetworkState,
    payload: Array<any>
  ) {
    state.externalInvitedContractorList = payload;
  },
};
