<template>
  <div class="main_project_preview" v-if="!isEstimateReview && !loading">
    <!-- Left Block -->
    <div class="left_block">
      <div class="top_links_main mobile__wrapper">
        <div class="back_results">
          <v-btn
            prepend-icon="mdi-chevron-left"
            variant="plain"
            @click.prevent="() => $emit('onClickBackToSearchResults')"
            >Back to Search results
          </v-btn>
        </div>

        <StandardChip />
      </div>

      <div class="proj_components">
        <!-- Job Overiew -->
        <div class="list_block">
          <h3>Job Overiew</h3>
          <div class="tw-mb-8"></div>

          <div class="list_block__item">
            <div class="list_block__item__wrapper">
              <div class="list_block__item__wrapper__left">Address</div>
              <div class="list_block__item__wrapper__right">
                33 Collier Close,Espom,Surrey
              </div>
            </div>
          </div>
          <div class="tw-mb-8"></div>
        </div>

        <!-- Job Details -->
        <div class="list_block">
          <h3>Job Details</h3>
          <div class="tw-mb-8"></div>

          <div class="list_block__item">
            <div class="list_block__item__wrapper">
              <div class="list_block__item__wrapper__left">Project Type</div>
              <div class="list_block__item__wrapper__right">Standard</div>
            </div>
          </div>
          <div class="tw-mb-8"></div>
        </div>

        <!-- Estimate -->
        <div class="list_block">
          <h3>Estimate</h3>
          <div class="tw-mb-8"></div>

          <div class="list_block__item">
            <div class="list_block__item__wrapper">
              <div class="list_block__item__wrapper__left">Start Date</div>
              <div class="list_block__item__wrapper__right">
                {{ suggestedStartDate }}
              </div>
            </div>

            <div class="list_block__item__wrapper">
              <div class="list_block__item__wrapper__left">Total Phase</div>
              <div class="list_block__item__wrapper__right">
                {{ totalPhaseLength }}
              </div>
            </div>

            <div class="list_block__item__wrapper">
              <div class="list_block__item__wrapper__left">Total Tasks</div>
              <div class="list_block__item__wrapper__right">
                {{ totalTasks }}
              </div>
            </div>

            <div class="list_block__item__wrapper">
              <div class="list_block__item__wrapper__left">Duration</div>
              <div class="list_block__item__wrapper__right">
                {{ totalDuration }}
              </div>
            </div>

            <div class="list_block__item__wrapper">
              <div class="list_block__item__wrapper__left">Total Cost</div>
              <div class="list_block__item__wrapper__right">
                {{ totalCost }}
              </div>
            </div>

            <div class="tw-mb-4"></div>
            <v-btn class="button button-orange" @click="toggleView">
              verify the estimate
            </v-btn>
          </div>
          <div class="tw-mb-8"></div>
        </div>
      </div>
    </div>

    <!-- Right Block -->
    <div class="right_block">
      <div class="top_links_main desktop__wrapper">
        <div class="back_results">
          <v-btn
            prepend-icon="mdi-chevron-left"
            variant="plain"
            @click.prevent="() => $emit('redirectTab', 'Requests')"
            >Back to Search results
          </v-btn>
        </div>

        <StandardChip />
      </div>

      <!-- Basic Detail -->
      <div class="project_info__wrapper">
        <template v-if="!isEditProjectNoteSection">
          <v-card class="project_info">
            <div class="card_content">
              <!-- Header -->
              <div class="card_content__header">
                <div class="card_content__title">
                  <h5 id="project_name">
                    {{ jobDetails?.name }}
                  </h5>
                  <div class="card_content__title_info">
                    <div
                      class="raised"
                      id="project_review_item_icon_commerical"
                    >
                      <v-icon class="pointer_events_none">
                        <img
                          class="pointer_events_none"
                          :src="require('@/assets/icons/user.svg')"
                          alt="user"
                        />
                      </v-icon>
                      <p class="pointer_events_none">Commercial</p>
                    </div>
                  </div>
                </div>

                <v-icon
                  class="custom_edit"
                  icon="mdi-pencil"
                  @click.stop="updateProjectNameNote"
                ></v-icon>
              </div>

              <!-- Description -->
              <div class="card_content__footer">
                <h6 id="project_notes_title">Project Notes</h6>
                <p v-html="jobDetails?.description"></p>
              </div>
            </div>
          </v-card>
        </template>

        <!-- Edit Card -->
        <template v-else>
          <v-card class="edit_project_info">
            <div class="title">
              <h6>Project Title</h6>
              <v-text-field
                v-model="jobTitle"
                density="compact"
                placeholder="enter title"
                variant="outlined"
              ></v-text-field>
            </div>
            <div class="description">
              <h6>Project Notes</h6>
              <div class="custom_editor">
                <quill-editor
                  class="editor_ql"
                  v-model:value="state.content"
                  :options="state.editorOption"
                  :disabled="state.disabled"
                />
              </div>
            </div>
            <div class="edit__buttons">
              <v-btn
                class="button button-white"
                @click="onCancelProjectNotes"
                variant="outlined"
              >
                cancel
              </v-btn>
              <v-btn
                class="button button-orange"
                @click="onClickSaveProjectNotes"
                :loading="isLoadingProjectNotes"
                :disabled="isLoadingProjectNotes"
                prepend-icon="mdi-content-save"
              >
                save
              </v-btn>
            </div>
          </v-card>
        </template>
      </div>

      <!-- Specialism -->
      <div class="specialism__wrapper">
        <div class="header">
          <div class="title">
            <h4>Specialism</h4>
          </div>
          <v-icon
            class="custom_edit"
            v-if="!isEditSpecialismSection"
            icon="mdi-pencil"
            @click.stop="updateSpecialismSection()"
          ></v-icon>
        </div>
        <div class="specialism__wrapper__content">
          <div v-if="!jobDetails?.subCategories?.length">No Specialism</div>
          <div class="specialism-card-list">
            <div
              class="specialism-card-list__item"
              v-for="subCategory in jobDetails?.subCategories"
              :key="subCategory.id"
            >
              <v-btn
                variant="text"
                aria-label="Delete"
                class="specialism-card-list__item-delete"
                prepend-icon="mdi-delete"
                v-if="isEditSpecialismSection"
                @click="onClickDeleteCategoryButton(subCategory?.id)"
              ></v-btn>

              <div class="specialism-card-list__item-author">
                <div
                  class="specialism-card-list__item-author-header"
                  :class="specialismCategoryClass(subCategory?.generatedBy)"
                >
                  <div class="speicalism__icon">
                    <img
                      v-if="
                        specialismCategoryClass(subCategory?.generatedBy) ===
                        'krystal'
                      "
                      :src="require('@/assets/icons/krystal.svg')"
                      alt="krystalImg"
                    />
                    <img
                      v-if="
                        specialismCategoryClass(subCategory?.generatedBy) ===
                        'property'
                      "
                      :src="require('@/assets/icons/house.svg')"
                      alt="houseimage"
                    />
                  </div>
                  <h6>{{ subCategory?.generatedBy }}</h6>
                  <v-icon icon="mdi-information-variant-circle-outline" />
                </div>
                <div class="specialism-card-list__item-author-content">
                  <div class="category_icon">
                    <img
                      :src="
                        require('@/assets/category-icons/' +
                          subCategory?.category?.iconName +
                          '.svg')
                      "
                      alt="Image"
                    />
                  </div>
                  <div class="category_text">
                    <h5>{{ subCategory?.label }}</h5>
                    <p>{{ subCategory?.category?.poLabel }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="isEditSpecialismSection" class="edit-mode-button">
          <v-btn
            class="button button-purple-border"
            variant="text"
            @click="onClickAddMoreSpecialism"
          >
            Add more Specialism
          </v-btn>
          <div class="edit__buttons">
            <v-btn
              class="button button-white"
              @click="onClickCancelSpecialismButton"
              variant="outlined"
            >
              cancel
            </v-btn>
            <v-btn
              class="button button-orange"
              :loading="isLoadingProjectData"
              @click="onClickSaveSpecialismButton"
            >
              save
            </v-btn>
          </div>
        </div>
      </div>

      <!-- Project Properties -->
      <div class="properties_wrapper">
        <div class="header">
          <div class="title">
            <h4 id="project_properties_title">Properties</h4>
          </div>
        </div>
        <div class="properties_wrapper__content">
          <div class="properties_wrapper__left">
            <div class="properties_wrapper__info">
              <div class="properties_wrapper__info__left">
                <div class="location">
                  <v-icon
                    icon="mdi mdi-map-marker"
                    class="pointer_events_none"
                  ></v-icon>
                  {{ projectProperty?.property?.postcode }}
                </div>

                <div class="prop_name">
                  <v-icon class="pointer_events_none">
                    <img
                      src="../../../assets/icons/house.svg"
                      alt="house-icon"
                    />
                  </v-icon>
                  <h6 class="pointer_events_none">
                    {{ projectProperty?.property?.address }}
                  </h6>
                </div>

                <div class="property__info">
                  <div class="property__info__item">
                    {{ projectProperty?.property?.propertyType || "NA" }}
                  </div>
                  <div>|</div>
                  <div class="property__info__item">
                    <span class="pointer_events_none">{{
                      projectProperty?.property?.beds || "NA"
                    }}</span>
                    bedroom
                  </div>
                  <div>|</div>
                  <div class="property__info__item">
                    <span class="pointer_events_none">{{
                      projectProperty?.property?.propertySize || "NA"
                    }}</span>
                    sqm
                  </div>
                </div>
                <div class="property_type">
                  <v-icon
                    icon="mdi mdi-account-circle"
                    class="pointer_events_none"
                  ></v-icon
                  >Commercial
                </div>
              </div>
              <div class="properties_wrapper__info__right">
                <div class="property_image">
                  <img
                    src="../../../assets/images/property-sample-image.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="properties_wrapper__right">
            <div
              class="map__wrapper"
              id="map_properties"
              v-if="addresses && addresses.length"
            >
              <AddressMapComponent
                class="pointer_events_none"
                :latlng="[53.5809811, -0.6393335]"
                :addresses="addresses"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Documents / Attachments -->
      <div class="documents_attachment">
        <div class="title">
          <h3 id="project_documents_attachment_name">
            Documents / Attachments
          </h3>
          <v-select
            label="Select category"
            :items="attachments"
            item-title="userCategoryName"
            item-value="userCategoryName"
            multiple
            variant="outlined"
            density="compact"
            class="select_prop_dropdown"
            @update:modelValue="changeCategoryId"
            :menu-props="{ contentClass: 'custom__text__selector' }"
          >
          </v-select>
        </div>

        <v-expansion-panels v-model="panel" multiple class="accordion__wrapper">
          <v-expansion-panel
            v-for="(attachmentCategory, index) in filterAttachments"
            :key="index"
          >
            <v-expansion-panel-title>
              <v-icon class="house__icon">
                <img src="../../../assets/icons/house.svg" alt="" />
              </v-icon>
              <h6>
                {{ attachmentCategory?.userCategoryName }}
              </h6>
              <template v-slot:actions="{ expanded }">
                <v-icon
                  class="custom_edit"
                  v-if="expanded && !isEditAttachmentsSection.flag"
                  icon="mdi-pencil"
                  @click="updateAttachmentsSection(index)"
                ></v-icon>
                <v-icon
                  v-else
                  icon="mdi-chevron-down"
                  class="bottom__chevron"
                ></v-icon>
              </template>
            </v-expansion-panel-title>

            <div class="group__cards" v-if="isShowGroupCard(index)">
              <div class="group__card__text">
                <v-expansion-panel-text>
                  <div class="accordion_inner_title">
                    <div class="doc_main">
                      <div
                        class="doc_main__list"
                        v-for="attachment in attachmentCategory.attachments"
                        :key="attachment.id"
                      >
                        <!-- @click.stop="
                            onClickOpenNewTab(attachment.uploadedImages?.url)
                          " -->
                        <div class="property__img__name">
                          <v-icon icon="mdi mdi-image-outline"></v-icon>
                          <span>{{ attachment?.uploadedImages?.name }}</span>
                        </div>

                        <div
                          v-if="
                            isEditAttachmentsSection.flag &&
                            isEditAttachmentsSection.index === index
                          "
                          @click="
                            onClickRemoveAttachment(
                              attachment?.uploadedImages?.id,
                              attachment?.uploadedImages?.attachmentId,
                              attachment?.categoryId
                            )
                          "
                        >
                          <v-icon
                            icon="mdi-close"
                            class="pointer_events_none"
                          ></v-icon>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="!isEditAttachmentsSection.flag"
                    class="property__images"
                  >
                    <div
                      v-for="attachment in attachmentCategory.attachments"
                      :key="attachment.id"
                      class="property__item__images"
                    >
                      <img
                        :src="attachment?.uploadedImages?.url"
                        @click="openCarousel(index)"
                      />
                    </div>
                  </div>
                  <div
                    v-if="
                      isEditAttachmentsSection.flag &&
                      isEditAttachmentsSection.index === index
                    "
                    class="edit-mode-button"
                  >
                    <v-btn
                      class="button button-purple-border"
                      @click="onClickToggleAttachmentModal"
                    >
                      Upload More
                    </v-btn>
                    <div class="edit__buttons">
                      <v-btn
                        class="button button-white"
                        variant="outlined"
                        @click="onCancelAttachments"
                      >
                        cancel
                      </v-btn>
                      <v-btn
                        class="button button-orange"
                        size="large"
                        block
                        @click="onClickSaveButtonAttachments"
                      >
                        save
                      </v-btn>
                    </div>
                  </div>
                </v-expansion-panel-text>
              </div>
            </div>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>

      <div class="tw-mb-8"></div>
    </div>
  </div>
  <CommonLoader :loading="loading" />

  <SpecialismMultiSelectModel
    v-if="isShowSpecialismModal"
    :isHistorical="false"
    :projectId="jobDetails.id"
    :isLoading="isLoadingForSaveSpecialism"
    :selectedSpecialism="jobDetails?.subCategories"
    @onClickCloseTab="onClickCloseTab"
    @onClickSaveButtonClick="onClickSaveButtonClick"
  />
  <UploadProjectAttachmentsModal
    v-if="isShowAttachmentModal"
    :isPropertyOwner="false"
    :projectData="jobDetails"
    :projectAttachments="attachments"
    @on-close="onCloseAttachmentModal"
  />

  <EstimateReview
    v-if="isEstimateReview"
    @on-back="toggleView"
    @redirectTab="redirectTab"
    :jobDetails="jobDetails"
    :projectProperty="projectProperty"
  />

  <!-- CarouselDialog -->
  <v-dialog
    v-model="isCarouselDialog"
    height="auto"
    max-width="600"
    class="project__carousal__modal"
    :show-arrows="false"
  >
    <v-card>
      <template v-slot:actions>
        <v-btn
          icon="mdi-close"
          @click="isCarouselDialog = false"
          class="d-flex"
        ></v-btn>
        <v-carousel
          hide-delimiter-background
          show-arrows="hover"
          v-model="currentIndex"
        >
          <v-carousel-item
            v-for="attachment in carouselImageList"
            :key="attachment.id"
          >
            <v-img
              cover
              :src="attachment?.url"
              height="100%"
              width="100%"
            ></v-img>
          </v-carousel-item>
        </v-carousel>
      </template>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref, onBeforeMount, computed } from "vue";
import { useStore } from "vuex";
import { JOBS_STORE } from "@/store/modules/jobs";
import CommonLoader from "@/core/components/CommonLoader.vue";
import appFilters from "@/filters";
import CurrencyFilter from "@/filters/currency.filter";
import moment from "moment";
import { getCurrentInstance } from "vue";
import { PROJECT, PROJECT_STORE } from "@/store/modules/project";
import { quillEditor } from "vue3-quill";
import { useDisplay } from "vuetify";

import {
  ProjectPhaseType,
  ProjectSpecialismGeneratedEnum,
} from "@/core/enums/ProjectsEnum";
import { EscrowStatusEnum } from "@/core/enums/estimateEnum";
import { reactive } from "vue";
import SpecialismMultiSelectModel from "@/modules/project/modal/SpecialismMultiSelectModel.vue";
import PropertySpecialismModal from "@/modules/Specialism/components/PropertySpecialismModal.vue";
import AddressMapComponent from "@/core/components/AddressMapComponent.vue";
import { USER_STORE } from "@/store/modules/user";
import UploadProjectAttachmentsModal from "@/modules/properties/components/Modals/UploadProjectAttachmentsModal.vue";
import EstimateReview from "./EstimateReview.vue";
const store = useStore();
const jobDetails = ref(null);
const emits = defineEmits(["redirectTab"]);
const isEditProjectNoteSection = ref(false);
const isEditSpecialismSection = ref(false);
const loading = ref(false);
const isEstimateReview = ref(false);

const projectAttachments = ref([]);

const updateProjectNameNote = () => {
  isEditProjectNoteSection.value = true;
  jobTitle.value = jobDetails.value?.name;
  state.content = jobDetails.value?.description;
};

const onCancelProjectNotes = () => {
  isEditProjectNoteSection.value = false;
};

const updateSpecialismSection = () => {
  isEditSpecialismSection.value = true;
};

const onClickCancelSpecialismButton = async () => {
  isEditSpecialismSection.value = false;
  await setProjectData();
};
const specialismCategoryClass = (val) => {
  if (val.toLowerCase() === "krystal") {
    return "krystal";
  }
  if (val.toLowerCase() === "property") {
    return "property";
  }
  if (val.toLowerCase() === "trade") {
    return "trade";
  }
};
const redirectTab = (payload) => {
  emits("redirectTab", payload);
};
/* new */
const isLoadingProjectNotes = ref(false);
const isShowSpecialismModal = ref(false);
const isShowAttachmentModal = ref(false);

const jobTitle = ref("");
const projectProperty = ref(null);
const addresses = ref(null);
const attachments = ref([]);
const filterAttachments = ref([]);
const panel = ref([]);
const isLoadingRemoveAttachments = ref(false);

const isEditAttachmentsSection = ref({
  flag: false,
  id: -1,
});
const loadingSelectProperty = ref(false);
const isCarouselDialog = ref(false);
const currentIndex = ref(0);
const carouselImageList = ref([]);

const availableCategoryList = ref([]);
const attachmentsUpdateList = ref([]);

const onClickSaveProjectNotes = async () => {
  try {
    isLoadingProjectNotes.value = true;
    await store.dispatch(`${PROJECT_STORE}/updateHistoricalProject`, {
      projectId: jobDetails.value?.id,
      data: {
        description: state.content,
        name: jobTitle.value,
      },
    });
    displayToast("Update successfully.", "success");
  } catch {
    console.log();
  } finally {
    await setProjectData();
    isEditProjectNoteSection.value = false;
    isLoadingProjectNotes.value = false;
  }
};
const state = reactive({
  content: "",
  _content: "",
  editorOption: {
    placeholder: "Description here",
    modules: {},
  },
  disabled: false,
});
const onClickAddMoreSpecialism = () => {
  isShowSpecialismModal.value = true;
};

const internalInstance = getCurrentInstance();
const displayToast = (message, type) => {
  if (
    internalInstance &&
    internalInstance.appContext.config.globalProperties.$notify
  ) {
    internalInstance.appContext.config.globalProperties.$notify[type]({
      message,
    });
  }
};

const openCarousel = (idx = 0) => {
  isCarouselDialog.value = true;
  const carouselImages = [];
  filterAttachments.value.map((data) => {
    data.attachments.map((attachment) => {
      carouselImages.push({ url: attachment?.uploadedImages?.url });
    });
    carouselImageList.value = carouselImages;
  });

  currentIndex.value = idx;
};
const getFilterImages = (category) => {
  const filterImages = [];
  return Object.values(category)
    .flat()
    .filter((category) => {
      if (!filterImages.includes(category.attachmentUrl)) {
        filterImages.push(category.attachmentUrl);
        return true;
      }
      return false;
    });
};
const estimateDetails = computed(() => {
  return jobDetails.value?.projectEstimation[0];
});
const onClickCloseTab = () => {
  isShowSpecialismModal.value = false;
};
const isLoadingForSaveSpecialism = ref(false);
const onClickSaveButtonClick = async (selectedCategory) => {
  try {
    isLoadingForSaveSpecialism.value = true;
    const projectSubCategories = jobDetails.value?.subCategories.map(
      (subCategory) => {
        return subCategory.id;
      }
    );

    const removedSubCategories = projectSubCategories.filter(
      (subCategoryId) => {
        return !selectedCategory.includes(subCategoryId);
      }
    );

    if (removedSubCategories.length) {
      jobDetails.value.subCategories = jobDetails.value.subCategories.filter(
        (subCategory) => {
          return !removedSubCategories.includes(subCategory.id);
        }
      );
    }

    const newAddedSubCategoriesIds = selectedCategory.filter(
      (subCategoryId) => {
        return !projectSubCategories.includes(subCategoryId);
      }
    );

    if (newAddedSubCategoriesIds.length) {
      const newAddedSubCategoriesData = await store.dispatch(
        `${PROJECT_STORE}/getNewAddedSubCategoryForProject`,
        {
          subCategoriesIds: newAddedSubCategoriesIds,
          generatedBySpecialism: ProjectSpecialismGeneratedEnum.TRADE,
        }
      );

      newAddedSubCategoriesData.forEach((subCategory) => {
        jobDetails.value?.subCategories.push(subCategory);
      });
    }
  } catch (error) {
    console.log(error);
  } finally {
    isLoadingForSaveSpecialism.value = false;
    isShowSpecialismModal.value = false;
  }
};
const onClickDeleteCategoryButton = (subCategoryId) => {
  jobDetails.value.subCategories = jobDetails.value.subCategories.filter(
    (subCategory) => {
      return subCategory.id !== subCategoryId;
    }
  );
};
const suggestedStartDate = computed(() => {
  if (estimateDetails.value) {
    return appFilters.formatToDate(
      estimateDetails.value?.suggestedStartDate,
      "DD MMM YYYY"
    );
  }
  return null;
});
const totalPhaseLength = computed(() => {
  return estimateDetails.value?.estimationPhase.length || 0;
});
const totalTasks = computed(() => {
  let sum = 0;
  estimateDetails.value?.estimationPhase?.forEach((phase) => {
    sum += phase.estimationPhaseTasks.length;
  });
  return sum;
});
const totalDuration = computed(() => {
  let totalDays = 0;
  if (estimateDetails.value) {
    totalDays = estimateDetails.value?.estimationPhase?.reduce((sum, stage) => {
      const startDate = moment(stage.phaseStartDate);
      const endDate = moment(stage.phaseEndDate);
      return sum + endDate.diff(startDate, "days");
    }, 0);
  }
  return `${totalDays} day${totalDays > 1 ? "s" : ""}`;
});

const totalSubTotal = computed(() => {
  const sum = estimateDetails.value?.estimationPhase?.reduce((sum, phase) => {
    const roundedSubTotal = parseFloat(phase.subTotal);
    return sum + roundedSubTotal;
  }, 0);
  return Math.round(sum * 100) / 100;
});
const totalVat = computed(() => {
  const sum = estimateDetails.value?.estimationPhase?.reduce(
    (sum, phase, index) => {
      const roundedTotalVat = parseFloat(phase.subTotal * phase.vat);
      return sum + roundedTotalVat;
    },
    0
  );
  return Math.round(sum * 100) / 100;
});

const serviceCharges = computed(() => {
  const initialProjectCharge = 5;
  const serviceFeePercentage = 0.03;
  let totalWithServiceFee = estimateDetails.value?.estimationPhase?.reduce(
    (sum, phase, index) => {
      const roundedTotalWithServiceFee = parseFloat(
        phase.subTotal * serviceFeePercentage
      );
      return sum + roundedTotalWithServiceFee;
    },
    0
  );

  totalWithServiceFee += initialProjectCharge;
  return Math.round(totalWithServiceFee * 100) / 100;
});
const totalCost = computed(() => {
  return CurrencyFilter.formatToCurrency(
    totalSubTotal.value + totalVat.value + serviceCharges.value
  );
});
const onClickSaveSpecialismButton = async () => {
  const selectedCategory = jobDetails.value?.subCategories.map(
    (subCategory) => {
      return subCategory.id;
    }
  );

  await store.dispatch(`${PROJECT_STORE}/updateSubSpecialismCategory`, {
    projectId: jobDetails.value?.id,
    data: {
      categoryData: selectedCategory,
      generatedBy: ProjectSpecialismGeneratedEnum.TRADE,
    },
  });

  onClickCancelSpecialismButton();
};

const activeJob = computed(
  () => store.getters[`${JOBS_STORE}/getActiveHistoricalJob`]
);
const setProjectData = async () => {
  loading.value = true;
  if (activeJob.value.refId)
    jobDetails.value = await store.dispatch(
      `${JOBS_STORE}/getHistoricalJobDetails`,
      { projectRefId: activeJob.value.refId }
    );
  projectProperty.value = jobDetails.value?.projectProperties?.length
    ? jobDetails.value?.projectProperties[0]
    : null;

  projectAttachments.value = jobDetails.value?.projectAttachments;

  attachments.value = jobDetails.value?.projectAttachmentProperties.map(
    (propertyItems) => ({
      uploadedImages: {
        url: propertyItems[0]?.attachmentUrl,
        name: propertyItems[0]?.projectAttechment?.originalName,
        attachment: propertyItems[0]?.projectAttechment?.attachment,
        attachmentId: propertyItems[0]?.projectAttechment?.id,
        id: propertyItems[0]?.id,
      },
      propertyId: propertyItems.map((property) => property.property.id),
      userCategoryName: propertyItems[0]?.projectAttachmentsCategory?.value,
      categoryId: propertyItems[0]?.projectAttachmentsCategory?.id,
    })
  );
  filterAttachments.value = getFilterCategory(attachments.value);

  availableCategoryList.value = await store.dispatch(
    `${PROJECT_STORE}/getProjectCategoryList`,
    jobDetails.value.id
  );
  setPropertyAddress();

  loading.value = false;
};

const setPropertyAddress = () => {
  const addressList = [];

  const item = projectProperty.value?.property;
  if (item && item.addressData) {
    let latlang = null;
    const address =
      typeof item.addressData === "string"
        ? JSON.parse(item.addressData)
        : item.addressData;
    latlang = address?.latlng;
    addressList.push({
      address: item.address,
      uprn: item.uprn,
      latlang,
      id: item.id,
      postcode: item.postcode,
    });
  }
  addresses.value = addressList;
};
const getFilterCategory = (attachments) => {
  const grouped = attachments.reduce((acc, item) => {
    const { userCategoryName, uploadedImages, propertyId, categoryId } = item;

    if (!acc[userCategoryName]) {
      acc[userCategoryName] = [];
    }

    acc[userCategoryName].push({ uploadedImages, propertyId, categoryId });

    return acc;
  }, {});

  return Object.keys(grouped).map((key) => ({
    userCategoryName: key,
    attachments: grouped[key],
  }));
};
const isShowGroupCard = (index) => {
  return panel.value.includes(index);
};

const changeCategoryId = async (categories) => {
  loadingSelectProperty.value = true;
  if (categories.length > 0) {
    const filterData = attachments.value.filter((attachment) =>
      categories.includes(attachment.userCategoryName)
    );
    filterAttachments.value = getFilterCategory(filterData);
  } else {
    await setProjectData();
  }
  loadingSelectProperty.value = false;
};
const updateAttachmentsSection = (index) => {
  panel.value.push(index);
  isEditAttachmentsSection.value.flag = true;
  isEditAttachmentsSection.value.index = index;
};
const onClickToggleAttachmentModal = () => {
  isShowAttachmentModal.value = !isShowAttachmentModal.value;
};
const onCloseAttachmentModal = async () => {
  onClickToggleAttachmentModal();
  await setProjectData();
};
const onClickRemoveAttachment = (id, attachmentId, attachmentCategoryId) => {
  filterAttachments.value = filterAttachments.value.map((category) => {
    return {
      categoryId: category.categoryId,
      userCategoryName: category.userCategoryName,
      attachments: category.attachments.filter(
        (attachment) => attachment.uploadedImages.id !== id
      ),
    };
  });

  attachments.value = attachments.value.filter(
    (attachment) => attachment.uploadedImages.id !== id
  );

  attachmentsUpdateList.value.push({
    id: attachmentId,
    propertyIds: [
      {
        id,
        propertyId: projectProperty.value?.property?.id,
        isDeleted: true,
      },
    ],
    attachmentCategoryId: attachmentCategoryId,
    isDeleted: false,
  });

  console.log("onClickRemoveAttachment :>> ");
};
const onCancelAttachments = async () => {
  try {
    await setProjectData();
    isEditAttachmentsSection.value.flag = false;
  } catch (e) {
    console.log("Error :", e);
  }
};

const onClickSaveButtonAttachments = async () => {
  try {
    isLoadingRemoveAttachments.value = true;
    await store.dispatch(`${PROJECT_STORE}/updateHistoricalProject`, {
      projectId: jobDetails.value?.id,
      data: {
        attachments: attachmentsUpdateList.value,
      },
    });

    await setProjectData();
    isEditAttachmentsSection.value.flag = false;
  } catch {
    console.log();
  } finally {
    isLoadingRemoveAttachments.value = false;
    attachmentsUpdateList.value = [];
  }
};
const toggleView = () => {
  isEstimateReview.value = !isEstimateReview.value;
};
onBeforeMount(async () => {
  await setProjectData();
});
</script>

<style lang="scss" scoped>
@import "@/modules/project/styles/projectReview.scss";
</style>
