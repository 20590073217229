export enum PropertyTypeEnum {
  PROPERTY_OWNER = "property owner",
  PROPERTY_OCCUPIER = "property occupier",

  FREEHOLD = "Freehold",
  LEASEHOLD = "Leasehold",

  OCCUPIER = "Occupier",
  OWNER = "Owner",
  COMPANY = "Company",
  MANAGER = "Manager",

  COMMERCIAL = "Commercial",
  RESIDENTIAL = "Residential",
}

export enum PropertyConnectionRoleEnum {
  OWNER = "owner",
  ADMIN = "admin",
  EDITOR = "editor",
  VIEWER = "viewer",
}
export enum PropertyWorkflowTypeEnum {
  PROPERTY = "property",
  CLIENT = "client",
  RAISE_CLIENT = "raise_client",
  RAISE_AN_ISSUE = "raise-an-issue",
}

export enum PropertyMapLayerType {
  SHAPES = "shapes",
  MEASURE = "measure",
}

export enum PropertyCategoryEnum {
  COMMERCIAL = "Commercial",
  RESIDENTIAL = "Residential",
}
