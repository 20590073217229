<template>
  <div
    v-if="isLoading"
    class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-full tw-w-full tw-mt-[530px]"
  >
    <Loader v-if="isLoading" />
  </div>
  <div v-if="!isLoading">
    <div class="post_project_wrapper" v-if="getProjectCount">
      <div v-if="!isShowPropertyList">
        <v-btn
          class="button button-orange purple_btn new_project_button"
          size="small"
          @click="onClickTogglePropertySelect"
        >
          <v-icon icon="mdi-plus-circle" class="mr-2"></v-icon>
          New Project
        </v-btn>
        <!-- <div class="top_content">
        <div class="post_project__text">
          <h3>Start your next project.</h3> -->

        <!-- <p>
            Lorem ipsum dolor sit amet consectetur. Sed odio tristique a libero.
            Habitant duis congue nunc etiam malesuada risus. Tellus rhoncus vel
            est arcu. Ornare nisi sed odio id. Eget in vestibulum lorem lectus
            justo enim.
          </p> -->
        <!-- <v-btn
            class="button button-purple purple_btn"
            size="small"
            block
            @click="onClickTogglePropertySelect"
          >
            Start New Project
          </v-btn>
        </div>
        <div class="post_project__img">
          <img
            width="340"
            height="168"
            src="../../../assets/images/property-owner-dashboard.png"
            class="logo_top"
          />
        </div>
      </div> -->
      </div>
      <ProjectListComponent v-if="isShowProjectListing" />
    </div>
    <div v-if="!getProjectCount && !isShowPropertyList">
      <ProjectListEmptyState
        :allow-button="true"
        headingText="active"
        @openNewProject="onClickTogglePropertySelect"
      />
    </div>
    <PropertyListComponent
      v-if="isShowPropertyList"
      @onClickBackButton="onClickBackButton"
    />

    <PageRestrictionNoticeModal
      v-if="isShowWorkstationRestrictionModal"
      @on-close="toggleRestrictionModal"
    />
  </div>
</template>

<script lang="ts">
import { ref, computed, onMounted, onBeforeMount } from "vue";
import PropertyListComponent from "@/modules/project/components/PropertyListComponent.vue";
import ProjectListComponent from "@/modules/project/components/ProjectListComponent.vue";
import ProjectListEmptyState from "./ProjectListEmptyState.vue";
import Loader from "@/core/components/common/Loader.vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { PROJECT } from "@/store/modules/project";
import projectService from "@/core/services/project.service";
import { WORKSTATION } from "@/store/modules/workstation";
import PageRestrictionNoticeModal from "@/core/components/modals/PageRestrictionNoticeModal.vue";

export default {
  components: {
    Loader,
    PropertyListComponent,
    ProjectListComponent,
    ProjectListEmptyState,
    PageRestrictionNoticeModal,
  },
  setup() {
    const isShowPropertyList = ref(false);
    const isShowProjectListing = ref(true);
    const isLoading = ref(false);
    const isShowWorkstationRestrictionModal = ref(false);

    const projectListing = ref([]) as any;
    const route = useRoute();
    const store = useStore();

    const isPersonalAccountWorkStation = computed(
      () =>
        store.getters[`${WORKSTATION}/activeUserWorkstation`]?.name ===
        "Default"
    );

    const toggleRestrictionModal = () => {
      isShowWorkstationRestrictionModal.value =
        !isShowWorkstationRestrictionModal.value;
    };

    const onClickTogglePropertySelect = () => {
      if (isPersonalAccountWorkStation.value) {
        toggleRestrictionModal();
      } else {
        isShowPropertyList.value = true;
        isShowProjectListing.value = false;
        store.commit(`${PROJECT}/setProjectDetails`, null);
      }
    };
    const onClickBackButton = () => {
      isShowPropertyList.value = false;
      isShowProjectListing.value = true;
    };
    onBeforeMount(async () => {
      try {
        isLoading.value = true;
        projectListing.value = (await projectService.getAllProjects()).data;
      } catch (error) {
        console.log("error while fetching active properties", error);
      } finally {
        isLoading.value = false;
      }
    });
    const getProjectCount = computed(() => {
      if (isPersonalAccountWorkStation.value) {
        return 1;
      } else {
        return projectListing.value.length;
      }
    });

    onMounted(() => {
      if (route?.query?.propertyId) {
        isShowPropertyList.value = true;
      }
    });
    return {
      isLoading,
      isShowPropertyList,
      onClickTogglePropertySelect,
      onClickBackButton,
      isShowProjectListing,
      getProjectCount,
      isShowWorkstationRestrictionModal,
      toggleRestrictionModal,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/modules/project/styles/project.scss";
</style>
