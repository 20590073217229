<template>
  <CommonDialog
    :isShowHeader="false"
    :isShowBackButton="false"
    width="560px"
    max-width="100%"
    height="auto"
    :className="['welcome__modal_box']"
    v-if="isShowWelcomeModal"
  >
    <template v-slot:body>
      <div class="header-title">Welcome to iknowa,</div>
      <div class="header-sub-title">{{ userName }}</div>
      <div class="welcome__image">
        <img src="../../../assets/images/construction-image.png" alt="" />
      </div>
      <div class="welcome__text">
        <p>
          Check out your new HQ. Access Verified, Training, Jobs, Subcontractors
          and greater protections, with ease.
        </p>
      </div>
    </template>
    <template v-slot:footer
      ><v-btn @click="onClickExploreButton" class="button button-orange"
        >ExplorE 🚀</v-btn
      ></template
    >
  </CommonDialog>
  <TradePersonBenefitsModal
    v-if="isShowTradePersonBenefitsModal"
    @onClickClosePreviewModalButton="onClickClosePreviewModalButton"
  />
</template>

<script lang="ts">
import CommonDialog from "@/core/components/CommonDialog.vue";
import { USER_STORE } from "@/store/modules/user";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import TradePersonBenefitsModal from "@/modules/dashboard/components/TradePersonBenefitsModal.vue";
export default {
  components: {
    CommonDialog,
    TradePersonBenefitsModal,
  },
  setup(props: any, ctx: any) {
    const store = useStore();

    const user = computed(() => store.getters[`${USER_STORE}/user`]);

    const userName = computed(
      () =>
        `${user.value?.firstName || "user"} ${user.value?.lastName || "name"}`
    );
    const isShowWelcomeModal = ref(true);
    const isShowTradePersonBenefitsModal = ref(false);

    const onClickExploreButton = () => {
      isShowWelcomeModal.value = false;
      isShowTradePersonBenefitsModal.value = true;
    };

    const onClickClosePreviewModalButton = () => {
      ctx.emit("onClickExploreButton");
    };
    return {
      userName,
      onClickExploreButton,
      isShowWelcomeModal,
      isShowTradePersonBenefitsModal,
      onClickClosePreviewModalButton,
    };
  },
};
</script>
