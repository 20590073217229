<template>
  <section
    class="custom-card tw-w-full tw-rounded-lg tw-box-border"
    :class="{
      'no-shadow': noShadow,
      '!tw-oveflow-auto': isOverflowAuto,
      '!tw-overflow-hidden': isOverflowHidden,
      '!tw-overflow-[unset]': isOverflowUnset,
    }"
  >
    <slot name="content"></slot>
  </section>
</template>
<script setup>
const props = defineProps({
  bgColor: {
    type: String,
    default: "#FFF",
  },
  noShadow: Boolean,
  isOverflowAuto: Boolean,
  isOverflowUnset: Boolean,
  isOverflowHidden: Boolean,
});
</script>
<script>
export default {
  name: "Card",
};
</script>
<style lang="scss" scoped>
.custom-card {
  background: v-bind("props.bgColor");
  box-shadow: 0px 0px 8px 0px rgba(12, 15, 74, 0.08);
  // box-shadow: none;
  // border: 1px solid black;
}
.custom-card.no-shadow {
  box-shadow: none;
}
</style>
