import $axios from "../utils/axios-api-config";

export default {
  inviteContractors(workStationId: number, payload: object) {
    return $axios.post(`contractor/invite/${workStationId}`, payload);
  },

  async getProjectInvitation(payload: {
    projectId: number;
    workstationId: number;
    email: string;
  }) {
    return await $axios.get("contractor/project/invitation", {
      params: payload,
    });
  },
  getProjectInvited(payload: { projectId: number }, params: any = {}) {
    return $axios.get(`contractor/invited/project/${payload.projectId}`, {
      params,
    });
  },

  InvitationRequestAcceptOrReject(
    invitationId: number,
    InvitationStatus: string
  ) {
    return $axios.post(
      `contractor/project/invitation/${invitationId}/${InvitationStatus}`
    );
  },
  editInvitation(
    invitationId: number,
    invitationData: { permissionType: string }
  ) {
    return $axios.put(
      `contractor/project/invitation/${invitationId}`,
      invitationData
    );
  },
  deleteInvitation(invitationId: number) {
    return $axios.delete(`contractor/project/invitation/${invitationId}`);
  },
};
