import {
  NavigationGuardNext,
  RouteLocationNormalized,
  createRouter,
  createWebHistory,
  RouteRecordRaw,
} from "vue-router";
import LoginRoute from "@/modules/login/routes";
import ForgotPasswordRoute, {
  FORGOT_PASSWORD_ROUTE,
} from "@/modules/forgot-password/routes";
import RegistrationRoute from "@/modules/registration/routes";
import ChangePasswordRoute from "@/modules/change-password/routes";
import DashboardRoute, { DASHBOARD_ROUTE } from "@/modules/dashboard/routes";
import DocumentsRoute from "@/modules/documents/routes";
import OnboardingRoute from "@/modules/onboarding/routes";
import CoursesRoute from "@/modules/courses/routes";
import JobsRoute from "@/modules/jobs/routes";
import TradePassportRoute from "@/modules/trade-passport/routes";
import { getLocalStorageWithExpiry } from "@/core/utils/common";
import { AUTH_TOKEN } from "@/core/constants";
import OwnerOnboardingRoute from "@/modules/owner-onboarding/routes";
import ProfileRoute from "@/modules/profile/routes";
import ProjectRoute from "@/modules/project/routes";
import PropertyOwnerWorkstation from "@/modules/workstation/propertyowner/routes";
import PropertiesRoute from "@/modules/properties/routes";
import CalendarRoute from "@/modules/calendar/routes";
import TradeNetwork from "@/modules/trade-network/routes";
import PreferredContractorsRoute from "@/modules/preferred-contractors/routes";
import WorkstationSettingRoute from "@/modules/workstation-setting/routes";
import RasieIssueRoute from "@/modules/issues/routes";
import EmergencyOutOfHoursRoute from "@/modules/emergency-out-of-hours/routes";
import EWalletRoute from "@/modules/e-wallet/routes";
import AliaseEmailRoute, {
  ALIASE_EMAIL_ROUTE,
} from "@/modules/aliase-email/routes";
import { USER_STORE } from "@/store/modules/user";
import IssuePropertyRoute from "@/modules/issues/routes";
import ConnectionRoute from "@/modules/connections/routes";
import VerifyJobsRoute from "@/modules/verify-historical-projects/routes";
import ChatRoute from "@/modules/chat/routes";
import ClientsRoute from "@/modules/clients/routes";
import ViewRatingRoute from "@/modules/view-rating/routes";

import store from "@/store";

const routes: Array<RouteRecordRaw> = [
  LoginRoute,
  ...RegistrationRoute,
  ChangePasswordRoute,
  ...DashboardRoute,
  ForgotPasswordRoute,
  ...OnboardingRoute,
  ...CoursesRoute,
  ...JobsRoute,
  TradePassportRoute,
  OwnerOnboardingRoute,
  ProfileRoute,
  ...ProjectRoute,
  PropertyOwnerWorkstation,
  ...PropertiesRoute,
  TradeNetwork,
  PreferredContractorsRoute,

  ...DocumentsRoute,
  ...CalendarRoute,
  WorkstationSettingRoute,
  RasieIssueRoute,
  EWalletRoute,
  EmergencyOutOfHoursRoute,
  AliaseEmailRoute,
  ConnectionRoute,
  VerifyJobsRoute,
  ChatRoute,
  ClientsRoute,
  ViewRatingRoute,
  {
    path: "/:catchAll(.*)",
    redirect: { path: "/" },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Add meta information to specify the layout for routes that require authentication
router.beforeEach(
  async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) => {
    const authToken = getLocalStorageWithExpiry(AUTH_TOKEN);
    const isRequireAuth = to.matched.some(
      (route: any) => route.meta.requiresAuth
    );
    if (to.meta.layout) {
      to.meta.layout = "AuthLayout";
    }
    if (!authToken && isRequireAuth) {
      localStorage.clear();
      return next({ path: "/" });
    }
    const user = store.getters[`${USER_STORE}/user`];
    if (!user) {
      await store.dispatch(`${USER_STORE}/initializeUserState`);
    }
    if (
      !isRequireAuth &&
      authToken &&
      FORGOT_PASSWORD_ROUTE !== to?.name &&
      ALIASE_EMAIL_ROUTE !== to?.name
    ) {
      return next({ name: DASHBOARD_ROUTE });
    }
    next();
  }
);

export default router;
