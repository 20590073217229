<template>
  <div class="main_issue_preview">
    <div class="left_block">
      <v-card class="issue_type__overview_card">
        <div class="issue_type__overview_card_content">
          <div class="heading">
            <h4 class="title">Issue Overview</h4>
          </div>
          <div class="issue_type__overview_card_text">
            <div class="issue_type__overview_card_text_item">
              <div class="issue_type__overview_list">
                <h6>Address</h6>
                <div
                  class="property_address"
                  v-if="issuesReviewComponent?.property?.length"
                >
                  <p
                    v-for="property in issuesReviewComponent?.property"
                    :key="property.id"
                  >
                    {{ property?.property?.address }}
                  </p>
                </div>
              </div>
            </div>
            <div class="issue_type__overview_card_text_item">
              <div class="heading">Issue Details</div>
              <div class="issue_type__overview_list">
                <h6>Issue Type</h6>
                <p>{{ issuesReviewComponent.issueType }}</p>
              </div>
            </div>
          </div>
        </div>
        <!-- v-if="isShowIntervention(issuesReviewComponent.property)" -->
        <!-- <template> -->
        <v-btn
          class="button button-orange"
          size="large"
          block
          @click="onClickSaveIssueLater(PropertyIssueStatus.RAISED)"
          :loading="isShowLoaderOnRaiseAnIssue"
          :disabled="
            isShowLoaderOnSaveIssueForLater ||
            isShowLoaderOnRaiseAnIssue ||
            isShowLoaderOnDeleteIssue
          "
          v-if="issuesReviewComponent.issueStatus === PropertyIssueStatus.DRAFT"
        >
          raise issue
        </v-btn>
        <v-btn
          class="button button-purple-border"
          size="large"
          block
          @click="onClickSaveIssueLater(PropertyIssueStatus.DRAFT)"
          :loading="isShowLoaderOnSaveIssueForLater"
          :disabled="
            isShowLoaderOnSaveIssueForLater ||
            isShowLoaderOnRaiseAnIssue ||
            isShowLoaderOnDeleteIssue
          "
          v-if="issuesReviewComponent.issueStatus === PropertyIssueStatus.DRAFT"
        >
          save Issue for later
        </v-btn>
        <v-btn
          v-if="isShowDeleteButton"
          class="button button-red"
          size="large"
          block
          variant="outlined"
          @click="onClickDelete()"
          :loading="isShowLoaderOnDeleteIssue"
          :disabled="
            isShowLoaderOnSaveIssueForLater ||
            isShowLoaderOnRaiseAnIssue ||
            isShowLoaderOnDeleteIssue
          "
        >
          Delete
        </v-btn>
        <!-- </template> -->
      </v-card>

      <!-- v-if="!isShowIntervention(issuesReviewComponent.issueStatus)" -->
      <!-- v-if="issuesReviewComponent.issueStatus === PropertyIssueStatus.RAISED" -->
      <div
        class="yellow-content-box"
        v-if="
          issuesReviewComponent.issueType ===
          PropertyIssueType.EMERGENCY_OUT_OF_HOURS
        "
      >
        <p>SLA Time (Manual Intervention)</p>
        <v-btn
          class="button button-orange"
          @click="onClickManualInterventionButton"
          >Yes</v-btn
        >
      </div>
    </div>
    <div class="right_block">
      <div class="top_links_main">
        <div class="back_results">
          <v-btn
            prepend-icon="mdi-chevron-left"
            variant="plain"
            @click="onClickBackButton()"
            >Back</v-btn
          >
        </div>
        <div class="emergency">
          <v-icon icon="mdi mdi-alert-circle"></v-icon>
          <p>{{ issuesReviewComponent.issueType }}</p>
        </div>
      </div>
      <!-- issue Notes -->
      <div class="issue_info__wrapper">
        <template v-if="!isEditIssueNoteSection">
          <v-card class="issue_info">
            <div class="card_content">
              <div class="card_content__header">
                <div class="card_content__title">
                  <h5>{{ issuesReviewComponent.title }}</h5>
                  <!-- <div class="card_content__title_info">
                    <div class="issue_id">
                      <p>Issue ID:</p>
                      <span>#DWS2G436366</span>
                    </div>
                  </div> -->
                </div>

                <v-icon
                  class="custom_edit"
                  icon="mdi-pencil"
                  @click.stop="updateIssueNameNote()"
                ></v-icon>
              </div>
              <div class="card_content__footer">
                <h6>Issue Notes</h6>
                <p v-html="issuesReviewComponent.description"></p>
              </div>
            </div>
          </v-card>
        </template>
        <template v-else>
          <v-card class="edit_issue_info">
            <div class="title">
              <h6>Issue Title</h6>
            </div>
            <v-text-field
              density="compact"
              placeholder="Enter Title"
              v-model="issueDetails.name"
              variant="outlined"
              class="property_input"
            ></v-text-field>

            <div class="description">
              <h6>Issue Notes</h6>
              <div class="custom_editor">
                <quill-editor
                  v-model:value="issueDetails.description"
                  :options="state.editorOption"
                  :disabled="state.disabled"
                  class="editor_ql"
                />
              </div>
            </div>
            <div class="edit__buttons">
              <v-btn
                class="button button-white"
                @click="onCancelIssueNotes"
                variant="outlined"
              >
                cancel
              </v-btn>
              <v-btn
                class="button button-orange"
                @click="onClickSaveIssueNotes"
                :loading="isLoadingIssueNotes"
                prepend-icon="mdi-content-save"
              >
                save
              </v-btn>
            </div>
          </v-card>
        </template>
      </div>

      <!-- Specialism -->
      <div class="specialism__wrapper">
        <div class="header">
          <div class="title">
            <h4>Specialism</h4>
          </div>
          <v-icon
            class="custom_edit"
            icon="mdi-pencil"
            v-if="!isEditSpecialism"
            @click="isEditSpecialism = true"
          ></v-icon>
        </div>
        <div class="specialism__wrapper__content">
          <div class="specialism-card-list">
            <div
              class="specialism-card-list__item"
              v-for="subCategory in issueSubCategory"
              :key="subCategory.id"
            >
              <v-btn
                variant="text"
                aria-label="Delete"
                class="specialism-card-list__item-delete"
                prepend-icon="mdi-delete"
                v-if="isEditSpecialism"
                @click="onClickDeleteSpecialism(subCategory.id)"
              ></v-btn>

              <div class="specialism-card-list__item-author">
                <div class="specialism-card-list__item-author-image">
                  <img
                    :src="
                      require('@/assets/category-icons/' +
                        subCategory.category.iconName +
                        '.svg')
                    "
                    alt="Image"
                  />
                </div>

                <div class="specialism-card-list__item-author-content">
                  <h2>{{ subCategory.label }}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="edit-mode-button" v-if="isEditSpecialism">
          <v-btn
            class="button button-purple-border"
            variant="text"
            @click="addMoreSpecialismClick"
          >
            Add more Specialism
          </v-btn>
          <div class="edit__buttons">
            <v-btn
              class="button button-white"
              variant="outlined"
              @click="onclickCancelEditSpecialism"
            >
              cancel
            </v-btn>
            <v-btn class="button button-orange" @click="onClickSaveSpecialism">
              save
            </v-btn>
          </div>
        </div>
      </div>

      <!-- Issue Properties -->
      <div class="properties_wrapper">
        <div class="header">
          <div class="title">
            <h4>Properties</h4>
          </div>
        </div>
        <div class="properties_wrapper__content">
          <div class="properties_wrapper__left">
            <div
              class="properties_wrapper__info"
              v-for="property in issuesReviewComponent.property"
              :key="property.id"
            >
              <div class="properties_wrapper__info__left">
                <div class="location">
                  <v-icon
                    icon="mdi mdi-map-marker"
                    class="pointer_events_none"
                  ></v-icon>
                  {{ property?.property?.postcode }}
                </div>

                <div class="prop_name">
                  <v-icon>
                    <img src="../../../assets/icons/house.svg" alt="" />
                  </v-icon>
                  <h6>{{ property?.property?.address }}</h6>
                </div>

                <!-- <div class="property__info">
                  <div class="property__info__item">NA</div>
                  <div>|</div>
                  <div class="property__info__item">
                    <span class="pointer_events_none">
                      {{
                        issuesReviewComponent?.property?.beds
                          ? issuesReviewComponent?.property?.beds
                          : "NA"
                      }}</span
                    >
                    bedroom
                  </div>
                  <div>|</div>
                  <div class="property__info__item">
                    <span class="pointer_events_none">NA</span>
                    sqm
                  </div>
                </div> -->

                <div class="property_type">
                  <v-icon
                    icon="mdi mdi-account-circle"
                    class="pointer_events_none"
                  ></v-icon
                  >{{ property?.property?.propertyCategory }}
                </div>
              </div>
              <div class="properties_wrapper__info__right">
                <div class="property_image">
                  <img
                    src="../../../assets/images/property-sample-image.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="properties_wrapper__right">
            <div
              class="map__wrapper"
              id="map_properties"
              v-if="addresses && addresses.length"
            >
              <AddressMapComponent
                class="pointer_events_none"
                :addresses="addresses"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Documents / Attachments -->
      <div class="documents_attachment">
        <div class="title">
          <h3>Documents / Attachments</h3>
          <v-select
            v-model="categoryId"
            label="Select property"
            :items="categoryName"
            multiple
            variant="outlined"
            density="compact"
            class="select_prop_dropdown"
            :menu-props="{ contentClass: 'custom__text__selector' }"
          >
          </v-select>
        </div>

        <v-expansion-panels
          v-model="categoryPanel"
          multiple
          class="accordion__wrapper"
        >
          <v-expansion-panel
            v-for="(
              categoryValue, categoryKey, index
            ) in issuesReviewComponent.categoryAttachments"
            :key="index"
          >
            <v-expansion-panel-title>
              <v-icon class="house__icon">
                <img src="../../../assets/icons/house.svg" alt="" />
              </v-icon>
              <h6>{{ categoryKey }}</h6>
              <template v-slot:actions="{ expanded }">
                <v-icon
                  class="custom_edit"
                  v-if="expanded && !isEditAttachmentsSection"
                  icon="mdi-pencil"
                  @click="updateAttachmentsSection(index)"
                ></v-icon>
                <v-icon
                  v-else
                  icon="mdi-chevron-down"
                  class="bottom__chevron"
                  @click="removeAttachmentsSection(index)"
                ></v-icon>
              </template>
            </v-expansion-panel-title>

            <div class="group__cards" v-if="isShowGroupCard(index)">
              <div class="group__card__text">
                <v-expansion-panel-text>
                  <div class="accordion_inner_title">
                    <div class="doc_main">
                      <ul class="doc_main__list">
                        <li
                          class="property__img__name"
                          v-for="(attachment, index) in categoryValue"
                          :key="index"
                        >
                          <div @click="onClickOpenNewTab(attachment)">
                            <v-icon icon="mdi mdi-image-outline"></v-icon>
                            <span>{{
                              attachment.propertyIssueAttachment?.originalName
                            }}</span>
                          </div>
                          <div
                            v-if="isEditAttachmentsSection"
                            class="close_icon"
                          >
                            <v-icon
                              icon="mdi-close"
                              class="pointer_events_none"
                              @click="
                                removeAttachmentsSection(
                                  index,
                                  attachment.propertyIssueAttachment?.id,
                                  attachment.name
                                )
                              "
                            ></v-icon>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    v-if="!isEditAttachmentsSection"
                    class="property__images"
                  >
                    <div
                      class="property__item__images"
                      v-for="(attachment, index) in categoryValue"
                      :key="index"
                      v-show="isShowImage(attachment)"
                    >
                      <img
                        :src="attachment.propertyIssueAttachment.publicUrl"
                        :alt="attachment.propertyIssueAttachment?.originalName"
                        @click="
                          openCarousel(
                            index,
                            attachment?.propertyIssueAttachment?.publicUrl
                          )
                        "
                      />
                    </div>
                  </div>
                  <div v-if="isEditAttachmentsSection" class="edit-mode-button">
                    <v-btn
                      class="button button-purple-border"
                      @click="onClickToggleAttachmentModal"
                    >
                      Upload More
                    </v-btn>
                    <div class="edit__buttons">
                      <v-btn
                        class="button button-white"
                        variant="outlined"
                        @click="onCancelAttachments"
                      >
                        cancel
                      </v-btn>
                      <v-btn
                        class="button button-orange"
                        size="large"
                        block
                        :loading="isLoadingRemoveAttachments"
                        @click="onClickSaveButtonAttachments"
                      >
                        save
                      </v-btn>
                    </div>
                  </div>
                </v-expansion-panel-text>
              </div>
            </div>
          </v-expansion-panel>
        </v-expansion-panels>
        <!-- <div v-if="isEditAttachmentsSection" class="edit-mode-button"> -->
        <!-- <div class="edit-mode-button">
          <v-btn
            class="button button-purple-border"
            @click="onClickToggleAttachmentModal"
          >
            Upload More
          </v-btn>
          <div class="edit__buttons">
            <v-btn
              class="button button-white"
              variant="outlined"
              @click="onCancelAttachments"
            >
              cancel
            </v-btn>
            <v-btn
              class="button button-orange"
              size="large"
              block
              :loading="isLoadingRemoveAttachments"
              @click="onClickSaveButtonAttachments"
            >
              save
            </v-btn>
          </div>
        </div> -->
      </div>
    </div>
    <!-- AttachmentModal -->
    <commonDialog
      v-model="isShowAttachmentModal"
      :isShowHeader="false"
      :isShowBackButton="false"
      width="860px"
      max-width="100%"
      persistent
      :className="['issue__upload__modal']"
    >
      <template v-slot:body>
        <v-icon
          icon="mdi-close"
          class="close__icon"
          @click="onClickToggleAttachmentModal"
        ></v-icon>
        <div class="modal__content">
          <div class="left_upload_img">
            <h4>Upload images and files.</h4>
            <div class="upload__area">
              <div class="uploade-wrapper">
                <!-- append-inner-icon="mdi-image-plus" -->
                <v-file-input
                  class="upload_file"
                  variant="solo-filled"
                  v-model="selectedFile"
                  :class="{ 'added-img': imageUrl }"
                  @change="onFileChange"
                  @click:clear="clearImageUrl"
                  multiple
                >
                  <!-- append-inner-icon="mdi-image-plus" -->
                  <!-- append-icon="mdi-upload" -->
                  <template v-slot:append-inner>
                    <v-icon>mdi-image-plus</v-icon>
                  </template>
                  <!-- <template v-slot:label>
                    <div class="custom-label">
                      <v-icon left>mdi-upload</v-icon>
                      <span>Upload</span>
                    </div>
                  </template> -->
                </v-file-input>
                <v-img
                  class="uploaded-img"
                  :width="200"
                  aspect-ratio="16/9"
                  cover
                  :src="imageUrl"
                ></v-img>
                <v-icon icon="mdi mdi-file-image-box"></v-icon>

                <p class="error_msg" v-if="errorMessage">
                  {{ errorMessage }}
                </p>
              </div>
              <div class="upload__text">
                <p>
                  <strong>Drag and drop documents, or</strong>
                  <span>Browse</span>
                </p>
                <p class="">
                  Attachments such as images, design ideas and documents help
                  better understand the project and provide increasingly
                  accurate estimates.
                </p>
              </div>
            </div>
            <div class="selected-info">
              <v-btn
                block
                aria-label="Refresh"
                class="button button-orange"
                @click="addIssueImage"
                :disabled="!uploadedImages"
                >{{ attachmentDataList.length ? "ADD ANOTHER" : "add" }}</v-btn
              >
            </div>
          </div>

          <div class="image__upload__wrapper">
            <div v-if="attachmentDataList.length">
              <h5>Uploaded Files</h5>
              <div class="uploaded_details_wrapper">
                <div
                  class="show_uploaded_details"
                  v-for="(attachment, index) in attachmentDataList"
                  :key="index"
                >
                  <!-- <v-divider color="info" class="mt-3 mb-3"></v-divider> -->
                  <v-img
                    class="uploaded-img"
                    :width="156"
                    :height="88"
                    aspect-ratio="16/9"
                    cover
                    :src="attachment?.uploadedImages?.url"
                  ></v-img>
                  <div class="prop_img_details">
                    <h5>{{ attachment?.uploadedImages?.name }}</h5>
                    <v-btn
                      @click="removeImageFromAttachments(index)"
                      variant="text"
                    >
                      <img src="../../../assets/icons/delete.svg" alt="" />
                    </v-btn>
                  </div>

                  <div class="upload__list">
                    <!-- <v-select
                        v-model="attachment.issueId"
                        placeholder="Select issue"
                        :items="userSelectedIssue"
                        item-title="address"
                        item-value="id"
                        multiple
                        variant="outlined"
                        density="compact"
                        class="selectprop_dropdown"
                      >
                      </v-select> -->
                    <!-- <v-text-field
                        density="compact"
                        placeholder="E.g. Public liability"
                        variant="outlined"
                        v-model="attachment.userCategoryName"
                        class="v-text-custom"
                      >
                        <v-icon
                          icon="mdi-menu-down"
                          :id="`menu-activator-${index}`"
                          class="cursor-pointer"
                        ></v-icon>
                      </v-text-field> -->
                    <v-combobox
                      class="selectprop_dropdown"
                      v-model="attachment.userCategoryName"
                      :items="userCategory"
                      item-title="name"
                      item-value="name"
                      variant="outlined"
                      placeholder="Enter or select category"
                      multiple
                      :menu-props="{ contentClass: 'text__selector' }"
                    ></v-combobox>
                  </div>
                  <v-menu
                    transition="slide-y-reverse-transition"
                    class="slider-menu-active"
                    :activator="`#menu-activator-${index}`"
                    :active="true"
                  >
                    <v-list>
                      <v-list-item
                        v-for="(item, index) in userCategory"
                        :key="index"
                      >
                        <v-list-item-title
                          class="cursor-pointer"
                          @click="attachment.userCategoryName = item.name"
                          >{{ item.name }}</v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </div>
            </div>
            <div v-else class="blank_project">
              <p>Your added project attachments will show up here</p>
            </div>
          </div>
        </div>

        <div class="footer__button footer-buttons">
          <v-btn
            class="button button-purple-border"
            @click="onClickToggleAttachmentModal"
            variant="outlined"
          >
            cancel
          </v-btn>
          <v-btn
            class="button button-orange"
            :loading="isLoadingOnSave"
            :disabled="!isAttachmentDataValid"
            @click="onClickSaveButtonAttachmentsModal"
          >
            save
          </v-btn>
        </div>
      </template>
    </commonDialog>

    <!-- CarouselDialog -->
    <v-dialog
      v-model="isCarouselDialog"
      height="auto"
      max-width="600"
      class="issue__carousal__modal"
      :show-arrows="false"
    >
      <v-card>
        <template v-slot:actions>
          <v-btn
            icon="mdi-close"
            @click="isCarouselDialog = false"
            class="d-flex"
          ></v-btn>
          <v-carousel
            hide-delimiter-background
            show-arrows="hover"
            v-model="currentIndex"
          >
            <v-carousel-item>
              <img cover :src="carouselImage" height="100%" width="100%" />
            </v-carousel-item>
          </v-carousel>
        </template>
      </v-card>
    </v-dialog>
  </div>
  <SpecialismMultiSelectModel
    v-if="isShowSpecialismModal"
    @onClickCloseTab="closeSpecialismModal"
    :selectedSpecialism="issueSubCategoryIdForSpecialismModel"
    @onClickSaveButtonClick="onClickSaveButtonClick"
  />
  <ManualIntervention
    v-if="isShowManualInterventionModal"
    :issueDetail="issuesReviewComponent"
    @onClickManualIntervention="onClickManualIntervention"
    :isLoading="isLoading"
    @onClickNoIntervene="onClickNoIntervene"
  />
</template>
<script lang="ts">
import { ref, reactive, getCurrentInstance, onMounted } from "vue";
import { quillEditor } from "vue3-quill";
import AddressMapComponent from "@/core/components/AddressMapComponent.vue";
import CommonDialog from "@/core/components/CommonDialog.vue";
import RaiseIssueService from "@/core/services/raise-issue.service";
import { onBeforeMount } from "vue";
import { getImageApiUrl, parseJSON } from "@/core/utils/common";
import $axios from "@/core/utils/axios-api-config";
import { USER_STORE } from "@/store/modules/user";
import { useStore } from "vuex";
import {
  PropertyIssueStatus,
  PropertyIssueType,
} from "@/core/enums/RaiseIssueEnum";
import SpecialismMultiSelectModel from "@/modules/project/modal/SpecialismMultiSelectModel.vue";
import { PROPERTY_STORE } from "@/store/modules/property";
import { PropertyTypeEnum } from "@/core/enums/PropertiesEnum";
import { PROJECT_STORE } from "@/store/modules/project";
import ManualIntervention from "./ManualIntervention.vue";
import { useRouter } from "vue-router";
import EOHService from "@/core/services/emergency-out-of-hours.service";
import ProjectService from "@/core/services/project.service";
import { LatLngExpression } from "leaflet";

import {
  ProjectJobStatusEnum,
  ProjectTypesEnum,
} from "@/core/enums/ProjectsEnum";
import { watch } from "vue";
import { computed } from "vue";
export default {
  name: "IssueReviewComponent",
  props: {
    attachmentList: { type: Array, default: [] as any },
    createdIssueId: { type: Number, default: null },
    isShowDeleteButton: { type: Boolean, default: false },
  },
  components: {
    quillEditor,
    AddressMapComponent,
    CommonDialog,
    SpecialismMultiSelectModel,
    ManualIntervention,
  },
  setup(props: any, ctx: any) {
    const internalInstance = getCurrentInstance();

    const isEditIssueNoteSection = ref(false);
    const issueTitle = ref("");
    const IssueDescription = ref("Kitchen Refurbishment");
    const isLoadingIssueNotes = ref(false);
    const isEditAttachmentsSection = ref(false);
    const isLoadingRemoveAttachments = ref(false);
    const isShowAttachmentModal = ref(false);
    const isNewPropertyAdded = ref(false);
    const addresses: any = ref([]);
    const categoryId = ref([]);
    const categoryPanel = ref([]) as any;
    const isCarouselDialog = ref(false);
    const currentIndex = ref(0);
    const carouselImage = ref("");
    const imageUrl = ref("");
    const errorMessage = ref("");
    const uploadedImages = ref(null) as any;
    const attachmentDataList = ref([]) as any;
    const userCategory = ["Floorplan", "Garden", "Materials", "Maps"];

    const router = useRouter();

    const isShowLoadingOnSave = ref(false);

    const isLoadingOnSave = ref(false);

    const isShowLoaderOnSaveIssueForLater = ref(false);

    const isShowSpecialismModal = ref(false);

    const isShowLoaderOnRaiseAnIssue = ref(false);

    const isShowLoaderOnDeleteIssue = ref(false);

    const isEditSpecialism = ref(false);

    const removedAttachmentList = ref([]) as any;

    const categoryName = ref([]) as any;

    const selectedFile = ref([]) as any;

    const issueSubCategory = ref([]) as any;

    const issueSubCategoryId = ref([]) as any;

    const issueSubCategoryIdForSpecialismModel = ref([]) as any;

    const isLoadingForSpecialismComponent = ref(false);

    const isShowManualInterventionModal = ref(false);

    const issuesTypeList = ref([
      {
        id: 1,
        name: "Emergency",
        iconName: "Vector",
        subTitle: "This project needs to start now",
        selected: false,
        value: "emergency",
      },
      {
        id: 2,
        name: "Standard",
        iconName: "model_training_black",
        subTitle: "This project has a flexible or preferred start date ",
        selected: false,
        value: "standard",
      },
      {
        id: 3,
        name: "Out Of Hours",
        iconName: "emergency-watch",
        subTitle: "This project has a flexible or preferred start date ",
        selected: false,
        value: "emergency_out_of_hours",
      },
    ]) as any;

    const issuesReviewComponent = ref({
      title: "",
      issueType: "",
      issueStatus: "",
      description: "",
      property: null,
      categoryAttachments: [],
      subCategoryData: [],
      user: null,
      EOHEventId: null,
    }) as any;

    const issuesRefId = ref(null);

    const EOHEventPreferredContractor = ref([]) as any;

    const issueDetails = ref({
      name: "",
      description: "",
    }) as any;

    const store = useStore();

    const groupedByCategory = ref(null) as any;

    const isLoading = ref(false) as any;

    const createProject = ref(null) as any;

    const categoryAttachments = ref([
      {
        id: 14,
        dateCreated: "2024-04-29T06:26:15.432Z",
        dateUpdated: "2024-04-29T06:26:15.432Z",
        name: "Garden",
        issueAttachment: {
          id: 11,
          dateCreated: "2024-04-29T06:26:15.427Z",
          dateUpdated: "2024-04-29T06:26:15.427Z",
          attachment: "temp/1714106127824_l06GEWGuICwVR.jpg",
          originalName: "profile-avatar-girl-female.jpg",
          issue: {
            id: 8,
            dateCreated: "2024-04-29T06:26:15.425Z",
            dateUpdated: "2024-04-29T06:26:15.425Z",
            name: "asdfasd",
            description: "sdfdsfjhihfsd",
            issueType: "standard",
            issueProgressStatus: "open",
            issueStatus: "0",
          },
        },
      },
      {
        id: 16,
        dateCreated: "2024-04-29T06:26:15.437Z",
        dateUpdated: "2024-04-29T06:26:15.437Z",
        name: "Maps",
        issueAttachment: {
          id: 12,
          dateCreated: "2024-04-29T06:26:15.434Z",
          dateUpdated: "2024-04-29T06:26:15.434Z",
          attachment: "temp/1714106127824_l06GEWGuICwVR.jpg",
          originalName: "profile-avatar-girl-female.jpg",
          issue: {
            id: 8,
            dateCreated: "2024-04-29T06:26:15.425Z",
            dateUpdated: "2024-04-29T06:26:15.425Z",
            name: "asdfasd",
            description: "sdfdsfjhihfsd",
            issueType: "standard",
            issueProgressStatus: "open",
            issueStatus: "0",
          },
        },
      },
      {
        id: 15,
        dateCreated: "2024-04-29T06:26:15.436Z",
        dateUpdated: "2024-04-29T06:26:15.436Z",
        name: "Floor",
        issueAttachment: {
          id: 12,
          dateCreated: "2024-04-29T06:26:15.434Z",
          dateUpdated: "2024-04-29T06:26:15.434Z",
          attachment: "temp/1714106127824_l06GEWGuICwVR.jpg",
          originalName: "profile-avatar-girl-female.jpg",
          issue: {
            id: 8,
            dateCreated: "2024-04-29T06:26:15.425Z",
            dateUpdated: "2024-04-29T06:26:15.425Z",
            name: "asdfasd",
            description: "sdfdsfjhihfsd",
            issueType: "standard",
            issueProgressStatus: "open",
            issueStatus: "0",
          },
        },
      },
    ]);

    const state = reactive({
      content: "",
      _content: "",
      editorOption: {
        placeholder: "Description here",
        modules: {},
      },
      disabled: false,
    });

    const properties = computed(
      () => store.getters[`${PROPERTY_STORE}/properties`]
    );

    const updateIssueNameNote = () => {
      isEditIssueNoteSection.value = true;
      issueTitle.value = "Kitchen Refurbishment";
      state.content = "Kitchen Refurbishment";
    };

    const displayToast = (message: string, type: string) => {
      if (
        internalInstance &&
        internalInstance.appContext.config.globalProperties.$notify
      ) {
        internalInstance.appContext.config.globalProperties.$notify[type]({
          message,
        });
      }
    };

    const onCancelIssueNotes = () => {
      isEditIssueNoteSection.value = false;
    };

    const onClickSaveIssueNotes = async () => {
      try {
        isLoadingIssueNotes.value = true;
        await RaiseIssueService.updateParticularIssue(
          props.createdIssueId,
          issueDetails.value
        );
        await getUpdatedData();
        await displayToast("Update successfully.", "success");
      } catch {
        console.log();
      } finally {
        // await setProjectData();
        isEditIssueNoteSection.value = false;
        isLoadingIssueNotes.value = false;
      }
    };

    const updateAttachmentsSection = (index: number) => {
      categoryPanel.value.push(index);
      isEditAttachmentsSection.value = true;
    };

    const onClickSaveButtonAttachments = async () => {
      try {
        isLoadingRemoveAttachments.value = true;

        await RaiseIssueService.updateParticularIssue(props.createdIssueId, {
          deleteAttachment: removedAttachmentList.value,
        });

        await getUpdatedData();
        isEditAttachmentsSection.value = false;
      } catch {
        console.log();
      } finally {
        isLoadingRemoveAttachments.value = false;
      }
    };

    const onCancelAttachments = () => {
      isEditAttachmentsSection.value = false;
    };

    const isShowGroupCard = (index: number) => {
      return categoryPanel.value.includes(index);
    };

    const onFileChange = async (event: any) => {
      const fileInput = event.target;
      errorMessage.value = "";
      if (fileInput.files && fileInput.files.length > 0) {
        Array.from(fileInput.files).forEach((file: any) => {
          if (file.type.includes("image")) {
            // Check image size limit (200MB)
            if (file.size <= 200 * 1024 * 1024) {
              imageUrl.value = URL.createObjectURL(file);
              uploadedImages.value = {
                url: imageUrl.value,
                name: file.name,
                imageData: file,
              };
            } else {
              errorMessage.value = "Image size exceeds the limit (200MB).";
              // Handle error message or display notification
            }
          } else if (file.type.includes("video")) {
            // Check video size limit (20MB)
            if (file.size <= 10 * 1024 * 1024) {
              uploadedImages.value = {
                type: "video",
                name: file.name,
                imageData: file,
              };
              // Process video file
            } else {
              errorMessage.value = "Video size exceeds the limit (20MB).";
              // Handle error message or display notification
            }
          } else {
            // Allow other file types without size validation
            imageUrl.value = URL.createObjectURL(file);
            uploadedImages.value = {
              url: imageUrl.value,
              name: file.name,
              imageData: file,
            };
          }
        });
      } else {
        console.error("Error: No file selected.");
      }
    };

    const removeImageFromAttachments = (index: number) => {
      attachmentDataList.value.splice(index, 1);
      selectedFile.value = [];
      imageUrl.value = "";
    };

    const onClickSaveButtonAttachmentsModal = async () => {
      try {
        isLoadingOnSave.value = true;
        const formData = new FormData();
        attachmentDataList.value.forEach((attachment: any) => {
          formData.append(`files`, attachment.uploadedImages.imageData);
        });
        const uploadMultipleImage = await store.dispatch(
          `${USER_STORE}/uploadMultipleImage`,
          formData
        );
        const attachments = [] as any;
        uploadMultipleImage.forEach((image: any) => {
          attachments.push({
            attachment: image.attachment,
            originalName: image.originalName,
            attechmentCategories: [],
          });
        });

        attachmentDataList.value.forEach((attachment: any, index: any) => {
          attachment.userCategoryName.forEach((name: string) => {
            attachments[index]?.attechmentCategories.push({
              name,
            });
          });
        });

        await RaiseIssueService.updateParticularIssue(props.createdIssueId, {
          attachments: attachments,
        });

        await getUpdatedData();
        attachmentDataList.value = [];
        isShowAttachmentModal.value = false;
      } catch (e) {
        console.log("Error: ", e);
      } finally {
        isLoadingOnSave.value = false;
        imageUrl.value = "";
      }
    };

    const openCarousel = (idx: any = 0, categoryData: any) => {
      isCarouselDialog.value = true;
      // carouselImageList.value = getFilterImages(categoryData);
      carouselImage.value = categoryData;
      currentIndex.value = idx;
    };

    const onClickToggleAttachmentModal = () => {
      isShowAttachmentModal.value = !isShowAttachmentModal.value;
      isNewPropertyAdded.value = false;
    };

    const getLatLng = (addressData: string): LatLngExpression | undefined => {
      if (!addressData) return undefined;

      try {
        const unParsedData = parseJSON(addressData);
        const latlng: LatLngExpression = [
          unParsedData.LAT ? unParsedData.LAT : unParsedData.lat,
          unParsedData.LNG ? unParsedData.LNG : unParsedData.lng,
        ];
        return latlng;
      } catch (error) {
        console.error("Invalid address data:", error);
        return undefined;
      }
    };
    const getUpdatedData = async () => {
      if (props.createdIssueId) {
        const createdIssue = (await RaiseIssueService.getParticularIssue(
          props.createdIssueId
        )) as any;
        issuesRefId.value = createdIssue?.refId;
        issueDetails.value.name = createdIssue.name;
        issueDetails.value.description = createdIssue.description;
        issuesReviewComponent.value.title = createdIssue.name;
        issuesReviewComponent.value.property =
          createdIssue?.propertyIssueAttachedProperties;
        createdIssue?.propertyIssueAttachedProperties &&
          createdIssue?.propertyIssueAttachedProperties.map((item: any) => {
            if (item?.property?.addressData) {
              const address = parseJSON(item.property.addressData);
              addresses.value.push({
                address: item.property.address,
                uprn: item.property.uprn,
                latlang: getLatLng(address),
                id: item.property.id,
                postcode: item.property.postcode,
              });
            }
          });
        issuesReviewComponent.value.issueType = issuesTypeList.value.find(
          (type: any) => {
            return type.value === createdIssue.issueType;
          }
        )?.name;
        issuesReviewComponent.value.issueStatus = createdIssue.issueStatus;
        issuesReviewComponent.value.description = createdIssue.description;
        issuesReviewComponent.value.categoryAttachments =
          createdIssue.groupedByCategory;

        issuesReviewComponent.value.subCategoryData =
          createdIssue.subCategories;

        issuesReviewComponent.value.user = createdIssue.user;

        issueSubCategory.value = issuesReviewComponent.value.subCategoryData;

        issuesReviewComponent.value.EOHEventId =
          createdIssue.emergencyOutOfHours?.id;

        groupedByCategory.value = createdIssue.groupedByCategory;

        for (const [attachmentValue, attachmentKey] of Object.entries(
          issuesReviewComponent.value.categoryAttachments
        ) as any) {
          categoryName.value.push(attachmentValue);
          attachmentKey.map(async (attachment: any) => {
            attachment.propertyIssueAttachment["publicUrl"] =
              await getAttachmentImage(
                attachment.propertyIssueAttachment.attachment
              );
          });
        }
      }
    };

    watch(
      () => categoryId.value,
      (newValue: any) => {
        issuesReviewComponent.value.categoryAttachments = {};
        for (const [attachmentValue, attachmentKey] of Object.entries(
          groupedByCategory.value
        ) as any) {
          if (newValue.includes(attachmentValue)) {
            issuesReviewComponent.value.categoryAttachments[
              `${attachmentValue}`
            ] = attachmentKey;
          }
        }
      }
    );

    onBeforeMount(() => {
      getUpdatedData();
    });

    const getAttachmentImage = async (attachmentpath: string) => {
      const imageUrl = getImageApiUrl(attachmentpath, true);
      try {
        const encoded: any = await $axios.get(imageUrl);
        return encoded?.publicUrl;
      } catch (error) {
        return null;
      }
    };

    const addIssueImage = () => {
      attachmentDataList.value.push({
        uploadedImages: { ...uploadedImages.value },
        issueId: null,
        userCategoryName: null,
      });
      uploadedImages.value = null;
      selectedFile.value = [];
      imageUrl.value = "";
    };

    const removeAttachmentsSection = (
      removeIndex: number,
      index: number,
      name: string
    ) => {
      issuesReviewComponent.value.categoryAttachments[`${name}`].splice(
        removeIndex,
        1
      );
      removedAttachmentList.value.push({
        id: index,
        name,
      });
    };
    const onClickSaveIssueLater = async (issueStatus: string) => {
      try {
        if (issueStatus === PropertyIssueStatus.RAISED) {
          isShowLoaderOnRaiseAnIssue.value = true;
        }

        if (issueStatus === PropertyIssueStatus.DRAFT) {
          isShowLoaderOnSaveIssueForLater.value = true;
        }

        await RaiseIssueService.updateParticularIssue(props.createdIssueId, {
          issueStatus,
        });

        ctx.emit("goToListIssuesTab");
      } catch (error) {
        console.log();
      } finally {
        isShowLoaderOnSaveIssueForLater.value = false;
        isShowLoaderOnRaiseAnIssue.value = false;
      }
    };

    const onClickDelete = async () => {
      try {
        isShowLoaderOnDeleteIssue.value = true;
        await RaiseIssueService.deleteParticularIssue(props.createdIssueId);
        ctx.emit("goToListIssuesTab");
      } catch (error) {
        console.log();
      } finally {
        isShowLoaderOnDeleteIssue.value = false;
      }
    };

    const onClickBackButton = () => {
      ctx.emit("onClickBackButton");
    };

    const clearImageUrl = () => {
      imageUrl.value = "";
    };

    const onClickOpenNewTab = (attachment: any) => {
      window.open(attachment?.propertyIssueAttachment?.publicUrl);
    };

    const isShowImage = (attachment: any) => {
      const fileName =
        attachment?.propertyIssueAttachment?.originalName.split(".")[1];
      if (fileName === "png" || fileName === "jpg") {
        return true;
      } else {
        return false;
      }
    };

    const getFilterImages: any = (category: any) => {
      const filterImages: any = [];
      return Object.values(category)
        .flat()
        .filter((category: any) => {
          if (!filterImages.includes(category.attachmentUrl)) {
            filterImages.push(category.attachmentUrl);
            return true;
          }
          return false;
        });
    };

    const onClickDeleteSpecialism = (subCategoryId: number) => {
      issueSubCategory.value = issueSubCategory.value.filter(
        (category: any) => {
          return category.id !== subCategoryId;
        }
      );

      issueSubCategoryId.value = issueSubCategory.value.map((category: any) => {
        return category.id;
      });
    };

    const onClickSaveSpecialism = async () => {
      try {
        await RaiseIssueService.updateParticularIssue(props.createdIssueId, {
          subCategoryData: issueSubCategoryId.value,
        });
        getUpdatedData();
      } catch (e) {
        console.log("Error :", e);
      } finally {
        isEditSpecialism.value = false;
      }
    };

    const addMoreSpecialismClick = () => {
      issueSubCategoryIdForSpecialismModel.value = issueSubCategory.value.map(
        (category: any) => {
          return { id: category.id };
        }
      );
      isShowSpecialismModal.value = true;
    };

    const closeSpecialismModal = () => {
      isShowSpecialismModal.value = false;
    };

    const onClickSaveButtonClick = async (selectedCategory: any) => {
      try {
        isLoadingForSpecialismComponent.value = true;
        await RaiseIssueService.updateParticularIssue(props.createdIssueId, {
          subCategoryData: selectedCategory,
        });
        getUpdatedData();
        isLoadingForSpecialismComponent.value = false;
      } catch (error) {
        console.log("error", error);
      } finally {
        isShowSpecialismModal.value = false;
        isEditSpecialism.value = false;
      }
    };

    const onclickCancelEditSpecialism = () => {
      isEditSpecialism.value = false;
      issueSubCategory.value = issuesReviewComponent.value.subCategoryData;
    };

    const isAttachmentDataValid = computed(() => {
      const isCategorySelected = attachmentDataList.value.every(
        (attachmentDataList: any) => {
          return attachmentDataList.userCategoryName?.length;
        }
      );

      if (!attachmentDataList.value || attachmentDataList.value.length === 0) {
        return false;
      } else if (attachmentDataList.value.length && !isCategorySelected) {
        return false;
      } else {
        return true;
      }
    });

    const isShowIntervention = (propertiesData: any) => {
      const issueProperty = properties?.value?.filter((userProperty: any) => {
        const propertyDetails = propertiesData?.find((property: any) => {
          return (
            userProperty?.postcode === property.property?.postcode &&
            userProperty.uprn === property.property?.uprn
          );
        });

        return propertyDetails;
      });

      const isPropertyOccupier = issueProperty?.some((property: any) => {
        return property.propertyRole === PropertyTypeEnum.OCCUPIER;
      });

      return isPropertyOccupier;
    };

    const onClickManualInterventionButton = () => {
      isShowManualInterventionModal.value = true;
    };

    const onClickManualIntervention = async () => {
      try {
        isLoading.value = true;
        let propertiesData = [] as any;
        issuesReviewComponent.value.property.forEach((property: any) => {
          propertiesData.push({ propertyId: property.property.id });
        });
        const attachmentCategoryData: any = [];

        for (let [key, attachment] of Object.entries(
          issuesReviewComponent.value.categoryAttachments
        ) as any) {
          const existCategory = attachmentCategoryData.find(
            (category: any) => category.label === key
          );
          if (existCategory) {
            attachmentCategoryData.push(existCategory);
          } else {
            const response = await store.dispatch(
              `${PROJECT_STORE}/attachmentCategory`,
              {
                label: key,
                value: key,
              }
            );
            attachmentCategoryData.push(response);
          }
        }

        let attachmentsList = [] as any;
        for (let [key, attachments] of Object.entries(
          issuesReviewComponent.value.categoryAttachments
        ) as any) {
          attachments.forEach((attachmentData: any) => {
            attachmentsList.push({
              publicUrl: attachmentData?.propertyIssueAttachment?.publicUrl,
              attachment: attachmentData?.propertyIssueAttachment.attachment,
              originalName:
                attachmentData?.propertyIssueAttachment.originalName,
              propertyIds: issuesReviewComponent.value.property.map(
                (property: any) => {
                  return {
                    propertyId: property.property?.id,
                    isDeleted: false,
                  };
                }
              ),
              attechmentCategoryId: attachmentCategoryData.find((data: any) => {
                return data.label === attachmentData.name;
              })?.id,
              isDeleted: false,
            });
          });
        }

        const categories = issuesReviewComponent.value.subCategoryData.map(
          (subCategory: any) => {
            return subCategory?.id;
          }
        );
        createProject.value = await store.dispatch(
          `${PROJECT_STORE}/createProject`,
          {
            propertiesData,
            projectJobStatusId: ProjectJobStatusEnum.DRAFT_PROJECT,
            type: ProjectTypesEnum.EMERGENCY,
            subType: null,
            description: issueDetails.value.description,
            name: issueDetails.value.name,
            startDate: null,
            endDate: null,
            inviteMembers: [],
            categories,
            attachments: attachmentsList,
            siteVisitStatus: false,
            isAddSpecialismFromGemini: false,
          }
        );
        if (issuesReviewComponent.value?.EOHEventId) {
          EOHEventPreferredContractor.value = (
            (await EOHService.getParticularEOHEvents(
              issuesReviewComponent.value?.EOHEventId
            )) as any
          )?.EOHPreferredContractor;
          if (EOHEventPreferredContractor.value.length) {
            await ProjectService.updateProjectStatus(createProject.value?.id, {
              projectJobStatusId: ProjectJobStatusEnum.AWAITING_QUOTES,
              inviteOnlyProject: true,
            });
            EOHEventPreferredContractor.value.forEach(
              async (contractor: any) => {
                await ProjectService.inviteContractorToProject({
                  project: createProject.value?.id,
                  user: contractor.user.id,
                  userWorkstation: contractor.userWorkstation.id,
                });
              }
            );
          }
        }
        if (issuesRefId.value) {
          await EOHService.stopAutomateProjectConversion({
            name: issuesRefId.value,
          });
        }
        isLoading.value = false;
      } catch (error) {
        console.log("error :", error);
      } finally {
        isLoading.value = false;
        isShowManualInterventionModal.value = false;
        if (createProject.value) {
          router.push({
            name: "projectDetails",
            params: { refId: createProject.value.refId },
          });
        }
      }
    };

    const onClickNoIntervene = () => {
      isShowManualInterventionModal.value = false;
    };

    return {
      isLoading,
      onClickOpenNewTab,
      isEditIssueNoteSection,
      updateIssueNameNote,
      issueTitle,
      state,
      IssueDescription,
      isLoadingIssueNotes,
      onCancelIssueNotes,
      onClickSaveIssueNotes,
      addresses,
      categoryId,
      categoryAttachments,
      updateAttachmentsSection,
      isEditAttachmentsSection,
      isShowGroupCard,
      categoryPanel,
      onClickSaveButtonAttachments,
      onCancelAttachments,
      isLoadingRemoveAttachments,
      openCarousel,
      isCarouselDialog,
      currentIndex,
      carouselImage,
      onClickToggleAttachmentModal,
      isShowAttachmentModal,
      imageUrl,
      clearImageUrl,
      onFileChange,
      errorMessage,
      uploadedImages,
      attachmentDataList,
      removeImageFromAttachments,
      onClickSaveButtonAttachmentsModal,
      userCategory,
      issuesReviewComponent,
      getAttachmentImage,
      addIssueImage,
      removeAttachmentsSection,
      onClickSaveIssueLater,
      PropertyIssueStatus,
      issueDetails,
      onClickDelete,
      onClickBackButton,
      categoryName,
      isShowLoadingOnSave,
      isShowLoaderOnSaveIssueForLater,
      isShowLoaderOnRaiseAnIssue,
      isShowLoaderOnDeleteIssue,
      isLoadingOnSave,
      isShowImage,
      selectedFile,
      isAttachmentDataValid,
      isEditSpecialism,
      issueSubCategory,
      onClickDeleteSpecialism,
      onClickSaveSpecialism,
      isShowSpecialismModal,
      addMoreSpecialismClick,
      closeSpecialismModal,
      issueSubCategoryId,
      issueSubCategoryIdForSpecialismModel,
      onClickSaveButtonClick,
      isLoadingForSpecialismComponent,
      onclickCancelEditSpecialism,
      isShowIntervention,
      PropertyTypeEnum,
      onClickManualInterventionButton,
      isShowManualInterventionModal,
      onClickManualIntervention,
      onClickNoIntervene,
      PropertyIssueType,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/modules/issues/styles/issuesReview.scss";
</style>
