export default {
  setTradeEstimate(state: any, payload: any) {
    state.tradeEstimate = payload;
  },
  setTradeEstimateDetails(state: any, payload: any) {
    state.tradeEstimateDetail = payload;
  },
  setTradeEstimateDataWithEscrowStatus(state: any, payload: any) {
    state.tradeEstimateWithEscrowStatus = payload;
  },
  setProjectEstimateStatus(state: any, payload: boolean) {
    state.projectEstimateStatus = payload;
  },
  setContractorTradePassportDetail(state: any, payload: boolean) {
    state.contractorTradePassportDetail = payload;
  },
  setContractorTradePassportWorkstationId(state: any, id: number) {
    state.contractorTradePassportWorkstationId = id;
  },
};
