<template>
  <div class="e-wallet-wrapper md:!tw-pb-16 md:!tw-box-border">
    <h4 class="md:!tw-text-base">Workstation E-Wallet Account</h4>
    <WalletTransactionsListComponent />
  </div>
</template>
<script lang="ts">
import WalletTransactionsListComponent from "@/modules/e-wallet/components/WalletTransactionsListComponent.vue";
export default {
  components: {
    WalletTransactionsListComponent,
  },
  setup(props: any, ctx: any) {
    return {};
  },
};
</script>
<style lang="scss">
.e-wallet-wrapper {
  margin-top: 16px;
  h4 {
    display: flex;
    @include fluidFont(24, 24, 1.3);
    font-weight: 700;
    letter-spacing: 0.25px;
    color: rgba($blueDark, 1);
  }
}
</style>
