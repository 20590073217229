import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-626e84b7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal_content" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "form_wrapper" }
const _hoisted_4 = { class: "modal_form" }
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 0,
  class: "modal_form_footer"
}
const _hoisted_7 = {
  key: 1,
  class: "success-btn-wrap"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_CommonDialog = _resolveComponent("CommonDialog")!

  return (_openBlock(), _createBlock(_component_CommonDialog, {
    isShowHeader: false,
    isShowBackButton: false,
    className: ['delete_event_modal'],
    width: "350px",
    height: "auto"
  }, {
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_v_btn, {
            variant: "plain",
            icon: "mdi-close",
            onClick: $setup.onClickCloseTab
          }, null, 8, ["onClick"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("img", {
              src: $setup.getModalInfo.image,
              alt: ""
            }, null, 8, _hoisted_5),
            _createElementVNode("h6", null, _toDisplayString($setup.getModalInfo.header), 1),
            _createElementVNode("p", null, _toDisplayString($setup.getModalInfo.info), 1)
          ])
        ]),
        ($props.modalType === 'delete')
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(_component_v_btn, {
                variant: "plain",
                onClick: $setup.onClickCloseTab,
                class: "button button-purple-border"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Cancel")
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_v_btn, {
                class: "button button-orange",
                onClick: $setup.onClickDeleteEmail
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Delete")
                ]),
                _: 1
              }, 8, ["onClick"])
            ]))
          : _createCommentVNode("", true),
        ($props.modalType === 'success')
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createVNode(_component_v_btn, {
                class: "button button-orange",
                onClick: $setup.onClickCloseTab
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" CONTINUE ")
                ]),
                _: 1
              }, 8, ["onClick"])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}