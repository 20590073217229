<template>
  <GeneralWidgetLayoutSlot
    :getUserWorkStationFieldAccess="getUserWorkStationFieldAccess"
    :class="className"
    :hasCustomHeader="true"
    buttonLabel="Manage Team"
    @on-action-click="onClickManageTeam"
  >
    <template #custom-header>
      <div
        class="tw-flex tw-w-full tw-flex-col tw-gap-3 tw-items-start !tw-px-4 !tw-box-border"
      >
        <div class="tw-w-full tw-flex tw-gap-2 tw-items-center">
          <!-- <ProfileThumbnail size="sm" /> -->
          <ProfileThumbnail
            class="ws-profile-logo"
            size="sm"
            v-if="userWorkStationImage"
            :src="userWorkStationImage"
          />
          <div
            v-else
            class="!tw-rounded-lg tw-overflow-hidden tw-max-h-[22px] tw-max-w-[22px] tw-min-h-[22px] tw-min-w-[22px] tw-h-[22px] tw-relative"
          >
            <UserProfileLogo class="ws-profile-logo" />
          </div>
          <Text variant="h6">{{ activeUserWorkstation?.name }}</Text>
        </div>
        <Text variant="span" textWeight="400">
          {{ members?.length }} Member Online | {{ members?.length }} Team
          Members</Text
        >
      </div>
    </template>
    <template #content>
      <main class="tw-h-full tw-w-full tw-pb-5 tw-px-4 tw-box-border">
        <div
          class="tw-flex tw-items-center tw-gap-4 tw-overflow-x-auto tw-w-full tw-py-2 tw-box-border"
          v-if="members?.length > 0"
        >
          <template v-for="(member, index) in members">
            <div v-if="index < 8" :key="index">
              <div v-if="index === 0">
                <ProfileThumbnail
                  size="md"
                  :isOnline="true"
                  :isStatusTypeChat="true"
                  v-if="member?.userProfileImageUrl"
                  :src="member?.userProfileImageUrl"
                />
                <div
                  v-else
                  class="tw-rounded-full tw-max-h-[48px] tw-max-w-[48px] tw-min-h-[48px] tw-min-w-[48px] tw-h-[48px] tw-relative"
                >
                  <div
                    class="tw-h-[10px] tw-w-[10px] tw-bg-[#3BC740] tw-rounded-full tw-border tw-border-solid tw-border-[#FFF] tw-absolute tw-top-0 tw-right-[.2rem] tw-z-10"
                  ></div>
                  <UserProfileLogo :userName="member?.userName" />
                </div>
              </div>
              <div
                v-if="
                  index > 0 &&
                  (member.invitationStatus === 'pending' ||
                    member.invitationStatus === 'accepted')
                "
                class="tw-rounded-full tw-max-h-[48px] tw-max-w-[48px] tw-min-h-[48px] tw-min-w-[48px] tw-h-[48px] tw-relative"
              >
                <!-- <div
                    class="tw-h-[10px] tw-w-[10px] tw-rounded-full tw-border tw-border-solid tw-border-[#FFF] tw-absolute tw-top-0 tw-right-[.2rem] tw-z-10"
                    :class="{
                      'tw-bg-[#3BC740]': member?.userWorkstation?.isAvailable,
                      'tw-bg-[#FFA500]': !member?.userWorkstation?.isAvailable,
                    }"
                  ></div>
                  <UserProfileLogo :userName="member?.email" /> -->
                <ProfileThumbnail
                  size="md"
                  :isOnline="member?.userWorkstation?.isAvailable"
                  :isStatusTypeChat="member?.userWorkstation?.isAvailable"
                  v-if="member?.userProfileImageUrl"
                  :src="member?.userProfileImageUrl"
                />
                <div
                  v-else
                  class="tw-rounded-full tw-max-h-[48px] tw-max-w-[48px] tw-min-h-[48px] tw-min-w-[48px] tw-h-[48px] tw-relative"
                >
                  <div
                    class="tw-h-[10px] tw-w-[10px] tw-rounded-full tw-border tw-border-solid tw-border-[#FFF] tw-absolute tw-top-0 tw-right-[.2rem] tw-z-10"
                    :class="{
                      'tw-bg-[#3BC740]': member?.userWorkstation?.isAvailable,
                      'tw-bg-[#FFA500]': !member?.userWorkstation?.isAvailable,
                    }"
                  ></div>
                  <UserProfileLogo :userName="member?.email" />
                </div>
              </div>
            </div>
          </template>
          <div
            class="counter tw-max-h-[48px] tw-max-w-[48px] tw-min-h-[48px] tw-min-w-[48px] tw-rounded-full tw-flex tw-items-center tw-justify-center"
            v-if="members.length > 7"
          >
            <Text variant="span" textColor="#264FD5">{{
              `${members.length - 7}+`
            }}</Text>
          </div>
        </div>
        <div v-else>
          <div class="tw-flex tw-justify-center">
            <CommonLoader :loading="true" />
          </div>
        </div>
      </main>
    </template>
  </GeneralWidgetLayoutSlot>
  <TeamMemberModal
    v-if="openedModel === modelName.TEAMMEMBERS"
    @onClickCloseTab="onClickCloseModel"
  />
</template>
<script setup>
import { computed, reactive, ref, onMounted } from "vue";
import { useStore } from "vuex";
import TeamMemberModal from "@/modules/trade-passport/components/TeamMemberModal.vue";
import GeneralWidgetLayoutSlot from "@/core/components/slots/GeneralWidgetLayoutSlot.vue";
import Text from "@/core/components/ui/general/Text.vue";
import ProfileThumbnail from "@/core/uni-components/ProfileThumbnail.vue";
import UserProfileLogo from "@/core/components/UserProfileLogo.vue";
import { USER_STORE } from "@/store/modules/user";
import { WORKSTATION } from "@/store/modules/workstation";
import { MEMBER_STORE } from "@/store/modules/manage-member";
import { getImageStringToImageURL } from "@/core/utils/common";
import CommonLoader from "@/core/components/CommonLoader.vue";

const store = useStore();

const props = defineProps({
  className: String,
  getUserWorkStationFieldAccess: {
    type: Boolean,
    default: true,
  },
});

const openedModel = ref("");
const modifiedMembers = ref([]);
const loadingImages = ref(false);

const modelName = {
  TEAMMEMBERS: "TeamMember",
};

const members = ref([]);

const userWorkStationImage = computed(
  () => store.getters[`${USER_STORE}/userWorkStationImage`]
);

const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);

const user = computed(() => store.getters[`${USER_STORE}/user`]);

const onClickManageTeam = () => {
  openedModel.value = modelName.TEAMMEMBERS;
};
const onClickCloseModel = async () => {
  openedModel.value = "";
  getTeamMember();
};
const getTeamMember = async () => {
  try {
    const response = await store.dispatch(`${MEMBER_STORE}/getTeamMember`, {
      userId: user.value?.id,
    });

    members.value = await Promise.all(
      response.map(async (member) => {
        if (member?.toUserId) {
          if (member?.toUser?.userPublicProfile) {
            member.userProfileImageUrl = await getImageStringToImageURL(
              member?.toUser?.userPublicProfile?.profileImage
            );
          } else {
            member.userProfileImageUrl = null;
          }
        } else {
          member.userProfileImageUrl = null;
        }
        return member;
      })
    );
  } catch (err) {
    console.log();
  }
};

const getWorkstationProfile = async (profileImage) => {
  if (!profileImage) {
    return null;
  }
  try {
    const imagePath = profileImage;
    const response = await store.dispatch(`${USER_STORE}/getImage`, imagePath);
    if (response) {
      return response;
    }
    return null;
  } catch (error) {
    return null;
  }
};

const modifyMembersList = async () => {
  loadingImages.value = true;
  if (!members.value && !members.value.length) {
    loadingImages.value = false;
    return;
  }
  try {
    const defaultMember = members.value[0];
    const { profileImage } = defaultMember.userWorkstation[0];

    const source = await getWorkstationProfile(profileImage);

    members.value.forEach((member, index) => {
      if (index === 0) {
        modifiedMembers.value.push({
          ...member,
          attachmentSource: source,
        });
      } else {
        modifiedMembers.value.push({
          ...member,
        });
      }
    });
  } catch (error) {
    loadingImages.value = false;
  } finally {
    loadingImages.value = false;
  }
};

onMounted(async () => {
  await getTeamMember();
  await modifyMembersList();
});
</script>
<style lang="scss" scoped>
.counter {
  box-shadow: 0px 0px 20px 0px #0c0f4a1a;
}
.ws-profile-logo {
  border-radius: 10px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
