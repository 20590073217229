import { API_URL } from "@/config";
import { CRYPTO_SECRET_KEY } from "../constants";
import CryptoJS from "crypto-js";
import $axios from "@/core/utils/axios-api-config";
import {
  AgreeTermsConsumerDisclosure,
  DurationsType,
  ContractorInvitePermissionEnum,
  EstimatePhaseStatus,
} from "../enums/estimateEnum";

export const cloneObject = (value: any) => {
  return JSON.parse(JSON.stringify(value));
};

export const setLocalStorageWithExpiry = (
  value: any,
  key: string,
  ttl: number
) => {
  const now = new Date();

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value,
    expiry: now.getTime() + ttl,
  };
  window.localStorage.setItem(key, JSON.stringify(item));
};

export const parseJSON = (data: any) => {
  try {
    if (typeof data === "string") {
      return JSON.parse(data);
    } else {
      return data;
    }
  } catch (error) {
    return null;
  }
};

export const getLocalStorageWithExpiry = (key: string) => {
  const itemStr = window.localStorage.getItem(key);
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }
  const item = parseJSON(itemStr);
  if (!item) {
    return null;
  }
  const now = new Date();
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    //   // If the item is expired, delete the item from storage
    // and return null
    window.localStorage.removeItem(key);
    return null;
  }
  return item.value;
};

export const removeLocalStorageByKey = (key: string) => {
  window.localStorage.removeItem(key);
};

export const deleteAllCookies = () => {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
};

export const getImageApiUrl = (profileImage: string, encoded = false) => {
  let encodedParam = "";
  const encryptedToken = CryptoJS.AES.encrypt("", CRYPTO_SECRET_KEY).toString();

  if (encoded) {
    encodedParam = "encoded=1&";
  }

  const url = `${API_URL}upload?${encodedParam}key=${profileImage}&token=${encodeURIComponent(
    encryptedToken
  )}`;
  return url;
};
const profileImagesData: any = [];
export const getImageStringToImageURL = async (profileImage: string) => {
  try {
    if (!profileImage) return null;

    if (profileImage in profileImagesData) {
      return profileImagesData[profileImage];
    }
    const imageUrl = getImageApiUrl(profileImage, true);
    const encoded: any = await $axios.get(imageUrl);
    profileImagesData[profileImage] = encoded.publicUrl;
    return encoded.publicUrl;
  } catch (error) {
    return null;
  }
};

export const isExpiredDate = (dateString: string) => {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  const inputDate = new Date(dateString);
  inputDate.setHours(0, 0, 0, 0);
  return inputDate < currentDate;
};

export const isAliased = (value: string): boolean => {
  return value.indexOf("iknowa.alias.") > -1;
};

export const getRandomColor = () => {
  const upperLimit = 200;
  const red = Math.floor(Math.random() * upperLimit);
  const green = Math.floor(Math.random() * upperLimit);
  const blue = Math.floor(Math.random() * upperLimit);
  const hexColor = `#${red.toString(16).padStart(2, "0")}${green
    .toString(16)
    .padStart(2, "0")}${blue.toString(16).padStart(2, "0")}`;
  return hexColor;
};

export const getTotalDuration = (
  startDate: string,
  endDate: string,
  durationType: number
) => {
  if (!endDate) {
    return null;
  }
  const startedDate: any = new Date(startDate);
  const endedDate: any = new Date(endDate);

  const timeDifference = endedDate - startedDate;

  let duration;
  if (durationType === DurationsType.DAY) {
    duration = timeDifference / (1000 * 60 * 60 * 24);
  } else {
    duration = timeDifference / (1000 * 60 * 60 * 24 * 7);
  }

  return duration;
};

export const filterSubContracts = (initialId: number, contracts: any) => {
  const result: any = [];

  function findContracts(currentId: number) {
    for (const contract of contracts) {
      if (contract.fromContractorId === currentId) {
        result.push(contract);
        findContracts(contract.toContractorId);
      }
    }
  }

  findContracts(initialId);

  return result;
};

export const getCurrentConTractorPhases = (initialId: any, phases: any) => {
  return phases.filter((el: any) => el.workStation?.id == initialId);
};

export const getSubContractorPhases = (
  initialId: any,
  contractors: any,
  phases: any
) => {
  const subContractors = [] as any;

  const getSubContractors = (currID: any) => {
    contractors.map((con: any) => {
      if (con.fromContractorId == currID) {
        subContractors.push(con);
        getSubContractors(con.toContractorId);
      }
    });
  };

  getSubContractors(initialId);

  const subContractorphases = [] as any;

  const getSubContractorPhases = (currID: any) => {
    const arr = subContractors.filter(
      (el: any) => el.fromContractorId == currID
    );

    arr.map((ele: any) => {
      if (
        ele.agreeTermsConsumerDisclosure == AgreeTermsConsumerDisclosure.PENDING
      ) {
        phases.map((el: any) => {
          if (el.workStation.id == ele.toContractorId) {
            subContractorphases.push(el);
          }
        });
        getSubContractorPhases(ele.toContractorId);
      }
    });
  };
  getSubContractorPhases(initialId);

  return subContractorphases.filter(
    (el: any) => el.phaseStatus !== EstimatePhaseStatus.REJECTED
  );
};

export const getParentContractorPhases = (
  initialId: any,
  contractors: any,
  phases: any
) => {
  try {
    const parentContractors = [] as any;

    const getParentContractors = (currID: any) => {
      contractors.map((con: any) => {
        if (con.toContractorId == currID) {
          parentContractors.push(con);
          getParentContractors(con.fromContractorId);
        }
      });
    };

    getParentContractors(initialId);

    const parentContractorPhases = {} as any;

    const getParentContractorPhases = (currID: any) => {
      const parent = parentContractors.find(
        (el: any) => el.toContractorId == currID
      );
      if (parent) {
        phases.map((pha: any) => {
          if (pha.workStation.id == parent.fromContractorId) {
            if (!parentContractorPhases[parent.fromContractorId]) {
              parentContractorPhases[parent.fromContractorId] = {
                tasks: [],
                permission: parent.permission_type,
              };
            }
            parentContractorPhases[parent.fromContractorId].tasks.push(pha);
          }
        });
        const getData = (newCurrID: any) => {
          const subsOfParent = contractors.filter(
            (ele: any) =>
              ele.fromContractorId == newCurrID && ele.toContractorId !== currID
          );
          subsOfParent.map((ele: any) => {
            if (
              ele.agreeTermsConsumerDisclosure ==
              AgreeTermsConsumerDisclosure.PENDING
            ) {
              phases.map((el: any) => {
                if (el.workStation.id == ele.toContractorId) {
                  if (!parentContractorPhases[parent.fromContractorId]) {
                    parentContractorPhases[parent.fromContractorId] = {
                      tasks: [],
                      permission: parent.permission_type,
                    };
                  }
                  parentContractorPhases[parent.fromContractorId].tasks.push(
                    el
                  );
                  getData(ele.toContractorId);
                }
              });
            }
          });
          getParentContractorPhases(newCurrID);
        };

        getData(parent.fromContractorId);
      }
    };

    getParentContractorPhases(initialId);

    let finalPhases = [] as any;

    Object.keys(parentContractorPhases).map((parentID: any) => {
      if (
        parentContractorPhases[parentID].permission ==
        ContractorInvitePermissionEnum.ALLPHASE
      ) {
        parentContractorPhases[parentID].tasks.map((ele: any) => {
          finalPhases.push(ele);
        });
      }

      if (
        parentContractorPhases[parentID].permission ==
        ContractorInvitePermissionEnum.BYMAINCONTRACTORONLY
      ) {
        const tempfinal = [
          ...finalPhases,
          ...parentContractorPhases[parentID].tasks,
        ];
        const finalData = tempfinal.filter(
          (ele: any) => ele.workStation.id == parentID
        );
        finalPhases = finalData;
      }
    });
    return finalPhases;
  } catch (error) {
    console.log("error", error);
  }
};

export const panelsPalette = ["E8EAF6", "1A237E"];
export function lerp(x: number, y: number, t: number): number {
  return x + t * (y - x);
}
export function colorToRGB(color: string): { r: number; g: number; b: number } {
  const hex = color.startsWith("#") ? color.slice(1) : color;
  return {
    r: parseInt(hex.substring(0, 2), 16),
    g: parseInt(hex.substring(2, 4), 16),
    b: parseInt(hex.substring(4, 6), 16),
  };
}
export function createPalette(
  hexColors: string[]
): { r: number; g: number; b: number }[] {
  // Map each hex color into an RGB value.
  const rgb = hexColors.map(colorToRGB);
  // Create a palette with 256 colors derived from our rgb colors.
  const size = 256;
  const step = (rgb.length - 1) / (size - 1);
  return Array(size)
    .fill(0)
    .map((_, i) => {
      // Get the lower and upper indices for each color.
      const index = i * step;
      const lower = Math.floor(index);
      const upper = Math.ceil(index);
      // Interpolate between the colors to get the shades.
      return {
        r: lerp(rgb[lower].r, rgb[upper].r, index - lower),
        g: lerp(rgb[lower].g, rgb[upper].g, index - lower),
        b: lerp(rgb[lower].b, rgb[upper].b, index - lower),
      };
    });
}
export function normalize(x: any, max = 1, min = 0) {
  const y = (x - min) / (max - min);
  return clamp(y, 0, 1);
}
export function clamp(x: number, min: number, max: number) {
  return Math.min(Math.max(x, min), max);
}
export function rgbToColor({
  r,
  g,
  b,
}: {
  r: number;
  g: number;
  b: number;
}): string {
  const f = (x: number) => {
    const hex = Math.round(x).toString(16);
    return hex.length == 1 ? `0${hex}` : hex;
  };
  return `#${f(r)}${f(g)}${f(b)}`;
}
export const getUserSubSkillList = (userSkill: any, getAllSpecialism: any) => {
  try {
    const userSkillClone = JSON.parse(JSON.stringify(userSkill));
    let skillSubCategoryList: any = [];
    userSkillClone.forEach((skill: any) => {
      const subCategoryList: any = getAllSpecialism.find(
        (specialism: any) => specialism.id === skill.category.id
      );
      const skillSubCategory = subCategoryList.subCategories.filter(
        (category: any) => parseJSON(skill.subCategoryIds).includes(category.id)
      );
      skillSubCategoryList = [
        ...skillSubCategory.map((category: any) => ({
          ...category,
          iconName: skill.category.iconName,
        })),
        ...skillSubCategoryList,
      ];
    });
    return skillSubCategoryList;
  } catch (error) {
    return [];
  }
};
