<template>
  <commonDialog
    v-if="isShowPropertyOwnerOnboarding"
    :HeaderTittle="currentSelectedTab?.header"
    :title="currentSelectedTab?.title"
    :description="currentSelectedTab?.description"
    :onClickBackButton="onClickBackButton"
    :className="['onboarding__dialog']"
    height="100%"
  >
    <template v-slot:body>
      <div v-if="currentSelectedTab?.id == 2" class="modal__inner__body">
        <v-form @submit.prevent :rules="validation" ref="formRef">
          <div class="contact__wrapper">
            <div class="contact_form">
              <label class="">Phone Number</label>
              <div class="contact_input">
                <v-autocomplete
                  v-model="countryCode"
                  :items="countryinfo"
                  item-title="name"
                  item-value="dial_code"
                  @update:modelValue="handleCountryCode"
                  class="flag-selector input_field"
                  rounded
                  outlined="false"
                >
                  <template v-slot:chip="{ props, item }">
                    <flag
                      v-bind="props"
                      style="font-size: 16px; width: 100%"
                      :iso="item.raw.code"
                    />
                  </template>
                  <template v-slot:item="{ props, item }">
                    <div
                      class="tw-min-w-[300px] rmd:tw-min-w-[360px] !tw-w-full"
                    >
                      <v-list-item
                        v-bind="props"
                        :title="item.raw.name"
                        class="country__selector tw-w-full"
                      >
                        <flag
                          v-bind="props"
                          style="font-size: 16px"
                          :iso="item.raw.code"
                      /></v-list-item>
                    </div>
                  </template>
                </v-autocomplete>
                <v-text-field
                  type="text"
                  density="compact"
                  placeholder="Phone Number"
                  v-model="userPhone"
                  :rules="validation.phone"
                  rounded
                  class="c-input"
                ></v-text-field>
              </div>
            </div>
            <v-btn
              class="button button-orange w-full"
              :disabled="!isPhoneValid"
              :loading="isPhoneNumberSend"
              @click.enter="onClickCompleteOnboarding"
            >
              complete onboarding
            </v-btn>
          </div>
        </v-form>
      </div>
      <div v-else class="modal__carousel__content">
        <div class="onboarding-steps-content__upload">
          <div class="uploade-wrapper">
            <v-file-input
              class="upload_file"
              label="Upload"
              append-inner-icon="mdi-image-plus"
              accept="image/png, image/jpeg"
              variant="solo-filled"
              :class="{ 'added-img': imageUrl }"
              @change="onFileChange"
              @click:clear="clearImageUrl"
            >
            </v-file-input>
            <v-img
              class="uploaded-img"
              :width="200"
              aspect-ratio="16/9"
              cover
              :src="imageUrl"
            ></v-img>
            <Text
              variant="span"
              textAlign="center"
              textColor="rgba(12, 15, 74, 0.5)"
              >Accepted file formats: PNG, JPEG</Text
            >
          </div>
          <div class="next_step_btns">
            <v-btn
              class="button button-orange w-full traking"
              :disabled="isContinueDisabled"
              :loading="isImageUpload"
              @click.enter="onClickSaveImage"
            >
              save
            </v-btn>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <v-stepper class="onboard-stepper" v-model="currentSelectedTab.id">
        <v-stepper-header class="onboard-stepper__header">
          <v-stepper-item
            class="onboard-stepper__item"
            v-for="step in modelDetails"
            :key="step?.id"
            :class="{ done: currentSelectedTab.id > step?.id }"
            :title="step?.tab"
            :value="step?.id"
          ></v-stepper-item>
        </v-stepper-header>
      </v-stepper>
    </template>
  </commonDialog>
  <WelcomePropertyOwnerPopup
    v-if="isShowWelcomePropertyOwnerModal"
    @on-close="onClickCloseWelcomeModal"
    @on-click-toggle-trades-workstation="onClickToggleTradesWorkStation"
  />
  <PropertyOwnerWorkStationModal
    v-if="isShowPropertyOwnerWorkStationModal"
    @onClickCloseTab="onClickClosePOWorkstationModal"
  />
</template>

<script lang="ts">
import { ref, computed, onBeforeMount } from "vue";
import CommonDialog from "@/core/components/CommonDialog.vue";
import { useRouter } from "vue-router";
import { countryinfo } from "../../../core/utils/countryinfo";
import { useStore } from "vuex";
import { USER_STORE } from "@/store/modules/user";
import { VUE_APP_OS_API, VUE_APP_OS_POSTCODE_URL } from "@/config";
import { UserRolesEnum } from "@/core/enums/RolesEnum";
import WelcomePropertyOwnerPopup from "@/modules/owner-onboarding/components/WelcomePropertyOwnerPopup.vue";
import Text from "@/core/components/ui/general/Text.vue";
import PropertyOwnerWorkStationModal from "@/modules/workstation/propertyowner/pages/PropertyOwnerWorkStationModal.vue";
import { WORKSTATION } from "@/store/modules/workstation";

export default {
  components: {
    CommonDialog,
    Text,
    WelcomePropertyOwnerPopup,
    PropertyOwnerWorkStationModal,
  },
  setup(props: any, ctx: any) {
    const router = useRouter();
    const store = useStore();
    const model = ref(0);
    const formRef = ref(null) as any;
    const isShowAddressData = ref(false);
    const isShowPhoneData = ref(false);
    const countryCode = ref(null) as any;
    const userPhone = ref("");
    const imageUrl = ref("");
    let imageData = ref("");
    const isImageUpload = ref(false);
    const isPhoneNumberSend = ref(false);
    const isActiveNextButton = ref(false);
    const isShowWelcomePropertyOwnerModal = ref(false);
    const isShowPropertyOwnerOnboarding = ref(true);
    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const validation = ref({
      phone: [
        (v: string) => !!v || "Phone number is required",
        (v: string) => /^\+\d{1,3}\s?/.test(v) || "Please select country code.",
        (v: string) =>
          /^\+\d{1,3}\s?\d{10,14}$/.test(v) || "Invalid phone number.",
      ],
    });

    const isPhoneValid = computed(() => {
      return validation.value.phone.every(
        (rule) => rule(userPhone.value) === true
      );
    });
    const modelDetails = ref([
      {
        id: 1,
        tab: "Profile Picture",
        name: "Profile Picture",
        header: "Profile Image",
        title: "Upload your Profile Image",
        description: `Please select or capture an image to be uploaded`,
      },
      {
        id: 2,
        tab: "Phone Number",
        name: "Phone Number",
        header: "Phone Number",
        title: "Add Your Phone Number",
        description: `Be the first to receive special offers & benefits from iknowa`,
      },
    ]);
    const currentSelectedTab = ref(modelDetails.value[0]);
    const isShowPropertyOwnerWorkStationModal = ref(false);

    const isShowCreatePropertyOwnerWorkStationModal = computed(
      () =>
        store.getters[`${WORKSTATION}/getPropertyOwnerWorkStationModelState`]
    );

    onBeforeMount(() => {
      countryCode.value = countryinfo.find(
        (country: any) => country.code === "GB"
      );
      userPhone.value = countryCode.value.dial_code + " " + userPhone.value;
    });
    const onClickBackButton = () => {
      const currentTabIndex = modelDetails.value.findIndex(
        (tab) => tab.name === currentSelectedTab.value.name
      );
      if (currentTabIndex === 0) {
        router.push("/onboarding");
      } else {
        currentSelectedTab.value = modelDetails.value[currentTabIndex - 1];
      }
    };

    const onClickContinue = async () => {
      currentSelectedTab.value.header = "Phone Number";
      currentSelectedTab.value.title = "Submit Your Phone Number";
      currentSelectedTab.value.description =
        "Never miss a job that matches your requirements.";
      isShowPhoneData.value = true;
    };

    const handleCountryCode = () => {
      if (userPhone.value) {
        userPhone.value = "";
      }
      userPhone.value = countryCode.value + " " + userPhone.value;
    };

    const onClickCompleteOnboarding = async () => {
      try {
        isPhoneNumberSend.value = true;
        const isFormValid = await formRef.value.validate();
        if (!isFormValid.valid) return;
        await store.dispatch(`${USER_STORE}/verifyPhoneNumber`, {
          contactNo: userPhone.value,
        });
        await store.dispatch(`${USER_STORE}/updateUser`, {
          userId: user.value.id,
          userData: { hasAccountSetup: true },
        });
        // router.push("/dashboard");
        isShowWelcomePropertyOwnerModal.value = true;
        isShowPropertyOwnerOnboarding.value = false;
      } catch (error) {
        console.log("error:", error);
      } finally {
        isPhoneNumberSend.value = false;
      }
    };

    const onClickToggleTradesWorkStation = () => {
      isShowWelcomePropertyOwnerModal.value = false;
      isShowPropertyOwnerWorkStationModal.value = true;
      store.commit(
        `${WORKSTATION}/setPropertyOwnerWorkStationModelState`,
        !isShowCreatePropertyOwnerWorkStationModal.value
      );
    };

    const onClickClosePOWorkstationModal = () => {
      isShowPropertyOwnerWorkStationModal.value = false;
      isShowWelcomePropertyOwnerModal.value = true;
    };

    const onClickCloseWelcomeModal = () => {
      isShowWelcomePropertyOwnerModal.value = false;
      router.push("/dashboard");
    };

    const onFileChange = async (event: any) => {
      const fileInput = event.target;
      if (fileInput.files && fileInput.files.length > 0) {
        const file = fileInput.files[0];

        imageUrl.value = URL.createObjectURL(file);
        imageData.value = file;
      } else {
        console.error("Error: No file selected.");
      }
    };
    const clearImageUrl = () => {
      imageUrl.value = "";
    };

    const isContinueDisabled = computed(() => {
      return !imageUrl.value || isImageUpload.value;
    });

    const isContinueOnboardingDisabled = computed(() => {
      return !userPhone.value;
    });

    const onClickSaveImage = async () => {
      if (!imageData.value) return;
      try {
        isImageUpload.value = true;
        const formData = new FormData();
        formData.append("file", imageData.value);
        const response = await store.dispatch(
          `${USER_STORE}/uploadProfileImage`,
          formData
        );

        await store.dispatch(`${USER_STORE}/saveUserProfile`, {
          userId: user.value.id,
          profile: {
            profileImage: response?.attachment,
          },
        });
        await store.dispatch(
          `${USER_STORE}/saveUserImage`,
          response?.publicUrl
        );
        await store.dispatch(
          `${USER_STORE}/saveUserRole`,
          UserRolesEnum.PROPERTY_OWNER
        );
        currentSelectedTab.value = modelDetails.value[1];
      } catch (error) {
        console.log("error:", error);
      } finally {
        isImageUpload.value = false;
      }
    };

    return {
      modelDetails,
      onClickBackButton,
      currentSelectedTab,
      model,
      isShowAddressData,
      onClickContinue,
      isShowPhoneData,
      handleCountryCode,
      countryCode,
      userPhone,
      countryinfo,
      onClickCompleteOnboarding,
      imageUrl,
      onFileChange,
      clearImageUrl,
      onClickSaveImage,
      isContinueDisabled,
      isImageUpload,
      isContinueOnboardingDisabled,
      isPhoneNumberSend,
      isActiveNextButton,
      UserRolesEnum,
      user,
      formRef,
      validation,
      isShowWelcomePropertyOwnerModal,
      isShowPropertyOwnerOnboarding,
      onClickCloseWelcomeModal,
      isPhoneValid,
      isShowPropertyOwnerWorkStationModal,
      onClickToggleTradesWorkStation,
      onClickClosePOWorkstationModal,
    };
  },
};
</script>

<style lang="scss" scoped>
.description {
  display: block;
}
@import "@/modules/owner-onboarding/styles/OwnerOnboarding.scss";
:deep(.country__selector) {
  .v-list-item__content {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: flex-end;
    gap: 20px;
  }
}
</style>
