<template>
  <div class="emergency_hours_wrapper" v-if="getAllEvent.length">
    <v-btn
      class="button button-orange"
      prepend-icon="mdi-plus-circle"
      @click="onClickAddNew"
      >ADD NEW</v-btn
    >

    <!-- Desktop View -->
    <v-table class="emergency_hours_table">
      <thead>
        <tr>
          <th><p>Issue name</p></th>
          <th><p>Property Name</p></th>
          <th><p>Location</p></th>
          <th>
            <div class="more_info">
              <p>Date and time</p>
              <v-icon icon="mdi-information-variant-circle-outline"></v-icon>
            </div>
          </th>
          <th>
            <div class="more_info">
              <p>Trade Specialist</p>
              <v-icon icon="mdi-information-variant-circle-outline"></v-icon>
            </div>
          </th>
          <th>
            <div class="more_info">
              <p>Preferred Contractors</p>
              <v-icon icon="mdi-information-variant-circle-outline"></v-icon>
            </div>
          </th>
          <th>
            <div class="more_info">
              <p>Intervention Time</p>
              <v-icon icon="mdi-information-variant-circle-outline"></v-icon>
            </div>
          </th>
          <th><p>Earmark Budget</p></th>
          <th><p>Action</p></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="event in getAllEvent" :key="event.id">
          <td>
            <p>{{ event.title }}</p>
          </td>
          <td>
            <p>{{ event.EOHProperties[0]?.property?.address }}</p>
            <span v-if="event.EOHProperties.length - 1 > 0"
              >+{{ event.EOHProperties.length - 1 }}</span
            >
          </td>
          <td>
            <p>{{ event.EOHProperties[0]?.property?.postcode }}</p>
          </td>
          <td>
            <p v-if="getWeekName(event?.days)">
              {{ getWeekName(event?.days) }}
            </p>
            <p>{{ event?.fromTime }} to {{ event?.toTime }}</p>
          </td>
          <td>
            <div class="trade_specialist" v-if="event.EOHSpecialism?.length">
              <p>{{ event.EOHSpecialism[0]?.subCategories?.label }}</p>
              <span v-if="event?.EOHSpecialism.length - 1 > 0"
                >{{ event?.EOHSpecialism.length - 1 }}+</span
              >
            </div>
          </td>
          <td>
            <div class="preferred_contractor">
              <img
                src="../../../assets/images/user-placeholder.jpg"
                alt=""
                v-if="event.EOHPreferredContractor.length - 1 >= 0"
              />
              <p>
                {{ event.EOHPreferredContractor[0]?.user?.firstName }}
                {{ event.EOHPreferredContractor[0]?.user?.lastName }}
              </p>
              <span v-if="event.EOHPreferredContractor.length - 1 > 0"
                >{{ event.EOHPreferredContractor.length - 1 }}+</span
              >
            </div>
          </td>
          <td>
            <div class="intervention_time">
              <img src="../../../assets/icons/clock-icon.svg" alt="" />
              <p>{{ event?.SLATime }} Min Left</p>
            </div>
          </td>
          <td>
            <p>£ {{ event?.earnmarkBudget }}</p>
          </td>
          <td>
            <div class="status_btn">
              <v-btn
                class="edit_button"
                icon="mdi-pencil"
                @click="onClickEditButton(event.id)"
              >
              </v-btn>
              <v-btn
                class="delete_button"
                icon="mdi-delete"
                @click="onClickDeleteButton(event.id)"
              >
              </v-btn>
            </div>
          </td>
        </tr>
      </tbody>
    </v-table>

    <!-- Mobile View -->
    <v-card
      class="emergency_hours_card"
      v-for="event in getAllEvent"
      :key="event.id"
    >
      <div class="header">
        <h5>Energy Efficiency Rating</h5>
        <v-btn
          class="edit_button"
          icon="mdi-pencil"
          @click="onClickEditButton(event.id)"
        >
        </v-btn>
      </div>
      <div class="content">
        <div class="content_list">
          <div class="title">
            <h5>Property Name</h5>
          </div>
          <div class="description">
            <p>{{ event.EOHProperties[0]?.property?.address }}</p>
          </div>
        </div>
        <div class="content_list">
          <div class="title">
            <h5>Location</h5>
          </div>
          <div class="description">
            <p>{{ event.EOHProperties[0]?.property?.postcode }}</p>
          </div>
        </div>
        <div class="content_list">
          <div class="title">
            <div class="more_info">
              <h5>Date and time</h5>
              <v-icon icon="mdi-information-variant-circle-outline"></v-icon>
            </div>
          </div>
          <div class="description">
            <p v-if="getWeekName(event?.days)">
              {{ getWeekName(event?.days) }}
            </p>
            <p>{{ event?.fromTime }} to {{ event?.toTime }}</p>
          </div>
        </div>
        <div class="content_list">
          <div class="title">
            <div class="more_info">
              <h5>Trade Specialist</h5>
              <v-icon icon="mdi-information-variant-circle-outline"></v-icon>
            </div>
          </div>
          <div class="description">
            <p>{{ event.EOHSpecialism[0]?.subCategories?.label }}</p>
            <span v-if="event.EOHSpecialism.length - 1 > 0"
              >{{ event.EOHSpecialism.length - 1 }}+</span
            >
          </div>
        </div>
        <div class="content_list">
          <div class="title">
            <div class="more_info">
              <h5>Preferred Contractors</h5>
              <v-icon icon="mdi-information-variant-circle-outline"></v-icon>
            </div>
          </div>
          <div class="description">
            <div class="preferred_contractor">
              <img
                src="../../../assets/images/user-placeholder.jpg"
                alt=""
                v-if="event.EOHPreferredContractor.length - 1 >= 0"
              />
              <p>
                {{ event.EOHPreferredContractor[0]?.user?.firstName }}
                {{ event.EOHPreferredContractor[0]?.user?.lastName }}
              </p>
              <span v-if="event.EOHPreferredContractor.length - 1 > 0"
                >{{ event.EOHPreferredContractor.length - 1 }}+</span
              >
            </div>
          </div>
        </div>
        <div class="content_list">
          <div class="title">
            <div class="more_info">
              <h5>Intervention Time</h5>
              <v-icon icon="mdi-information-variant-circle-outline"></v-icon>
            </div>
          </div>
          <div class="description intervention_time">
            <img src="../../../assets/icons/clock-icon.svg" alt="" />
            <p>5 Min Left</p>
          </div>
        </div>
        <div class="content_list">
          <div class="title">
            <div class="more_info">
              <h5>Earmark Budget</h5>
              <v-icon icon="mdi-information-variant-circle-outline"></v-icon>
            </div>
          </div>
          <div class="description">
            <p>£ {{ event?.earnmarkBudget }}</p>
          </div>
        </div>
      </div>
    </v-card>

    <!-- :ref="create" -->
  </div>
  <div v-else class="empty__content">
    <div class="empty__banner">
      <div class="empty__banner__img">
        <img src="../../../assets/images/eoh-banner.png" alt="" />
      </div>
      <div class="empty__banner__content">
        <h4>You don’t have any out of hours projects yet.</h4>
        <p>Raise your next out of hours issue at lightning speed.</p>
        <ul class="empty__banner__content__list">
          <li>
            <div class="list__item">
              <v-icon icon="mdi-check-circle" />
              <p><strong> Dispute protection</strong> as standard</p>
            </div>
          </li>
          <li>
            <div class="list__item">
              <v-icon icon="mdi-check-circle" />
              <p>Deposits secured <strong>in Escrow</strong></p>
            </div>
          </li>
          <li>
            <div class="list__item">
              <v-icon icon="mdi-check-circle" />
              <p>
                Verified trade contractors <strong> in your local area</strong>
              </p>
            </div>
          </li>
        </ul>
      </div>
      <div class="empty__banner__footer">
        <v-btn class="button button-purple" width="100%" @click="onClickAddNew"
          >Raise a new Project</v-btn
        >
      </div>
    </div>
  </div>

  <!-- <InfoBanner
    v-else
    class="!tw-h-auto"
    buttonVariant="accent"
    :title="bannerInfo.title"
    :description="bannerInfo.description"
    :buttonLabel="bannerInfo.actionLabel"
    :hideClose="true"
    :isAttachmentThumbnail="true"
    @on-button-click="onClickAddNew"
  >
    <template #thumbnail>
      <img
        src="@/assets/images/properties-banner.png"
        class="!tw-w-[240px] !tw-h-[120px] rsm:!tw-w-[280px] rsm:!tw-h-[156px]"
      />
    </template>
  </InfoBanner> -->
  <CreateEOHModal
    :isShowHeader="false"
    :selectedEventId="selectedEventId"
    v-if="isShowCreateEOH"
    @onClickCancel="onClickCancel"
    @onClickPostButton="onClickPostButton"
  ></CreateEOHModal>
  <ServiceAgreementModalForEvent
    :createdEvent="createdEvent"
    :selectedEventId="selectedEventId"
    v-if="isShowServiceAgreement"
    @on-close="onCloseServiceAgreementModal"
  ></ServiceAgreementModalForEvent>
  <DeleteEventModal
    :selectedEventId="selectedEventId"
    @cancelDeleteModal="cancelDeleteModal"
    v-if="isShowDeleteModal"
  ></DeleteEventModal>
  <PageRestrictionNoticeModal
    v-if="isShowWorkstationRestrictionModal"
    @on-close="toggleRestrictionModal"
  />
</template>
<script lang="ts">
import CreateEOHModal from "@/modules/emergency-out-of-hours/components/CreateEOHModal.vue";
import DeleteEventModal from "@/modules/emergency-out-of-hours/components/DeleteEventModal.vue";
import ServiceAgreementModalForEvent from "@/modules/emergency-out-of-hours/components/ServiceAgreementModalForEvent.vue";
import EOHService from "@/core/services/emergency-out-of-hours.service";
import InfoBanner from "@/core/components/common/InfoBanner.vue";
import { computed, onBeforeMount } from "vue";
import { ref } from "vue";
import { PROPERTY_STORE } from "@/store/modules/property";
import { useStore } from "vuex";
import { WORKSTATION } from "@/store/modules/workstation";
import PageRestrictionNoticeModal from "@/core/components/modals/PageRestrictionNoticeModal.vue";
export default {
  components: {
    CreateEOHModal,
    DeleteEventModal,
    ServiceAgreementModalForEvent,
    PageRestrictionNoticeModal,
  },
  setup(props: any, ctx: any) {
    const store = useStore();

    const isShowCreateEOH = ref(false);
    const isShowDeleteModal = ref(false);
    const isShowServiceAgreement = ref(false);

    const selectedEventId = ref(null) as any;
    const isShowWorkstationRestrictionModal = ref(false);

    const createdEvent = ref(null);

    const days = [
      { name: "Sun", value: 1 },
      { name: "Mon", value: 2 },
      { name: "Tue", value: 3 },
      { name: "Wed", value: 4 },
      { name: "Thu", value: 5 },
      { name: "Fri", value: 6 },
      { name: "Sat", value: 7 },
    ];

    const bannerInfo = ref({
      title: "You currently don’t have any Out of Hours Project",
      description: "",
      actionLabel: "Add New",
    });

    const getAllEvent = ref([]) as any;

    const onClickAddNew = () => {
      if (isPersonalAccountWorkStation.value) {
        toggleRestrictionModal();
      } else {
        selectedEventId.value = null;
        isShowCreateEOH.value = true;
      }
    };

    const onClickCancel = () => {
      isShowCreateEOH.value = false;
    };

    const onClickEditButton = async (eventId: number) => {
      if (isPersonalAccountWorkStation.value) {
        toggleRestrictionModal();
      } else {
        selectedEventId.value = eventId;
        isShowCreateEOH.value = true;
      }
    };

    const onClickDeleteButton = (eventId: number) => {
      if (isPersonalAccountWorkStation.value) {
        toggleRestrictionModal();
      } else {
        selectedEventId.value = eventId;
        isShowDeleteModal.value = true;
      }
    };

    const cancelDeleteModal = async () => {
      isShowDeleteModal.value = false;
      getAllEvent.value = await EOHService.getEOHEvents();
    };

    const onClickPostButton = (event: any) => {
      createdEvent.value = event;
      isShowServiceAgreement.value = true;
      isShowCreateEOH.value = false;
    };

    const getWeekName = (weekArray: any) => {
      let weekName = "" as any;
      if (!weekArray) {
        return weekName;
      }
      if (weekArray.length === 1) {
        weekName = days.find((day) => {
          return day.value === weekArray[0];
        });
        return weekName.name;
      }
      if (weekArray.length > 1) {
        const firstDay = days.find((day) => {
          return day.value === weekArray[0];
        }) as any;

        const lastDay = days.find((day) => {
          return day.value === weekArray[weekArray.length - 1];
        }) as any;

        return `${firstDay?.name} to ${lastDay?.name}`;
      }
    };

    const onCloseServiceAgreementModal = async () => {
      isShowServiceAgreement.value = false;
      getAllEvent.value = await EOHService.getEOHEvents();
    };

    const isPersonalAccountWorkStation = computed(
      () =>
        store.getters[`${WORKSTATION}/activeUserWorkstation`]?.name ===
        "Default"
    );

    const toggleRestrictionModal = () => {
      isShowWorkstationRestrictionModal.value =
        !isShowWorkstationRestrictionModal.value;
    };

    onBeforeMount(async () => {
      if (isPersonalAccountWorkStation.value) {
        getAllEvent.value = [
          {
            id: 1,
            title: "Gas Pipe Leak",
            fromTime: "10:00 AM",
            toTime: "12:00 AM",
            days: [2],
            EOHProperties: [
              {
                id: 1,
                dateCreated: "2024-08-01T12:08:23.171Z",
                dateUpdated: "2024-08-01T12:08:23.171Z",
                property: {
                  id: 84,
                  dateCreated: "2024-08-01T06:01:45.089Z",
                  dateUpdated: "2024-08-01T06:01:45.089Z",
                  address: "FLAT A, 91, BRIXTON HILL, LONDON, SW2 1AA",
                  refId: "PLBvSgf3_Gj8XohaxwHgq",
                  countryCode: null,
                  nickname: null,
                  postcode: "SW2 1AA",
                  uprn: "200000455797",
                  beds: null,
                  baths: null,
                  reception: null,
                  propertyType: "Leasehold",
                  energyRating: null,
                  propertyCategory: "Residential",
                  propertySize: null,
                  tenure: null,
                  yearsOfLease: null,
                  addressData:
                    '{"uprn":200000455797,"address":"FLAT A, 91, BRIXTON HILL, LONDON, SW2 1AA","postcode":"SW2 1AA","x_coordinate":530772,"y_coordinate":174552,"lng":-0.1192269,"lat":51.4548542,"tenure":"Leasehold"}',
                  maxMemberLimit: 0,
                  epcData: null,
                  subscription: null,
                  propertyRole: "Owner",
                  companyName: null,
                  epcDisplay: null,
                  epcDomestic: {},
                  epcNonDomestic: null,
                  epcUpdatedDate: null,
                  valuation: [],
                },
              },
            ],
            EOHSpecialism: [
              {
                id: 35,
                dateCreated: "2024-08-01T12:08:23.182Z",
                dateUpdated: "2024-08-01T12:08:23.182Z",
                subCategories: {
                  id: 619,
                  dateCreated: "2021-03-11T08:39:46.513Z",
                  dateUpdated: "2021-04-20T12:49:49.654Z",
                  label: "\nBath Resurfacing",
                  sortOrder: "1",
                  isCustom: false,
                  isApprovedByAdmin: false,
                  category: {
                    id: 6,
                    dateCreated: "2021-03-11T08:39:46.452Z",
                    dateUpdated: "2022-12-05T15:34:21.000Z",
                    poLabel: "Bathrooms",
                    tpLabel: "Bathroom Specialist",
                    iconName: "bathrooms",
                    isCustom: false,
                    isApprovedByAdmin: false,
                  },
                },
              },
              {
                id: 24,
                dateCreated: "2024-08-01T12:08:23.169Z",
                dateUpdated: "2024-08-01T12:08:23.169Z",
                subCategories: {
                  id: 877,
                  dateCreated: "2021-03-11T08:39:46.513Z",
                  dateUpdated: "2021-04-20T12:49:49.654Z",
                  label: "\nSystem Maintenance",
                  sortOrder: "1",
                  isCustom: false,
                  isApprovedByAdmin: false,
                  category: {
                    id: 32,
                    dateCreated: "2021-03-11T08:39:46.452Z",
                    dateUpdated: "2022-12-05T15:34:21.000Z",
                    poLabel: "Air Conditioning",
                    tpLabel: "Air Conditioning Specialist",
                    iconName: "air-conditioning",
                    isCustom: false,
                    isApprovedByAdmin: false,
                  },
                },
              },
            ],
            EOHPreferredContractor: [
              {
                user: {
                  firstName: "Jenny",
                  lastName: "Wilson",
                },
              },
            ],
            SLATime: 740,
            earnmarkBudget: "2626",
          },
        ];
      } else {
        getAllEvent.value = await EOHService.getEOHEvents();
      }
      await store.dispatch(`${PROPERTY_STORE}/fetchProperties`, {});
    });
    return {
      isShowCreateEOH,
      onClickAddNew,
      onClickCancel,
      onClickEditButton,
      onClickDeleteButton,
      cancelDeleteModal,
      isShowDeleteModal,
      getAllEvent,
      isShowServiceAgreement,
      onClickPostButton,
      getWeekName,
      selectedEventId,
      createdEvent,
      onCloseServiceAgreementModal,
      bannerInfo,
      isShowWorkstationRestrictionModal,
      toggleRestrictionModal,
    };
  },
};
</script>

<style lang="scss" scoped>
.emergency_hours_wrapper {
  .button {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
  .emergency_hours_table {
    margin-top: 30px;
    border-radius: 8px;
    @include respond(md) {
      display: none;
    }
    .v-table__wrapper {
      table {
        thead {
          tr {
            th {
              height: auto;
              padding: 18px 16px;
              p {
                @include fluidFont(10, 10, 2);
                font-weight: 500;
                color: rgba($blueDark, 0.5);
              }
              .more_info {
                display: flex;
                justify-content: space-between;
                align-items: center;
                i {
                  @include fluidFont(16, 16, 2);
                  color: rgba($blueDark, 0.5);
                }
              }
            }
          }
        }
        tbody {
          text-align: left;
          tr {
            td {
              height: auto;
              padding: 15px 16px;
              border-bottom: 1px solid rgba($blueDark, 0.5);
              p {
                @include fluidFont(12, 12, 2);
                font-weight: 500;
                color: rgba($blueDark, 1);
              }
              span {
                width: 20px;
                height: 20px;
                background-color: #dde2e7;
                border-radius: 100%;
                font-size: 10px;
                font-weight: 500;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .trade_specialist {
                display: flex;
                align-items: center;
                gap: 4px;
              }
              .preferred_contractor {
                display: flex;
                align-items: center;
                gap: 4px;
                img {
                  width: 20px;
                  height: 20px;
                  border-radius: 100%;
                }
              }
              .intervention_time {
                display: flex;
                align-items: center;
                gap: 4px;
                padding: 2px 8px;
                background-color: rgba($orange, 0.1);
                border-radius: 6px;
                img {
                  width: 12px;
                  height: 12px;
                  border-radius: 100%;
                  filter: invert(69%) sepia(26%) saturate(3885%)
                    hue-rotate(359deg) brightness(101%) contrast(102%);
                }
                p {
                  @include fluidFont(10, 10, 2);
                  font-weight: 700;
                  color: rgba($orange, 1);
                }
              }
              // .status_btn {
              //   display: flex;
              //   gap: 10px;
              //   align-items: center;
              //   .v-btn {
              //     width: 20px;
              //     display: flex;
              //     align-items: center;
              //     height: 20px;
              //     min-width: 20px;
              //     border-radius: 100%;
              //     opacity: 1;
              //     color: #fff;
              //     justify-content: center;
              //     padding: 0;
              //     :deep(.v-btn__content) {
              //       margin: 0;
              //       i {
              //         @include fluidFont(9, 9, 1);
              //         color: rgba($white, 1);
              //       }
              //     }
              //   }
              //   .edit_button {
              //     background-color: rgba($info, 1);
              //   }
              //   .delete_button {
              //     background-color: rgba(255, 54, 54, 1);
              //   }
              // }
            }
            &:last-child {
              td {
                border-bottom: none;
              }
            }
          }
        }
      }
    }
  }
  .emergency_hours_card {
    padding: 16px;
    box-shadow: none;
    border-radius: 8px;
    margin-top: 30px;
    display: none;
    @include respond(md) {
      display: block;
    }
    .header {
      margin: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 0 10px;
      border-bottom: 1px dashed rgba($blueDark, 1);
      h5 {
        @include fluidFont(12, 12, 1.4);
        font-weight: 600;
        color: rgba($blueDark, 1);
      }
      .edit_button {
        background-color: rgba($info, 1);
        width: 20px;
        display: flex;
        align-items: center;
        height: 20px;
        min-width: 20px;
        border-radius: 100%;
        opacity: 1;
        justify-content: center;
        padding: 0;
        box-shadow: none;
        :deep(.v-btn__content) {
          margin: 0;
          i {
            @include fluidFont(9, 9, 1);
            color: rgba($white, 1);
          }
        }
      }
    }
    .content {
      margin-top: 12px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      .content_list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title {
          .more_info {
            display: flex;
            gap: 8px;
            align-items: flex-start;
            i {
              @include fluidFont(16, 16, 1);
              color: rgba($blueDark, 0.5);
            }
          }
          h5 {
            @include fluidFont(10, 10, 2);
            font-weight: 500;
            color: rgba($blueDark, 0.5);
          }
        }
        .description {
          text-align: right;
          display: flex;
          align-items: center;
          gap: 8px;
          p {
            @include fluidFont(12, 12, 2);
            font-weight: 500;
            color: rgba($blueDark, 1);
          }
          span {
            width: 20px;
            height: 20px;
            background-color: #dde2e7;
            border-radius: 100%;
            font-size: 10px;
            font-weight: 500;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .intervention_time {
          display: flex;
          align-items: center;
          gap: 4px;
          padding: 2px 8px;
          background-color: rgba($orange, 0.1);
          border-radius: 6px;
          img {
            width: 12px;
            height: 12px;
            border-radius: 100%;
            filter: invert(69%) sepia(26%) saturate(3885%) hue-rotate(359deg)
              brightness(101%) contrast(102%);
          }
          p {
            @include fluidFont(10, 10, 2);
            font-weight: 700;
            color: rgba($orange, 1);
          }
        }
        .preferred_contractor {
          display: flex;
          align-items: center;
          gap: 4px;
          img {
            width: 20px;
            height: 20px;
            border-radius: 100%;
          }
        }
      }
    }
  }
}

.status_btn {
  display: flex;
  gap: 10px;
  align-items: center;
  .v-btn {
    width: 20px;
    display: flex;
    align-items: center;
    height: 20px;
    min-width: 20px;
    border-radius: 100%;
    opacity: 1;
    color: #fff;
    justify-content: center;
    padding: 0;
    :deep(.v-btn__content) {
      margin: 0;
      i {
        @include fluidFont(9, 9, 1);
        color: rgba($white, 1);
      }
    }
  }
  .edit_button {
    background-color: rgba($info, 1);
  }
  .delete_button {
    background-color: rgba(255, 54, 54, 1);
  }
}

/*Empty Screen */
.empty__content {
  height: 80dvh;
  display: flex;
  align-items: center;
  justify-content: center;
  @include respond(s720) {
    height: 95dvh;
  }
  .empty__banner {
    width: 480px;
    max-width: 100%;
    margin: 0 auto;
    @include respond(s720) {
      width: 300px;
    }
    .empty__banner__img {
      img {
        @include respond(s720) {
          width: 100%;
          max-width: 100%;
        }
      }
    }
    .empty__banner__content {
      margin-top: 46px;
      h4 {
        @include fluidFont(28, 28, 1.3);
        font-weight: 500;
        color: rgba($blueDark, 1);
      }
      p {
        margin-top: 16px;
        @include fluidFont(14, 14, 1.3);
        font-weight: 500;
        color: rgba($blueDark, 0.5);
        letter-spacing: 0.15px;
      }
      .empty__banner__content__list {
        margin-top: 36px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        padding: 0 27px;
        @include respond(s720) {
          padding: 0;
        }
        .list__item {
          display: flex;
          gap: 8px;
          align-items: center;
          justify-content: flex-start;
          .v-icon {
            @include fluidFont(24, 24, 1);
            color: rgba($skyBlue, 1);
          }
          p {
            @include fluidFont(14, 14, 1.3);
            font-weight: 400;
            color: rgba($blueDark, 1);
            letter-spacing: 0.4px;
            margin: 0;
            text-align: left;
          }
        }
      }
    }
    .empty__banner__footer {
      margin-top: 36px;
    }
  }
}
</style>
