<template>
  <div
    class="phase__accordion tw-w-full tw-max-w-full !tw-shadow-[0px_0px_8px_0px_#0C0F4A14]"
  >
    <v-expansion-panel class="phase-received-item !tw-rounded-[10px]">
      <v-expansion-panel-title class="tw-flex tw-items-end">
        <main
          class="tw-flex tw-items-center tw-justify-between tw-max-w-full tw-w-[calc(100%_-_35px)] md:tw-flex-col md:tw-items-start md:tw-w-full"
        >
          <PhaseTitle
            :title="phase.name"
            :phaseNumber="phaseIndex + 1"
            :estimate="phase"
            :textColor="phase?.color || '#4A92E5'"
          />
          <section
            class="phase_list_summary tw-relative tw-flex tw-gap-4 tw-items-center tw-w-[75%] tw-max-w-full tw-justify-end xl:tw-w-[60%] md:tw-w-full md:tw-justify-start md:tw-flex-col-reverse md:tw-items-start md:tw-gap-2"
          >
            <div
              class="phase-received-item__item tw-flex tw-gap-2 tw-items-start tw-pr-2"
            >
              <div class="tw-flex tw-gap-2 tw-items-start tw-pr-2">
                <Text variant="span">{{
                  phase?.estimationPhaseTasks.length
                }}</Text>
                <Text
                  variant="span"
                  textWeight="400"
                  textColor="rgba(12, 15, 74, 0.5)"
                  >tasks</Text
                >
              </div>
              <div
                class="phase-received-item__item tw-flex tw-gap-2 tw-items-start tw-pr-2"
                v-if="phase?.phaseType === EstimatePhaseType.LABOUR"
              >
                <Text variant="span">{{
                  getDuration(
                    phase?.phaseStartDate,
                    phase?.phaseEndDate,
                    phase.durationType
                  )
                }}</Text>
                <Text
                  variant="span"
                  textWeight="400"
                  textColor="rgba(12, 15, 74, 0.5)"
                  >{{ getDurationType(phase.durationType) }}</Text
                >
              </div>
              <div
                class="phase-received-item__item tw-flex tw-gap-2 tw-items-start tw-pr-2"
              >
                <Text variant="span">{{
                  CurrencyFilter.formatToCurrency(phase?.subTotal)
                }}</Text>
              </div>
              <div
                class="phase-received-item__item tw-flex tw-gap-2 tw-items-start tw-pr-2"
              >
                <Text variant="span">{{
                  phase?.estimationPhaseProperties.length
                }}</Text>
                <Text
                  variant="span"
                  textWeight="400"
                  textColor="rgba(12, 15, 74, 0.5)"
                  >{{
                    `Propert${
                      phase?.estimationPhaseProperties.length > 1 ? "ies" : "y"
                    }`
                  }}</Text
                >
              </div>
            </div>
            <div class="tw-flex tw-items-center">
              <ProfileThumbnail
                class="tw-mr-[-.5rem]"
                mobilSize="sm"
                size="sm"
                v-for="item in 3"
                :key="item"
              />
            </div>
          </section>
        </main>
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <main class="tw-flex tw-flex-col tw-w-full">
          <div v-if="phase?.phaseType === EstimatePhaseType.MATERIALS">
            <AddressItem
              v-if="phase?.estimationPhaseProperties.length"
              :showAddAction="false"
              :addressValue="
                getPropertiesFirstValue(phase?.estimationPhaseProperties)
              "
              :countValue="
                getPropertyAdditionalCount(
                  phase?.estimationPhaseProperties.length
                )
              "
              :properties="phase?.estimationPhaseProperties"
            />
            <Text variant="span" v-else>No Property</Text>
          </div>
          <section
            class="phase-received-item__task-item tw-py-2 tw-box-border tw-flex tw-items-center tw-justify-between tw-w-full"
            v-for="(task, index) in phase?.estimationPhaseTasks"
            :key="index"
          >
            <div v-if="phase?.phaseType === EstimatePhaseType.LABOUR">
              <AddressItem
                v-if="task.estimationPhaseTasksProperties.length"
                :showAddAction="false"
                :addressValue="
                  getPropertiesFirstValue(task.estimationPhaseTasksProperties)
                "
                :countValue="
                  getPropertyAdditionalCount(
                    task.estimationPhaseTasksProperties.length
                  )
                "
                :properties="task.estimationPhaseTasksProperties"
              />
              <Text variant="span" v-else>No Property</Text>
            </div>
            <section class="tw-flex tw-gap-4 tw-items-center tw-pr-4">
              <div class="tw-pr-4 tw-flex tw-gap-3 tw-items-start">
                <Text variant="span">{{ task.name }}</Text>
              </div>
              <div class="tw-pr-4 tw-flex tw-gap-3 tw-items-start">
                <Text variant="span">{{
                  getDuration(
                    task?.phaseTaskStartDate,
                    task?.phaseTaskEndDate,
                    task.durationType
                  )
                }}</Text>
                <Text
                  variant="span"
                  textWeight="400"
                  textColor="rgba(12, 15, 74, 0.5)"
                  >{{ getDurationType(task.durationType) }}</Text
                >
              </div>
              <div class="tw-pr-4 tw-flex tw-gap-3 tw-items-start">
                <Text variant="span">{{ task?.taskCost }}</Text>
              </div>
              <div class="tw-flex tw-items-center">
                <ProfileThumbnail
                  class="tw-mr-[-.5rem]"
                  size="sm"
                  v-for="item in 1"
                  :key="item"
                />
              </div>
            </section>
          </section>
        </main>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </div>
</template>
<script lang="ts" setup>
import moment from "moment";
import { ref, reactive } from "vue";
import Text from "@/core/components/ui/general/Text.vue";
import ProfileThumbnail from "@/core/uni-components/ProfileThumbnail.vue";
import PhaseTitle from "@/modules/project/components/common/PhaseTitle.vue";
import AddressItem from "@/modules/project/components/common/AddressItem.vue";
import { DurationsType, EstimatePhaseType } from "@/core/enums/estimateEnum";
import CurrencyFilter from "@/filters/currency.filter";
import { computed } from "vue";

const props = defineProps(["phase", "phaseIndex"]);

const getDurationType = (durationsType: number) => {
  if (durationsType === DurationsType.DAY) {
    return "day(s)";
  }
  return "week(s)";
};

const getDuration = (
  taskStartDate: any,
  taskEndDate: any,
  durationType: number
) => {
  if (!taskStartDate || !taskEndDate) return 0;

  const start = moment(taskStartDate);
  const end = moment(taskEndDate);
  const duration = moment.duration(end.diff(start));

  if (durationType === DurationsType.DAY) {
    return duration.days();
  }
  return duration.weeks();
};

const getPropertyAdditionalCount = (count: number) => {
  if (count > 1) {
    return count - 1;
  }
  return;
};

const getPropertiesFirstValue = (properties: any) => {
  if (properties && properties.length) {
    return properties[0].property.address;
  }
  return "";
};
</script>
<script lang="ts">
export default {
  name: "ContractorDetailCard",
};
</script>
<style lang="scss" scoped>
.phase__accordion {
  .phase-received-item {
    :deep(.v-expansion-panel__shadow) {
      display: none;
    }
  }
}
.v-expansion-panel-title__icon {
  width: 25px;
  max-width: 100%;
  margin: 0;
}

.phase-received-item__item {
  border-right: 2px solid rgba(12, 15, 74, 0.2);

  .phase-received-item__item:last-child {
    border-right: unset;
  }
}
</style>
