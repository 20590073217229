import { Commit } from "vuex";
import AuditLogService from "@/core/services/audit-logs.service";

export default {
  async getAuditLogs(
    { commit }: { commit: Commit },
    payload: { userId: number; params: any }
  ) {
    try {
      const auditLogsList = await AuditLogService.getAuditLogs(
        payload.userId,
        payload.params || {}
      );
      commit("setAuditLogs", auditLogsList?.data);
      return auditLogsList;
    } catch (error) {
      console.log("error", error);
    }
  },
};
