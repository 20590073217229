<template>
  <div class="courses_content no-scrollbar md:!tw-pb-16 md:!tw-box-border">
    <CoursesDetailsView />
    <!-- <v-tabs class="tab-list" v-model="tab" align-tabs="center" stacked>
      <v-tab value="tab-1">
        <v-icon>mdi-home</v-icon>
        Courses
      </v-tab>

      <v-tab value="tab-2">
        <v-icon>mdi-file-document</v-icon>
        Enrolled
      </v-tab>

      <v-tab value="tab-3">
        <v-icon>mdi-view-list</v-icon>
        Completed
      </v-tab>
    </v-tabs>

    <v-window v-model="tab" class="tw-h-full">
      <v-window-item v-for="i in 3" :key="i" :value="'tab-' + i">
        <CoursesDetailsView v-if="i === 1" />
        <EnrollCoursesList v-if="i === 2" />
      </v-window-item>
    </v-window> -->
  </div>
</template>

<script lang="ts">
import CoursesDetailsView from "@/modules/courses/components/CoursesDetailsView.vue";
// import EnrollCoursesList from "@/modules/courses/components/EnrollCoursesList.vue";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "CoursesDetailsPage",
  components: {
    CoursesDetailsView,
    // EnrollCoursesList,
  },
  setup() {
    const tab = ref(null);
    return { tab };
  },
});
</script>
<style lang="scss" scoped>
@import "@/modules/courses/styles/courses.scss";
.courses_content {
  max-height: 800px;
  overflow: auto;
  @include respond(s720) {
    max-height: 590px;
  }
  @include respond(sm) {
    max-height: 670px;
  }
}
</style>
