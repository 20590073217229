import { Commit } from "vuex";
import $axios from "@/core/utils/axios-api-config";
import {
  SelectRole,
  User,
  VerifyPhoneNumber,
  UpdateUserSkillsRequestDto,
  removeAssignedMembersSkillsDto,
  RemoveSubCategoryDto,
} from "@/core/models/user";
import UserService from "@/core/services/user.service";
import { UserVerificationStatusEnum } from "@/core/enums/RolesEnum";
import app from "@/main";
import { WORKSTATION } from "../workstation";
import { getImageApiUrl, getLocalStorageWithExpiry } from "@/core/utils/common";
import { AUTH_TOKEN, AUTH_USER } from "@/core/constants";
import { USER_STORE } from ".";
import { API_URL, PUSHER_API_KEY, PUSHER_CLUSTER } from "@/config";
import PusherPlugin from "@/core/utils/pusher";

export default {
  async selectRole({ state, commit }: any, payload: SelectRole) {
    const { userId, roleId, email } = payload;
    return UserService.selectRole(userId, roleId, email).then((response) => {
      return response;
    });
  },
  async selectAccount({ state, commit }: any, payload: SelectRole) {
    const { userId, roleId, email } = payload;
    return UserService.selectAccount(userId, roleId, email).then((response) => {
      return response;
    });
  },
  async uploadProfileImage({ state, commit }: any, formData: any) {
    return UserService.uploadProfileImage(formData).then((response) => {
      return response;
    });
  },
  async uploadMultipleImage({ state, commit }: any, formData: any) {
    return UserService.uploadMultipleImage(formData).then((response) => {
      return response;
    });
  },
  async verifyPhoneNumber(
    { commit }: { commit: Commit },
    payload: VerifyPhoneNumber
  ) {
    const { contactNo } = payload;
    return UserService.verifyPhoneNumber(contactNo).then((response) => {
      return response;
    });
  },
  async getCategories({ commit }: { commit: Commit }) {
    return UserService.getCategories().then((response) => {
      commit("setSpecialismsCategory", response);
      return response;
    });
  },
  async saveUserSkills(
    { state, commit }: any,
    payload: UpdateUserSkillsRequestDto
  ) {
    return UserService.saveUserSkills(payload).then((response) => {
      return response;
    });
  },
  async updateUser({ state, commit }: any, payload: any) {
    const { userId, userData } = payload;
    return UserService.updateUser(userId, userData).then((response) => {
      commit("setUser", response);
      return response;
    });
  },
  saveUserState({ commit }: { commit: Commit }, user: User): User | null {
    commit("setUser", user);

    return user;
  },
  saveUserImage({ commit }: { commit: Commit }, userImage: any): any | null {
    if (userImage) {
      commit("setImage", userImage);

      return userImage;
    }

    return null;
  },

  saveUserProfile(
    { commit, getters }: { commit: Commit; getters: any },
    payload: any
  ) {
    const { userId, profile } = payload;
    if (!userId && !profile) return;
    if (!getters.user?.userPublicProfile) {
      return UserService.createUserProfile(userId, profile);
    }
    return UserService.updateUserProfile(
      userId,
      getters.user?.userPublicProfile?.id,
      profile
    );
  },
  async setUserProfileImage(
    { commit }: { commit: Commit },
    profileImage: string
  ) {
    if (!profileImage) return;
    const imageUrl = getImageApiUrl(profileImage, true);
    try {
      const encoded: any = await $axios.get(imageUrl);
      commit("setImage", encoded?.publicUrl);
      return encoded;
    } catch (error) {
      return null;
    }
  },
  async setUserWorkStationProfileImage(
    { commit }: { commit: Commit },
    profileImage: string
  ) {
    if (!profileImage) {
      commit("setUserWorkStationImage", null);
      return;
    }
    const imageUrl = getImageApiUrl(profileImage, true);
    try {
      const encoded: any = await $axios.get(imageUrl);
      commit("setUserWorkStationImage", encoded?.publicUrl);
      return encoded;
    } catch (error) {
      return null;
    }
  },
  async getImage({ commit }: { commit: Commit }, profileImage: string) {
    if (!profileImage) {
      return;
    }
    const imageUrl = getImageApiUrl(profileImage, true);
    try {
      const encoded: any = await $axios.get(imageUrl);
      return encoded?.publicUrl;
    } catch (error) {
      return null;
    }
  },
  saveUserRole({ commit }: { commit: Commit }, userRole: any): any | null {
    if (userRole) {
      commit("setRole", userRole);

      return userRole;
    }

    return null;
  },
  saveUserStripePayment({ commit }: { commit: Commit }, payload: any) {
    commit("setUserStripeCustomer", payload);
  },
  async initializeUserState({
    commit,
    dispatch,
  }: {
    commit: Commit;
    dispatch: any;
  }) {
    const userId = await dispatch("getAndIdentifyUser");
    if (!userId) return;
    return UserService.getUser(userId).then((user: any) => {
      if (!user) return null;
      // Initial Pusher
      app.use(PusherPlugin, {
        apiKey: PUSHER_API_KEY,
        options: {
          cluster: PUSHER_CLUSTER,
          authEndpoint: `${API_URL}user-notifications/authenticate`,
          auth: {
            headers: {
              Authorization: `Bearer ${getLocalStorageWithExpiry(AUTH_TOKEN)}`,
            },
          },
        },
      });
      commit("setUser", user);

      return user;
    });
  },
  async getAccountStatuses({
    commit,
    dispatch,
  }: {
    commit: Commit;
    dispatch: any;
  }) {
    const userId = await dispatch("getAndIdentifyUser");
    if (!userId) return;
    return UserService.getAccountStatuses(userId).then((statuses) => {
      commit("setAccountStatuses", statuses);

      let profileStatus = false;

      if (statuses && statuses.forms) {
        const totalForms = Object.values(statuses.forms).length;

        const checkAllFormsVerified = Object.values(statuses.forms).reduce(
          (acc: number, form) => {
            return form === UserVerificationStatusEnum.PENDING_VERIFICATION ||
              form === UserVerificationStatusEnum.VERIFIED
              ? acc + 1
              : acc;
          },
          0
        ) as number;
        profileStatus = checkAllFormsVerified >= totalForms;
      }

      commit("setIsProfileVerifiable", profileStatus);

      return statuses;
    });
  },
  getAndIdentifyUser({ rootGetters }: { rootGetters: any }) {
    const authenticatedUserId =
      rootGetters[`${USER_STORE}/user`]?.id ||
      getLocalStorageWithExpiry(AUTH_USER)?.userId;
    let id = authenticatedUserId;
    const isHosted = window.localStorage.getItem("hst");

    if (isHosted) {
      const activeWs = rootGetters[`${WORKSTATION}/activeUserWorkstation`];

      if (activeWs?.user?.id) {
        id = activeWs.user?.id;
      }
    }
    if (!id) {
      window.localStorage.clear();
    }
    return id;
  },

  setUserInsurances({ commit }: { commit: Commit }, userId: number) {
    return UserService.getUserInsurances(userId).then((insurances) => {
      commit("setInsurances", insurances);
      return insurances;
    });
  },
  setContractorInsurances({ commit }: { commit: Commit }, payload: any) {
    const { userId, workStationId } = payload;
    return UserService.getContractorInsurances(userId, workStationId).then(
      (insurances) => {
        commit("setInsurances", insurances);
        return insurances;
      }
    );
  },

  addNewInsurance({ commit }: { commit: Commit }, payload: any) {
    return UserService.createUserInsurance(payload).then((response: any) => {
      commit("setNewUserInsurance", response);
      return response;
    });
  },
  updateUserInsurance({ commit }: { commit: Commit }, payload: any) {
    const { userId, userInsuranceId, insurance } = payload;

    return UserService.updateUserInsurance(
      userId,
      userInsuranceId,
      insurance
    ).then((response: any) => {
      commit("updateUserInsurance", response);
      return response;
    });
  },
  removeUserInsurance({ commit }: { commit: Commit }, payload: any) {
    const { userId, userInsuranceId } = payload;
    return UserService.deleteUserInsurance(userId, userInsuranceId).then(
      (response) => {
        if (response === 1) {
          commit("removeUserInsurance", userInsuranceId);
        }
        return response;
      }
    );
  },

  //certification

  setUserCertificates({ commit }: { commit: Commit }, userId: number) {
    return UserService.getUserCertificates(userId).then((certificates: any) => {
      commit("setCertificates", certificates);

      return certificates;
    });
  },

  setContractorCertificates({ commit }: { commit: Commit }, payload: any) {
    const { userId, workStationId } = payload;
    return UserService.getContractorCertificates(userId, workStationId).then(
      (certificates: any) => {
        commit("setCertificates", certificates);

        return certificates;
      }
    );
  },

  addNewCertificate({ commit }: { commit: Commit }, certificate: any) {
    return UserService.createCertificate(certificate).then((response: any) => {
      commit("setNewUserCertificate", response);
      return response;
    });
  },

  updateUserCertificate({ commit }: { commit: Commit }, payload: any) {
    const { userId, certificate, id } = payload;

    return UserService.updateCertificate(userId, id, certificate).then(
      (response: any) => {
        commit("updateCertificate", response);
        return response;
      }
    );
  },

  async addCertificateRelatedMembers(
    { commit }: { commit: Commit },
    payload: any
  ) {
    try {
      const { userId, certificateRecordId, teamMemberIds } = payload;

      return await UserService.addCertificateRelatedMembers(
        userId,
        certificateRecordId,
        teamMemberIds
      );
    } catch (error) {
      return null;
    }
  },

  async removeCertificateRelatedMembers(
    { commit }: { commit: Commit },
    payload: any
  ) {
    try {
      const { userId, certificateRecordId, teamMemberIds } = payload;

      return await UserService.deleteCertificateRelatedMembers(
        userId,
        certificateRecordId,
        teamMemberIds
      );
    } catch (error) {
      return null;
    }
  },

  removeUserCertificates(
    { commit }: { commit: Commit },
    payload: any
  ): Promise<number> {
    const { userId, userCertificateId } = payload;

    return UserService.deleteCertificate(userId, userCertificateId).then(
      (response: any) => {
        commit("removeUserCertificates", userCertificateId);

        return response;
      }
    );
  },
  getUser(context: any, userId: any) {
    return UserService.getUser(userId).then((user) => {
      if (!user) return null;
      // Initial Pusher
      setTimeout(() => {
        app.use(PusherPlugin, {
          apiKey: PUSHER_API_KEY,
          options: {
            cluster: PUSHER_CLUSTER,
            authEndpoint: `${API_URL}user-notifications/authenticate`,
            auth: {
              headers: {
                Authorization: `Bearer ${getLocalStorageWithExpiry(
                  AUTH_TOKEN
                )}`,
              },
            },
          },
        });
      }, 1000);

      return user;
    });
  },
  getUserSkills({ commit }: { commit: Commit }, userId: number) {
    return UserService.getUserSkills(userId).then((response) => {
      commit("setUserSpecialisms", response);
      return response;
    });
  },
  getContractorSkills({ commit }: { commit: Commit }, payload: any) {
    const { userId, workStationId } = payload;
    return UserService.getContractorSkills(userId, workStationId).then(
      (response) => {
        commit("setUserSpecialisms", response);
        return response;
      }
    );
  },
  saveBusinessDetails({ getters }: { getters: any }, payload: any) {
    const { userId, formData } = payload;
    if (!getters.userBusinessDetails?.id) {
      return UserService.createBusinessDetails(userId, formData);
    }
    return UserService.updateBusinessDetails(
      userId,
      getters.userBusinessDetails.id,
      formData
    );
  },
  getWorkStationInvitation({ commit }: { commit: Commit }, userId: number) {
    if (!userId) return;
    return UserService.getWorkStationInvitation(userId);
  },
  acceptWorkStationInvitation({ commit }: { commit: Commit }, payload: any) {
    const { userId, key } = payload;
    return UserService.acceptWorkStationInvitation(userId, key);
  },
  rejectWorkStationInvitation({ commit }: { commit: Commit }, payload: any) {
    const { userId, workStationInvitationId } = payload;
    return UserService.rejectWorkStationInvitation(
      userId,
      workStationInvitationId
    );
  },
  updatePropertyOwnerWSType({ commit }: { commit: Commit }, payload: any) {
    const { userId, userWorkstationId, verificationType } = payload;

    return UserService.updatePropertyOwnerWSType(
      userId,
      userWorkstationId,
      verificationType
    ).then((response: any) => {
      return response;
    });
  },

  async removeAssignedMembers(
    { state, commit }: any,
    payload: removeAssignedMembersSkillsDto
  ) {
    const { userId, teamMemberIds } = payload;
    return UserService.removeAssignedMembers(userId, teamMemberIds).then(
      (response) => {
        return response;
      }
    );
  },
  async removeSubCategory(
    { state, commit }: any,
    payload: RemoveSubCategoryDto
  ) {
    return UserService.removeSubCategory(payload).then((response) => {
      return response;
    });
  },
  async setAllSpecialismsCategory(
    { commit, rootGetters }: { commit: Commit; rootGetters: any },
    activeUserWorkstationId: number
  ) {
    try {
      const currentUserWorkStationId =
        rootGetters[`${WORKSTATION}/activeUserWorkstation`]?.id;
      const response = await UserService.getCustomCategories(
        activeUserWorkstationId || currentUserWorkStationId
      );
      commit("setAllSpecialism", response);
      return response;
    } catch (error) {
      commit("setAllSpecialism", []);
    }
  },
};
