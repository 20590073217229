<template>
  <div class="desktop__wrapper">
    <v-table
      fixed-header
      class="tw-border-t-solid-custom tw-pb-4 tw-box-border !tw-rounded-none !tw-max-h-[500px]"
    >
      <thead>
        <tr>
          <th class="text-left"></th>
          <th class="text-left">
            <Text variant="p" textColor="rgba(12, 15, 74, 0.5)"
              >Property Type</Text
            >
          </th>
          <th class="text-left tw-cursor-pointer" @click="sort">
            <div class="tw-flex tw-gap-[2px] tw-items-center">
              <Text
                variant="p"
                whiteSpace="nowrap"
                textColor="rgba(12, 15, 74, 0.5)"
                >Date Listed</Text
              >
              <v-icon
                v-if="!sortIsAsc"
                icon="mdi-arrow-up-thin"
                color="rgba(12, 15, 74, 0.5)"
              ></v-icon>
              <v-icon
                v-else
                icon="mdi-arrow-down-thin"
                color="rgba(12, 15, 74, 0.5)"
              ></v-icon>
            </div>
          </th>
          <th class="text-left">
            <Text
              variant="p"
              whiteSpace="nowrap"
              textColor="rgba(12, 15, 74, 0.5)"
              >Price PCM</Text
            >
          </th>
          <th class="text-left">
            <Text
              variant="p"
              whiteSpace="nowrap"
              textColor="rgba(12, 15, 74, 0.5)"
              >Beds</Text
            >
          </th>
          <th class="text-left">
            <Text
              variant="p"
              whiteSpace="nowrap"
              textColor="rgba(12, 15, 74, 0.5)"
              >Baths</Text
            >
          </th>
          <th class="text-left">
            <Text
              variant="p"
              whiteSpace="nowrap"
              textColor="rgba(12, 15, 74, 0.5)"
              >Area</Text
            >
          </th>
          <!-- <th class="text-left">
          <Text
            variant="p"
            whiteSpace="nowrap"
            textColor="rgba(12, 15, 74, 0.5)"
            >EPC</Text
          >
        </th> -->
          <th class="text-left">
            <Text
              variant="p"
              whiteSpace="nowrap"
              textColor="rgba(12, 15, 74, 0.5)"
              >Map</Text
            >
          </th>
          <th class="text-left">
            <Text
              variant="p"
              whiteSpace="nowrap"
              textColor="rgba(12, 15, 74, 0.5)"
              >Postcode</Text
            >
          </th>
          <th class="text-left">
            <Text
              variant="p"
              whiteSpace="nowrap"
              textColor="rgba(12, 15, 74, 0.5)"
              >Distance</Text
            >
          </th>
          <th class="text-left">
            <v-menu location="bottom">
              <template v-slot:activator="{ props }">
                <v-icon v-bind="props" icon="mdi-plus-circle"></v-icon>
              </template>
              <!-- Menu here -->
            </v-menu>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in sortedData"
          :key="index"
          class="tw-cursor-pointer"
          :class="{
            'tw-bg-blue-100':
              state.selectedRow === item.locationId &&
              state.selectedRow !== null,
          }"
          @click="onHandleClickRow($event, item)"
        >
          <td class="text-left">
            <div
              class="tw-min-w-[12px] tw-max-w-[12px] tw-max-h-[12px] tw-min-h-[12px] tw-bg-[#DCDDF9] tw-rounded-full"
            ></div>
          </td>
          <td class="text-left">
            <Text variant="p">{{
              normarlizeText(item?.build?.propertyType)
            }}</Text>
          </td>
          <td class="text-left">
            <Text variant="p">{{
              appFilters.formatToDate(
                item.marketStatus?.lastOTMRent,
                "DD MMM YYYY"
              )
            }}</Text>
          </td>
          <td class="text-left">
            <div class="tw-flex tw-items-center tw-gap-2">
              <template v-if="!props.isPreviousRent">
                <MinusGrayIcon
                  v-if="getDirection(item?.listings) === 'initial'"
                />
                <DownwardRedIcon
                  v-else-if="getDirection(item?.listings) === 'down'"
                />
                <UpwardGreenIcon v-else />
              </template>

              <Text variant="p">{{ getPCM(item) }}</Text>
            </div>
          </td>
          <td class="text-left">
            <Text variant="p">{{ item.roomDetails?.beds }}</Text>
          </td>
          <td class="text-left">
            <Text variant="p">{{ item.roomDetails?.baths }}</Text>
          </td>
          <td class="text-left">
            <v-menu
              open-on-hover
              :close-on-content-click="false"
              location="start"
            >
              <template v-slot:activator="{ props }">
                <div class="tw-flex tw-items-center tw-gap-2" v-bind="props">
                  <!-- <AreaIcon fill="#FAA500" fillOpacity="1" /> -->
                  <AreaIcon />
                  <Text variant="p">{{
                    convertSqmToSqft(item.build?.totalFloorArea)
                  }}</Text>
                </div>
              </template>

              <v-card min-width="420" class="tw-right-[10px]">
                <div
                  class="tw-w-full tw-h-full tw-flex tw-flex-col tw-gap-4 tw-p-4 tw-box-border"
                >
                  <div
                    class="tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center"
                    v-if="!getImageSource(item?.listings)"
                  >
                    <Text>No floor plan</Text>
                  </div>
                  <v-img
                    class="tw-w-full tw-h-full"
                    cover
                    :src="getImageSource(item?.listings)"
                  ></v-img>
                  <Button
                    @click="downloadImage(item?.listings)"
                    label="Download floorplan"
                    v-if="getImageSource(item?.listings)"
                    :loading="loadingDownload"
                  />
                </div>
              </v-card>
            </v-menu>
          </td>
          <!-- <td class="text-left">
          <EnergyRatingChip currentRating="-" potentialRating="-" />
        </td> -->
          <td class="text-left">
            <Text variant="p">{{ item.address?.displayAddress }}</Text>
          </td>
          <td class="text-left">
            <Text variant="p">{{ item.address?.postcode }}</Text>
          </td>
          <td class="text-left">
            <Text variant="p">{{
              haversineDistance(item?.spatial?.coordinates)
            }}</Text>
          </td>
          <td class="text-left">
            <v-menu location="bottom">
              <template v-slot:activator="{ props }">
                <v-icon
                  v-bind="props"
                  icon="mdi-dots-horizontal"
                  color="rgba(12, 15, 74, 0.5)"
                ></v-icon>
              </template>
              <!-- Menu here -->
            </v-menu>
          </td>
        </tr>
      </tbody>
    </v-table>
  </div>

  <!-- Mobile Version -->
  <div class="mobile__wrapper">
    <div class="property_card__list">
      <div
        v-for="(item, index) in sortedData"
        :key="index"
        class="tw-cursor-pointer property_card__list__item"
        :class="{
          'tw-bg-blue-100':
            state.selectedRow === item.locationId && state.selectedRow !== null,
        }"
        @click="onHandleClickRow($event, item)"
      >
        <div class="property_card__list__item__content">
          <v-menu location="bottom">
            <template v-slot:activator="{ props }">
              <v-icon
                v-bind="props"
                icon="mdi-dots-horizontal"
                color="rgba(12, 15, 74, 0.5)"
              ></v-icon>
            </template>
            <!-- Menu here -->
          </v-menu>
          <v-menu location="bottom">
            <template v-slot:activator="{ props }">
              <v-icon v-bind="props" icon="mdi-plus-circle"></v-icon>
            </template>
            <!-- Menu here -->
          </v-menu>
        </div>
        <div class="property_card__list__item__content">
          <Text variant="p" textColor="rgba(12, 15, 74, 0.5)"
            >Property Type</Text
          >
          <Text variant="p">{{
            normarlizeText(item?.build?.propertyType)
          }}</Text>
        </div>

        <div class="property_card__list__item__content" @click="sort">
          <div class="tw-flex tw-gap-[2px] tw-items-center">
            <Text
              variant="p"
              whiteSpace="nowrap"
              textColor="rgba(12, 15, 74, 0.5)"
              >Date Listed</Text
            >
            <v-icon
              v-if="!sortIsAsc"
              icon="mdi-arrow-up-thin"
              color="rgba(12, 15, 74, 0.5)"
            ></v-icon>
            <v-icon
              v-else
              icon="mdi-arrow-down-thin"
              color="rgba(12, 15, 74, 0.5)"
            ></v-icon>
          </div>
          <Text variant="p">{{
            appFilters.formatToDate(
              item.marketStatus?.lastOTMRent,
              "DD MMM YYYY"
            )
          }}</Text>
        </div>

        <div class="property_card__list__item__content">
          <Text
            variant="p"
            whiteSpace="nowrap"
            textColor="rgba(12, 15, 74, 0.5)"
            >Price PCM</Text
          >
          <div class="tw-flex tw-items-center tw-gap-2">
            <template v-if="!props.isPreviousRent">
              <MinusGrayIcon
                v-if="getDirection(item?.listings) === 'initial'"
              />
              <DownwardRedIcon
                v-else-if="getDirection(item?.listings) === 'down'"
              />
              <UpwardGreenIcon v-else />
            </template>

            <Text variant="p">{{ getPCM(item) }}</Text>
          </div>
        </div>

        <div class="property_card__list__item__content">
          <Text
            variant="p"
            whiteSpace="nowrap"
            textColor="rgba(12, 15, 74, 0.5)"
            >Beds</Text
          >
          <Text variant="p">{{ item.roomDetails?.beds }}</Text>
        </div>

        <div class="property_card__list__item__content">
          <Text
            variant="p"
            whiteSpace="nowrap"
            textColor="rgba(12, 15, 74, 0.5)"
            >Baths</Text
          >
          <Text variant="p">{{ item.roomDetails?.baths }}</Text>
        </div>

        <div class="property_card__list__item__content">
          <Text
            variant="p"
            whiteSpace="nowrap"
            textColor="rgba(12, 15, 74, 0.5)"
            >Area</Text
          >
          <v-menu
            open-on-hover
            :close-on-content-click="false"
            location="start"
          >
            <template v-slot:activator="{ props }">
              <div class="tw-flex tw-items-center tw-gap-2" v-bind="props">
                <!-- <AreaIcon fill="#FAA500" fillOpacity="1" /> -->
                <AreaIcon />
                <Text variant="p">{{
                  convertSqmToSqft(item.build?.totalFloorArea)
                }}</Text>
              </div>
            </template>

            <v-card min-width="420" class="tw-right-[10px]">
              <div
                class="tw-w-full tw-h-full tw-flex tw-flex-col tw-gap-4 tw-p-4 tw-box-border"
              >
                <div
                  class="tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center"
                  v-if="!getImageSource(item?.listings)"
                >
                  <Text>No floor plan</Text>
                </div>
                <v-img
                  class="tw-w-full tw-h-full"
                  cover
                  :src="getImageSource(item?.listings)"
                ></v-img>
                <Button
                  @click="downloadImage(item?.listings)"
                  label="Download floorplan"
                  v-if="getImageSource(item?.listings)"
                  :loading="loadingDownload"
                />
              </div>
            </v-card>
          </v-menu>
        </div>

        <div class="property_card__list__item__content">
          <Text
            variant="p"
            whiteSpace="nowrap"
            textColor="rgba(12, 15, 74, 0.5)"
            >Map</Text
          >
          <Text variant="p">{{ item.address?.displayAddress }}</Text>
        </div>

        <div class="property_card__list__item__content">
          <Text
            variant="p"
            whiteSpace="nowrap"
            textColor="rgba(12, 15, 74, 0.5)"
            >Postcode</Text
          >
          <Text variant="p">{{ item.address?.postcode }}</Text>
        </div>

        <div class="property_card__list__item__content">
          <Text
            variant="p"
            whiteSpace="nowrap"
            textColor="rgba(12, 15, 74, 0.5)"
            >Distance</Text
          >
          <Text variant="p">{{
            haversineDistance(item?.spatial?.coordinates)
          }}</Text>
        </div>
      </div>
    </div>
  </div>
  <!-- Modals -->
</template>
<script setup>
import moment from "moment";
import { computed, ref, reactive, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import ProfileThumbnail from "@/core/uni-components/ProfileThumbnail.vue";
import Text from "@/core/components/ui/general/Text.vue";
import PropertyListActionMenu from "@/modules/properties/components/PropertyListActionMenu.vue";
import PencilCircularBlueIcon from "@/core/components/icons/PencilCircularBlueIcon.vue";
import UpwardGreenIcon from "@/core/components/icons/UpwardGreenIcon.vue";
import DownwardRedIcon from "@/core/components/icons/DownwardRedIcon.vue";
import MinusGrayIcon from "@/core/components/icons/MinusGrayIcon.vue";
import AreaIcon from "@/core/components/icons/AreaIcon.vue";
import EnergyRatingChip from "@/modules/properties/components/EnergyRatingChip.vue";
import CurrencyFilter from "@/filters/currency.filter";
import appFilters from "@/filters";
import { PROPERTY_STORE } from "@/store/modules/property";
import Button from "@/core/components/ui/general/Button.vue";

const router = useRouter();
const store = useStore();

const props = defineProps(["isPreviousRent", "data"]);
const emits = defineEmits(["on-edit"]);

const loadingDownload = ref(false);

const sortedData = ref([]);
const sortIsAsc = ref(true);

const state = reactive({
  selectedRow: null,

  result: [],
  delay: 200,
  clicks: 0,
  timer: null,

  list: [],

  selectedProperty: null,
});

const propertyDetails = computed(
  () => store.getters[`${PROPERTY_STORE}/propertyDetails`]
);

const onEdit = (item) => {
  emits("on-edit", item);
};

const onHandleClickRow = (event, item) => {
  state.clicks++;
  if (state.clicks === 1) {
    state.timer = setTimeout(() => {
      state.selectedRow =
        state.selectedRow === item.locationId ? null : item.locationId;
      state.result.push(event.type);
      state.clicks = 0;
    }, state.delay);
  } else {
    clearTimeout(state.timer);
    state.selectedProperty = item;
    state.selectedRow = item.locationId;
    state.clicks = 0;
    // emits("on-view-property", item);
  }
};

const formattedCurrency = (amount) => {
  return CurrencyFilter.formatToCurrency(amount);
};

const getPCM = (property) => {
  let result = 0;
  if (props.isPreviousRent) {
    result = property.listings.find(
      (listing) => listing.publishedDate === property.marketStatus?.lastOTMRent
    );
  } else {
    result = property.listings.find((listing) => listing.status === "live");
  }
  return formattedCurrency(result?.pricing?.price);
};

const haversineDistance = (coords2) => {
  const { addressData } = propertyDetails.value?.localData;
  const epc = JSON.parse(addressData);
  const coords1 = [epc.lng, epc.lat];

  const toRadians = (degrees) => (degrees * Math.PI) / 180;

  const [lon1, lat1] = coords1;
  const [lon2, lat2] = coords2;

  const R = 3958.8; // Radius of the Earth in miles

  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) *
      Math.cos(toRadians(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = R * c;

  if (!distance) return "-";

  return distance.toFixed(2) + " mi ";
};

const convertSqmToSqft = (sqm) => {
  if (sqm === null) {
    return "-";
  }

  const SQM_TO_SQFT = 10.7639; // Conversion factor from square meters to square feet
  let sqft = sqm * SQM_TO_SQFT;
  return `${sqft.toFixed(2)} ft2`;
};

const getDirection = (listings) => {
  // Find the latest listing by publishedDate
  const latestListing = listings.reduce((latest, current) => {
    return new Date(current.publishedDate) > new Date(latest.publishedDate)
      ? current
      : latest;
  });

  const latestPriceChange = latestListing.priceChange.reduce(
    (latest, current) => {
      return new Date(current.date) > new Date(latest.date) ? current : latest;
    }
  );

  // Return the priceChange object from the latest listing
  return latestPriceChange?.direction;
};

const getImageSource = (listings) => {
  const latestListing = listings.reduce((latest, current) => {
    return new Date(current.publishedDate) > new Date(latest.publishedDate)
      ? current
      : latest;
  });

  if (latestListing?.floorPlanImages && latestListing?.floorPlanImages.length) {
    return latestListing?.floorPlanImages[0].url;
  }

  return null;
};

const downloadImage = (listings) => {
  const imgSource = getImageSource(listings);
  if (!imgSource) return;
  loadingDownload.value = true;
  // Fetch the image
  fetch(imgSource)
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.blob();
    })
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = "image.jpg";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    })
    .catch((error) => {
      console.error("Error downloading image:", error);
    })
    .finally(() => {
      loadingDownload.value = false;
    });
};

const normarlizeText = (str) => {
  let words = str.match(/[A-Z]*[a-z]+/g);

  // Capitalize each word and join with space
  return words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const onSort = (data, sortOrder = "asc") => {
  sortedData.value = data.sort((a, b) => {
    const startDateA = moment(a.marketStatus?.lastOTMRent);
    const startDateB = moment(b.marketStatus?.lastOTMRent);

    // const daysA = calculateDaysOTM(startDateA);
    // const daysB = calculateDaysOTM(startDateB);

    if (sortOrder === "asc") {
      return startDateB - startDateA;
    } else if (sortOrder === "desc") {
      return startDateA - startDateB;
    } else {
      return 0;
    }
  });

  return data;
};

const sort = () => {
  if (sortIsAsc.value) {
    onSort(props.data, "desc");
    sortIsAsc.value = false;
  } else {
    onSort(props.data, "asc");
    sortIsAsc.value = true;
  }
};

onMounted(() => {
  if (props.data.length && props.data) {
    onSort(props.data, "asc");
  }
});
</script>
<style lang="scss" scoped>
:deep(.v-table__wrapper) {
  &::-webkit-scrollbar {
    display: none;
  }
}
.desktop__wrapper {
  @include respond(s1024) {
    display: none;
  }
}

.mobile__wrapper {
  display: none;
  @include respond(s1024) {
    display: block;
    //background-color: rgba($white, 1);
    padding: 0;
    border-radius: 8px;
    .property_card__list {
      //padding: 16px;
      // background-color: rgba($cardBorder, 1);
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      .property_card__list__item {
        background-color: rgba($white, 1);
        padding: 16px;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        .property_card__list__item__content {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          gap: 16px;
        }
      }
    }
  }
}
</style>
