<template>
  <Card class="!tw-py-4 !tw-box-border !tw-min-w-[300px]"
    ><template #content>
      <main class="tw-flex tw-flex-col tw-gap-2 tw-justify-between tw-h-full">
        <section
          class="tw-w-full tw-pb-4"
          :class="{ 'tw-border-b-solid-custom': borderBottom }"
        >
          <slot name="custom-header" v-if="hasCustomHeader"></slot>
          <div
            v-else
            class="tw-w-full tw-flex tw-items-center tw-justify-between !tw-px-4 !tw-box-border"
          >
            <Text variant="h6">{{ title }}</Text>
            <slot name="header-rigth-content"></slot>
          </div>
        </section>
        <section class="tw-w-full tw-h-full">
          <slot name="content"></slot>
        </section>
        <section
          v-if="!hideFooter"
          class="tw-w-full tw-border-t-solid-custom !tw-px-4 tw-pt-3 tw-box-border"
        >
          <slot name="custom-footer" v-if="hasCustomFooter"></slot>
          <div
            v-else
            class="tw-w-full tw-flex tw-items-center tw-justify-between"
          >
            <div>
              <slot name="footer-content-left"></slot>
            </div>
            <Button
              v-if="getUserWorkStationFieldAccess"
              class="!tw-w-auto !tw-p-0 tw-box-border !tw-text-[#4B4BFF]"
              variant="custom"
              @click="onActionClick"
              :label="buttonLabel"
              activeBgColor="transparent"
              width="auto"
            >
              <template #postfix>
                <v-icon icon="mdi-chevron-right"></v-icon>
              </template>
            </Button>
          </div>
        </section>
      </main> </template
  ></Card>
</template>
<script setup>
import Button from "@/core/components/ui/general/Button.vue";
import Card from "@/core/components/ui/general/Card.vue";
import Text from "@/core/components/ui/general/Text.vue";

const emit = defineEmits(["on-action-click"]);

const props = defineProps({
  title: String,
  borderBottom: Boolean,
  hideFooter: Boolean,
  hasCustomFooter: Boolean,
  buttonLabel: String,
  hasCustomHeader: Boolean,
  getUserWorkStationFieldAccess: {
    type: Boolean,
    default: true,
  },
});

const onActionClick = () => {
  emit("on-action-click");
};
</script>
<style lang="scss" scoped></style>
