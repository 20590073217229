<template>
  <Card
    class="left_task_container"
    v-for="projectData of projectList"
    :key="projectData.id"
    @click="onClickTaskTab(projectData)"
  >
    <template #content>
      <section class="small-phase-item">
        <div class="projects_list">
          <div name="1" class="phase-content">
            <div class="phase-img" v-if="projectData?.profileImage">
              <img
                v-if="projectData?.profileImage?.imageUrl"
                :src="projectData?.profileImage?.imageUrl"
                alt="profile"
              />
              <UserProfileLogo
                v-else
                :userName="projectData?.profileImage?.name"
              />
            </div>
            <div class="phase-text">
              <h6>{{ projectData?.name }}</h6>
              <p v-if="calendarType === 'project'">
                Phase : {{ projectData?.phaseCount }}
              </p>
              <p v-else>Role : {{ projectData?.role }}</p>
            </div>
          </div>
        </div>
      </section>
    </template>
  </Card>
</template>
<script setup>
import Card from "@/core/components/ui/general/Card.vue";
import UserProfileLogo from "@/core/components/UserProfileLogo.vue";
import { ref, watch } from "vue";
const props = defineProps({
  projectList: Array,
  chartClickTask: Object,
  calendarType: { type: String, default: "project" },
});
const emits = defineEmits(["on-click-task"]);
const onClickTaskTab = (startDate) => {
  emits("on-click-task", startDate);
};
watch(
  () => props.chartClickTask,
  (newValue) => {
    const subTaskItemElement = document.getElementById(
      `child-task-${newValue.taskIndex}`
    );
    if (
      subTaskItemElement &&
      subTaskItemElement.classList.contains("hide") === newValue.value
    )
      onClickTaskTab(newValue.taskIndex);
  }
);
</script>
<script>
export default {
  name: "SmallPhaseCollapseItem",
};
</script>
<style lang="scss" scoped>
.small-phase-item {
  &__item {
    border-right: 1px solid rgba(12, 15, 74, 0.2);

    &:last-child {
      border-right: unset;
    }
  }
}
:deep(.el-collapse-item__header) {
  @apply tw-border-0;
}
:deep(.el-collapse-item__wrap) {
  @apply tw-border-0;
}

.small-phase-item {
  :deep(.el-collapse) {
    .el-collapse-item {
      width: 100%;
      border-bottom: 1px dashed rgba($black, 0.1);
      .el-collapse-item__header {
        height: 100%;
        padding: 13px 0;
      }
      .el-collapse-item__wrap {
        .el-collapse-item__content {
          padding: 0;
        }
      }
    }
    .is-active {
      transition: 0.5s ease all;
    }
  }
}
.sub-tasks:last-child {
  margin-bottom: 14px;
}
.hide {
  display: none;
}

.custom_icons {
  display: flex;
  gap: 10px;
  align-items: center;
}

.subtask_text {
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.left_task_container {
  cursor: pointer;
  background-color: transparent !important;
  border-radius: 0 !important;
  position: relative;
  max-height: 60px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 60px;
  padding-left: 24px;
  @include respond(md) {
    padding-left: 8px;
  }

  &:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 1px;
    left: 0;
    background-color: rgba(12, 15, 74, 0.1);
    bottom: 0;
  }
}

.phase-content {
  text-align: left;
  display: flex;
  align-items: flex-start;
  gap: 8px;
  .phase-img {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    overflow: hidden;
    @include respond(s720) {
      width: 20px;
      height: 20px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .phase-text {
    h6 {
      @include fluidFont(16, 16, 1.3);
      font-weight: 400;
      color: rgba($blueDark, 1);
      max-width: 120px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    p {
      margin: 4px 0 0;
      @include fluidFont(12, 12, 1.3);
      font-weight: 400;
      color: rgba($blueDark, 0.5);
    }
  }
}
</style>
