<template>
  <InvitationSuccessComponent
    v-if="isShowInvitationsSentModal"
    @onClose="onClickCloseButton"
    :members="form.members"
  />
  <CommonDialog
    v-else
    :isShowHeader="false"
    :isShowBackButton="false"
    :className="['add_team_member_dialog']"
    width="968px"
  >
    <template v-slot:body>
      <div class="header">
        <h4>Invite a member to iknowa platform</h4>
        <v-btn
          icon="mdi-close"
          @click="onClickCloseButton(false, $event)"
        ></v-btn>
      </div>
      <div class="modal__content">
        <div class="modal__content_header_note">
          Each added member costs £9.99/month. <span> Learn More. </span>
        </div>
        <div class="modal__inner__content">
          <v-form ref="emailFormRef" @submit.prevent class="tw-w-full">
            <div
              v-for="(member, index) in form.members"
              :key="index"
              class="tw-w-full invite_members_form"
            >
              <div class="input_field email_address">
                <label>Email Addresses</label>
                <v-text-field
                  type="email"
                  density="compact"
                  v-model="member.email"
                  :rules="validation.email"
                  variant="outlined"
                  class="c-input"
                  placeholder="Email address"
                ></v-text-field>
              </div>
              <div class="input_field invite_wrapper">
                <label>Invite As</label>
                <v-select
                  placeholder="Select"
                  v-model="member.role"
                  :rules="validation.role"
                  :items="['Admin', 'Editor', 'Viewer']"
                  variant="outlined"
                  class="c-select"
                ></v-select>
              </div>
              <div class="input_field tags_wrapper">
                <label>Tags</label>
                <v-select
                  class="c-select"
                  v-model="member.tags"
                  :items="Tags"
                  placeholder="Select Tags"
                  multiple
                  variant="outlined"
                  :menu-props="{ contentClass: 'tags_dropdown' }"
                >
                  <template v-slot:prepend-item>
                    <v-list-item title="Select All" @click="toggle(member)">
                      <template v-slot:prepend>
                        <v-checkbox-btn
                          :color="
                            likesSomeTag(member) ? 'indigo-darken-4' : undefined
                          "
                          :indeterminate="
                            likesSomeTag(member) && !likesAllTags(member)
                          "
                          :model-value="likesAllTags(member)"
                        ></v-checkbox-btn>
                      </template>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                  <template v-slot:append-item>
                    <v-divider class="mb-2"></v-divider>
                    <v-list-item>
                      <v-text-field
                        v-model="customTag"
                        placeholder="Add custom tag"
                        variant="outlined"
                      ></v-text-field>
                      <v-btn
                        @click="addCustomTag(member)"
                        class="button button-orange"
                        >Add</v-btn
                      >
                    </v-list-item>
                  </template>
                </v-select>
              </div>

              <v-btn
                v-if="form.members.length > 1"
                icon="mdi-close"
                @click="onRemoveEmail(index)"
                class="remove_btn"
                variant="plain"
              ></v-btn>
            </div>
            <v-btn
              variant="text"
              class="button button-orange add_btn"
              @click="onAddEmail"
            >
              +ADD
            </v-btn>
            <div class="input_field message_wrapper">
              <label>Message (optional):</label>
              <v-textarea
                v-model="memberMessage"
                variant="outlined"
              ></v-textarea>
            </div>
          </v-form>
        </div>
      </div>
      <div class="modal__footer">
        <v-btn
          class="button button-purple-border"
          @click="onClickCloseButton(false, $event)"
          >cancel</v-btn
        >
        <v-btn
          class="button button-orange"
          @click="onSendInvitation"
          :loading="isShowLoadingSendInvitation"
          >send invitation</v-btn
        >
      </div>
    </template>
  </CommonDialog>
</template>

<script lang="ts">
import CommonDialog from "@/core/components/CommonDialog.vue";
import { computed } from "vue";
import { ref, reactive, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import { MEMBER_STORE } from "@/store/modules/manage-member";
import { USER_STORE } from "@/store/modules/user";
import InvitationSuccessComponent from "@/core/components/InvitationSuccessComponent.vue";

export default {
  components: {
    CommonDialog,
    InvitationSuccessComponent,
  },
  setup(props: any, ctx: any) {
    const store = useStore();
    const internalInstance = getCurrentInstance();
    const user = computed(() => store.getters[`${USER_STORE}/user`]);

    const memberMessage = ref("");
    const isShowLoadingSendInvitation = ref(false);
    const isShowInvitationsSentModal = ref(false);
    const selectedTags = ref([]) as any;
    const Tags = ref(["Trade Contactor", "Manager", "Employee", "Assistant"]);
    const customTag = ref("");
    const form = reactive({
      members: [{ email: "", role: "", tags: [] }],
    });
    const emailFormRef = ref(null) as any;
    const validation = ref({
      email: [
        (v: any) => !!v || "Email is required",
        (v: any) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "Invalid email. Try again.",
        (v: any) => !isEmailDuplicate(v) || "Email is already used",
      ],
      role: [(v: any) => !!v || "Role is required"],
    });

    const isEmailDuplicate = (email: string) => {
      const emailValues = form.members.map((emailObj) => emailObj.email);
      const emailCount = emailValues.filter((e) => e === email).length;
      return emailCount > 1;
    };

    const onAddEmail = (e: any) => {
      e.preventDefault();
      form.members.push({ email: "", role: "", tags: [] });
    };

    const onRemoveEmail = (index: number) => {
      form.members.splice(index, 1);
    };

    const likesAllTags = (member: any) => {
      return member.tags.length === Tags.value.length;
    };

    const likesSomeTag = (member: any) => {
      return member.tags.length > 0;
    };

    const toggle = (member: any) => {
      if (likesAllTags(member)) {
        member.tags = [];
      } else {
        member.tags = Tags.value.slice();
      }
    };

    const addCustomTag = (member: any) => {
      if (customTag.value && !member.tags.includes(customTag.value)) {
        member.tags.push(customTag.value);
        customTag.value = "";
      }
    };

    const displayToast = (message: string, type: string) => {
      if (
        internalInstance &&
        internalInstance.appContext.config.globalProperties.$notify
      ) {
        internalInstance.appContext.config.globalProperties.$notify[type]({
          message,
        });
      }
    };

    const onSendInvitation = async () => {
      try {
        isShowLoadingSendInvitation.value = true;
        const processedForm = form.members.map((member: any) => {
          const processedMember = {
            ...member,
            tags: JSON.stringify(member.tags),
          };

          if (memberMessage.value) {
            processedMember.message = memberMessage.value;
          }

          return processedMember;
        });
        const response = await store.dispatch(`${MEMBER_STORE}/inviteMember`, {
          members: { members: processedForm },
          userId: user.value?.id,
        });
        if (response) {
          if (response.data.length > 0) {
            isShowInvitationsSentModal.value = true;
          } else {
            displayToast("Mail already Used", "success");
          }
        }
      } catch (err) {
        console.log();
      } finally {
        isShowLoadingSendInvitation.value = false;
      }
    };

    const onClickCloseButton = (isNewMemberAdded = false, event: any) => {
      ctx.emit("onClickCloseTab", isNewMemberAdded);
    };

    return {
      onClickCloseButton,
      validation,
      emailFormRef,
      form,
      onAddEmail,
      onSendInvitation,
      onRemoveEmail,
      selectedTags,
      Tags,
      toggle,
      likesAllTags,
      likesSomeTag,
      customTag,
      addCustomTag,
      isShowInvitationsSentModal,
      user,
      memberMessage,
      isShowLoadingSendInvitation,
    };
  },
};
</script>
