<template>
  <div class="commentbox__wrapper">
    <v-card
      class="commentbox__inner"
      variant="flat"
      :class="{ commentbox_active: isOpenComment }"
    >
      <template v-slot:text>
        <div class="commentbox__top" @click="isOpenComment = !isOpenComment">
          <div class="commentbox__left">
            <div class="commentbox__img">
              <img v-if="userWorkStationImage" :src="userWorkStationImage" />

              <UserProfileLogo v-else :userName="activeUserWorkstation?.name" />
            </div>
            <div class="msg__notification"></div>
            <div class="commentbox__heading">
              <h5>All Comments</h5>
            </div>
          </div>
          <div class="commentbox__right">
            <v-icon icon="mdi-chevron-down"></v-icon>
          </div>
        </div>
        <div class="commentbox__section">
          <div class="commentbox__message">
            <div
              class="commentbox__bottom"
              v-for="(commentRoom, index) in projectComments"
              :key="commentRoom?.id"
            >
              <div
                class="commentbox__bottom__chats"
                @click.stop="
                  openActiveComment(
                    commentRoom?.elementId,
                    commentRoom?.userComments[0].id
                  )
                "
              >
                <div class="commentbox__projects">
                  <div class="commentbox__projects__title">
                    <p>#{{ index + 1 }} - Project ID: #{{ projectId }}</p>
                    <v-icon
                      icon="mdi-check-circle-outline"
                      @click="resolveUserComments(commentRoom?.id)"
                      v-if="isShowResolve(commentRoom?.userWorkstation?.id)"
                    ></v-icon>
                  </div>
                </div>
                <div class="commentbox__bottom__chats__list">
                  <div class="commentbox__left">
                    <div class="commentbox__img">
                      <img
                        v-if="commentRoom?.userWorkstation?.profileImage"
                        :src="
                          getWorkStationImagePublicUrl(
                            commentRoom?.userWorkstation
                          )
                        "
                        alt=""
                      />
                      <UserProfileLogo
                        v-else
                        :userName="comment?.userWorkstation?.name"
                      />
                    </div>
                  </div>
                  <div class="commentbox__bottom__chats__lists">
                    <div class="commentbox__bottom__chats__list__item">
                      <div class="commentbox__center">
                        <div class="commentbox__heading">
                          <h6>
                            {{ commentRoom?.userWorkstation?.name }}
                          </h6>
                        </div>
                      </div>

                      <div class="commentbox__right">
                        <span>{{
                          timeCommentAgo(
                            commentRoom?.userComments[0]?.dateCreated
                          )
                        }}</span>
                      </div>
                    </div>
                    <div class="commentbox__bottom__chats__text">
                      <p>{{ commentRoom?.userComments[0]?.comment }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </v-card>
  </div>
</template>

<script lang="ts">
import { ref } from "vue";
import { UserCommentRoomStatus } from "../enums/CommentsEnum";
import { PROJECT_STORE } from "@/store/modules/project";
import { WORKSTATION } from "@/store/modules/workstation";
import { computed } from "vue";
import { useStore } from "vuex";
import { USER_STORE } from "@/store/modules/user";
import UserProfileLogo from "@/core/components/UserProfileLogo.vue";
import { UserRolesEnum } from "../enums/RolesEnum";

export default {
  name: "CommentsComponent",
  props: {
    projectComments: {
      type: Array,
      required: true,
    },
    activeComment: {
      type: Object,
      required: true,
      default: null,
    },
    projectId: {
      type: Number,
    },
    userImagesMap: {
      type: Object,
    },
  },
  components: {
    UserProfileLogo,
  },

  setup(props: any, ctx: any) {
    const chatRooms = ref([]);
    const isActive = ref(false);
    const isCreateChat = ref(false);
    const search = ref("");
    const isOpenComment = ref(false);
    const chatDashboard = ref(false);
    const store = useStore();
    const timeCommentAgo = (timestamp: any) => {
      const previous = new Date(timestamp) as any;
      const current = new Date() as any;

      const elapsed = current - previous;

      const seconds = Math.floor(elapsed / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);
      const weeks = Math.floor(days / 7);
      if (weeks > 0) {
        return weeks === 1 ? "1 week ago" : `${weeks} weeks ago`;
      } else if (days > 0) {
        return days === 1 ? "1 day ago" : `${days} days ago`;
      } else if (hours > 0) {
        return hours === 1 ? "1 hour ago" : `${hours} hours ago`;
      } else if (minutes > 0) {
        return minutes === 1 ? "1 minute ago" : `${minutes} minutes ago`;
      } else {
        return "Just now";
      }
    };

    const openActiveComment = (elementId: string, id: number) => {
      ctx.emit("toggleActiveComment", elementId);
    };
    const resolveUserComments = (commentRoomId: number) => {
      ctx.emit(
        "updateCommentRoomStatus",
        commentRoomId,
        UserCommentRoomStatus.RESOLVED
      );
    };

    const activeUserWorkstation = computed(
      () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
    );

    const getWorkStationImagePublicUrl = (message: any) => {
      if (!message || !message.id) return null;
      if (message && message?.id && props.userImagesMap[message?.id])
        return props.userImagesMap[message?.id];
    };

    const userWorkStationImage = computed(
      () => store.getters[`${USER_STORE}/userWorkStationImage`]
    );
    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const isShowResolve = (workstationId: number) => {
      if (user.value.role.name === UserRolesEnum.PROPERTY_OWNER) return true;
      return activeUserWorkstation.value.id === workstationId;
    };
    return {
      chatRooms,
      isActive,
      isCreateChat,
      search,
      isOpenComment,
      chatDashboard,
      timeCommentAgo,
      openActiveComment,
      resolveUserComments,
      activeUserWorkstation,
      getWorkStationImagePublicUrl,
      userWorkStationImage,
      isShowResolve,
    };
  },
};
</script>

<style lang="scss" scoped>
.commentbox__wrapper {
  position: fixed;
  right: 0;
  bottom: 0px;
  width: 320px;
  max-width: 100%;
  @media screen and (max-width: 1279px) {
    bottom: 72px;
  }
  @include respond(sm) {
    width: 100%;
  }
  .commentbox__inner {
    border-top-left-radius: 8px;
    padding: 12px 16px 0;

    cursor: pointer;
    :deep .v-card-text {
      padding: 0;
      .commentbox__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0 12px;
        border-bottom: 1px solid rgba($blueDark, 0.18);

        .commentbox__left {
          display: flex;
          gap: 8px;
          align-items: center;
          position: relative;
          .commentbox__img {
            width: 24px;
            height: 24px;
            position: relative;
            border-radius: 100%;
            overflow: hidden;
            // .tw-relative {
            //   width: 24px;
            //   height: 24px;
            //   position: relative;
            //   border-radius: 100%;
            //   overflow: hidden;
            //   :deep(.contractor-detail__img-wrapper) {
            //     width: 24px !important;
            //     height: 24px !important;
            //     position: relative !important;
            //     border-radius: 100% !important;
            //     overflow: hidden !important;
            //   }
            // }
            img {
              width: 100%;
              max-width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .msg__notification {
            width: 8px;
            height: 8px;
            background-color: #1fb27a;
            border: 1px solid #fff;
            border-radius: 100%;
            position: absolute;
            left: 15px;
            top: 0;
          }
          .commentbox__heading {
            h5 {
              @include fluidFont(14, 14, 1);
              font-weight: 700;
              letter-spacing: 0.25px;
              color: #0c0f4a;
            }
          }
        }
      }
      .commentbox__section {
        height: 0;
        transition: 0.5s all ease;
        .commentbox__message {
          .commentbox__center {
            padding: 16px 0;
            display: flex;
            gap: 16px;
            align-items: center;
            border-bottom: 1px solid #0c0f4a;
            .commentbox__search {
              .v-input__control {
                :deep .v-field {
                  border-radius: 9px;
                  .v-field__input {
                    padding: 0;
                    min-height: unset;
                  }
                  :deep .v-field__field {
                    label {
                      @include fluidFont(12, 12, 1);
                      color: rgba(142, 142, 147, 1);
                    }
                  }
                }
                .v-field--prepended {
                  padding: 0;
                  border-color: rgba(0, 0, 0, 0.04);
                  display: flex;
                  align-items: center;
                  height: 38px;
                  padding: 8px 16px;
                  gap: 8px;
                  border-radius: 9px;
                }
              }
            }
            .commentbox__edit {
              width: 36px;
              height: 35px;
              background: rgba(0, 0, 0, 0.04);
              border-radius: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              .v-icon {
                font-size: 16px;
                color: #0c0f4a;
              }
            }
          }
          .commentbox__bottom {
            .commentbox__bottom__chats {
              padding: 16px 0;
              border-bottom: 1px solid rgba($blueDark, 0.18);
              .commentbox__projects {
                margin-bottom: 8px;
                .commentbox__projects__title {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  p {
                    @include fluidFont(12, 12, 1);
                    font-weight: 700;
                    letter-spacing: 0.25px;
                    color: rgba($black, 0.54);
                  }
                  .v-icon {
                    @include fluidFont(16, 16, 1);
                    color: rgba($black, 0.54);
                  }
                }
              }
              .commentbox__bottom__chats__list {
                display: flex;
                gap: 8px;
                align-items: flex-start;
                .commentbox__left {
                  width: 24px;
                  max-width: 100%;
                  position: relative;
                  .commentbox__img {
                    width: 24px;
                    height: 24px;
                    border-radius: 100%;
                    overflow: hidden;
                    img {
                      width: 100%;
                      max-width: 100%;
                      height: 100%;
                      object-fit: cover;
                    }
                  }
                }
                .commentbox__bottom__chats__lists {
                  width: 100%;
                  max-width: 100%;
                  .commentbox__bottom__chats__list__item {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .commentbox__center {
                      padding: 0;
                      display: block;
                      text-align: left;
                      border: none;
                      .commentbox__heading {
                        h6 {
                          @include fluidFont(12, 12, 1);
                          font-weight: 700;
                          letter-spacing: 0.25px;
                          color: #0c0f4a;
                          max-width: 170px;
                          white-space: nowrap;
                          text-overflow: ellipsis;
                          overflow: hidden;
                        }
                      }
                    }
                  }
                  .commentbox__right {
                    span {
                      @include fluidFont(12, 12, 1);
                      font-weight: 700;
                      letter-spacing: 0.25px;
                      color: rgba(12, 15, 74, 0.54);
                    }
                  }
                }
              }
            }
          }
          .commentbox__bottom__chats__text {
            p {
              text-align: left;
              margin-left: auto;
              @include fluidFont(12, 12, 1.2);
              font-weight: 400;
              color: #0c0f4a;
              margin-top: 4px;
            }
            .commentbox__notification {
              font-size: 12px;
              background-color: rgba(38, 79, 213, 1);
              width: calc(100% - 214px);
              height: 20px;
              border-radius: 100%;
              color: #fff;
            }
          }
        }
      }
    }
  }
}

.commentbox__inner.commentbox_active .commentbox__section {
  height: calc(100dvh - 315px) !important;
  transition: 0.5s all ease;
  overflow-y: auto;
}

.commentbox__inner.commentbox_active {
  .commentbox__top {
    .commentbox__right {
      transform: rotate(180deg);
    }
  }
}
</style>
