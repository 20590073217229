import UserService from "@/core/services/user.service";
import { Commit, useStore } from "vuex";

import { UserWorkstationConstant } from "@/core/enums/RolesEnum";
import { getLocalStorageWithExpiry } from "@/core/utils/common";
import { AUTH_USER } from "@/core/constants";
import { userVerificationState } from "@/core/models/workstation";
import { USER_STORE } from "../user";
import { encryptModalData } from "@/core/helpers/workstation.helper";
import { root } from "postcss";

export default {
  getUserWorkstations({ commit }: { commit: Commit }, userId: number) {
    if (!userId) return;
    return UserService.getUserWorkstations(userId).then((response) => {
      commit("setUserWorkstations", response);
    });
  },
  getPropertyOwnerUserWorkstations(
    { commit }: { commit: Commit },
    userId: number
  ) {
    if (!userId) return;
    return UserService.getPropertyOwnerUserWorkstations(userId).then(
      (response) => {
        commit("setUserWorkstations", response);
      }
    );
  },
  async getUserWorkstationById(
    { commit }: any,
    params: { userId: number; userWorkstationId: number }
  ) {
    const { userId, userWorkstationId } = params;

    return UserService.getUserWorkstation(userId, userWorkstationId).then(
      (response) => {
        return Promise.resolve(response);
      }
    );
  },
  createUserWorkstation({ commit }: { commit: Commit }, payload: any) {
    const { userId, userWorkstation } = payload;

    return UserService.createUserWorkstation(userId, userWorkstation);
  },
  updateUserWorkstation({ commit }: { commit: Commit }, request: any) {
    const { userId, userWorkstationId, payload } = request;

    return UserService.updateUserWorkstation(
      userId,
      userWorkstationId,
      payload
    );
  },
  async setActiveUserWorkstation(
    {
      commit,
      dispatch,
      rootGetters,
    }: { commit: Commit; dispatch: any; rootGetters: any },
    userWorkstation = null
  ) {
    let value = userWorkstation as any;

    const authenticatedUserId =
      rootGetters[`${USER_STORE}/user`]?.id ||
      getLocalStorageWithExpiry(AUTH_USER)?.userId;
    const authenticatedUserRoleId =
      rootGetters[`${USER_STORE}/user`]?.role?.id ||
      getLocalStorageWithExpiry(AUTH_USER)?.roleId;
    if (
      !userWorkstation &&
      authenticatedUserId &&
      [3, 2].includes(authenticatedUserRoleId)
    ) {
      const userWorkstations: any = await (authenticatedUserRoleId === 3
        ? UserService.getUserWorkstations(authenticatedUserId)
        : UserService.getPropertyOwnerUserWorkstations(authenticatedUserId));

      commit("setUserWorkstations", userWorkstations);

      if (userWorkstations.length) {
        let foundActive: any = userWorkstations.filter(
          (item: any) => item.status === UserWorkstationConstant.ACTIVE
        );
        foundActive = foundActive.sort((a: any, b: any) => {
          return (
            new Date(b.dateUpdated).getTime() -
            new Date(a.dateUpdated).getTime()
          );
        });
        value = foundActive[0];
      }

      await dispatch(
        `${USER_STORE}/setUserWorkStationProfileImage`,
        value?.profileImage,
        {
          root: true,
        }
      );
      commit("setActiveUserWorkstation", value);
    }
  },
  updateUserWorkstationStatus({ commit }: { commit: Commit }, params: any) {
    const { userId, userWorkstationId, formData } = params;

    return UserService.updateUserWorkstationStatus(
      userId,
      userWorkstationId,
      formData
    ).then((response: any) => {
      commit("setActiveUserWorkstation", response);
    });
  },

  createUserWorkstationMembers(context: any, payload: any) {
    const { userId, members } = payload;
    return UserService.createUserWorkstationMembers(userId, {
      members,
    });
  },
  updateUserWorkstationMembers(context: any, payload: any) {
    const { id, userId, role } = payload;
    return UserService.updateUserWorkstationMember(userId, id, { role });
  },
  deleteUserWorkstationMember(
    { commit, getters }: { commit: Commit; getters: any },
    {
      userId,
      userWorkstationMemberId,
    }: { userId: number; userWorkstationMemberId: number }
  ) {
    return UserService.deleteUserWorkstationMember(
      userId,
      userWorkstationMemberId
    ).then((response) => {
      const { userWorkstationMembers } = getters;
      let currentList = userWorkstationMembers;

      if (response) {
        currentList = currentList.filter(
          (item: any) => item.id !== userWorkstationMemberId
        );
      }

      commit("setUserWorkstationMembers", currentList);
    });
  },
  acceptUserWorkstationMember(context: any, params: any) {
    const { userId, key } = params;
    return UserService.acceptUserWorkstationMember(userId, { key });
  },
  setUserWorkstationMembers({ commit }: { commit: Commit }, payload: any) {
    const { userId, keyword = "" } = payload;

    return UserService.getUserWorkstationMembers(
      userId,
      encodeURIComponent(keyword)
    ).then((response) => {
      commit("setUserWorkstationMembers", response);
    });
  },
  getMemberPermissions() {
    return UserService.getMemberPermissions();
  },
  async activateWorkstation(
    { commit, dispatch }: { commit: Commit; dispatch: any },
    params: { userId: number; userWorkstationId: number }
  ) {
    const { userId, userWorkstationId } = params;
    if (!userId || !userWorkstationId) return;
    await UserService.activateWorkstation(userId, userWorkstationId)
      .then(async (response: any) => {
        commit("setActiveUserWorkstation", response);
        await dispatch(
          `${USER_STORE}/setUserWorkStationProfileImage`,
          response?.profileImage,
          {
            root: true,
          }
        );
      })
      .catch((err) => {
        console.log();
      });
  },
  async getWorkstationVerificationData(
    { commit }: { commit: Commit },
    workstationId: number
  ) {
    if (!workstationId) return;
    return UserService.getWorkstationVerificationData(workstationId);
  },
  async saveWorkstationVerificationData(
    { commit }: { commit: Commit },
    payload: any
  ) {
    return UserService.setWorkstationVerificationData(payload);
  },
  async setWorkStationSiltVerificationStatus(
    { commit }: { commit: Commit },
    status: any
  ) {
    commit("setWorkStationSiltVerificationStatus", status);
  },

  async getWorkStationSiltVerificationStatus(
    { commit }: { commit: Commit },
    payload: userVerificationState
  ) {
    try {
      const response = await UserService.getUserVerificationStatus(payload);
      commit("setWorkStationSiltVerificationStatus", response);
      return response;
    } catch (error) {
      return null;
    }
  },
  async sendUserKYBProcessMail({ commit }: { commit: Commit }, payload: any) {
    return UserService.sendUserKYBProcessMail(payload).then((response) => {
      return response;
    });
  },
  async activateWorkstationDescription(
    { commit }: { commit: Commit },
    payload: any
  ) {
    try {
      const activateWorkstationDescription =
        await UserService.activateWorkstationDescription(
          payload?.userId,
          payload?.userWorkstationId,
          payload?.description
        );
      commit(
        "setActiveUserWorkstationDescription",
        activateWorkstationDescription
      );
    } catch (error) {
      return null;
    }
  },

  async setWsModal(
    { commit, getters }: { commit: Commit; getters: any },
    payload: any
  ) {
    commit("setWsModal", { ...getters.getWsModal, ...payload });
    encryptModalData({ ...getters.getWsModal, ...payload });
  },

  setLoadingWorkstation({ commit }: any, value: boolean) {
    commit("setLoadingWorkstation", value);
  },
  updateFreePlanWorkstation({ commit }: { commit: Commit }, request: any) {
    const { userId, userWorkstationId } = request;
    return UserService.updateFreePlanWorkstation(userId, userWorkstationId);
  },
};
