<template>
  <GeneralDialog headerTitle="Folder Upload" @on-close="onCloseModal">
    <template #body>
      <div class="tw-min-w-[400px] tw-max-w-[400px]">
        <v-form
          :rules="validation"
          ref="folderNameFormRef"
          class="tw-w-full tw-flex tw-gap-3 tw-items-center tw-relative tw-cursor-pointer tw-mb-4"
        >
          <div class="tw-absolute tw-left-0 tw-top-0">
            <img
              src="../../../../assets/icons/upload-icon.svg"
              alt="Upload Icon"
            />
          </div>

          <input
            type="file"
            webkitdirectory
            ref="fileUploadInputRef"
            @change="onChangeFileInput"
            class="tw-w-full tw-border tw-border-gray-300 tw-rounded folder_upload tw-pl-8"
          />
        </v-form>
        <div class="folder-progress" v-if="isLoading">
          <el-progress :stroke-width="5" :percentage="uploadPercentage" />
        </div>
      </div>
    </template>
    <template #footer>
      <div class="tw-w-full tw-grid tw-grid-cols-2 tw-items-center tw-gap-3">
        <Button
          :isLoading="isLoading"
          :disabled="form.disableButton"
          label="Save"
          :isActive="hasAttachments"
          @click="onSave"
        />
        <Button label="Cancel" variant="tertiary" @click="onCloseModal" />
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup>
import { computed, onMounted, ref, reactive } from "vue";
import { useStore } from "vuex";
import documentConstants from "@/modules/documents/contants";
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import { DOCUMENTS_STORE } from "@/store/modules/documents";
import { USER_STORE } from "@/store/modules/user";
import { PROPERTY_STORE } from "@/store/modules/property";

const emits = defineEmits(["on-close", "on-success"]);
const props = defineProps(["isPropertyDocument"]);

const store = useStore();
const isLoading = ref(false);
const fileUploadInputRef = ref(null);
const uploadPercentage = ref(0);
const form = reactive({
  attachments: [],
  disableButton: true,
  isReadyToSubmit: false,
  formValue: {},
  isSubmitting: false,
  isUploading: true,
});

const user = computed(() => store.getters[`${USER_STORE}/user`]);

const onCloseModal = () => {
  form.attachments = [];
  if (!isLoading.value) {
    emits("on-close");
  }
};
const openedFolder = computed(
  () => store.getters[`${DOCUMENTS_STORE}/openedFolder`]
);
const onSave = () => {
  form.disableButton = true;
  handleFolderUpload();
};
const propertyDetails = computed(
  () => store.getters[`${PROPERTY_STORE}/propertyDetails`]?.localData
);
const handleFolderUpload = async (event) => {
  try {
    const files = fileUploadInputRef.value.files;
    const fileStructure = {};
    uploadPercentage.value = 10;
    form.isUploading = false;
    isLoading.value = true;
    for (let file of files) {
      const pathParts = file.webkitRelativePath.split("/");
      let currentLevel = fileStructure;

      for (let i = 0; i < pathParts.length; i++) {
        const part = pathParts[i];
        if (i === pathParts.length - 1) {
          if (!currentLevel.files) {
            currentLevel.files = [];
          }
          currentLevel.files.push(file);
        } else {
          if (!currentLevel[part]) {
            currentLevel[part] = {};
          }
          currentLevel = currentLevel[part];
        }
      }
    }

    const { id } = openedFolder.value;
    const parentId = id || null;

    const createFolder = async (folderName, parentId) => {
      const params = {
        userId: user.value?.id,
        formData: {
          name: folderName,
          parentId,
          entity: "",
        },
      };

      if (props.isPropertyDocument) {
        params.formData.workStationId =
          propertyDetails.value?.userWorkstation?.id;
        params.userId = propertyDetails.value?.propertyCreator?.id;
      }

      const response = await store.dispatch(
        `${DOCUMENTS_STORE}/createUserDocument`,
        params
      );
      uploadPercentage.value = 30;

      if (response && response[0]?.id) {
        return response[0]?.id;
      } else {
        throw new Error("Failed to create folder");
      }
    };

    const uploadFiles = async (files) => {
      const uploadedFilesDetails = [];
      for (let file of files) {
        const formData = new FormData();
        formData.append("file", file);

        const uploadResponse = await store.dispatch(
          `${USER_STORE}/uploadProfileImage`,
          formData
        );

        if (uploadResponse) {
          uploadedFilesDetails.push(uploadResponse);
        }
      }
      uploadPercentage.value = 70;
      return uploadedFilesDetails;
    };

    const createDocumentWithAttachments = async (parentId, attachments) => {
      const params = {
        userId: user.value?.id,
        formData: {
          name: "--",
          parentId,
          attachments,
          entity: "",
        },
      };
      if (props.isPropertyDocument) {
        params.formData.workStationId =
          propertyDetails.value?.userWorkstation?.id;
        params.userId = propertyDetails.value?.propertyCreator?.id;
      }
      const response = await store.dispatch(
        `${DOCUMENTS_STORE}/createUserDocument`,
        params
      );
      uploadPercentage.value = 100;
    };

    const processFolder = async (folder, parentId) => {
      for (const key in folder) {
        if (key === "files") {
          const uploadedFilesDetails = await uploadFiles(folder[key]);

          await createDocumentWithAttachments(parentId, uploadedFilesDetails);
        } else {
          const newParentId = await createFolder(key, parentId);
          await processFolder(folder[key], newParentId);
        }
      }
    };

    await processFolder(fileStructure, parentId);
    setTimeout(() => {
      isLoading.value = false;
      onCloseModal();
      emits("on-success", documentConstants.FOLDER);
    }, 500);
  } catch (error) {
    console.log("Error processing folder upload:", error);
    isLoading.value = false;
    onCloseModal();
  }
};

const onChangeFileInput = (event) => {
  if (event?.target?.files?.length) {
    form.disableButton = false;
  }
};
onMounted(() => {
  form.attachments = [];
});
</script>
<style lang="scss" scoped>
::-webkit-file-upload-button {
  display: none;
}

.folder-progress .el-progress--line {
  width: 100%;
  max-width: 100%;
}

.folder-progress {
  width: 130px;
  max-width: 100%;
  border: 1px solid rgba($blueDark, 0.02);
  border-radius: 4px;
}

.el-progress {
  flex-direction: column-reverse;
  align-items: flex-end;
  :deep(.el-progress-bar) {
    width: 100%;
    max-width: 100%;
    .el-progress-bar__outer {
      .el-progress-bar__inner {
        background-color: rgba($orange, 1);
      }
    }
  }
  :deep(.el-progress__text) {
    margin-left: 0;
    min-width: auto;
    font-size: 10px !important;
    margin-bottom: 8px !important;
  }
}
</style>
