import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5eff5178"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "map-container" }
const _hoisted_2 = {
  key: 0,
  class: "loader-container"
}
const _hoisted_3 = {
  id: "map",
  class: "map",
  ref: "mapElement"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonLoader = _resolveComponent("CommonLoader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.IsLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_CommonLoader, {
            loading: $setup.IsLoading,
            class: "loader-container"
          }, null, 8, ["loading"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, null, 512)
  ]))
}