<template>
  <GeneralWidgetLayoutSlot
    class="!tw-min-h-[396px]"
    title="Projects Overview"
    buttonLabel="Manage Projects"
    :borderBottom="true"
    @on-action-click="onClickManageProject"
  >
    <template #content>
      <main class="tw-w-full tw-h-full tw-flex tw-justify-between tw-flex-col">
        <div
          class="tw-flex tw-items-center tw-justify-between tw-py-2 tw-px-4 tw-box-border"
          :class="{ 'tw-border-b-solid-custom': !item?.noBorder }"
          v-for="(item, index) in items"
          :key="index"
        >
          <div class="tw-flex tw-items-center tw-gap-2">
            <component :is="item.icon" />
            <Text variant="p" textWeight="500">{{ item.title }}</Text>
          </div>
          <Text class="!tw-text-[20px]" textWeight="600">{{ item.count }}</Text>
        </div>
      </main>
    </template>
  </GeneralWidgetLayoutSlot>
</template>
<script setup>
import { reactive } from "vue";
import { useRouter } from "vue-router";

import GeneralWidgetLayoutSlot from "@/core/components/slots/GeneralWidgetLayoutSlot.vue";
import Text from "@/core/components/ui/general/Text.vue";
import InProgressIcon from "@/core/components/icons/InProgressIcon.vue";
import EstimateReceivedIcon from "@/core/components/icons/EstimateReceivedIcon.vue";
import NotStartedIcon from "@/core/components/icons/NotStartedIcon.vue";
import DraftJobsIcon from "@/core/components/icons/DraftJobsIcon.vue";
import { PROJECT_ROUTE } from "@/modules/project/routes";

const inProgress = InProgressIcon;
const estimateReceived = EstimateReceivedIcon;
const notStarted = NotStartedIcon;
const draftJobs = DraftJobsIcon;

const router = useRouter();

const items = reactive([
  {
    icon: inProgress,
    title: "In progress",
    count: "0",
  },
  {
    icon: notStarted,
    title: "Issues Raised",
    count: "0",
  },
  {
    icon: estimateReceived,
    title: "Estimates received",
    count: "0",
  },
  {
    icon: notStarted,
    title: "Not Started",
    count: "0",
  },
  {
    icon: draftJobs,
    title: "Draft Projects",
    count: "0",
  },
  {
    icon: draftJobs,
    title: "Project Posted",
    count: "0",
    noBorder: true,
  },
]);

const onClickManageProject = () => {
  router.push({ name: PROJECT_ROUTE });
};
</script>
<style lang="scss" scoped></style>
