import { Commit } from "vuex";
import JobsService from "@/core/services/jobs.service";
import {
  JobDetails,
  JobFilters,
  ProjectDetails,
  PropertyDetails,
} from "@/core/models/jobs";
import historicalProjectService from "@/core/services/historical-project.service";

export default {
  async fetchJobs({ commit }: { commit: Commit }, payload: JobFilters) {
    return JobsService.fetchJobs(payload)
      .then((response) => {
        commit("setJobs", response?.data);
        return response;
      })
      .catch((error) => {
        console.log("🚀 ~ returnJobsService.fetchJobs ~ error:", error);
      });
  },
  async fetchJobDetails({ commit }: { commit: Commit }, refId: string) {
    try {
      const projectDetails = await JobsService.fetchProjectDetails(refId);
      // We need to query for jobDetails first as jobDetails
      // contains 'propertyId' for us to fetch another endpoint which
      // const propertyDetails = (await JobsService.fetchPropertyDetails(
      //   projectDetails.propertyId
      // ).catch((error) => {
      //   console.log("🚀 ~ fetchJobDetails ~ error:", error);
      // })) as unknown as PropertyDetails;

      // const jobDetails: JobDetails = {
      //   name: projectDetails.name,
      //   postcode: projectDetails.postcode,
      //   description: projectDetails.description,
      //   type: projectDetails.type,
      //   refId: projectDetails.refId,
      //   projectAttachments: projectDetails.projectAttachments,
      //   jobPoster: {
      //     firstName: projectDetails.projectCreator.firstName,
      //     lastName: projectDetails.projectCreator.lastName,
      //     userVerificationStatus:
      //       projectDetails.projectCreator.userVerificationStatus,
      //   },
      //   jobOverview: {
      //     specialisms: projectDetails.categories,
      //     datePosted: projectDetails.dateCreated,
      //     projectUrgency: projectDetails.type,
      //     associatedProperties: 0,
      //   },
      //   propertyDetails: {
      //     propertyCategory: propertyDetails.propertyCategory,
      //     postcode: propertyDetails.postcode,
      //     beds: propertyDetails.beds,
      //     propertySize: propertyDetails.propertySize,
      //     propertyType: propertyDetails.propertyType,
      //     energyRating: propertyDetails.energyRating,
      //   },
      // };

      commit("setJobDetails", projectDetails);
      return projectDetails;
    } catch (error) {
      console.log("🚀 ~ fetchJobDetails ~ error:", error);
    }
  },

  async fetchMyJobs({ commit }: { commit: Commit }, payload: JobFilters) {
    return JobsService.fetchActiveJobs(payload)
      .then((response) => {
        commit("setMyJobs", response);
        return response;
      })
      .catch((error) => {
        console.log("🚀 ~ returnJobsService.fetchActiveJobs ~ error:", error);
      });
  },
  async fetchDraftJobs({ commit }: { commit: Commit }, payload: JobFilters) {
    return JobsService.fetchDraftJobs(payload)
      .then((response) => {
        commit("setDraftJobs", response);
        return response;
      })
      .catch((error) => {
        console.log("🚀 ~ returnJobsService.fetchDraftJobs ~ error:", error);
      });
  },
  async fetchRejectedJobs({ commit }: { commit: Commit }, payload: JobFilters) {
    return JobsService.fetchRejectedJobs(payload)
      .then((response) => {
        commit("setRejectedJobs", response);
        return response;
      })
      .catch((error) => {
        console.log("🚀 ~ returnJobsService.fetchDraftJobs ~ error:", error);
      });
  },

  async fetchHistoricalJobs({ commit }: { commit: Commit }) {
    try {
      const response = await historicalProjectService.getHistoricalJobs();
      commit("setHistoricalJobs", response);
    } catch (error) {
      console.log();
    }
  },
  async fetchHistoricalProjects({ commit }: { commit: Commit }) {
    try {
      const response = await historicalProjectService.getHistoricalProjects();
      commit("setHistoricalJobs", response);
    } catch (error) {
      console.log();
    }
  },
  async createHistoricalJob({ commit }: { commit: Commit }, payload: any) {
    try {
      return await historicalProjectService.createHistoricalProject(payload);
    } catch (error) {
      console.log();
    }
  },
  async getHistoricalJobDetails({ commit }: { commit: Commit }, payload: any) {
    try {
      const { projectRefId } = payload;
      return await historicalProjectService.getHistoricalJobDetail(
        projectRefId
      );
    } catch (error) {
      console.log();
    }
  },

  async setActiveHistoricalJobs({ commit }: { commit: Commit }, payload: any) {
    commit("setActiveHistoricalJob", payload);
  },
  async getVideoConsultationRoomUrl(
    { commit }: { commit: Commit },
    payload: any
  ) {
    try {
      const { roomTitle, type } = payload;
      return JobsService.getVideoConsultationRoomUrl({
        ...payload,
      }).then((response) => {
        return response;
      });
    } catch (error: any) {
      console.log(error);
      return error?.response;
    }
  },

  async setVideoScheduleTime({ commit }: { commit: Commit }, payload: any) {
    commit("setVideoScheduleTime", payload);
  },

  setWorkFlowType({ commit }: { commit: Commit }, value: any) {
    commit("setWorkFlowType", value);
  },
};
