<template>
  <main
    class="tw-w-full tw-flex tw-flex-col tw-gap-4 md:!tw-pb-[3rem] md:!tw-box-border md:!tw-mt-[-1rem]"
    :class="{
      'tw-h-[calc(100%-2.3rem)]':
        !(fileList && fileList.length) && !(folderList && folderList.length),
    }"
  >
    <section class="tw-flex-row-between">
      <div class="tw-flex tw-items-center tw-gap-2">
        <ImageIcon />
        <DocumentsBreadcrumbs
          :titlePath="state.titlePath"
          @on-view-folder="onViewFolder($event, true)"
          :isGallery="true"
        />
      </div>
      <v-menu>
        <template v-slot:activator="{ props }">
          <Button
            v-if="getUserWorkStationFieldAccess"
            v-bind="props"
            variant="text"
            :isCapitalize="true"
            label="New"
            class="!tw-w-[auto]"
            :ripple="false"
          >
            <template #prefix>
              <PlusFilledYellowIcon />
            </template>
          </Button>
        </template>
        <AddDocumentMenu
          @on-handle-action="onHandleAction"
          :hideFolderUpload="true"
        />
      </v-menu>
    </section>

    <section
      class="tw-w-full tw-flex tw-flex-col tw-gap-6 tw-items-start"
      v-if="
        ((fileList && fileList.length) || (folderList && folderList.length)) &&
        !state.initializing
      "
    >
      <div
        v-if="folderList && folderList.length"
        class="tw-w-full tw-flex tw-flex-col tw-gap-4 tw-items-start"
      >
        <Text variant="p">Folders</Text>
        <div class="gallery-content-container tw-gap-4 tw-grid tw-w-full">
          <template v-for="(folder, index) in folderList">
            <GalleryFolder
              :getUserWorkStationFieldAccess="getUserWorkStationFieldAccess"
              v-if="folder.docType === 'gallery'"
              :key="index"
              :folder="folder"
              :selectedId="state.selectedId"
              @set-selected-id="setSelectedId"
              @on-view-folder="onViewFolder"
              @on-success="onSuccess"
            />
          </template>
        </div>
      </div>

      <div
        v-if="fileList && fileList.length"
        class="tw-w-full tw-flex tw-flex-col tw-gap-4 tw-items-start"
      >
        <Text variant="p">Photos</Text>
        <div class="gallery-content-container tw-gap-4 tw-grid tw-w-full">
          <template v-for="(file, index) in fileList" :key="index">
            <div
              class="gallery_item tw-relative"
              v-if="file.docType === 'gallery'"
            >
              <TrashCircularWhiteIcon
                v-if="getUserWorkStationFieldAccess"
                @click="onOpenDeleteConfirmationModal(file)"
                class="tw-absolute tw-right-3 tw-top-3 tw-z-50 tw-cursor-pointer"
              />
              <div
                @click="onOpenPhotoPreviewModal(file)"
                class="tw-flex tw-items-center tw-justify-center tw-min-h-[160px] tw-max-h-[160px] tw-overflow-hidden tw-rounded-lg tw-relative tw-bg-slate-200"
              >
                <v-icon
                  @click="onOpenVideo(file)"
                  v-if="file.fileType === 'video/mp4'"
                  icon="mdi-play-circle"
                  color="#264FD5"
                  class="tw-absolute tw-right-[45%] tw-top-[45%] tw-z-50 tw-cursor-pointer"
                ></v-icon>

                <imgfileList
                  v-if="file.attachmentSource && file.fileType === 'video/mp4'"
                  class="tw-cursor-pointer"
                  :src="require(`@/assets/images/iknowa-thumbnail.png`)"
                />
                <v-img
                  v-else-if="
                    file.attachmentSource && file.fileType !== 'video/mp4'
                  "
                  class="tw-w-full tw-h-full tw-cursor-pointer"
                  :class="{
                    'tw-hidden':
                      file?.fileType !== 'image/jpeg' &&
                      file?.fileType !== 'image/png',
                  }"
                  aspect-ratio="16/9"
                  cover
                  :src="file?.attachmentSource"
                ></v-img>
                <v-img
                  v-else
                  class="tw-cursor-pointer"
                  :class="{
                    'tw-hidden':
                      file?.fileType !== 'image/jpeg' &&
                      file?.fileType !== 'image/png',
                  }"
                  :width="60"
                  :height="60"
                  :src="require(`@/assets/images/project-placeholder.png`)"
                ></v-img>
              </div>
            </div>
          </template>
        </div>
      </div>
    </section>
    <section
      v-if="state.initializing"
      class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-full tw-w-full"
    >
      <Loader :show="state.initializing" />
    </section>

    <section
      class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-2 tw-w-full tw-h-full"
      v-if="
        !fileList &&
        !fileList.length &&
        !folderList &&
        !folderList.length &&
        !state.initializing
      "
    >
      <img :src="require('@/assets/images/gallery-empty.png')" alt="icon" />
      <Text variants="p" textWeight="400" textColor="rgba(12, 15, 74, 0.5)"
        >Your photos will appear here</Text
      >
    </section>

    <v-alert
      v-if="state.alert"
      class="tw-absolute tw-bottom-[6rem] tw-right-0"
      transition="slide-y-transition"
      :title="state.alertConfig.title"
      :text="state.alertConfig.text"
      :type="state.alertType === 'success' ? state.alertType : 'error'"
    >
    </v-alert>

    <!-- MODALS -->
    <CreateNewFolderModal
      v-if="modals.newFolderModal"
      @on-close="onCloseNewFolderModal"
      @on-success="onUploadSuccess"
      :isGallery="true"
    />
    <FileUploadModal
      v-if="modals.fileUploadModal"
      @on-close="onCloseFileUploadModal"
      @on-success="onUploadSuccess"
      @on-success-gallery="getList($event, true)"
      :isGallery="true"
    />
    <FolderUploadModal
      v-if="modals.addFolderModal"
      @on-close="onCloseAddFolderModal"
      @on-success="onUploadSuccess"
    />

    <DocumentDeleteConfirmationModal
      v-if="modals.deleteConfirmation"
      @on-close="onCloseDeleteConfirmationModal"
      :selectedDocument="state.selectedDocument"
      deleteType="files"
      :isGallery="true"
      @on-success="onSuccess('delete')"
      @on-success-gallery="getList($event, false, state.selectedDocument?.id)"
    />

    <GalleryPhotoPreview
      v-if="modals.photoPreview"
      :file="state.selectedDocument"
      @on-close="onClosePhotoPreviewModal"
    />
  </main>
</template>
<script setup>
import { computed, reactive, ref, onMounted, onBeforeMount, watch } from "vue";
import { useStore } from "vuex";

import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import ImageIcon from "@/core/components/icons/ImageIcon.vue";
import PlusFilledYellowIcon from "@/core/components/icons/PlusFilledYellowIcon.vue";
import AddDocumentMenu from "@/modules/documents/components/AddDocumentMenu.vue";
import GalleryFolder from "@/modules/trade-passport/components/cards/GalleryFolder.vue";
import CreateNewFolderModal from "@/modules/documents/components/modal/CreateNewFolderModal.vue";
import FileUploadModal from "@/modules/documents/components/modal/FileUploadModal.vue";
import FolderUploadModal from "@/modules/documents/components/modal/FolderUploadModal.vue";
import DocumentsBreadcrumbs from "@/modules/documents/components/DocumentsBreadcrumbs.vue";
import { WORKSTATION } from "@/store/modules/workstation";
import { DOCUMENTS_STORE } from "@/store/modules/documents";
import { USER_STORE } from "@/store/modules/user";
import documentConstants from "@/modules/documents/contants";
import $axios from "@/core/utils/axios-api-config";
import TrashCircularWhiteIcon from "@/core/components/icons/TrashCircularWhiteIcon.vue";
import { getImageApiUrl } from "@/core/utils/common";
import Loader from "@/core/components/common/Loader.vue";
import DocumentDeleteConfirmationModal from "@/modules/documents/components/modal/DocumentDeleteConfirmationModal.vue";
import GalleryPhotoPreview from "@/modules/trade-passport/components/modals/GalleryPhotoPreview.vue";
import { useRoute } from "vue-router";
import { UserRolesEnum } from "../../../core/enums/RolesEnum";
import { TRADE_ESTIMATE_STORE } from "@/store/modules/trade-estimate";

const store = useStore();
const route = useRoute();
const isLoading = ref(false);
const emit = defineEmits(["onChangeGallery"]);
const modals = reactive({
  newFolderModal: false,
  addFolderModal: false,
  fileUploadModal: false,
  deleteConfirmation: false,
  photoPreview: false,
});
const state = reactive({
  isInMainPage: true,

  initializing: false,
  defaultTitlePath: {
    name: "Gallery",
  },
  titlePath: [],
  fileTitle: [{ name: "Files" }],
  currentFolderList: [],
  currentFileList: [],
  accumlatedSize: "",

  alert: false,
  alertType: "",
  actionType: "",
  alertConfig: {
    text: "",
    title: "",
  },

  documentStatus: null,

  selectedId: null,

  selectedDocument: null,
});

const user = computed(() => store.getters[`${USER_STORE}/user`]);
const getUserWorkStationFieldAccess = computed(
  () => store.getters[`${WORKSTATION}/getUserWorkStationFieldAccess`]
);
const fileList = computed(() => store.getters[`${DOCUMENTS_STORE}/fileList`]);
const folderList = computed(
  () => store.getters[`${DOCUMENTS_STORE}/folderList`]
);

const onOpenNewFolderModal = () => {
  modals.newFolderModal = true;
};

const onCloseNewFolderModal = () => {
  modals.newFolderModal = false;
};

const onOpenAddFolderModal = () => {
  modals.addFolderModal = true;
};

const onCloseAddFolderModal = () => {
  modals.addFolderModal = false;
};

const onOpenFileUploadModal = () => {
  modals.fileUploadModal = true;
};

const onCloseFileUploadModal = () => {
  modals.fileUploadModal = false;
};

const onOpenDeleteConfirmationModal = (document) => {
  state.selectedDocument = document;
  modals.deleteConfirmation = true;
};

const onCloseDeleteConfirmationModal = () => {
  state.selectedDocument = null;
  modals.deleteConfirmation = false;
};

const onOpenPhotoPreviewModal = (file) => {
  if (file?.fileType === "video/mp4") return;
  state.selectedDocument = file;
  modals.photoPreview = true;
};

const onClosePhotoPreviewModal = () => {
  state.selectedDocument = null;
  modals.photoPreview = false;
};

const onHandleAction = (name) => {
  if (name === documentConstants.NEW_FOLDER) {
    onOpenNewFolderModal();
  }
  if (name === documentConstants.FOLDER) {
    onOpenAddFolderModal();
  }
  if (name === documentConstants.FILE) {
    onOpenFileUploadModal();
  }
};

const hideAlert = (event) => {
  setTimeout(() => {
    state.alert = false;
  }, 3000);
};

const setSelectedId = (event) => {
  state.selectedId = event;
};

const setFolderList = async (params) => {
  await store.dispatch(`${DOCUMENTS_STORE}/setFolderList`, params);
};
const setFileList = async (params) => {
  await store.dispatch(`${DOCUMENTS_STORE}/setFileList`, params);
};

const setLoaderFalse = () => {
  setTimeout(() => {
    state.initializing = false;
  }, 1000);
};

const onUploadSuccess = (event) => {
  // on upload success
  state.alertType = "success";
  state.actionType = event;
  state.alert = true;
  state.alertConfig = {
    text:
      state.actionType === documentConstants.FILE
        ? "File uploaded successfully"
        : "Folder created successfully",
    title: state.actionType === documentConstants.FILE ? "Uploaded" : "Created",
  };
  emit("onChangeGallery");
};

const onSuccess = async (event) => {
  state.alertType = "success";
  state.actionType = event;
  state.alert = true;
  state.alertConfig = {
    text:
      state.actionType === "edit"
        ? "Edited Successfully"
        : "Deleted Successfully",
    title: "Successful",
  };
  emit("onChangeGallery");
};

const isUserProprtyOwner = computed(
  () => user.value?.role?.name === UserRolesEnum.PROPERTY_OWNER
);
const contractorTradePassportWorkstationId = computed(
  () =>
    store.getters[
      `${TRADE_ESTIMATE_STORE}/getContractorTradePassportWorkstationId`
    ]
);

const getList = async (options = {}, reload = true, id = null) => {
  console.log("trigered get list", options);
  if (reload) {
    state.initializing = true;
  }
  const { id: userId } = user.value;
  const params = { userId };
  const { parentId } = options;
  const hasParentParam = parentId || null;

  if (Object.keys(options).length) {
    params.options = options;
  }

  try {
    // if (!id || id === null) {
    let userDocuments;
    if (isUserProprtyOwner.value) {
      userDocuments = await store.dispatch(
        `${DOCUMENTS_STORE}/getContractorDocuments`,
        {
          workStationId: contractorTradePassportWorkstationId.value,
          userId: user.value.id,
          params,
        }
      );
    } else {
      userDocuments = await store.dispatch(
        `${DOCUMENTS_STORE}/getUserDocuments`,
        params
      );
    }

    state.currentFolderList = userDocuments.filter(
      (userDocument) =>
        userDocument.isDir === true && userDocument.parentId === hasParentParam
    );

    state.currentFileList = userDocuments.filter(
      (userDocument) =>
        !userDocument.isDir && userDocument.parentId === hasParentParam
    );

    state.currentFileList = state.currentFileList.map((file) => {
      return { ...file, name: file.originalName, attachment: file.name };
    });

    const attachmentPromises = state.currentFileList.map(
      async (currentFile) => {
        const { attachment } = currentFile;
        const imageUrl = getImageApiUrl(attachment, true);
        const encoded = await $axios.get(imageUrl);
        const publicURL = encoded?.publicUrl;
        return { ...currentFile, attachmentSource: publicURL };
      }
    );

    // Wait for all attachment promises to resolve
    state.currentFileList = await Promise.all(attachmentPromises);

    setFolderList(state.currentFolderList);
    setFileList(state.currentFileList);
    // } else {
    //   if (fileList.value.length) {
    //     console.log(fileList.value, "filelist 1");
    //     fileList.value = fileList.value.filter((file) => file.id !== id);
    //     setFileList(fileList.value);
    //     console.log(fileList.value, "filelist");
    //   }
    // }
  } catch (e) {
    const formError = "Error getting list. Please try again.";
    // Note: Add notification logic
    console.log(formError);
  } finally {
    setLoaderFalse();
    console.log(state.currentFileList, "file list");
  }
};

const onOpenVideo = (file) => {
  // Check if the file has an attachmentSource property
  if (file && file.attachmentSource) {
    // Open the video in a new tab
    window.open(file.attachmentSource, "_blank");
  } else {
    console.error("Invalid file or missing attachmentSource");
  }
};
const onViewFolder = async (folder = {}, isBreadcrumbClick = false) => {
  const { id } = folder;
  let params = {};

  if ("id" in folder) {
    params = { parentId: id };
  }

  await store.dispatch(`${DOCUMENTS_STORE}/setOpenedFolder`, folder);

  await getList(params).then(() => {
    if (id) {
      if (isBreadcrumbClick) {
        const foundIndex = state.titlePath.findIndex((item) => item.id === id);

        if (foundIndex !== -1) {
          state.titlePath.splice(foundIndex + 1);
        }
      } else {
        state.titlePath.push(folder);
      }
    } else {
      resetTitlePath();
    }
  });
};

const resetTitlePath = () => {
  state.titlePath = [state.defaultTitlePath];
};

const checkSuccessfulTransaction = async () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const { id: userId } = user.value;

  if (params.session_id) {
    let url = `/payment/${userId}/session?sessionId=${params.session_id}`;

    if (params.is_document) {
      url = `${url}&isDocument=${params.is_document}`;
    }

    await $axios.get(url).then(async (sessionResponse) => {
      if (sessionResponse) {
        // prompt success
      }
    });
  }
};

watch(
  () => state.alert,
  (newValue, oldValue) => {
    if (newValue) {
      hideAlert();
    }
  },
  { deep: true, immediate: true }
);

onBeforeMount(async () => {
  setFolderList([]);
  setFileList([]);

  await store.dispatch(`${DOCUMENTS_STORE}/setIsPropertyDocument`, false);

  await getList();
  resetTitlePath();

  await store.dispatch(`${USER_STORE}/initializeUserState`);

  // check successful transaction
  // await checkSuccessfulTransaction();
});

onMounted(async () => {
  state.alert = false;
});
</script>
<style lang="scss" scoped>
.gallery-content-container {
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
}
</style>
