import { TradeNetworkState } from "@/core/models/trade-network";

export default {
  getTradeNetworkContractorList: (state: TradeNetworkState) =>
    state.tradeNetworkContractorList,
  getUserNetworkContractorList: (state: TradeNetworkState) =>
    state.userNetworkContractorList,
  getUserNetworkConnectedList: (state: TradeNetworkState) =>
    state.userNetworkConnectedList,
  getFilterState: (state: any) => state.isClearFilter,
  getExternalInvitedContractors: (state: TradeNetworkState) =>
    state.externalInvitedContractorList,
};
