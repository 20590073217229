<template>
  <div
    class="loader"
    v-show="show"
    style="
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    "
  >
    <svg viewBox="0 0 120 120" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <circle class="load one" cx="60" cy="60" r="40" />
      <circle class="load two" cx="60" cy="60" r="40" />
      <circle class="load three" cx="60" cy="60" r="40" />
    </svg>

    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="119.221"
      height="125.613"
      viewBox="0 0 119.221 125.613"
      style="position: relative; top: -135px"
    >
      <g
        id="Litlle_Loading_Animation"
        data-name="Litlle Loading Animation"
        transform="translate(-346.32 -196.068)"
      >
        <g
          id="iknowa_logo"
          data-name="iknowa logo"
          transform="translate(-26.879 -212.879)"
        >
          <circle
            id="BG"
            cx="27"
            cy="27"
            r="26"
            transform="translate(405.879 447.879)"
            fill="#2a65e8"
          />
          <g
            id="IKNOWA_logo_svg"
            data-name="IKNOWA logo svg"
            transform="translate(420.379 459.545)"
          >
            <path
              id="Shape"
              d="M254.1,407.156a2.611,2.611,0,1,1-.2-1.04A2.61,2.61,0,0,1,254.1,407.156Zm0,24.258h-5.335V412.159h5.3Z"
              transform="translate(-248.683 -404.446)"
              fill="#fff"
            />
            <path
              id="Path"
              d="M316.482,465.963h-5.157v-2.372a4.687,4.687,0,0,0-1.27-3.276,3.837,3.837,0,0,0-3.064-1.35h-.625v7h-5.3V446.748h5.3v7.136h.625a3.748,3.748,0,0,0,2.856-1.171,4.767,4.767,0,0,0,1.478-3.008v-2.977h5.167v2.5a9.423,9.423,0,0,1-3.183,7.146,9.453,9.453,0,0,1,3.164,7.216V464.8Z"
              transform="translate(-291.491 -438.996)"
              fill="#fff"
            />
            <circle
              id="Oval"
              cx="2.718"
              cy="2.718"
              r="2.718"
              transform="translate(0)"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "loader",
  props: {
    show: {
      type: Boolean,
    },
  },
});
</script>
<style lang="scss" scoped>
$color1: #c3d7f8;
$color2: #c3d7f8;
$color3: #6fa5ff;

.loader {
  width: 150px;
  height: 150px;
}
svg {
  width: 90%;
  fill: none;
}

.load {
  transform-origin: 50% 50%;
  stroke-dasharray: 570;
  stroke-width: 20px;
  &.one {
    stroke: $color3;
    animation: load 1.5s infinite;
  }
  &.two {
    stroke: $color2;
    animation: load 1.5s infinite;
    animation-delay: 0.1s;
  }
  &.three {
    stroke: $color1;
    animation: load 1.5s infinite;
    animation-delay: 0.2s;
  }
}

.point {
  animation: bounce 1s infinite ease-in-out;
  &.one {
    fill: $color1;
    animation-delay: 0s;
  }
  &.two {
    fill: $color2;
    animation-delay: 0.1s;
  }
  &.three {
    fill: $color3;
    animation-delay: 0.2s;
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}
@keyframes load {
  0% {
    stroke-dashoffset: 570;
  }
  50% {
    stroke-dashoffset: 530;
  }
  100% {
    stroke-dashoffset: 570;
    transform: rotate(360deg);
  }
}
</style>
