<template>
  <InfoCardLayoutSlot
    size="lg"
    :removeBorderBottom="true"
    :hasFooter="true"
    title="Property Logs"
    bgColor="#F1F6FB"
  >
    <template #title-icon>
      <ToolTip description="Property Logs" />
    </template>
    <template #content>
      <section
        class="tw-h-full tw-gap-4 tw-overflow-auto tw-flex-col tw-justify-between tw-flex"
      >
        <div class="card-container tw-grid tw-gap-2">
          <Card
            class="!tw-min-h-[90px] !tw-max-h-[90px] tw-w-full"
            v-for="(item, index) in cardData"
            :key="index"
          >
            <template #content>
              <div
                class="tw-w-full tw-h-full tw-flex tw-flex-col tw-justify-between tw-gap-1"
              >
                <div
                  class="tw-w-full tw-h-full tw-px-3 tw-pt-3 tw-box-border tw-flex tw-items-center tw-gap-1"
                >
                  <ChipCounter :bgColor="item.color" :value="item.value" />
                  <Text
                    class="tw-w-[50%]"
                    variant="p"
                    whiteSpace="wrap"
                    textAlign="left"
                    lineHeight="18px"
                    >{{ item.label }}</Text
                  >
                </div>
                <div class="tw-w-full tw-h-full">
                  <!-- <div
                    v-if="item.hasFooter"
                    class="tw-w-full tw-h-full tw-border-t-dashed-custom tw-flex tw-items-center tw-justify-between tw-px-3 tw-box-border"
                  >
                    <Text
                      variant="span"
                      textWeight="400"
                      textColor="rgba(12, 15, 74, 0.5)"
                      >This month</Text
                    >
                    <Text variant="span" textWeight="400" textColor="#FFA500"
                      >+5%</Text
                    >
                  </div> -->
                </div>
              </div>
            </template>
          </Card>
        </div>
        <div class="tw-w-full tw-h-full tw-flex tw-flex-col tw-items-start">
          <Text variant="p">Timeline</Text>
          <div
            v-if="!timeLineData.length"
            class="tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center"
          >
            <Text variant="span">Timeline empty</Text>
          </div>
          <v-timeline
            v-if="timeLineData && timeLineData.length"
            side="end"
            density="compact"
            truncate-line="start"
            align="start"
          >
            <template v-for="(timeline, index) in timeLineData">
              <v-timeline-item
                dot-color="#FFF"
                fill-dot
                v-if="index < 4"
                :key="index"
              >
                <template v-slot:icon>
                  <img
                    :src="require(`@/assets/icons/kyp-icons/${timeline?.icon}`)"
                  />
                </template>
                <div class="tw-flex tw-flex-col tw-items-start">
                  <Text variant="p" lineHeight="20px"
                    >{{ timeline.title }}
                    <Text
                      variant="p"
                      textColor="rgba(12, 15, 74, 0.54)"
                      textWeight="400"
                      whiteSpace="wrap"
                      lineHeight="20px"
                      >({{ timeline.status }})</Text
                    ></Text
                  >
                  <Text
                    variant="span"
                    textWeight="400"
                    textColor="rgba(12, 15, 74, 0.54)"
                    lineHeight="20px"
                    >{{ timeline.date }}</Text
                  >
                </div>
              </v-timeline-item>
            </template>
          </v-timeline>
          <Text
            class="tw-pl-6"
            variant="span"
            textColor="#FFA500"
            v-if="timeLineData.length > 4"
            >{{ `+${timeLineData.length - 4} More` }}</Text
          >
        </div>
      </section>
    </template>
    <template #footer-content>
      <section class="tw-w-full tw-flex tw-items-center tw-justify-start">
        <Button
          class="!tw-w-auto !tw-capitalize"
          label="View Project"
          fontSize="12px"
          @click="routeToProjectLogs"
        />
      </section>
    </template>
  </InfoCardLayoutSlot>
</template>
<script setup>
import { ref } from "vue";

import Text from "@/core/components/ui/general/Text.vue";
import Card from "@/core/components/ui/general/Card.vue";
import Button from "@/core/components/ui/general/Button.vue";
import ChipCounter from "@/core/components/common/ChipCounter.vue";
import InfoCardLayoutSlot from "@/core/components/slots/InfoCardLayoutSlot.vue";
import ToolTip from "@/core/components/common/ToolTip.vue";

const emits = defineEmits(["route-to-project-logs"]);

// const timeLineData = ref([
//   {
//     title: "Solar PV installation",
//     status: "iknowa verified",
//     date: "28/08/2024",
//     icon: "solar-pv-icon.svg",
//   },
// ]);

const timeLineData = ref([]);

const cardData = ref([
  {
    value: "0",
    label: "iknowa Verified",
    color: "#1FB27A",
    hasFooter: false,
  },
  {
    value: "0",
    label: "Trade Verified",
    color: "#7467B7",
    hasFooter: false,
  },
  {
    value: "0",
    label: "DIY Projects",
    color: "#FAA500",
    hasFooter: true,
  },
  {
    value: "0",
    label: "In Progress",
    color: "#4A92E5",
    hasFooter: false,
  },
]);

const routeToProjectLogs = () => {
  emits("route-to-project-logs");
};
</script>
<style lang="scss" scoped>
.card-container {
  grid-template-columns: repeat(auto-fit, minmax(136px, 1fr));

  @media screen and (min-width: 1880px) {
    grid-template-columns: repeat(auto-fit, minmax(208px, 1fr));
  }

  @media screen and (min-width: 2528px) {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}
</style>
