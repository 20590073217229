<template>
  <div v-if="isLoading" class="issue_loader">
    <Loader :show="isLoading" />
  </div>
  <div class="active_issues" v-if="!createdIssueId && !isLoading">
    <div class="active_issues__heading">
      <h4>Issues</h4>
      <div class="active_issues__selector">
        <v-select
          class="custom-select filter input_field"
          density="compact"
          variant="outlined"
          clearable
          placeholder="Issue Type"
          v-model="filters.selectedType"
          :items="issueType"
          item-title="text"
          item-value="value"
          :menu-props="{
            contentClass: 'icon__text__select icon__text__select_smaller',
          }"
        ></v-select>
        <v-select
          class="custom-select status input_field"
          density="compact"
          variant="outlined"
          clearable
          placeholder="Issue Status"
          v-model="filters.selectedStatus"
          :items="issueStatus"
          item-title="text"
          item-value="value"
          :menu-props="{
            contentClass: 'icon__text__select icon__text__select_smaller',
          }"
        ></v-select>
      </div>
    </div>
    <div class="custom__cards">
      <div class="custom__card__list" v-if="allIssue?.length">
        <v-card class="custom__card" v-for="issue in allIssue" :key="issue.id">
          <div class="custom__card__header">
            <div class="custom__card__header__left">
              <div
                class="emergency-out-of-hours"
                v-if="issue.issueType === 'emergency_out_of_hours'"
              >
                <div class="status-icon">
                  <img
                    src="@/assets/icons/status-out-of-hours.svg"
                    alt="Out Of Hours"
                  />
                </div>
                <p>Out Of Hours</p>
              </div>

              <div class="standard" v-if="issue.issueType === 'standard'">
                <div class="status-icon">
                  <img
                    src="@/assets/icons/status-standard-icon.svg"
                    alt="Standard"
                  />
                </div>
                <p>Standard</p>
              </div>

              <div class="emergency" v-if="issue.issueType === 'emergency'">
                <div class="status-icon">
                  <img
                    src="@/assets/icons/status-emergency.svg"
                    alt="Emergency"
                  />
                </div>
                <p>Emergency</p>
              </div>

              <!-- <div
                :class="{
                  standard: issue.issueType === 'standard',
                  emergency: issue.issueType === 'emergency',
                }"
              >
                <v-icon icon=" mdi-checkbox-marked-circle" />
                <p>{{ issue.issueType }}</p>
              </div> -->
            </div>
            <div class="custom__card__header__right">
              <div
                :class="{
                  completed: issue.issueStatus === 'completed',
                  draft: issue.issueStatus === 'draft',
                  raised: issue.issueStatus === 'raised',
                  inprogress: issue.issueStatus === 'inprogress',
                }"
              >
                <v-icon icon=" mdi-checkbox-marked-circle" />
                <p>
                  {{ getIssueStatus(issue.issueStatus) }}
                </p>
              </div>
            </div>
          </div>
          <div class="custom__card__content">
            <div class="custom__card__content__header">
              <div class="custom__card__content__title">
                <h5>{{ issue.name }}</h5>
                <span>Issue ID: #{{ issue?.refId }}</span>
              </div>
              <div class="custom__card__content__time" v-if="issue.SLATime">
                <div class="time_icon">
                  <img src="../../../assets/icons/bell-icon.svg" alt="" />
                </div>
                <div class="time_text">
                  <p v-if="issue.slaTime?.hours && issue.slaTime?.minutes">
                    <span
                      >{{ issue.slaTime?.hours }} Hours
                      {{ issue.slaTime?.minutes }}</span
                    >Mins Left
                  </p>
                  <p v-if="!issue.slaTime?.hours && issue.slaTime?.minutes">
                    <span>{{ issue.slaTime?.minutes }}</span
                    >Mins Left
                  </p>
                  <p v-if="issue.slaTime?.hours && !issue.slaTime?.minutes">
                    <span>{{ issue.slaTime?.hours }}</span
                    >Hours Left
                  </p>
                </div>
              </div>
            </div>
            <div class="custom__card__content__description">
              <p v-html="issue.description"></p>
            </div>
          </div>
          <div class="custom__card__footer">
            <div class="custom__card__footer__left">
              <span class="card__date">{{
                getCreatedDate(issue.dateCreated)
              }}</span>
            </div>
            <div
              class="custom__card__footer__right"
              @click="onClickDetails(issue?.id)"
            >
              <label class="bright-blue-text">
                Details<v-icon icon="mdi-chevron-right" />
              </label>
            </div>
          </div>
        </v-card>
      </div>
      <div v-else>
        <div class="empty__content">
          <div class="empty__banner">
            <div class="empty__banner__content text-center">
              <h4>You haven’t raised any issues yet.</h4>
              <p>Raise your next issue at lightning speed.</p>
            </div>
            <div class="empty__banner__img">
              <img src="../../../assets/images/empty-issue.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <IssueReviewComponent
    v-if="createdIssueId"
    :isShowDeleteButton="true"
    :createdIssueId="createdIssueId"
    @onClickBackButton="onClickBackButtonFromReview"
    @goToListIssuesTab="goToListIssuesTab"
  />
  <PageRestrictionNoticeModal
    v-if="isShowWorkstationRestrictionModal"
    @on-close="toggleRestrictionModal"
  />
</template>
<script lang="ts">
import { onBeforeMount } from "vue";
import RaiseIssueService from "@/core/services/raise-issue.service";
import moment from "moment";
import IssueReviewComponent from "@/modules/issues/components/IssueReviewComponent.vue";
import {
  PropertyIssueType,
  PropertyIssueStatus,
} from "@/core/enums/RaiseIssueEnum";
import { ref, computed } from "vue";
import { watch } from "vue";
import { WORKSTATION } from "@/store/modules/workstation";
import { useStore } from "vuex";
import PageRestrictionNoticeModal from "@/core/components/modals/PageRestrictionNoticeModal.vue";
import Loader from "@/core/components/common/Loader.vue";

export default {
  name: "ActiveIssuesComponent",
  components: {
    IssueReviewComponent,
    PageRestrictionNoticeModal,
    Loader,
  },
  setup(props: any, ctx: any) {
    const store = useStore();
    const allIssue = ref([]) as any;
    const createdIssueId = ref(null) as any;
    const isShowWorkstationRestrictionModal = ref(false);

    const issueType = ref([
      {
        text: "Standard",
        value: PropertyIssueType.STANDARD,
      },
      { text: "Emergency", value: PropertyIssueType.EMERGENCY },
      {
        text: "Out Of Hours",
        value: PropertyIssueType.EMERGENCY_OUT_OF_HOURS,
      },
    ]);

    const issueStatus = ref([
      { text: "Draft", value: PropertyIssueStatus.DRAFT },
      { text: "Closed", value: PropertyIssueStatus.CLOSED },
      { text: "In Progress", value: PropertyIssueStatus.IN_PROGRESS },
      { text: "Raised", value: PropertyIssueStatus.RAISED },
    ]);
    const filters = ref({
      selectedType: null,
      selectedStatus: null,
    }) as any;

    const isLoading = ref(false);

    const onClickDetails = (issueId: number) => {
      if (isPersonalAccountWorkStation.value) {
        toggleRestrictionModal();
      } else {
        createdIssueId.value = issueId;
      }
    };

    const onClickBackButtonFromReview = () => {
      createdIssueId.value = null;
    };

    const goToListIssuesTab = async () => {
      createdIssueId.value = null;
      allIssue.value = await RaiseIssueService.getAllIssue(
        filters.value.selectedType,
        filters.value.selectedStatus
      );
    };

    const getCreatedDate = (date: any) => {
      const targetDate = moment.utc(date);
      return targetDate.format("DD/MM/YYYY, HH:mm");
    };

    const getIssueStatus = (IssueStatus: string) => {
      return issueStatus.value.find((status: any) => {
        return status.value === IssueStatus;
      })?.text;
    };

    const isPersonalAccountWorkStation = computed(
      () =>
        store.getters[`${WORKSTATION}/activeUserWorkstation`]?.name ===
        "Default"
    );

    const toggleRestrictionModal = () => {
      isShowWorkstationRestrictionModal.value =
        !isShowWorkstationRestrictionModal.value;
    };

    watch(
      () => filters.value,
      async (newValue) => {
        await setAllIssueAndTime();
      },
      { deep: true }
    );

    const setAllIssueAndTime = async () => {
      try {
        isLoading.value = true;
        if (isPersonalAccountWorkStation.value) {
          allIssue.value = [
            {
              id: 1,
              dateCreated: "2024-08-06T06:45:32.780Z",
              dateUpdated: "2024-08-06T06:49:22.000Z",
              name: "Window Repairing",
              refId: "nyrfXZ4geC_AieYgIRv6Y",
              description: "<p>Issue Raise Window is not working.</p>",
              issueType: "standard",
              issueStatus: "raised",
              SLATime: null,
              isProjectCreated: false,
            },
          ];
        } else {
          allIssue.value = await RaiseIssueService.getAllIssue(
            filters.value.selectedType,
            filters.value.selectedStatus
          );
        }
        allIssue.value = allIssue.value.map((issue: any) => {
          if (issue.SLATime) {
            let createdTime = moment(issue.dateCreated);
            const compilationTime = createdTime.add(issue.SLATime, "minutes");
            const currentTime = moment();
            const diffWithCurrentTime = compilationTime.diff(
              currentTime,
              "minutes"
            );
            issue["slaTime"] = {
              hours: Math.floor(Number(diffWithCurrentTime) / 60),
            };
            issue["slaTime"]["minutes"] =
              diffWithCurrentTime - issue?.slaTime?.hours * 60;
          }
          return issue;
        });
      } catch (error) {
        console.log("error", error);
      } finally {
        isLoading.value = false;
      }
    };
    onBeforeMount(async () => {
      await setAllIssueAndTime();
    });
    return {
      allIssue,
      createdIssueId,
      onClickDetails,
      issueType,
      filters,
      issueStatus,
      onClickBackButtonFromReview,
      goToListIssuesTab,
      getCreatedDate,
      getIssueStatus,
      toggleRestrictionModal,
      isShowWorkstationRestrictionModal,
      isLoading,
    };
  },
};
</script>
<style scoped lang="scss">
@import "@/modules/issues/styles/activeIssue.scss";
.issue_loader {
  margin-top: 530px;
}
</style>
