<template>
  <div class="property_details_content !tw-h-full">
    <v-tabs
      class="tab-list tw-mb-4"
      v-model="selectedTab"
      align-tabs="center"
      stacked
    >
      <v-tab
        v-for="tabItem in propertyTabList"
        :key="tabItem.name"
        :value="tabItem.name"
      >
        <img
          v-if="tabItem.icon"
          height="18px"
          :src="
            require('@/assets/icons/propety-data-tab/' + tabItem.icon + '.svg')
          "
          alt="ghg"
        />
        <span>{{ tabItem.label }}</span>
      </v-tab>
    </v-tabs>
    <v-btn
      v-if="selectedTab !== 'projects'"
      class="back__btn tw-mb-4"
      variant="plain"
      prepend-icon="mdi-chevron-left"
      @click="backToPropertyPage"
      >Back</v-btn
    >
    <v-window v-model="selectedTab" class="tw-overflow-y-auto" v-if="!loading">
      <v-window-item
        class="no-scrollbar md:tw-pb-[2rem] md:tw-box-border"
        v-for="tabValue in propertyTabList"
        :key="tabValue.name"
        :value="tabValue.name"
      >
        <PropertyMapTab v-if="tabValue.name === 'property-map'" />
        <PropertyComparablesTab v-if="tabValue.name === 'comparables'" />
        <PropertyDataTab
          v-if="tabValue.name === 'property-data'"
          @route-to-project-logs="onRouteToProjectLogs"
        />
        <PropertyProjectLogTab v-if="tabValue.name === 'projects'" />
        <PropertyMemberTab v-if="tabValue.name === 'members'" />
        <PropertyDocumentTab v-if="tabValue.name === 'documents'" />
        <PropertyLegalTab v-if="tabValue.name === 'legal'" />
        <CollapsiblePropertyPriceHistory
          v-if="tabValue.name === 'market-history'"
        />

        <!-- <SamplePropbarQueryBuilder v-if="tabValue.name === 'calendar'" /> -->
      </v-window-item>
    </v-window>
    <div
      v-else
      class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-w-full tw-h-[calc(100dvh-221px)]"
    >
      <Loader :show="true" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, onBeforeMount, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

import PropertyMapTab from "@/modules/properties/components/Tabs/PropertyMapTab.vue";
import PropertyDataTab from "@/modules/properties/components/Tabs/PropertyDataTab.vue";
import PropertyProjectLogTab from "@/modules/properties/components/Tabs/PropertyProjectLogTab.vue";
import PropertyMemberTab from "@/modules/properties/components/Tabs/PropertyMemberTab.vue";
import PropertyComparablesTab from "@/modules/properties/components/Tabs/PropetyComparablesTab.vue";
import PropertyDocumentTab from "@/modules/properties/components/Tabs/PropertyDocumentTab.vue";
import PropertyLegalTab from "@/modules/properties/components/Tabs/PropertyLegalTab.vue";
import PropertyMarketHistoryTab from "@/modules/properties/components/Tabs/PropertyMarketHistoryTab.vue";
// import SamplePropbarQueryBuilder from "@/modules/properties/components/SamplePropbarQueryBuilder.vue";
import { PROPERTY_STORE } from "@/store/modules/property";
import connectionsService from "@/core/services/connections.service";
import { USER_STORE } from "@/store/modules/user";
import { CONNECTIONS } from "@/store/modules/connections";
import Loader from "@/core/components/common/Loader.vue";
import CollapsiblePropertyPriceHistory from "@/modules/properties/components/Collapsibles/CollapsiblePropertyPriceHistory.vue";

const store = useStore();
const route = useRoute();
const router = useRouter();

const selectedTab = ref();
const loading = ref(true);

const propertyTabList = [
  { name: "property-map", icon: "property-map-icon", label: "Property Map" },
  {
    name: "property-data",
    icon: "property-data-icon",
    label: "Property Data",
  },
  {
    name: "comparables",
    icon: "comparable-icon",
    label: "Comparables",
  },
  {
    name: "market-history",
    icon: "market-history-icon",
    label: "Market History",
  },
  {
    name: "legal",
    icon: "legal-icon",
    label: "Legal",
  },
  {
    name: "projects",
    icon: "projects-filled-icon",
    label: "Projects",
  },
  {
    name: "documents",
    icon: "my-document",
    label: "Documents",
  },
  {
    name: "members",
    icon: "members-icon",
    label: "Members",
  },
];

const propertyId = computed(() => route?.params?.propertyId);
const user = computed(() => store.getters[`${USER_STORE}/user`]);
const currentPropertyConnectionRole = computed(
  () => store.getters[`${CONNECTIONS}/getPropertyConnectionRole`]
);

const init = async () => {
  loading.value = true;
  try {
    await store.dispatch(
      `${PROPERTY_STORE}/fetchPropertyDetails`,
      propertyId.value
    );
  } catch (error) {
    console.log(error, "error in fetching property details");
  } finally {
    loading.value = false;
  }
};

const onRouteToProjectLogs = () => {
  selectedTab.value = "projects";
};

const setPropertyConnectionRole = async () => {
  try {
    const response = await store.dispatch(
      `${CONNECTIONS}/getPropertyConnectionRole`,
      {
        userId: user.value.id,
        propertyId: propertyId.value,
      }
    );
  } catch (error) {
    console.log();
  }
};
onBeforeMount(async () => {
  await init();
  await setPropertyConnectionRole();
});

const backToPropertyPage = () => {
  router.push("/properties");
};
// onBeforeUnmount(() => {
//   store.dispatch(`${PROPERTY_STORE}/setPropertyDetails`, {});
// });
</script>
<style lang="scss" scoped>
:deep .property_details_content {
  :deep .v-tabs--density-default.v-tabs--stacked {
    height: unset;
  }
}

:deep(.v-window__container) {
  height: 100%;
}
.back__btn {
  height: auto;
  display: flex;
  padding: 0;
  opacity: 1;
  :deep(.v-btn__content) {
    color: rgba($blueDark, 1);
    text-transform: capitalize;
  }
  :deep(.v-btn__prepend) {
    margin: 0;
  }
}
.v-tabs--density-default.v-tabs--stacked {
  --v-tabs-height: auto;
}
</style>
