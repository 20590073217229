import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e6f6504c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "newgroupchatMessages" }
const _hoisted_2 = { class: "edit__group" }
const _hoisted_3 = { class: "newgroupchatMessages__inputs__wrapper" }
const _hoisted_4 = { class: "group_input_content" }
const _hoisted_5 = { class: "uploade-wrapper" }
const _hoisted_6 = { class: "newgroupchatMessages__userNetworkContacts__wrapper" }
const _hoisted_7 = { class: "group_selector" }
const _hoisted_8 = { class: "group_img" }
const _hoisted_9 = ["src"]
const _hoisted_10 = ["src"]
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { class: "submit_button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_file_input = _resolveComponent("v-file-input")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_list_item_action = _resolveComponent("v-list-item-action")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_text_field, {
      class: "chatbox__search create_group__search",
      modelValue: $setup.searchNetworkUsers,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.searchNetworkUsers) = $event)),
      label: "Search",
      "prepend-inner-icon": "mdi-magnify",
      variant: "solo-filled",
      "hide-details": "",
      "single-line": "",
      flat: "",
      placeholder: "Search"
    }, null, 8, ["modelValue"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_v_form, {
          onSubmit: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"]))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_v_file_input, {
                  class: "upload_file",
                  variant: "solo-filled",
                  modelValue: $setup.groupAvatar,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.groupAvatar) = $event)),
                  accept: "image/*",
                  label: "Group Avatar",
                  onChange: $setup.handleFileChange,
                  "on-click:clear": $setup.onClearGroupAvatar
                }, null, 8, ["modelValue", "onChange", "on-click:clear"]),
                _createVNode(_component_v_img, {
                  class: "uploaded-img",
                  width: 200,
                  "aspect-ratio": "16/9",
                  cover: "",
                  src: $setup.imageUrl
                }, null, 8, ["src"])
              ]),
              _createVNode(_component_v_text_field, {
                modelValue: $setup.groupName,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.groupName) = $event)),
                class: "messagechat__message",
                variant: "solo-filled",
                "hide-details": "",
                "single-line": "",
                flat: "",
                label: "Group Name"
              }, null, 8, ["modelValue"])
            ])
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_v_list, { dense: "" }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.getGroupNetWorkContactList, (item, index) => {
                return (_openBlock(), _createBlock(_component_v_list_item, {
                  key: index,
                  onClick: ($event: any) => ($setup.toggleCheckboxOnClick(index)),
                  disabled: $setup.isLoading
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_8, [
                      (item?.isUserChat)
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 0,
                            src: 
                    $setup.getImagePublicUrl(item?.receiverWorkstation?.user)
                      ? $setup.getImagePublicUrl(item?.receiverWorkstation?.user)
                      : require('@/assets/images/user-placeholder.png')
                  ,
                            alt: "workStation_img"
                          }, null, 8, _hoisted_9))
                        : (_openBlock(), _createElementBlock("img", {
                            key: 1,
                            src: 
                    $setup.getImagePublicUrl(item?.receiverWorkstation)
                      ? $setup.getImagePublicUrl(item?.receiverWorkstation)
                      : require('@/assets/images/user-placeholder.png')
                  ,
                            alt: "workStation_img"
                          }, null, 8, _hoisted_10))
                    ]),
                    _createVNode(_component_v_list_item_action, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_checkbox, {
                          modelValue: $setup.isChecked(item),
                          "onUpdate:modelValue": 
                    (value) => $setup.updateSelectedItems(item, value)
                  ,
                          label: 
                    item?.isUserChat
                      ? `${item?.receiverWorkstation?.user?.firstName} ${item?.receiverWorkstation?.user?.lastName}`
                      : item?.receiverWorkstation?.name
                  
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "label"])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1032, ["onClick", "disabled"]))
              }), 128)),
              ($setup.getGroupNetWorkContactList.length === 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, " No users found "))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_12, [
          (
              $setup.groupName &&
              $setup.groupName.trim() !== '' &&
              $setup.groupAvatar &&
              $setup.selectedItems.length
            )
            ? (_openBlock(), _createBlock(_component_v_btn, {
                key: 0,
                class: "button button-orange",
                disabled: $setup.isLoading,
                isloading: $setup.isLoading,
                onClick: $setup.createNewUserNetWorkGroup
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Create New Group ")
                ]),
                _: 1
              }, 8, ["disabled", "isloading", "onClick"]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}