<template>
  <CommonDialog
    :isShowHeader="false"
    :isShowBackButton="false"
    width="560px"
    max-width="100%"
    height="auto"
    :className="['welcome__property_owner_modal_box']"
    v-if="isShowWelcomeModal"
  >
    <template v-slot:body>
      <div class="header-title-close-icon" @click="onCloseModal">
        <v-icon icon="mdi-close"></v-icon>
      </div>
      <div class="header-title">Welcome to iknowa,</div>
      <div class="header-sub-title">{{ userName }}!</div>
      <div class="welcome__image">
        <img
          width="264px"
          height="200px"
          src="../../../assets/images/property-banner.png"
          alt=""
        />
      </div>
      <div class="welcome__text">
        <p>Get started for free.</p>
        <p>Create your <strong>Property Workstation.</strong></p>
      </div>
    </template>
    <template v-slot:footer
      ><v-btn
        @click="onClickToggleTradesWorkStationEmit"
        class="button button-purple"
        >Create property workstation 🚀</v-btn
      >
      <!-- @click="onClickToggleTradesWorkStation" -->
      <p class="footer_skip_text" @click="onCloseModal">
        Skip for now
      </p></template
    >
  </CommonDialog>
  <!-- <PropertyOwnerWorkStationModal
    v-if="isShowPropertyOwnerWorkStationModal"
    @onClickCloseTab="onClickClosePOWorkstationModal"
  /> -->
</template>

<script lang="ts">
import CommonDialog from "@/core/components/CommonDialog.vue";
import { USER_STORE } from "@/store/modules/user";
import { computed, ref } from "vue";
import { useStore } from "vuex";
// import PropertyOwnerWorkStationModal from "@/modules/workstation/propertyowner/pages/PropertyOwnerWorkStationModal.vue";
// import { WORKSTATION } from "@/store/modules/workstation";

export default {
  components: {
    CommonDialog,
    // PropertyOwnerWorkStationModal,
  },
  setup(props: any, ctx: any) {
    const store = useStore();

    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    // const isShowPropertyOwnerWorkStationModal = ref(false);
    const userName = computed(
      () =>
        `${user.value?.firstName || "user"} ${user.value?.lastName || "name"}`
    );
    const isShowWelcomeModal = ref(true);
    const isShowTradePersonBenefitsModal = ref(false);

    const onClickExploreButton = () => {
      isShowWelcomeModal.value = false;
      isShowTradePersonBenefitsModal.value = true;
    };

    const onClickToggleTradesWorkStationEmit = () => {
      ctx.emit("on-click-toggle-trades-workstation");
    };

    // const isShowCreatePropertyOwnerWorkStationModal = computed(
    //   () =>
    //     store.getters[`${WORKSTATION}/getPropertyOwnerWorkStationModelState`]
    // );

    // const onClickToggleTradesWorkStation = () => {
    //   isShowPropertyOwnerWorkStationModal.value = true;
    //   store.commit(
    //     `${WORKSTATION}/setPropertyOwnerWorkStationModelState`,
    //     !isShowCreatePropertyOwnerWorkStationModal.value
    //   );
    // };

    // const onClickClosePOWorkstationModal = () => {
    //   isShowPropertyOwnerWorkStationModal.value = false;
    // };

    const onCloseModal = () => {
      ctx.emit("on-close");
    };

    const onClickClosePreviewModalButton = () => {
      ctx.emit("onClickExploreButton");
    };
    return {
      userName,
      onClickExploreButton,
      isShowWelcomeModal,
      isShowTradePersonBenefitsModal,
      onClickClosePreviewModalButton,
      onClickToggleTradesWorkStationEmit,
      // onClickToggleTradesWorkStation,
      // isShowPropertyOwnerWorkStationModal,
      // onClickClosePOWorkstationModal,
      onCloseModal,
    };
  },
};
</script>
