<template>
  <div class="jobs_content md:!tw-pb-4 md:!tw-box-border">
    <v-tabs class="tab-list" v-model="tab" align-tabs="center" stacked>
      <v-tab
        v-for="tabItem in jobTabList"
        :key="tabItem.name"
        :value="tabItem.name"
        :disabled="
          isPersonalAccountWorkStation && tabItem?.name !== 'find-jobs'
        "
      >
        <!-- <v-icon>{{ tabItem.icon }}</v-icon> -->
        <div class="tw-w-[16px] tw-h-[16px]">
          <img :src="tabItem.icon" alt="" />
        </div>
        {{ tabItem.label }}
      </v-tab>
    </v-tabs>

    <v-window v-model="tab" :touch="false">
      <v-window-item
        v-for="tabValue in jobTabList"
        :key="tabValue.name"
        :value="tabValue.name"
      >
        <component :is="tabValue.component" v-if="tab === tabValue.name" />
      </v-window-item>
    </v-window>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, onBeforeMount } from "vue";
import { WORKSTATION } from "@/store/modules/workstation";
import JobsListView from "@/modules/jobs/components/JobsListView.vue";
import EstimateTemplatePage from "@/modules/jobs/pages/EstimateTemplatePage.vue";
import MyJobsListView from "@/modules/jobs/components/MyJobsListView.vue";
import DraftJobListView from "@/modules/jobs/components/DraftJobListView.vue";
const store = useStore();
const isPersonalAccountWorkStation = computed(
  () =>
    store.getters[`${WORKSTATION}/activeUserWorkstation`]?.name === "Default"
);
const workFlowType = computed(
  () => store.getters[`${JOBS_STORE}/getWorkFlowType`]
);
const tab = ref();
const TEMPLATE = "estimate-template";
const FIND_JOBS = "find-jobs";
const MY_JOBS = "my-jobs";
const DRAFT_JOBS = "draft-jobs";
const REJECTED_JOBS = "rejected-jobs";
const jobTabList = [
  {
    name: FIND_JOBS,
    icon: require("../assets/svg/search.svg"),
    label: "Find Jobs",
    component: JobsListView,
  },
  {
    name: MY_JOBS,
    icon: require("../assets/svg/projects.svg"),
    label: "My Jobs",
    component: MyJobsListView,
  },
  {
    name: DRAFT_JOBS,
    icon: require("../assets/svg/Draft.svg"),
    label: "Draft Jobs",
    component: DraftJobListView,
  },
  {
    name: REJECTED_JOBS,
    icon: require("../assets/svg/rejected_jobs.svg"),
    label: "Rejected Jobs",
    component: RejectedJobListView,
  },
  {
    name: TEMPLATE,
    icon: require("../assets/svg/template.svg"),
    label: "template",
    component: EstimateTemplatePage,
  },
];

onBeforeMount(() => {
  if (workFlowType.value) {
    tab.value = DRAFT_JOBS;
  }
});
</script>

<script lang="ts">
import { defineComponent } from "vue";

import RejectedJobListView from "@/modules/jobs/components/RejectedJobListView.vue";
import { useStore } from "vuex";
import { PROJECT_STORE } from "@/store/modules/project";
import { JOBS_STORE } from "@/store/modules/jobs";

export default defineComponent({
  name: "JobsPage",
});
</script>

<style lang="scss" scoped>
@import "@/modules/jobs/styles/jobs.scss";
</style>
