<template>
  <CommonDialog
    :isShowHeader="false"
    :isShowBackButton="false"
    :className="[
      'create_workstation_preview_modal create_workstation_preview_modal_new',
    ]"
    width="590px"
    height="auto"
    ><template v-slot:body>
      <div class="modal_content">
        <div class="header">
          <h4>Tutorial</h4>
          <v-btn icon="mdi-close" @click="onClickClosePreviewButton"></v-btn>
        </div>
        <div class="modal_form">
          <div
            v-if="currentSelectedTab?.id == 1"
            class="modal__carousel__content"
          >
            <div class="modal__image">
              <img
                src="../../../../assets/images/Property_owner_preview-1.png"
              />
            </div>
            <div class="title">Add a Property</div>
            <!-- <div class="sub-title">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam,
            </div> -->
          </div>

          <div
            v-if="currentSelectedTab?.id == 2"
            class="modal__carousel__content"
          >
            <div class="modal__image">
              <img
                src="../../../../assets/images/Property_owner_preview-2.png"
              />
            </div>
            <div class="title">Raise an Issue</div>
            <!-- <div class="sub-title">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam,
            </div> -->
          </div>

          <div
            v-if="currentSelectedTab?.id == 3"
            class="modal__carousel__content"
          >
            <div class="modal__image">
              <img
                src="../../../../assets/images/Property_owner_preview-3.png"
              />
            </div>
            <div class="title">Post a Project</div>
            <!-- <div class="sub-title">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam,
            </div> -->
          </div>

          <div
            v-if="currentSelectedTab?.id == 4"
            class="modal__carousel__content"
          >
            <div class="modal__image">
              <img
                src="../../../../assets/images/Property_owner_preview-4.png"
              />
            </div>
            <div class="title">Out of Hours</div>
            <!-- <div class="sub-title">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam,
            </div> -->
          </div>

          <div
            v-if="currentSelectedTab?.id == 5"
            class="modal__carousel__content"
          >
            <div class="modal__image">
              <img
                src="../../../../assets/images/Property_owner_preview-5.png"
              />
            </div>
            <div class="title">Documents</div>
            <!-- <div class="sub-title">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam,
            </div> -->
          </div>

          <div
            v-if="currentSelectedTab?.id == 6"
            class="modal__carousel__content"
          >
            <div class="modal__image">
              <img
                src="../../../../assets/images/Property_owner_preview-6.png"
              />
            </div>
            <div class="title">Calendar</div>
            <!-- <div class="sub-title">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam,
            </div> -->
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="modal__navigation__buttons">
        <v-btn
          class="button"
          :class="isDisablePrevious ? 'disable' : 'button-purple-border'"
          @click="goToPreviousTab"
          >Previous</v-btn
        >
        <v-btn class="button button-orange" @click="goToNextTab">{{
          currentSelectedTab.id == 6 ? "Go To Dashboard" : "Next"
        }}</v-btn>
      </div>
      <v-stepper class="onboard-stepper" v-model="currentSelectedTab.id">
        <v-stepper-header class="onboard-stepper__header">
          <v-stepper-item
            class="onboard-stepper__item"
            v-for="step in modelDetails"
            :key="step?.id"
            :class="{ done: currentSelectedTab.id > step?.id }"
            :title="step?.tab"
            :value="step?.id"
          ></v-stepper-item>
        </v-stepper-header>
      </v-stepper>
    </template>
  </CommonDialog>
  <PropertyOwnerSubscriptionModal
    v-if="isShowSubscriptionModal"
    @closeSubscriptionModal="onClickCloseButton"
    @closeSelectWorkstationTypeModal="onClickCloseButton"
  />
</template>
<script lang="ts">
import { ref, onBeforeMount, computed } from "vue";
import CommonDialog from "@/core/components/CommonDialog.vue";
import PropertyOwnerSubscriptionModal from "@/modules/workstation/propertyowner/pages/PropertyOwnerSubscriptionModal.vue";
import { DASHBOARD_ROUTE } from "@/modules/dashboard/routes";
import { useRouter } from "vue-router";
export default {
  components: {
    CommonDialog,
    PropertyOwnerSubscriptionModal,
  },
  setup(props: any, ctx: any) {
    const router = useRouter();
    const isShowSubscriptionModal = ref(false);

    const modelDetails = ref([
      {
        id: 1,
        tab: "Property",
        name: "Property",
        header: "Workstation Name",
        title: "How do you refer to your Workstation?",
        description: `Please let us know your preferred Workstation name`,
      },
      {
        id: 2,
        tab: "Issue",
        name: "Issue",
        header: "Upload Logo",
        title: "Upload Workstation Logo",
        description: `Please upload a logo that represents your business or an image that clearly shows your face.`,
      },
      {
        id: 3,
        tab: "Project",
        name: "Project",
        header: "Terms & Conditions",
        title: "Terms & Conditions",
        description: `Please read through and agree to the terms & conditions to access your Iknowa platform.`,
      },
      {
        id: 4,
        tab: "Emergency",
        name: "Emergency",
        header: "Terms & Conditions",
        title: "Terms & Conditions",
        description: `Please read through and agree to the terms & conditions to access your Iknowa platform.`,
      },
      {
        id: 5,
        tab: "Documents",
        name: "Documents",
        header: "Terms & Conditions",
        title: "Terms & Conditions",
        description: `Please read through and agree to the terms & conditions to access your Iknowa platform.`,
      },
      {
        id: 6,
        tab: "Calendar",
        name: "Calendar",
        header: "Terms & Conditions",
        title: "Terms & Conditions",
        description: `Please read through and agree to the terms & conditions to access your Iknowa platform.`,
      },
    ]);

    const currentSelectedTab = ref(modelDetails.value[0]);

    const onClickCloseButton = () => {
      isShowSubscriptionModal.value = false;
      ctx.emit("closeUpgradePremiumModal");
    };

    const onClickUpgradePlanButton = () => {
      isShowSubscriptionModal.value = true;
    };

    const isDisablePrevious = computed(() => {
      if (currentSelectedTab.value.id === 1) {
        return true;
      } else {
        return false;
      }
    });

    const onClickClosePreviewButton = () => {
      ctx.emit("onClickClosePreviewButton");
    };

    const goToPreviousTab = () => {
      currentSelectedTab.value =
        modelDetails.value[currentSelectedTab.value.id - 2];
    };

    const goToNextTab = () => {
      if (currentSelectedTab.value.id === 6) {
        ctx.emit("onClickClosePreviewButton");
        router.push({ name: DASHBOARD_ROUTE });
      } else {
        currentSelectedTab.value =
          modelDetails.value[currentSelectedTab.value.id];
      }
    };

    onBeforeMount(() => {
      const checkSubscriptionModal = localStorage.getItem(
        "isShowPOSubScriptionModal"
      );
      if (checkSubscriptionModal) {
        isShowSubscriptionModal.value = true;
      }
    });

    return {
      onClickCloseButton,
      isShowSubscriptionModal,
      onClickUpgradePlanButton,
      modelDetails,
      currentSelectedTab,
      goToNextTab,
      goToPreviousTab,
      isDisablePrevious,
      onClickClosePreviewButton,
    };
  },
};
</script>
