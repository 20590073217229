<template>
  <div class="escrow_account" v-if="!loading">
    <div class="escrow_account_top">
      <div class="escrow_account_header escrow_account_card">
        <div class="escrow_account_header_top">
          <TradeType value="Commercial" />
          <div class="escrow_account_property">
            <v-icon class="escrow_account_property_icon">
              <img
                src="@/core/uni-assets/svgs/house.svg"
                alt="House"
                width="16"
                height="16"
              />
            </v-icon>
            <p>{{ projectDetails?.projectProperties?.length }} Property</p>
          </div>

          <div class="escrow_account_emergency_chip">
            <div class="escrow_account_emergency_chip">
              <EmergencyChip
                v-if="projectDetails?.type === ProjectTypesEnum.EMERGENCY"
              />
              <StandardChip
                v-if="projectDetails?.type === ProjectTypesEnum.STANDARD"
              />
              <RoutineChip
                v-if="projectDetails?.type === ProjectTypesEnum.ROUTINE"
              />
            </div>
          </div>
        </div>

        <h2>{{ projectDetails?.name }}</h2>
        <LabelWithValue
          class="!tw-w-[auto]"
          :noBg="true"
          :justifyStart="true"
          label="Project ID:"
          :data="projectDetails?.refId"
        />
      </div>
    </div>
    <div class="escrow_account_bottom">
      <div class="escrow_account_title">
        <h2>Jobs Escrow Account</h2>
      </div>

      <div class="escrow_account_bottom_inner">
        <div class="escrow_account_bottom_left escrow_account_card">
          <h2>Your Jobs Escrow Wallet</h2>

          <div class="escrow_account_amount">
            <div class="escrow_account_amount_title">
              {{ formattedCurrency(escrowBalance) }}
            </div>
            <div class="escrow_account_amount_label">Available Balance</div>
          </div>

          <div class="escrow_account_info_box" v-if="estimatePhasesMessage">
            <v-icon icon="mdi mdi-alert-circle" color="#FFA500"></v-icon>
            <!-- <v-checkbox :value="true"></v-checkbox> -->
            <div class="escrow_account_info_box_text">
              <div
                v-if="estimatePhasesMessage"
                v-html="estimatePhasesMessage"
              ></div>
            </div>
          </div>

          <div
            class="escrow_account_payment_list"
            v-if="phaseCheckboxes?.length"
          >
            <div class="escrow_account_payment_list_header">
              <h2>Select payment amount</h2>

              <v-tooltip
                max-width="242px"
                activator="parent"
                location="bottom"
                class="estimate_tooltip"
              >
                Sub-contractor finished this task.
              </v-tooltip>

              <v-icon
                icon="mdi-information-slab-circle-outline mdi"
                color="#80829F"
              ></v-icon>
            </div>

            <div class="escrow_account_payment_list_inner_wrapper">
              <div
                class="escrow_account_payment_list_inner"
                v-for="phase in phaseCheckboxes"
                :key="phase?.id"
              >
                <div class="escrow_account_payment_list_item">
                  <div class="escrow_account_payment_list_item_left">
                    <div class="escrow_account_payment_icon">
                      <img
                        v-if="
                          [
                            EscrowStatusEnum.IN_PROGRESS_POKED,
                            EscrowStatusEnum.IN_PROGRESS,
                          ].includes(phase?.escrowStatus?.id) ||
                          !phase?.escrowStatus?.id
                        "
                        :src="require('@/assets/images/' + phase?.src)"
                        width="18"
                      />
                      <v-checkbox
                        v-else-if="
                          [EscrowStatusEnum.NOT_STARTED_POKED].includes(
                            phase?.escrowStatus?.id
                          )
                        "
                        v-model="phase.box"
                      ></v-checkbox>
                      <v-icon v-else :icon="phase?.icon" :color="phase?.color">
                      </v-icon>
                    </div>
                    <p>
                      {{ phaseEstimateType(phase) }} -
                      <strong>{{
                        formattedCurrency(phase?.totalAmount)
                      }}</strong>
                    </p>
                    <!-- <div class="escrow_account_payment_list_tooltip">
                    <v-tooltip
                      max-width="242px"
                      activator="parent"
                      location="bottom"
                      class="escrow_account_tooltip"
                    >
                      Payment requested
                    </v-tooltip>
                    <v-icon
                      icon="mdi-information-slab-circle-outline mdi"
                      color="#80829F"
                    ></v-icon>
                  </div> -->
                  </div>
                  <div
                    class="escrow_account_payment_list_status"
                    :class="phase?.class"
                  >
                    <p>{{ phase?.escrowStatus?.name || "Not Started" }}</p>
                  </div>
                </div>
              </div>
            </div>
            <v-divider :thickness="2"></v-divider>
            <Button
              class="button button-orange"
              label="continue"
              @click="onContinue"
              :disabled="isDisableContinue"
            />
          </div>
        </div>

        <div class="escrow_account_bottom_right">
          <div class="escrow_account_bottom_right_header">
            <h2>Your Job Escrow Transactions</h2>
            <v-btn
              variant="white"
              aria-label="Download Button"
              class="download_btn"
              @click="downloadTransactionData"
              :disabled="downloadLoading"
            >
              <img
                src="@/assets/icons/download-icon.svg"
                alt="Download"
                width="12"
                height="12"
              />
              Download PDF</v-btn
            >
          </div>

          <div
            class="escrow_account_empty_box"
            v-if="transactionsList && transactionsList?.data?.length === 0"
          >
            <div class="escrow_account_empty_box_inner">
              <div class="escrow_account_empty_box_image">
                <img
                  src="@/assets/images/empty_escrow.svg"
                  alt="InProgress"
                  width="40"
                  height="52"
                />
              </div>
              <div class="escrow_account_empty_box_text">
                <p>
                  Your Job escrow transactions will show up here. Please deposit
                  the required amount into this account to confirm the project.
                </p>
              </div>
            </div>
          </div>

          <div class="escrow_account_table escrow_account_card">
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Description</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <!-- <th>Invoice</th> -->
                </tr>
              </thead>
              <tbody>
                <tr
                  v-if="
                    transactionsList && transactionsList?.data?.length === 0
                  "
                >
                  <td colspan="5" class="text-center">No transactions</td>
                </tr>
                <tr
                  v-else
                  v-for="transaction in transactionsList?.data"
                  :key="transaction?.id"
                >
                  <td>
                    {{
                      getRelativeDateDescription(transaction?.transactionDate)
                    }}
                  </td>
                  <td>{{ transaction?.description }}</td>
                  <td>
                    <span class="text-yellow">{{
                      formattedCurrency(transaction?.transactionAmount)
                    }}</span>
                  </td>
                  <td>
                    <span class="text-green">{{ transaction?.status }}</span>
                  </td>
                  <td>
                    <v-btn
                      variant="text"
                      class="download_btn"
                      aria-label="Download Button"
                    >
                      <img
                        src="@/assets/icons/download-icon.svg"
                        alt="Download"
                        width="12"
                        height="12"
                      />
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <EscrowSelectBankModal
      v-if="isOpenEscrowWallet"
      :selectedPhases="selectedPhases"
      :subTotalAmount="subTotalAmount"
      :vatTotal="vatTotal"
      :projectDetails="projectDetails"
      @on-close="onCloseEscrowModal"
      @on-confirm="onConfirmEscrowBank"
      @on-redirect-EWallet="onRedirectEWalletModal"
    />
    <PayDepositModal
      v-if="isOpenPayDeposit"
      :paymentPayload="paymentPayload"
      :totalPayAmount="subTotalAmount + vatTotal"
      @on-close="onClosePayDepositModal"
      @on-confirm="onConfirmPayDeposit"
    />
    <PaymentSuccessModal
      v-if="isOpenPaymentSuccess"
      @on-close="onClosePaymentSuccessModal"
    />

    <PaymentFailureModal
      v-if="isOpenPaymentFail"
      @on-close="onClosePaymentFailureModal"
    />
    <PayEWalletModal
      v-if="isOpenPayIntoWallet"
      :selectedPhases="selectedPhases"
      :subTotalAmount="subTotalAmount"
      :vatTotal="vatTotal"
      :projectId="projectDetails?.id"
      @on-redirect-bank="onRedirectBankModal"
      @on-close="onClosePaymentWalletModal"
      @on-confirm="onConfirmPayIntoWallet"
    />
  </div>
</template>
<script setup lang="ts">
import TradeType from "@/core/uni-components/TradeType.vue";
import EmergencyChip from "@/core/uni-components/Chips/EmergencyChip.vue";
import StandardChip from "@/core/uni-components/Chips/StandardChip.vue";
import RoutineChip from "@/core/uni-components/Chips/RoutineChip.vue";
import LabelWithValue from "@/core/components/common/LabelWithValue.vue";
import { PROJECT_STORE } from "@/store/modules/project";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { onMounted } from "vue";
import { EscrowStatusEnum } from "@/core/enums/estimateEnum";
import { ProjectPhaseType, ProjectTypesEnum } from "@/core/enums/ProjectsEnum";
import CurrencyFilter from "@/filters/currency.filter";
import Button from "@/core/components/ui/general/Button.vue";
import EscrowSelectBankModal from "@/modules/project/modal/EscrowSelectBankModal.vue";
import PayDepositModal from "@/modules/project/modal/PayDepositModal.vue";
import PaymentSuccessModal from "@/modules/project/modal/PaymentSuccessModal.vue";
import PaymentFailureModal from "@/modules/project/modal/PaymentFailureModal.vue";
import PayEWalletModal from "@/modules/project/modal/PayEWalletModal.vue";
import tradeEstimateService from "@/core/services/trade-estimate.service";
import { useRoute, useRouter } from "vue-router";

const store = useStore();
const route = useRoute();
const router = useRouter();
const paymentPayload = ref(null) as any;
const loading = ref(false);
const isOpenEscrowWallet = ref(false);
const isOpenPayDeposit = ref(false);
const isOpenPaymentSuccess = ref(false);
const isOpenPaymentFail = ref(false);
const isOpenPayIntoWallet = ref(false);
const isDisableContinue = ref(false);
const projectEstimateWithEscrow = ref(null);
const transactionsList = ref();
const downloadLoading = ref(false);
const projectDetails = computed(
  () => store.getters[`${PROJECT_STORE}/projectDetails`]
);

const onContinue = () => {
  if (!isDisableContinue.value) {
    isOpenEscrowWallet.value = true;
  }
};
const onCloseEscrowModal = () => {
  isOpenEscrowWallet.value = false;
};
const onConfirmEscrowBank = (payload) => {
  isOpenEscrowWallet.value = false;
  paymentPayload.value = payload;
  isOpenPayDeposit.value = true;
};

const onClosePayDepositModal = () => {
  isOpenPayDeposit.value = false;
  paymentPayload.value = null;
  loading.value = false;
};

const onRedirectEWalletModal = () => {
  isOpenEscrowWallet.value = false;
  isOpenPayIntoWallet.value = true;
  paymentPayload.value = null;
};
const onRedirectBankModal = () => {
  isOpenPayIntoWallet.value = false;
  isOpenEscrowWallet.value = true;
  paymentPayload.value = null;
};
const onConfirmPayDeposit = async () => {
  try {
    loading.value = true;
    if (!localStorage.getItem("escrowPayment")) {
      localStorage.setItem(
        "escrowPayment",
        JSON.stringify({
          url: route?.params?.refId,
          path: "project-details",
        })
      );
    }
  } catch (error) {
    isOpenPaymentFail.value = false;
    console.log(error);
  } finally {
    isOpenPayDeposit.value = false;
  }
};

const onClosePaymentSuccessModal = async () => {
  isOpenPaymentSuccess.value = false;
};
const onClosePaymentFailureModal = () => {
  isOpenPaymentFail.value = false;
};

const onClosePaymentWalletModal = () => {
  isOpenPayIntoWallet.value = false;
};
const onConfirmPayIntoWallet = () => {
  isOpenPayIntoWallet.value = false;
};

const MATERIALS = "Materials";

const estimateDetails = computed(
  () => store.getters[`${PROJECT_STORE}/getProjectEstimateDataWithEscrow`]
);

const phaseCheckboxes = ref([]) as any;
const escrowBalance = computed(
  () => store.getters[`${PROJECT_STORE}/getProjectEstimateEscrowBalance`]
);
const SERVICE_CHARGE = 0.03;
const PHASE_FIRST_CHARGE = 5;
const totalPhaseAmount = (
  amount: number,
  vat: number,
  isFirstPhase: boolean
) => {
  let sum = 0;

  sum += amount + amount * vat;
  sum += sum * SERVICE_CHARGE;
  if (isFirstPhase) {
    sum += PHASE_FIRST_CHARGE;
  }
  return sum;
};

const getPhaseDetails = () => {
  const filterPhase = estimateDetails.value?.estimationPhase?.map(
    (phase: any) => {
      if (
        [EscrowStatusEnum.NOT_STARTED_POKED].includes(
          phase?.escrowStatus?.id
        ) ||
        !phase?.escrowStatus?.id
      ) {
        return {
          ...phase,
          totalAmount: totalPhaseAmount(
            phase?.subTotal,
            phase?.vat,
            phase?.isOld
          ),
          class: "not-started",
          icon: "mdi-information-slab-circle-outline mdi",
          src: "clock.svg",
          color: "#80829F",
          box: true,
        };
      } else if (phase?.escrowStatus?.id == EscrowStatusEnum.STAGE_COMPLETE) {
        return {
          ...phase,
          totalAmount: totalPhaseAmount(
            phase?.subTotal,
            phase?.vat,
            phase?.isOld
          ),
          class: "complete",
          icon: "mdi-check-circle",
          color: "#40AE42",
          box: false,
        };
      } else if (
        [
          EscrowStatusEnum.IN_PROGRESS_POKED,
          EscrowStatusEnum.IN_PROGRESS,
        ].includes(phase?.escrowStatus?.id)
      ) {
        return {
          ...phase,
          totalAmount: totalPhaseAmount(
            phase?.subTotal,
            phase?.vat,
            phase?.isOld
          ),
          class: "in-progress",
          icon: "mdi-check-circle",
          color: "#faa500",
          src: "inprogress.svg",
          box: false,
        };
      } else
        return {
          ...phase,
          totalAmount: totalPhaseAmount(
            phase?.subTotal,
            phase?.vat,
            phase?.isOld
          ),
        };
    }
  );
  phaseCheckboxes.value = filterPhase;
};
const getProjectRedirectTabName = computed(
  () => store.getters[`${PROJECT_STORE}/getProjectRedirectTabName`]
);

const getPaymentDetails = async () => {
  if (!route.query.result || !route.query.paymentInitiationId) return;
  if (
    route.query &&
    route.query.result == "success" &&
    route.query.paymentInitiationId
  ) {
    const paymentInitiationId = route.query.paymentInitiationId;
    try {
      const response = await tradeEstimateService.verifyDepositsPayment(
        paymentInitiationId
      );
      console.log("response", response);
      if (response.status === "verified") {
        isOpenPaymentSuccess.value = true;
        // await tradeEstimateService.updateProjectEstimatePhaseStatus(
        //   estimateDetails.value?.id,
        //   {
        //     escrowStatusId: EscrowStatusEnum.IN_PROGRESS,
        //   }
        // );

        if (projectDetails.value?.projectEstimateId)
          await store.dispatch(
            `${PROJECT_STORE}/getProjectEstimateWithEscrow`,
            projectDetails.value?.projectEstimateId
          );
      } else {
        isOpenPaymentFail.value = true;
      }
      getPhaseDetails();
    } catch (error) {
      isOpenPaymentFail.value = true;
      console.log(error);
    }
  } else {
    isOpenPaymentFail.value = true;
  }
  router.push({
    path: `/project-details/${projectDetails.value?.refId}`,
  });
  store.dispatch(
    `${PROJECT_STORE}/setProjectRedirectTabName`,
    "escrow-account"
  );
};

const getRelativeDateDescription = (inputDate: any) => {
  const today = new Date();
  const date = new Date(inputDate);

  today.setHours(0, 0, 0, 0);
  date.setHours(0, 0, 0, 0);

  const diffTime = today.getTime() - date.getTime();
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  if (diffDays === 0) {
    return "Today";
  } else if (diffDays === 1) {
    return "Yesterday";
  } else {
    const dayOfWeek = daysOfWeek[date.getDay()];
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    return `${dayOfWeek}, ${day} ${month} ${year}`;
  }
};

const phaseEstimateType = (phase: any) => {
  if (phase?.phaseType === ProjectPhaseType.MATERIAL) return MATERIALS;
  else return phase?.name;
};
const formattedCurrency = (value: any) => {
  return CurrencyFilter.formatToCurrency(value);
};
const estimatePhasesMessage = computed(() => {
  if (selectedPhases.value.length === 0) {
    isDisableContinue.value = true;
    return null;
  }
  isDisableContinue.value = false;

  const phaseDetails = selectedPhases.value
    .map(
      (phase: any) =>
        `${
          phase?.phaseType === ProjectPhaseType.LABOUR ? phase.name : MATERIALS
        } (£${phase.totalAmount.toFixed(2)})`
    )
    .join(" & ");
  const totalAmount = selectedPhases.value
    .reduce((sum: any, phase: any) => sum + phase.totalAmount, 0)
    .toFixed(2);

  return `
    <p><strong>Required Deposit (£${totalAmount}) </strong></p>
    <p>
      Deposits required for
      <strong>${phaseDetails}</strong> to
      kick-off the project.
    </p>
  `;
});

const selectedPhases = computed(() => {
  const phases = phaseCheckboxes.value.filter(
    (phase: any) =>
      phase.box === true &&
      [
        EscrowStatusEnum.NOT_STARTED_POKED,
        EscrowStatusEnum.NOT_STARTED,
      ].includes(phase?.escrowStatus?.id)
  );

  return phases;
});

const subTotalAmount = computed(() => {
  const sum = selectedPhases.value.reduce(
    (accumulator: any, currentValue: any) => {
      return (
        accumulator +
        (currentValue?.totalAmount - currentValue?.vat * currentValue?.subTotal)
      );
    },
    0
  );
  return sum;
});

const vatTotal = computed(() => {
  return selectedPhases.value.reduce((accumulator: any, currentValue: any) => {
    return accumulator + currentValue?.vat * currentValue?.subTotal;
  }, 0);
});

const downloadTransactionData = async () => {
  try {
    downloadLoading.value = true;
    const response = await tradeEstimateService.getEscrowTransactionList(
      projectDetails.value?.id
    );

    const blob = new Blob([response.data], { type: "application/pdf" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "downloaded.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.log("error", error);
  } finally {
    downloadLoading.value = false;
  }
};
onMounted(async () => {
  getPhaseDetails();
  await getPaymentDetails();

  try {
    transactionsList.value =
      await tradeEstimateService.getProjectEstimateTransactionList(
        projectDetails?.value?.id
      );
  } catch (error) {
    console.log("error", error);
  }
});
</script>

<style lang="scss" scoped>
.escrow_account {
  text-align: left;
  color: $blueDark;

  &_card {
    box-shadow: 0px 0px 8px 0px #0c0f4a14;
    background-color: $white;
    border-radius: 8px;
    padding: 20px;
  }

  &_top {
    max-width: 415px;
    width: 100%;

    @include respond(md) {
      max-width: 100%;
    }
  }

  &_property {
    @include fluidFont(12, 12, 1);
    letter-spacing: 0.15px;
    display: grid;
    align-items: center;
    grid-template-columns: 16px 1fr;
    grid-gap: 4px;
    font-weight: 700;
    color: $blueDark;

    &_icon {
      width: 16px;
      height: 16px;
      border-radius: 100%;
      background-color: $blueDark;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    img {
      width: 100%;
      height: auto;
      max-width: 6px;
      object-fit: contain;
      filter: invert(100%) sepia(0%) saturate(2%) hue-rotate(338deg)
        brightness(102%) contrast(101%);
    }
  }

  &_header {
    &_top {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 12px;
      margin-bottom: 8px;
    }

    h2 {
      @include fluidFont(16, 16, 2);
    }
  }

  &_emergency_chip {
    margin-left: auto;

    @include respond(s720) {
      order: -1;
      flex: auto;
      width: 100%;
      display: flex;
    }
  }

  &_bottom {
    border-top: 1px solid #c5d2f2;
    margin-top: 24px;
    padding-top: 24px;

    &_inner {
      display: grid;
      grid-template-columns: 30.5% 1fr;
      grid-gap: 16px;
      align-items: flex-start;

      @media (max-width: 1439px) {
        grid-template-columns: 38% 1fr;
      }

      @include respond(md) {
        grid-template-columns: 1fr;
        display: flex;
        flex-direction: column;
        grid-gap: 0;
        gap: 26px;
      }
    }

    &_left {
      padding: 26px 20px;

      @include respond(md) {
        width: 100%;
      }

      h2 {
        @include fluidFont(18, 18, 32px);
        letter-spacing: 0.25px;
      }
    }

    &_right {
      @include respond(md) {
        width: 100%;
      }

      &_header {
        margin-bottom: 25px;
        display: flex;
        justify-content: space-between;
        gap: 20px;
        flex-wrap: wrap;
        align-items: center;

        @include respond(md) {
          margin-bottom: 14px;
        }

        h2 {
          @include fluidFont(18, 18, 1);
        }
      }
    }
  }

  &_amount {
    margin-top: 24px;

    &_title {
      @include fluidFont(38, 38, 25px);
      letter-spacing: 0.25px;
      font-weight: 700;
    }

    &_label {
      margin-top: 18px;
      color: rgba($blueDark, 0.5);
    }
  }

  &_title {
    margin-bottom: 16px;

    h2 {
      @include fluidFont(24, 24, 32px);
      letter-spacing: 0.25px;
    }
  }

  &_info_box {
    box-shadow: 0px 0px 10px 0px #2a2a2a05;
    background-color: rgba(#ffa500, 0.1);
    border: 1px solid #ffa500;
    border-radius: 6px;
    padding: 16px;
    @include fluidFont(12, 12, 15px);
    letter-spacing: 0.24px;
    font-weight: 500;
    display: flex;
    align-items: flex-start;
    gap: 12px;
    margin-top: 35px;

    .mdi {
      flex-shrink: 0;
    }

    strong {
      font-weight: 800;
    }

    p + p {
      margin-top: 8px;
    }
  }

  &_payment_list {
    margin-top: 24px;
    padding-top: 24px;
    border-top: 1px solid rgba($blueDark, 0.1);

    &_header {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      margin-bottom: 24px;

      h2 {
        @include fluidFont(18, 18, 22px);
        letter-spacing: 0.25px;
      }

      .mdi {
        font-size: 16px;
        flex-shrink: 0;
        margin-left: auto;
      }
    }

    &_tooltip {
      width: 16px;
      height: 16px;
      position: relative;
      flex-shrink: 0;
    }

    &_inner {
      display: grid;
      gap: 12px;
      margin: 12px 0;
    }

    &_item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include fluidFont(14, 14, 22px);
      letter-spacing: 0.25px;

      &_left {
        display: flex;
        gap: 8px;
        align-items: center;

        &_icon {
          width: 16px;
          height: 16px;
          img {
            width: 100%;
            height: 100%;
          }

          .mdi {
            font-size: 18px;
            flex-shrink: 0;
          }
        }
        p {
          color: rgba($blueDark, 0.5);

          strong {
            font-weight: 400;
            color: $blueDark;
          }
        }
      }
    }

    &_status {
      background-color: rgba($blueDark, 0.05);
      text-transform: uppercase;
      letter-spacing: 1.15px;
      color: rgba($blueDark, 0.5);
      @include fluidFont(10, 10, 1);
      font-weight: 600;
      border-radius: 8px;
      padding: 5px 14px;
      min-height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      flex-shrink: 0;
      min-width: 110px;

      p {
        color: inherit;
      }

      .paid-item & {
        background-color: rgba(#40ae42, 0.1);
        color: #40ae42;
      }

      .completed-item & {
        background-color: rgba(#ffa500, 0.1);
        color: #ffa500;
      }
    }
  }

  &_empty_box {
    box-shadow: 0px 0px 10px 0px #2a2a2a05;
    background-color: $white;
    border-radius: 8px;
    padding: 32px 30px;
    margin-bottom: 20px;

    @include respond(s720) {
      padding: 20px;
    }

    &_inner {
      display: flex;
      align-items: flex-start;
      gap: 15px;
      max-width: 400px;
      width: 100%;
      @include fluidFont(14, 14, 18px);
      letter-spacing: 0.25px;
      font-weight: 600;
    }

    &_image {
      max-width: 42px;
      width: 100%;
      flex-shrink: 0;

      img {
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }
  }

  .download_btn {
    @include fluidFont(12, 12, 1);
    letter-spacing: 0.4px;
    font-weight: 600;
    color: #80829f;
    background-color: $white;
    height: auto;
    min-height: 30px;

    @include respond(s720) {
      min-height: 40px;
      width: 100%;
    }

    :deep(.v-btn__content) {
      gap: 6px;
      text-transform: none;
    }
  }

  &_table {
    box-shadow: 0px 0px 10px 0px #2a2a2a05;
    padding: 28px 20px;
    min-height: 510px;

    @include respond(s720) {
      padding: 20px;
      min-height: 278px;
      overflow: auto;
    }

    table {
      width: 100%;
      @include fluidFont(14, 14, 1);
      letter-spacing: 0.25px;
      border-collapse: collapse;

      @include respond(s720) {
        width: 735px;
      }

      td,
      th {
        padding: 18px 16px;
        border-bottom: 1px solid $blueDark;
      }

      td {
        border-bottom-color: rgba($blueDark, 0.15);
        font-weight: 500;
        @include fluidFont(14, 12, 1);
      }
    }

    .download_btn {
      min-height: 1px;
      min-width: 1px;
      padding: 0;
      width: auto;

      .v-btn__overlay,
      .v-btn__underlay {
        display: none;
      }

      &:hover,
      &:focus {
        background-color: transparent;
      }
    }
  }

  .text-yellow {
    color: #faa500 !important;
  }

  .text-green {
    color: #40ae42 !important;
  }
}

:deep(.tw-text-sm) {
  font-size: 12px;
  letter-spacing: 0.15px;
}

:deep(.typography-span) {
  line-height: 20px;
}

:deep(.card.no-shadow) {
  margin-top: 4px;
}

:deep(.card.no-shadow .typography-span) {
  color: rgba($blueDark, 0.5);
}

.escrow_account_tooltip {
  :deep(.v-overlay__content) {
    background-color: rgba($blueDark, 0.88);
    @include fluidFont(10, 10, 1);
    letter-spacing: 0.25px;
  }
}

.escrow_account_payment_icon {
  :deep(.v-checkbox) {
    .v-input__control {
      .v-selection-control {
        min-height: unset;
        .v-selection-control__wrapper {
          width: 18px;
          height: 18px;
          .v-selection-control__input {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}

.escrow_account_payment_list_inner_wrapper {
  padding-bottom: 12px;
}

.button-orange {
  margin-top: 24px;
  width: 100%;
  max-width: 100%;
}
.not-started {
  background-color: rgba($buttonText, 1);
  color: rgba($white, 1);
}
.in-progress {
  background-color: rgba($orange, 1);
  color: rgba($white, 1);
}

.complete {
  background-color: rgba($PrimaryGreen, 1);
  color: rgba($white, 1);
}
</style>
