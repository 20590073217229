<template>
  <CommonDialog
    :isShowHeader="false"
    :isShowBackButton="false"
    width="765px"
    max-width="100%"
    :className="['setting_up_preview_modal']"
  >
    <template v-slot:body>
      <div class="modal_content">
        <div class="header">
          <v-btn
            variant="text"
            icon="mdi-close"
            @click="onClickCloseButton"
          ></v-btn>
        </div>
        <div class="modal_content_main">
          <div class="title">Submit Your Workstation for Verification</div>
          <div class="sub-title">
            Once all of the required sections have been completed and you are
            happy with the details provided, don’t forget to click on the
            <p>“SUMBIT FOR VERIFICATION”</p>
            button, so our team of experts can review the information and get
            you going on iknowa platform. Although, we try our best to do this
            on a maximum speed, this process may take up to 72h
          </div>
          <div class="title_img">
            <img
              src="@/assets/images/workstation-for-verification-preview.png"
            />
          </div>
        </div>
        <div class="modal_footer_button">
          <v-btn
            variant="elevated"
            class="button button-orange w-full"
            @click="onClickSubmitVerification"
          >
            SUMBIT FOR VERIFICATION
          </v-btn>
        </div>
      </div>
    </template>
  </CommonDialog>
</template>

<script lang="ts">
import CommonDialog from "@/core/components/CommonDialog.vue";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { USER_STORE } from "@/store/modules/user";
import { WORKSTATION } from "@/store/modules/workstation";
import { UserVerificationStatusEnum } from "@/core/enums/RolesEnum";

export default {
  components: {
    CommonDialog,
  },
  setup(props: any, ctx: any) {
    const store = useStore();
    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const activeUserWorkstation = computed(
      () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
    );

    const onClickSubmitVerification = async () => {
      try {
        const data = {
          userId: user.value?.id,
          userWorkstationId: activeUserWorkstation.value?.id,
          formData: {
            verificationStatus: UserVerificationStatusEnum.PENDING_VERIFICATION,
          },
        };
        await store.dispatch(
          `${WORKSTATION}/updateUserWorkstationStatus`,
          data
        );
      } catch (error) {
        console.log();
      } finally {
        ctx.emit("onClickCloseTab");
      }
    };

    const onClickCloseButton = () => {
      ctx.emit("onClickCloseTab");
    };

    return {
      onClickCloseButton,
      onClickSubmitVerification,
    };
  },
};
</script>
