import { UserMessagesRoomStatusEnum } from "@/core/enums/chatsEnums";
import chatService from "@/core/services/chat.service";
import { Commit } from "vuex";

export default {
  async getUserWorkstationMessages(
    { commit }: { commit: Commit },
    workstationId: number
  ) {
    return chatService
      .getUserMessageRoomByProjectId(workstationId)
      .then((response) => commit("setUserWorkstationsMessages", response));
  },
  async setNetworkDirectMessage(
    { commit }: { commit: Commit },
    payload: object
  ) {
    commit("setNetworkDirectMessage", payload);
  },
  async setCurrentMessageType({ commit }: { commit: Commit }, payload: string) {
    commit("setCurrentMessageType", payload);
  },
  async setProjectChatRequests({ commit }: any, payload: Array<any>) {
    commit("setProjectChatRequests", payload);
  },

  async acceptProjectChatStatus(
    { commit }: { commit: Commit },
    payload: { userMessageId: number; status: UserMessagesRoomStatusEnum }
  ) {
    try {
      await chatService.acceptProjectChatStatus(payload.userMessageId, {
        status: payload.status,
      });
    } catch (error) {
      console.log("error", error);
    }
  },
  async rejectProjectChatStatus(
    { commit }: { commit: Commit },
    payload: { userMessageId: number }
  ) {
    try {
      await chatService.rejectProjectChatStatus(payload.userMessageId);
    } catch (error) {
      console.log("error", error);
    }
  },
  async getKrystalConversation({ commit }: { commit: Commit }) {
    try {
      const response = await chatService.getKrystalConversation();
      if (response?.questions) {
        commit("setKrystalConversation", response?.questions);
      } else {
        commit("setKrystalConversation", []);
      }
    } catch (error) {
      console.log("error", error);
    }
  },
  async getKrystalChatResponse(
    { commit }: { commit: Commit },
    payload: {
      prompt: string;
    }
  ) {
    try {
      const response = await chatService.getKrystalChatResponse(payload);
      commit("popKrystalConversation");
      commit("pushKrystalConversation", response?.question);
    } catch (error) {
      console.log(error);
    }
  },
};
