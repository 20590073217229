<template>
  <section class="tw-flex tw-flex-col tw-gap-6">
    <div class="tw-flex tw-flex-col tw-gap-2">
      <LabelWithValue
        :noBg="true"
        label="Project Estimate"
        :data="'£' + totalSubTotal"
      />
      <LabelWithValue :noBg="true" label="VAT" :data="'£' + totalVat" />
      <LabelWithValue
        :noBg="true"
        label="Iknowa Service Fee"
        :data="'£' + serviceCharges"
      >
        <template #postfix>
          <InfoIcon class="cursor-pointer" />
        </template>
      </LabelWithValue>
      <v-divider :thickness="2"></v-divider>
      <LabelWithValue
        :noBg="true"
        :allFontBold="true"
        label="Total Project Estimate"
        :data="'£' + customerTotal"
      />
    </div>
  </section>
</template>
<script setup>
import LabelWithValue from "@/core/components/common/LabelWithValue.vue";
import InfoIcon from "@/core/components/icons/InfoIcon.vue";
import { computed } from "vue";

const emits = defineEmits(["on-submit"]);
const props = defineProps({
  totalSubTotal: {
    type: Number,
  },
  totalVat: {
    type: Number,
  },
  serviceCharges: {
    type: Number,
  },
});
const onSubmitEstimate = () => {
  emits("on-submit");
};
const customerTotal = computed(() => {
  const total = props.totalSubTotal + props.totalVat + props.serviceCharges;
  return Math.round(parseFloat(total) * 100) / 100;
});
</script>
<script>
export default {
  name: "EstimateReceivedTotal",
};
</script>
<style lang="scss" scoped></style>
