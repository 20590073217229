<template>
  <ChipState :hasCustomLabel="true" :bgColor="bgColor">
    <template #custom-label>
      <Text variant="span" :textColor="textColor"> +{{ value }} More </Text>
    </template>
  </ChipState>
</template>
<script setup>
import ChipState from "@/core/components/common/ChipState.vue";
import Text from "@/core/components/ui/general/Text.vue";

const props = defineProps({
  value: String,
  bgColor: {
    type: String,
    default: "rgba(12, 15, 74, 0.04)",
  },
  textColor: {
    type: String,
    default: "#0C0F4A",
  },
});
</script>
<script>
export default {
  name: "CountChip",
};
</script>
