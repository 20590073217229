<template>
  <div class="onbording-wrap">
    <div class="onbording-wrap__inner big">
      <div class="back-button">
        <v-btn
          variant="text"
          aria-label="Refresh"
          class="back-button"
          prepend-icon="mdi-chevron-left"
          @click="backToPrevious"
          >back</v-btn
        >
      </div>
      <div
        class="onboarding__modal tw-h-full tw-flex tw-flex-col tw-justify-between"
      >
        <div class="onboarding-steps-header">
          <div class="onboarding-steps-header__inner">
            <div class="onboarding-steps-header__logo">
              <img
                width="160"
                height="29"
                class="open-logo"
                src="../../../assets/images/logo/iknowa-logo-v4.svg"
              />
            </div>
            <div class="onboarding-steps-header__title">
              <!-- <h3 v-if="!showProfile && !showPhone">
                Tell us about your trade superpowers
              </h3> -->
              <h3 v-if="showProfile" class="s540:!tw-text-[22px]">
                Profile Image
              </h3>
              <h3 v-if="showPhone" class="s540:!tw-text-[22px]">
                Phone Number
              </h3>
            </div>
          </div>
        </div>
        <div class="onboarding-steps-content tw-h-full tw-bg-white">
          <div
            class="onboarding-steps-content__inner tw-h-full rmd:!tw-h-[638px]"
          >
            <!-- <div v-if="!showProfile && !showPhone" class="tw-h-full">
              <div
                v-if="!loading"
                class="tw-flex tw-flex-col tw-justify-between tw-h-full tw-overflow-y-hidden"
              >
                <div
                  class="tw-w-full tw-h-full tw-flex tw-flex-col tw-gap-4 tw-overflow-hidden"
                >
                  <div class="onboarding-steps-content__text">
                    <h4>Add your trade specialisms</h4>
                    <p>Select as many as are relevant to you.</p>
                  </div>
                  <div
                    class="onboarding-service-list !tw-mt-0 !tw-p-4 tw-box-border"
                    v-if="!showSearchSpecialism"
                  >
                    <v-card
                      class="onboarding-service-item"
                      v-for="(specialism, index) in filteredCategories"
                      :key="index"
                      :class="{ selected: specialism.selected }"
                      @click="toggleSelection(index)"
                    >
                      <div class="onboarding-service-item__logo">
                        <v-icon
                          v-if="specialism.iconName"
                          class="list_content_btn"
                        >
                          <img
                            style="width: 10px"
                            v-if="specialism.iconName"
                            :src="
                              require('@/assets/category-icons/' +
                                specialism.iconName +
                                '.svg')
                            "
                            alt="icon"
                          />
                        </v-icon>
                      </div>
                      <div class="onboarding-service-item__text">
                        <h5>{{ specialism.tpLabel }}</h5>
                        <p>
                          {{
                            specialism.subCategories
                              .slice(0, 2)
                              .map((sub) => sub.label)
                              .join(", ")
                          }}
                          <span v-if="specialism.subCategories.length > 2">
                            etc..</span
                          >
                        </p>
                      </div>
                    </v-card>
                  </div>
                  <div
                    class="onboarding-steps-select !tw-mt-0 tw-h-full"
                    v-else
                  >
                    <div class="tw-flex tw-flex-col tw-gap-3">
                      <Text variant="p">More Trade Specialisms</Text>
                      <v-autocomplete
                        placeholder="Select your specialism(s) from list"
                        v-model="listOfSpecialism"
                        :items="listOfCategories"
                        color="blue-grey-lighten-2"
                        class="dropdown-box"
                        item-value="id"
                        item-title="tpLabel"
                        clearable
                      >
                      </v-autocomplete>
                    </div>

                    <div
                      class="onboarding-service-list !tw-mt-0 !tw-p-4 tw-box-border tw-overflow-auto tw-max-h-[calc(100%-7.7rem)] !tw-pb-4"
                    >
                      <v-card
                        class="onboarding-service-item"
                        v-for="(specialism, index) in selectedItems"
                        :key="index"
                        :class="{ selected: specialism.selected }"
                      >
                        <div class="onboarding-service-item__logo">
                          <v-icon
                            v-if="specialism.iconName"
                            :width="'10px'"
                            :height="'10px'"
                          >
                            <img
                              style="width: 10px"
                              v-if="specialism.iconName"
                              :src="
                                require('@/assets/category-icons/' +
                                  specialism.iconName +
                                  '.svg')
                              "
                              alt="icon"
                            />
                          </v-icon>
                        </div>
                        <div class="onboarding-service-item__text">
                          <h5>{{ specialism.tpLabel }}</h5>
                          <p>
                            {{
                              specialism.subCategories
                                .slice(0, 2)
                                .map((sub) => sub.label)
                                .join(", ")
                            }}
                            <span v-if="specialism.subCategories.length > 2">
                              etc..</span
                            >
                          </p>
                        </div>
                      </v-card>
                    </div>
                  </div>
                </div>

                <div class="tw-flex tw-flex-col tw-gap-3">
                  <div class="onboarding-not-service">
                    <p v-if="!showSearchSpecialism">
                      Can’t see your spcialism?
                      <span @click="searchForSpecialisms"
                        >Search from our full list of trade specialisms
                        <v-icon icon="mdi-chevron-right"
                      /></span>
                    </p>
                    <p v-else>
                      Select from popular specialisms.
                      <span @click="showSearchSpecialism = false"
                        >Back <v-icon icon="mdi-chevron-right"
                      /></span>
                    </p>
                  </div>
                  <div class="onboarding-button-wrap !tw-m-0">
                    <v-btn
                      class="button button-purple-border"
                      @click="clearSpecialism"
                      >Clear</v-btn
                    >
                    <v-btn
                      v-if="!hasSelectedSpecialism"
                      class="button button-orange"
                      variant="tonal"
                      disabled
                    >
                      continue
                    </v-btn>
                    <v-btn
                      v-else
                      class="button button-orange traking"
                      @click.enter="goToNextStep"
                      :loading="isSpecialismContinueLoading"
                      >Continue</v-btn
                    >
                  </div>
                </div>
              </div>
              <CommonLoader v-else :loading="loading" />
            </div> -->
            <div v-if="showProfile">
              <div class="onboarding-steps-content__text">
                <h4 class="s540:!tw-text-[18px]">Upload your Profile Image</h4>
                <p class="s540:!tw-text-[14px] s540:!tw-leading-[18px]">
                  Please select or capture an image to be uploaded
                </p>
              </div>
              <div
                class="onboarding-steps-content__upload s540:!tw-gap-[3rem] s540:!tw-mb-0"
              >
                <div
                  class="uploade-wrapper s540:!tw-my-4 s540:!tw-mx-auto s540:!tw-w-full s540:!tw-h-full s540:tw-flex s540:tw-flex-col s540:tw-gap-4 s540:tw-items-center s540:tw-mb-0"
                >
                  <v-file-input
                    class="upload_file s540:!tw-w-[150px] s540:!tw-h-[150px] s540:!tw-m-0 s540:!tw-overflow-hidden"
                    append-inner-icon="mdi-image-plus"
                    accept="image/png, image/jpeg"
                    variant="solo-filled"
                    :class="{ 'added-img': imageUrl }"
                    @change="onFileChange"
                    @click:clear="clearImageUrl"
                  >
                  </v-file-input>
                  <v-img
                    class="uploaded-img s540:!tw-w-[150px] s540:!tw-h-[150px] s540:!tw-left-[unset]"
                    :width="200"
                    aspect-ratio="16/9"
                    cover
                    :src="imageUrl"
                  ></v-img>
                  <Text
                    variant="span"
                    textAlign="center"
                    textColor="rgba(12, 15, 74, 0.5)"
                    >Accepted file formats: PNG, JPEG</Text
                  >
                </div>
                <div
                  class="next_step_btns tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-3"
                >
                  <v-btn
                    class="button button-orange w-full traking"
                    :disabled="isContinueDisabled"
                    :loading="isImageUpload"
                    @click.enter="goToLastStep"
                  >
                    continue
                  </v-btn>
                  <Text
                    variant="p"
                    textWeight="500"
                    textColor="rgba(12, 15, 74, 0.5)"
                    >No Image?
                    <Text
                      class="tw-cursor-pointer"
                      variant="p"
                      textWeight="500"
                      textColor="#FFA500"
                      @click.enter="goToLastStep($event, true)"
                    >
                      Skip for now
                      <v-icon
                        icon="mdi-chevron-right"
                        color="#FFA500"
                      ></v-icon></Text
                  ></Text>
                </div>
                <!-- <div class="onboarding-not-service">
                  <p>
                    No Image?
                    <span @click="skipProfileImage"
                      >Skip for now <v-icon icon="mdi-chevron-right"
                    /></span>
                  </p>
                </div> -->
              </div>
            </div>
            <div v-if="showPhone" class="tw-h-full">
              <div class="tw-h-full">
                <div class="onboarding-steps-content__text">
                  <h4 class="s540:!tw-text-[18px]">Add Your Phone Number</h4>
                  <p class="s540:!tw-text-[14px] s540:!tw-leading-[18px]">
                    Be the first to receive special offers & benefits from
                    iknowa
                  </p>
                </div>
                <div class="contact_form tw-h-[calc(100%-70px)]">
                  <v-form
                    @submit.prevent
                    :rules="validation"
                    ref="formRef"
                    class="tw-gap-8"
                  >
                    <div class="contact__field">
                      <label class="">Phone Number</label>
                      <div class="contact_input">
                        <v-autocomplete
                          v-model="countryCode"
                          :items="countryinfo"
                          item-title="name"
                          item-value="dial_code"
                          @update:modelValue="handleCountryCode"
                          class="flag-selector input_field"
                          rounded
                          outlined="false"
                        >
                          <template v-slot:chip="{ props, item }">
                            <flag
                              v-bind="props"
                              style="font-size: 16px; width: 100%"
                              :iso="item.raw.code"
                            />
                          </template>
                          <template v-slot:item="{ props, item }">
                            <div
                              class="tw-min-w-[300px] rmd:tw-min-w-[360px] !tw-w-full"
                            >
                              <v-list-item
                                v-bind="props"
                                :title="item.raw.name"
                                class="country__selector tw-w-full"
                              >
                                <flag
                                  v-bind="props"
                                  style="font-size: 16px"
                                  :iso="item.raw.code"
                              /></v-list-item>
                            </div>
                          </template>
                        </v-autocomplete>
                        <v-text-field
                          class="input"
                          type="text"
                          density="compact"
                          placeholder="Phone Number"
                          variant="solo-filled"
                          :rules="validation.phone"
                          v-model="userPhone"
                        ></v-text-field>
                      </div>
                    </div>
                    <v-btn
                      class="button button-orange w-full"
                      :loading="savingDetails"
                      :disabled="!isPhoneValid"
                      @click.enter="goToDashboard"
                    >
                      complete onboarding
                    </v-btn>
                  </v-form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="tw-w-full tw-px-4 tw-py-5 rmd:tw-px-20 rmd:tw-pt-4 rmd:tw-pb-5 tw-rounded-b-[30px] tw-bg-white"
        >
          <v-stepper v-model="step" class="onboard-stepper">
            <v-stepper-header>
              <!-- <v-stepper-item
                :class="{ done: step > 0 }"
                value="1"
                title="Specialisms"
                @click="goToFirstStep"
              ></v-stepper-item> -->
              <v-stepper-item
                :class="{ done: step > 0 }"
                title="Profile Image"
                value="2"
              ></v-stepper-item>
              <v-stepper-item title="Phone" value="3"></v-stepper-item>
            </v-stepper-header>
          </v-stepper>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
import { useStore } from "vuex";
const store = useStore();
import { USER_STORE } from "@/store/modules/user";
// import { UKPhoneValidationSchema } from "../../../core/validation/validation";
import { countryinfo } from "../../../core/utils/countryinfo";
import { UserRolesEnum } from "@/core/enums/RolesEnum";
import CommonLoader from "@/core/components/CommonLoader.vue";
import Text from "@/core/components/ui/general/Text.vue";
import { watch } from "vue";

const loading = ref(false);
const showProfile = ref(true);
const showPhone = ref(false);
const showSearchSpecialism = ref(false);
const imageUrl = ref("");
const listOfSpecialism = ref([]) as any;
const filteredCategories = ref([]) as any;
const listOfCategories = ref([]);
const selectedItems = ref([]) as any;
const countryCode = ref(null) as any;
const formRef = ref(null) as any;
const userPhone = ref("");
const user = computed(() => store.getters[`${USER_STORE}/user`]);
const isSpecialismContinueLoading = ref(false);
const savingDetails = ref(false);
const validation = ref({
  phone: [
    (v: string) => !!v || "Phone number is required",
    (v: string) => /^\+\d{1,3}\s?/.test(v) || "Please select country code.",
    (v: string) => /^\+\d{1,3}\s?\d{10,14}$/.test(v) || "Invalid phone number.",
  ],
});

const isPhoneValid = computed(() => {
  return validation.value.phone.every((rule) => rule(userPhone.value) === true);
});

// Function to handle selection change event
const handleSelectionChange = (selectedItemID: any) => {
  const index = selectedItems.value.findIndex(
    (item: any) => item.id === selectedItemID
  );
  let selectedItem: any = listOfCategories.value.find(
    (item: any) => item.id === selectedItemID
  );
  if (!selectedItem) {
    return;
  }
  if (index !== -1) {
    selectedItems.value.splice(index, 1); // Remove the item if it exists
  } else {
    selectedItem.selected = true;
    selectedItems.value.push(selectedItem); // Push the selected item object into the selectedItems array
  }
  selectedItemsCategory.value = selectedItems.value.filter(
    (specialism: any) => specialism.selected
  );
  hasSelectedSpecialism.value = selectedItems.value.length > 0; // Check if there are any selected items
};

onMounted(async () => {
  getData();
  countryCode.value = countryinfo.find(
    (country: any) => country.code === "GB"
  )?.dial_code;
  userPhone.value = countryCode.value + " " + userPhone.value;
});

const getData = async () => {
  try {
    loading.value = true;
    let response = await store.dispatch(`${USER_STORE}/getCategories`);
    listOfCategories.value = response;
    const popularCategories = [1, 2, 4, 13, 8, 41];

    filteredCategories.value = response.filter((category: any) =>
      popularCategories.includes(category.id)
    );
  } catch (error) {
    console.log("error:", error);
  } finally {
    loading.value = false;
  }
};
let step = ref(0);
const selectedItemsCategory = ref([]);

const toggleSelection = (index: number) => {
  const specialism = filteredCategories.value[index];
  if (!("selected" in specialism)) {
    specialism.selected = true; // If 'selected' key is not present, add it and set to true
    hasSelectedSpecialism.value = true;
  } else {
    specialism.selected = !specialism.selected; // Toggle 'selected' value
    // hasSelectedSpecialism.value = false;
  }
  selectedItemsCategory.value = filteredCategories.value.filter(
    (specialism: any) => specialism.selected
  );
};

const handleCountryCode = () => {
  if (userPhone.value) {
    userPhone.value = "";
  }
  userPhone.value = countryCode.value + " " + userPhone.value;
};

const hasSelectedSpecialism = ref(false);

const goToFirstStep = () => {
  step.value = 0; // Change to the first step
  showProfile.value = false;
  clearSpecialism();
};

// const goToNextStep = async () => {
//   try {
//     isSpecialismContinueLoading.value = true;
//     const subCategoryId = selectedItemsCategory.value.map((item: any) => {
//       return Number(item.subCategories[0].id);
//     });

//     await store.dispatch(`${USER_STORE}/saveUserSkills`, {
//       userId: user.value?.id,
//       subCategoryId: subCategoryId,
//     });

//     step.value = 1; // Change to the next step
//     showProfile.value = true;
//   } catch (error) {
//     console.log("error:", error);
//   } finally {
//     isSpecialismContinueLoading.value = false;
//   }
// };
let imageData = ref("");
const isImageUpload = ref(false);

const goToLastStep = async (e: any, skip = false) => {
  e.preventDefault();
  if (skip) {
    isImageUpload.value = true;
    step.value = 1;
    showPhone.value = true;
    showProfile.value = false;

    try {
      await store.dispatch(`${USER_STORE}/saveUserProfile`, {
        userId: user.value.id,
        profile: {
          profileImage: null,
        },
      });
    } catch (error) {
      isImageUpload.value = false;
    } finally {
      isImageUpload.value = false;
    }
    return;
  }
  if (!imageData.value) return;
  step.value = 1; // Change to the next step
  try {
    isImageUpload.value = true;
    const formData = new FormData();
    formData.append("file", imageData.value);
    const response = await store.dispatch(
      `${USER_STORE}/uploadProfileImage`,
      formData
    );
    await store.dispatch(`${USER_STORE}/saveUserProfile`, {
      userId: user.value.id,
      profile: {
        profileImage: response?.attachment,
      },
    });
    showPhone.value = true;
    showProfile.value = false;

    await store.dispatch(`${USER_STORE}/saveUserImage`, response?.publicUrl);
  } catch (error) {
    console.log("error:", error);
    isImageUpload.value = false;
  } finally {
    isImageUpload.value = false;
  }
};

const clearSpecialism = () => {
  listOfSpecialism.value = [];
  getData();
  selectedItems.value = [];
  hasSelectedSpecialism.value = false;
};

const skipProfileImage = () => {
  step.value = 2;
  showPhone.value = true;
  showProfile.value = false;
};
const onFileChange = async (event: any) => {
  const fileInput = event.target;
  if (fileInput.files && fileInput.files.length > 0) {
    const file = fileInput.files[0];
    if (
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg"
    ) {
      imageUrl.value = URL.createObjectURL(file);
      imageData.value = file;
    }
  } else {
    console.error("Error: No file selected.");
  }
};

const searchForSpecialisms = () => {
  showSearchSpecialism.value = true;
};

const goToDashboard = async () => {
  const isFormValid = await formRef.value.validate();
  if (!isFormValid.valid) return;
  try {
    savingDetails.value = true;
    await store.dispatch(`${USER_STORE}/verifyPhoneNumber`, {
      contactNo: userPhone.value,
    });
    await store.dispatch(`${USER_STORE}/updateUser`, {
      userId: user.value?.id,
      userData: { hasAccountSetup: true, contactNo: userPhone.value },
    });
    await store.dispatch(
      `${USER_STORE}/saveUserRole`,
      UserRolesEnum.TRADESPERSON
    );
    router.push("/dashboard");
  } catch (error) {
    console.log();
  } finally {
    savingDetails.value = false;
  }
};

const backToPrevious = () => {
  if (step.value == 0) {
    router.push("/onboarding");
  } else if (step.value == 1) {
    showProfile.value = true;
    showPhone.value = false;
    step.value = 0;
  }
  // else if (step.value == 2) {
  //   showProfile.value = true;
  //   showPhone.value = false;
  //   step.value = 1;
  // }
};

const isContinueDisabled = computed(() => {
  return !imageUrl.value || isImageUpload.value;
});
const clearImageUrl = () => {
  imageUrl.value = "";
};

watch(
  () => listOfSpecialism.value,
  async (newValue, oldValue) => {
    if (newValue) {
      handleSelectionChange(newValue);
    }
  }
);
</script>

<style lang="scss" scoped>
@import "@/modules/onboarding/styles/onboarding.scss";

:deep .flag-selector {
  max-width: unset;
  display: block;
  min-width: unset;
  flex: unset;
  * {
    grid-area: unset;
  }
  .v-input__control {
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
    background: rgba(12, 15, 74, 0.05);
    height: 42px;
    overflow: hidden;
  }
  .v-input__details {
    display: none;
  }
  .v-field__append-inner {
    padding-right: 10px;
  }
  .v-field {
    padding: 0;
    flex: unset;
    border-radius: 0;
  }
  .v-field__input {
    min-width: 50px;
    padding-right: 0;
    display: block;
    input {
      width: 1px;
    }
  }
}
:deep .flag-selector {
  .v-field--variant-filled {
    background-color: transparent;
    .v-field__overlay {
      background-color: transparent;
      opacity: 0;
    }
    .v-field__input {
      .v-autocomplete__selection {
        width: 100%;
      }
    }
  }
}
:deep(.contact_input) {
  .input {
    .v-input__control {
      background: transparent;
      height: 42px;
      overflow: hidden;
      .v-field {
        box-shadow: none;

        padding-top: 1px;
        padding-bottom: 1px;
        height: 42px;
        background: rgba(12, 15, 74, 0.05);
        border-top-right-radius: 100px;
        border-bottom-right-radius: 100px;

        &__overlay {
          background-color: transparent;
        }
      }
    }
  }
}
:deep(.country__selector) {
  .v-list-item__content {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: flex-end;
    gap: 20px;
  }
}
</style>
