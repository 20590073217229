<template>
  <GeneralDialog
    :headerTitle="modalHeader.title"
    :showBackButton="formData.grantType"
    @on-close="onCloseModal"
    @on-Back="onClickBack"
  >
    <template #body>
      <main class="tw-max-w-full tw-min-w-[200px] tw-w-[570px]">
        <!-- Select grant type -->
        <section
          class="tw-flex tw-flex-col tw-w-full tw-gap-6 tw-pb-6 tw-border-b tw-border-solid tw-border-[#C5D2F2] tw-border-0"
          v-if="!formData.grantType"
        >
          <Card
            @click="onSelectGrantType(GRANT)"
            class="grant_type !tw-py-4 !tw-px-6 tw-box-border tw-cursor-pointer !tw-shadow-[0px 0px 8px 0px #0C0F4A14]"
            :class="{
              'is-active': state.grantType === GRANT,
            }"
          >
            <template #content>
              <div class="tw-flex tw-items-start tw-gap-2">
                <div
                  class="tw-w-[40px] tw-h-[40px] tw-min-w-[40px] tw-overflow-hidden"
                >
                  <img
                    :src="require('@/assets/icons/blue-exclaimation.svg')"
                    alt=""
                  />
                </div>
                <div class="tw-flex tw-flex-col">
                  <Text variant="h5" lineHeight="18px">Grant</Text>
                  <Text
                    class="tw-mt-1"
                    variant="p"
                    textWeight="500"
                    lineHeight="18px"
                    textColor="rgba(12, 15, 74, 0.5)"
                    >Grants are provided by the government, energy supplier or
                    other external organization</Text
                  >
                </div>
              </div>
            </template>
          </Card>
          <Card
            @click="onSelectGrantType(DEPOSIT)"
            class="grant_type !tw-py-4 !tw-px-6 tw-box-border tw-cursor-pointer"
            :class="{
              'is-active': state.grantType === DEPOSIT,
            }"
          >
            <template #content>
              <div class="tw-flex tw-items-start tw-gap-2">
                <div
                  class="tw-w-[40px] tw-h-[40px] tw-min-w-[40px] tw-overflow-hidden"
                >
                  <LightBulbFilledIcon />
                </div>
                <div class="tw-flex tw-flex-col">
                  <Text variant="h5" lineHeight="18px">Personal Discount</Text>
                  <Text
                    variant="p"
                    class="tw-mt-1"
                    textWeight="500"
                    lineHeight="18px"
                    textColor="rgba(12, 15, 74, 0.5)"
                    >Add Personal Discount</Text
                  >
                </div>
              </div>
            </template>
          </Card>
        </section>

        <!-- grant form -->
        <section
          v-else
          class="tw-flex tw-flex-col tw-w-full tw-gap-6 tw-pb-6 tw-border-b tw-border-solid tw-border-[#C5D2F2] tw-border-0"
        >
          <v-form @submit.prevent :rules="validation" ref="formRef">
            <div
              class="tw-w-full tw-flex tw-flex-col tw-gap-2"
              v-if="formData.grantType === 1"
            >
              <Text variant="span" textWeight="400"> Grant Name </Text>
              <v-text-field
                placeholder="eg. NERC"
                variant="outlined"
                v-model="data.grantName"
                :rules="validation.grantName"
                class="c-input add_input"
              ></v-text-field>
            </div>
            <div
              class="tw-w-full tw-flex tw-flex-col tw-gap-2"
              v-if="[1, 2].includes(formData.grantType)"
            >
              <Text variant="span" textWeight="400"> Grant Amount </Text>
              <v-text-field
                label="£"
                variant="outlined"
                class="c-input add_input_amount add_input"
                v-model="formattedAmount"
                :rules="validation.grantAmount"
                @input="formatGrantAmount"
              ></v-text-field>
            </div>
          </v-form>
        </section>
      </main>
    </template>
    <template #footer>
      <div
        class="tw-flex tw-flex-col tw-gap-2 tw-items-center tw-justify-center tw-w-auto tw-ml-auto"
      >
        <Button
          label="Next"
          @click="onNext"
          v-if="!formData.grantType"
          width="auto"
          class="tw-w-auto"
          :disabled="isDisableButton"
        />
        <Button :label="buttonLabel" @click="onSave" v-else />
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup>
import { reactive } from "vue";

import Card from "@/core/components/ui/general/Card.vue";
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import LightBulbFilledIcon from "@/core/components/icons/LightBulbFilledIcon.vue";
import ExclamationFilledIcon from "@/core/components/icons/ExclamationFilledIcon.vue";
import { useStore } from "vuex";
import { JOB_TEMPLATE_STORE } from "@/store/modules/job-template";
import { computed } from "vue";
import { ref } from "vue";
import { isNumber } from "@/core/helpers/number.helper";
import NoBgInput from "@/core/components/ui/inputs/NoBgInput.vue";
import { watch } from "vue";

const SELECT = "select";
const GRANT = "grant";
const DEPOSIT = "deposit";
const store = useStore();
const emits = defineEmits(["on-close", "on-save-project-estimation"]);
const formData = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/formData`]
);
const isDisableButton = ref(false);
const onClickBack = () => {
  isDisableButton.value = true;
  formData.value.grantType = null;
  state.grantType = "";
  modalHeader.title = "Add A Grant";
};
const modalHeader = {
  title: "Add A Grant",
};

const state = reactive({
  screenType: SELECT,
  grantType: "",
});

const onSelectGrantType = (type) => {
  state.grantType = type;
  isDisableButton.value = false;
};

const onNext = () => {
  if (state.grantType === DEPOSIT) {
    formData.value.grantType = 2;
    modalHeader.title = "Personal Discount";
    formData.value.grantName = "";
  } else {
    modalHeader.title = "Grant";
    formData.value.grantType = 1;
  }
};
const buttonLabel = computed(() => {
  return formData.value.grantType === 1
    ? "Grant Save"
    : "Personal Discount Save";
});
const onSave = async () => {
  const isFormValid = await formRef.value.validate();

  if (formData.value.grantType === 1) {
    if (!isFormValid.valid) return;
  } else {
    if (!formData.value.grantAmount) return;
  }

  formData.value.grantAmount = data.value.grantAmount;
  formData.value.grantName = data.value.grantName;
  emits("on-save-project-estimation");
  onCloseModal();
};

const onCloseModal = () => {
  emits("on-close");
};
const validation = ref({
  grantName: [(v) => !!v || "Provide Grant Name"],
  grantAmount: [
    (v) => !!v || "Provide Grant Amount",
    (v) => !isNaN(Number(v.replace(/,/g, ""))) || "Must be a number",
  ],
});
const formatNumber = (value) => {
  if (!value) return "";
  let number = value.toString().replace(/\D/g, "");
  return number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
const formattedAmount = ref("");
const formRef = ref(null);
const data = ref({
  grantName: "",
  grantAmount: 0,
});
data.value.grantName = formData.value.grantName;
data.value.grantAmount = formData.value.grantAmount;
formattedAmount.value = formatNumber(formData.value.grantAmount);

const formatGrantAmount = (event) => {
  const value = event.target.value.replace(/,/g, "");
  data.value.grantAmount = value;
  formattedAmount.value = formatNumber(value);
};
</script>
<script>
export default {
  name: "AddGrantModal",
};
</script>
<style lang="scss" scoped>
.is-active {
  border: 2px solid #faa500 !important;
}
.grant_type {
  box-shadow: 0px 0px 8px 0px #0c0f4a14;
}
.add_input {
  margin: 0;
  :deep(.v-input__control) {
    .v-field {
      .v-field__field {
        border: 1px solid rgba($buttonText, 0.4);
        border-radius: 8px;
        .v-field__input {
          background-color: transparent;
          min-height: 40px;
          padding: 12px 16px;
        }
      }
    }
  }
}
.v-field--active .v-label.v-field-label {
  visibility: visible;
}

.add_input_amount {
  :deep(.v-input__control) {
    .v-field--active {
      .v-field__field {
        .v-label.v-field-label {
          visibility: visible;
          font-weight: 700;
          color: rgba($blueDark, 1);
        }
        .v-field__input {
          padding: 12px 30px;
        }
      }
    }
    .v-field {
      .v-field__field {
        .v-label.v-field-label {
          font-weight: 700;
          color: rgba($blueDark, 1);
          opacity: 1;
        }
      }
    }
  }
}
</style>
