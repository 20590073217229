import { update } from "lodash";
import $axios from "../utils/axios-api-config";
export default {
  createHistoricalProject(formData: any) {
    return $axios.post(`/historical-project`, formData);
  },
  getHistoricalJobs() {
    return $axios.get(`/historical-project/verify-job`);
  },
  getHistoricalProjects() {
    return $axios.get(`/historical-project/client`);
  },
  getHistoricalJobDetail(projectRefId: number) {
    return $axios.get(`/historical-project/details/${projectRefId}`);
  },
  updateHistoricalJobDetail(payload: any, projectId: number) {
    return $axios.put(`/historical-project/${projectId}`, payload);
  },
  updateHistoricalJobStatus(
    projectId: number,
    payload: {
      historicalProjectStatus: number;
      projectEstimateId: number;
    }
  ) {
    return $axios.put(
      `/historical-project/historical-status-update/${projectId}`,
      payload
    );
  },
};
