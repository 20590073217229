export default {
  propertyForm: (state: any) => {
    return state.propertyForm;
  },

  properties: (state: any) => {
    return state.properties;
  },

  propertyDetails: (state: any) => {
    return state.propertyDetails;
  },

  epcCategoryDataList: (state: any) => {
    return state.epcCategoryDataList;
  },

  epcDataListByCategoryId: (state: any) => {
    return state.epcDataListByCategoryId;
  },

  selectedProperties: (state: any) => {
    return state.selectedProperties;
  },

  assetRegisterForm: (state: any) => {
    return state.assetRegisterForm;
  },

  assetRegisters: (state: any) => {
    return state.assetRegisters;
  },

  assetRegisterDetails: (state: any) => {
    return state.assetRegisterDetails;
  },

  selectedAsset: (state: any) => {
    return state.selectedAsset;
  },

  propertyProjects: (state: any) => {
    return state.propertyProjects;
  },

  getPropertyMapLayers: (state: any) => {
    return state.propertyMapLayers;
  },
};
