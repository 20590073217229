<template>
  <div class="dashboard__btn">
    <v-btn
      @click="goToDashboard"
      variant="plain"
      prepend-icon="mdi-chevron-left"
      class="back_dashboard_btn"
      >Back to Dashboard</v-btn
    >
  </div>
  <div
    v-if="getAllProjectReviewList?.length && !isLoading"
    class="review-card-list-wrapper"
  >
    <div class="review-card-list__title">
      <p><strong>Your Reviews</strong></p>
    </div>
    <div class="review-card-list">
      <ProjectReviewCard
        v-for="review of getAllProjectReviewList"
        :key="review.id"
        :reviewData="review"
        @onViewDetail="onClickViewDetail(review)"
        @onViewComment="onClickViewCommentDetail(review)"
      />
    </div>
  </div>
  <div v-else-if="!isLoading" class="empty__content">
    <div class="empty__banner">
      <div class="empty__banner__img">
        <img src="../../../assets/images/review-empty.png" alt="" />
      </div>
      <div class="empty__banner__content">
        <h4>You don't have any review yet!</h4>
        <p>Get reviews by completing jobs.</p>
      </div>
      <div class="empty__banner__footer">
        <v-btn
          class="button button-purple"
          width="100%"
          @click="onClickGotoJobPage"
          >Find New Jobs</v-btn
        >
      </div>
    </div>
  </div>
  <Loader :show="isLoading" />
  <projectReviewModal
    v-if="isShowReviewModal"
    :reviewData="selectedProjectReviewData"
    @on-close="toggleReviewModal"
    @onViewComment="onClickViewCommentDetail(selectedProjectReviewData)"
  />
  <projectReviewCommentModal
    v-if="isShowReviewCommentModal"
    :reviewData="selectedProjectReviewData"
    @on-close="toggleReviewCommentModal"
  />
</template>

<script setup lang="ts">
import projectReviewModal from "@/modules/trade-passport/components/modals/projectReviewModal.vue";
import projectReviewCommentModal from "@/modules/trade-passport/components/modals/projectReviewCommentModal.vue";
import ProjectReviewCard from "@/modules/trade-passport/components/cards/ProjectReviewCard.vue";
import Loader from "@/core/components/common/Loader.vue";
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { JOBS_ROUTE } from "@/modules/jobs/routes";
import { useRouter } from "vue-router";
import { ProjectRatingTypeEnum } from "@/core/enums/ProjectsEnum";
import { REVIEW_RATING_STORE } from "@/store/modules/review-rating";
import { reviewRatingState } from "@/core/models/project-rating";
import { DASHBOARD_ROUTE } from "@/modules/dashboard/routes";
import { PROJECT_ROUTE } from "@/modules/project/routes";

const store = useStore();
const router = useRouter();
const isShowReviewModal = ref(false);
const isLoading = ref(false);
const isShowReviewCommentModal = ref(false);
const selectedProjectReviewData = ref();
const toggleReviewModal = () => {
  isShowReviewModal.value = !isShowReviewModal.value;
};
const toggleReviewCommentModal = () => {
  isShowReviewCommentModal.value = !isShowReviewCommentModal.value;
};

const onClickViewDetail = (reviewData: any) => {
  selectedProjectReviewData.value = reviewData;
  toggleReviewModal();
  isShowReviewCommentModal.value = false;
};
const onClickViewCommentDetail = (reviewData: any) => {
  selectedProjectReviewData.value = reviewData;
  toggleReviewCommentModal();
  isShowReviewModal.value = false;
};
const onClickGotoJobPage = () => {
  router.push({ name: PROJECT_ROUTE });
};
const getAllProjectReviewList = computed<
  reviewRatingState["allProjectReviewList"]
>(() => store.getters[`${REVIEW_RATING_STORE}/getAllProjectReviewList`]);
const setAllProjectReviewList = async () => {
  try {
    isLoading.value = true;
    await store.dispatch(
      `${REVIEW_RATING_STORE}/getAllProjectReviewList`,
      ProjectRatingTypeEnum.TRADER_OWNER
    );
  } catch (error) {
    console.log("all review list", error);
  } finally {
    isLoading.value = false;
  }
};
const goToDashboard = () => {
  router.push({ name: DASHBOARD_ROUTE });
};
onMounted(async () => {
  if (!getAllProjectReviewList.value?.length) {
    await setAllProjectReviewList();
  }
});
</script>
<style lang="scss" scoped>
/*Empty Screen */
.empty__content {
  height: calc(100% - 150px);
  display: flex;
  align-items: center;
  justify-content: center;
  @include respond(s720) {
    height: 100%;
  }
  .empty__banner {
    width: 480px;
    max-width: 100%;
    margin: 0 auto;
    @include respond(s720) {
      width: 100%;
    }
    .empty__banner__img {
      @include respond(s720) {
        width: 300px;
        margin: 0 auto;
      }
      img {
        @include respond(s720) {
          width: 100%;
        }
      }
    }
    .empty__banner__content {
      margin-top: 46px;
      @include respond(sm) {
        margin: 0;
      }
      h4 {
        @include fluidFont(28, 28, 1.3);
        font-weight: 500;
        color: rgba($blueDark, 1);
      }
      p {
        margin-top: 16px;
        @include fluidFont(14, 14, 1.3);
        font-weight: 500;
        color: rgba($blueDark, 0.5);
        letter-spacing: 0.15px;
      }
      .empty__banner__content__list {
        margin-top: 36px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        padding: 0 27px;
        .list__item {
          display: flex;
          gap: 8px;
          align-items: center;
          justify-content: flex-start;
          .v-icon {
            @include fluidFont(24, 24, 1);
            color: rgba($skyBlue, 1);
          }
          p {
            @include fluidFont(14, 14, 1.3);
            font-weight: 400;
            color: rgba($blueDark, 1);
            letter-spacing: 0.4px;
            margin: 0;
            text-align: left;
          }
        }
      }
    }
    .empty__banner__footer {
      margin-top: 36px;
      @include respond(sm) {
        margin-top: 16px;
      }
    }
  }
}

// Card List

.review-card-list {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 24px 16px;
  text-align: left;
  color: $blueDark;

  @media (max-width: 1439px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }

  &__title {
    padding-bottom: 26px;
    border-bottom: 1px solid #c5d2f2;
    margin-bottom: 26px;
    text-align: left;
    color: $blueDark;
  }
}

.dashboard__btn {
  text-align: left;
  margin-bottom: 38px;
  .v-btn {
    height: auto;
    padding: 0;
    text-transform: capitalize;
    opacity: 1;
    color: rgba($blueDark, 1);
    background-color: rgba($white, 1);
    border-radius: 40px;
    padding: 1px 8px;
    @include fluidFont(12, 12, 1.2);
    font-weight: 500;
    :deep(.v-btn__prepend) {
      margin: 0;
    }
  }
}
</style>
