<template>
  <v-table
    height="450px"
    fixed-header
    class="tw-border-t-solid-custom tw-pb-4 tw-box-border !tw-rounded-none lg:tw-hidden"
  >
    <thead>
      <tr>
        <th class="text-left">
          <Text variant="p" textColor="rgba(12, 15, 74, 0.5)">Name</Text>
        </th>
        <th class="text-left">
          <Text
            variant="p"
            whiteSpace="nowrap"
            textColor="rgba(12, 15, 74, 0.5)"
            >Value</Text
          >
        </th>
        <th class="text-left">
          <Text
            variant="p"
            whiteSpace="nowrap"
            textColor="rgba(12, 15, 74, 0.5)"
            >Last updated on</Text
          >
        </th>
        <th class="text-left">
          <Text
            variant="p"
            whiteSpace="nowrap"
            textColor="rgba(12, 15, 74, 0.5)"
            >Last updated by:</Text
          >
        </th>
        <th class="text-left">Action</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(item, index) in allEPCCategoryDataList"
        :key="index"
        class="tw-cursor-pointer"
        :class="{
          'tw-bg-blue-100':
            state.selectedRow === item.id && state.selectedRow !== null,
        }"
        @click="onHandleClickRow($event, item)"
      >
        <td class="text-left">
          <div class="tw-flex tw-items-center tw-gap-2">
            <Text variant="span" class="rmd:!text-[14px]">{{
              formatString(item?.name)
            }}</Text>

            <div class="info-icon">
              <img src="../../../../assets/icons/info-icon-transparent.svg" />

              <div class="edit_history_popup">
                <div class="edit_history_popup__header">
                  <h3>Edit History</h3>
                  <v-btn
                    density="compact"
                    variant="text"
                    icon="mdi-close"
                  ></v-btn>
                </div>

                <div class="edit_history_popup__content">
                  <div v-if="item.updatedByWs">
                    <img
                      v-if="item.updatedByWs?.profileImage"
                      :src="`https://storage.googleapis.com/idgc-b10/${item.updatedByWs?.profileImage}`"
                      :alt="item.receiverWorkstation?.name"
                      width="20"
                      height="20"
                    />
                    <WorkStationProfileLogo
                      class="ws-logo"
                      v-else
                      :WSName="item.updatedByWs?.name"
                    />
                  </div>
                  <div v-else>
                    <img
                      src="../../../../assets/icons/government-img.png"
                      class="tw-w-full tw-h-full tw-object-cover tw-rounded-full"
                    />
                  </div>
                  <div class="edit_history_popup__content-right">
                    <Text
                      variant="span"
                      class="rmd:!text-[14px]"
                      textWeight="600"
                      textColor="#0C0F4A"
                      >{{
                        item?.updatedByWs?.name
                          ? item?.updatedByWs?.name
                          : "Government"
                      }}</Text
                    >
                    <Text variant="span" textWeight="400" textColor="#0C0F4A">{{
                      formatDateTime(item?.dateUpdated) || "--"
                    }}</Text>
                  </div>
                </div>
                <div class="edit_history_popup__footer">
                  <strong>Replaced:</strong>
                  <Text variant="span" class="rmd:!text-[14px]">
                    {{
                      item?.propertyEpcHistory?.length
                        ? item?.propertyEpcHistory[
                            item?.propertyEpcHistory?.length - 1
                          ].oldValue
                        : "N/A"
                    }}
                    with
                    <span>
                      {{
                        item?.propertyEpcHistory?.length
                          ? item?.propertyEpcHistory[
                              item?.propertyEpcHistory?.length - 1
                            ].newValue
                          : "N/A"
                      }}</span
                    >
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </td>
        <td class="text-left">
          <Text variant="p">{{
            item?.value ? `${item?.score} | ${item?.value}` : "--"
          }}</Text>
        </td>

        <td class="text-left">
          <div class="tw-flex tw-items-start">
            <Text
              variant="span"
              textWeight="400"
              textColor="rgba(12, 15, 74, 0.54)"
              >{{ formatDateTime(item?.dateUpdated) || "--" }}</Text
            >
          </div>
        </td>
        <td class="text-left">
          <div
            v-if="item?.updatedByWs"
            class="tw-flex tw-gap-2 tw-items-center"
          >
            <div
              class="tw-relative tw-w-[20px] tw-h-[20px] tw-rounded-full"
              v-if="item?.updatedByWs?.profileImage"
            >
              <img
                :src="`https://storage.googleapis.com/idgc-b10/${item.updatedByWs?.profileImage}`"
                :alt="item?.updatedByWs?.profileImage"
                width="20"
                height="20"
                class="tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full tw-object-cover tw-rounded-full"
              />
              <div
                class="tw-absolute tw-top-[-4px] tw-right-[-4px] tw-h-[12px] tw-w-[12px] tw-rounded-full tw-bg-[#24BD63] tw-text-white tw-text-[7px] tw-font-bold tw-text-center tw-uppercase tw-flex tw-items-center tw-justify-center"
              >
                <p>{{ item?.updatedByWs?.name[0] }}</p>
              </div>
            </div>
            <WorkStationProfileLogo
              class="ws-logo"
              v-else
              :WSName="item?.updatedByWs?.name"
            />
            <Text
              variant="span"
              textWeight="400"
              textColor="rgba(12, 15, 74, 0.54)"
              >{{ item?.updatedByWs?.name }}</Text
            >
          </div>
          <div v-else class="tw-flex tw-gap-2 tw-items-center">
            <div class="tw-relative tw-w-[20px] tw-h-[20px] tw-rounded-full">
              <img
                src="../../../../assets/icons/government-img.png"
                class="tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full tw-object-cover tw-rounded-full"
              />
              <div
                class="tw-absolute tw-top-[-4px] tw-right-[-4px] tw-h-[12px] tw-w-[12px] tw-rounded-full tw-bg-[#24BD63] tw-text-white tw-text-[7px] tw-font-bold tw-text-center tw-uppercase tw-flex tw-items-center tw-justify-center"
              >
                <p>G</p>
              </div>
            </div>
            <Text
              variant="span"
              textWeight="400"
              textColor="rgba(12, 15, 74, 0.54)"
              >Government</Text
            >
          </div>
        </td>
        <td class="text-left">
          <PencilCircularBlueIcon
            v-if="isUserHasEditRight"
            @click="onEdit(item)"
          />
        </td>
      </tr>
    </tbody>
  </v-table>

  <div
    class="property__card tw-hidden lg:tw-flex tw-gap-3 tw-items-center tw-flex-col tw-w-full tw-max-w-full"
    v-for="(item, index) in allEPCCategoryDataList"
    :key="index"
  >
    <ul
      class="tw-flex tw-justify-between tw-items-start tw-gap-2 tw-flex-col tw-p-4 tw-w-full tw-max-w-full tw-bg-white tw-rounded-lg"
    >
      <li
        class="tw-flex tw-justify-between tw-items-center tw-w-full tw-max-w-full"
      >
        <h3 class="tw-text-xs tw-text-[#0C0F4A80] tw-font-medium tw-leading-4">
          Name:
        </h3>
        <div class="tw-flex tw-items-center tw-gap-2">
          <p
            class="tw-text-[12px] rmd:!text-[14px] tw-text-[#0C0F4A] tw-font-medium tw-leading-4"
          >
            {{ formatString(item?.name) }}
          </p>

          <div class="info-icon">
            <img src="../../../../assets/icons/info-icon-transparent.svg" />

            <div class="edit_history_popup">
              <div class="edit_history_popup__header">
                <h3>Edit History</h3>
                <v-btn
                  density="compact"
                  variant="text"
                  icon="mdi-close"
                ></v-btn>
              </div>

              <div class="edit_history_popup__content">
                <div v-if="item.updatedByWs">
                  <img
                    v-if="item.updatedByWs?.profileImage"
                    :src="`https://storage.googleapis.com/idgc-b10/${item.updatedByWs?.profileImage}`"
                    :alt="item.receiverWorkstation?.name"
                    width="20"
                    height="20"
                  />
                  <WorkStationProfileLogo
                    class="ws-logo"
                    v-else
                    :WSName="item.updatedByWs?.name"
                  />
                </div>
                <div v-else>
                  <img
                    src="../../../../assets/icons/government-img.png"
                    class="tw-w-full tw-h-full tw-object-cover tw-rounded-full"
                  />
                </div>
                <div class="edit_history_popup__content-right">
                  <Text variant="p" textWeight="600" textColor="#0C0F4A">{{
                    item?.updatedByWs?.name
                      ? item?.updatedByWs?.name
                      : "Government"
                  }}</Text>
                  <Text variant="span" textWeight="400" textColor="#0C0F4A">{{
                    formatDateTime(item?.dateUpdated) || "--"
                  }}</Text>
                </div>
              </div>
              <div class="edit_history_popup__footer">
                <strong>Replaced:</strong>
                <Text variant="p">
                  {{
                    item?.propertyEpcHistory?.length
                      ? item?.propertyEpcHistory[
                          item?.propertyEpcHistory?.length - 1
                        ].oldValue
                      : "N/A"
                  }}
                  with
                  <span>
                    {{
                      item?.propertyEpcHistory?.length
                        ? item?.propertyEpcHistory[
                            item?.propertyEpcHistory?.length - 1
                          ].newValue
                        : "N/A"
                    }}</span
                  >
                </Text>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li
        class="tw-flex tw-justify-between tw-items-center tw-w-full tw-max-w-full"
      >
        <h3 class="tw-text-xs tw-text-[#0C0F4A80] tw-font-medium tw-leading-4">
          Value:
        </h3>
        <p
          class="tw-text-[12px] rmd:!text-[14px] tw-text-[#0C0F4A] tw-font-medium tw-leading-4 md:tw-text-right"
        >
          {{ item?.value ? `${item?.score} | ${item?.value}` : "--" }}
        </p>
      </li>
      <li
        class="tw-flex tw-justify-between tw-items-center tw-w-full tw-max-w-full"
      >
        <h3 class="tw-text-xs tw-text-[#0C0F4A80] tw-font-medium tw-leading-4">
          Last updated by:
        </h3>
        <p class="tw-text-xs tw-text-[#0C0F4A80] tw-font-medium tw-leading-4">
          {{ formatDateTime(item?.dateUpdated) || "--" }}
        </p>
      </li>
      <li
        class="tw-flex tw-justify-between tw-items-center tw-w-full tw-max-w-full"
      >
        <h3 class="tw-text-xs tw-text-[#0C0F4A80] tw-font-medium tw-leading-4">
          Last updated on:
        </h3>
        <div v-if="item?.updatedByWs" class="tw-flex tw-gap-2 tw-items-center">
          <div
            class="tw-relative tw-w-[20px] tw-h-[20px] tw-rounded-full"
            v-if="item?.updatedByWs?.profileImage"
          >
            <img
              :src="`https://storage.googleapis.com/idgc-b10/${item.updatedByWs?.profileImage}`"
              :alt="item?.updatedByWs?.profileImage"
              width="20"
              height="20"
              class="tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full tw-object-cover tw-rounded-full"
            />
            <div
              class="tw-absolute tw-top-[-4px] tw-right-[-4px] tw-h-[12px] tw-w-[12px] tw-rounded-full tw-bg-[#24BD63] tw-text-white tw-text-[7px] tw-font-bold tw-text-center tw-uppercase tw-flex tw-items-center tw-justify-center"
            >
              <p
                class="tw-text-xs tw-text-[#0C0F4A80] tw-font-medium tw-leading-4"
              >
                {{ item?.updatedByWs?.name[0] }}
              </p>
            </div>
          </div>
          <WorkStationProfileLogo
            class="ws-logo"
            v-else
            :WSName="item?.updatedByWs?.name"
          />
          <Text
            variant="span"
            textWeight="400"
            textColor="rgba(12, 15, 74, 0.54)"
            >{{ item?.updatedByWs?.name }}</Text
          >
        </div>
        <div v-else class="tw-flex tw-gap-2 tw-items-center">
          <div class="tw-relative tw-w-[20px] tw-h-[20px] tw-rounded-full">
            <img
              src="../../../../assets/icons/government-img.png"
              class="tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full tw-object-cover tw-rounded-full"
            />
            <div
              class="tw-absolute tw-top-[-4px] tw-right-[-4px] tw-h-[12px] tw-w-[12px] tw-rounded-full tw-bg-[#24BD63] tw-text-white tw-text-[7px] tw-font-bold tw-text-center tw-uppercase tw-flex tw-items-center tw-justify-center"
            >
              <p>G</p>
            </div>
          </div>
          <Text
            variant="span"
            textWeight="400"
            textColor="rgba(12, 15, 74, 0.54)"
            >Government</Text
          >
        </div>
      </li>
      <li
        class="tw-flex tw-justify-between tw-items-center tw-w-full tw-max-w-full"
      >
        <h3 class="tw-text-xs tw-text-[#0C0F4A80] tw-font-medium tw-leading-4">
          Action:
        </h3>
        <PencilCircularBlueIcon
          v-if="isUserHasEditRight"
          @click="onEdit(item)"
          class="tw-cursor-pointer"
        />
      </li>
    </ul>
  </div>
  <!-- Modals -->
</template>
<script setup>
import { computed, ref, reactive, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";

import ProfileThumbnail from "@/core/uni-components/ProfileThumbnail.vue";
import Text from "@/core/components/ui/general/Text.vue";
import PropertyListActionMenu from "@/modules/properties/components/PropertyListActionMenu.vue";
import PencilCircularBlueIcon from "@/core/components/icons/PencilCircularBlueIcon.vue";
import { CONNECTIONS } from "@/store/modules/connections";
import { PropertyConnectionRoleEnum } from "@/core/enums/PropertiesEnum";
import { PROPERTY_STORE } from "@/store/modules/property";
import appFilters from "@/filters";
import WorkStationProfileLogo from "@/core/components/WorkStationProfileLogo.vue";

const router = useRouter();
const route = useRoute();
const store = useStore();

const emits = defineEmits(["on-edit"]);

const state = reactive({
  selectedRow: null,

  result: [],
  delay: 200,
  clicks: 0,
  timer: null,

  list: [],

  selectedProperty: null,
});

const epcCategoryDataList = ref([]);
const loading = ref(false);

// Temporary data
const energyRatingData = ref([
  {
    id: 1,
    name: "Energy Efficiency Rating",
    value: "-",
    lastUpdated: {
      date: "-",
      time: "-",
    },
    LastUpdatedBy: {
      name: "-",
    },
  },
  {
    id: 2,
    name: "Current CO2",
    value: "-",
    lastUpdated: {
      date: "-",
      time: "-",
    },
    LastUpdatedBy: {
      name: "-",
    },
  },
  {
    id: 3,
    name: "Environmental Impact Current",
    value: "-",
    lastUpdated: {
      date: "-",
      time: "-",
    },
    LastUpdatedBy: {
      name: "-",
    },
  },
  {
    id: 4,
    name: "Potential Energy Rating",
    value: "-",
    lastUpdated: {
      date: "-",
      time: "-",
    },
    LastUpdatedBy: {
      name: "-",
    },
  },
  {
    id: 5,
    name: "EPC Lodgement Date",
    value: "-",
    lastUpdated: {
      date: "-",
      time: "-",
    },
    LastUpdatedBy: {
      name: "-",
    },
  },
  {
    id: 6,
    name: "Energy Tariff",
    value: "-",
    lastUpdated: {
      date: "-",
      time: "-",
    },
    LastUpdatedBy: {
      name: "-",
    },
  },
  {
    id: 7,
    name: "Potential CO2",
    value: "-",
    lastUpdated: {
      date: "-",
      time: "-",
    },
    LastUpdatedBy: {
      name: "-",
    },
  },
]);

const propertyDetails = computed(
  () => store.getters[`${PROPERTY_STORE}/propertyDetails`]
);

const currentPropertyConnectionRole = computed(
  () => store.getters[`${CONNECTIONS}/getPropertyConnectionRole`]
);
const isUserHasEditRight = computed(
  () =>
    currentPropertyConnectionRole.value !== PropertyConnectionRoleEnum.VIEWER
);

const allEPCCategoryDataList = computed(
  () => store.getters[`${PROPERTY_STORE}/epcCategoryDataList`]?.epcData
);

const onEdit = (item) => {
  emits("on-edit", item);
};

const onHandleClickRow = (event, item) => {
  state.clicks++;
  if (state.clicks === 1) {
    state.timer = setTimeout(() => {
      state.selectedRow = state.selectedRow === item.id ? null : item.id;
      state.result.push(event.type);
      state.clicks = 0;
    }, state.delay);
  } else {
    clearTimeout(state.timer);
    state.selectedProperty = item;
    state.selectedRow = item.id;
    state.clicks = 0;
    // emits("on-view-property", item);
  }
};

const initialize = () => {
  energyRatingData.value.forEach((item) => {
    switch (item.name) {
      case "Energy Efficiency Rating":
        item.value =
          propertyDetails.value.localData?.epcDomestic.energy
            ?.current_energy_efficiency +
          " | " +
          propertyDetails.value.localData?.epcDomestic.energy
            ?.current_energy_rating;
        break;
      case "Current CO2":
        item.value =
          propertyDetails.value.localData?.epcDomestic.co2_emissions_current +
          "t of CO2";
        break;
      case "Environmental Impact Current":
        item.value =
          propertyDetails.value.localData?.epcDomestic.environmental_impact_current;
        break;
      case "Potential Energy Rating":
        item.value =
          propertyDetails.value.localData?.epcDomestic.energy
            ?.potential_energy_efficiency +
          " | " +
          propertyDetails.value.localData?.epcDomestic.energy
            ?.potential_energy_rating;
        break;
      case "EPC Lodgement Date":
        item.value = appFilters.formatToDate(
          propertyDetails.value.localData?.epcDomestic.lodgement_date,
          "DD/MM/YYYY"
        );
        break;
      case "Energy Tariff":
        item.value =
          propertyDetails.value.localData?.epcDomestic.energy?.energy_tariff;
        break;
      case "Potential CO2":
        item.value =
          propertyDetails.value.localData?.epcDomestic.co2_emissions_potential;
        break;
      default:
        break;
    }

    // Update lastUpdated with lodgement_datetime split into date and time
    if (
      item.lastUpdated &&
      propertyDetails.value.localData?.epcDomestic.lodgement_datetime
    ) {
      const lodgementDateTime = new Date(
        propertyDetails.value.localData?.epcDomestic.lodgement_datetime
      );
      item.lastUpdated.date = appFilters.formatToDate(
        lodgementDateTime.toISOString().split("T")[0],
        "DD/MM/YYYY"
      ); // Date format YYYY-MM-DD
      item.lastUpdated.time = lodgementDateTime
        .toISOString()
        .split("T")[1]
        .substring(0, 8); // Time format HH:MM:SS
    }
  });
};
const propertyId = computed(() => route?.params?.propertyId);

const formatString = (str) => {
  return str
    .split("_") // Split the string by underscores
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word
    .join(" "); // Join them back with spaces
};

const formatDateTime = (dateString) => {
  if (!dateString) return "";

  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${day}/${month}/${year}, ${hours}:${minutes}`;
};

onMounted(async () => {
  if (propertyDetails.value.localData?.epcDomestic) {
    initialize();
  }
  loading.value = true;
  try {
    let params = {};

    params = Object.fromEntries(
      Object.entries(params).filter((value) =>
        value[1] === 0 ? true : value[1]
      )
    );
    epcCategoryDataList.value = await store.dispatch(
      `${PROPERTY_STORE}/fetchEPCDataList`,
      {
        propertyId: propertyId.value,
        params: params,
      }
    );
  } catch (error) {
    console.log(error, "error in fetching property details");
  } finally {
    loading.value = false;
  }
});
</script>
<style lang="scss" scoped>
.ws-logo {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}
.edit_history_popup {
  display: block;
  width: 348px;
  border-radius: 8px;
  box-shadow: 0px 0px 8px 0px #0c0f4a14;
  background-color: #fff;
  padding: 16px;
  color: $blueDark;
  margin-top: 16px;
  position: absolute;
  left: -20px;
  top: 100%;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: opacity 0.3s ease, visibility 0.3s ease;

  @media (max-width: 992px) {
    left: initial;
    right: -20px;
  }

  &::before {
    content: "";
    position: absolute;
    left: 22px;
    bottom: 100%;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0 10px 17.3px 10px;
    border-color: transparent transparent #fff transparent;
    transform: rotate(0deg);
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #c5d2f2;
    margin-bottom: 16px;

    :deep(.mdi-close) {
      font-size: 20px;
    }
  }

  h3 {
    @include fluidFont(18, 18, 1);
    font-weight: 700;
  }

  &__content {
    display: grid;
    grid-template-columns: 32px 1fr;
    align-items: center;
    grid-gap: 8px;

    p,
    span {
      display: block;
      line-height: 1;

      & + span {
        margin-top: 4px;
      }
    }

    .ws-logo,
    img {
      width: 32px;
      height: 32px;
      border-radius: 100%;
    }
  }

  &__footer {
    padding-top: 16px;
    border-top: 1px solid #c5d2f2;
    margin-top: 16px;

    .typography-p {
      font-weight: 400;
      margin-left: 4px;

      span {
        color: #4b4bff;
      }
    }
  }
}
.info-icon {
  position: relative;
  display: flex;
  width: 14px;
  height: 14px;
  img {
    cursor: pointer;
  }

  &:hover {
    .edit_history_popup {
      opacity: 1;
      visibility: visible;
      pointer-events: all;
    }
  }
}
</style>
