<template>
  <CommonDialog
    HeaderTittle="Pay Money Into Your E-Wallet"
    width="560px"
    :onClickBackButton="onClickBackButton"
    :className="['escrow_pay_money_e-wallet']"
  >
    <template #body>
      <div v-if="!loading" class="wallet_summary">
        <v-form
          ref="form"
          v-model="formValid"
          class="tw-max-w-full tw-mt-6 tw-flex tw-flex-col tw-gap-6"
        >
          <!--details -->
          <div class="tw-flex tw-gap-3">
            <div class="tw-w-1/2 tw-max-w-full tw-flex tw-gap-3 tw-flex-col">
              <div class="tw-flex tw-flex-col">
                <span
                  class="tw-text-xs tw-tracking-[0.25px] tw-leading-[22px] tw-font-medium tw-text-[#0c0f4a]"
                >
                  Beneficiary
                </span>
                <div class="tw-flex tw-items-center tw-justify-between">
                  <span
                    class="tw-text-[21px] tw-text-normal tw-leading-[30px] tw-font-normal tw-text-[#0c0f4a] tw-w-[calc(100%_-_20px)]"
                    >{{ eWalletDetails?.name }}</span
                  >
                  <button class="tw-flex">
                    <img src="../../../assets/icons/copy.svg" alt="" />
                  </button>
                </div>
              </div>
              <div class="tw-flex tw-flex-col">
                <span
                  class="tw-text-xs tw-tracking-[0.25px] tw-leading-[22px] tw-font-medium tw-text-[#0c0f4a]"
                >
                  Sort Code
                </span>
                <div class="tw-flex tw-items-center tw-justify-between">
                  <span
                    class="tw-text-[21px] tw-text-normal tw-leading-[30px] tw-font-normal tw-text-[#0c0f4a]"
                    >{{ eWalletDetails?.sortCode }}
                  </span>
                  <button class="tw-flex">
                    <img src="../../../assets/icons/copy.svg" alt="" />
                  </button>
                </div>
              </div>
            </div>
            <div class="tw-w-1/2 tw-max-w-full tw-flex tw-gap-3 tw-flex-col">
              <div class="tw-flex tw-flex-col">
                <span
                  class="tw-text-xs tw-tracking-[0.25px] tw-leading-[22px] tw-font-medium tw-text-[#0c0f4a]"
                >
                  Account number</span
                >
                <div class="tw-flex tw-items-center tw-justify-between">
                  <span
                    class="tw-text-[21px] tw-text-normal tw-leading-[30px] tw-font-normal tw-text-[#0c0f4a]"
                    >{{ eWalletDetails?.accountNumber }}</span
                  >
                  <button class="tw-flex">
                    <img src="../../../assets/icons/copy.svg" alt="" />
                  </button>
                </div>
              </div>
              <div class="tw-flex tw-flex-col">
                <span
                  class="tw-text-xs tw-tracking-[0.25px] tw-leading-[22px] tw-font-medium tw-text-[#0c0f4a]"
                >
                  Reference</span
                >
                <div class="tw-flex tw-items-center tw-justify-between">
                  <span
                    class="tw-text-[21px] tw-text-normal tw-leading-[30px] tw-font-normal tw-text-[#0c0f4a]"
                    >{{ eWalletDetails?.reference }}</span
                  >
                  <button
                    v-clipboard:copy="eWalletDetails?.reference"
                    class="tw-flex"
                  >
                    <img src="../../../assets/icons/copy.svg" alt="" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="amount_details">
            <div
              v-for="phase in selectedPhases"
              :key="phase?.id"
              class="tw-flex tw-items-center tw-justify-between"
            >
              <p
                class="tw-text-xs tw-tracking-[0.25px] tw-leading-[22px] tw-font-medium tw-text-[#0C0F4A]"
              >
                {{ phaseEstimateType(phase) }}
              </p>
              <strong
                class="tw-text-xs tw-tracking-[0.25px] tw-leading-[22px] tw-font-bold tw-text-[#0C0F4A]"
                >{{ formattedCurrency(phase?.subTotal) }}</strong
              >
            </div>
            <v-divider :thickness="1"></v-divider>

            <div class="tw-flex tw-items-center tw-justify-between tw-my-2">
              <p
                class="tw-text-xs tw-tracking-[0.25px] tw-leading-[22px] tw-font-medium tw-text-[#0C0F4A]"
              >
                Subtotal
              </p>
              <strong
                class="tw-text-xs tw-tracking-[0.25px] tw-leading-[22px] tw-font-bold tw-text-[#0C0F4A]"
                >{{ formattedCurrency(subTotalAmount) }}</strong
              >
            </div>
            <div class="tw-flex tw-items-center tw-justify-between tw-my-2">
              <p
                class="tw-text-xs tw-tracking-[0.25px] tw-leading-[22px] tw-font-medium tw-text-[#0C0F4A]"
              >
                vat
              </p>
              <strong
                class="tw-text-xs tw-tracking-[0.25px] tw-leading-[22px] tw-font-bold tw-text-[#0C0F4A]"
                >{{ formattedCurrency(vatTotal) }}</strong
              >
            </div>
            <v-divider :thickness="1"></v-divider>
            <div class="tw-flex tw-items-center tw-justify-between tw-my-2">
              <p
                class="tw-text-lg tw-tracking-[0.25px] tw-leading-[22px] tw-font-medium tw-text-[#0C0F4A]"
              >
                Total
              </p>
              <strong
                class="tw-text-lg tw-tracking-[0.25px] tw-leading-[22px] tw-font-bold tw-text-[#0C0F4A]"
                >{{ formattedCurrency(subTotalAmount + vatTotal) }}</strong
              >
            </div>
          </div>

          <div class="confirm_wrapper tw-mt-8">
            <Button type="submit" label="Done" @click="onConfirm" />
            <div
              class="confirm_wrapper_issue tw-flex tw-items-center tw-gap-2 md:tw-flex-col md:tw-items-start tw-mt-8"
            >
              <p
                class="tw-text-sm tw-tracking-[0.25px] tw-leading-[22px] tw-font-medium tw-text-[#0c0f4a80]"
              >
                Find Your Bank?
              </p>
              <span
                @click="onSelectRedirectBank"
                class="tw-text-sm tw-tracking-[0.25px] tw-leading-[22px] tw-font-bold tw-text-[#ffa500] tw-cursor-pointer"
              >
                Select your bank
              </span>
            </div>
          </div>
        </v-form>
      </div>
      <CommonLoader v-if="loading" :loading="loading" class="loader-123" />
    </template>
    <template #footer>
      <p
        v-if="!loading"
        class="tw-mt-6 tw-text-[10px] tw-tracking-[0.25px] tw-leading-[18px] tw-font-medium tw-text-[#0c0f4a80] tw-text-center"
      >
        By agreeing and continuing you consent to us requesting funds from your
        selected bank and sharing your account details with them.
        <span class="tw-text-[#0c0f4a]">Terms & conditions</span> apply. You
        will be redirect to your bank app for payment.
      </p>
    </template>
  </CommonDialog>
</template>

<script lang="ts" setup>
import {
  computed,
  ComputedRef,
  onMounted,
  ref,
  defineProps,
  defineEmits,
} from "vue";
import { useStore } from "vuex";
import { USER_STORE } from "@/store/modules/user";
import CommonDialog from "@/core/components/CommonDialog.vue";
import CurrencyFilter from "@/filters/currency.filter";
import { EscrowStatusEnum } from "@/core/enums/estimateEnum";
import { ProjectPhaseType } from "@/core/enums/ProjectsEnum";
import tradeEstimateService from "@/core/services/trade-estimate.service";
import Button from "@/core/components/ui/general/Button.vue";
const emits = defineEmits(["on-close", "on-redirect-bank", "on-confirm"]);
import CommonLoader from "@/core/components/CommonLoader.vue";

const formValid = ref(false);
const props = defineProps({
  selectedPhases: {
    type: Array,
  },
  subTotalAmount: {
    type: Number,
  },
  vatTotal: {
    type: Number,
  },
  projectId: {
    type: Number,
  },
});
const bankList = ref([]);

const phaseEstimateType = (phase: any) => {
  if (phase?.phaseType === ProjectPhaseType.MATERIAL) return "Materials";
  else return phase?.name;
};
const formattedCurrency = (value: any) => {
  return CurrencyFilter.formatToCurrency(value);
};
const selectedBank = ref(null);
const loading = ref(false);
const onClickBackButton = () => {
  emits("on-close");
};

const onSelectRedirectBank = () => {
  emits("on-redirect-bank");
};
const onConfirm = () => {
  emits("on-confirm");
};
const eWalletDetails = ref(null);
const copyToClipboard = (value: any) => {
  var tempTextarea = document.createElement("textarea");
  tempTextarea.value = value;

  document.body.appendChild(tempTextarea);

  tempTextarea.select();

  document.execCommand("copy");

  document.body.removeChild(tempTextarea);
};

onMounted(async () => {
  try {
    eWalletDetails.value = await tradeEstimateService.getEscrowEWalletDetails(
      props?.projectId
    );
    loading.value = true;
  } catch (error) {
    console.log("error", error);
  } finally {
    loading.value = false;
  }
});
</script>

<style lang="scss" scoped></style>
