<template>
  <CommonDialog
    :isShowHeader="false"
    :isShowBackButton="false"
    width="465px"
    max-width="100%"
    height="auto"
    :className="['restricted__modal_box']"
    v-if="isShowWelcomeModal"
  >
    <template v-slot:body>
      <div
        class="dashboard-welcome__close !tw-top-8 !tw-right-8"
        @click="onCloseModal"
      >
        <v-icon icon="mdi-close"></v-icon>
      </div>
      <div class="header-title">Unlock the Full Experience</div>
      <div class="welcome__image">
        <img src="../../../assets/images/restricted-area-2.png" alt="" />
      </div>
      <div class="header-sub-title" v-if="isDefault">
        This was a taste of the premium features. Create your
        {{
          user.role?.name === UserRolesEnum.TRADESPERSON ? "Trade" : "Property"
        }}
        Workstation today and enjoy the full experience.
      </div>
      <div class="header-sub-title" v-else>
        Verification Required. Your Workstation/ID, requires verification. Once
        completed - your access all area pass will be assigned.
      </div>
    </template>
    <template v-slot:footer
      ><v-btn
        v-if="isDefault"
        @click="onCreateWorkstation"
        class="button button-purple"
        >Create a
        {{
          user.role?.name === UserRolesEnum.TRADESPERSON ? "Trade" : "Property"
        }}
        Workstation</v-btn
      ><v-btn v-else @click="onCloseModal" class="button button-orange"
        >Verification Workstation/ID</v-btn
      ></template
    >
  </CommonDialog>
</template>

<script lang="ts">
import CommonDialog from "@/core/components/CommonDialog.vue";
import { USER_STORE } from "@/store/modules/user";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { WORKSTATION } from "@/store/modules/workstation";
import { UserRolesEnum } from "@/core/enums/RolesEnum";
import { useRouter } from "vue-router";
import { DASHBOARD_ROUTE } from "@/modules/dashboard/routes";
export default {
  name: "PageRestrictionNoticeModal",
  components: {
    CommonDialog,
  },
  setup(props: any, ctx: any) {
    const store = useStore();
    const router = useRouter();
    const user = computed(() => store.getters[`${USER_STORE}/user`]);

    const userName = computed(
      () =>
        `${user.value?.firstName || "user"} ${user.value?.lastName || "name"}`
    );
    const activeUserWorkstation = computed(
      () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
    );

    const isDefault = computed(() => {
      return activeUserWorkstation.value?.name === "Default";
    });
    const isShowWelcomeModal = ref(true);

    const onCloseModal = () => {
      ctx.emit("on-close");
    };

    const onCreateWorkstation = () => {
      onCloseModal();
      router.push({ name: DASHBOARD_ROUTE });
      if (user.value?.role?.name === UserRolesEnum.TRADESPERSON) {
        store.dispatch(`${WORKSTATION}/setWsModal`, {
          isShowWorkStationModal: true,
        });
      }
    };
    return {
      user,
      userName,
      isShowWelcomeModal,
      UserRolesEnum,
      onCloseModal,
      onCreateWorkstation,
      isDefault,
    };
  },
};
</script>
