<template>
  <GeneralDialog
    class="tw-max-w-full"
    @on-close="onCloseModal"
    :showBackButton="stepper === 1"
    :class="{
      '!tw-w-[460px] tw-max-w-full': stepper === 0,
      '!tw-w-[570px] !tw-max-w-full md:tw-w-[340px]': stepper === 1,
    }"
    @on-back="onClickBackButton"
  >
    <template #body>
      <template v-if="stepper === 2">
        <div
          class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4"
        >
          <img
            src="@/core/uni-assets/svgs/check-big.svg"
            class="tw-w-[68px] tw-h-[68px]"
          />
          <Text variant="h4" textAlign="center" lineHeight="28px"
            >Your Site Visit Request has been
            {{ siteId ? "edit request" : "submit request" }}</Text
          >
          <Text
            class="tw-max-w-[80%] tw-mt-6"
            variant="h6"
            textWeight="500"
            textColor="rgba(12, 15, 74, 0.5)"
            textAlign="center"
          >
            You’ll be notified once your request gets accepted by the property
            owner
          </Text>
          <Button variant="primary" label="close" @click="onCloseModal" />
        </div>
      </template>
      <template v-if="stepper === 1">
        <Text variant="h5" lineHeight="28px" textWeight="400"
          >Request Site Visit</Text
        >
        <v-form
          ref="form"
          v-model="formValid"
          class="tw-max-w-full tw-mt-6 tw-flex tw-flex-col tw-gap-6"
          @submit.prevent="onSubmit"
        >
          <div class="custom_input_field">
            <Text variant="span" textWeight="400" class="tw-mb-2">
              Select Property
            </Text>
            <v-select
              variant="outlined"
              class="custom_selector"
              v-model="selectedProperty"
              :items="propertyList"
              item-title="address"
              item-value="id"
              :menu-props="{ contentClass: 'text__selector' }"
              placeholder="Select Property"
              :rules="[(v) => !!v || 'Property is required']"
              required
              @update:model-value="selectSiteProperty"
            ></v-select>
          </div>
          <div class="custom_input_field">
            <v-text-field
              class="custom_selector"
              variant="outlined"
              v-model="selectedDate"
              label="Select Date"
              type="date"
              :rules="[(v) => !!v || 'Date is required']"
              required
              :min="new Date().toISOString().substr(0, 10)"
            ></v-text-field>
          </div>
          <div class="custom_input_field">
            <VueDatePicker
              variant="outlined"
              class="date_selector"
              v-model="selectedTime"
              time-picker
              range
              required
              placeholder="Select Time"
              :time-picker-options="{ format: 'hh:mm A' }"
              @update:model-value="updateTimeValidation"
            ></VueDatePicker>
            <Text
              v-if="timeError"
              variant="span"
              textWeight="400"
              class="tw-mb-2"
              textColor="red"
            >
              Time is required
            </Text>
          </div>
          <div
            class="custom_input_field tw-pb-6 tw-rounded-none tw-border-[#C5D2F2] tw-border-0 tw-border-b tw-border-solid"
          >
            <span></span>
            <Text variant="span" textWeight="400" class="tw-mb-2">
              Message (optional)
            </Text>
            <v-textarea
              v-model="message"
              placeholder="Message"
              required
              variant="outlined"
              class="custom_selector"
            ></v-textarea>
          </div>
          <Button
            class="button button-purple-border !tw-w-1/2 md:!tw-w-full"
            :label="siteId ? 'edit request' : 'submit request'"
            type="submit"
          />
        </v-form>
      </template>
      <div
        v-if="stepper === 0"
        class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4 tw-max-w-[90%] tw-m-auto"
      >
        <Text variant="h4" textAlign="center" lineHeight="28px"
          >Do you want to request a site visit?</Text
        >
        <Text
          class="tw-mt-6"
          variant="h6"
          textWeight="500"
          textColor="rgba(12, 15, 74, 0.5)"
          textAlign="center"
        >
          If you do not want to do it right now, you can always do it later.
        </Text>
      </div>
    </template>
    <template #footer>
      <div
        v-if="stepper === 0"
        class="tw-w-full tw-grid tw-grid-cols-2 tw-items-center tw-gap-3"
      >
        <Button
          variant="secondary"
          label="i'll do it later"
          @click="onCloseModal"
        />
        <Button variant="primary" label="yes" @click="() => stepper++" />
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup lang="ts">
import GeneralDialog from "@/core/uni-components/GeneralDialog.vue";
import Button from "@/core/uni-components/Button.vue";
import Text from "@/core/uni-components/Text.vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { ref } from "vue";
import { JOBS_STORE } from "@/store/modules/jobs";
import { useStore } from "vuex";
import { computed } from "vue";
import { JOB_TEMPLATE_STORE } from "@/store/modules/job-template";
import { onMounted } from "vue";
import { WORKSTATION } from "@/store/modules/workstation";

const stepper = ref(0);
const store = useStore();
const emits = defineEmits(["on-close"]);

const selectedProperty = ref(null);
const selectedDate = ref("");
const selectedTime = ref("");
const message = ref("");
const timeError = ref(false);
const onClickBackButton = () => {
  selectedDate.value = "";
  selectedProperty.value = null;
  message.value = "";
  timeError.value = true;
  stepper.value--;
};
const siteVisitRequests = ref([]);
const siteId = ref(null);
const onCloseModal = () => {
  emits("on-close");
};

const props = defineProps({
  projectId: Number,
  projectEstimateId: Number,
});
const updateTimeValidation = (value) => {
  if (!value) timeError.value = true;
  else timeError.value = false;
};
const currentJobDetails = computed(
  () => store.getters[`${JOBS_STORE}/getJobDetails`]
);

const propertyList = computed(() => {
  return currentJobDetails.value.projectProperties.map(
    (property: any) => property.property
  );
});
const formValid = ref(false);

const onSubmit = async () => {
  const formData = {
    id: siteId.value ? parseInt(siteId.value) : null,
    property: selectedProperty.value,
    date: selectedDate.value,
    startTime: JSON.stringify(selectedTime.value),
    description: message.value,
  };

  if (!selectedTime.value) {
    timeError.value = true;
    return;
  } else {
    timeError.value = false;
  }

  if (!timeError.value && formValid.value) {
    stepper.value++;

    if (props.projectEstimateId && props.projectId) {
      try {
        await store.dispatch(`${JOB_TEMPLATE_STORE}/setSiteRequest`, {
          projectEstimateId: props.projectEstimateId,
          projectId: props.projectId,
          formData,
        });
      } catch (error) {
        console.log();
      }
    }
  }
};
const selectSiteProperty = (value: any) => {
  const isExistRequest = siteVisitRequests.value.find(
    (request: any) => request.property?.id === value
  );

  if (isExistRequest) {
    siteId.value = isExistRequest?.id;
    selectedDate.value = isExistRequest?.date;
    selectedTime.value = JSON.parse(isExistRequest?.startTime);
    message.value = isExistRequest?.description;
  } else {
    selectedDate.value = "";
    selectedTime.value = "";
    message.value = "";
    siteId.value = null;
  }
};

const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);

onMounted(async () => {
  siteVisitRequests.value = await store.dispatch(
    `${JOB_TEMPLATE_STORE}/getSiteRequestAll`,
    {
      projectId: props.projectId,
      wsID: activeUserWorkstation?.value?.id,
    }
  );
});
</script>
<style lang="scss" scoped>
.custom_selector {
  :deep(.v-input__control) {
    .v-field {
      background-color: transparent;
      border: 1px solid rgba($buttonText, 0.4);
      border-radius: 8px;
      padding: 0;
      .v-field__field {
        .v-field__input {
          min-height: 40px;
          padding: 12px 16px;
          @include fluidFont(12, 12, 1.2);
          font-weight: 400;
          color: rgba($blueDark, 1);
          letter-spacing: 0.4px;
          opacity: 1;
          .v-select__selection {
            .v-select__selection-text {
              @include fluidFont(12, 12, 1.2);
              font-weight: 400;
              color: rgba($blueDark, 1);
              letter-spacing: 0.4px;
            }
          }
          input {
            @include fluidFont(12, 12, 1.2);
            font-weight: 400;
            color: rgba($blueDark, 1);
            letter-spacing: 0.4px;
            &::placeholder {
              @include fluidFont(12, 12, 1.2);
              font-weight: 400;
              color: rgba($blueDark, 1);
              letter-spacing: 0.4px;
              opacity: 1;
            }
          }
        }
      }
      .v-field__outline {
        display: none;
      }
    }
  }
}

.date_selector {
  :deep(.dp__input_wrap) {
    .dp__input {
      min-height: 40px;
      @include fluidFont(12, 12, 1.2);
      font-weight: 400;
      color: rgba($blueDark, 1);
      letter-spacing: 0.4px;
      padding: 12px 30px;
      border-radius: 8px;
    }
    &::placeholder {
      @include fluidFont(12, 12, 1.2);
      font-weight: 400;
      color: rgba($blueDark, 1);
      letter-spacing: 0.4px;
      opacity: 1;
    }
  }
}
</style>
