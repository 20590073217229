<template>
  <div class="estimate_summery">
    <div class="estimate_summery_left estimate_card">
      <div class="estimate_card_title">Your Jobs Escrow Wallet</div>
      <div class="estimate_summery_amount">
        {{ formattedCurrency(escrowBalance) }}
      </div>
      <div class="estimate_summery_amount_label"><p>Available Balance</p></div>
    </div>
    <div class="estimate_summery_right">
      <div class="estimate_summery_header estimate_card">
        <div class="estimate_summery_header_title">
          <h2>{{ projectDetails?.name }}</h2>
        </div>
        <div class="estimate_summery_header_info">
          <div class="estimate_summery_header_label">
            <img
              src="@/assets/images/trades.svg"
              alt="Trades Icon"
              width="24"
              height="24"
            />
            <p>Commercial</p>
          </div>
          <div class="estimate_summery_header_id">
            <p><span>Project ID:</span> #{{ projectDetails?.refId }}</p>
          </div>
        </div>
      </div>

      <div class="estimate_summery_list_title">
        <h3>Estimate</h3>
      </div>

      <div class="estimate_summery_list_inner estimate_card">
        <div class="estimate_summery_info_list">
          <div class="estimate_card_title">Your Estimate Summary</div>

          <div class="estimate_summery_info_list_inner">
            <div class="estimate_summery_info_list_box">
              <div class="estimate_summery_info_list_box_title">
                <p>
                  {{ getProjectEstimateDataWithEscrow.suggestedStartDate }}
                </p>
              </div>

              <p>Start Date</p>
            </div>
            <div class="estimate_summery_info_list_box">
              <div class="estimate_summery_info_list_box_title">
                <p>
                  {{ getProjectEstimateDataWithEscrow.estimationPhase.length }}
                </p>
              </div>
              <p>Total phases</p>
            </div>
            <div class="estimate_summery_info_list_box">
              <div class="estimate_summery_info_list_box_title">
                <p>{{ getTotalDays }} Days</p>
              </div>
              <p>Total Duraution</p>
            </div>
            <div class="estimate_summery_info_list_box">
              <div class="estimate_summery_info_list_box_title">
                <p>{{ getTotalCost }}</p>
              </div>
              <p>Total Project Cost</p>
            </div>
          </div>
        </div>

        <div class="estimate_summery_list_title hide-desktop">
          <h3>Estimate</h3>
        </div>

        <div class="estimate_summery_list_items">
          <v-expansion-panels
            v-if="filterEstimatePhases.length"
            :disabled="disabled"
            multiple
          >
            <EstimatePhaseModal
              v-for="phase in filterEstimatePhases"
              :phaseDetails="phase"
              :estimateDetails="getProjectEstimateDataWithEscrow"
              :key="phase.id"
            />
          </v-expansion-panels>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

import { PROJECT_STORE } from "@/store/modules/project";
import { useStore } from "vuex";
import { computed } from "vue";
import CurrencyFilter from "@/filters/currency.filter";
import { onMounted } from "vue";
import moment from "moment";
import EstimatePhaseModal from "@/modules/project/components/EstimatePhaseModal.vue";

const panel = ref([0, 1]);
const disabled = ref(false);
const store = useStore();

const projectDetails = computed(
  () => store.getters[`${PROJECT_STORE}/projectDetails`]
);

const escrowBalance = computed(
  () => store.getters[`${PROJECT_STORE}/getProjectEstimateEscrowBalance`]
);
const formattedCurrency = (value) => {
  return CurrencyFilter.formatToCurrency(value);
};
const getProjectEstimateDataWithEscrow = computed(
  () => store.getters[`${PROJECT_STORE}/getProjectEstimateDataWithEscrow`]
);
const getTotalDays = computed(() => {
  if (!getProjectEstimateDataWithEscrow.value) return 0;
  const startDate = moment(
    getProjectEstimateDataWithEscrow.value.suggestedStartDate
  );
  const endDate = moment(
    getProjectEstimateDataWithEscrow.value.projectEstimateEndDate
  );
  return endDate.diff(startDate, "days");
});

const totalPhaseAmount = (amount, vat, isFirstPhase) => {
  const SERVICE_CHARGE = 0.03;
  const PHASE_FIRST_CHARGE = 5;
  let sum = 0;

  sum += amount + amount * vat;
  sum += sum * SERVICE_CHARGE;
  if (isFirstPhase) {
    sum += PHASE_FIRST_CHARGE;
  }
  return sum;
};
const getTotalCost = computed(() => {
  const totalCost = filterEstimatePhases.value.reduce((accumulator, phase) => {
    const phaseTotal = totalPhaseAmount(
      phase?.subTotal,
      phase?.vat,
      phase?.isOld
    );
    return accumulator + phaseTotal;
  }, 0);
  return CurrencyFilter.formatToCurrency(totalCost);
});

const filterEstimatePhases = computed(() => {
  return getProjectEstimateDataWithEscrow.value?.estimationPhase;
});
</script>

<style lang="scss" scoped>
.estimate_card {
  border-radius: 8px;
  background-color: $white;
  padding: 24px;
  box-shadow: 0px 0px 8px 0px #0c0f4a14;

  &_title {
    @include fluidFont(18, 18, 2);
    letter-spacing: 0.25px;
  }
}

.task_images {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 4;

  .task_images_list {
    width: 22px;
    height: 22px;
    border-radius: 100%;
    overflow: hidden;
    margin-right: -5px;

    &:last-child {
      margin-right: 0;
    }

    $a: 1;

    @for $i from 1 through 5 {
      &:nth-child(3n + #{$i}) {
        z-index: -$a;
        $a: $a + 1;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &:first-child {
    margin-left: 0;
  }
}

.estimate_summery {
  display: grid;
  grid-template-columns: 388px 1fr;
  grid-gap: 16px;
  align-items: flex-start;
  text-align: left;
  color: $blueDark;

  @media (max-width: 1439px) {
    grid-template-columns: 1fr;
    display: flex;
    flex-direction: column;
    grid-gap: 0;
    gap: 16px;
  }

  &_left {
    width: 100%;

    .estimate_card_title {
      margin-bottom: 22px;
    }
  }

  &_amount {
    font-weight: 700;
    @include fluidFont(38, 38, 25px);
    letter-spacing: 0.25px;

    &_label {
      @include fluidFont(14, 14, 18px);
      font-weight: 500;
      letter-spacing: 1px;
      color: rgba($blueDark, 0.5);
      margin-top: 18px;
    }
  }

  &_header {
    box-shadow: none;
    font-weight: 600;
    margin-bottom: 30px;

    @include respond(s720) {
      display: none;
    }

    h2 {
      font-weight: 600;
      @include fluidFont(25, 25, 25px);
      letter-spacing: 0.64px;
    }

    &_info {
      display: flex;
      align-items: center;
      gap: 42px;
      flex-wrap: wrap;
      margin-top: 15px;
    }

    &_label {
      display: grid;
      grid-template-columns: 18px 1fr;
      grid-gap: 5px;
      font-weight: 700;
      @include fluidFont(14, 14, 1);
      color: $info;
      flex-shrink: 0;

      img {
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }

    &_id {
      @include fluidFont(14, 14, 1);

      span {
        color: rgba($blueDark, 0.5);
      }
    }
  }

  &_list {
    &_title {
      margin-bottom: 24px;

      h3 {
        @include fluidFont(18, 20, 22px);
        letter-spacing: 0.25px;
      }
    }

    &_inner {
      padding: 16px;

      @include respond(s720) {
        padding: 0;
        box-shadow: none;
        background-color: transparent;
        border-radius: 0;
      }
    }
  }

  &_info_list {
    padding-bottom: 12px;
    border-bottom: 1px solid #c5d2f2;
    margin-bottom: 30px;

    @include respond(s720) {
      padding: 16px 14px;
      background-color: $white;
      box-shadow: 0px 0px 8px 0px #0c0f4a14;
      border: 0;
      border-radius: 6px;
      margin-bottom: 22px;
    }

    .estimate_card_title {
      font-weight: 700;
      margin-bottom: 12px;

      @include respond(s720) {
        margin-bottom: 0;
      }
    }

    &_inner {
      display: grid;
      grid-template-columns: repeat(4, minmax(0, 1fr));
      grid-gap: 8px;

      @include respond(s720) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
    }

    &_box {
      background-color: #f3f3f6;
      border-radius: 8px;
      padding: 8px;
      min-height: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      @include fluidFont(10, 10, 12px);
      letter-spacing: 0.25px;
      color: rgba($blueDark, 0.7);

      &_title {
        @include fluidFont(12, 12, 25px);
        color: $blueDark;
        font-weight: 700;
      }
    }
  }

  &_item {
    &_header {
      &_left {
        position: relative;
        display: flex;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
      }

      &_right {
        position: relative;
        flex: auto;
        display: flex;
        align-items: center;
        gap: 30px;
        margin-left: auto;
        justify-content: flex-end;
      }
    }

    &_title {
      @include fluidFont(14, 10, 17px);
      font-weight: 700;

      .paid-item & {
        color: #eb8c49;
      }

      .in-progress & {
        color: #d84f50;
      }
    }

    &_body {
      padding: 20px 24px;

      @include respond(s720) {
        padding: 28px 20px;
      }
    }

    &_info_list {
      display: flex;
      align-items: flex-start;
      gap: 32px 84px;
      letter-spacing: 0.25px;
      flex-wrap: wrap;

      @include respond(s720) {
        gap: 32px 62px;
      }
    }

    &_info_item {
      position: relative;

      @include respond(s720) {
        display: flex;
        flex-direction: column-reverse;
      }

      &:not(:last-child) {
        &::after {
          content: "";
          position: absolute;
          right: -54px;
          top: 0;
          height: 100%;
          width: 1px;
          background-color: rgba($blueDark, 0.1);

          @include respond(s720) {
            right: -30px;
          }
        }
      }
    }

    &_info_label {
      @include fluidFont(10, 12, 1);
      color: rgba($blueDark, 0.8);
      margin-bottom: 2px;
    }

    &_info_text {
      @include fluidFont(16, 16, 25px);
      font-weight: 700;
    }

    &_footer {
      background-color: rgba($blueDark, 0.1);
      padding: 10px;
      @include fluidFont(12, 10, 12px);
      color: #6c6d8e;
      border-radius: 0 0 8px 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 30px;

      @include respond(s720) {
        border-radius: 0 0 10px 10px;
      }
    }

    &_controls {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 30px;

      @include respond(s720) {
        gap: 10px;
      }
    }

    &_control {
      position: relative;
      display: flex;
      align-items: center;
      gap: 12px;

      @include respond(s720) {
        gap: 8px;
      }

      img {
        width: auto;
        height: auto;
        max-height: 18px;
        object-fit: contain;
        flex-shrink: 0;

        @include respond(s720) {
          max-height: 14px;
        }
      }

      &:not(:last-child) {
        &::after {
          content: "";
          position: absolute;
          right: -15px;
          top: 0;
          height: 100%;
          width: 1px;
          background-color: rgba($blueDark, 0.2);

          @include respond(s720) {
            right: -4px;
          }
        }
      }
    }

    &_author {
      width: 18px;
      height: 18px;
      border-radius: 100%;
      position: relative;
      overflow: hidden;

      img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        max-height: 100%;
        max-width: 100%;
      }
    }
  }

  &_status {
    display: flex;
    align-items: center;
    gap: 6px;
    @include fluidFont(10, 10, 1);
    text-transform: uppercase;
    color: rgba($blueDark, 0.5);
    background-color: rgba($blueDark, 0.05);
    border-radius: 5px;
    min-height: 20px;
    padding: 2px 10px;
    font-weight: 600;

    .in-progress & {
      background-color: rgba($blueDark, 0.05);
      color: $blueDark;
    }

    .paid-item & {
      background-color: rgba($PrimaryGreen, 0.1);
      color: $PrimaryGreen;
    }

    .mdi-check-circle {
      flex-shrink: 0;
      font-size: 16px;
    }

    img {
      flex-shrink: 0;
      max-width: 12px;
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }

  &_tag {
    min-height: 32px;
    border-radius: 5px;
    color: $white;
    background-color: #ffa500;
    text-transform: uppercase;
    font-weight: 700;
    padding: 8px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    @include respond(s720) {
      margin-top: 20px;
    }

    .in-progress & {
      background-color: #e7e7ed;
      color: rgba($blueDark, 0.54);
    }

    .paid-item & {
      background-color: rgba($PrimaryGreen, 0.1);
      color: $PrimaryGreen;
    }
  }

  .v-expansion-panel {
    border-radius: 8px;

    @include respond(s720) {
      border-radius: 10px;
    }

    &-title {
      min-height: 56px;
      display: flex;
      align-items: center;
      gap: 34px;
      justify-content: space-between;
      padding: 12px 24px;

      @include respond(s720) {
        gap: 10px;
        min-height: 46px;
      }
    }
  }

  &_notes {
    background-color: rgba(#f3f3f6, 0.6);
    border-radius: 6px;
    padding: 10px;

    &_list {
      margin-top: 20px;
      padding-top: 20px;
      border-top: 1px solid rgba(#c5d2f2, 0.3);
      display: grid;
      grid-gap: 10px;

      @include respond(s720) {
        border-top: 0;
        padding-top: 0;
        margin-top: 12px;
      }
    }

    &_title {
      background-color: rgba(#4a92e5, 0.1);
      @include fluidFont(12, 10, 1);
      border-radius: 5px;
      min-height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-weight: 600;
      color: #4a92e5;
      padding: 4px 10px;
    }

    &_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;

      @include respond(s720) {
        flex-direction: column;
        gap: 14px;
        align-items: flex-start;
      }

      &_left {
        display: flex;
        align-items: center;
        gap: 6px;
        flex: auto;

        @include respond(s720) {
          width: 100%;
        }
      }

      &_right {
        display: flex;
        align-items: center;
        gap: 13px;
      }
    }

    &_author {
      margin-left: auto;
    }

    &_body {
      @include fluidFont(12, 12, 12px);
      padding-top: 18px;
      font-weight: 500;

      @include respond(s720) {
        padding-top: 14px;
      }
    }

    &_info {
      display: flex;
      align-items: center;
      gap: 26px;

      &_item {
        position: relative;
        @include fluidFont(10, 10, 12px);
        color: rgba($blueDark, 0.5);
        letter-spacing: 0.25px;

        &:not(:last-child) {
          &::after {
            content: "";
            position: absolute;
            right: -14px;
            top: 0;
            height: 100%;
            width: 1px;
            background-color: rgba($blueDark, 0.1);
          }
        }
      }
    }
  }

  .display-mobile {
    display: none;

    @include respond(s720) {
      display: flex;
    }
  }

  .hide-mobile {
    @include respond(s720) {
      display: none;
    }
  }

  .checkbox {
    .mdi-check-circle {
      font-size: 18px;
    }
  }

  --v-selection-control-size: 16px !important;
}

.estimate_tooltip {
  :deep(.v-overlay__content) {
    background-color: rgba($blueDark, 0.88);
    @include fluidFont(10, 10, 1);
    letter-spacing: 0.25px;
  }
}

:deep {
  .v-selection-control--density-default {
    --v-input-control-height: 16px;
    --v-selection-control-size: 16px !important;
  }

  .v-expansion-panel__shadow {
    box-shadow: 0px 0px 8px 0px #0c0f4a14;
  }

  .v-expansion-panel-title__overlay {
    background-color: rgba($info, 0.1);
    opacity: 1;

    .paid-item & {
      background-color: #fdf4ed;
    }

    .in-progress & {
      background-color: rgba(#d84f50, 0.1);
    }
  }

  .v-expansion-panel-text__wrapper {
    padding: 0;
  }
}
</style>
