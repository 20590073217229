/**
 * Project route page
 */
import ProjectRoute from "@/modules/project/pages/ProjectOverviewPage.vue";
import ProjectDetailsPage from "@/modules/project/pages/ProjectDetailsPage.vue";
import EstimatesReceivedMainComponent from "@/modules/project/components/EstimatesReceivedMainComponent.vue";
import ProjectRequestedDetailPage from "@/modules/project/pages/ProjectRequestedDetailPage.vue";
import RequestedProjectEstimate from "@/modules/project/pages/RequestedProjectEstimate.vue";
export const PROJECT_ROUTE = "project";
export const PROJECT_DETAILS_ROUTE = "projectDetails";
export const TRADE_ESTIMATES_ROUTE = "tradeEstimates";
export const PROJECT_REQUESTED_DETAILS_ROUTE = "projectRequestedDetails";
export const REQUEST_PROJECT_ESTIMATE_ROUTE = "requestedProjectEstimate";
import TradePassportRoute from "@/modules/trade-passport/pages/TradePassport.vue";
import HistoricProjectDetailsPage from "@/modules/project/pages/HistoricProjectDetailsPage.vue";
export const TRADE_ESTIMATES_TRADE_PASSPORT = "tradeEstimatesTradePassport";
export const HISTORIC_PROJECT_DETAILS_ROUTE = "historicProjectDetails";

export default [
  {
    path: "/project",
    name: PROJECT_ROUTE,
    component: ProjectRoute,
    meta: {
      validateAuth: true,
      requiresAuth: true,
      title: "Projects",
    },
  },
  {
    path: "/project-details/:refId",
    name: PROJECT_DETAILS_ROUTE,
    component: ProjectDetailsPage,
    meta: {
      validateAuth: true,
      requiresAuth: true,
      title: "Project Details",
    },
  },
  {
    path: "/trade-estimates/:estimateId",
    name: TRADE_ESTIMATES_ROUTE,
    component: EstimatesReceivedMainComponent,
    meta: {
      validateAuth: true,
      requiresAuth: true,
      title: "Estimate Received",
    },
  },
  {
    path: "/requested-project-details/:refId",
    name: PROJECT_REQUESTED_DETAILS_ROUTE,
    component: ProjectRequestedDetailPage,
    meta: {
      validateAuth: true,
      requiresAuth: true,
      title: "Projects",
    },
  },
  {
    path: "/requested-project-details/:refId/:estimateId",
    name: REQUEST_PROJECT_ESTIMATE_ROUTE,
    component: RequestedProjectEstimate,
    meta: {
      validateAuth: true,
      requiresAuth: true,
      title: "Projects",
    },
  },
  {
    path: "/trade-estimates/trade-passport",
    name: TRADE_ESTIMATES_TRADE_PASSPORT,
    component: TradePassportRoute,
    meta: {
      validateAuth: true,
      requiresAuth: true,
      title: "Trade Passport",
    },
  },
  {
    path: "/historic-project-details/:refId/:estimateId",
    name: HISTORIC_PROJECT_DETAILS_ROUTE,
    component: HistoricProjectDetailsPage,
    meta: {
      validateAuth: true,
      requiresAuth: true,
      title: "Historic Project Details",
    },
  },
];
