import { Commit } from "vuex";
import projectRatingService from "@/core/services/project-rating.service";
import { ProjectRatingTypeEnum } from "@/core/enums/ProjectsEnum";

export default {
  async getAllProjectReviewList(
    { commit }: { commit: Commit },
    type: ProjectRatingTypeEnum
  ) {
    try {
      const response = await projectRatingService.getAllProjectRating(type);
      commit("setProjectReviewList", response);
      return response;
    } catch (error) {
      return [];
    }
  },
  async resetAllProjectReviewList({ commit }: { commit: Commit }) {
    commit("setProjectReviewList", []);
  },
};
