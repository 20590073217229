<template>
  quam earum?
  <GeneralDialog @on-close="onCloseModal" width="900px">
    <template #body>
      <section
        class="tw-flex tw-w-full tw-items-center tw-justify-center tw-gap-4 tw-px-8 tw-box-border"
      >
        <div
          class="tw-w-full tw-h-[411px] tw-bg-[#F1F6FB] tw-rounded-lg tw-flex tw-flex-col tw-justify-between tw-gap-4 tw-p-4 tw-box-border"
        >
          <Text variant="h6" textWeight="600">Upload images and files</Text>
          <div class="tw-h-full tw-flex tw-flex-col tw-items-center tw-gap-1">
            <Text
              variant="span"
              textColor="rgba(12, 15, 74, 0.5)"
              textWeight="500"
              lineHeight="20px"
              textAlign="center"
            >
              Attachments such as images, design ideas and documents help better
              understand the project and provide increasingly accurate
              estimates.
            </Text>
            <AttachmentUploadIcon />
            <div class="tw-w-full tw-flex tw-items-center tw-flex-col tw-gap-3">
              <v-file-input
                class="tw-max-w-[115px] tw-w-full !tw-cursor-pointer"
                label="Upload"
                prepend-inner-icon="mdi-upload"
                variant="solo-filled"
                @change="onFileChange"
                @click:clear="clearImageUrl"
                multiple
                clearable
              ></v-file-input>
              <div
                class="tw-w-full tw-justify-center tw-items-center tw-flex tw-flex-wrap"
              >
                <div
                  class="tw-relative tw-w-[150px] tw-h-[100px] tw-rounded-md tw-overflow-hidden"
                  :class="{
                    'tw-flex tw-items-center tw-gap-2 tw-w-auto tw-h-[30px]':
                      uploadedImages?.imageData.type === 'application/pdf' ||
                      uploadedImages?.imageData.type === 'application/msword',
                  }"
                  v-if="imageUrl"
                >
                  <Text
                    v-if="
                      uploadedImages?.imageData.type === 'application/pdf' ||
                      uploadedImages?.imageData.type === 'application/msword'
                    "
                  >
                    {{ uploadedImages.name }}
                  </Text>
                  <v-icon
                    @click="clearImageUrl"
                    icon="mdi-close-circle"
                    class="tw-absolute tw-right-1 tw-top-1 tw-z-20 tw-cursor-pointer"
                    :class="{
                      'tw-relative !tw-right-0 !tw-top-0':
                        uploadedImages?.imageData.type === 'application/pdf' ||
                        uploadedImages?.imageData.type === 'application/msword',
                    }"
                  ></v-icon>
                  <v-img
                    v-if="
                      uploadedImages?.imageData.type !== 'application/pdf' &&
                      uploadedImages?.imageData.type !== 'application/msword'
                    "
                    class="tw-w-full tw-h-full"
                    aspect-ratio="16/9"
                    cover
                    :src="imageUrl ? imageUrl : userImage"
                  ></v-img>
                </div>
              </div>

              <p class="error_msg" v-if="errorMessage">
                {{ errorMessage }}
              </p>
            </div>
          </div>
          <Button
            @click="addPropertyImage"
            :label="attachmentDataList.length ? 'ADD ANOTHER' : 'add'"
            :isActive="uploadedImages !== null"
          />
        </div>
        <div
          class="tw-w-full tw-h-[411px] tw-rounded-lg tw-flex tw-flex-col tw-justify-between tw-gap-4 tw-py-4 tw-box-border"
        >
          <div
            class="tw-flex tw-items-center tw-gap-2 tw-pb-4 tw-box-border tw-border-b-solid-custom"
          >
            <Text variant="h6" textWeight="600">{{
              attachmentDataList.length
                ? "Uploaded Files"
                : "Your added project attachments will show up here"
            }}</Text>
            <div
              v-if="attachmentDataList.length"
              class="tw-w-[20px] tw-h-[20px] tw-rounded-full tw-bg-[#1FB27A] tw-flex tw-flex-col tw-items-center tw-justify-center"
            >
              <Text variant="xsmall" textColor="#fff" textWeight="400">{{
                attachmentDataList.length
              }}</Text>
            </div>
          </div>
          <div
            v-if="attachmentDataList.length"
            class="tw-h-full tw-rounded-lg tw-overflow-y-auto tw-flex tw-flex-col tw-gap-4 tw-pr-2 tw-box-border no-scrollbar"
          >
            <div
              v-for="(attachment, index) in attachmentDataList"
              :key="index"
              class="tw-w-full tw-flex tw-flex-col tw-gap-1 tw-items-start tw-border-b-solid-custom tw-pb-4 tw-box-border"
            >
              <v-img
                class="tw-rounded-lg"
                :class="{
                  'tw-hidden':
                    attachment?.uploadedImages?.imageData?.type ===
                      'application/pdf' ||
                    attachment?.uploadedImages?.imageData?.type ===
                      'application/msword',
                }"
                :width="156"
                :height="88"
                aspect-ratio="16/9"
                cover
                :src="attachment?.uploadedImages?.url"
              ></v-img>
              <div class="tw-flex-row-between">
                <Text variant="span">{{
                  attachment?.uploadedImages?.name
                }}</Text>
                <img
                  class="tw-cursor-pointer"
                  @click="
                    removeImageFromAttachments(
                      index,
                      attachment?.uploadedImages?.id
                    )
                  "
                  src="@/assets/icons/delete.svg"
                />
              </div>

              <v-text-field
                density="compact"
                placeholder="E.g. Public liability"
                variant="outlined"
                v-model="attachment.userCategoryName"
                class="v-text-custom selectprop_dropdown"
                :menu-props="{ contentClass: 'text__selector' }"
              >
                <v-icon
                  icon="mdi-chevron-down"
                  :id="`menu-activator-${index}`"
                  class="cursor-pointer"
                ></v-icon>
              </v-text-field>
              <v-menu
                transition="slide-y-reverse-transition"
                class="slider-menu-active pro_review_drop"
                :activator="`#menu-activator-${index}`"
                :active="true"
                :menu-props="{ contentClass: 'pro_review_drop' }"
              >
                <v-list class="dropdown_selector">
                  <v-list-item
                    :menu-props="{ contentClass: 'text__selector' }"
                    v-for="(item, index) in userCategory"
                    :key="index"
                  >
                    <v-list-item-title
                      class="cursor-pointer"
                      @click="attachment.userCategoryName = item.name"
                      >{{ item.name }}</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
        </div>
      </section>
    </template>
    <template #footer>
      <section
        class="tw-w-full tw-flex tw-justify-end tw-items-center tw-gap-3 tw-border-t-dashed-custom tw-pt-4 tw-box-border"
      >
        <Button
          class="!tw-w-auto"
          variant="secondary"
          :disabled="isSaving"
          label="Cancel"
          :isActive="true"
          @click="onCloseModal"
        />
        <Button
          class="!tw-w-auto"
          label="save"
          :isLoading="isSaving"
          :isActive="isAttachmentDataValid"
          @click="onSave"
        />
      </section>
    </template>
  </GeneralDialog>
</template>
<script setup lang="ts">
import { computed, ref, reactive, onMounted, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import TrashIcon from "@/core/components/icons/TrashIcon.vue";
import AttachmentUploadIcon from "@/core/components/icons/AttachmentUploadIcon.vue";
import InputSelect from "@/core/components/common/InputSelect.vue";
import { PROJECT_STORE } from "@/store/modules/project";
import ProjectDetail from "@/core/uni-components/ProjectDetail.vue";
import { USER_STORE } from "@/store/modules/user";

const props = defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
  isPropertyOwner: {
    type: Boolean,
    default: false,
  },
  projectAttachments: {
    type: Array,
  },
  projectData: {
    type: Object,
    default: null,
  },
});
const emits = defineEmits(["on-close", "on-success", "on-save"]);

const store = useStore();
const route = useRoute();

const isSaving = ref(false);

const imageUrl = ref("");
const errorMessage = ref("");
const uploadedImages = ref(null) as any;
const attachmentDataList = ref([]) as any;

const state = reactive({
  loading: false,
});

const propertyId = computed(() => route?.params?.propertyId);

const isAttachmentDataValid = computed(() => {
  if (!attachmentDataList.value || attachmentDataList.value.length === 0) {
    return false;
  }

  return attachmentDataList.value.every(
    (attachment: { propertyId: null; userCategoryName: null }) =>
      attachment.propertyId !== null && attachment.userCategoryName !== null
  );
});

const onCloseModal = () => {
  emits("on-close");
};

const onFileChange = async (event: any) => {
  const fileInput = event.target;
  errorMessage.value = "";
  if (fileInput.files && fileInput.files.length > 0) {
    Array.from(fileInput.files).forEach((file: any) => {
      if (file.type.includes("image")) {
        // Check image size limit (200MB)
        if (file.size <= 200 * 1024 * 1024) {
          imageUrl.value = URL.createObjectURL(file);
          uploadedImages.value = {
            url: imageUrl.value,
            name: file.name,
            imageData: file,
            id: `${file.name}_${Date.now()}`,
          };
        } else {
          errorMessage.value = "Image size exceeds the limit (200MB).";
          // Handle error message or display notification
        }
      } else if (file.type.includes("video")) {
        // Check video size limit (20MB)
        if (file.size <= 10 * 1024 * 1024) {
          uploadedImages.value = {
            type: "video",
            name: file.name,
            imageData: file,
          };
          // Process video file
        } else {
          errorMessage.value = "Video size exceeds the limit (20MB).";
          // Handle error message or display notification
        }
      } else {
        // Allow other file types without size validation
        imageUrl.value = URL.createObjectURL(file);
        uploadedImages.value = {
          url: imageUrl.value,
          name: file.name,
          imageData: file,
        };
      }
    });
  } else {
    console.error("Error: No file selected.");
  }
};
const clearImageUrl = () => {
  imageUrl.value = "";
  errorMessage.value = "";
  uploadedImages.value = null;
};

const addPropertyImage = () => {
  if (uploadedImages.value) {
    attachmentDataList.value.push({
      uploadedImages: { ...uploadedImages.value },
      propertyId: projectProperty.value?.property?.id,
      userCategoryName: null,
    });
    clearImageUrl();
  }
};

const removeImageFromAttachments = (index: number, attachmentId: number) => {
  attachmentDataList.value.splice(index, 1);

  if (!props.isPropertyOwner) {
    cloneAttachmentDataList.value.forEach((attachment: any) => {
      if (attachment.uploadedImages.id === attachmentId) {
        attachment["isDeleted"] = true;
      }
    });
  }
  clearImageUrl();
};

const onSave = async () => {
  isSaving.value = true;
  if (props.isPropertyOwner) {
    if (isAttachmentDataValid.value) {
      await store.dispatch(
        `${PROJECT_STORE}/setProjectAttachments`,
        attachmentDataList.value
      );
    }
  } else {
    try {
      //new attachment add functionality
      const newAttachmentDataList = attachmentDataList.value.filter(
        (attachment: any) => attachment?.uploadedImages.imageData
      );

      for (const attachment of attachmentDataList.value) {
        const existCategory = availableCategoryList.value.find(
          (category: any) => category.label === attachment.userCategoryName
        );
        if (!existCategory) {
          const response = await store.dispatch(
            `${PROJECT_STORE}/attachmentCategory`,
            {
              label: attachment.userCategoryName,
              value: attachment.userCategoryName,
            }
          );
          availableCategoryList.value.push(response);
        }
      }

      let newCreatedAttachment = [];
      if (newAttachmentDataList.length) {
        const formData = new FormData();
        newAttachmentDataList.forEach((attachment: any) => {
          if (attachment.uploadedImages.imageData) {
            formData.append(`files`, attachment.uploadedImages.imageData);
          }
        });
        const uploadMultipleImage = await store.dispatch(
          `${USER_STORE}/uploadMultipleImage`,
          formData
        );

        newCreatedAttachment = uploadMultipleImage.map(
          (item: any, index: number) => ({
            publicUrl: item.publicUrl,
            attachment: item.attachment,
            originalName: item.originalName,
            propertyIds: [
              {
                propertyId: projectProperty.value?.property?.id,
                isDeleted: false,
              },
            ],
            attachmentCategoryId: availableCategoryList.value.find(
              (category: any) =>
                category.label === newAttachmentDataList[index].userCategoryName
            ).id,
            isDeleted: false,
          })
        );
      }

      const removeDataAttachment = [] as any;
      const updatedDataAttachment = [] as any;
      cloneAttachmentDataList.value.forEach((attachment: any, index: any) => {
        let attachmentData;
        if (attachment.isDeleted) {
          attachmentData = {
            id: attachment?.uploadedImages?.id,

            propertyIds: [
              {
                propertyId: projectProperty.value?.property?.id,
                isDeleted: true,
              },
            ],
            isDeleted: true,
          };
          removeDataAttachment.push(attachmentData);
        } else {
          const currentAttachment = attachmentDataList.value.find(
            (att: any) =>
              att?.uploadedImages.id === attachment?.uploadedImages?.id
          );
          if (
            currentAttachment?.userCategoryName &&
            currentAttachment?.userCategoryName !== attachment.userCategoryName
          ) {
            updatedDataAttachment.push({
              id: attachment?.uploadedImages?.id,
              attachment: attachment.uploadedImages.attachment,
              originalName: attachment.uploadedImages.name,
              propertyIds: [
                {
                  id: props.projectData.projectAttachments[0]?.id,
                  propertyId: projectProperty.value?.property?.id,
                  isDeleted: false,
                },
              ],
              attachmentCategoryId: availableCategoryList.value.find(
                (category: any) =>
                  category.label === currentAttachment?.userCategoryName
              ).id,
              isDeleted: false,
            });
          }
        }
      });
      emits("on-save", {
        updatePayload: [
          ...newCreatedAttachment,
          ...removeDataAttachment,
          ...updatedDataAttachment,
        ],
        attachmentData: JSON.parse(JSON.stringify(attachmentDataList.value)),
      });
    } catch (error) {
      console.log("error", error);
    } finally {
      isSaving.value = false;
    }
  }
  isSaving.value = false;
  onCloseModal();
};
const userCategory = [
  {
    name: "Floorplan",
  },
  {
    name: "Garden",
  },
  {
    name: "Materials",
  },
  {
    name: "Maps",
  },
];
const selectedFile = ref([]);
const cloneAttachmentDataList = ref([]);
const availableCategoryList = ref();
const projectProperty = ref();
onMounted(async () => {
  attachmentDataList.value = JSON.parse(
    JSON.stringify(props.projectAttachments)
  );
  if (!props.isPropertyOwner) {
    projectProperty.value = props.projectData
      ? props.projectData?.projectProperties[0]
      : null;
    // attachmentDataList.value = attachmentDataList.value.map((data: any) => {
    //   return {
    //     ...data,
    //     propertyId: data[0],
    //   };
    // });
    availableCategoryList.value = await store.dispatch(
      `${PROJECT_STORE}/getProjectCategoryList`,
      props.projectData.id
    );
    cloneAttachmentDataList.value = JSON.parse(
      JSON.stringify(attachmentDataList.value)
    );
  }
});
</script>
<style lang="scss" scoped>
:deep(.v-input__prepend) {
  display: none;
}
:deep(.v-field--variant-solo-filled) {
  background: transparent;
  box-shadow: none;
  .v-field__overlay {
    background-color: transparent;
  }
}

:deep(.v-field--active .v-label.v-field-label) {
  visibility: visible !important;
}

:deep(.v-label.v-field-label--floating) {
  display: none;
}

:deep(.v-input--density-default .v-field__input) {
  display: none;
}

:deep(.v-field__clearable) {
  display: none;
}

.uploade-wrapper {
  position: relative;
  width: 12.50038rem;
  height: 12.50038rem;
  margin: 50px auto;
  z-index: 1;
  .uploaded-img {
    position: absolute;
    top: 5px;
    left: 0;
    border-radius: 100%;
    object-fit: cover;
    height: 100%;
    width: 100%;
    pointer-events: none;
    z-index: -1;
  }
}
.upload_file {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  border-radius: 100%;
  /* overflow: hidden;*/
  cursor: pointer;
  margin: 0 auto 24px;
  .v-field__overlay,
  .v-field {
    border-radius: 100%;
    background-color: rgba($blueDark, 0.15) !important;
    box-shadow: none;
    .v-field__overlay {
      display: none;
    }
  }
  &.added-img {
    .v-field__overlay {
      background: transparent;
    }
    .v-field {
      background: transparent;
    }
    .v-field__input {
      display: none;
    }
  }

  input {
    cursor: pointer;
  }
  .v-field__clearable {
    position: absolute;
    z-index: 1;
    left: 0;
    height: 100%;
    width: 100%;
    margin: 0;
    opacity: 1;
    overflow: unset;
    grid-area: unset;
    align-items: center;
    justify-content: center;
    background-color: rgba($black, 0.5);
    color: rgba($white, 1);
    border-radius: 100%;
  }
  .v-input__prepend {
    display: none;
  }
  .v-input__control {
    position: absolute;
    inset: 0;
    border-radius: 100%;
  }
  :deep(.v-input__append) {
    position: absolute;
    width: 20px;
    height: 20px;
    margin: 0;
    bottom: -30px;
    left: 40px;
  }
  .v-field--appended {
    padding: 0;
    position: relative;
  }
  .v-field__append-inner {
    all: initial;
    color: rgba($blueDark, 0.5);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .v-field-label {
    left: 50%;
    transform: translateX(-50%) !important;
    margin: 28px 0 0 0;
    font-size: 16px;
    font-weight: 500;
    /* max-width: 130px;*/
    bottom: -30px;
    top: unset !important;
    @include fluidFont(16, 16, 1.3);
    font-weight: 700;
    color: rgba($blueDark, 0.7);
    opacity: 1;
  }
}
.selectprop_dropdown {
  width: 100%;
  max-width: 100%;
}
</style>
