<template>
  <GeneralDialog @on-close="onCloseModal" headerTitle="Asset Register">
    <template #body>
      <div
        class="tw-w-[522px] tw-max-h-[522px] tw-border-b-solid-custom tw-flex tw-flex-col tw-gap-4 tw-overflow-x-auto tw-max-w-full md:tw-gap-1 no-scrollbar"
      >
        <div class="tw-flex tw-flex-col tw-gap-2">
          <Text variant="span" textWeight="400">Description of asset</Text>
          <v-textarea
            row-height="25"
            rows="3"
            variant="outlined"
            auto-grow
            shaped
            v-model="assetForm.description"
            class="description__area"
          ></v-textarea>
        </div>
        <div class="tw-flex tw-flex-col tw-gap-2">
          <Text variant="span" textWeight="400">Id number</Text>
          <InputField
            placeholder="e.g 124564658"
            v-model="assetForm.idNumber"
          />
        </div>
        <div class="tw-flex tw-flex-col tw-gap-2">
          <Text variant="span" textWeight="400">Location</Text>
          <InputField
            class="!tw-w-full !tw-max-w-[unset]"
            placeholder="e.g KT19"
            v-model="assetForm.location"
          />
        </div>
        <div class="tw-flex tw-flex-col tw-gap-2">
          <Text variant="span" textWeight="400">Size</Text>
          <div class="tw-grid tw-grid-cols-3 tw-gap-4">
            <InputField placeholder="W" v-model="state.width" />
            <InputField placeholder="L" v-model="state.length" />
            <InputField placeholder="H" v-model="state.heigth" />
          </div>
        </div>
        <div class="tw-flex tw-flex-col tw-gap-2 tw-mb-6">
          <Text variant="span" textWeight="400">Access Information</Text>
          <InputField
            placeholder="Door & Ramp"
            v-model="assetForm.accessInformation"
          />
        </div>

        <div class="tw-flex tw-flex-col tw-gap-2 tw-mb-6">
          <Text variant="span" textWeight="400">Supplier</Text>
          <InputField
            placeholder="e.g Crossman Manufacturing"
            v-model="assetForm.supplier"
          />
        </div>
        <div class="tw-flex tw-flex-col tw-gap-2 tw-mb-6">
          <Text variant="span" textWeight="400">Installer</Text>
          <InputField
            placeholder="e.g Klein Builders"
            v-model="assetForm.installer"
          />
        </div>
        <div class="tw-flex tw-flex-col tw-gap-2 tw-mb-6">
          <Text variant="span" textWeight="400">Purchase price</Text>
          <InputField
            placeholder="e.g £30000"
            v-model="assetForm.purchasePrice"
            :rules="validation.purchasePrice"
          />
        </div>
        <div class="tw-flex tw-flex-col tw-gap-2 tw-mb-6">
          <Text variant="span" textWeight="400">Date of acquisition</Text>
          <DatePicker v-model="assetForm.dateOfAcquisition" :popover="true">
            <template #default="{ togglePopover, inputValue }">
              <div class="calendar__select" @click="() => togglePopover()">
                <v-text-field
                  :value="inputValue"
                  variant="outlined"
                  placeholder="Choose Date"
                  class="date__start"
                  color="#0C0F4A"
                >
                  <img src="@/assets/icons/personal-calendar.svg" alt="" />
                </v-text-field>
              </div>
            </template>
          </DatePicker>
        </div>
        <div class="tw-flex tw-flex-col tw-gap-2 tw-mb-6">
          <Text variant="span" textWeight="400">Purchase Order Number</Text>
          <InputField
            placeholder="e.g 12345678"
            v-model="assetForm.purchaseOrderNumber"
            :rules="validation.purchaseNumber"
          />
        </div>
        <div class="tw-flex tw-flex-col tw-gap-2 tw-mb-6">
          <Text variant="span" textWeight="400">Date of Delivery</Text>
          <DatePicker v-model="assetForm.dateOfDelivery" :popover="true">
            <template #default="{ togglePopover, inputValue }">
              <div class="calendar__select" @click="() => togglePopover()">
                <v-text-field
                  :value="inputValue"
                  variant="outlined"
                  placeholder="Choose Date"
                  class="date__start"
                  color="#0C0F4A"
                >
                  <img src="@/assets/icons/personal-calendar.svg" alt="" />
                </v-text-field>
              </div>
            </template>
          </DatePicker>
        </div>
        <div class="tw-flex tw-flex-col tw-gap-2 tw-mb-6">
          <Text variant="span" textWeight="400">Ownership records</Text>
          <InputField
            placeholder="e.g 12345678"
            v-model="assetForm.ownershipRecords"
          />
        </div>
        <div class="tw-flex tw-flex-col tw-gap-2 tw-mb-6">
          <Text variant="span" textWeight="400">Current Value</Text>
          <InputField
            placeholder="e.g £30000"
            v-model="assetForm.currentValue"
            :rules="validation.currentValue"
          />
        </div>
        <div class="tw-flex tw-flex-col tw-gap-2 tw-mb-6">
          <Text variant="span" textWeight="400">Conditions and defects</Text>
          <InputField
            placeholder="e.g dents"
            v-model="assetForm.conditionsAndDefects"
          />
        </div>
        <div class="tw-flex tw-flex-col tw-gap-2 tw-mb-6">
          <Text variant="span" textWeight="400"
            >Maintenance requirements and intervals</Text
          >
          <InputField
            placeholder="Enter requirements"
            v-model="assetForm.maintenanceRequirements"
          />
        </div>
        <div class="tw-flex tw-flex-col tw-gap-2 tw-mb-6">
          <Text variant="span" textWeight="400">Information about spares</Text>
          <InputField
            placeholder="Enter spares information"
            v-model="assetForm.informationAboutSpares"
          />
        </div>
        <div class="tw-flex tw-flex-col tw-gap-2 tw-mb-6">
          <Text variant="span" textWeight="400">Photographs</Text>
          <div class="tw-flex tw-flex-col tw-gap-2 tw-w-full">
            <UploadForm
              class="!tw-w-full"
              :record="form"
              :allowMultiple="true"
              :attachmentSources="[]"
              :useCustomUploadIcon="true"
              :useDragTrigger="true"
              @on-progress-upload="onProgressUpload"
              @on-upload-success="updateFileOnSuccess"
              @on-handle-error="onHandleError"
              :forDocuments="true"
            />
          </div>
        </div>
        <div class="tw-flex tw-flex-col tw-gap-2 tw-mb-6">
          <Text variant="span" textWeight="400">Running Cost</Text>
          <InputField
            placeholder="e.g £30000"
            v-model="assetForm.runningCost"
            :rules="validation.runningCost"
          />
        </div>
        <div class="tw-flex tw-flex-col tw-gap-2 tw-mb-6">
          <Text variant="span" textWeight="400">Energy performance</Text>
          <InputField
            placeholder="e.g 3 Star"
            v-model="assetForm.energyPerformance"
          />
        </div>
        <div class="tw-flex tw-flex-col tw-gap-2 tw-mb-6">
          <Text variant="span" textWeight="400">Certification</Text>
          <InputField
            placeholder="e.g Government Approved"
            v-model="assetForm.certification"
          />
        </div>
      </div>
    </template>
    <template #footer>
      <div class="tw-w-full tw-flex tw-justify-end tw-items-center tw-gap-3">
        <Button
          class="!tw-w-auto"
          variant="secondary"
          :disabled="state.loading"
          label="close"
          :isActive="true"
          @click="onCloseModal"
        />
        <Button
          class="!tw-w-auto"
          :disabled="state.loading"
          :isLoading="state.loading"
          :label="isEditMode ? 'update' : 'save'"
          :isActive="true"
          @click="onHandleClick"
        />
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup>
import moment from "moment";
import {
  computed,
  ref,
  reactive,
  onMounted,
  onBeforeUnmount,
  watch,
} from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import UploadForm from "@/core/components/forms/Upload.vue";
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Text from "@/core/components/ui/general/Text.vue";
import Button from "@/core/components/ui/general/Button.vue";
import InputField from "@/core/components/common/InputField.vue";
import { PROPERTY_STORE } from "@/store/modules/property";
import { DatePicker } from "v-calendar";
import { USER_STORE } from "@/store/modules/user";

const emits = defineEmits(["on-close", "on-success", "on-error"]);
const props = defineProps(["isEditMode", "selectedAsset"]);

const store = useStore();
const route = useRoute();

const state = reactive({
  loading: false,

  width: "",
  length: "",
  heigth: "",
});
const user = computed(() => store.getters[`${USER_STORE}/user`]);
const form = reactive({
  attachments: [],
  disableButton: false,
  isReadyToSubmit: false,
  formValue: {},
  isSubmitting: false,
  isUploading: false,
  isLoading: false,
});

const validation = ref({
  startDate: [
    (v) => !!v || "startDate is required.",
    (v) =>
      isDateAfterCurrentDate(v) ||
      "Date must be  greater than or equal to today's date",
  ],
  purchaseNumber: [
    (v) => {
      if (!v) {
        return "";
      }
      return !isNaN(v) || "Please enter a valid number.";
    },
  ],
  purchasePrice: [
    (v) => {
      if (!v) {
        return "";
      }
      return !isNaN(v) || "Please enter a valid number.";
    },
  ],
  currentValue: [
    (v) => {
      if (!v) {
        return "";
      }
      return !isNaN(v) || "Please enter a valid number.";
    },
  ],
  runningCost: [
    (v) => {
      if (!v) {
        return "";
      }
      return !isNaN(v) || "Please enter a valid number.";
    },
  ],
});

const onProgressUpload = () => {
  form.isUploading = true;
  form.isLoading = true;
};

const updateFileOnSuccess = () => {
  form.isUploading = false;
  form.isLoading = false;
};

const onHandleError = () => {
  form.isUploading = false;
  form.isLoading = false;
};

const isDateAfterCurrentDate = (givenDate) => {
  const today = moment().startOf("day");
  const givenMoment = moment(givenDate, "YYYY-MM-DD");
  return givenMoment.isSameOrAfter(today);
};

const assetForm = computed(
  () => store.getters[`${PROPERTY_STORE}/assetRegisterForm`]
);

const propertyId = computed(() => route?.params?.propertyId);

const onCloseModal = () => {
  emits("on-close");
};

const onSave = async () => {
  state.loading = true;
  try {
    assetForm.value.size = `${state.width}*${state.length}*${state.heigth}`;
    assetForm.value.assetPropertyId = propertyId;
    assetForm.value.purchasePrice = parseInt(assetForm.value.purchasePrice, 10);
    assetForm.value.runningCost = parseInt(assetForm.value.runningCost, 10);
    assetForm.value.currentValue = parseInt(assetForm.value.currentValue, 10);

    const response = await store.dispatch(
      `${PROPERTY_STORE}/createAssetRegister`,
      assetForm.value
    );
    if (response) {
      emits("on-success", true);
    } else {
      emits("on-error");
    }
  } catch (error) {
    state.loading = false;
  } finally {
    state.loading = false;
    onCloseModal();
  }
};

const onEdit = async () => {
  state.loading = true;
  try {
    assetForm.value.size = `${state.width}*${state.length}*${state.heigth}`;
    const assetId = props.selectedAsset.id;
    const purchasePrice = parseInt(props.selectedAsset.purchasePrice, 10);
    const currentValue = parseInt(props.selectedAsset.currentValue, 10);
    const runningCost = parseInt(props.selectedAsset.runningCost, 10);

    delete props.selectedAsset.description;
    delete props.selectedAsset.idNumber;
    delete props.selectedAsset.location;
    delete props.selectedAsset.size;
    delete props.selectedAsset.accessInformation;
    delete props.selectedAsset.assetPropertyId;
    delete props.selectedAsset.dateCreated;
    delete props.selectedAsset.dateUpdated;
    delete props.selectedAsset.dateDeleted;
    delete props.selectedAsset.id;
    delete props.selectedAsset.purchasePrice;
    delete props.selectedAsset.currentValue;
    delete props.selectedAsset.runningCost;

    const formData = {
      description: assetForm.value.description,
      idNumber: assetForm.value.idNumber,
      location: assetForm.value.location,
      size: assetForm.value.size,
      accessInformation: assetForm.value.accessInformation,
      purchasePrice,
      currentValue,
      runningCost,
      ...props.selectedAsset,
    };
    const payload = {
      assetId,
      formData,
    };

    const response = await store.dispatch(
      `${PROPERTY_STORE}/updateAssetRegister`,
      payload
    );
    if (response) {
      emits("on-success", false);
    } else {
      emits("on-error");
    }
  } catch (error) {
    state.loading = false;
  } finally {
    state.loading = false;
    onCloseModal();
  }
};

const onHandleClick = () => {
  if (props.isEditMode) {
    onEdit();
  } else {
    onSave();
  }
};

const destructureSize = (size) => {
  let values = size.split("*");

  state.width = values[0];
  state.length = values[1];
  state.heigth = values[2];
};

const onInitializeEditMode = () => {
  destructureSize(props.selectedAsset.size);
  assetForm.value.description = props.selectedAsset.description;
  assetForm.value.idNumber = props.selectedAsset.idNumber;
  assetForm.value.location = props.selectedAsset.location;
  assetForm.value.accessInformation = props.selectedAsset.accessInformation;
};

watch(
  assetForm,
  (newValue) => {
    if (newValue.location !== newValue.location.toUpperCase()) {
      assetForm.value.location = newValue.location.toUpperCase();
    }
  },
  { deep: true, immediate: true }
);

onMounted(() => {
  if (props.isEditMode && props.selectedAsset) {
    onInitializeEditMode();
  }
});
onBeforeUnmount(async () => {
  const assetRegisterForm = {
    description: "",
    idNumber: "",
    location: "",
    size: "",
    accessInformation: "",
    assetPropertyId: "",
    supplier: "",
    installer: "",
    purchasePrice: 0,
    dateOfAcquisition: "2024-06-21T00:00:00.000Z",
    purchaseOrderNumber: "-",
    dateOfDelivery: "2024-06-21T00:00:00.000Z",
    ownershipRecords: "",
    currentValue: 0,
    conditionsAndDefects: "",
    maintenanceRequirements: "",
    informationAboutSpares: "",
    runningCost: 0,
    energyPerformance: "",
    certification: "",
    attachments: [],
  };

  await store.dispatch(
    `${PROPERTY_STORE}/setAssetRegisterForm`,
    assetRegisterForm
  );
});
</script>
<style lang="scss" scoped>
.v-input {
  :deep(.v-input__control) {
    .v-field {
      border: 1px solid rgba($blueDark, 0.2);
      border-radius: 8px;
      .v-field__field {
        .v-field__input {
          @include fluidFont(12, 12, 1.2);
          color: rgba($blueDark, 1);
          padding: 8px;
          -webkit-mask-image: none;
          mask-image: none;
        }
      }
      .v-field__outline {
        display: none;
      }
    }
  }
}
.calendar__select {
  :deep(.v-input__control) {
    .v-field {
      .v-field__field {
        .v-field__input {
          padding: 0 1rem;
          img {
            position: absolute;
            right: 1rem;
          }
        }
      }
    }
  }
}
</style>
