<template>
  <div class="issue_type">
    <div class="issue_type_content">
      <div
        class="back_button"
        v-if="!isShowIssueReview && ![1, 2].includes(currentSelectedTab?.id)"
      >
        <v-btn
          variant="text"
          aria-label="Refresh"
          class="button button-transparent"
          prepend-icon="mdi-chevron-left"
          @click="onClickBackButton"
          >back</v-btn
        >
      </div>

      <div
        class="issue_type_content_wrapper"
        :class="{
          'full-view': [1, 2].includes(currentSelectedTab?.id),
          'main-review': isShowIssueReview,
        }"
      >
        <div class="issue_type__content">
          <div
            class="issue_type__heading"
            v-if="![1, 2].includes(currentSelectedTab.id) && !isShowIssueReview"
          >
            <div class="issue_type__heading_prefix">
              <div class="notification">
                {{ currentSelectedTab.id - 1 }}
              </div>
              <div class="issue_type__heading_text">
                {{ currentSelectedTab.name }}
              </div>
            </div>

            <div class="issue_type__heading_title">
              <h3>{{ currentSelectedTab.title }}</h3>
            </div>
          </div>

          <div v-if="currentSelectedTab?.id === 1" class="issue_cards">
            <div class="issue_type__cards">
              <v-card
                class="issue_type__card_item"
                v-for="(item, index) in issuesTypeList"
                :key="index"
                :class="{ selected: item.selected }"
                @click="toggleSelectionType(index)"
              >
                <div class="issue_type__card_item__icon">
                  <v-icon v-if="item.iconName" class="list_content_btn">
                    <img
                      v-if="item.iconName"
                      :src="require('@/assets/icons/' + item.iconName + '.svg')"
                      alt="icon"
                    />
                  </v-icon>
                </div>
                <div class="issue_type__card_item__text">
                  <h5>{{ item.name }}</h5>
                  <p>
                    {{ item.subTitle }}
                  </p>
                </div>
              </v-card>
            </div>
            <!--<div class="modal__footer mt-4">
           <div class="issue_type__button">
            <v-btn
              aria-label="Refresh"
              class="button button-purple"
              @click="onClickToggleContinue"
              >continue</v-btn
            >
          </div> 
        </div>-->
          </div>
          <PropertyListComponent
            className="raise_issue_property_list"
            v-if="currentSelectedTab?.id === 2"
            :workflowType="PropertyWorkflowTypeEnum.RAISE_AN_ISSUE"
            @onClickBackButton="onClickBackButton"
            @onGoAddHistoricalPage="afterSelectingProperty"
          />

          <div v-if="currentSelectedTab?.id === 3" class="issue_specify">
            <div class="issue_specify__content">
              <div class="issue_specify__text">
                <v-text-field
                  placeholder="Issue title"
                  v-model="issueDetails.name"
                  variant="underlined"
                  density="compact"
                ></v-text-field>
                <div class="custom_editor">
                  <quill-editor
                    v-model:value="issueDetails.description"
                    :options="state.editorOption"
                    :disabled="state.disabled"
                    @blur="onEditorBlur($event)"
                    @focus="onEditorFocus($event)"
                    @ready="onEditorReady($event)"
                    @change="onEditorChange($event)"
                    class="editor_ql"
                  />
                </div>
              </div>
            </div>
            <div class="selected-info">
              <v-btn
                aria-label="Refresh"
                class="button button-purple min-w-342"
                @click="onClickToggleContinueIssueDetails"
                >continue</v-btn
              >
            </div>
          </div>

          <div
            class="attachment__wrapper"
            v-if="currentSelectedTab?.id === 4 && !isShowIssueReview"
          >
            <div class="attachment__wrapper__upload">
              <div class="left_upload_img">
                <div class="upload__area">
                  <h4>
                    Upload images and files.
                    <span>(Optional)</span>
                  </h4>
                  <div class="upload__section">
                    <p class="">
                      Attachments such as images, design ideas and documents
                      help better understand the project and provide
                      increasingly accurate estimates.
                    </p>
                    <!-- append-inner-icon="mdi-image-plus" -->
                    <div class="uploade-wrapper secondary_upload_file">
                      <v-file-input
                        append-inner-icon="mdi-image-plus"
                        class="upload_file"
                        label="Upload"
                        variant="solo-filled"
                        v-model="selectedFile"
                        :class="{ 'added-img': imageUrl }"
                        @change="onFileChange"
                        @click:clear="clearImageUrl"
                        multiple
                        append-icon="mdi-upload"
                        clearable
                      >
                        <v-icon icon="mdi mdi-file-image-box"></v-icon>
                      </v-file-input>
                      <v-img
                        v-if="imageUrl"
                        class="uploaded-img"
                        :width="200"
                        aspect-ratio="16/9"
                        cover
                        :src="imageUrl"
                      ></v-img>
                      <p class="error_msg" v-if="errorMessage">
                        {{ errorMessage }}
                      </p>
                    </div>
                  </div>
                  <div class="selected-info">
                    <v-btn
                      block
                      aria-label="Refresh"
                      class="button button-orange"
                      @click="addIssueImage"
                      :disabled="!uploadedImages"
                      >{{
                        attachmentDataList.length ? "ADD ANOTHER" : "add"
                      }}</v-btn
                    >
                    <v-btn
                      aria-label="Refresh"
                      class="button button-purple"
                      @click="onClickPostIssue"
                      :disabled="!isAttachmentDataValid"
                      :loading="loading"
                      >COMPLETE & REVIEW ISSUE</v-btn
                    >
                  </div>
                </div>
              </div>

              <div class="image__upload__wrapper">
                <div v-if="attachmentDataList.length">
                  <h5>Uploaded Files</h5>
                  <div class="uploaded_details_wrapper">
                    <div
                      class="show_uploaded_details"
                      v-for="(attachment, index) in attachmentDataList"
                      :key="index"
                    >
                      <!-- <v-divider color="info" class="mt-3 mb-3"></v-divider> -->
                      <v-img
                        class="uploaded-img"
                        :width="156"
                        :height="88"
                        aspect-ratio="16/9"
                        cover
                        :src="attachment?.uploadedImages?.url"
                      ></v-img>
                      <div class="prop_img_details">
                        <h5>{{ attachment?.uploadedImages?.name }}</h5>
                        <v-btn
                          @click="removeImageFromAttachments(index)"
                          variant="text"
                        >
                          <img src="../../../assets/icons/delete.svg" alt="" />
                        </v-btn>
                      </div>

                      <div class="upload__list">
                        <!-- <v-select
                        v-model="attachment.issueId"
                        placeholder="Select issue"
                        :items="userSelectedIssue"
                        item-title="address"
                        item-value="id"
                        multiple
                        variant="outlined"
                        density="compact"
                        class="selectprop_dropdown"
                      >
                      </v-select> -->
                        <!-- <v-text-field
                        density="compact"
                        placeholder="E.g. Public liability"
                        variant="outlined"
                        v-model="attachment.userCategoryName"
                        class="v-text-custom"
                      >
                        <v-icon
                          icon="mdi-menu-down"
                          :id="`menu-activator-${index}`"
                          class="cursor-pointer"
                        ></v-icon>
                      </v-text-field> -->
                        <v-combobox
                          class="selectprop_dropdown"
                          v-model="attachment.userCategoryName"
                          :items="userCategory"
                          item-title="name"
                          item-value="name"
                          placeholder="Enter or select category"
                          multiple
                          :menu-props="{
                            contentClass: 'custom__text__selector',
                          }"
                        ></v-combobox>
                      </div>
                      <v-menu
                        transition="slide-y-reverse-transition"
                        class="slider-menu-active"
                        :activator="`#menu-activator-${index}`"
                        :active="true"
                      >
                        <v-list>
                          <v-list-item
                            v-for="(item, index) in userCategory"
                            :key="index"
                          >
                            <v-list-item-title
                              class="cursor-pointer"
                              @click="attachment.userCategoryName = item?.name"
                              >{{ item?.name }}</v-list-item-title
                            >
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                  </div>
                </div>
                <div v-else class="blank_project">
                  <p>Your added project attachments will show up here</p>
                </div>
              </div>
            </div>
          </div>

          <div>
            <IssueReviewComponent
              v-if="isShowIssueReview && createdIssueId"
              :createdIssueId="createdIssueId"
              :issueDetails="issueDetails"
              :attachmentList="attachmentDataList"
              @onBackButton="onBackButton"
              @goToListIssuesTab="goToListIssuesTab"
              @onClickBackButton="onClickBackButtonFromReview"
            />
          </div>
        </div>
        <div
          class="issue_type__overview"
          v-if="isShowRightIssueReviewCard(currentSelectedTab?.id)"
        >
          <v-card class="issue_type__overview_card">
            <div class="issue_type__overview_card_content">
              <div class="heading">
                <h4 class="title">Issue Overview</h4>
              </div>
              <div class="issue_type__overview_card_text">
                <div class="issue_type__overview_card_text_item">
                  <div class="issue_type__overview_list">
                    <h6>Address</h6>
                    <div
                      v-if="
                        issueDetails.issueType == 'emergency_out_of_hours' &&
                        selectedEOHEventDetails?.EOHProperties?.length
                      "
                      class="property_address"
                    >
                      <p
                        v-for="property of selectedEOHEventDetails?.EOHProperties"
                        :key="property.id"
                      >
                        {{ property?.property?.address }}
                      </p>
                    </div>
                    <p v-else>
                      {{ selectedPropertyDetails?.address }}
                    </p>
                  </div>
                </div>
                <div class="issue_type__overview_card_text_item">
                  <div class="heading">Issue Details</div>
                  <div class="issue_type__overview_list">
                    <h6>Issue Type</h6>
                    <p>{{ selectedIssueTypeName }}</p>
                  </div>
                </div>
              </div>
            </div>
            <v-btn
              class="button button-purple-border"
              size="large"
              block
              @click="onClickSaveIssueLater(PropertyIssueStatus.DRAFT)"
              :loading="isShowLoaderOnSaveIssueForLater"
              :disabled="currentSelectedTab?.id === 3 && !isAttachmentDataValid"
            >
              save Issue for later
            </v-btn>
          </v-card>
        </div>
      </div>
    </div>
    <div class="modal__footer">
      <div v-if="currentSelectedTab?.id === 1" class="issue_type__button">
        <v-btn
          aria-label="Refresh"
          class="button button-purple"
          v-if="currentSelectedTab?.id === 1"
          :disabled="!selectedIssueTypeName"
          @click="onClickToggleContinue"
          >continue</v-btn
        >
      </div>
      <v-stepper
        class="onboard-stepper"
        v-model="currentSelectedTab.id"
        v-if="!isShowIssueReview"
      >
        <v-stepper-header class="onboard-stepper__header">
          <v-stepper-item
            class="onboard-stepper__item"
            v-for="step in modelDetails"
            :key="step.id"
            :title="step.tab"
            :value="step.id"
          ></v-stepper-item>
        </v-stepper-header>
      </v-stepper>
    </div>

    <!-- @click="saveForDraftProject" -->
  </div>
  <EmergencyOutHoursEventList
    v-if="isShowEmergencyOutHoursEventList"
    @onCloseEmergencyListModal="onCloseEmergencyListModal"
    @onClickNextButton="onClickNextButton"
  />
  <EmergencyOutHoursDetails
    v-if="isShowEmergencyOutHoursDetails"
    @onCloseDetailsModal="onCloseDetailsModal"
    @onClickBackButton="openEmergencyOutHoursEventListModal"
    @onClickNextButton="onClickEmergencyOutHoursDetailNextButton"
    :selectedEOHEventDetails="selectedEOHEventDetails"
  />
  <!-- @onClickBackButton=""
    @onGoAddHistoricalPage="" -->
</template>
<script lang="ts">
import { computed, ref, reactive } from "vue";
import { quillEditor } from "vue3-quill";
import IssueReviewComponent from "@/modules/issues/components/IssueReviewComponent.vue";
import RaiseIssueService from "@/core/services/raise-issue.service";
import { PropertyIssueStatus } from "@/core/enums/RaiseIssueEnum";
import { USER_STORE } from "@/store/modules/user";
import { useStore } from "vuex";
import PropertyListComponent from "@/modules/project/components/PropertyListComponent.vue";

import {
  PropertyTypeEnum,
  PropertyWorkflowTypeEnum,
} from "@/core/enums/PropertiesEnum";
import { PROPERTY_STORE } from "@/store/modules/property";
import EmergencyOutHoursEventList from "./EmergencyOutHoursEventList.vue";
import EmergencyOutHoursDetails from "./EmergencyOutHoursDetails.vue";
import EOHService from "@/core/services/emergency-out-of-hours.service";

export default {
  components: {
    quillEditor,
    IssueReviewComponent,
    EmergencyOutHoursEventList,
    EmergencyOutHoursDetails,
    PropertyListComponent,
  },
  props: {},
  setup(props: any, ctx: any) {
    const selectedFile = ref([]);
    const modelDetails = ref([
      {
        id: 1,
        tab: "Issue Type",
        name: "Issue Type",
        title: "Please select the type, best suited for your project.",
      },
      {
        id: 2,
        tab: "Property Select",
        name: "propertySelect",
        title: "Please select the type, best suited for your project.",
      },

      {
        id: 3,
        tab: "Issue Specifics",
        name: "Issue Specifics",
        title: "Add in specific details about the Issue",
      },
      {
        id: 4,
        tab: "Attachments",
        name: "Attachments",
        title: "Add any relevant images and documents.",
      },
    ]);
    const createdIssueId = ref(null) as any;
    const loading = ref(false);
    const projectTitle = ref("");
    const imageUrl = ref("");
    const errorMessage = ref("");
    const imageName = ref("");
    const uploadedImages = ref(null) as any;
    const currentSelectedTab = ref(modelDetails.value[0]) as any;
    const selectedIssueTypeName = ref("") as any;
    const isShowIssueReview = ref(false) as any;
    const isShowLoaderOnSaveIssueForLater = ref(false);
    const isShowEmergencyOutHoursEventList = ref(false) as any;
    const isShowEmergencyOutHoursDetails = ref(false) as any;
    const selectedEOHEventDetails = ref(null) as any;
    const selectedPropertyDetails = ref();
    const store = useStore();
    const userSelectedIssue = [
      { id: 1, address: "123 Main Street" },
      { id: 2, address: "456 Elm Street" },
      { id: 3, address: "789 Oak Avenue" },
      { id: 4, address: "101 Pine Road" },
    ];

    const selectedCategory = ref([]);

    const properties = computed(
      () => store.getters[`${PROPERTY_STORE}/properties`]
    );

    const isPropertiesWithOccupier = computed(() =>
      properties.value.find(
        (property: any) => property?.propertyRole === PropertyTypeEnum.OCCUPIER
      )
    );

    const onClickBackButton = () => {
      if (issueDetails.value.issueType !== "emergency_out_of_hours") {
        if (currentSelectedTab.value.id == 2) {
          currentSelectedTab.value = modelDetails.value[0];
        } else if (currentSelectedTab.value.id == 3) {
          currentSelectedTab.value = modelDetails.value[1];
        } else if (currentSelectedTab.value.id == 4) {
          currentSelectedTab.value = modelDetails.value[2];
        } else {
          ctx.emit("onClickBackButton");
        }
      } else {
        currentSelectedTab.value = modelDetails.value[0];
      }
    };
    const issuesTypeList = ref([
      {
        id: 1,
        name: "Emergency",
        iconName: "Vector",
        subTitle: "This project needs to start now",
        selected: false,
        value: "emergency",
      },
      {
        id: 2,
        name: "Standard",
        iconName: "model_training_black",
        subTitle: "This project has a flexible or preferred start date ",
        selected: false,
        value: "standard",
      },
      {
        id: 3,
        name: "Out Of Hours",
        iconName: "model_training_black",
        subTitle: "Lorem Ipsum is simply dummy text of the printing.",
        selected: false,
        value: "emergency_out_of_hours",
      },
    ]) as any;
    const state = reactive({
      content: "",
      _content: "",
      editorOption: {
        placeholder: "Description here",
        modules: {},
      },
      disabled: false,
    });

    const issueDetails = ref({
      name: "",
      description: "",
      issueType: null,
      issueStatus: null,
      attachments: [],
      subCategoryData: [],
      SLATime: null,
      propertiesData: [],
      eohId: null,
    }) as any;

    const attachmentDataList = ref([]) as any;
    const isProjectTypeSelected = computed(() => {
      return issuesTypeList.value.some((item: any) => item.selected);
    });
    const toggleSelectionType = (index: number) => {
      issuesTypeList.value.forEach((item: any, i: number) => {
        if (i === index) {
          item.selected = true;
          selectedIssueTypeName.value = item.name;
          issueDetails.value.issueType = item.value;
        } else {
          item.selected = false;
        }
      });
    };
    const onClickToggleContinue = () => {
      let selectedItems = [];
      if (issueDetails.value.issueType === "emergency_out_of_hours") {
        isShowEmergencyOutHoursEventList.value = true;
      } else {
        currentSelectedTab.value = modelDetails.value[1];
      }
    };
    const userCategory = ["Floorplan", "Garden", "Materials", "Maps"];
    const onEditorBlur = (quill: any) => {
      console.log("editor blur!", quill);
    };
    const onEditorFocus = (quill: any) => {
      console.log("editor focus!", quill);
    };
    const onEditorReady = (quill: any) => {
      console.log("editor ready!", quill);
    };
    const onEditorChange = ({
      quill,
      html,
      text,
    }: {
      quill: any;
      html: string;
      text: string;
    }) => {
      state._content = html;
    };
    const isAttachmentDataValid = computed(() => {
      const isCategorySelected = attachmentDataList.value.every(
        (attachmentDataList: any) => {
          return attachmentDataList.userCategoryName?.length;
        }
      );

      if (!attachmentDataList.value || attachmentDataList.value.length === 0) {
        return false;
      } else if (attachmentDataList.value.length && !isCategorySelected) {
        return false;
      } else {
        return true;
      }
    });

    const afterSelectingProperty = (property: any) => {
      selectedPropertyDetails.value = property;
      currentSelectedTab.value = modelDetails.value[2];
    };
    const onClickToggleContinueIssueDetails = () => {
      // isShowJobSpecifics.value = false;
      currentSelectedTab.value = modelDetails.value[3];
      // isShowAttachments.value = true;
    };

    const onClickPostIssue = async () => {
      try {
        loading.value = true;
        if (attachmentDataList.value.length) {
          const formData = new FormData();
          attachmentDataList.value.forEach((attachment: any) => {
            formData.append(`files`, attachment.uploadedImages.imageData);
          });
          const uploadMultipleImage = await store.dispatch(
            `${USER_STORE}/uploadMultipleImage`,
            formData
          );

          uploadMultipleImage.forEach((image: any) => {
            issueDetails.value?.attachments.push({
              attachment: image.attachment,
              originalName: image.originalName,
              attechmentCategories: [],
            });
          });
          attachmentDataList.value.forEach((attachment: any, index: any) => {
            attachment.userCategoryName.forEach((name: string) => {
              issueDetails.value?.attachments[index]?.attechmentCategories.push(
                {
                  name,
                }
              );
            });
          });

          delete issueDetails.value.issueStatus;
          if (issueDetails.value.issueType !== "emergency_out_of_hours") {
            issueDetails.value.propertiesData = [
              { propertyId: selectedPropertyDetails.value.id },
            ];
          }

          const createdIssue = (await RaiseIssueService.createIssue(
            issueDetails.value
          )) as any;
          createdIssueId.value = createdIssue.id;
        }
      } catch (error) {
        console.log("AAAA", error);
      } finally {
        loading.value = false;
      }

      // currentSelectedTab.value = modelDetails.value[3];
      // currentSelectedTab.value = null;
      isShowIssueReview.value = true;
    };
    const onBackButton = () => {
      isShowIssueReview.value = false;
      currentSelectedTab.value = modelDetails.value[2];
    };
    const onFileChange = async (event: any) => {
      const fileInput = event.target;
      errorMessage.value = "";
      if (fileInput.files && fileInput.files.length > 0) {
        Array.from(fileInput.files).forEach((file: any) => {
          if (file.type.includes("image")) {
            // Check image size limit (200MB)
            if (file.size <= 200 * 1024 * 1024) {
              imageUrl.value = URL.createObjectURL(file);
              uploadedImages.value = {
                url: imageUrl.value,
                name: file.name,
                imageData: file,
              };
            } else {
              errorMessage.value = "Image size exceeds the limit (200MB).";
              // Handle error message or display notification
            }
          } else if (file.type.includes("video")) {
            // Check video size limit (20MB)
            if (file.size <= 10 * 1024 * 1024) {
              uploadedImages.value = {
                type: "video",
                name: file.name,
                imageData: file,
              };
              // Process video file
            } else {
              errorMessage.value = "Video size exceeds the limit (20MB).";
              // Handle error message or display notification
            }
          } else {
            // Allow other file types without size validation
            imageUrl.value = URL.createObjectURL(file);
            uploadedImages.value = {
              url: imageUrl.value,
              name: file.name,
              imageData: file,
            };
          }
        });
      } else {
        console.error("Error: No file selected.");
      }
    };
    const clearImageUrl = () => {
      imageUrl.value = "";
      errorMessage.value = "";
      selectedFile.value = [];
    };
    const removeImageFromAttachments = (index: number) => {
      attachmentDataList.value.splice(index, 1);

      clearImageUrl();
    };

    const addIssueImage = () => {
      attachmentDataList.value.push({
        uploadedImages: { ...uploadedImages.value },
        issueId: null,
        userCategoryName: null,
      });
      uploadedImages.value = null;
      clearImageUrl();
    };

    const onClickSaveIssueLater = async (issueStatus: string) => {
      try {
        isShowLoaderOnSaveIssueForLater.value = true;
        issueDetails.value.issueStatus = issueStatus;
        const formData = new FormData();
        attachmentDataList.value.forEach((attachment: any) => {
          formData.append(`files`, attachment.uploadedImages.imageData);
        });
        const uploadMultipleImage = await store.dispatch(
          `${USER_STORE}/uploadMultipleImage`,
          formData
        );

        uploadMultipleImage.forEach((image: any) => {
          issueDetails.value?.attachments.push({
            attachment: image.attachment,
            originalName: image.originalName,
            attechmentCategories: [],
          });
        });
        attachmentDataList.value.forEach((attachment: any, index: any) => {
          attachment.userCategoryName.forEach((name: string) => {
            issueDetails.value?.attachments[index]?.attechmentCategories.push({
              name,
            });
          });
        });
        if (issueDetails.value.issueType !== "emergency_out_of_hours") {
          issueDetails.value.propertiesData = [
            { propertyId: selectedPropertyDetails.value.id },
          ];
        }
        await RaiseIssueService.createIssue(issueDetails.value);

        ctx.emit("switchToIssueListTab");
      } catch (error) {
        console.log("error", error);
      } finally {
        isShowLoaderOnSaveIssueForLater.value = false;
      }
    };

    const isShowRightIssueReviewCard = (currentID: any) => {
      return ![1, 2].includes(currentID) && !isShowIssueReview.value;
    };

    const goToListIssuesTab = () => {
      ctx.emit("switchToIssueListTab");
    };

    const onClickBackButtonFromReview = () => {
      currentSelectedTab.value = modelDetails.value[2];
      isShowIssueReview.value = false;
      createdIssueId.value = null;
    };

    const onCloseEmergencyListModal = () => {
      isShowEmergencyOutHoursEventList.value = false;
    };

    const onClickNextButton = async (selectedEventId: number) => {
      isShowEmergencyOutHoursEventList.value = false;
      isShowEmergencyOutHoursDetails.value = true;
      selectedEOHEventDetails.value = await EOHService.getParticularEOHEvents(
        selectedEventId
      );
    };

    const onCloseDetailsModal = () => {
      isShowEmergencyOutHoursDetails.value = false;
    };

    const openEmergencyOutHoursEventListModal = () => {
      isShowEmergencyOutHoursEventList.value = true;
      isShowEmergencyOutHoursDetails.value = false;
    };

    const onClickEmergencyOutHoursDetailNextButton = (eohId: number) => {
      isShowEmergencyOutHoursDetails.value = false;
      issueDetails.value.name = selectedEOHEventDetails.value?.title;
      issueDetails.value.description =
        selectedEOHEventDetails.value?.description;
      issueDetails.value.SLATime = selectedEOHEventDetails.value?.SLATime;
      issueDetails.value.eohId = eohId;
      if (selectedEOHEventDetails.value.EOHProperties.length) {
        selectedEOHEventDetails.value.EOHProperties.forEach((property: any) => {
          issueDetails.value.propertiesData.push({
            propertyId: property.property.id,
          });
        });
      }
      selectedEOHEventDetails.value?.EOHSpecialism?.forEach(
        (specialism: any) => {
          issueDetails.value.subCategoryData.push(
            specialism?.subCategories?.id
          );
        }
      );
      currentSelectedTab.value = modelDetails.value[2];
    };

    return {
      modelDetails,
      currentSelectedTab,
      onClickBackButton,
      toggleSelectionType,
      onClickToggleContinue,
      issuesTypeList,
      onEditorBlur,
      onEditorFocus,
      onEditorReady,
      onEditorChange,
      state,
      onClickToggleContinueIssueDetails,
      attachmentDataList,
      userCategory,
      onClickPostIssue,
      isShowIssueReview,
      onBackButton,
      loading,
      projectTitle,
      errorMessage,
      imageUrl,
      imageName,
      uploadedImages,
      onFileChange,
      clearImageUrl,
      removeImageFromAttachments,
      addIssueImage,
      isAttachmentDataValid,
      userSelectedIssue,
      selectedIssueTypeName,
      issueDetails,
      onClickSaveIssueLater,
      PropertyIssueStatus,
      selectedCategory,
      isShowRightIssueReviewCard,
      createdIssueId,
      goToListIssuesTab,
      onClickBackButtonFromReview,
      isShowLoaderOnSaveIssueForLater,
      isPropertiesWithOccupier,
      selectedFile,
      isShowEmergencyOutHoursEventList,
      onCloseEmergencyListModal,
      onClickNextButton,
      isShowEmergencyOutHoursDetails,
      onCloseDetailsModal,
      openEmergencyOutHoursEventListModal,
      onClickEmergencyOutHoursDetailNextButton,
      selectedEOHEventDetails,
      afterSelectingProperty,
      PropertyWorkflowTypeEnum,
      selectedPropertyDetails,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/modules/issues/styles/issues.scss";

.secondary_upload_file {
  height: 3.50038rem !important;
  margin: 30px auto 80px !important;

  .uploaded-img {
    display: none !important;
  }

  :deep(.upload_file) {
    .v-input__control {
      position: relative !important;
    }

    .v-field__append-inner {
      .mdi-image-plus {
        width: 65px;
        height: 65px;
        background: url("../../../assets/icons/image_placeholder.svg") no-repeat
          center / contain;
        opacity: 1;

        &::before {
          display: none;
        }
      }
    }

    .v-field {
      background-color: transparent !important;
    }

    .v-field-label {
      padding-left: 30px !important;
      color: $blueDark;
      font-weight: 600;
    }

    .v-input__append {
      left: 56px !important;
    }

    .v-field__clearable {
      display: none;
    }

    .v-field__input {
      display: block !important;
      font-size: 0;
      line-height: 0;
      opacity: 0;
      user-select: none;
    }
  }
}
.issue_type_content_wrapper {
  display: block;
}
.property_address {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.issue_type_content {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  align-items: flex-start;
}
</style>
