<template>
  <CommonDialog
    v-model="onCloseModal"
    :isShowHeader="false"
    :isShowBackButton="false"
    width="848px"
    max-width="100%"
    persistent
    :className="['contract_assign_modal']"
  >
    <template v-slot:body>
      <div class="header">
        <!-- group name -->
        <h2>Phase # notes</h2>
        <v-icon
          icon="mdi-close"
          class="close__icon"
          @click="onCloseModal"
        ></v-icon>
      </div>
      <div class="modal__content">
        <v-expansion-panels v-if="phaseTasks.length" multiple>
          <v-expansion-panel v-for="(task, index) in phaseTasks" :key="task.id">
            <v-expansion-panel-title>
              <div class="contract_assign_modal_item_title">
                <h2>Note {{ index + 1 }}</h2>
                <div class="contract_assign_modal_tag">
                  <p>{{ task.name }}</p>
                </div>
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <div class="contract_assign_modal_item_content">
                <div
                  class="contract_assign_modal_info"
                  v-for="notes in task.phaseNotes"
                  :key="notes?.id"
                >
                  <p>
                    {{ notes?.note }}
                  </p>
                  <div class="contract_assign_modal_info_footer">
                    <div class="contract_assign_modal_info_author">
                      <div class="contract_assign_modal_info_author_image">
                        <img
                          src="@/assets/images/contractor-profile.png"
                          alt=""
                        />
                      </div>
                      <p>Toby Sparks</p>
                    </div>
                    <div class="contract_assign_modal_info_date">
                      <p>{{ formatDate(notes?.dateCreated) }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <div class="contract_assign_modal_footer">
        <Button
          label="Cancel"
          variant="elevated"
          class="button button-purple-border"
          @click="onCloseModal"
        />
      </div>
    </template>
  </CommonDialog>
</template>

<script lang="ts">
import { ref, computed } from "vue";
import CommonDialog from "@/core/components/CommonDialog.vue";
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import moment from "moment";
import tradeEstimateService from "@/core/services/trade-estimate.service";
export default {
  components: {
    CommonDialog,
    Button,
  },
  props: {
    phaseDetails: { type: Object, default: null },
  },

  setup(props: any, ctx: any) {
    const onCloseModal = () => {
      ctx.emit("on-close");
    };

    const phaseTasks = computed(() => {
      return props.phaseDetails.estimationPhaseTasks;
    });
    const formatDate = (date: any) => moment(date).format("DD, MMMM YYYY");
    return {
      formatDate,
      onCloseModal,
      phaseTasks,
    };
  },
};
</script>
