<template>
  <commonDialog
    :HeaderTittle="modelDetails.header"
    :title="modelDetails.title"
    :description="modelDetails.description"
    :onClickBackButton="onClickBackButton"
    width="560px"
    :className="['add_property_modal_box']"
    :isShowHeader="false"
  >
    <template v-slot:body>
      <!-- Select Property Form -->
      <div class="sole-trader-data-wrapper">
        <div class="form__wrapper">
          <v-form @submit.prevent :rules="validation" ref="propertyFormRef">
            <div class="modal_input_wrapper">
              <div class="modal_input">
                <v-select
                  :items="addressesList"
                  placeholder="Please select Property"
                  :rules="validation.propertyId"
                  v-model="selectedProperty"
                  variant="outlined"
                  item-title="address"
                  item-value="address"
                  class="c-select"
                  multiple
                  @update:modelValue="handlePropertySelection(selectedProperty)"
                ></v-select>
              </div>
              <v-btn @click="saveTaskProperties" variant="plain">save</v-btn>
            </div>
          </v-form>
        </div>
      </div>

      <!-- Show Property On Map -->
      <div
        class="map__wrapper"
        id="map_properties"
        v-if="addresses && addresses.length > 0"
      >
        <AddressMapComponent
          class="pointer_events_none"
          :latlng="[53.5809811, -0.6393335]"
          :addresses="addresses"
          :key="mapKey"
        />
      </div>

      <div class="map__wrapper" v-if="!addresses.length">
        No properties Data
      </div>

      <!-- Show Property List -->

      <div
        v-for="(item, index) in matchedAddresses"
        :key="index"
        class="property-list"
      >
        <v-card variant="tonal" class="md:!tw-items-center">
          <v-card-text>
            <div class="property_location">
              <v-icon>
                <img src="../../assets/icons/pin-location.svg" alt="" />
              </v-icon>
              <span> {{ item?.property?.postcode }}</span>
            </div>
            <div class="property_content">
              <div class="property_name">
                <v-icon class="!tw-min-w-[15px] !tw-min-h-[15px]">
                  <img src="../../assets/icons/house-icon.svg" alt="" />
                </v-icon>
                <h4 class="md:!tw-text-[10px]">
                  {{ item?.property?.address }}
                </h4>
              </div>
              <div class="property_info">
                <span>
                  {{
                    item.property.propertyCategory
                      ? item.property.propertyCategory
                      : "N/A"
                  }}
                </span>
                |
                <span>
                  {{
                    item.property.beds ? `${item.property.beds} beds` : "N/A"
                  }}
                </span>
                |
                <span class="">
                  {{
                    item.property.propertySize
                      ? `${item.property.propertySize}sqm`
                      : "N/A"
                  }}
                </span>
              </div>
              <div class="property_type">
                <v-icon>
                  <img src="../../assets/icons/user.svg" alt="" />
                </v-icon>
                <h5>
                  {{
                    item.property.propertyType
                      ? item.property.propertyType
                      : "N/A"
                  }}
                </h5>
              </div>
            </div>
          </v-card-text>
          <div class="property_img md:!tw-min-w-[70px] md:!tw-h-[70px]">
            <img src="../../assets/images/house-sample-2.jpg" />
          </div>
        </v-card>
      </div>
    </template>
  </commonDialog>
</template>

<script lang="ts" setup>
import { computed, ref, onBeforeMount } from "vue";
import CommonDialog from "@/core/components/CommonDialog.vue";
import { useStore } from "vuex";
import { JOBS_STORE } from "@/store/modules/jobs";
import { JOB_TEMPLATE_STORE } from "@/store/modules/job-template";
import AddressMapComponent from "@/core/components/AddressMapComponent.vue";
import Button from "../components/ui/general/Button.vue";
import { watch } from "vue";

const store = useStore();

const props = defineProps({
  phaseIndex: {
    type: Number,
  },
  taskIndex: {
    type: Number,
  },
});
const modelDetails = ref({
  id: 2,
  tab: "Property",
  name: "Property",
  header: "Property",
  title: "Add or remove property",
  description: "",
});
const propertyFormRef = ref(null) as any;
const selectedProperty = ref([]);
const addresses: any = ref([]);
const sendAddress: any = ref({});
const emits = defineEmits(["on-save-task-properties", "on-click-close-tab"]);
const matchedAddresses: any = ref([]);
const validation = ref({
  propertyId: [(v: any) => !!v || "Select one property"],
});
const estimateForm = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/estimateForm`]
);
const onClickBackButton = () => {
  return emits("on-click-close-tab");
};

const removeProperties = async (id: number) => {
  try {
    await store.dispatch(`${JOB_TEMPLATE_STORE}/removeEstimationProperty`, {
      estimatePropertyId: id,
    });
  } catch (error) {
    console.log();
  }
};
const saveTaskProperties = async () => {
  await Promise.all(
    estimationProperties.value.map(async (data: any) => {
      await removeProperties(data.propertyId);
    })
  );
  const propertiesData = matchedAddresses.value.map((entry: any) => ({
    propertyId: entry.property.id,
  }));

  if (isMaterialsProperties.value) {
    store.dispatch(`${JOB_TEMPLATE_STORE}/setPhaseProperties`, {
      phaseIndex: props.phaseIndex,
      phaseProperties: propertiesData,
    });
  } else {
    store.dispatch(`${JOB_TEMPLATE_STORE}/setPhaseTaskProperties`, {
      phaseIndex: props.phaseIndex,
      itemIndex: props.taskIndex,
      taskProperties: propertiesData,
    });
  }

  emits("on-save-task-properties", matchedAddresses.value);
};
const currentJobDetails = computed(
  () => store.getters[`${JOBS_STORE}/getJobDetails`]
);

const addressesList = currentJobDetails.value.projectProperties.map(
  (property: any) => property.property
);

const handlePropertySelection = (selectedProperty: any) => {
  const addressList: any = [];
  matchedAddresses.value = currentJobDetails.value.projectProperties.filter(
    (property: any) => selectedProperty.includes(property.property.address)
  );

  addressList.value = matchedAddresses.value.map(
    (property: any) => property.property.addressData
  );

  let latlang = null;
  if (addressList.value) {
    addressList.value.forEach((item: any) => {
      if (item) {
        let parsedItem;
        try {
          parsedItem = JSON.parse(item);
        } catch (error) {
          console.error("Error parsing item:", error);
          return; // Skip this item if parsing fails
        }
        const address = parsedItem;
        latlang = address?.latlng;
        addressList.push({
          address: address.ADDRESS || "",
          uprn: address.UPRN || "",
          latlang,
          // id: address.id || "",
          id: address.TOPOGRAPHY_LAYER_TOID || "",
          postcode: address.POSTCODE || "",
        });
      }
    });
  }

  // if (item.addressData) {
  //   const address =
  //     typeof item.addressData === "string"
  //       ? JSON.parse(item.addressData)
  //       : item.addressData;
  //   latlang = address?.latlng;
  //   addressList.push({
  //     address: item.address,
  //     uprn: item.uprn,
  //     latlang,
  //     id: item.id,
  //     postcode: item.postcode,
  //   });
  // }
  addresses.value = addressList;
};

const isMaterialsProperties = computed(() => {
  return (
    estimateForm.value.projectStages[props.phaseIndex].stageType === "materials"
  );
});
const estimationProperties = computed(() => {
  if (!isMaterialsProperties.value) {
    return (
      (estimateForm.value.projectStages[props.phaseIndex].projectStageTasks[
        props.taskIndex
      ] &&
        estimateForm.value.projectStages[props.phaseIndex].projectStageTasks[
          props.taskIndex
        ].estimationPhaseTasksProperties) ||
      []
    );
  }
  return (
    (estimateForm.value.projectStages[props.phaseIndex]
      .estimationPhaseProperties &&
      estimateForm.value.projectStages[props.phaseIndex]
        .estimationPhaseProperties) ||
    []
  );
});

const mapKey = ref(0);

watch(
  addresses,
  (newAddresses, oldAddresses) => {
    // Check if addresses have actually changed
    if (newAddresses !== oldAddresses) {
      // Increment the key to force re-render

      mapKey.value++;
    }
  },
  { deep: true }
);
onBeforeMount(() => {
  const existingPropertyIds = estimationProperties.value.map(
    (data: any) => data.propertyId
  );

  matchedAddresses.value = currentJobDetails.value.projectProperties.filter(
    (property: any) => existingPropertyIds.includes(property.property.id)
  );
  selectedProperty.value = matchedAddresses.value.map(
    (property: any) => property.property.address
  );
  const addressList: any = [];
  if (matchedAddresses.value) {
    let latlang = null;
    matchedAddresses.value.forEach((item: any) => {
      if (item.property.addressData) {
        let parsedItem;
        try {
          parsedItem = JSON.parse(item.property.addressData);
        } catch (error) {
          console.error("Error parsing item:", error);
          return;
        }
        const address = parsedItem;
        latlang = address?.latlng;
        addressList.push({
          address: address.ADDRESS || "",
          uprn: address.UPRN || "",
          latlang,
          id: address.TOPOGRAPHY_LAYER_TOID || "",
          postcode: address.POSTCODE || "",
        });
      }
    });
  }
  addresses.value = addressList;
});
</script>

<style lang="scss" scoped>
.property-list {
  margin-top: 12px;
  .v-card {
    padding: 8px 12px;
    background-color: rgba($cardBorder, 1);
    border-radius: 8px;
    display: flex;
    gap: 16px;
    :deep(.v-card__underlay) {
      display: none;
    }
    .v-card-text {
      padding: 0;
      .property_location {
        display: flex;
        gap: 18px;
        align-items: center;
        padding-bottom: 6px;
        border-bottom: 1px dashed #8687a5;
        .v-icon {
          width: 8px;
          height: 8px;
        }
        span {
          @include fluidFont(8, 8, 1.2);
          color: rgba($blueDark, 0.5);
          font-weight: 400;
          letter-spacing: 0.16px;
        }
      }
      .property_content {
        margin-top: 6px;
        display: flex;
        align-items: flex-start;
        gap: 6px;
        flex-direction: column;
        .property_name {
          display: flex;
          gap: 6px;
          align-items: center;
          .v-icon {
            width: 15px;
            height: 15px;
            border-radius: 100%;
            background-color: rgba($blueDark, 1);
            img {
              width: 6px;
              height: 6px;
              filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg)
                brightness(102%) contrast(102%);
            }
          }
          h4 {
            @include fluidFont(12, 12, 1.2);
            font-weight: 700;
            color: rgba($blueDark, 1);
            letter-spacing: 0.77px;
            margin: 0;
          }
        }
        .property_info {
          @include fluidFont(8, 8, 1.2);
          color: rgba($blueDark, 0.5);
          font-weight: 400;
          letter-spacing: 0.16px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 50%;
        }
        .property_type {
          display: flex;
          gap: 6px;
          align-items: center;
          .v-icon {
            width: 10px;
            height: 10px;
            border-radius: 100%;
            background-color: rgba($info, 1);
            img {
              width: 3px;
              height: 3px;
              filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg)
                brightness(102%) contrast(102%);
            }
          }
          h5 {
            @include fluidFont(8, 8, 1.2);
            color: rgba($info, 1);
            font-weight: 400;
            letter-spacing: 0.16px;
            margin: 0;
          }
        }
      }
    }
    .property_img {
      width: 87px;
      height: 87px;
      overflow: hidden;
      border-radius: 6px;
      img {
        width: 100%;
        max-width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
.map__wrapper {
  margin-top: 20px;
  min-height: 280px;
  .map-container {
    min-height: 280px;
  }
}
</style>
