<template>
  <svg
    width="29"
    height="30"
    viewBox="0 0 29 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="0.5" width="29" height="29" rx="4" fill="#B7677A" />
    <g clip-path="url(#clip0_151_45859)">
      <path
        d="M24.1107 18.5764L23.6426 19.0445L24.7328 20.1347H20.5662V15.1689C20.5662 14.8038 20.2692 14.5068 19.9041 14.5068H14.89C14.6374 14.5068 14.4106 14.6472 14.2977 14.8727L13.8187 15.8311H6.66211C6.29695 15.8311 6 16.128 6 16.4932V24.4384C6 24.8035 6.29695 25.1005 6.66211 25.1005H19.9041C20.2692 25.1005 20.5662 24.8035 20.5662 24.4384V20.7968H24.7328L23.6426 21.887L24.1107 22.3551L26 20.4657L24.1107 18.5764ZM19.242 15.1689H19.9041V15.8311H19.242V15.1689ZM17.9178 15.1689H18.5799V15.8311H17.9178V15.1689ZM16.5936 15.1689H17.2557V15.8311H16.5936V15.1689ZM15.9315 23.1141H9.31051V17.8173H15.9315V23.1141ZM15.9315 15.8311H15.2694V15.1689H15.9315V15.8311Z"
        fill="white"
      />
      <path
        d="M15.2695 18.4795H13.9453V20.4658H11.2969V18.4795H9.97266V22.4521H15.2695V18.4795Z"
        fill="white"
      />
      <path
        d="M6.99316 7.22388H8.31734V15.1691H6.99316V7.22388Z"
        fill="white"
      />
      <path d="M11.959 18.4795H13.2832V19.8037H11.959V18.4795Z" fill="white" />
      <path
        d="M13.7055 14.5768C13.9313 14.1252 14.3852 13.8449 14.89 13.8449H19.573V5.89966H8.97949V15.169H13.4096L13.7055 14.5768ZM18.9109 12.5206H13.6142V11.8585H18.911L18.9109 12.5206ZM18.9109 11.1965H13.6142V10.5343H18.911L18.9109 11.1965ZM18.9109 8.54806H18.2488V7.88595H18.9109V8.54806ZM9.64156 6.56177H18.9109V7.22388H9.64156V6.56177ZM9.64156 7.88595H17.5868V8.54806H9.64156V7.88595ZM9.64156 9.21017H18.9109V9.87228H9.64156V9.21017ZM12.621 14.1759H9.97262V10.8654H12.621V14.1759Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_151_45859">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(6 5.5)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: "EstimateSentIcon",
};
</script>
