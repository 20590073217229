<template>
  <div class="issue_type_wrapper no-scroll">
    <v-tabs class="tab-list" v-model="selectedTab" align-tabs="center" stacked>
      <v-tab
        v-for="tabItem in IssuesTabList"
        :key="tabItem.name"
        :value="tabItem.name"
      >
        <v-icon :icon="tabItem.icon"></v-icon>

        {{ tabItem.label }}
      </v-tab>
    </v-tabs>

    <v-window v-model="selectedTab" :touch="false">
      <v-window-item
        v-for="tabValue in IssuesTabList"
        :key="tabValue.name"
        :value="tabValue.name"
      >
        <PostIssuePropertyComponent
          v-if="selectedTab === 'new-issues'"
          @switchToIssueListTab="switchToIssueListTab"
        />

        <ActiveIssuesComponent v-if="selectedTab === 'active-issues'" />
      </v-window-item>
    </v-window>
  </div>
</template>

<script lang="ts">
import PostIssuePropertyComponent from "@/modules/issues/components/PostIssuePropertyComponent.vue";
import { computed, defineComponent, ref, watch } from "vue";
import { useStore } from "vuex";
import { PROJECT_STORE } from "@/store/modules/project";
import ActiveIssuesComponent from "@/modules/issues/components/ActiveIssuesComponent.vue";
export default defineComponent({
  name: "IssuePropertyPage",
  components: {
    PostIssuePropertyComponent,

    ActiveIssuesComponent,
  },
  setup() {
    const selectedTab = ref();
    const store = useStore();
    const getProjectRedirectTabName = computed(
      () => store.getters[`${PROJECT_STORE}/getProjectRedirectTabName`]
    );
    const IssuesTabList = [
      { name: "new-issues", icon: "mdi-home", label: "New Issue" },
      {
        name: "active-issues",
        icon: "mdi-camera-iris",
        label: "Issues",
      },
      // { name: "draft-issue", icon: "mdi-menu", label: "Draft Issues" },
      // {
      //   name: "complete-issues",
      //   icon: "mdi-file-image",
      //   label: "Completed Issues",
      // },
    ];

    const switchToIssueListTab = () => {
      selectedTab.value = "active-issues";
    };
    watch(getProjectRedirectTabName, (newValue, oldValue) => {
      selectedTab.value = newValue;
    });
    return { selectedTab, IssuesTabList, switchToIssueListTab };
  },
});
</script>
<!-- <style lang="scss" scoped>
.issue_type_wrapper {
  .v-slide-group {
    height: auto;
    .v-slide-group__content {
      @include respond(s720) {
        padding: 0;
      }
    }
  }
}
</style> -->
<style lang="scss" scoped>
@import "@/modules/issues/styles/issues.scss";
</style>
