import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d0a030a8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "courses_content tw-h-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_CoursesListView = _resolveComponent("CoursesListView")!
  const _component_EnrollCoursesList = _resolveComponent("EnrollCoursesList")!
  const _component_CompletedCourseList = _resolveComponent("CompletedCourseList")!
  const _component_v_window_item = _resolveComponent("v-window-item")!
  const _component_v_window = _resolveComponent("v-window")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_tabs, {
      class: "tab-list md:!tw-h-auto md:!tw-mb-4",
      modelValue: _ctx.tab,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tab) = $event)),
      "align-tabs": "center",
      stacked: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_tab, { value: "tab-1" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_icon, null, {
              default: _withCtx(() => [
                _createTextVNode("mdi-home")
              ]),
              _: 1
            }),
            _createTextVNode(" Courses ")
          ]),
          _: 1
        }),
        _createVNode(_component_v_tab, { value: "tab-2" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_icon, null, {
              default: _withCtx(() => [
                _createTextVNode("mdi-file-document")
              ]),
              _: 1
            }),
            _createTextVNode(" Enrolled ")
          ]),
          _: 1
        }),
        _createVNode(_component_v_tab, { value: "tab-3" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_icon, null, {
              default: _withCtx(() => [
                _createTextVNode("mdi-view-list")
              ]),
              _: 1
            }),
            _createTextVNode(" Completed ")
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_v_window, {
      modelValue: _ctx.tab,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.tab) = $event)),
      class: "tw-h-full md:!tw-pb-16 md:!tw-box-border"
    }, {
      default: _withCtx(() => [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (i) => {
          return _createVNode(_component_v_window_item, {
            key: i,
            value: 'tab-' + i
          }, {
            default: _withCtx(() => [
              (i === 1)
                ? (_openBlock(), _createBlock(_component_CoursesListView, { key: 0 }))
                : _createCommentVNode("", true),
              (i === 2)
                ? (_openBlock(), _createBlock(_component_EnrollCoursesList, {
                    key: 1,
                    onOnBack: _ctx.onBack
                  }, null, 8, ["onOnBack"]))
                : _createCommentVNode("", true),
              (i === 3)
                ? (_openBlock(), _createBlock(_component_CompletedCourseList, {
                    key: 2,
                    onOnBack: _ctx.onBack
                  }, null, 8, ["onOnBack"]))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1032, ["value"])
        }), 64))
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}