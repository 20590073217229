<template>
  <GeneralDialog
    :headerTitle="modalHeader.title"
    :headerDescription="modalHeader.description"
    @on-close="onCloseModal"
    :isShowCloseButton="false"
    min-width="430px"
  >
    <template #body>
      <div class="tw-w-full tw-max-w-[412px] tw-px-[16px]">
        <div class="tw-flex tw-justify-between tw-items-center">
          <div class="tw-relative tw-h-[40px]">
            <ImageHolder class="tw-absolute tw-top-0 tw-left-0" />
            <ImageHolder class="tw-absolute tw-top-0 tw-left-[25px]" />
            <ImageHolder class="tw-absolute tw-top-0 tw-left-[50px]" />
            <!-- Numbered ImageHolder -->
            <div
              class="tw-w-[40px] tw-h-[40px] tw-flex tw-justify-center tw-items-center tw-absolute tw-top-0 tw-left-[75px] tw-bg-cyan-50 tw-rounded-full tw-border-solid tw-border-white"
            >
              <Text
                variant="p"
                textWeight="600"
                textColor="#4B4BFF"
                textSize="20px"
              >
                +5
              </Text>
            </div>
          </div>
          <div class="tw-flex tw-items-center tw-gap-[16px]">
            <EstimateBadge bgColor="#6B5F4B" />
          </div>
        </div>

        <div class="tw-flex tw-items-center tw-gap-1 tw-mt-4">
          <span class="tw-text-xl tw-font-bold tw-leading-6 tw-text-[#0C0F4A]">
            {{ estimateData?.workStation?.name }}
          </span>
          <span class="tw-text-[10px] tw-font-[600] tw-text-[#0C0F4A80]">
            +3 more
          </span>
        </div>

        <div class="tw-flex tw-items-center tw-gap-3 tw-mt-1">
          <div
            class="tw-flex tw-items-center tw-font-font-semibold tw-text-xs tw-text-[#0C0F4A80]"
          >
            <span>Limited Company | </span>
            <span class="tw-flex tw-items-center">
              <v-icon icon="mdi-map-marker" size="12px" />
              {{ estimateData?.workStation?.user?.postcode }}
            </span>
          </div>
          <div class="tw-flex tw-items-center tw-gap">
            <v-icon icon="mdi-star" color="#faa500" size="12px" />
            <span class="tw-font-normal tw-text-[#0C0F4A] tw-text-xs"
              >4.8/5 (536 Jobs)</span
            >
          </div>
        </div>

        <div class="tw-flex tw-items-center tw-gap-2 tw-mt-3">
          <CustomBadge>
            <v-icon
              class="tw-text-[12px]"
              icon="mdi-check-circle"
              color="green"
            />
            <span
              class="tw-pl-[4px] tw-text-xs tw-font-semibold tw-text-[#1FB27A]"
            >
              {{
                estimateData?.experience
                  ? `${estimateData?.experience} + Yrs `
                  : "N/A"
              }}
            </span>
            <span class="tw-text-xs tw-font-semibold tw-text-[#0C0F4A]">
              Exp.
            </span>
          </CustomBadge>
          <CustomBadge v-if="displayedSkills.length">
            <span
              class="tw-text-xs tw-font-bold tw-text-[#0C0F4A]"
              v-for="skill in getUserSkillList(displayedSkills)"
              :key="skill.id"
            >
              {{ skill?.label }}
            </span>
          </CustomBadge>
          <span v-if="remainingCount > 0"> +{{ remainingCount }} </span>
        </div>

        <div class="tw-mt-4">
          <div class="tw-w-full tw-h-[1px] tw-bg-gray-200 tw-mb-1" />
          <div class="tw-grid tw-grid-cols-3 tw-my-2">
            <div
              class="tw-flex tw-flex-col tw-gap-2 tw-items-center tw-justify-center"
            >
              <span class="tw-font-[500] tw-text-[12px] tw-text-[#00000080]">
                Start Date
              </span>
              <span class="tw-font-[700] tw-text-[14px] tw-text-[#0C0F4A]">
                {{ formattedDate }}
              </span>
            </div>
            <div
              class="tw-flex tw-flex-col tw-gap-2 tw-items-center tw-justify-center tw-border-0 tw-border-solid tw-border-l tw-border-r tw-border-[#F1F6FB]"
            >
              <span class="tw-font-[500] tw-text-[12px] tw-text-[#00000080]">
                Duration
              </span>
              <span class="tw-font-[700] tw-text-[14px] tw-text-[#0C0F4A]">
                {{ duration }}
              </span>
            </div>
            <div
              class="tw-flex tw-flex-col tw-gap-2 tw-items-center tw-justify-center"
            >
              <span class="tw-font-[500] tw-text-[12px] tw-text-[#00000080]">
                Cost
              </span>
              <span class="tw-font-[700] tw-text-[14px] tw-text-[#0C0F4A]">
                £ 121
              </span>
            </div>
          </div>
          <div class="tw-w-full tw-h-[1px] tw-bg-gray-200 tw-mt-1" />
        </div>
      </div>
    </template>
    <template #footer>
      <section
        class="tw-w-full tw-grid tw-grid-cols-1 tw-items-center tw-gap-3"
      >
        <Button
          variant="tertiary"
          label="Back To Project"
          @click="onClickBack"
          class="button button-purple-border"
        />
        <Button
          variant="tertiary"
          label="Deposit funds into escrow"
          @click="onClickDepositFunds"
          class="button button-orange"
        />
      </section>
    </template>
  </GeneralDialog>
</template>
<script setup lang="ts">
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import EstimateBadge from "@/core/uni-components/EstimateBadge.vue";
import ImageHolder from "@/core/uni-components/ImageHolder.vue";
import CustomBadge from "@/core/uni-components/CustomBadge.vue";
import { computed } from "vue";
import moment from "moment";
import { USER_STORE } from "@/store/modules/user";
import { onMounted } from "vue";
import { getUserSubSkillList } from "@/core/utils/common";
import { WORKSTATION } from "@/store/modules/workstation";
import { useStore } from "vuex";
const emits = defineEmits(["on-back-project", "on-deposit"]);

const props = defineProps({
  estimateData: {
    type: Object,
  },
});
const modalHeader = {
  title: "Estimate has been approved!",
  description: "Estimate provided by",
};
const onClickBack = () => {
  emits("on-back-project", false);
};

const onClickDepositFunds = () => {
  emits("on-deposit");
};

const duration = computed(() => {
  if (
    !props.estimateData?.suggestedStartDate ||
    !props.estimateData?.projectEstimateEndDate
  ) {
    return 0;
  }

  const startDate = moment(props.estimateData?.suggestedStartDate) as any;
  const endDate = moment(props.estimateData?.projectEstimateEndDate) as any;

  const totalDays = endDate.diff(startDate, "days");

  if (totalDays % 7 === 0) {
    const totalWeeks = totalDays / 7;
    return `${totalWeeks} week${totalWeeks > 1 ? "s" : ""}`;
  }

  return `${totalDays} day${totalDays > 1 ? "s" : ""}`;
});
const formattedDate = computed(() => {
  if (!props.estimateData?.suggestedStartDate) {
    return "N/A";
  }
  const date = new Date(props.estimateData?.suggestedStartDate);
  const options = { day: "2-digit", month: "long", year: "numeric" } as any;
  return date.toLocaleDateString("en-GB", options);
});

const displayedSkills = computed(() => {
  return props.estimateData?.workStation?.user?.userSkills.slice(0, 2);
});

const remainingCount = computed(() => {
  return props.estimateData?.workStation?.user?.userSkills.length - 2;
});

const store = useStore();
const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);

const getAllSpecialism = computed(
  () => store.getters[`${USER_STORE}/getAllSpecialism`]
);

const getUserSkillList = (userSkill: any) => {
  return getUserSubSkillList(userSkill, getAllSpecialism.value);
};
onMounted(async () => {
  try {
    if (!getAllSpecialism.value.length) {
      await store.dispatch(
        `${USER_STORE}/setAllSpecialismsCategory`,
        activeUserWorkstation.value.id
      );
    }
  } catch (error) {
    console.log();
  }
});
</script>

<style lang="scss" scoped></style>
