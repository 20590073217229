<template>
  <CommonDialog
    :isShowHeader="false"
    :isShowBackButton="false"
    :className="['edit_specialism_modal']"
    width="835px"
    height="auto"
  >
    <template v-slot:body>
      <div class="modal_content">
        <div class="header">
          <h4>Edit Service for {{ userSpecialismData?.label }} Services</h4>
          <v-btn
            icon="mdi-close"
            @click="onClickCloseButton"
            variant="text"
          ></v-btn>
        </div>
        <div class="modal_form">
          <div class="service_wrapper">
            <v-icon icon="mdi-hammer-wrench" />
            <h4>{{ userSpecialismData?.label }}</h4>
          </div>
          <div class="cerificate__content" v-if="!loader">
            <div class="left_block">
              <h5>Services</h5>
              <div class="left_block__content">
                <v-text-field
                  placeholder="Search"
                  class="search_field"
                  bg-color="#fff"
                  prepend-inner-icon="mdi-magnify"
                  variant="outlined"
                  v-model="searchSubCategories"
                />
                <div class="left_block__content__item">
                  <div class="service__list" v-if="listOfSubCategory?.length">
                    <ul>
                      <li
                        v-for="subCategory in filterSubCategoryList"
                        :key="subCategory?.id"
                      >
                        <v-checkbox
                          color="indigo"
                          v-model="selectedSubCategory"
                          :value="subCategory?.id"
                        />
                        <p>{{ subCategory?.label }}</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="right_block">
              <h5>Members</h5>
              <div class="right_block__content">
                <v-text-field
                  placeholder="Search"
                  class="search_field"
                  bg-color="#fff"
                  prepend-inner-icon="mdi-magnify"
                  variant="outlined"
                  v-model="searchMembers"
                />
                <div class="members__list" v-if="teamMemberList?.length">
                  <ul class="members__list__status">
                    <li
                      v-for="member in filterTeamMemberList"
                      :key="member?.id"
                    >
                      <v-checkbox
                        color="indigo"
                        v-model="selectedMembers"
                        :value="member?.toUser?.id"
                      />
                      <div class="member_img">
                        <img
                          v-if="member?.toUser?.profileURL"
                          :src="member?.toUser?.profileURL"
                        />
                        <UserProfileLogo
                          v-else
                          :userName="`${member?.toUser?.firstName} ${member?.toUser?.lastName}`"
                        />
                      </div>
                      <p>
                        {{ member?.toUser?.firstName }}
                        {{ member?.toUser?.lastName }}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="footer">
            <v-btn
              class="button button-purple-border"
              width="50%"
              max-width="100%"
              @click="onClickCloseButton"
              >CANCEL</v-btn
            >
            <v-btn
              class="button button-orange"
              width="50%"
              max-width="100%"
              :disabled="disabled || loader"
              @click="onSave"
              :loading="disabled"
              >SAVE</v-btn
            >
          </div>
        </div>
        <div class="loader">
          <CommonLoader v-if="loader" :loading="loader" />
        </div>
      </div>
    </template>
  </CommonDialog>
</template>

<script lang="ts">
import CommonDialog from "@/core/components/CommonDialog.vue";
import { useStore } from "vuex";
import { computed, onBeforeMount, ref } from "vue";
import { USER_STORE } from "@/store/modules/user";
import { MEMBER_STORE } from "@/store/modules/manage-member";
import { getImageStringToImageURL, parseJSON } from "@/core/utils/common";
import UserProfileLogo from "@/core/components/UserProfileLogo.vue";
import { WORKSTATION } from "@/store/modules/workstation";
import CommonLoader from "@/core/components/CommonLoader.vue";

export default {
  props: {
    userSpecialismData: { type: Object, UserProfileLogodefault: null },
  },
  components: {
    CommonDialog,
    UserProfileLogo,
    CommonLoader,
  },
  setup(props: any, ctx: any) {
    const store = useStore();
    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const userSpecialismModalData = ref(null) as any;
    const selectedMembers = ref([]) as any;
    const searchMembers = ref<string>("");
    const searchSubCategories = ref<string>("");
    const selectedSubCategory = ref([]) as any;
    const listOfSubCategory = ref([]) as any;
    const loader = ref(false);
    const deleteMembersIdsAsExpertise = ref<number[]>([]);
    const teamMemberList = ref([]) as any;
    const onClickCloseButton = () => {
      ctx.emit("onClickCancel");
    };
    const disabled = ref<boolean>(false);

    const activeUserWorkstation = computed(
      () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
    );

    const onSave = async () => {
      try {
        const newSubCategoriesId = selectedSubCategory.value.filter(
          (id: any) =>
            !(
              parseJSON(userSpecialismModalData.value.subCategoryIds) || []
            ).includes(id)
        );

        const deletedSubCategoriesId = (
          parseJSON(userSpecialismModalData.value.subCategoryIds) || []
        ).filter((id: any) => !selectedSubCategory.value.includes(id));

        const newAssignedUserIds = selectedMembers.value.filter(
          (id: any) =>
            !(
              parseJSON(userSpecialismModalData.value.assignedUserIds) || []
            ).includes(id)
        );

        const deletedAssignedUserId = (
          parseJSON(userSpecialismModalData.value.assignedUserIds) || []
        ).filter((id: any) => !selectedMembers.value.includes(id));

        if (deletedAssignedUserId?.length || deletedSubCategoriesId?.length) {
          await store.dispatch(`${USER_STORE}/removeSubCategory`, {
            userId: user.value.id,
            skillRecordId: userSpecialismModalData.value.id,
            payload: {
              categoryId: userSpecialismModalData.value.category.id,
              assignedUserIds: deletedAssignedUserId || [],
              subCategoryIds: deletedSubCategoriesId || [],
            },
          });
        }

        if (newAssignedUserIds.length || newSubCategoriesId.length) {
          await store.dispatch(`${USER_STORE}/saveUserSkills`, {
            userId: user.value.id,

            payload: [
              {
                categoryId: userSpecialismModalData.value.category.id,
                subCategoryIds: newSubCategoriesId || [],
                assignedUserIds: newAssignedUserIds || [],
              },
            ],
          });
        }
        if (
          newAssignedUserIds.length ||
          newSubCategoriesId.length ||
          deletedAssignedUserId.length ||
          deletedSubCategoriesId.length
        ) {
          await store.dispatch(`${USER_STORE}/getUserSkills`, user.value.id);
        }
      } catch (error) {
        console.log("specialism edit error", error);
      } finally {
        onClickCloseButton();
      }
    };

    const setMemberUser = async () => {
      try {
        loader.value = true;
        const projectDataResponse = await store.dispatch(
          `${MEMBER_STORE}/getTeamMember`,
          {
            userId: user.value?.id,
          }
        );
        teamMemberList.value = projectDataResponse.filter(
          (member: any) => member.invitationStatus === "accepted"
        );

        for (const member of teamMemberList.value) {
          if (member?.toUser?.userPublicProfile?.profileImage) {
            member.toUser.profileURL = await getImageStringToImageURL(
              member?.toUser?.userPublicProfile?.profileImage
            );
          }
        }
      } catch (error) {
        console.log("error: ", error);
      } finally {
        loader.value = false;
      }
    };

    const filterTeamMemberList = computed(() => {
      if (searchMembers.value) {
        return teamMemberList.value.filter((member: any) => {
          const memberFullName = `${member.toUser.firstName} ${member.toUser.lastName}`;

          return memberFullName
            .toLowerCase()
            .includes(searchMembers.value.toLowerCase().trim());
        });
      } else {
        return teamMemberList.value;
      }
    });

    const filterSubCategoryList = computed(() => {
      if (searchSubCategories.value) {
        return listOfSubCategory.value.filter((subCategory: any) => {
          return subCategory.label
            .toLowerCase()
            .includes(searchSubCategories.value.toLowerCase().trim());
        });
      } else {
        return listOfSubCategory.value;
      }
    });
    const getAllSpecialism = computed(
      () => store.getters[`${USER_STORE}/getAllSpecialism`]
    );

    onBeforeMount(async () => {
      userSpecialismModalData.value = props.userSpecialismData;
      selectedSubCategory.value =
        parseJSON(props.userSpecialismData.subCategoryIds) || [];
      selectedMembers.value =
        parseJSON(props.userSpecialismData.assignedUserIds) || [];
      if (!getAllSpecialism.value.length) {
        await store.dispatch(
          `${USER_STORE}/setAllSpecialismsCategory`,
          activeUserWorkstation.value.id
        );
      }

      listOfSubCategory.value = getAllSpecialism.value.find(
        (o: any) => o.id == props.userSpecialismData.category.id
      )?.subCategories;
      await setMemberUser();
    });
    return {
      user,
      onClickCloseButton,
      userSpecialismModalData,
      teamMemberList,
      selectedMembers,
      searchMembers,
      filterTeamMemberList,
      onSave,
      deleteMembersIdsAsExpertise,
      disabled,
      filterSubCategoryList,
      selectedSubCategory,
      searchSubCategories,
      listOfSubCategory,
      loader,
    };
  },
};
</script>
