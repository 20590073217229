<template>
  <div class="dashboard-welcome">
    <div class="dashboard-welcome__close" v-if="isShowCloseButton">
      <v-icon icon="mdi-close"></v-icon>
    </div>
    <div class="dashboard-welcome__text">
      <slot name="title"></slot>
      <p class="body-text !tw-mb-4">
        <slot name="text"></slot>
      </p>
      <slot name="button"></slot>
    </div>
    <div class="dashboard-welcome__img">
      <div class="img-wrapper">
        <slot name="image">
          <img src="../../assets/images/final-contractor.png"
        /></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isShowCloseButton: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn--disabled {
  background-color: rgba(12, 15, 74, 0.04);
  color: rgba(12, 15, 74, 1);
  opacity: 0.4;
}
</style>
