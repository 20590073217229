<template>
  <div class="gemini-model">
    <div class="gemini-model__header">
      <img src="@/assets/images/krystal-purple.svg" alt="" />
      <h2>write with crytal</h2>
      <v-btn
        icon="mdi-close"
        @click="onClose"
        density="compact"
        variant="text"
      ></v-btn>
    </div>
    <div class="gemini-model__body">
      <div class="gemini-model__prompt-input">
        <v-text-field
          :autofocus="true"
          v-model="jobDiscriptionRecommendationInput"
          placeholder="Enter prompt"
          variant="outlined"
          class="input_field"
          bg-color="#fff"
          @keyup.enter="generateRecommendationForJobDescription"
        ></v-text-field>
        <v-btn
          @click="generateRecommendationForJobDescription"
          :disabled="disableBtn"
          variant="text"
          density="compact"
          icon="mdi-send"
        ></v-btn>
      </div>
      <div class="gemini-model__text">
        <div class="gemini-model__text-inner">
          <v-textarea
            bg-color="white"
            base-color="white"
            density="compact"
            variant="solo"
            class="input_field input_textarea"
            flat
            no-resize
            rows="1"
            :loading="isLoading"
            v-model="jobDiscriptionRecommendationOutput"
          ></v-textarea>
        </div>
        <div class="gemini-model__text-footer">
          <button @click="copyContent">
            <v-icon icon="mdi-content-copy" color="#80829F"></v-icon>
          </button>
          <button @click="cleanBody">
            <v-icon icon="mdi-trash-can-outline" color="#80829F"></v-icon>
          </button>
        </div>
      </div>
    </div>

    <div class="gemini-model__footer">
      <v-btn
        :disabled="disableBtn"
        class="button button-purple"
        @click="insertContent"
        >insert</v-btn
      >
      <v-btn
        :disabled="disableBtn"
        class="button button-white"
        @click="generateRecommendationForJobDescription"
        >regenrate</v-btn
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { PROJECT_STORE } from "@/store/modules/project";
import { useStore } from "vuex";

const emit = defineEmits(["appendJobDescription", "onClose"]);
const store = useStore();
const jobDiscriptionRecommendationInput = ref("");
const jobDiscriptionRecommendationOutput = ref("");
const isLoading = ref(false);
const disableBtn = ref(false);
const generateRecommendationForJobDescription = async () => {
  if (jobDiscriptionRecommendationInput.value) {
    jobDiscriptionRecommendationOutput.value = "";
    isLoading.value = true;
    disableBtn.value = true;
    const response = await store.dispatch(
      `${PROJECT_STORE}/getRecommendationForJobDescription`,
      {
        prompt: jobDiscriptionRecommendationInput.value,
      }
    );
    const tempText = response.text.split(" ");
    isLoading.value = false;

    const printInterval = setInterval(() => {
      jobDiscriptionRecommendationOutput.value += ` ${tempText.shift()}`;
      if (!tempText.length) {
        clearInterval(printInterval);
        disableBtn.value = false;
      }
    }, 100);
  }
};

const cleanBody = () => {
  jobDiscriptionRecommendationInput.value = "";
  jobDiscriptionRecommendationOutput.value = "";
};

const copyContent = async () => {
  await navigator.clipboard.writeText(jobDiscriptionRecommendationOutput.value);
};
const insertContent = () => {
  if (jobDiscriptionRecommendationOutput.value) {
    emit("appendJobDescription", jobDiscriptionRecommendationOutput.value);
    cleanBody();
  } else {
    onClose();
  }
};
const onClose = () => {
  emit("onClose");
};
</script>
<style lang="scss" scoped>
.gemini-model {
  width: 348px;
  background: $white;
  box-shadow: 0px 0px 8px rgba(12, 15, 74, 0.08);
  border-radius: 8px;
  z-index: 9;
  padding: 16px 16px 20px;

  @include respond(s720) {
    width: calc(100vw - 80px);
    right: 5px;
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;

    img {
      max-width: 24px;
      width: 100%;
      height: auto;
      object-fit: contain;
      flex-shrink: 0;
    }

    h2 {
      @include fluidFont(14, 14, 1);
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 0.15px;
    }

    .v-btn {
      margin-left: auto;
      flex-shrink: 0;
    }
  }

  .input_field {
    width: 100%;

    :deep(.v-input__control) {
      .v-field {
        padding: 11px 60px 11px 12px;
        border-radius: 8px;
        border: 1px solid rgba($buttonText, 0.4);
        gap: 0;

        .v-field__field {
          .v-field__input {
            padding: 0;
            min-height: auto;
            @include fluidFont(14, 14, 1.4);
            font-weight: 400;
            color: rgba($blueDark, 1);
            &::placeholder {
              @include fluidFont(14, 14, 1.4);
              font-weight: 400;
              color: rgba($blueDark, 1);
            }
          }
        }
        .v-field__outline {
          display: none;
        }
        .v-field__append-inner {
          height: 16px;
        }
      }
    }
  }

  :deep(.v-textarea) {
    .v-input__control {
      .v-field {
        padding-right: 12px;
        padding-bottom: 40px;

        .v-field__input {
          mask-image: none;
          height: 180px;
        }
      }
    }
  }

  &__prompt-input {
    position: relative;
    margin-bottom: 12px;

    .v-btn {
      position: absolute;
      top: 6px;
      right: 6px;
      font-size: 10px;
      color: rgba($blueDark, 0.4);

      &:hover {
        color: $blueDark;
      }
    }
  }

  &__text {
    position: relative;

    &-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: rgba(#d9d9d9, 0.2);
      border-radius: 0 0 8px 8px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 6px;
      padding: 5px 12px;

      .mdi {
        font-size: 14px;
      }
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    gap: 6px;
    flex-wrap: wrap;
    margin-top: 16px;

    .button {
      min-height: 32px;
      padding: 8px 10px;
      border-radius: 5px;
      line-height: 1;
      height: auto;

      &.button-white {
        color: #80829f;
      }

      :deep(.v-btn__content) {
        @include fluidFont(12, 12, 1);
        font-weight: 600;
      }
    }
  }
}
</style>
