<template>
  <div
    class="tw-w-full tw-h-full tw-flex tw-flex-col tw-gap-4 rmd:tw-gap-6 tw-items-start"
  >
    <div
      class="tw-w-full tw-h-full rmd:tw-min-w-[440px] rmd:tw-w-[440px] rmd:!tw-min-h-[198px] tw-flex tw-flex-col tw-gap-4 rmd:tw-px-2 tw-box-border tw-overflow-y-hidden"
    >
      <PropertyCard />
    </div>
    <Text variant="h5">Comparable</Text>
    <section
      class="tw-flex tw-flex-col tw-w-full tw-p-4 tw-box-border tw-gap-4 tw-bg-white"
    >
      <div
        class="tw-w-full tw-h-[164px] tw-min-h-[164px] tw-flex tw-items-center tw-justify-center tw-relative"
        :class="{
          '!tw-h-[500px] !tw-min-h-[500x]': isMapShow,
        }"
      >
        <Button
          variant="custom"
          activeBgColor="#0C0F4A"
          class="!tw-hidden rmd:!tw-flex !tw-w-auto tw-absolute tw-right-4 tw-top-4 tw-z-[999]"
          width="auto"
          :label="isMapShow ? 'show table' : 'show map'"
          @click="toggleMap"
        />
        <LeafletMap
          :data="[]"
          :isDraggable="isMapShow"
          :showControls="false"
          :key="childKey"
        />
      </div>
      <div class="tw-w-full !tw-flex rmd:!tw-hidden">
        <Button
          variant="custom"
          activeBgColor="#0C0F4A"
          :label="isMapShow ? 'show table' : 'show map'"
          @click="toggleMap"
        />
      </div>
      <div
        class="tw-w-full tw-flex tw-flex-col rmd:tw-flex-row tw-items-start tw-gap-4 tw-justify-between"
      >
        <InputField
          prepend-inner-icon="mdi-magnify"
          class="tw-w-full rmd:tw-max-w-[412px]"
          placeholder="Search"
          v-model="filters.keywords"
          @input="debouncedFilterComparables"
        />
        <div
          class="tw-flex tw-flex-col tw-items-end tw-gap-4 tw-w-full tw-max-w-full"
        >
          <div
            class="tw-grid tw-grid-cols-3 rmd:tw-flex tw-items-center tw-gap-[.4rem] rmd:tw-gap-4 tw-w-full tw-max-w-full"
          >
            <v-menu
              v-model="fitlerMenu"
              :close-on-content-click="false"
              location="bottom end"
              origin="auto"
            >
              <template v-slot:activator="{ props }">
                <v-btn
                  class="md:!tw-w-full"
                  variant="outlined"
                  size="large"
                  v-bind="props"
                >
                  <Text
                    variant="xsmall"
                    class="rmd:!tw-text-[14px]"
                    whiteSpace="nowrap"
                    >Add Filters</Text
                  >
                </v-btn>
              </template>

              <v-card min-width="250" class="tw-flex tw-flex-col tw-top-[10px]">
                <div class="tw-w-full tw-p-4 tw-box-border">
                  <Text>Filters</Text>
                </div>
                <v-divider></v-divider>
                <div class="tw-w-full tw-h-full tw-flex-col tw-flex">
                  <v-menu
                    v-for="(menu, index) in filterSubMenus"
                    :key="index"
                    location="start"
                    origin="end top"
                    v-model="menu.menu"
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ props }">
                      <div
                        v-bind="props"
                        class="tw-cursor-pointer tw-h-full tw-flex tw-items-center tw-justify-start tw-py-3 tw-px-4 tw-box-border hover:tw-bg-gray-100 tw-relative"
                      >
                        <div
                          v-if="
                            (menu?.name === 'Property Type' &&
                              propertyTypeFilters.length) ||
                            (menu?.name === 'Bedrooms' && bedFilters.length)
                          "
                          class="tw-w-[8px] tw-h-[8px] tw-rounded-full tw-bg-[rgb(0,210,182)] tw-absolute tw-top-4 tw-left-2"
                        ></div>
                        <Text class="tw-pl-1 tw-box-border">
                          {{ menu?.name }}
                        </Text>
                      </div>
                    </template>
                    <v-card min-width="200" class="tw-right-[10px]">
                      <div
                        v-for="(subMenu, index) in menu.subMenus"
                        :key="index"
                        class="tw-flex tw-items-center tw-justify-start tw-box-border hover:tw-bg-gray-100"
                      >
                        <v-checkbox
                          v-model="selectedFilters"
                          :label="subMenu.label"
                          :value="subMenu"
                        ></v-checkbox>
                      </div>
                    </v-card>
                  </v-menu>
                </div>
              </v-card>
            </v-menu>
            <v-menu
              v-model="radiusMenu"
              :close-on-content-click="false"
              location="bottom end"
              origin="auto"
            >
              <template v-slot:activator="{ props }">
                <v-btn
                  class="md:!tw-w-full"
                  variant="outlined"
                  size="large"
                  v-bind="props"
                  append-icon="mdi-chevron-down"
                >
                  <Text
                    variant="xsmall"
                    class="rmd:!tw-text-[14px]"
                    whiteSpace="nowrap"
                    >Radius</Text
                  >
                </v-btn>
              </template>

              <v-card min-width="250" class="tw-flex tw-flex-col tw-top-[10px]">
                <v-radio-group v-model="selectedRadius">
                  <div
                    v-for="(menu, index) in radiusFilters"
                    :key="index"
                    class="tw-flex tw-items-center tw-justify-start tw-box-border hover:tw-bg-gray-100"
                  >
                    <v-radio :label="menu.label" :value="menu.value"></v-radio>
                  </div>
                </v-radio-group>
              </v-card>
            </v-menu>
            <v-menu
              v-model="exportMenu"
              :close-on-content-click="false"
              location="bottom end"
              origin="auto"
            >
              <template v-slot:activator="{ props }">
                <v-btn
                  class="md:!tw-w-full"
                  variant="outlined"
                  size="large"
                  v-bind="props"
                  append-icon="mdi-chevron-down"
                  :disabled="true"
                >
                  <Text
                    variant="xsmall"
                    class="rmd:!tw-text-[14px]"
                    whiteSpace="nowrap"
                    >Export</Text
                  >
                </v-btn>
              </template>

              <v-card min-width="250" class="tw-flex tw-flex-col tw-top-[10px]">
                <div
                  v-for="(menu, index) in exportFilters"
                  :key="index"
                  class="tw-flex tw-items-center tw-justify-start tw-box-border hover:tw-bg-gray-100"
                >
                  <v-checkbox
                    v-model="selectedExportFilters"
                    :label="menu.label"
                    :value="menu"
                  ></v-checkbox>
                </div>
                <v-card-actions>
                  <Button
                    class="tw-cursor-not-alloweds"
                    label="Apply & Export"
                    :isActive="false"
                    ><template #prefix
                      ><v-icon icon="mdi-lock"></v-icon></template
                  ></Button>
                </v-card-actions>
              </v-card>
            </v-menu>
          </div>
          <div
            class="tw-flex tw-items-center tw-gap-4"
            v-if="selectedFilters.length"
          >
            <Text textColor="#80829F" variant="p"
              >{{ selectedFilters.length }} Active Filter{{
                selectedFilters.length > 1 ? "s" : ""
              }}</Text
            >
            <Button
              class="!tw-w-auto"
              label="Reset Filters"
              @click="resetFilters"
            />
          </div>
        </div>
      </div>
      <div
        class="tw-w-full tw-flex tw-flex-col tw-gap-4"
        :class="{ 'tw-hidden': isMapShow }"
      >
        <v-tabs
          class="tab-list !tw-h-auto"
          v-model="selectedTab"
          align-tabs="center"
          stacked
        >
          <v-tab
            v-for="tabItem in tablist"
            :key="tabItem.name"
            :value="tabItem.name"
          >
            <div class="tw-flex tw-items-center tw-gap-2">
              <Text variant="p">{{ tabItem.label }}</Text>
              <div
                class="tw-px-[5px] tw-py-[3px] tw-box-border tw-bg-[rgba(12, 15, 74, 0.1)] tw-rounded-[3px] tw-flex tw-items-center tw-justify-center"
                :class="{ '!tw-bg-[#DCDDF9]': tabItem.name === selectedTab }"
              >
                <Text
                  variant="xsmall"
                  :textColor="
                    tabItem.name === selectedTab ? '#4F55F0' : '#0C0F4A'
                  "
                  >{{
                    tabItem.name === "sales"
                      ? filteredOnTheMarketSaleComparablesResult.length +
                        filteredSoldHistoryComparablesResult.length
                      : filteredOffTheMarketRentalsResult.length +
                        filteredOnTheMarketRentComparablesResult.length
                  }}</Text
                >
              </div>
            </div>
          </v-tab>
        </v-tabs>
        <v-window v-model="selectedTab" v-if="!loading">
          <v-window-item
            class="tw-h-full tw-overflow-y-auto"
            v-for="tabValue in tablist"
            :key="tabValue.name"
            :value="tabValue.name"
          >
            <div
              class="tw-w-full tw-h-full tw-flex tw-flex-col tw-gap-4"
              v-if="tabValue.name === 'sales'"
            >
              <div
                class="tw-w-full tw-h-full tw-bg-[#F1F6FB] tw-flex tw-p-4 tw-box-border tw-rounded-lg"
              >
                <div
                  class="title tw-w-full tw-h-full tw-flex tw-flex-col tw-rounded-t-lg tw-bg-white tw-rounded-lg tw-overflow-hidden"
                >
                  <div
                    class="tw-w-full tw-px-4 tw-py-5 tw-box-border tw-flex tw-items-center tw-justify-start md:tw-pb-4 md:tw-p-0"
                  >
                    <Text variant="p"
                      >Currently For Sale
                      <Text variant="xsmall" textColor="rgba(12, 15, 74, 0.5)"
                        >({{
                          filteredOnTheMarketSaleComparablesResult.length
                        }}
                        Results)</Text
                      ></Text
                    >
                  </div>
                  <CurrentForSaleTable
                    :data="filteredOnTheMarketSaleComparablesResult"
                    v-if="filteredOnTheMarketSaleComparablesResult.length"
                  />
                </div>
              </div>
              <div
                class="tw-w-full tw-h-full tw-bg-[#F1F6FB] tw-flex tw-p-4 tw-box-border tw-rounded-lg"
              >
                <div
                  class="title tw-w-full tw-h-full tw-flex tw-flex-col tw-rounded-t-lg tw-bg-white tw-rounded-lg tw-overflow-hidden"
                >
                  <div
                    class="tw-w-full tw-px-4 tw-py-5 tw-box-border tw-flex tw-items-center tw-justify-start md:tw-pb-4 md:tw-p-0"
                  >
                    <Text variant="p"
                      >Previously Sold
                      <Text variant="xsmall" textColor="rgba(12, 15, 74, 0.5)"
                        >({{
                          filteredSoldHistoryComparablesResult.length
                        }}
                        Results)</Text
                      ></Text
                    >
                  </div>
                  <PreviouslySoldTable
                    :data="filteredSoldHistoryComparablesResult"
                    v-if="filteredSoldHistoryComparablesResult.length"
                  />
                </div>
              </div>
            </div>

            <div
              class="tw-w-full tw-h-full tw-flex tw-flex-col tw-gap-4"
              v-if="tabValue.name === 'rentals'"
            >
              <div
                class="tw-w-full tw-h-full tw-bg-[#F1F6FB] tw-flex tw-p-4 tw-box-border tw-rounded-lg"
              >
                <div
                  class="title tw-w-full tw-h-full tw-flex tw-flex-col tw-rounded-t-lg tw-bg-white tw-rounded-lg tw-overflow-hidden"
                >
                  <div
                    class="tw-w-full tw-px-4 tw-py-5 tw-box-border tw-flex tw-items-center tw-justify-start md:tw-pb-4 md:tw-p-0"
                  >
                    <Text variant="p"
                      >Currently For Rent
                      <Text variant="xsmall" textColor="rgba(12, 15, 74, 0.5)"
                        >({{
                          filteredOnTheMarketRentComparablesResult.length
                        }}
                        Results)</Text
                      ></Text
                    >
                  </div>
                  <ForRentTable
                    :data="filteredOnTheMarketRentComparablesResult"
                    v-if="filteredOnTheMarketRentComparablesResult.length"
                  />
                </div>
              </div>
              <div
                class="tw-w-full tw-h-full tw-bg-[#F1F6FB] tw-flex tw-p-4 tw-box-border tw-rounded-lg"
              >
                <div
                  class="title tw-w-full tw-h-full tw-flex tw-flex-col tw-rounded-t-lg tw-bg-white tw-rounded-lg tw-overflow-hidden"
                >
                  <div
                    class="tw-w-full tw-px-4 tw-py-5 tw-box-border tw-flex tw-items-center tw-justify-start md:tw-pb-4 md:tw-p-0"
                  >
                    <Text variant="p"
                      >Previously Rented
                      <Text variant="xsmall" textColor="rgba(12, 15, 74, 0.5)"
                        >({{
                          filteredOffTheMarketRentalsResult.length
                        }}
                        Results)</Text
                      ></Text
                    >
                  </div>
                  <ForRentTable
                    :isPreviousRent="true"
                    :data="filteredOffTheMarketRentalsResult"
                    v-if="filteredOffTheMarketRentalsResult.length"
                  />
                </div>
              </div>
            </div>
          </v-window-item>
        </v-window>
        <div v-else>
          <CommonLoader :loading="true" />
        </div>
      </div>
    </section>
  </div>
</template>
<script setup>
import { QueryBuilder } from "@vepler/property-query-builder";
import { computed, reactive, ref, onMounted, watch } from "vue";
import { useStore } from "vuex";

import Text from "@/core/components/ui/general/Text.vue";
import CollapsibleWidgetSlot from "@/core/components/slots/CollapsibleWidgetSlot.vue";
import Button from "@/core/components/ui/general/Button.vue";
import InputField from "@/core/components/common/InputField.vue";
import InputSelect from "@/core/components/common/InputSelect.vue";
import LeafletMap from "@/core/components/map/LeafletMap.vue";
import CurrentForSaleTable from "@/modules/properties/components/Tables/CurrentForSaleTable.vue";
import PreviouslySoldTable from "@/modules/properties/components/Tables/PreviouslySoldTable.vue";
import ForRentTable from "../Tables/ForRentTable.vue";
import { PROPERTY_STORE } from "@/store/modules/property";
import PropertiesComposables from "@/composables/properties";
import { useQueryComparables } from "@/composables/properties/useQueryComparables";
import debounce from "lodash/debounce";
import CommonLoader from "@/core/components/CommonLoader.vue";
import PropertyCard from "@/modules/properties/components/Cards/PropertyCard.vue";

const {
  comparables,
  OnTheMarketSaleComparables,
  OnTheMarketRentComparables,
  OffMarketRentComparables,
  soldHistoryComparables,
  loading,
  error,
  queryComparables,
} = useQueryComparables();

const store = useStore();

const filters = reactive({
  keywords: "",
});

const isLoading = ref(false);

const filteredOnTheMarketSaleComparablesResult = ref([]);
const filteredOnTheMarketRentComparablesResult = ref([]);
const filteredSoldHistoryComparablesResult = ref([]);
const filteredOffTheMarketRentalsResult = ref([]);

const apiUrl = "https://api2.propbar.co.uk/property/query";
const apiKey = "9614620b-10dd-4e0e-9865-4edb7ffbd0cd";
const querySales = ref({});
const querySalesSold = ref({});

const propertySales = ref([]);

const queryRentals = ref({});
const propertyRentals = ref([]);

const offTheMarketRentals = ref([]);

const isMapShow = ref(false);

const selectedRadius = ref(250);
const radiusMenu = ref(false);
const radiusFilters = ref([
  {
    label: "250m",
    value: 250,
  },
  {
    label: "500m",
    value: 500,
  },
  {
    label: "1000m",
    value: 1000,
  },
  {
    label: "1500m",
    value: 1500,
  },
  {
    label: "2000m",
    value: 2000,
  },
  {
    label: "2500m",
    value: 2500,
  },
]);

const selectedExportFilters = ref([
  {
    label: "For Sale",
    value: "forSale",
  },
  {
    label: "Sold",
    value: "sold",
  },
]);
const exportMenu = ref(false);
const exportFilters = ref([
  {
    label: "For Sale",
    value: "forSale",
  },
  {
    label: "Sold",
    value: "sold",
  },
]);

const bedFilters = ref([]);
const joinedBedFilters = ref("");
const propertyTypeFilters = ref([]);
const previosSelectedRadius = ref(250);

const selectedFilters = ref([]);
const fitlerMenu = ref(false);
const filterSubMenus = ref([
  {
    name: "Property Type",
    subMenus: [
      {
        label: "Flat",
        value: "flat",
        comparator: "eq",
        field: "propertyType",
      },
      {
        label: "Mobile Home",
        value: "mobileHome",
        comparator: "eq",
        field: "propertyType",
      },
      {
        label: "Semi Detached",
        value: "semiDetached",
        comparator: "eq",
        field: "propertyType",
      },
      {
        label: "Detached",
        value: "detached",
        comparator: "eq",
        field: "propertyType",
      },
      {
        label: "Terraced",
        value: "terraced",
        comparator: "eq",
        field: "propertyType",
      },
      {
        label: "Bungalow",
        value: "bungalow",
        comparator: "eq",
        field: "propertyType",
      },
      {
        label: "Maisonette",
        value: "maisonette",
        comparator: "eq",
        field: "propertyType",
      },
      {
        label: "Chalet",
        value: "chalet",
        comparator: "eq",
        field: "propertyType",
      },
      {
        label: "Cottage",
        value: "cottage",
        comparator: "eq",
        field: "propertyType",
      },
      {
        label: "Lodge",
        value: "lodge",
        comparator: "eq",
        field: "propertyType",
      },
    ],
  },
  {
    name: "Bedrooms",
    subMenus: [
      {
        label: "1 Bed",
        value: "1",
        comparator: "eq",
        field: "beds",
      },
      {
        label: "2 Beds",
        value: "2",
        comparator: "eq",
        field: "beds",
      },
      {
        label: "3 Beds",
        value: "3",
        comparator: "eq",
        field: "beds",
      },
      {
        label: "4 Beds",
        value: "4",
        comparator: "eq",
        field: "beds",
      },
      {
        label: "5+ Beds",
        value: "5+",
        comparator: "gte",
        field: "beds",
      },
    ],
  },
]);

const selectedTab = ref("sales");
const tablist = ref([]);

const propertyDetails = computed(
  () => store.getters[`${PROPERTY_STORE}/propertyDetails`]
);

const childKey = ref(0);

const toggleMap = () => {
  isMapShow.value = !isMapShow.value;
  childKey.value += 1;
};

const filterPreviouslyRented = () => {
  const result = comparables.value.filter((comparable) =>
    comparable.listings.some(
      (listing) => listing.type === "rent" && listing.status !== "live"
    )
  );
  offTheMarketRentals.value = result;
};

const fetchComparables = async (propertyType, beds, radius) => {
  const { addressData } = propertyDetails.value?.localData;
  const epc = JSON.parse(addressData);
  const uprn = propertyDetails.value?.localData?.uprn;
  try {
    // const { addressData } = propertyDetails.value?.localData;
    // const epc = JSON.parse(addressData);
    isLoading.value = true;
    await queryComparables(
      propertyType,
      beds,
      radius,
      epc.LNG ? epc.LNG : epc.lng,
      epc.LAT ? epc.LAT : epc.lat,
      uprn
    );
    if (comparables.value.length) {
      filterPreviouslyRented();
    }
  } catch (error) {
    console.log(error);
  } finally {
    filteredOnTheMarketSaleComparablesResult.value =
      OnTheMarketSaleComparables.value;
    filteredOnTheMarketRentComparablesResult.value =
      OnTheMarketRentComparables.value;
    filteredSoldHistoryComparablesResult.value = soldHistoryComparables.value;
    filteredOffTheMarketRentalsResult.value = offTheMarketRentals.value;

    isLoading.value = false;
  }
};

const initialiaze = async (propertyType, beds, radius) => {
  try {
    loading.value = true;
    await fetchComparables(propertyType, beds, radius);
    tablist.value = [
      {
        name: "sales",
        label: "Sales",
        results:
          OnTheMarketSaleComparables.value.length +
          soldHistoryComparables.value.length,
      },
      {
        name: "rentals",
        label: "Rentals",
        results:
          offTheMarketRentals.value.length +
          OnTheMarketRentComparables.value.length,
      },
    ];
  } catch (error) {
    console.log(error);
  } finally {
    // console.log(
    //   OnTheMarketSaleComparables.value.result,
    //   "OnTheMarketSaleComparables"
    // );
    // console.log(
    //   OnTheMarketRentComparables.value.result,
    //   "OnTheMarketRentComparables"
    // );
    loading.value = false;
  }
};

const resetFilters = async () => {
  selectedFilters.value = [];
  bedFilters.value = [];
  joinedBedFilters.value = "";
  propertyTypeFilters.value = [];
  selectedRadius.value = 250;

  await initialiaze(
    propertyTypeFilters.value,
    joinedBedFilters.value,
    selectedRadius.value
  );
};

const filterComparables = async () => {
  if (filters.keywords !== "") {
    // Filter each array separately
    filteredOnTheMarketSaleComparablesResult.value =
      OnTheMarketSaleComparables.value.filter((item) =>
        item.address?.displayAddress
          .toLowerCase()
          .includes(filters.keywords.toLowerCase())
      );

    filteredSoldHistoryComparablesResult.value =
      soldHistoryComparables.value.filter((item) =>
        item.address?.displayAddress
          .toLowerCase()
          .includes(filters.keywords.toLowerCase())
      );

    filteredOnTheMarketRentComparablesResult.value =
      OnTheMarketRentComparables.value.filter((item) =>
        item.address?.displayAddress
          .toLowerCase()
          .includes(filters.keywords.toLowerCase())
      );

    filteredOffTheMarketRentalsResult.value = offTheMarketRentals.value.filter(
      (item) =>
        item.address?.displayAddress
          .toLowerCase()
          .includes(filters.keywords.toLowerCase())
    );
  } else {
    filteredOnTheMarketSaleComparablesResult.value =
      OnTheMarketSaleComparables.value;

    filteredSoldHistoryComparablesResult.value = soldHistoryComparables.value;

    filteredOnTheMarketRentComparablesResult.value =
      OnTheMarketRentComparables.value;

    filteredOffTheMarketRentalsResult.value = offTheMarketRentals.value;
  }
};

const debouncedFilterComparables = debounce(filterComparables, 300);

watch(
  radiusMenu,
  async () => {
    if (!radiusMenu.value) {
      if (previosSelectedRadius.value !== selectedRadius.value) {
        previosSelectedRadius.value = selectedRadius.value;
        await initialiaze(
          propertyTypeFilters.value,
          joinedBedFilters.value,
          selectedRadius.value
        );
      }
    }
  },
  { deep: true, immediate: true }
);

watch(
  fitlerMenu,
  async () => {
    if (
      !fitlerMenu.value &&
      selectedFilters.value &&
      selectedFilters.value.length
    ) {
      const bedsFilter = selectedFilters.value
        .filter((filter) => filter.field === "beds")
        .map((filter) => filter.value);

      const propertyTypeFilter = selectedFilters.value
        .filter((filter) => filter.field === "propertyType")
        .map((filter) => filter.value);

      // Check lengths of the filters
      if (
        bedsFilter.length !== bedFilters.value.length ||
        propertyTypeFilter.length !== propertyTypeFilters.value.length
      ) {
        bedFilters.value = bedsFilter; // Update previous length
        joinedBedFilters.value = bedFilters.value.join(",");
        propertyTypeFilters.value = propertyTypeFilter; // Update previous length

        await initialiaze(
          propertyTypeFilters.value,
          joinedBedFilters.value,
          selectedRadius.value
        );
      }
    } else if (!fitlerMenu.value && !selectedFilters.value.length) {
      await resetFilters();
    }
  },
  { deep: true, immediate: true }
);

// onMounted(async () => {
//   await initialiaze(
//     propertyTypeFilters.value,
//     joinedBedFilters.value,
//     selectedRadius.value
//   );
// });
</script>
<style lang="scss" scoped>
:deep(.v-input__details) {
  display: none;
}
:deep(.v-slide-group) {
  .v-slide-group__container {
    .v-slide-group__content {
      gap: 10px;
    }
  }
}
.title {
  @include respond(s1024) {
    background-color: rgba($cardBorder, 1);
  }
}
</style>
