<template>
  <GeneralDialog
    class="tw-max-w-[560px] tw-min-w-[300px] rmd:tw-min-w-[560px]"
    @on-close="onCloseModal"
  >
    <template #body>
      <div>
        <div
          class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4"
        >
          <div
            class="tw-flex tw-w-full tw-items-center tw-justify-center tw-bg-[#FAFAFB] tw-h-[233px]"
          >
            <img
              :src="require('@/assets/images/coming-soon-image.png')"
              alt="coming soon"
            />
          </div>
          <Text
            class="!tw-text-[30px]"
            textAlign="center"
            textWeight="400"
            lineHeight="28px"
          >
            Coming Soon...
          </Text>
          <Text class="tw-max-w-[80%] tw-mt-6" variant="h6" textAlign="center">
            Get a head start! Jobs, are live from June 27th
            <Text variant="p" textWeight="400" textAlign="center">
              - meaning you’ll be amongst the first, to estimate and win jobs.
              In the meantime, Perfect your Trade Passport, Enrol on a retrofit
              course, and Build your networks of elite contractors before the
              27th. To celebrate, we have also credited your account with a free
              month (check it out!)</Text
            >
          </Text>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="tw-w-full tw-grid tw-grid-cols-1 tw-gap-4 tw-items-center">
        <!-- <Button variant="tertiary" label="Tutorial" @click="onTutorial" /> -->
        <Button label="go to dashboard" @click="onRouteToDashboard" />
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

import GeneralDialog from "@/core/uni-components/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import { WORKSTATION } from "@/store/modules/workstation";

const store = useStore();

const emits = defineEmits(["on-close", "on-route-to-dashboard", "on-tutorial"]);

const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);

const isDefault = computed(() => {
  return activeUserWorkstation.value.name === "Default";
});

const onCloseModal = () => {
  emits("on-close");
};

const onRouteToDashboard = () => {
  emits("on-route-to-dashboard");
};
const onTutorial = () => {
  emits("on-tutorial");
};
</script>
