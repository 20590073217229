<template>
  <Card
    class="!tw-min-h-[unset] !tw-box-border !tw-bg-transparent !tw-shadow-none"
  >
    <template #content>
      <section class="tw-h-full tw-flex tw-flex-col tw-gap-4 !tw-box-border">
        <div
          class="tw-flex tw-justify-between tw-items-center tw-gap-4 md:tw-flex-col md:tw-items-start"
        >
          <InputField
            prepend-inner-icon="mdi-magnify"
            class="tw-max-w-[412px] md:tw- max-w-full md:tw-w-full"
            placeholder="Search"
            v-model="filters.keywords"
          />
          <div class="tw-flex tw-gap-4 tw-items-center">
            <ChipState
              class="tw-cursor-pointer"
              label="Filters"
              textColor="#FFA500"
              ><template #prefix><FilterCircleYellowIcon /></template
            ></ChipState>
            <v-menu>
              <template v-slot:activator="{ props }">
                <Button
                  v-bind="props"
                  variant="text"
                  :isCapitalize="true"
                  label="Sort by"
                  class="!tw-w-[auto]"
                  :ripple="false"
                >
                  <template #prefix>
                    <SortIcon />
                  </template>
                </Button>
              </template>
              <div>sort menu</div>
            </v-menu>
          </div>
        </div>
        <div
          class="tw-grid tw-grid-cols-3 tw-items-center tw-gap-4 md:tw-grid-cols-1"
        >
          <InputSelect
            prepend-inner-icon="mdi-shield-check"
            class="!tw-w-full !tw-max-w-[unset]"
            placeholder="All"
            :items="[
              'California',
              'Colorado',
              'Florida',
              'Georgia',
              'Texas',
              'Wyoming',
            ]"
          />
          <InputSelect
            prepend-inner-icon="mdi-shield-check"
            class="!tw-w-full !tw-max-w-[unset]"
            placeholder="All"
            :items="[
              'California',
              'Colorado',
              'Florida',
              'Georgia',
              'Texas',
              'Wyoming',
            ]"
          />
          <InputSelect
            prepend-inner-icon="mdi-account-hard-hat"
            class="!tw-w-full !tw-max-w-[unset]"
            placeholder="Commercial/Residential"
            :items="[
              'California',
              'Colorado',
              'Florida',
              'Georgia',
              'Texas',
              'Wyoming',
            ]"
          />
        </div>
      </section>
    </template>
  </Card>
</template>
<script setup>
import { reactive } from "vue";

import Button from "@/core/components/ui/general/Button.vue";
import Card from "@/core/components/ui/general/Card.vue";
import ChipState from "@/core/components/common/ChipState.vue";
import InputField from "@/core/components/common/InputField.vue";
import InputSelect from "@/core/components/common/InputSelect.vue";
import SortIcon from "@/core/components/icons/SortIcon.vue";
import FilterCircleYellowIcon from "@/core/components/icons/FilterCircleYellowIcon.vue";

const emit = defineEmits(["show-map"]);

const filters = reactive({
  keywords: "",
});
</script>
<style lang="scss" scoped></style>
