import { CourseState, CourseDetails } from "@/core/models/courses";

export default {
  setCourseDetails(state: CourseState, payload: CourseDetails) {
    state.courses = payload;
  },
  setSelectedCourseDetails(state: CourseState, payload: CourseDetails) {
    state.courseDetails = payload;
  },
  setEnrollCourseDetails(state: CourseState, payload: CourseDetails) {
    state.enrollCourses = payload;
  },
};
