<template>
  <section
    class="estimate-form__wrapper tw-bg-[rgba(12,15,74,0.1)] tw-w-full tw-h-[36px] tw-flex tw-items-center tw-justify-center tw-gap-[2rem] tw-py-2 tw-box-border tw-min-h-[36px]"
  >
    <MessageIcon class="cursor-pointer" />
    <div class="tw-flex tw-gap-2">
      <!--  -->
      <ClipIcon class="cursor-pointer" @click="toggleAttachmentModal" />

      <div v-if="error" class="error-message">{{ error }}</div>
    </div>
    <AddAvatarIcon class="cursor-pointer" />
    <v-divider vertical="true" v-if="!hideDeleteAction"></v-divider>
    <TrashIcon
      class="cursor-pointer"
      v-if="!hideDeleteAction"
      @click="onDeletePhaseForm"
    />
    <EstimatePhaseAttachments
      v-if="isShowAttachmentModal"
      :phaseIndex="phaseIndex"
      :estimatePhaseId="estimateForm?.projectStages[props?.phaseIndex]?.id"
      :projectEstimateId="formData?.id"
      @on-close="toggleAttachmentModal"
    />
  </section>
</template>
<script setup>
import { ref } from "vue";
import MessageIcon from "@/core/components/icons/MessageIcon.vue";
import AddAvatarIcon from "@/core/components/icons/AddAvatarIcon.vue";
import ClipIcon from "@/core/components/icons/ClipIcon.vue";
import TrashIcon from "@/core/components/icons/TrashIcon.vue";
import projectEstimationService from "@/core/services/project-estimation.service";
import store from "@/store";
import { computed, onMounted } from "vue";
import { JOB_TEMPLATE_STORE } from "@/store/modules/job-template";
import LoadButton from "@/core/components/common/LoadButton.vue";
import EstimatePhaseAttachments from "../modal/EstimatePhaseAttachments.vue";
const emits = defineEmits(["on-delete-phase", "on-update-Estimation"]);

const props = defineProps({
  phaseIndex: Number,
  hideDeleteAction: Boolean,
});
const fileInputRef = ref(null);
const attachmentFile = ref(null);
const loading = ref(false);
const error = ref(null);
const attachmentResponse = ref(null);
const estimateForm = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/estimateForm`]
);
const formData = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/formData`]
);
const onDeletePhaseForm = () => {
  emits("on-delete-phase");
};

const isShowAttachmentModal = ref(false);
const toggleAttachmentModal = () => {
  if (!formData.value.id) {
    emits("on-update-Estimation");
  }
  isShowAttachmentModal.value = !isShowAttachmentModal.value;
};
// onMounted(() => {
//   const phaseAttachments =
//     estimateForm.value.projectStages[props.phaseIndex]?.attachments;
//   if (phaseAttachments && phaseAttachments.length) {
//     attachmentFile.value = {
//       name: phaseAttachments[0]?.originalName,
//       url: phaseAttachments[0]?.attachment,
//     };
//     attachmentResponse.value = phaseAttachments[0];
//   }
// });
</script>
<script>
export default {
  name: "SandBoxActionFooter",
};
</script>
<style lang="scss" scoped></style>
