<template>
  <CommonDialog
    :isShowHeader="false"
    :isShowBackButton="false"
    :className="['edit_team_member_dialog']"
    width="570px"
    ><template v-slot:body>
      <div class="header">
        <h4>Edit member details</h4>
        <v-btn
          icon="mdi-close"
          @click="onClickCloseButton"
          variant="plain"
        ></v-btn>
      </div>
      <div class="modal__content">
        <div>
          <v-form ref="updateMemberFormRef">
            <div class="input_field">
              <label>Member Name</label>
              <v-text-field
                type="text"
                density="compact"
                v-model="members.name"
                :rules="validation.name"
                variant="outlined"
                class="c-input"
                placeholder="Member Name"
              ></v-text-field>
            </div>
            <div class="input_field">
              <label>Email Address Name</label>
              <v-text-field
                type="email"
                density="compact"
                v-model="members.email"
                :rules="validation.email"
                variant="outlined"
                class="c-input"
                placeholder="Email address"
                disabled
              ></v-text-field>
            </div>
            <div class="input_field">
              <label>Role</label>
              <v-select
                placeholder="Select"
                class="c-select"
                v-model="members.role"
                :rules="validation.role"
                :items="['Admin', 'Editor', 'Viewer']"
                variant="outlined"
              ></v-select>
            </div>
            <div class="input_field">
              <label>Tags</label>
              <v-select
                v-model="selectedTags"
                :items="Tags"
                placeholder="Select Tags"
                multiple
                variant="outlined"
                :menu-props="{ contentClass: 'tags_dropdown' }"
              >
                <template v-slot:prepend-item>
                  <v-list-item title="Select All" @click="toggle">
                    <template v-slot:prepend>
                      <v-checkbox-btn
                        :color="likesSomeTag ? 'indigo-darken-4' : undefined"
                        :indeterminate="likesSomeTag && !likesAllTags"
                        :model-value="likesAllTags"
                      ></v-checkbox-btn>
                    </template>
                  </v-list-item>

                  <v-divider class="mt-2"></v-divider>
                </template>

                <template v-slot:append-item>
                  <v-divider class="mb-2"></v-divider>

                  <v-list-item>
                    <v-text-field
                      v-model="customTag"
                      placeholder="Add custom tag"
                      variant="outlined"
                    ></v-text-field>
                    <v-btn class="button button-orange" @click="addCustomTag"
                      >Add</v-btn
                    >
                  </v-list-item>
                </template>
              </v-select>
            </div>
          </v-form>
        </div>
      </div>
      <div class="modal__footer">
        <v-btn
          class="button button-orange"
          :disabled="!isFormValid"
          @click="onClickUpdateDetails"
          >Save Details</v-btn
        >
      </div>
    </template>
  </CommonDialog>
</template>

<script lang="ts">
import CommonDialog from "@/core/components/CommonDialog.vue";
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { MEMBER_STORE } from "@/store/modules/manage-member";
import { USER_STORE } from "@/store/modules/user";
import { parseJSON } from "@/core/utils/common";

export default {
  components: {
    CommonDialog,
  },
  props: {
    memberData: {
      type: Object,
      required: false,
    },
  },
  setup(props: any, ctx: any) {
    const store = useStore();
    const user = computed(() => store.getters[`${USER_STORE}/user`]);

    const members = ref({
      name: "",
      email: "",
      role: "",
      tags: [] as any,
    });
    const Tags = ref(["Trade Contactor", "Manager", "Employee", "Assistant"]);
    const selectedTags = ref([]) as any;
    const customTag = ref("");
    const updateMemberFormRef = ref(null) as any;

    const validation = ref({
      name: [(v: any) => !!v || "Name is required"],
      email: [
        (v: any) => !!v || "Email is required",
        (v: any) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "Invalid email. Try again.",
      ],
      role: [(v: any) => !!v || "Role is required"],
    });

    const likesAllTags = computed(() => {
      return selectedTags.value.length === Tags.value.length;
    });

    const likesSomeTag = computed(() => {
      return selectedTags.value.length > 0;
    });

    const toggle = () => {
      if (likesAllTags.value) {
        selectedTags.value = [];
      } else {
        selectedTags.value = Tags.value.slice();
      }
    };

    const addCustomTag = () => {
      if (customTag.value && !selectedTags.value.includes(customTag.value)) {
        selectedTags.value.push(customTag.value);
        customTag.value = "";
      }
    };

    const onClickCloseButton = () => {
      ctx.emit("onClickCloseTab");
    };

    const onClickUpdateDetails = async () => {
      try {
        const membersWithTagsStringified = {
          userName: members.value.name,
          role: members.value.role,
          tags: JSON.stringify(selectedTags.value),
        };

        const response = await store.dispatch(
          `${MEMBER_STORE}/updateMemberDetails`,
          {
            members: membersWithTagsStringified,
            userId: user.value?.id,
            userWorkstationMemberId: props.memberData.id,
          }
        );
      } catch (error) {
        console.error("Error updating member details:", error);
      } finally {
        ctx.emit("onClickCloseTab");
      }
    };

    const isFormValid = computed(() => {
      const nameValid = validation.value.name[0](members.value.name) === true;
      const emailValid =
        validation.value.email[0](members.value.email) === true &&
        validation.value.email[1](members.value.email) === true;
      const roleValid = validation.value.role[0](members.value.role) === true;
      return nameValid && emailValid && roleValid;
    });

    onMounted(() => {
      if (props.memberData) {
        members.value.name = props.memberData.userName;
        members.value.email = props.memberData.email;
        members.value.role = props.memberData.role;
        if (props.memberData.tags) {
          const memberTags = parseJSON(props.memberData.tags);
          memberTags?.map((item: any) => {
            selectedTags.value.push(item);
          });
        }
      }
    });

    return {
      onClickCloseButton,
      updateMemberFormRef,
      members,
      validation,
      Tags,
      selectedTags,
      toggle,
      likesSomeTag,
      likesAllTags,
      customTag,
      addCustomTag,
      onClickUpdateDetails,
      isFormValid,
      user,
    };
  },
};
</script>
