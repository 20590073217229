<template>
  <div class="form-wrapper">
    <template v-if="!isShowSendEmail">
      <div class="text">
        <p>Enter an email so that we can send you password reset link</p>
      </div>
      <v-form @submit.prevent="onEmailSend()">
        <div class="form_input">
          <label for="email" class="">Enter Email</label>
          <v-text-field
            bg-color="rgba(12, 15, 74, 0.0509803922)"
            class="input_field"
            v-model="email"
            :rules="rules"
            validate-on="submit"
            placeholder="email address"
            rounded
            outlined="false"
            variant="solo"
          ></v-text-field>
        </div>
        <v-btn
          class="button button-orange send-mail"
          type="submit"
          block
          size="large"
          >Send Email</v-btn
        >
      </v-form>
    </template>
    <template v-else>
      <div class="forgot-text">
        <p>
          If the email address has an account, an email will be sent with a link
          to reset your password.
        </p>
        <div class="email__wrapper">
          <p>
            Email Sent To : <br /><span> {{ email }}</span>
          </p>
        </div>
        <p class="text">Didn’t receive email? Request a resend below.</p>
      </div>
      <v-btn
        class="button button-orange resend-mail"
        type="submit"
        size="large"
        block
        @click="onEmailSend"
        >Re-Send Email</v-btn
      >
    </template>

    <div class="btn_text">
      <label class="yellow-text" type="submit" @click="onClickLoginButton()">
        Back To Log In<v-icon icon="mdi-chevron-right"
      /></label>
    </div>
    <div class="benefit_wrapper llg:!tw-mb-[20px]">
      <div class="title">
        <h6><v-icon icon="mdi-star" />iknowa Benefits</h6>
      </div>
      <h5>Take a look at all the amazing benefits iknowa offers</h5>
      <label @click="openOverview" class="yellow-text"
        >Open iknowa Overview <v-icon icon="mdi-chevron-right"
      /></label>
    </div>
  </div>
  <AuthIknowaInfoMobile
    v-if="isShowAuthIknowaInfoMobile"
    @onCloseButtonClick="onCloseButtonClick"
  />
</template>

<script lang="ts">
import { useRouter } from "vue-router";
import { LOGIN_ROUTE } from "@/modules/login/routes";
import { ref, getCurrentInstance } from "vue";
import EmailValidator from "@/core/utils/email-validation";
import { computed } from "@vue/reactivity";
import { useStore } from "vuex";
import { AUTHENTICATION_STORE } from "@/store/modules/authentication";
import AuthIknowaInfoMobile from "@/core/components/AuthIknowaInfoMobile.vue";
export default {
  components: {
    AuthIknowaInfoMobile,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const email = ref("");
    const internalInstance = getCurrentInstance();

    const isShowAuthIknowaInfoMobile = ref(false);
    const isShowSendEmail = ref(false);

    const onClickLoginButton = () => {
      router.push({ name: LOGIN_ROUTE });
    };

    const isValidEmail = computed(() => EmailValidator.validate(email.value));

    const rules = ref([
      (v: string) => !!v || "Email is required",
      (v: string) =>
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || "Invalid email. Try again.",
    ]);

    const displayToast = (message: string, type: string) => {
      if (
        internalInstance &&
        internalInstance.appContext.config.globalProperties.$notify
      ) {
        internalInstance.appContext.config.globalProperties.$notify[type]({
          message,
        });
      }
    };

    const onEmailSend = async () => {
      try {
        if (!isValidEmail.value) return;
        isShowSendEmail.value = true;

        const resetPasswordResponse = await store.dispatch(
          `${AUTHENTICATION_STORE}/resetPassword`,
          { email: email.value }
        );
        displayToast("Resend Mail Successfully ", "success");
      } catch (error) {
        console.log("error: ", error);
      }
    };

    const onCloseButtonClick = () => {
      isShowAuthIknowaInfoMobile.value = false;
      document.body.classList.remove("noScrollModal");
    };

    const openOverview = () => {
      isShowAuthIknowaInfoMobile.value = true;
      document.body.classList.add("noScrollModal");
    };
    return {
      onClickLoginButton,
      email,
      rules,
      isShowSendEmail,
      onEmailSend,
      openOverview,
      isShowAuthIknowaInfoMobile,
      onCloseButtonClick,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/modules/forgot-password/styles/forgotPassword.scss";
</style>
