<template>
  <GeneralDialog
    class="invite_team_member_modal"
    headerTitle="Select team member to invite to project"
    @on-close="onCloseModal"
    v-if="isShowMainModal"
    ><template #body>
      <div class="search_field tw-relative">
        <input
          type="text"
          placeholder="Search..."
          class="tw-pl-10 tw-pr-4 tw-py-2 w-80 tw-border tw-bg-[#0c0f4a0a] tw-rounded-full tw-focus:outline-none tw-focus:ring-2 tw-focus:ring-blue-500 tw-w-full tw-max-w-full"
          v-model="searchMember"
        />
        <div
          class="tw-absolute tw-top-[35%] tw-left-0 tw-pl-3 tw-flex tw-items-center tw-pointer-events-none -tw-translate-y-2/4"
        >
          <img
            src="../../../assets/icons/search-icon.svg"
            alt=""
            class="tw-invert-[61%] tw-sepia-[6%] tw-saturate-[185%] tw-hue-rotate-[201deg] tw-brightness-[91%] tw-contrast-[90%]"
          />
        </div>
      </div>
      <div
        class="members_list tw-mt-6 tw-flex tw-gap-4 tw-flex-wrap tw-pb-6 tw-border-0 tw-border-b tw-border-solid tw-border-[#C5D2F2] sm:tw-flex-col"
      >
        <div
          class="members_select tw-py-6 tw-px-4 tw-rounded-lg tw-shadow-[0px_0px_8px_0px_#0C0F4A14] tw-flex tw-gap-4 tw-items-center tw-w-[calc(50%_-_8px)] tw-max-w-full sm:tw-w-full"
          v-for="(member, index) in filterMemberList"
          :key="index"
        >
          <div
            class="member_img tw-w-[56px] tw-max-w-full tw-h-[56px] tw-rounded-full"
          >
            <img
              src="../../../assets/images/profile-dashboard-image.png"
              class="tw-w-full tw-h-full tw-object-cover"
              alt=""
            />
          </div>
          <div class="member_info tw-w-[calc(100%_-_86px)]">
            <h5
              class="tw-text-xl tw-font-bold tw-text-[#0C0F4A] tw-leading-6 tw-mb-1 tw-text-ellipsis tw-max-w-full tw-overflow-hidden tw-whitespace-nowrap"
            >
              {{ member?.email }}
            </h5>
            <p
              class="tw-text-sm tw-font-bold tw-text-[#0C0F4A] tw-opacity-50 tw-leading-6"
            >
              {{ member?.role }}
            </p>
          </div>
          <div class="select_box tw-ml-auto tw-w-[30px]">
            <v-checkbox
              :value="member?.email"
              v-model="selectedMember"
            ></v-checkbox>
          </div>
        </div>
      </div>
    </template>
    <template #footer
      ><div
        class="member_footer tw-flex tw-justify-between tw-items-center sm:tw-flex-col"
      >
        <v-btn
          class="button button-purple-border tw-w-auto sm:!tw-w-full tw-max-w-full sm:tw-gap-2"
          @click="onClickAddMemberButton"
          >ADD NEW MEMber</v-btn
        >
        <v-btn
          class="button button-orange tw-w-auto sm:!tw-w-full tw-max-w-full sm:tw-mt-6"
          @click="onClickSendInviteButton"
          :disabled="!selectedMember.length"
          >SEND INVITE</v-btn
        >
      </div>
    </template></GeneralDialog
  >
  <TeamMemberModal
    v-if="isShowTeamMemberModal"
    @onClickCloseTab="onClickCloseTeamModel"
  />

  <GeneralDialog v-if="isShowInvitedModal" @on-close="onClickCloseButton">
    <template #body>
      <div class="banner_img">
        <img src="../../../assets/images/sent_invite.png" />
      </div>
      <h6
        class="tw-mt-4 tw-text-sm tw-font-bold tw-text-[#0C0F4A] tw-tracking-[0.25px]"
      >
        Your invitations have been sent
      </h6>
      <p
        class="tw-mt-2 tw-text-xs tw-font-bold tw-text-[#0c0f4a80] tw-tracking-[0.14px]"
      >
        We’ve sent your invitations to;
      </p>
      <p
        v-for="(member, i) in selectedMember"
        :key="i"
        class="tw-mt-4 tw-text-xs tw-font-bold tw-text-[#0c0f4a] tw-tracking-[0.14px]"
      >
        {{ member }}
      </p></template
    ><template #footer>
      <v-btn @click="onClickCloseButton" class="button button-purple-border"
        >CLOSE</v-btn
      ></template
    ></GeneralDialog
  >
</template>

<script lang="ts">
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import MemberService from "@/core/services/manage-member.service";
import project, { PROJECT, PROJECT_STORE } from "@/store/modules/project";
import { USER_STORE } from "@/store/modules/user";
import { computed } from "@preact/signals";
import { ref, onBeforeMount } from "vue";
import { useStore } from "vuex";
import TeamMemberModal from "@/modules/trade-passport/components/TeamMemberModal.vue";
import { watch } from "vue";
import projectService from "@/core/services/project.service";
export default {
  components: {
    GeneralDialog,
    TeamMemberModal,
  },
  props: {
    projectId: {
      type: Number,
      default: null,
    },
  },
  setup(props: any, ctx: any) {
    const store = useStore();

    const teamMemberList = ref([]) as any;

    const filterMemberList = ref([]) as any;

    const selectedMember = ref([]) as any;

    const isShowTeamMemberModal = ref(false);

    const isShowInvitedModal = ref(false);

    const isShowMainModal = ref(true);

    const user = computed(() => store.getters[`${USER_STORE}/user`]);

    const invitedMemberList = ref(
      store.getters[`${PROJECT_STORE}/projectDetails`]?.inviteMembers
    );

    const searchMember = ref("");

    const onCloseModal = () => {
      ctx.emit("onCloseModal");
    };

    const onClickAddMemberButton = () => {
      isShowTeamMemberModal.value = true;
      isShowMainModal.value = false;
      isShowInvitedModal.value = false;
    };

    const onClickSendInviteButton = async () => {
      const inviteMember = selectedMember.value.map((member: any) => {
        return { email: member };
      });
      store.dispatch(`${PROJECT}/projectDetails`, {
        inviteMembers: inviteMember,
      });
      if (props.projectId) {
        await projectService.updateProject(
          {
            inviteMembers: inviteMember,
          },
          props.projectId
        );
      }
      isShowInvitedModal.value = true;
      isShowTeamMemberModal.value = false;
      isShowMainModal.value = false;
    };

    const onClickCloseTeamModel = () => {
      isShowTeamMemberModal.value = false;
      isShowMainModal.value = true;
      isShowInvitedModal.value = false;
    };

    const onClickCloseButton = () => {
      ctx.emit("onCloseModal");
    };

    watch(
      () => searchMember.value,
      (newValue) => {
        if (newValue) {
          filterMemberList.value = teamMemberList.value.filter((item: any) => {
            return item.email.includes(searchMember.value);
          });
        } else {
          filterMemberList.value = teamMemberList.value;
        }
      },
      { deep: true }
    );

    onBeforeMount(async () => {
      teamMemberList.value = await MemberService.getTeamMember(user.value.id);
      // teamMemberList.value = teamMemberList.value.filter((member: any) => {
      //   return member.status === "accepted";
      // });
      filterMemberList.value = teamMemberList.value;
      if (invitedMemberList.value.length) {
        invitedMemberList.value.forEach((member: any) => {
          selectedMember.value.push(member.email);
        });
      }
    });
    return {
      onCloseModal,
      teamMemberList,
      selectedMember,
      // onClickAddMemberButton,
      onClickSendInviteButton,
      isShowTeamMemberModal,
      onClickCloseTeamModel,
      onClickAddMemberButton,
      invitedMemberList,
      isShowInvitedModal,
      isShowMainModal,
      onClickCloseButton,
      searchMember,
      filterMemberList,
    };
  },
};
</script>
<!-- title="Select team member to invite to project" -->
<style lang="scss" scoped></style>
