<template>
  <GeneralDialog
    class="tw-max-w-[600px] service-agreement-modal"
    :isShowCloseButton="false"
    @on-close="onCloseModal"
    :headerTitle="modalHeader.title"
    :headerDescription="dateNow"
    descriptionColor="#FAA100"
    :isTitleStrong="true"
  >
    <template #body>
      <div class="service-agreement-modal__content jc-c ai-c">
        <div>
          <div
            class="service-agreement-modal__scrollbar-content tw-overflow-auto tw-max-h-[600px]"
            ref="scrollbar"
            @scroll="handleScroll"
          >
            <div class="content">
              <div class="project-info">
                <p>
                  Project Name:<span>{{ information.projectName }}</span>
                </p>
                <p>
                  Project ID:
                  <span>{{ information.projectId }}</span>
                </p>
              </div>
              <div class="terms">
                <p>
                  This agreement (&#8220;<strong>Terms of Service</strong
                  >&#8221;) is entered into by and between
                  {{ information.tpCompanyName }} (&#8220;<strong
                    >The Contractor(s)</strong
                  >&#8221;) of {{ information.tpAddress }} and
                  {{ information.clientName }} (&#8220;<strong
                    >The Client</strong
                  >&#8221;) of {{ information.projectAddress }} (&#8220;<strong
                    >The Project Address</strong
                  >&#8221;).
                </p>
                <p>
                  The purpose of this agreement sets out the legal
                  responsibilities between the Contractor(s) and the Client in
                  relation to each term stipulated within this agreement.
                </p>
                <h6>The terms of this agreement:</h6>
                <ol class="list">
                  <li>
                    <h6>DESCRIPTION OF SERVICES.</h6>
                    <p>
                      {{ information.tpCompanyName }} is responsible for
                      delivering services (&#8220;<strong
                        >The Scope of Works</strong
                      >&#8221;) described and submitted in the accepted quote on
                      the iknowa platform for the project
                      {{ information.projectId }}. The contractor(s) will take
                      full responsibility to ensure VAT is inclusive on services
                      (where applicable) in all quotes before final submission
                    </p>
                  </li>
                  <li>
                    <h6>CLIENT DUTIES.</h6>
                    <p>
                      The client is responsible for checking and enquiring the
                      detail of services described in a submitted quote, before
                      accepting it. Once accepting a quote, throughout the
                      delivery of the project, the client bears duty to ensure
                      contractor(s) access to the site, to provide required
                      consents including any and all payment(s) against scope of
                      works are completed in a timely manner.
                    </p>
                  </li>
                  <li>
                    <h6>TERMS OF PAYMENT.</h6>
                    <p>
                      Upon signing, the client confirms, bearing the
                      responsibility to transfer all funds in accordance to
                      stages presented in the accepted quote. Prior to release
                      of the Client funds held in escrow, the contractor(s) are
                      responsible to deliver works as described in the submitted
                      quote up to the quality, in line with the building
                      standards and regulations prior to confirming a stage as
                      complete.
                    </p>
                    <ul class="stages-list">
                      <li v-for="(stage, i) in information.stages" :key="i">
                        {{ stage.name }} - ({{ stage.totalCost }})
                      </li>
                    </ul>
                    <p class="stage-sum">
                      Total: {{ information.totalCostSumOfStages }}
                    </p>
                  </li>
                  <li>
                    <h6>CHANGES TO THE PROJECT.</h6>

                    <p>
                      Changes to the project may happen in the form of; scope of
                      works, cost, duration, supplies/ equipment, labour,
                      accessibility and/ or any other unexpected events. Any
                      additional items not specified in the accepted quote
                      submitted on the platform, requires parties to submit a
                      change request, in order for changes of scope to be
                      considered. Submitted changes to any supplies/ equipment
                      and scope of works by the contractor(s), are subject to
                      approval by the client. All accepted variations under the
                      change request will be applied to the existing project
                      plan and schedule
                    </p>
                  </li>
                  <li>
                    <h6>NOTICE.</h6>
                    <p>
                      In the event of delays or any other unexpected
                      circumstance both parties are required to give sufficient
                      notice via the platforms’ messaging tool, in writing.
                    </p>
                  </li>
                  <li>
                    <h6>ASSIGNMENT.</h6>
                    <p>
                      Neither party may assign or transfer this agreement
                      without the prior written consent of the non-assigning
                      party, whose approval shall not be unreasonably withheld.
                      The client(s) shall not engage and/ or assign items to
                      other key parties outside this project, without adding
                      them to the project, via iknowa platform. The
                      contractor(s) shall not engage and/ or assign items to
                      other key parties, including other contractor(s) outside
                      iknowa, without assigning them to the project via iknowa
                      platform.
                    </p>
                  </li>
                  <li>
                    <h6>TIME FOR COMPLETION.</h6>
                    <p>
                      {{ information.tpCompanyName }} will complete the works
                      specified in the accepted quote within the indicated time
                      frame from the commencement of the project. A reasonable
                      time for delays may be considered, to deal with any delays
                      caused by the client or other external events
                    </p>
                  </li>
                  <li>
                    <h6>DELAYS.</h6>
                    <p>
                      If the project (scope of works) is delayed by strikes or
                      lockouts, the unavailability of necessary materials or
                      notices or by any breach of the agreement by the client or
                      by any other delay not the fault of the contractor(s), the
                      contractor(s) will be entitled to a fair and reasonable
                      extension of time. It’s the contractor(s) responsibility
                      to notify the client of any potential delays via the
                      platforms’ messaging tool
                    </p>
                  </li>
                  <li>
                    <h6>CANCELLATION / TERMINATION.</h6>
                    <p>
                      In the event the project is canceled by the client, with
                      no fault of the contractor(s), the contractor(s) will be
                      compensated for any work completed based on the breakdown
                      of the submitted and accepted quote. The client(s) is
                      entitled to cancel the project, following; unsatisfactory
                      quality of work, engaging outside contractor(s) not
                      verified on iknowa platform, not meeting the building
                      regulations & standards, and/ or misconduct by the
                      assigned contractor(s).
                    </p>
                  </li>
                  <li>
                    <h6>DEFAULTS AND REMEDIES.</h6>
                    <p>
                      In addition to any and all other rights a party may have
                      available according to law, if a party defaults by failing
                      to substantially perform any provision, term or condition
                      of this agreement (including without limitation the
                      failure to make a monetary payment when due), the other
                      party may terminate the agreement by providing written
                      notice to support@iknowa.com. This notice shall describe
                      with sufficient detail the nature of the default. The
                      party receiving such notice shall have 10 working days
                      from the effective date of such notice to cure the
                      default(s). Unless waived by a party providing notice, the
                      failure to cure the default(s) within such a time period
                      shall result in the automatic termination of this
                      agreement
                    </p>
                  </li>
                  <li>
                    <h6>WARRANTIES.</h6>
                    <p>
                      The contractor(s) is responsible for any defects appearing
                      as a fault of their work in a min 6 month period after
                      completion and must be rectified by the contractor(s)
                      within 10 business days of the defects and a limit on the
                      contractor’s overall liabilit
                    </p>
                  </li>
                  <li>
                    <h6>OTHER TERMS.</h6>
                    <p>
                      The quotation under this agreement is valid for 14
                      calendar days from the quotation submission dat
                    </p>
                  </li>
                  <li>
                    <h6>GOVERNING LAW.</h6>
                    <p>
                      This agreement shall be construed in accordance with the
                      laws and jurisdiction of England and Wales.
                    </p>
                  </li>
                </ol>
              </div>
              <div class="terms">
                <p>
                  The content of this agreement is strictly private &
                  confidential and intended for the recipient(s) specified in
                  the agreement only. It is strictly forbidden to copy or share
                  any part of this agreement with any third party, without the
                  written consent of the sender (support@iknowa.com)
                </p>
              </div>

              <div class="footer-note terms">
                <p>
                  NOTE: The items covered in this agreement are subject to the
                  standard iknowa Terms & Conditions and Privacy Policy accepted
                  during registration, which takes precedence over this
                  agreement if there are any inconsistencies.
                </p>
              </div>
              <div class="footer__part">
                <label>Digital Signature</label>
                <v-text-field
                  disabled="disable"
                  variant="outlined"
                  class="sign_wrapper"
                >
                  {{ information?.clientName }}
                </v-text-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="footer__buttons">
        <Button
          class="button button-purple-border"
          variant="tertiary"
          :disabled="loading"
          label="cancel"
          @click="onCloseModal"
        />
        <Button
          label="Accept"
          @click="onConfirm"
          class="button button-orange"
          :disabled="buttonEnabled"
        />
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup>
import { ref, computed } from "vue";
import moment from "moment";
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import CurrencyFilter from "@/filters/currency.filter";
import { JOB_TEMPLATE_STORE } from "@/store/modules/job-template";
import { useStore } from "vuex";
import { USER_STORE } from "@/store/modules/user";
const store = useStore();
const props = defineProps(["project", "projectEstimate", "loading", "type"]);
const emits = defineEmits(["on-confirm", "on-cancel", "loading", "on-close"]);

const modalHeader = {
  title: "Service Agreement",
};

const readAgreement = ref(false);

const dateNow = computed(() => moment(new Date()).format("Do MMM YYYY"));

const information = computed(() => {
  const initialProjectCharge = 5;
  const serviceFeePercentage = 0.03;
  const filter = CurrencyFilter;
  const { project, projectEstimate } = props;
  const {
    name: projectName,
    refId: projectId,
    projectCreator,
    postcode: projectAddress,
  } = project;
  const { projectEstimateCreator, projectStages, grantAmount } =
    projectEstimate;
  const { address: tpAddress, userBusinessDetails } = projectEstimateCreator;
  const { businessName, companyName } = userBusinessDetails;

  const stages = projectStages.map((projectStage) => {
    const { name, subTotal, vat, stageType } = projectStage;
    const stageCostWithFee =
      subTotal * vat + subTotal + subTotal * serviceFeePercentage;
    const rawTotalCost = stageCostWithFee;
    const totalCost = filter.formatToCurrency(rawTotalCost);
    const stageName =
      stageType === "labour" ? (name ? name : "Phase") : "Material";
    return {
      name: stageName,
      totalCost,
      rawTotalCost,
    };
  });
  const totalCostPerStage = stages.map((stage) => stage.rawTotalCost);
  let totalCostSumOfStages = totalCostPerStage.reduce((accumulator, a) => {
    return accumulator + a;
  }, 0);
  totalCostSumOfStages += initialProjectCharge;

  totalCostSumOfStages = grantAmount
    ? totalCostSumOfStages - grantAmount
    : totalCostSumOfStages;
  return {
    projectName,
    projectId,
    clientName:
      props.type === "property"
        ? `${projectCreator.firstName} ${projectCreator.lastName}`
        : `${projectEstimateCreator.firstName} ${projectEstimateCreator.lastName}`,
    projectAddress,
    tpCompanyName:
      companyName ||
      businessName ||
      `${projectEstimateCreator.firstName} ${projectEstimateCreator.lastName}`,
    tpAddress,
    stages,
    totalCostSumOfStages: filter.formatToCurrency(totalCostSumOfStages),
  };
});

const onCloseModal = () => {
  emits("on-close");
};
const formData = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/formData`]
);

const isSubContractor = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/getIsSubContractor`]
);
const onConfirm = async () => {
  emits("on-confirm");
};

const onCancel = () => {
  emits("on-cancel");
};
const buttonEnabled = ref(true);
const scrollbar = ref(null);
const handleScroll = (e) => {
  const wrap = e.target;
  const scrollThreshold = wrap.scrollHeight - 10;
  if (wrap.scrollTop + wrap.clientHeight >= scrollThreshold) {
    buttonEnabled.value = false;
  } else {
    buttonEnabled.value = true;
  }
};
</script>

<style lang="scss" scoped>
.service-agreement-modal__content {
  .content {
    .project-info {
      p {
        @include fluidFont(14, 14, 2);
        font-weight: 600;
        letter-spacing: 0.5px;
        color: rgba($blueDark, 0.5);
        span {
          color: rgba($blueDark, 1);
        }
      }
    }
    .terms {
      margin-top: 16px;
      @include fluidFont(14, 14, 2);
      font-weight: 600;
      letter-spacing: 0.5px;
      color: rgba($blueDark, 0.5);
      p {
        margin-top: 10px;
      }
      h6 {
        @include fluidFont(14, 14, 2);
        font-weight: 700;
        letter-spacing: 0.5px;
        color: rgba($blueDark, 1);
        margin-top: 30px;
        text-transform: capitalize;
      }
      .list {
        padding-left: 24px;
        li {
          &::marker {
            @include fluidFont(14, 14, 2);
            font-weight: 700;
            letter-spacing: 0.5px;
            color: rgba($blueDark, 1);
          }
        }
      }
    }
  }
}
.footer__part {
  margin-top: 86px;
  label {
    @include fluidFont(16, 16, 1.3);
    font-weight: 600;
    letter-spacing: 0.5px;
    color: rgba($blueDark, 1);
    display: inline-block;
    margin-bottom: 8px;
  }
}
.sign_wrapper {
  margin: 0;
  :deep(.v-input__control) {
    .v-field {
      opacity: 1;
      .v-field__field {
        .v-field__input {
          padding: 8px 16px;
          min-height: 42px;
          background-color: rgba($blueDark, 0.05);
          font-family: "Darlington Demo";
          @include fluidFont(24, 24, 1.2);
          font-weight: 400;
          color: rgba($blueDark, 1);
          border-radius: 100px;
        }
      }
      .v-field__outline {
        display: none;
      }
    }
  }
}
.footer__buttons {
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 85%;
  .button {
    width: calc(50% - 6px);
    max-width: 100%;
  }
}
</style>
