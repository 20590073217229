import $axios from "../utils/axios-api-config";
import {
  CreateProjectRatingPayload,
  UpdateProjectRatingPayload,
} from "@/core/models/project-rating";
export default {
  createProjectRating(payload: CreateProjectRatingPayload): Promise<any> {
    return $axios.post(`/project-rating`, payload);
  },
  getAllProjectRating(type: string): Promise<any> {
    return $axios.get(`/project-rating/${type}`);
  },
  getProjectRating(projectId: number): Promise<any> {
    return $axios.get(`/project-rating/project/${projectId}`);
  },
  updateProjectRating(
    ratingId: number,
    payload: UpdateProjectRatingPayload
  ): Promise<any> {
    return $axios.put(`/project-rating/${ratingId}`, payload);
  },
  deleteProjectRating(ratingId: number): Promise<any> {
    return $axios.get(`/project-rating/${ratingId}`);
  },
};
