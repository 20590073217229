<template>
  <GeneralDialog
    headerTitle="Add New Trade Services"
    @on-close="onClickCancelButton"
  >
    <template #body>
      <div class="add-new-trade-specialism-modal">
        <div class="add-new-trade-specialism-modal__label">
          <p>Service Category Name</p>
        </div>
        <v-text-field
          placeholder="Enter Category Name"
          class="c-input input_field"
          bg-color="#fff"
          variant="outlined"
          v-model="customCategoryName"
        >
        </v-text-field>
      </div>
    </template>

    <template #footer>
      <div class="add-new-trade-specialism-modal-footer">
        <v-btn class="button button-purple-border" @click="onClickCancelButton"
          >CANCEl</v-btn
        ><v-btn class="button button-orange" @click="onClickAddCustomCategory"
          >add</v-btn
        >
      </div>
    </template>
  </GeneralDialog>
</template>
<script lang="ts">
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import { ref, computed } from "vue";
import { useStore } from "vuex";
import UserService from "@/core/services/user.service";
import { WORKSTATION } from "@/store/modules/workstation";

export default {
  components: {
    GeneralDialog,
  },
  setup(props: any, ctx: any) {
    const store = useStore();

    const customCategoryName = ref("");

    const activeUserWorkstation = computed(
      () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
    );

    const onClickAddCustomCategory = async () => {
      try {
        await UserService.createCustomCategories(
          activeUserWorkstation.value?.id,
          { poLabel: customCategoryName.value }
        );
        ctx.emit("onClickCancelButton");
      } catch (error) {
        console.log("error :", error);
      }
    };

    const onClickCancelButton = () => {
      ctx.emit("onClickCancelButton");
    };
    return {
      customCategoryName,
      onClickAddCustomCategory,
      onClickCancelButton,
    };
  },
};
</script>

<style lang="scss" scoped>
.add-new-trade-specialism-modal {
  width: 522px;
  @include fluidFont(12, 12, 24px);
  letter-spacing: 0.15px;
  color: $blueDark;
  border-bottom: 1px solid #c5d2f2;
  border-top: 1px solid #c5d2f2;
  padding: 36px 0;

  @include respond(s720) {
    width: 100%;
  }

  &__label {
    margin-bottom: 8px;
  }

  :deep(.v-input) {
    margin: 0;
    max-width: 100%;
    .v-field {
      padding: 0 10px;
      border: 1px solid rgba($buttonText, 0.4);
      border-radius: 8px;
      .v-field__field {
        display: block;
        input {
          align-self: unset;
        }
        .v-field__input {
          padding: 0;
          background-color: transparent;
          min-height: 40px;
          padding-left: 8px;
          @include fluidFont(12, 12, 1);
          font-weight: 400;
          letter-spacing: 0.4px;
          max-width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          margin: 0;
          input {
            width: 100%;
            max-width: 100%;
            opacity: 1;
          }
        }
      }
    }
  }

  &-footer {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 16px;
    margin-top: 5px;
  }
}
</style>
