<template>
  <!-- <GeneralDialog
    @on-close="onCloseModal"
    class="tw-w-[448px]"
    :headerTitle="file?.originalName"
    width="620px"
  >
    <template #body>
      <div class="header-title">Your Account is Currently Frozen</div>
      <div class="welcome__image">
        <img src="../../../../assets/images/restricted-area-2.png" alt="" />
      </div>
      <div class="header-sub-title">
        It appears that your workstation has been verified but your user
        insurance has expired. Please contact support or renew your insurance to
        regain access.
      </div>
    </template>
    <template #footer>
      <div class="tw-w-full tw-grid tw-grid-cols-2 tw-items-center tw-gap-3">
        <Button variant="secondary" label="Cancel" @click="onCloseModal" />
      </div>
    </template>
  </GeneralDialog> -->
  <CommonDialog
    :isShowHeader="false"
    :isShowBackButton="false"
    width="465px"
    max-width="100%"
    height="auto"
    :className="['restricted__modal_box']"
  >
    <template v-slot:body>
      <div
        class="dashboard-welcome__close !tw-top-8 !tw-right-8"
        @click="onCloseModal"
      >
        <v-icon icon="mdi-close"></v-icon>
      </div>
      <div class="header-title">
        Hey, <b>{{ user?.firstName }}.</b> your workstation has been frozen.
      </div>

      <div class="header-sub-title">
        Unfortunately your insurance cover has expired. associated with your
        Please update this, as soon as possible so we may un-freeze your
        account.
      </div>
      <div class="welcome__image">
        <img src="../../../../assets//images/restricted-area-3.png" alt="" />
      </div>
    </template>
  </CommonDialog>
</template>

<script setup>
import { computed } from "vue";
import CommonDialog from "@/core/components/CommonDialog.vue";
import { useStore } from "vuex";
import { USER_STORE } from "../../../../store/modules/user/index";

const store = useStore();

const emits = defineEmits(["on-close"]);

const user = computed(() => store.getters[`${USER_STORE}/user`]);
const onCloseModal = () => {
  emits("on-close");
};
</script>

<style scoped>
/* Optional: Additional custom styling */
</style>
