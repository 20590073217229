<template>
  <CommonDialog
    HeaderTittle="Is an iknowa course 100% for you?"
    title="You're one small step away from unleashing your green building superpowers!"
    :className="['enrollment__wrapper']"
    v-if="isShowPaymentDialog"
    :onClickBackButton="onClickBackButton"
  >
    <template v-slot:body>
      <div class="modal_card_content tw-mt-4 tw-flex tw-flex-col tw-gap-4">
        <div class="login_wrapper_points tw-flex tw-flex-col tw-gap-2">
          <div class="form-container-list !tw-flex !tw-items-center !tw-gap-2">
            <InfoBlueIcon />
            <Text variant="h6" textWeight="600">Attendance</Text>
          </div>
          <Text
            variant="h6"
            textWeight="500"
            textColor="rgba(12, 15, 74, 0.5)"
            alineHeight="20px"
            >Committing to all 10 course days.</Text
          >
          <v-checkbox v-model="modelOption1">
            <template v-slot:label>
              <Text variant="p" textWeight="600"
                >I accept & agree to the above</Text
              >
            </template>
          </v-checkbox>
        </div>
        <div class="login_wrapper_points tw-flex tw-flex-col tw-gap-2">
          <div class="form-container-list !tw-flex !tw-items-center !tw-gap-2">
            <InfoBlueIcon />
            <Text variant="h6" textWeight="600">Passing the Exam</Text>
          </div>
          <Text
            variant="h6"
            textWeight="500"
            textColor="rgba(12, 15, 74, 0.5)"
            alineHeight="20px"
            >Just like any hero needs to show up for training, so do you!
            Attending all ten course sessions is key to unlocking your full
            potential!</Text
          >
          <v-checkbox v-model="modelOption2">
            <template v-slot:label>
              <Text variant="p" textWeight="600"
                >I accept & agree to the above</Text
              >
            </template>
          </v-checkbox>
        </div>
        <div class="login_wrapper_points tw-flex tw-flex-col tw-gap-2">
          <div class="form-container-list !tw-flex !tw-items-center !tw-gap-2">
            <InfoBlueIcon />
            <Text variant="h6" textWeight="600">Level up</Text>
          </div>
          <Text
            variant="h6"
            textWeight="500"
            textColor="rgba(12, 15, 74, 0.5)"
            alineHeight="20px"
            >Take your learning to the next level by completing three projects
            on iknowa. It's a win-win – you gain experience and get to showcase
            your skills to clients!</Text
          >
          <v-checkbox v-model="modelOption3">
            <template v-slot:label>
              <Text variant="p" textWeight="600"
                >I accept & agree to the above</Text
              >
            </template>
          </v-checkbox>
        </div>
        <div
          class="card__buttons tw-flex tw-flex-col tw-gap-2 tw-items-center tw-justify-center tw-w-1/2 md:tw-w-full"
        >
          <v-btn
            class="button button-orange"
            width="auto"
            size="large"
            block
            :loading="loading"
            :disabled="!modelOption1 || !modelOption2 || !modelOption3"
            @click="onClickRedirectToEnrollModal"
          >
            accept & enroll
          </v-btn>
          <!-- @click="initializeStripePayment(false, true)" -->

          <label class="yellow-text"
            >Read Full Terms & Conditions
            <v-icon icon="mdi-chevron-right"></v-icon
          ></label>
        </div>
      </div>
    </template>
  </CommonDialog>
  <div class="calendar__wrapper tab__area__wrapper" v-if="isShowCalenderView">
    <label class="blue-text" @click="onClickBackCoursesButton"
      ><v-icon icon="mdi-chevron-left"></v-icon>Back to Courses
    </label>
    <div class="tab__area__blocks">
      <div class="tab__area__left__block">
        <h4 class="tab__area__title">Select your preferred course date</h4>
        <Calendar
          ref="calendarRef"
          title-position="left"
          class="custom__calendar"
          :masks="masks"
          color="sky-blue"
          trim-weeks
          transparent
          :attributes="calendarAttributes"
          v-model="selectedDate"
          expanded
          :min-date="currentMonthStartDate"
        >
          <template v-slot:day-content="{ day, attributes }">
            <div class="custom__calender__date">
              <span class="day-label" :class="{ 'today-date': day.isToday }">{{
                day.day
              }}</span>
              <div class="">
                <ul>
                  <li>
                    <div class="event__list">
                      <div
                        v-for="attr in attributes"
                        :key="attr.id"
                        class="custom__calender__event__day tw-flex tw-flex-col tw-justify-center tw-gap-3"
                        :class="{ 'is-active': courseScheduleId === attr.id }"
                        @click="onClickSelectCourse(attr)"
                      >
                        <div class="tw-flex tw-items-center tw-gap-1">
                          <v-icon
                            v-if="attr.course?.name"
                            icon="mdi-record"
                          ></v-icon>
                          {{ attr?.course?.name }}
                        </div>
                        <Text
                          class="tw-text-[8px]"
                          textColor="rgba(12, 15, 74, 0.5)"
                          >spot(s) available:
                          {{
                            attr?.spotsRemaining ? attr?.spotsRemaining : "Full"
                          }}</Text
                        >
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </template>
        </Calendar>
      </div>

      <div class="tab__area__right__block !tw-p-3 tw-box-border">
        <v-card class="tab__area__right__block__card !tw-p-3 tw-box-border">
          <div class="content tw-flex tw-flex-col tw-gap-4">
            <div>
              <h4 class="title">View your course selection</h4>
              <p class="description">
                Here's where your upskilling journey begins!
              </p>
            </div>
            <div v-if="!isShowAddressList" class="location__name__wrapper">
              <div class="heading">
                <h4 class="title">Locations</h4>
                <h6 @click="changeAddress" class="yellow-text">
                  Change Filter
                </h6>
              </div>
              <template v-if="selectedAddress">
                <div class="location__wrapper">
                  <!-- active class below -->
                  <div
                    class="location__city tw-cursor-pointer"
                    v-for="(item, index) in selectedAddress"
                    :key="index"
                    @click="onClickSelectCourse(item)"
                    :class="{ active: courseScheduleId == item?.id }"
                  >
                    <div class="tab__area__list__icon">
                      <img
                        class="open-logo tw-min-w-[18px] tw-max-w-[18px] tw-min-h-[20px] tw-max-h-[20px]"
                        src="../../../assets/icons/yellow-location-icon.svg"
                      />
                    </div>
                    <p>
                      {{ item.address }}
                    </p>
                  </div>
                </div>
              </template>
              <div v-if="!selectedAddress.length" class="location__city">
                <div class="tab__area__list__icon">
                  <img
                    class="open-logo"
                    src="../../../assets/icons/yellow-location-icon.svg"
                  />
                </div>
                <p>No selected address filter</p>
              </div>
            </div>
            <div
              v-if="isShowAddressList"
              class="location__name__wrapper tw-flex tw-flex-col tw-gap-4"
            >
              <div class="heading">
                <h4 class="title">Locations</h4>
                <h6 @click="saveAddressChanges" class="yellow-text">Filter</h6>
              </div>
              <div class="tw-flex tw-flex-col tw-gap-2">
                <!-- <v-text-field
                  density="compact"
                  variant="outlined"
                  class="c-input rounded-full !tw-mt-0 !tw-mb-0"
                  v-model="selectedAddress"
                  disabled
                ></v-text-field> -->
                <div class="postcode__wrapper !tw-h-[300px]">
                  <ul class="address__postcodes !tw-gap-0">
                    <li
                      v-for="(item, index) in addressListFromCourse"
                      :key="index"
                    >
                      <v-checkbox v-model="selectedAddress" :value="item">
                        <template v-slot:label>
                          <Text
                            variant="xsmall"
                            textWeight="600"
                            lineHeight="20px"
                            >{{ item.address }}</Text
                          >
                        </template>
                      </v-checkbox>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="tab__area__card" v-if="enrollCourseName">
            <h6 class="title">
              {{ enrollCourseName ? enrollCourseName : courseDetails?.name }}
            </h6>
            <span class="yellow-text"
              >Starting date:
              {{
                enrollCourseStartDate
                  ? formattedStartDate(enrollCourseStartDate)
                  : formattedStartDate(
                      courseDetails?.courseSchedules[0]?.startDate
                    )
              }}</span
            >
            <div class="tw-flex tw-flex-col tw-items-start">
              <Text variant="span" textWeight="600"
                >Spots available:
                <Text variant="span" textColor="#FAA500">{{
                  spotsRemaining ? spotsRemaining : "Full"
                }}</Text></Text
              >
              <Text variant="span" textWeight="600"
                >Location:
                {{
                  enrollCourseAddress
                    ? enrollCourseAddress
                    : courseDetails?.courseSchedules[0]?.address
                }}</Text
              >
            </div>
          </div>

          <v-checkbox v-model="option1" class="tab__area__checkbox">
            <template v-slot:label>
              <p>Add me to the live queue.</p>
              <v-icon icon="mdi-information"></v-icon>
            </template>
          </v-checkbox>

          <v-checkbox
            v-model="option2"
            class="tab__area__checkbox remove-padding"
          >
            <template v-slot:label>
              <p>
                I have read and agree with the
                <span class="yellow-text remove-padding"
                  >Terms of Service & Privacy Policy</span
                >
              </p>
            </template>
          </v-checkbox>
          <v-btn
            class="button button-orange w-full"
            @click="onClickOpenPaymentDialog"
            :disabled="isEnrollDisable"
            :loading="enrollLoading"
            >confirm my enrollment <v-icon icon="mdi-chevron-right"
          /></v-btn>
        </v-card>
      </div>
    </div>
  </div>
  <CoursesListView v-if="isShowEnrollmentDialog" />
</template>

<script lang="ts">
import { VUE_APP_STRIPE_PUBLISHABLE_KEY } from "@/config";
import { Calendar } from "v-calendar";
import {
  computed,
  defineComponent,
  onBeforeMount,
  ref,
  getCurrentInstance,
} from "vue";
import "v-calendar/style.css";
import { useStore } from "vuex";
import $axios from "@/core/utils/axios-api-config";
import { USER_STORE } from "@/store/modules/user";
import { cloneObject } from "@/core/utils/common";
import CommonDialog from "@/core/components/CommonDialog.vue";
import { COURSES_STORE } from "@/store/modules/courses";
import { useRouter } from "vue-router";
import InfoBlueIcon from "@/core/components/icons/InfoBlueIcon.vue";
import Text from "@/core/components/ui/general/Text.vue";
import CoursesListView from "@/modules/courses/components/CoursesListView.vue";
import moment from "moment";

export default defineComponent({
  name: "CalenderView",

  components: {
    Calendar,
    CommonDialog,
    InfoBlueIcon,
    Text,
    CoursesListView,
  },
  setup() {
    const internalInstance = getCurrentInstance();
    const date = new Date();
    const calendarRef = ref(null) as any;
    const store = useStore();
    const router = useRouter();
    const month = new Date().getMonth();
    const selectedDate = ref(moment().format("YYYY-MM-DD"));
    const year = new Date().getFullYear();
    const courseSchedules = ref([]);
    const isShowPaymentDialog = ref(false);
    const enrollLoading = ref(false);
    const isShowCalenderView = ref(true);
    const isShowEnrollmentDialog = ref(
      localStorage.getItem("isShowEnrollmentDialog") === "true"
    );
    const courseScheduleId = ref();
    const enrollCourseStartDate = ref("");
    const enrollCourseName = ref();
    const loading = ref(false);
    const masks = {
      weekdays: "WWW",
    };
    const option1 = ref(false);
    const option2 = ref(false);
    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const isShowAddressList = ref(false);
    const addressListFromCourse = ref([]) as any;
    const selectedAddressIndex = ref(null);

    const selectedAddress = ref([]) as any;

    const onClickBackCoursesButton = () => {
      router.push("/courses");
    };
    const modelOption1 = ref(false);
    const modelOption2 = ref(false);
    const modelOption3 = ref(false);
    const stripe = ref(null);
    const sessionId = ref(null);
    const redirectUrl = ref("");
    const customer = ref("") as any;
    const enrollCourseAddress = ref("");
    const enrollCourseSpotLimit = ref("");
    const spotsRemaining = ref(null) as any;
    const calendarAttributes = ref([]) as any;

    const courseDetails = computed(
      () => store.getters[`${COURSES_STORE}/courseDetails`]
    );
    const getCourseSchedule = async () => {
      try {
        let response = await store.dispatch(
          `${COURSES_STORE}/getCourseSchedule`,
          {
            name: courseDetails.value?.name,
          }
        );
        const today = moment().startOf("day");
        const filteredData = response.data.filter((schedule: any) => {
          return moment(schedule.startDate).isSameOrAfter(today);
        });
        courseSchedules.value = filteredData;
        // courseSchedules.value = response.data;
        addressListFromCourse.value = courseSchedules.value

          .filter((item: any) => item.address !== null)
          .map((item: any) => {
            return {
              address: item.address,
              id: item.id,
            };
          });
        selectedAddress.value = addressListFromCourse.value;
        if (courseSchedules.value) {
          calendarAttributes.value = await getCalenderAttribute();
        }
      } catch (error) {
        console.log("error:", error);
      }
    };

    const fetchLearnersCount = async (scheduleId: any) => {
      try {
        const response = await store.dispatch(
          `${COURSES_STORE}/getCourseScheduleById`,
          {
            courseScheduleId: scheduleId,
          }
        );
        const { course } = response;

        const { userCourses } = course;

        if (userCourses.length) {
          let users = [];
          userCourses.forEach((userCourse: any) => {
            const { userCourseSchedules, user } = userCourse;

            if (userCourseSchedules.length) {
              const item = userCourseSchedules.find(
                (userCourseSchedule: any) => {
                  return userCourseSchedule?.courseSchedule?.id === scheduleId;
                }
              );
              if (item) {
                users.push(user);
              }
            }
          });
          return users.length;
        }

        return 0;
      } catch (error) {
        console.error(
          `Failed to fetch learners count for course ${scheduleId}:`,
          error
        );
        return 0; // Default to 0 if there's an error
      }
    };

    const getSpotRemaining = async (scheduleId: any, spotLimit: any) => {
      const enrolledLearnersCount = await fetchLearnersCount(scheduleId);
      let spots: any;
      if (enrolledLearnersCount >= spotLimit) {
        spots = 0;
      } else {
        spots = spotLimit - enrolledLearnersCount;
      }
      return spots;
    };

    const getCalenderAttribute = async () => {
      if (selectedAddress.value.length) {
        const selectedIds = selectedAddress.value.map((item: any) => item.id);

        const filteredCourseSchedulesByAddress = courseSchedules.value.filter(
          (schedule: any) => selectedIds.includes(schedule.id)
        );

        const results = await Promise.all(
          filteredCourseSchedulesByAddress.map(async (course: any) => ({
            ...course,
            spotsRemaining: await getSpotRemaining(course.id, course.spotLimit),
            dates: new Date(course?.startDate),
          }))
        );

        return results;
      }

      const results = await Promise.all(
        courseSchedules.value.map(async (course: any) => ({
          ...course,
          spotsRemaining: await getSpotRemaining(course.id, course.spotLimit),
          dates: new Date(course?.startDate),
        }))
      );

      return results;
    };

    const onClickOpenPaymentDialog = async () => {
      const response = await store.dispatch(
        `${COURSES_STORE}/checkCourseAlreadyEnrolled`,
        {
          userId: user.value?.id,
          scheduleId: courseScheduleId.value,
        }
      );
      if (!response?.isAlreadyEnrolled) {
        isShowPaymentDialog.value = !isShowPaymentDialog.value;
      } else {
        displayToast("You are already enrolled.", "error");
      }
    };

    const createCourses = async () => {
      enrollLoading.value = true;
      const createUserCourse = await store.dispatch(
        `${COURSES_STORE}/createUserCourse`,
        {
          userId: user.value?.id,
          courseId: courseDetails.value?.id,
          forQueue: option1.value,
          terms: option2.value,
        }
      );
      await store.dispatch(`${COURSES_STORE}/updateUserCourse`, {
        userId: user.value?.id,
        courseId: createUserCourse?.id,
        forQueue: option1.value,
        terms: option2.value,
        enrollmentStatus: "1",
      });
      if (createUserCourse) {
        const createUserCourseSchedules = await store.dispatch(
          `${COURSES_STORE}/createUserCourseSchedules`,
          {
            courseScheduleId: courseScheduleId.value,
            courseUserId: createUserCourse?.id,
          }
        );
        if (createUserCourseSchedules) {
          enrollLoading.value = false;
        }
      }
    };

    const onClickRedirectToEnrollModal = () => {
      isShowCalenderView.value = false;
      isShowPaymentDialog.value = false;
      isShowEnrollmentDialog.value = true;
      localStorage.setItem("isShowEnrollmentDialog", "true");
      createCourses();
    };

    const initializeStripe = () => {
      let stripe = null;

      if ("Stripe" in window) {
        const { Stripe } = window as any;

        // eslint-disable-next-line no-new
        stripe = new Stripe(VUE_APP_STRIPE_PUBLISHABLE_KEY);
      }

      return stripe;
    };

    const initializeStripePayment = async (
      isTrial: boolean,
      oneTimePayment: boolean
    ) => {
      loading.value = true;
      stripe.value = initializeStripe();
      if (stripe.value) {
        await onCheckout(isTrial, oneTimePayment);
      }
    };

    const onCheckout = async (isTrial: boolean, oneTimePayment: boolean) => {
      await createSession(isTrial, oneTimePayment);

      if (sessionId.value) {
        (stripe.value as any)
          .redirectToCheckout({
            sessionId: sessionId.value,
          })
          .then((result: any) => {
            if (result.error) {
              console.log("Error: ", result.error);
            }
          });
      }
    };

    const createSession = async (isTrial = false, oneTimePayment = false) => {
      const user = cloneObject(store.getters[`${USER_STORE}/user`]);
      const { id: userId, userStripeCustomer, email } = user;

      const productName = enrollCourseName.value
        ? enrollCourseName.value
        : courseDetails.value?.name;

      const entity = JSON.stringify({
        id: userId,
        name: enrollCourseName.value,
      });

      if (!userStripeCustomer?.length) {
        await $axios
          .post(`/payment/${userId}/customer`, {
            email,
            entity,
          })
          .then(async (customerResponse) => {
            await store.dispatch(`${USER_STORE}/saveUserStripePayment`, [
              customerResponse,
            ]);
            isShowEnrollmentDialog.value = true;
            customer.value = customerResponse;
            loading.value = false;
          })
          .catch((e) => {
            console.log("Error: ", e.message);
          });
      } else {
        const [stripeCustomer] = userStripeCustomer;
        customer.value = stripeCustomer;
      }

      const host = `${window.location.protocol}//${window.location.host}`;
      isShowEnrollmentDialog.value = true;
      redirectUrl.value = `${host}/courses`;

      const { customerId } = customer.value;
      const totalPrice = 9.99;

      if (customerId) {
        await $axios
          .post(`/payment/${userId}/session`, {
            customerId,
            price: totalPrice,
            redirectUrl: redirectUrl.value,
            productName,
            metadata: JSON.stringify({
              productName,
              userId,
            }),
            isTrial,
            oneTimePayment,
          })
          .then(async (sessionResponse: any) => {
            if (sessionResponse) {
              sessionId.value = sessionResponse.id;
              localStorage.setItem("isShowEnrollmentDialog", "true");
              createCourses();
            }
          });
      }
    };

    // Compute the current month start date
    const currentMonthStartDate = computed(() => {
      const date = new Date();
      date.setDate(1);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const startDate = `${year}-${month}-${day}`;
      return startDate;
    });

    const formattedStartDate = (value: any) => {
      const startDate = new Date(value);
      const day = startDate.getDate();
      const month = startDate.toLocaleString("default", { month: "short" });
      const year = startDate.getFullYear();
      return `${day}${getOrdinalSuffix(day)} ${month} ${year}`;
    };

    function getOrdinalSuffix(day: number) {
      if (day >= 11 && day <= 13) {
        return "th";
      }
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    }

    const isEnrollDisable = computed(() => {
      return !(
        option2.value &&
        spotsRemaining.value &&
        (courseScheduleId.value || enrollCourseName.value)
      );
    });

    const onClickBackButton = () => {
      isShowPaymentDialog.value = false;
    };

    const displayToast = (message: string, type: string) => {
      if (
        internalInstance &&
        internalInstance.appContext.config.globalProperties.$notify
      ) {
        internalInstance.appContext.config.globalProperties.$notify[type]({
          message,
        });
      }
    };

    const onClickSelectCourse = async (attr: any) => {
      if (attr?.spotsRemaining === 0) {
        displayToast("Available spots are full.", "success");
      }
      const calenderAtt = calendarAttributes.value.find(
        (item: any) => item.id === attr.id
      );
      let newDate = moment(calenderAtt?.startDate).format("YYYY-MM-DD");
      await calendarRef.value.move(newDate);
      spotsRemaining.value = await getSpotRemaining(
        calenderAtt?.id,
        calenderAtt?.spotLimit
      );
      courseScheduleId.value = calenderAtt?.id;
      enrollCourseStartDate.value = calenderAtt?.startDate;
      enrollCourseName.value = calenderAtt?.course?.name;
      enrollCourseAddress.value = calenderAtt?.address;
      enrollCourseSpotLimit.value = calenderAtt?.spotLimit;
    };

    const changeAddress = () => {
      isShowAddressList.value = true;
      addressListFromCourse.value = courseSchedules.value

        .filter((item: any) => item.address !== null)
        .map((item: any) => {
          return {
            address: item.address,
            id: item.id,
          };
        });
    };

    const saveAddressChanges = async () => {
      calendarAttributes.value = await getCalenderAttribute();
      isShowAddressList.value = false;
    };

    onBeforeMount(async () => {
      getCourseSchedule();
    });

    return {
      date,
      masks,
      getCalenderAttribute,
      onClickOpenPaymentDialog,
      isShowPaymentDialog,
      modelOption1,
      modelOption2,
      modelOption3,
      onClickBackCoursesButton,
      option1,
      option2,
      initializeStripePayment,
      isShowEnrollmentDialog,
      VUE_APP_STRIPE_PUBLISHABLE_KEY,
      loading,
      courseDetails,
      formattedStartDate,
      user,
      isEnrollDisable,
      onClickBackButton,
      enrollLoading,
      onClickSelectCourse,
      enrollCourseStartDate,
      enrollCourseName,
      courseScheduleId,
      enrollCourseAddress,
      changeAddress,
      isShowAddressList,
      saveAddressChanges,
      addressListFromCourse,
      selectedAddressIndex,
      enrollCourseSpotLimit,
      selectedAddress,
      onClickRedirectToEnrollModal,
      isShowCalenderView,
      spotsRemaining,
      fetchLearnersCount,
      getSpotRemaining,
      calendarAttributes,
      selectedDate,
      calendarRef,
      currentMonthStartDate,
    };
  },
});
</script>
<style lang="scss" scoped>
@import "@/modules/courses/styles/courses.scss";
</style>
