<template>
  <div class="tooltip__wrapper">
    <el-tooltip effect="dark" :placement="direction">
      <template #content
        ><div class="tooltip_content">
          {{ description }}
        </div></template
      >
      <v-icon icon="mdi-information-variant-circle-outline" />
    </el-tooltip>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useDisplay } from "vuetify";

const props = defineProps({
  description: {
    type: String,
    required: true,
  },
  direction: {
    type: String,
    default: "top",
  },
});
const vuetify = useDisplay();
const isMobileView = computed(() => vuetify.smAndDown.value);
</script>

<style lang="scss" scoped>
.tooltip__wrapper {
  .v-icon {
    @include fluidFont(16, 16, 1);
    cursor: pointer;
  }
}
.tooltip_content {
  max-width: 160px;
}
</style>
