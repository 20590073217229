import $axios from "../utils/axios-api-config";
import { JobFilters } from "../models/jobs";

export default {
  fetchJobs(payload: JobFilters) {
    const { keywords = "", ...filters } = payload;
    const urlParams = new URLSearchParams({ ...filters });
    const url = `/jobs?${urlParams.toString()}${
      keywords.length > 0 ? `&keywords[]=${keywords}` : ""
    }`;
    return $axios.get(url);
  },

  fetchProjectDetails(refId: string) {
    const url = `/projects/${refId}`;
    return $axios.get(url);
  },

  fetchPropertyDetails(id: number) {
    const url = `/properties/${id}`;
    return $axios.get(url);
  },

  fetchActiveJobs(payload: JobFilters) {
    const { keywords = "", ...filters } = payload;
    const urlParams = new URLSearchParams({ ...filters });
    const url = `/project-estimate/active/list?${urlParams.toString()}${
      keywords.length > 0 ? `&keywords[]=${keywords}` : ""
    }`;
    return $axios.get(url);
  },

  fetchDraftJobs(payload: JobFilters) {
    const { keywords = "", ...filters } = payload;
    const urlParams = new URLSearchParams({ ...filters });
    const url = `/project-estimate/draft/list?${urlParams.toString()}${
      keywords.length > 0 ? `&keywords[]=${keywords}` : ""
    }`;
    return $axios.get(url);
  },
  fetchRejectedJobs(payload: JobFilters) {
    const { keywords = "", ...filters } = payload;
    const urlParams = new URLSearchParams({ ...filters });
    const url = `/project-estimate/rejected/list?${urlParams.toString()}${
      keywords.length > 0 ? `&keywords[]=${keywords}` : ""
    }`;
    return $axios.get(url);
  },

  emergencyJobAccepted(payload: any) {
    return $axios.post("/jobs/emergency-jobs", payload);
  },
  getEmergencyJobCallOutDetails(projectId: number) {
    return $axios.get(`/jobs/emergency-jobs/${projectId}`);
  },
  getVideoConsultationRoomUrl(payload: any) {
    return $axios.post(`/daily_co/generate-member-token`, payload);
  },
};
