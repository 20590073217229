<!-- <template>
  <div class="project-network__wrapper">
    <h3>Your Project</h3>
  </div>
</template> -->

<template>
  <div
    v-if="isLoading"
    class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-full tw-w-full tw-mt-[530px]"
  >
    <Loader :show="isLoading" />
  </div>
  <div
    class="draft-project-container"
    v-if="(getProjectCount && !isLoading) || isSearch"
  >
    <div class="mobile__wrapper">
      <v-expansion-panels>
        <v-expansion-panel>
          <template v-slot:title>
            <img src="../../../assets/icons/filter-icon.svg" />
            <h6>All Filters</h6>
          </template>
          <v-expansion-panel-text>
            <div class="draft-project__filter">
              <v-text-field
                placeholder="Search Project name, Type, Description"
                class="input_field"
                bg-color="#fff"
                variant="outlined"
                v-model="filter.search"
              >
                <template v-slot:prepend-inner>
                  <img src="../../../assets/icons/trade-network-search.svg" />
                </template>
              </v-text-field>
              <v-text-field
                placeholder="KT19 9JG"
                class="input_field"
                bg-color="#fff"
                variant="outlined"
                v-model="filter.postcode"
              >
                <template v-slot:prepend-inner>
                  <img src="../../../assets/icons/trade-network-search.svg" />
                </template>
              </v-text-field>
              <!-- <v-text-field
                prepend-inner-icon="mdi-arrow-left-right"
                class="input_field"
                placeholder="Miles"
                type="number"
                v-model="filter.radius"
                bg-color="#fff"
                variant="outlined"
              /> -->
              <v-select
                class="input_field"
                bg-color="#fff"
                variant="outlined"
                placeholder="Commercial / Residential"
                item-title="text"
                item-value="value"
                clearable
                ><template v-slot:prepend-inner>
                  <img
                    src="../../../assets/icons/trade-network-trader.svg"
                  /> </template
              ></v-select>
              <v-select
                v-model="filter.type"
                class="input_field"
                bg-color="#fff"
                variant="outlined"
                placeholder="Project Type"
                item-title="text"
                item-value="value"
                :items="jobTypes"
                clearable
                ><template v-slot:prepend-inner>
                  <img
                    src="../../../assets/icons/trade-network-list.svg"
                  /> </template
              ></v-select>
            </div>
          </v-expansion-panel-text> </v-expansion-panel
      ></v-expansion-panels>
    </div>
    <div class="draft-project-innertabs">
      <div class="two_icons">
        <div class="right_btn" :class="{ active: !isMapView }">
          <v-btn
            prepend-icon="mdi-view-grid"
            variant="text"
            @click="isMapView = false"
          >
            Tile
          </v-btn>
        </div>
        <div class="right_btn" :class="{ active: isMapView }">
          <v-btn
            prepend-icon="mdi-map"
            variant="text"
            @click="isMapView = !isMapView"
          >
            Map
          </v-btn>
        </div>
      </div>
      <div class="sort__wrapper sort_dropdown_wrapper">
        <div class="sortby_btn">Sort by</div>
        <v-menu :persistent="true" :close-on-content-click="false">
          <template v-slot:activator="{ props }">
            <v-btn v-bind="props" class="sort__btn">
              <img src="../../../assets/icons/sort.svg" alt="" />
            </v-btn>
          </template>

          <v-list class="dropdown__sort sort_dropdown_inner">
            <!-- TODO : Toggle Active Class With Item -->
            <v-list-item v-for="item in filterOptions" :key="item.value">
              <v-list-item-title @click="onClickFilterOption"
                ><v-icon :icon="item.icon" width="12" /> {{ item.label }}
                <span class="checked__wrapper">
                  <v-icon icon="mdi-check" /> </span
              ></v-list-item-title>
            </v-list-item>
          </v-list>

          <DatePicker
            v-if="isShowDatePicker"
            :masks="masks"
            class="calendar__select calendar__select__custom"
            v-model.range="filter.dataRange"
            mode="date"
          />
        </v-menu>
      </div>
    </div>
    <div class="draft-project__filter desktop__wrapper">
      <v-text-field
        placeholder="Search Project name, Type, Description"
        class="input_field"
        bg-color="#fff"
        variant="outlined"
        v-model="filter.search"
      >
        <template v-slot:prepend-inner>
          <img src="../../../assets/icons/trade-network-search.svg" />
        </template>
      </v-text-field>
      <v-text-field
        placeholder="KT19 9JG"
        class="input_field"
        bg-color="#fff"
        variant="outlined"
        v-model="filter.postcode"
      >
        <template v-slot:prepend-inner>
          <img src="../../../assets/icons/trade-network-search.svg" />
        </template>
      </v-text-field>
      <!-- <v-text-field
        prepend-inner-icon="mdi-arrow-left-right"
        class="input_field"
        placeholder="Miles"
        type="number"
        bg-color="#fff"
        variant="outlined"
        v-model="filter.radius"
      /> -->
      <v-select
        class="input_field"
        bg-color="#fff"
        variant="outlined"
        placeholder="Commercial / Residential"
        item-title="text"
        item-value="value"
        clearable
        :menu-props="{ contentClass: 'icon__text__select' }"
        ><template v-slot:prepend-inner>
          <img
            src="../../../assets/icons/trade-network-trader.svg"
          /> </template
      ></v-select>
      <v-select
        v-model="filter.type"
        class="input_field"
        bg-color="#fff"
        variant="outlined"
        placeholder="Project Type"
        :items="jobTypes"
        item-title="text"
        item-value="value"
        clearable
        :menu-props="{ contentClass: 'icon__text__select' }"
        ><template v-slot:prepend-inner>
          <img src="../../../assets/icons/trade-network-list.svg" /> </template
      ></v-select>
    </div>
    <div v-if="isLoading" class="loader-container">
      <CommonLoader :loading="isLoading" />
    </div>
    <template v-else>
      <div class="top_project_listing" v-if="!isEmptyPropertiesList">
        <div class="top_list_details">
          <div>
            <!-- <strong>{{ paginationData.totalPropertiesCount }}</strong> -->
            {{ getProjectCount }} Projects
          </div>
          |
          <div>
            <!-- <strong>{{ paginationData.totalPropertiesCount }}</strong> -->
            {{ getProjectCount }} Results
          </div>
        </div>
      </div>
      <div class="top_project_listing_content" :class="{ show: isMapView }">
        <div class="project_main map_wrapper">
          <div class="full_container" :class="{ half_container: isMapView }">
            <ProjectCard
              v-for="item in projectListing"
              :key="item.id"
              :job="item"
              @click="onClickProject(item?.refId)"
            />
            <!-- <div
              class="columns_wrapper tw-relative"
              v-for="item in projectListing"
              :key="item.id"
            >
              <div class="project-list">
                <div :class="`${item?.type}_type__box project_type_box`">
                  <div
                    class="project_type__wrapper standard__tag"
                    v-if="item?.type === 'standard'"
                  >
                    <v-icon>
                      <img
                        src="../../../assets/icons/status-standard-icon.svg"
                      />
                    </v-icon>
                    <span>Standard</span>
                  </div>

                  <div
                    class="project_type__wrapper emergency__tag"
                    v-if="item?.type === 'emergency'"
                  >
                    <v-icon>
                      <img src="../../../assets/icons/exclaimation.svg" />
                    </v-icon>
                    <span>Emergency</span>
                  </div>

                  <div
                    class="project_type__wrapper routine__tag"
                    v-if="item?.type === 'routine'"
                  >
                    <v-icon>
                      <img
                        src="../../../assets/icons/status-routine-icon.svg"
                      />
                    </v-icon>
                    <span>Routine</span>
                  </div>
                </div>
                <div class="project-img">
                  <img
                    src="../../../assets/images/air-source-heat-pump-hero.jpg"
                    alt=""
                  />
                </div>
                <div class="project_type__content">
                  <div class="property__info">
                    <div v-if="item?.propertyType" class="user_role_img">
                      <div class="property__type">
                        <v-icon>
                          <img src="../../../assets/icons/user.svg" alt="" />
                        </v-icon>
                        <span>{{ item?.propertyType }}</span>
                      </div>
                    </div>
                    <div class="post_code">
                      <v-icon icon="mdi-map-marker"></v-icon
                      ><span>KT19 9JG</span>
                    </div>
                  </div>
                  <div class="project_type__text">
                    <h6>{{ item?.name }}</h6>

                    <div
                      class="project_type__text-inner tw-z-[3] tw-relative tw-cursor-default"
                      :class="{ 'toggle-text': toggleShowText === item?.id }"
                    >
                      <p>
                        <span v-html="item?.description"></span>
                        <span
                          class="project_type__text-toggle"
                          v-if="countLines(item?.description)"
                          @click="onClickToggleShowText(item.id)"
                          >{{
                            toggleShowText === item?.id
                              ? "Show Less"
                              : "Read More"
                          }}</span
                        >
                      </p>
                    </div>
                  </div>
                  <div class="estimations"></div>
                </div>
                <div class="project_type__info">
                  <div class="info__box">
                    <h6>{{ item?.projectPropertiesCount }}</h6>
                    <p>Properties</p>
                  </div>
                  <div class="info__box">
                    <h6>{{ getPostedDate(item?.dateCreated) }}</h6>
                    <p>Posted</p>
                  </div>
                  <div class="info__box">
                    <h6>Not Started</h6>
                    <p>Status</p>
                  </div>
                </div>
              </div>
              <div
                class="tw-absolute tw-top-0 tw-left-0 tw-h-full tw-w-full tw-cursor-pointer"
                @click="onClickProject(item?.refId)"
              ></div>
            </div> -->
          </div>
        </div>
        <div class="map__container">
          <!-- <AddressMapComponent :latlng="[53.5809811, -0.6393335]" /> -->
          <!-- :addresses="addresses" -->
        </div>
      </div>
    </template>
  </div>
  <div v-if="!getProjectCount && !isLoading && !isSearch">
    <ProjectListEmptyState :allow-button="false" heading-text="draft" />
  </div>
</template>

<script lang="ts">
import { ref } from "vue";
import { useRouter } from "vue-router";
import {
  ProjectJobStatusEnum,
  ProjectStatusType,
  ProjectTypesEnum,
} from "@/core/enums/ProjectsEnum";
import { onBeforeMount } from "vue";
import projectService from "@/core/services/project.service";
import InputField from "@/core/components/common/InputField.vue";
// import AddressMapComponent from "@/core/components/AddressMapComponent.vue";
import { PROJECT_DETAILS_ROUTE } from "../routes";
import { watch } from "vue";
import { DatePicker } from "v-calendar";
import { computed } from "vue";
import ProjectCard from "@/modules/project/components/ProjectCard.vue";
import ProjectListEmptyState from "@/modules/project/components/ProjectListEmptyState.vue";
import Loader from "@/core/components/common/Loader.vue";
export default {
  components: {
    // AddressMapComponent,
    // InputField,
    DatePicker,
    ProjectCard,
    ProjectListEmptyState,
    Loader,
  },
  setup(props: any, ctx: any) {
    const isMapView = ref(false);

    const projectListing = ref(null) as any;

    const isShowDatePicker = ref(false);

    const toggleShowText = ref(null) as any;

    const isLoading = ref(false);

    const isSearch = ref(false);

    const router = useRouter();

    const filter = ref({
      search: null,
      type: null,
      dataRange: null,
      postcode: null,
      radius: null,
    });

    const filterOptions = ref([
      {
        label: "Posted Date",
        value: "datePosted",
        icon: "mdi-calendar-range",
      },
    ]);

    const projectStatusItems = ref([
      {
        text: "All",
        value: "",
        icon: "trade-network-list",
      },
      {
        text: "Awaiting estimates",
        value: ProjectJobStatusEnum.AWAITING_QUOTES,
        icon: "awaiting-estimates-icon",
      },
      {
        text: "Estimates received",
        value: ProjectJobStatusEnum.QUOTES_RECEIVED,
        icon: "estimates-receive-icon",
      },
      {
        text: "In progress",
        value: ProjectJobStatusEnum.IN_PROGRESS,
        icon: "in-progress-icon",
      },
      {
        text: "Cancelled",
        value: ProjectJobStatusEnum.CANCELLED,
        icon: "cancelled-icon",
      },
    ]);

    const jobTypes = ref([
      {
        text: "Emergency",
        value: ProjectTypesEnum.EMERGENCY,
      },
      {
        text: "Routine",
        value: ProjectTypesEnum.ROUTINE,
      },
      {
        text: "Standard",
        value: ProjectTypesEnum.STANDARD,
      },
      {
        text: "Video Consultation",
        value: ProjectTypesEnum.VIDEO_CONSULTATION,
      },
    ]);

    const onClickProject = (value: string) => {
      router.push({ name: PROJECT_DETAILS_ROUTE, params: { refId: value } });
    };

    const getPostedDate = (isoDate: any) => {
      const date = new Date(isoDate);

      // Extract the day, month, and year from the Date object
      const day = String(date.getUTCDate()).padStart(2, "0");
      const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-indexed
      const year = date.getUTCFullYear();

      // Format the date as dd/mm/yyyy
      return `${day}/${month}/${year}`;
    };

    const formatDate = (dateString: string) => {
      const date = new Date(dateString);

      // Extracting date components
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");

      return `${year}-${month}-${day}`;
    };

    const onClickFilterOption = () => {
      isShowDatePicker.value = true;
    };

    const masks = {
      weekdays: "WWW",
    };

    const getProjectCount = computed(() => projectListing.value?.length);

    watch(
      () => filter.value,
      async (newValue: any) => {
        let params = {} as any;
        if (newValue.dataRange) {
          params = {
            ...newValue,
            startDate: formatDate(newValue.dataRange?.start),
            endDate: formatDate(newValue.dataRange?.end),
          } as any;
        } else {
          params = { ...newValue };
        }
        delete params[`dataRange`];

        params[`projectJobStatus`] = ProjectJobStatusEnum.DRAFT_PROJECT;

        params = Object.fromEntries(
          Object.entries(params).filter((value: any) =>
            value[1] === 0 ? true : value[1]
          )
        );
        const paramsLength = Object.keys(params);
        if (paramsLength.length > 1) isSearch.value = true;

        projectListing.value = (
          await projectService.getAllProjects(params)
        ).data;
      },
      { deep: true }
    );

    const countLines = (description: string) => {
      let words = description?.trim().split(" ");
      let maxLength = window.innerWidth < 720 ? 10 : 18;

      if (words?.length > maxLength) {
        return true;
      }

      return false;
    };

    const onClickToggleShowText = (itemId: number) => {
      if (toggleShowText.value === itemId) {
        toggleShowText.value = null;
      } else {
        toggleShowText.value = itemId;
      }
    };

    onBeforeMount(async () => {
      try {
        isLoading.value = true;
        projectListing.value = (
          await projectService.getAllProjects({
            projectJobStatus: ProjectJobStatusEnum.DRAFT_PROJECT,
          })
        ).data;
        isLoading.value = false;
      } catch (error) {
        console.log("error while fetching all projects", error);
      } finally {
        isLoading.value = false;
      }
    });
    return {
      isSearch,
      isLoading,
      isMapView,
      projectListing,
      onClickProject,
      filter,
      getPostedDate,
      filterOptions,
      isShowDatePicker,
      onClickFilterOption,
      jobTypes,
      masks,
      getProjectCount,
      countLines,
      onClickToggleShowText,
      toggleShowText,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/modules/project/styles/projectDraft.scss";
</style>
