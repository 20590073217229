<template>
  <div
    class="tw-mt-8 tw-bg-white tw-rounded-[14px] tw-text-left tw-p-[20px] tw-text-darkBlue tw-font-weight-medium c-card"
    v-if="videoScheduleDetails?.length"
  >
    <div
      class="tw-flex tw-gap-y-3 flex-column tw-items-start rmd:!tw-flex-row rmd:!tw-justify-between tw-mb-[20px]"
    >
      <div
        class="tw-text-[20px] rmd:tw-text-[24px] tw-text-darkBlue tw-font-bold tw-text-left"
      >
        <p>{{ props.projectDetails.name }}</p>
      </div>
      <v-btn
        class="button button-purple button-join"
        @click="startCall"
        :loading="isLoading"
        :disabled="isDisableCreateRoomButton"
        v-if="!isMeetingOnGoing"
        >Join meeting</v-btn
      >
      <v-btn
        class="button button-purple button-ongoing"
        v-if="isMeetingOnGoing"
        :disabled="true"
        >Ongoing</v-btn
      >
    </div>
    <div
      class="tw-flex tw-gap-3 rmd:!tw-gap-[35px] tw-mb-[25px] tw-flex-col rmd:!tw-items-center rmd:!tw-flex-row"
    >
      <div class="tw-flex tw-items-center tw-gap-[12px]">
        <img
          src="@/assets/icons/calendar-2.svg"
          alt=""
          class="tw-max-w-[20px]"
        />
        <p>
          {{ getVideoStartedDate(videoScheduleDetails[0].startDate) }}
        </p>
      </div>
      <div class="tw-flex tw-items-center tw-gap-[12px]">
        <img src="@/assets/icons/clock-2.svg" alt="" class="tw-max-w-[20px]" />
        <p>
          {{ videoScheduleDetails[0].startTime }} -
          {{ videoScheduleDetails[0].endTime }}
        </p>
      </div>
    </div>
    <div class="tw-grid tw-grid-cols-[32px_1fr] tw-gap-[10px] tw-items-center">
      <div
        class="tw-rounded-full tw-overflow-hidden tw-w-[30px] tw-h-[30px] tw-relative"
      >
        <img
          :src="userUrl"
          alt=""
          v-if="userUrl"
          class="tw-absolute tw-top-0 tw-left-0 tw-h-full tw-w-full tw-object-cover"
        />
        <UserProfileLogo
          v-else
          :userName="`${videoScheduleDetails[0].user.firstName} ${videoScheduleDetails[0].user.lastName}`"
        />
      </div>
      <p>
        <strong class="tw-font-semibold"
          >{{ videoScheduleDetails[0].user.firstName }}
          {{ videoScheduleDetails[0].user.lastName }}
        </strong>
        <span class="tw-opacity-50 tw-ml-1 tw-text-[14px]">(Trade Person)</span>
      </p>
    </div>
  </div>

  <!-- video consultation -->
  <div
    ref="callFrameRef"
    v-show="videoConsultationStatus === 'incall'"
    class="call-frame"
  >
    <v-btn
      variant="text"
      class="leave-button"
      prepend-icon="mdi-logout"
      @click="onClickLeaveButton"
      v-if="!isAllowToJoinMeeting"
      >Leave</v-btn
    >
  </div>
  <MeetingNotCreated
    v-if="isShowMeetingNotCreatedModal"
    @closeModal="closeModal"
  />
</template>

<script lang="ts" setup>
import { PROJECT_STORE } from "@/store/modules/project";
import { CallTypesEnums } from "@/core/enums/VideoEnum";
import { getImageApiUrl } from "@/core/utils/common";
import DailyIframe from "@daily-co/daily-js";
import ProjectService from "@/core/services/project.service";
import MeetingNotCreated from "@/modules/project/modal/MeetingNotCreated.vue";
import moment from "moment";
import { ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { onBeforeMount } from "vue";
import $axios from "@/core/utils/axios-api-config";
import UserProfileLogo from "@/core/components/UserProfileLogo.vue";
import VideoService from "@/core/services/video.service";
import { JOBS_STORE } from "@/store/modules/jobs";
import { onUnmounted } from "vue";
import { useRouter } from "vue-router";

const route = useRoute();
const store = useStore();
const router = useRouter();
const videoConsultationStatus = ref("idel");
const callFrameRef = ref<HTMLElement | null>(null);
let callFrame: any = null;

const props = defineProps({
  projectDetails: {
    type: Object,
  },
}) as any;

const isAllowToJoinMeeting = ref(true);

const userUrl = ref(null);

const videoScheduleDetails = ref([]) as any;

const isDisableCreateRoomButton = ref(false);

const isLoading = ref(false);

const isShowMeetingNotCreatedModal = ref(false);

const isMeetingOnGoing = ref(false);

const participantsCount = ref(0);

const startCall = async () => {
  if (!callFrame) {
    callFrame = DailyIframe.createFrame(callFrameRef.value as HTMLElement, {
      iframeStyle: {
        height: "100%",
        width: "100%",
        // aspectRatio: "16 / 9",
        border: "1px solid var(--grey)",
        borderRadius: "4px",
      },
      showLeaveButton: true,
    });

    callFrame.on("left-meeting", leaveCall);
    callFrame.on("participant-joined", handleParticipantStateChange);
    callFrame.on("participant-left", handleParticipantStateChange);
    let response;
    try {
      isLoading.value = true;
      response = await store.dispatch(
        `${JOBS_STORE}/getVideoConsultationRoomUrl`,
        {
          roomTitle: route.params?.refId,
          type: CallTypesEnums.MEETING,
          startDate: videoScheduleDetails.value?.[0].startDate,
          startTime: videoScheduleDetails.value?.[0].startTime,
          endTime: videoScheduleDetails.value?.[0].endTime,
        }
      );
    } catch (error) {
      videoConsultationStatus.value = "incall";
      isShowMeetingNotCreatedModal.value = true;
      response = null;
      onClickLeaveButton();
    } finally {
      isLoading.value = false;
    }
    console.log(response);
    if (response?.url) {
      // window.open(`${response?.url}?t=${response?.token}`, "_blank");
      videoConsultationStatus.value = "incall";

      callFrame
        .join({ url: response?.url, token: response?.token })
        .then(() => {
          isMeetingOnGoing.value = true;
        })
        .catch((error: any) => {
          if (error?.error?.type === "nbf-room") {
            isAllowToJoinMeeting.value = false;
          }
          console.error("Failed to join the call:", error);
        });
    }
  }
};

const handleParticipantStateChange = () => {
  const participants = callFrame.participants();
  participantsCount.value = Object.keys(participants).length;
};

const leaveCall = async () => {
  if (callFrame && isAllowToJoinMeeting.value) {
    videoConsultationStatus.value = "idel";
    callFrame.leave().catch((error: any) => {
      console.error("Failed to leave the call:", error);
    });
    callFrame.destroy();
    callFrame = null;
    isMeetingOnGoing.value = false;
    if (participantsCount.value === 1 || participantsCount.value === 0) {
      await VideoService.deleteRoom(route.params?.refId);
    }
  }
};

const closeModal = () => {
  isShowMeetingNotCreatedModal.value = false;
  router.go();
};

const onClickLeaveButton = () => {
  videoConsultationStatus.value = "idel";
  callFrame.leave().catch((error: any) => {
    console.error("Failed to leave the call:", error);
  });
  callFrame.destroy();
  callFrame = null;
  isMeetingOnGoing.value = false;
};

const getVideoStartedDate = (videoStartDate: string) => {
  return moment(videoStartDate).format("D MMMM YYYY");
};

onBeforeMount(async () => {
  videoScheduleDetails.value = await ProjectService.getMeetingScheduleDetails(
    props.projectDetails?.id
  );

  const startDate = videoScheduleDetails.value?.[0]?.startDate;
  const endTime = videoScheduleDetails.value?.[0]?.endTime;

  // Combine date and time into a single string
  const combinedDateTimeString = `${startDate} ${endTime}`;

  // Parse the combined date and time using Moment.js
  const combinedDateTime = moment(combinedDateTimeString, "YYYY-MM-DD h:mm A");

  // Get current date and time using Moment.js
  const currentDateTime = moment();

  // Compare the parsed date and time with current date and time
  if (combinedDateTime.isBefore(currentDateTime)) {
    isDisableCreateRoomButton.value = true;
  }

  const imageUrl = getImageApiUrl(
    videoScheduleDetails.value?.[0]?.user?.userPublicProfile?.profileImage,
    true
  );
  const encoded: any = await $axios.get(imageUrl);
  userUrl.value = encoded;
});

onUnmounted(() => {
  if (callFrame) {
    callFrame.off("participant-joined", handleParticipantStateChange);
    callFrame.off("participant-left", handleParticipantStateChange);
  }
});
</script>
<style lang="scss" scoped>
.call-frame {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
}
.c-card {
  box-shadow: 0px 0px 8px 0px #0c0f4a14;
}

.button-ongoing,
.button-join {
  text-transform: none;
  font-weight: 600;
}

.button-ongoing {
  background-color: rgba(#ff3636, 0.1) !important;
  border-color: transparent !important;
  color: #ff3636 !important;
}

.leave-button {
  display: flex !important;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-width: 1px !important;
  gap: 5px !important;
  height: auto !important;
  padding: 10px !important;
  background-color: rgba(#ff3636, 0.1);
  color: #000 !important;
  font-size: 10px !important;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 999;
  text-transform: capitalize !important;

  :deep(.v-btn__prepend) {
    margin: 0;
    color: #ff3636 !important;
  }
}
</style>
