<template>
  <CommonDialog
    :isShowHeader="false"
    :isShowBackButton="false"
    :className="['delete_event_modal']"
    width="350px"
    height="auto"
    ><template v-slot:body>
      <div class="modal_content">
        <div class="header">
          <v-btn
            variant="plain"
            icon="mdi-close"
            @click="onClickCloseTab"
          ></v-btn>
        </div>
        <div class="form_wrapper">
          <div class="modal_form">
            <img :src="getModalInfo.image" alt="" />
            <h6>{{ getModalInfo.header }}</h6>
            <p>{{ getModalInfo.info }}</p>
          </div>
        </div>
        <div v-if="modalType === 'delete'" class="modal_form_footer">
          <v-btn
            variant="plain"
            @click="onClickCloseTab"
            class="button button-purple-border"
            >Cancel</v-btn
          >
          <v-btn class="button button-orange" @click="onClickDeleteEmail"
            >Delete</v-btn
          >
        </div>
        <div v-if="modalType === 'success'" class="success-btn-wrap">
          <v-btn class="button button-orange" @click="onClickCloseTab">
            CONTINUE
          </v-btn>
        </div>
      </div>
    </template>
  </CommonDialog>
</template>

<script lang="ts">
import CommonDialog from "@/core/components/CommonDialog.vue";
import manageEmailService from "@/core/services/manage-email.service";
import { computed } from "vue";

export default {
  components: {
    CommonDialog,
  },
  props: {
    modalType: {
      type: String,
      default: "success",
    },
    manageEmail: Object,
  },
  setup(props: any, ctx: any) {
    const onClickCloseTab = () => {
      ctx.emit("on-close");
    };
    const getModalInfo = computed(() =>
      props.modalType === "success"
        ? {
            header: "Email Pending Verification!",
            info: "Your alias email is pending verification and will be added to your list once verified.",
            image: require("@/assets/images/email-success.png"),
          }
        : {
            header: "Confirm Deletion",
            info: `Are you sure you want to delete this email? This action cannot be
              undone.`,
            image: require("@/assets/icons/calendar-delete.svg"),
          }
    );

    const onClickDeleteEmail = async () => {
      try {
        await manageEmailService.removeEmail(props.manageEmail.id);
        ctx.emit("on-close", true);
      } catch (error) {
        console.error("email add error", error);
        ctx.emit("on-close");
      }
    };

    return {
      onClickCloseTab,
      onClickDeleteEmail,
      getModalInfo,
    };
  },
};
</script>

<style lang="scss" scoped>
.success-btn-wrap {
  margin-top: 30px;
  button {
    width: 100%;
  }
}
:deep(p) {
  max-width: 100% !important;
}
</style>
