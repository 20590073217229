/**
 * Trades Passport route page
 */
import TradePassportRoute from "@/modules/trade-passport/pages/TradePassport.vue";

export const TRADE_PASSPOSRT_ROUTE = "trade-passport";

export default {
  path: "/trade-passport",
  name: TRADE_PASSPOSRT_ROUTE,
  component: TradePassportRoute,
  meta: {
    validateAuth: true,
    requiresAuth: true,
    title: "Trades Passport",
  },
};
