<template>
  <InfoCardLayoutSlot
    size="lg"
    :hasFooter="true"
    title="Property Summary"
    :removeBorderBottom="true"
    :footerBorderTopDashed="true"
    bgColor="#F1F6FB"
  >
    <template #title-icon>
      <ToolTip description="Property Summary" />
    </template>
    <template #content>
      <section class="tw-flex tw-w-full tw-flex-col tw-gap-2 tw-rounded">
        <div
          class="tw-flex tw-w-full tw-justify-between tw-items-start tw-bg-white tw-py-3 tw-px-4 tw-box-border tw-border-0 tw-border-b tw-border-[#0000001a] tw-rounded tw-border-solid"
        >
          <Text variant="span" lineHeight="16px">Address</Text>
          <Text variant="span" textWeight="400" lineHeight="16px">{{
            getPropertyAddress(propertyDetails?.localData) || "-"
          }}</Text>
        </div>
        <div
          class="tw-flex tw-w-full tw-justify-between tw-items-start tw-bg-white tw-py-3 tw-px-4 tw-box-border tw-border-0 tw-border-b tw-border-[#0000001a] tw-rounded tw-border-solid"
        >
          <Text variant="span" lineHeight="16px">Tenure | Term</Text>
          <Text variant="span" textWeight="400" lineHeight="16px">{{
            propertyDetails?.localData?.propertyType || "-"
          }}</Text>
        </div>
        <div
          class="tw-flex tw-w-full tw-justify-between tw-items-start tw-bg-white tw-py-3 tw-px-4 tw-box-border tw-border-0 tw-border-b tw-border-[#0000001a] tw-rounded tw-border-solid"
        >
          <Text variant="span" lineHeight="16px">Property Type</Text>
          <Text variant="span" textWeight="400" lineHeight="16px">{{
            getPropertyType(propertyDetails?.localData) || "-"
          }}</Text>
        </div>
        <div
          class="tw-flex tw-w-full tw-justify-between tw-items-start tw-bg-white tw-py-3 tw-px-4 tw-box-border tw-border-0 tw-border-b tw-border-[#0000001a] tw-rounded tw-border-solid"
        >
          <Text variant="span" lineHeight="16px">Bedrooms</Text>
          <Text variant="span" textWeight="400" lineHeight="16px">{{
            getTotalRooms(propertyDetails?.localData) || "-"
          }}</Text>
        </div>
        <div
          class="tw-flex tw-w-full tw-justify-between tw-items-start tw-bg-white tw-py-3 tw-px-4 tw-box-border tw-border-0 tw-border-b tw-border-[#0000001a] tw-rounded tw-border-solid"
        >
          <Text variant="span" lineHeight="16px">Bathrooms</Text>
          <Text variant="span" textWeight="400" lineHeight="16px">
            {{ propertyDetails?.localData?.baths || "-" }}
          </Text>
        </div>
        <div
          class="tw-flex tw-w-full tw-justify-between tw-items-start tw-bg-white tw-py-3 tw-px-4 tw-box-border tw-border-0 tw-border-b tw-border-[#0000001a] tw-rounded tw-border-solid"
        >
          <Text variant="span" lineHeight="16px">Receptions</Text>
          <Text variant="span" textWeight="400" lineHeight="16px">0</Text>
        </div>
        <div
          class="tw-flex tw-w-full tw-justify-between tw-items-start tw-bg-white tw-py-3 tw-px-4 tw-box-border tw-border-0 tw-border-b tw-border-[#0000001a] tw-rounded tw-border-solid"
        >
          <Text variant="span" lineHeight="16px">EPC Rating</Text>
          <div>
            <Text variant="span" textWeight="400" lineHeight="16px">{{
              getEPCRating(propertyDetails?.localData)
            }}</Text>
            <v-icon
              color="#FFA500"
              icon="mdi-chevron-right"
              @click="onOpenModal"
            />
          </div>
        </div>
        <div
          class="tw-flex tw-w-full tw-justify-between tw-items-start tw-bg-white tw-py-3 tw-px-4 tw-box-border tw-border-0 tw-border-b tw-border-[#0000001a] tw-rounded tw-border-solid"
        >
          <Text variant="span" lineHeight="16px">Floor area</Text>
          <Text variant="span" textWeight="400" lineHeight="16px"
            >{{ getFloorArea(propertyDetails?.localData) || "0" }} sqm</Text
          >
        </div>
        <div
          class="tw-flex tw-w-full tw-justify-between tw-items-start tw-bg-white tw-py-3 tw-px-4 tw-box-border tw-border-0 tw-border-b tw-border-[#0000001a] tw-rounded tw-border-solid"
        >
          <Text variant="span" lineHeight="16px">Plot Size</Text>
          <Text variant="span" textWeight="400" lineHeight="16px">-</Text>
        </div>
        <div
          class="tw-flex tw-w-full tw-justify-between tw-items-start tw-bg-white tw-py-3 tw-px-4 tw-box-border tw-border-0 tw-border-b tw-border-[#0000001a] tw-rounded tw-border-solid"
        >
          <Text variant="span" lineHeight="16px">Year of Construction</Text>
          <Text variant="span" textWeight="400" lineHeight="16px">{{
            getYearOfBand(propertyDetails?.localData) || "-"
          }}</Text>
        </div>
      </section>
    </template>
    <template #footer-content>
      <section class="tw-w-full tw-flex tw-items-center tw-justify-center">
        <Text variant="span" textWeight="400" textColor="rgba(12, 15, 74, 0.5)"
          >Powered by <Text variant="span" textWeight="400">Krystal</Text></Text
        >
      </section>
    </template>
  </InfoCardLayoutSlot>
  <!-- MODALS -->
  <EnergyEfficiencyModal
    v-if="showEnergyEfficiencyModal"
    @on-close="onCloseModal"
  />
</template>
<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import Text from "@/core/components/ui/general/Text.vue";
import InfoCardLayoutSlot from "@/core/components/slots/InfoCardLayoutSlot.vue";
import EnergyEfficiencyModal from "@/modules/properties/components/Modals/EnergyEfficiencyModal.vue";
import { PROPERTY_STORE } from "@/store/modules/property";
import ToolTip from "@/core/components/common/ToolTip.vue";

const store = useStore();

const propertyDetails = computed(
  () => store.getters[`${PROPERTY_STORE}/propertyDetails`]
);

const removeTrailingZeros = (num) => {
  if (Number.isInteger(num)) {
    return num;
  }
  return parseFloat(num?.toString());
};

const getFloorArea = (localData) => {
  if (localData?.epcDomestic) {
    return removeTrailingZeros(
      localData?.epcDomestic?.floors?.total_floor_area
    );
  }
  if (!localData?.epcDomestic && localData?.epcDisplay) {
    return removeTrailingZeros(localData?.epcDisplay?.floors?.total_floor_area);
  }
  if (
    !localData?.epcDomestic &&
    !localData?.epcDisplay &&
    localData?.epcNonDomestic
  ) {
    return removeTrailingZeros(
      localData?.epcNonDomestic?.floors?.total_floor_area
    );
  }
};

const getPropertyType = (localData) => {
  if (localData?.epcDomestic) {
    return localData?.epcDomestic?.built_form;
  }
  if (!localData?.epcDomestic && localData?.epcDisplay) {
    return localData?.epcDisplay?.built_form;
  }
  if (
    !localData?.epcDomestic &&
    !localData?.epcDisplay &&
    localData?.epcNonDomestic
  ) {
    return localData?.epcNonDomestic?.built_form;
  }
};

const getPropertyAddress = (localData) => {
  if (localData?.epcDomestic) {
    return localData?.epcDomestic?.address;
  }
  if (!localData?.epcDomestic && localData?.epcDisplay) {
    return localData?.epcDisplay?.address;
  }
  if (
    !localData?.epcDomestic &&
    !localData?.epcDisplay &&
    localData?.epcNonDomestic
  ) {
    return localData?.epcNonDomestic?.address;
  }
};

const getYearOfBand = (localData) => {
  if (localData?.epcDomestic) {
    return localData?.epcDomestic?.construction_age_band?.split(":")[1];
  }
  if (!localData?.epcDomestic && localData?.epcDisplay) {
    return localData?.epcDisplay?.construction_age_band?.split(":")[1];
  }
  if (
    !localData?.epcDomestic &&
    !localData?.epcDisplay &&
    localData?.epcNonDomestic
  ) {
    return localData?.epcNonDomestic?.construction_age_band.split(":")[1];
  }
};

const getEPCRating = (localData) => {
  if (localData?.epcDomestic) {
    if (localData.epcDomestic.energy?.current_energy_efficiency) {
      return (
        localData?.epcDomestic?.energy?.current_energy_efficiency +
        " " +
        localData?.epcDomestic?.energy?.current_energy_rating
      );
    } else {
      return "NA";
    }
  }
  if (!localData?.epcDomestic && localData?.epcDisplay) {
    return (
      localData?.epcDisplay?.current_energy_efficiency +
      " " +
      localData?.epcDisplay?.current_energy_rating
    );
  }
  if (
    !localData?.epcDomestic &&
    !localData?.epcDisplay &&
    localData?.epcNonDomestic
  ) {
    return (
      localData?.epcNonDomestic?.current_energy_efficiency +
      " " +
      localData?.epcNonDomestic?.current_energy_rating
    );
  }
};

const getTotalRooms = (localData) => {
  if (localData?.baths) {
    return localData?.baths;
  }

  if (localData?.epcDomestic) {
    if (
      localData?.epcDomestic?.number_habitable_rooms &&
      localData?.epcDomestic?.number_heated_rooms
    ) {
      return (
        localData?.epcDomestic?.number_habitable_rooms +
        localData?.epcDomestic?.number_heated_rooms
      );
    }
    if (
      localData?.epcDomestic?.number_habitable_rooms &&
      !localData?.epcDomestic?.number_heated_rooms
    ) {
      return localData?.epcDomestic?.number_habitable_rooms;
    }
    if (
      !localData?.epcDomestic?.number_habitable_rooms &&
      localData?.epcDomestic?.number_heated_rooms
    ) {
      return localData?.epcDomestic?.number_heated_rooms;
    }
    return 0;
  }
  if (!localData?.epcDomestic && localData?.epcDisplay) {
    if (
      localData?.epcDisplay?.number_habitable_rooms &&
      localData?.epcDisplay?.number_heated_rooms
    ) {
      return (
        localData?.epcDisplay?.number_habitable_rooms +
        localData?.epcDisplay?.number_heated_rooms
      );
    }
    if (
      localData?.epcDisplay?.number_habitable_rooms &&
      !localData?.epcDisplay?.number_heated_rooms
    ) {
      return localData?.epcDisplay?.number_habitable_rooms;
    }
    if (
      !localData?.epcDisplay?.number_habitable_rooms &&
      localData?.epcDisplay?.number_heated_rooms
    ) {
      return localData?.epcDisplay?.number_heated_rooms;
    }
    return 0;
  }
  if (
    !localData?.epcDomestic &&
    !localData?.epcDisplay &&
    localData?.epcNonDomestic
  ) {
    if (
      localData?.epcNonDomestic?.number_habitable_rooms &&
      localData?.epcNonDomestic?.number_heated_rooms
    ) {
      return (
        localData?.epcNonDomestic?.number_habitable_rooms +
        localData?.epcNonDomestic?.number_heated_rooms
      );
    }
    if (
      localData?.epcNonDomestic?.number_habitable_rooms &&
      !localData?.epcNonDomestic?.number_heated_rooms
    ) {
      return localData?.epcNonDomestic?.number_habitable_rooms;
    }
    if (
      !localData?.epcNonDomestic?.number_habitable_rooms &&
      localData?.epcNonDomestic?.number_heated_rooms
    ) {
      return localData?.epcNonDomestic?.number_heated_rooms;
    }
    return 0;
  }
};

const showEnergyEfficiencyModal = ref(false);

const onCloseModal = () => {
  showEnergyEfficiencyModal.value = false;
};

const onOpenModal = () => {
  showEnergyEfficiencyModal.value = true;
};

const props = defineProps({
  hideAction: Boolean,
});
</script>
<style lang="scss" scoped>
.score {
  box-shadow: 0px 0px 8.61px 0px rgba(12, 15, 74, 0.2);
}
</style>
