<template>
  <Card class="!tw-max-h-[unset] !tw-min-w-[179px] !tw-p-4 tw-box-border">
    <template #content>
      <main class="tw-flex tw-flex-col tw-gap-2 tw-w-full">
        <section
          v-for="(item, index) in getMenuList"
          :key="index"
          @click="onHandleAction(item.name)"
          class="tw-flex tw-items-center tw-gap-2 tw-py-2 tw-px-3 pw-box-border tw-rounded-md hover:tw-bg-[#FFF6E5] tw-cursor-pointer"
        >
          <component :is="item.icon"></component>
          <Text
            class="hover:!tw-text-[#FFA500] !tw-capitalize"
            variant="p"
            textWeight="400"
            >{{ item.name }}</Text
          >
        </section>
        <v-divider v-if="isUserHasDeleteRight"></v-divider>
        <section
          v-if="isUserHasDeleteRight"
          @click="onHandleAction(documentConstants.DELETE)"
          class="tw-flex tw-items-center tw-gap-2 tw-p-2 tw-px-3 pw-box-border tw-rounded-md hover:tw-bg-[#FFF6E5] tw-cursor-pointer"
        >
          <TrashIcon class="!tw-w-[14px] !tw-h-[18px]" />
          <Text class="hover:!tw-text-[#FFA500]" variant="p" textWeight="400"
            >Delete</Text
          >
        </section>
      </main>
    </template>
  </Card>
</template>
<script setup>
import { reactive } from "vue";

import Card from "@/core/components/ui/general/Card.vue";
import Text from "@/core/components/ui/general/Text.vue";
import AvatarPlusIcon from "@/core/components/icons/AvatarPlusIcon.vue";
import EditPenIcon from "@/core/components/icons/EditPenIcon.vue";
import MoveIcon from "@/core/components/icons/MoveIcon.vue";
import InfoIcon from "@/core/components/icons/InfoIcon.vue";
import DownloadIcon from "@/core/components/icons/DownloadIcon.vue";
import TrashIcon from "@/core/components/icons/TrashIcon.vue";
import documentConstants from "@/modules/documents/contants";
import { computed } from "vue";
import { CONNECTIONS } from "@/store/modules/connections";
import { useStore } from "vuex";
import { PropertyConnectionRoleEnum } from "@/core/enums/PropertiesEnum";

const emits = defineEmits(["on-handle-action"]);
const props = defineProps(["isGallery", "isPropertyDocument", "tableType"]);

const menuDefault = reactive([
  {
    icon: AvatarPlusIcon,
    name: documentConstants.SHARE,
  },
  {
    icon: EditPenIcon,
    name: documentConstants.RENAME,
  },
  {
    icon: MoveIcon,
    name: documentConstants.MOVE,
  },
  {
    icon: InfoIcon,
    name: documentConstants.DETAILS,
  },
  {
    icon: DownloadIcon,
    name: documentConstants.DOWNLOAD,
  },
]);

const menuGallery = reactive([
  {
    icon: EditPenIcon,
    name: documentConstants.RENAME,
  },
  {
    icon: DownloadIcon,
    name: documentConstants.DOWNLOAD,
  },
]);

const getMenuList = computed(() => {
  const menuList = props.isGallery ? menuGallery : menuDefault;

  return menuList.filter((menu) => {
    const canEdit =
      isUserHasEditRight.value ||
      [documentConstants.DETAILS, documentConstants.DOWNLOAD].includes(
        menu.name
      );
    const isFileTable =
      props.tableType === documentConstants.FILES ||
      menu.name !== documentConstants.DOWNLOAD;
    return canEdit && isFileTable;
  });
});

const onHandleAction = (name) => {
  emits("on-handle-action", name);
};
const store = useStore();
const currentPropertyConnectionRole = computed(
  () => store.getters[`${CONNECTIONS}/getPropertyConnectionRole`]
);
const isUserHasEditRight = computed(
  () =>
    (props.isPropertyDocument &&
      currentPropertyConnectionRole.value !==
        PropertyConnectionRoleEnum.VIEWER) ||
    !props.isPropertyDocument
);
const isUserHasDeleteRight = computed(
  () =>
    (props.isPropertyDocument &&
      currentPropertyConnectionRole.value ===
        PropertyConnectionRoleEnum.OWNER) ||
    !props.isPropertyDocument
);
</script>
<style lang="scss" scoped></style>
