<template>
  <ChipState
    label="Residential"
    textColor="#4A92E5"
    bgColor="transparent"
    class="!tw-px-0 !tw-justify-between"
  >
    <template #prefix>
      <HomeCircleBlue class="!tw-w-[16px]" />
    </template>
  </ChipState>
</template>
<script setup>
import ChipState from "@/core/components/common/ChipState";
import HomeCircleBlue from "@/core/components/icons/HomeCircleBlue";
</script>
<script>
export default {
  name: "ResidentialChip",
};
</script>
